import NabuButton from '@/common/components/atoms/button/button';
import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import LookAheadSelect from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuBaseTextArea } from '@/common/components/atoms/textArea/textArea';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import AddCompanyAddressInline from '@/common/components/templates/pages/add/addCompanyAddressInline';
import AddCompanyInline from '@/common/components/templates/pages/add/addCompanyInline';
import { IPopOverState } from '@/common/components/templates/pages/add/addNewAsset';
import AddPersonAddressInline from '@/common/components/templates/pages/add/addPersonAddressInline';
import AddPersonInline from '@/common/components/templates/pages/add/addPersonInline';
import AddTalentAgentInline from '@/common/components/templates/pages/add/addTalentAgentInline';
import { ContractStatus, ContractStatusOptions, ContractUnionType, DefaultPageSize, SagAftraCOEDHTPerformerTypeOptions } from '@/common/constants/constants';
import { OPERATORS, UNION_TYPE } from '@/common/enums';
import useNotification from '@/common/hooks/useNotification';
import useScrollToField from '@/common/hooks/useScrollToField';
import { CompanyMainType, ICompanyAddress, ICompanyRecord } from '@/common/types/company';
import {
	IBaseContract,
	IContractSagAftraCOEDForm, IPacketControlForm
} from '@/common/types/contract';
import { IFilterOption } from '@/common/types/filterModel';
import { addressShortner, displayAddress, displayOnlyEmail, getBaseUrl, getCompanyDisplayName, getNotificationText, getPersonFullName, mutateContractAdditionalFormsData, sortEmailOrPhoneNumberForDropDown } from '@/common/utils/utils';
import styles from '@/modules/contracts/components/addContracts.module.scss';
import { getAllAddressByCompany, getCompanyBasicDetails, getStaffListByCompany } from '@/services/company';
import { addContractSagAftraCOED, addMultipleContract, updateContractSagAftraCOED } from '@/services/contract';
import { IAssetRecord } from '@/services/models/asset';
import { IPersonAddress, IPersonRecord } from '@/services/models/person';
import { GetByWithFullNameSearchForBasicDetail, getAddressBasedOnPinCode, getAgentInfo, getAllAddressByPerson } from '@/services/person';
import store from '@/store';
import { setDisableLoader } from '@/store/app/reducer';
import { updateContractFormState } from '@/store/contract/reducer';
import { yupResolver } from '@hookform/resolvers/yup';
import { CopyAll, HelpOutline, NoteAdd, Save } from '@mui/icons-material';
import {
	Autocomplete,
	Box, Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	ListItemButton,
	ListItemText,
	Radio,
	RadioGroup,
	TextField,
	Tooltip
} from '@mui/material';
import { HttpStatusCode } from 'axios';
import { City, State } from 'country-state-city';
import { addYears, format, isBefore } from 'date-fns';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
	Controller,
	UseFormReturn,
	useForm
} from 'react-hook-form';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { StandardSagAftraCOEDSchema } from '../contarctFormSchema';
import ContractCAWageTheftPreventionForm from '../contractCAWageyTheftPreventionForm';
import ContractMinorTrustForm from '../contractMinorTrustForm';
import ContractNYWageTheftPreventionForm from '../contractNYWageTheftPreventionForm';
import ContractW4ShortForm from '../contractW4ShortForm';
import ContractW8BenShortForm from '../contractW8BenShortForm';
import ContractW9BenShortForm from '../contractW9BenShortForm';
import TalentDetailsForm from '../talentDetailsForm';
import { IContractFormState } from './standardSagAftraEmployment2022';

interface Props {
	mainForm?: UseFormReturn<IBaseContract, any, undefined>;
	onCancel?: VoidFunction;
	onSave?: CallableFunction;
	handlePrintStateOfContract?: CallableFunction;
	initialValue?: IContractSagAftraCOEDForm;
	isPrint?: boolean;
	isEdit?: boolean;
	contractId?: string;
	defaultValue?: IBaseContract,
	assets?: IAssetRecord[];
	address?: ICompanyAddress[];
	people?: IPersonRecord[];
}

// eslint-disable-next-line react/display-name
const SagAftraCOED = forwardRef((props: Props, ref) => {
	const [params, setParams] = useSearchParams();
	const { scroll } = useScrollToField();

	useImperativeHandle(ref, () => ({
		handleContractCOEDFormCancel: (data: IContractSagAftraCOEDForm, statusValue: string) => {
			if(data){
				const submitData = {
					...data,
					status: statusValue ? statusValue : ContractStatus.Draft,
					contractSagAftraCOED: undefined,
					payrollVendorId: data?.payrollVendorId?.value,
					returnToEmail: data?.returnToEmail?.value,
					performerId: data?.performerId?.value,
					advertiserId: data?.advertiserId?.value,
					producerCompanyId: data?.producerCompanyId?.value,
					performerAddressId: data?.performerAddressId?.value,
					agentId: data?.agentId?.value,
					agentAddressId: data?.agentAddressId?.value,
					producerAddressId: data?.producerAddressId?.value,
					payrollVendorAddressId: data?.payrollVendorAddressId?.value || '',
					talentAgencyStaffId: data?.talentAgencyStaffId?.value,
					adAgencyId:data?.adAgencyId?.value,
					// workDays
				};
				editContractDetails(submitData);
			}
		},
	}));

	useEffect(() => {
		if(props?.defaultValue?.advertiserId)
			contractForm.setValue('advertiserId', props?.defaultValue?.advertiserId, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.advertiserId]);

	const DEFAULT_COUNTRY_CODE = 'US';
	const notification = useNotification();

	const contractForm = useForm<IContractSagAftraCOEDForm>(
		{
			defaultValues: !props?.initialValue
				? {
					...(props?.mainForm?.getValues() || {}),
					...(props?.defaultValue || {}),
					// workDays: [{}],
				}
				: {
					...(props?.initialValue || {} ),
					
				},
			resolver: yupResolver(StandardSagAftraCOEDSchema),
			shouldFocusError: false
		}
	);

	const packetControlForm = useForm<IPacketControlForm>({
		defaultValues: {
			isW4shortForm: !!contractForm.watch('contractW4Form.firstAndMiddleName'),
			isW8BenShort: !!contractForm.watch('contractW8BenShortForm'),
			isW9BenShort: !!contractForm.watch('contractW9BenShortForm'),
			isMinorTrust: !!contractForm.watch('contractMinorTrustForm'),
			isNYTheftPreventionForm: !!contractForm.watch(
				'contractNYTheftPreventionForm'
			),
			isCATheftPreventionForm: !!contractForm.watch(
				'contractCATheftPreventionForm'
			),
		},
	});

	const [, setCompanyOptions] = useState<ICompanyRecord[]>([]);
	const [personOptions, setPersonOptions] = useState<IPersonRecord[]>(props?.people || []);
	const [, setAssetOptions] = useState<IAssetRecord[]>([]);
	const [addressOptions, setAddressOptions] = useState<ICompanyAddress[]>(
		props?.address || []
	);
	const [selectedAddress, setSelectedAddress] = useState<{id:any, type:any}>();
	const [contractFormState, setContractFormState] =
		useState<IContractFormState>({});
	const [addressPopOver, setAddressPopOver] = useState<IPopOverState>({
		isOpen: false,
	});
	const [companyPopoverInline, setCompanyPopoverInline] = useState<IPopOverState>({
		isOpen: false,
	});

	const VENDOR_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'VENDOR',
		},
	];
	
	// helper functions
	const getPerformerFilter = (): IFilterOption[] => {
		const DEFAULT_FILTER: IFilterOption = {
			value: JSON.stringify(['TALENT']),
			property: 'PersonType',
			operator: OPERATORS.IN,
			isCaseSensitive: false,
		};
		return [DEFAULT_FILTER];
	};

	const handlePackageNotification = (key: keyof IPacketControlForm) => {
		notification.notify({
			message: `Form ${packetControlForm.watch(key) ? 'Added to' : 'Removed from'} Packet.`,
			type: 'info',
		});
	};
	
	const onAddressChange = (addressId: string, field: 'performer' | 'agent' | 'producerAddress') => {
		setSelectedAddress({type: field, id: addressId});
		const address = addressOptions?.find( ad => ad?.id === addressId);
		
		if(address) {
			contractForm.setValue(`${field}Zip`, address?.zipCode, {shouldDirty: true});
			contractForm.setValue(`${field}State`, address?.state, {shouldDirty: true});
			contractForm.setValue(`${field}City`, address?.city?.toUpperCase(), {shouldDirty: true});
		} else {
			contractForm.setValue(`${field}State`, '', {shouldDirty: true});
			contractForm.setValue(`${field}Zip`, '', {shouldDirty: true});
			contractForm.setValue(`${field}City`, '', {shouldDirty: true});
		}
		contractForm.trigger(`${field}State`);
		contractForm.trigger(`${field}City`);
	};

	const handleStateOptions = (
		responseData: any[],
		type: 'COMPANY' | 'PERSON' | 'ASSET' | 'ADDRESS'
	) => {
		const cb = {
			COMPANY: setCompanyOptions,
			PERSON: setPersonOptions,
			ASSET: setAssetOptions,
			ADDRESS: setAddressOptions,
		}[type];

		if (responseData && Array.isArray(responseData)) {
			cb((prev) => {
				const oldFilter = prev?.filter(
					(d) => !responseData?.some((r) => r?.id === d?.id)
				);

				return [...oldFilter, ...responseData];
			});
		}
	};

	useEffect(() => {
		if(selectedAddress?.type !== ''){
			onAddressChange(selectedAddress?.id, selectedAddress?.type);
		}
	}, [addressOptions]);

	
	const onPersonAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('performerId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('performerAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
		onAddressChange(contractForm.watch('performerAddressId.value') || '', 'performer');
	};

	const getUnions = (): any[] =>
		[UNION_TYPE.AFM, UNION_TYPE.SAG_AFTRA].map((value) => ({
			label: value,
			value: value,
		})) || [];

	const getUnionAgreement = (unionType: string): any[] =>
		ContractUnionType[unionType]?.agreements?.map((op) => ({
			label: op?.toUpperCase(),
			value: op?.toUpperCase(),
		})) || [];

	const getStateOptions = () => {
		const states = State.getStatesOfCountry(DEFAULT_COUNTRY_CODE).map(
			(state) => ({ label: state.name?.toUpperCase(), value: state.isoCode })
		);
		if (!states.length) states.unshift({ label: 'NA', value: 'NA' });
		return states;
	};

	const getCityOptions = (stateCode?: string) =>
		stateCode ? City.getCitiesOfState(DEFAULT_COUNTRY_CODE, stateCode)?.map((city) => ({
			label: city.name?.toUpperCase(),
			value: city.name?.toUpperCase(),
		})) || [] : [];

	const getPerformerEmailsOptions = () => {
		const unsortedEmails =
			[...(personOptions || []), ...(props?.people || [])]?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.emails || [];
	
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
			
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const companyOptionGenerator = (responseData: ICompanyRecord[]) => {
		handleStateOptions(responseData, 'COMPANY');

		return (responseData) && responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));
	};

	
	const onPayrollVendorAddressCreated = (createdAddress: ICompanyAddress) => {
		contractForm.setValue('payrollVendorAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const companyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		})) || [];
	};


	const performerOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');
		return responseData?.map((d) => ({
			value: d?.id,
			label: getPersonFullName(d?.personName),
		}));
	};

	const adAgencyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: addressShortner(d),
		}));
	};

	const onZipCodeChange = (
		zipCode: string,
		field: 'performer' | 'agent' | 'producerAddress'
	) => {
		if (zipCode) {
			store.dispatch(setDisableLoader(true));
			getAddressBasedOnPinCode(zipCode)
				.then((res) => {
					if (res?.data?.['country abbreviation'] === 'US') {
						if(res.data){
							const place = res.data.places[0];
							contractForm.setValue(`${field}State`, place['state abbreviation'], { shouldDirty: true });
							contractForm.setValue(`${field}City`, place['place name']?.toUpperCase() ?? '', { shouldDirty: true });
						}
					}
				})
				.catch((e) => console.error(e?.message))
				.finally(() => {
					store.dispatch(setDisableLoader(false));
				});
		}
	};

	const onClickSnippetBtn = () => {
		setContractFormState((prev) => ({
			isPacketOpen: !prev?.isPacketOpen,
		}));
	};

	const handleFormClose = () => {
		contractForm?.reset();
		props?.onCancel && props?.onCancel();
	};

	const { mutate: addContractDetails, isLoading: isAddingContract } =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addContractSagAftraCOED(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const { mutate: editContractDetails, isLoading: isEditingContract } =
		useMutation({
			mutationKey: ['contract-edit'],
			mutationFn: (payload: any) => updateContractSagAftraCOED(props?.contractId || '', payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'UPDATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'UPDATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});



	const handleContractSubmission = async (data: IContractSagAftraCOEDForm, handleMultipleContractAdd?: CallableFunction) => { 
		if (!packetControlForm.watch('isW4shortForm')) {
			data.w4FormId = undefined;
			data.contractW4Form = undefined;
		}		
		if (!packetControlForm.watch('isW8BenShort')) {
			data.w8BenShortFormId = undefined;
			data.contractW8BenShortForm = undefined;
		}		
		if (!packetControlForm.watch('isW9BenShort')) {
			data.w9BenShortFormId = undefined;
			data.contractW9BenShortForm = undefined;
		}		
		if (!packetControlForm.watch('isMinorTrust')) {
			data.minorTrustFormId = undefined;
			data.contractMinorTrustForm = undefined;
		}		
		if (!packetControlForm.watch('isNYTheftPreventionForm')) {
			data.nYTheftPreventionFormId = undefined;
			data.contractNYTheftPreventionForm = undefined;
		}		
		if (!packetControlForm.watch('isCATheftPreventionForm')) {
			data.cATheftPreventionFormId = undefined;
			data.contractCATheftPreventionForm = undefined;
		}

		
		try {
			const notify = (message: string) => {
				notification.notify({
					message,
					type: 'error',
				});
			};
			// NOTE: These linked form's handled separately because the payload size is huge.
			const updatedContractIds = await mutateContractAdditionalFormsData(data, data?.performerId?.value || '', notify);
			data.w4FormId = updatedContractIds?.w4FormId;
			data.w8BenShortFormId = updatedContractIds?.w8BenShortFormId;
			data.w9BenShortFormId = updatedContractIds?.w9BenShortFormId;
			data.minorTrustFormId = updatedContractIds?.minorTrustFormId;
			data.nYTheftPreventionFormId = updatedContractIds?.nYTheftPreventionFormId;
			data.cATheftPreventionFormId = updatedContractIds?.cATheftPreventionFormId;

		} catch (error) {
			notification.notify({
				message: getNotificationText('Form', 'UPDATE', true),
				type: 'error',
			});
		} 
		finally {
			data.contractW4Form = undefined;
			data.contractW8BenShortForm = undefined;
			data.contractW9BenShortForm = undefined;
			data.contractMinorTrustForm = undefined;
			data.contractNYTheftPreventionForm = undefined;
			data.contractCATheftPreventionForm = undefined;

			const mainFormValues = props?.mainForm?.getValues();
			const addMainFormValues = !props?.isEdit ? {
				contractType: mainFormValues?.contractType,
				contractDisplayName: mainFormValues?.contractDisplayName,
				unionType: mainFormValues?.unionType,
				unionAgreement: mainFormValues?.unionAgreement,
				payrollVendorAddressId: mainFormValues?.payrollVendorAddressId || '',
			} : {};

			const contractExpiryDate = data.termDate ? addYears(new Date(data.termDate), 3) : undefined;
			const isExpired = contractExpiryDate ? (isBefore(contractExpiryDate, new Date()) ? ContractStatus.Expired : undefined) : undefined;

			const submitData = {
				...data,
				...addMainFormValues,
				payrollVendorAddressId: data?.payrollVendorAddressId?.value,
				contractSagAftraCOED: undefined,
				payrollVendorId: data?.payrollVendorId?.value,
				returnToEmail: data?.returnToEmail?.value,
				performerId: data?.performerId?.value,
				advertiserId: data?.advertiserId?.value,
				talentAgencyStaffId: data?.talentAgencyStaffId?.value,
				producerCompanyId: data?.producerCompanyId?.value,
				performerAddressId: data?.performerAddressId?.value,
				agentId: data?.agentId?.value,
				agentAddressId: data?.agentAddressId?.value,
				adAgencyId:data?.adAgencyId?.value,
				producerAddressId: data?.producerAddressId?.value,
				expirationDate: contractExpiryDate,
				status: isExpired,
			};
			
			if (!props?.isEdit){
				addContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			}
			else {
				editContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			} 
		}
	};

	const handleCustomErrorLogic = () => {
		// let error = false;
		// const setError = (property, message: string) => contractForm.setError(property, { message, type: 'required' });		
		// if(!(contractForm.watch('isPointOfPurchaseCategory') || contractForm.watch('isIndustrialEducationalCategory'))){
		// 	setError('isPointOfPurchaseCategory', 'Please select at least one Category.');			
		// 	error = true;
		// }
		// else {
		// 	contractForm.clearErrors('isPointOfPurchaseCategory');
		// 	error= false;
		// }
		// return error;
	};

	const onSubmitError = (data) => {
		scroll(data);
		handleCustomErrorLogic();
		notification.notify({
			message: 'Please fill all required fields',
			type: 'error',
		});
	};

	const onSubmit = (data: IContractSagAftraCOEDForm) => {	
		if(!props?.mainForm?.watch('contractDisplayName') && !props?.isEdit){
			notification.notify({
				message: 'Please fill Contract Display Name.',
				type: 'error'
			});
		}
		// if(handleCustomErrorLogic()) return;
		handleContractSubmission(data);
	};

	useEffect(() => {
		if(props?.mainForm){
			contractForm.setValue('contractDisplayName', props?.mainForm?.watch('contractDisplayName') || '', { shouldDirty: true});
		}
	}, [props?.mainForm?.watch('contractDisplayName')]);

	const { mutate: addMultipleContractDetails} =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addMultipleContract(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					const baseURL = getBaseUrl(window.location.href);
					const contractIds : any = res?.data || [];
					const urls =  contractIds.map((contract) => {
						return `${baseURL}/#/contracts/${contract}/contract-view?isEdit=true`;
					}) || [];

					urls.forEach((url) => {
						const a = document.createElement('a');	
						a.href = url;
						a.target = '_blank';
						const evt = document.createEvent('MouseEvents');
						evt.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
						a.dispatchEvent(evt);
					});
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
				} else
					notification.notify({
						message:
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const [contractFormData, setContractFormData] = useState<IContractSagAftraCOEDForm>();

	const [noOfTalentsSelected, setNoOfTalentsSelected] = useState(false);

	const handleSaveAndDuplicate = (data: IContractSagAftraCOEDForm) => {
		setNoOfTalentsSelected(true);
		setContractFormData(data);
	};

	const handleTalentDetailsForm = (data: any) => {
		setNoOfTalentsSelected(false);
		const handleMultipleContractAdd = (payloadData) => {
			if(data) {
				const contractAddModels = data?.talentRecords?.map((talent, index) => {
					const submitData = {
						...payloadData,
						id: undefined,
						status:ContractStatus?.Draft,
						partName: talent?.role,
						agentId: talent?.agent?.value,
						performerId: talent?.talentName?.value,
						contractDisplayName: `${payloadData?.contractDisplayName}_copy_${index+1}`,
						compensation: talent?.compensation,
						performerTypes:talent?.perfCategory?.toUpperCase(),
						performerAddressId:undefined,
						performerEmail:undefined,
						talentAgencyStaffId:undefined
					};
					return submitData;
				});
				const multipleContractData = {
					contractAddSagAftraCOEDModels: contractAddModels
				};
				addMultipleContractDetails(multipleContractData);
			}
		};
		contractFormData && handleContractSubmission(contractFormData, handleMultipleContractAdd);
	};

	const [staffOptions, setStaffOptions] = useState([] as any);
	useEffect(() => {
		if(contractForm.watch('agentId')){
			const getStaffOptions = (filter: any) => {
				getStaffListByCompany(filter, contractForm.watch('agentId')?.value).then((res) => {
					const options: any = res?.data?.records?.map((staff: any) => {
						return {value: staff?.id, label: getPersonFullName(staff?.personName)};
					}) ?? [];
					setStaffOptions(options);
				});
			};
			getStaffOptions({
				pageNumber: 0,
				pageSize: DefaultPageSize,
				sortDescending: true,
				sortField: 'createdAt',
			});
		}
	}, [contractForm.watch('agentId')]);

	const getPerformerSSN = () =>{
		const performerSsn = personOptions?.find((a) => a?.id === contractForm.watch('performerId.value'))?.ssn || '';
		if (performerSsn) {
			return `***-**-${performerSsn.slice(-4)}`;
		}
		return '';
	};

	return (
		<>
			<div className={props?.isPrint ? styles.formWrapperPreview : ''}>
				<form onSubmit={contractForm.handleSubmit(onSubmit, onSubmitError)}>
					<div className={styles.packetTemplate} id='form'>
						<Grid item container columnSpacing={3}>
							{contractFormState?.isPacketOpen && (
								<Grid item sm={12} md={4} lg={3}>
									<div className={styles.snippetSection}>
										<Grid item container columnSpacing={3}>
											<Grid item md={12}>
												<h3 className={styles.pocketTitle}> Government & Payroll forms</h3>
											</Grid>
										</Grid>
										<div className={styles.contractFormBlockSnippet}>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW4shortForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW4shortForm');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW4shortForm');
																		}}
																	/>{' '}
																	<span>W4- short version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW8BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW8BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW8BenShort');
																		}}
																	/>
																	<span>W8 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW9BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW9BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW9BenShort');
																		}}
																	/>
																	<span>W9 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isMinorTrust'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isMinorTrust');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isMinorTrust');
																		}}
																	/>
																	<span>Minor Trust Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isNYTheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isNYTheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isNYTheftPreventionForm'
																			);
																		}}
																	/>
																	<span>NY Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isCATheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isCATheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isCATheftPreventionForm'
																			);
																		}}
																	/>
																	<span>CA Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
										</div>
									</div>
								</Grid>
							)}
							<Grid
								item
								sm={12}
								md={4}
								lg={!contractFormState?.isPacketOpen ? 12 : 9}
							>
								<div
									className={
										!contractFormState?.isPacketOpen ? undefined : styles.scrollFIle
									}
								>
									<div className={styles.contractFormBlockPacket} id='contract-form-block-packet'>
										<div className={styles.contractFormBlock}>
											{props?.isEdit && (
												<div className={styles.formFiledsBlock}>
													<Grid container item columnSpacing={3}>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>Contract Name<sup>*</sup></FormLabel>
																<Controller
																	control={contractForm.control}
																	name='contractDisplayName'
																	render={({ field }) => (
																		<>
																			<NabuTextbox 
																				{...field}
																				placeholder={!props?.isPrint ? 'Contract Name' : ''}
																			/>
																		</>
																	)}
																/>
																{contractForm.formState?.errors?.contractDisplayName
																	?.message && (
																	<FormHelperText error={true}>
																		{
																			contractForm.formState.errors
																				?.contractDisplayName?.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Payroll Vendor <sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='payrollVendorId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				{...field}
																				placeholder={!props?.isPrint ? 'Payroll Vendor' : ''}
																				filterProperty='CompanyNames.Name'
																				APICaller={getCompanyBasicDetails}
																				optionGenerator={companyOptionGenerator}
																				defaultFilter={VENDOR_FILTER}
																				isClearable={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																				}}
																				cacheOptions={false}
																				defaultOptions
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																			/>
																		</>
																	)}
																/>
																{contractForm.formState.errors?.payrollVendorId?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors?.payrollVendorId.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Payroll Vendor Address
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	key={`payroll-address-${contractForm.watch(
																		'payrollVendorAddressId.value'
																	)}`}
																	name='payrollVendorAddressId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				{...field}
																				placeholder={'Select Address'}
																				key={`payroll-vendor-address-${contractForm.watch(
																					'payrollVendorAddressId.value'
																				)}-${contractForm.watch('payrollVendorId.value')}`}
																				filterProperty='Line1'
																				APICaller={(filterModel) =>
																					getAllAddressByCompany(
																						filterModel,
																						contractForm.watch('payrollVendorId.value') || ''
																					)
																				}
																				optionGenerator={
																					companyAddressOptionGenerator
																				}
																				isDisabled={!contractForm.watch('payrollVendorId.value')}
																				dropDownsize={50}
																				isClearable={true}
																				isSearchText={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																					contractForm.trigger('payrollVendorAddressId.value');
																				}}
																				cacheOptions={false}
																				defaultOptions
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																			/>
																			{contractForm.watch('payrollVendorId.value') && <div style={{float:'right'}}>
																				<Box sx={{mt:0.9}}>
																					<AddCompanyAddressInline
																						companyId={contractForm.watch('payrollVendorId.value')}
																						title='ADDRESS'
																						isOpen={addressPopOver?.isOpen}
																						event={addressPopOver?.event}
																						onAddressCreated={(d) => onPayrollVendorAddressCreated(d)}
																						onClose={() => setAddressPopOver({ isOpen: false })}
																						onPopOverOpen={(e) => setAddressPopOver({
																							isOpen: true,
																							event: e?.currentTarget,
																						})}
																						isPersistent={true} />
																				</Box>
																			</div>}
																		</>
																	
																	)}
																/>
																{contractForm.formState.errors?.payrollVendorAddressId?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors
																				?.payrollVendorAddressId.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Status
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='status'
																	render={({ field }) => (
																		<>
																			<NabuSelect
																				{...field}
																				placeholder={!props?.isPrint ? 'Select Status' : ''}
																				options={ContractStatusOptions || []}
																				convertValueToUpperCase={false}
																				onChange={(e) => field.onChange(e)}
																			/>
																		</>
																	)}
																/>
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Union
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='unionType'
																	defaultValue={undefined}
																	render={({ field }) => (
																		<NabuSelect
																			{...field}
																			enableEmptySelection={true}
																			placeholder='Select Union'
																			options={getUnions()}
																		/>
																	)}
																/>
																{contractForm.formState?.errors?.unionType?.message && (
																	<FormHelperText error={true}>
																		{contractForm.formState.errors.unionType.message}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														{getUnionAgreement(contractForm.watch('unionType'))?.length > 0 && (
															<Grid item sm={12} md={6} lg={2.4}>
																<FormControl fullWidth>
																	<FormLabel>
											Union Agreement <sup />
																	</FormLabel>
																	<Controller
																		control={contractForm.control}
																		name='unionAgreement'
																		defaultValue=''
																		render={({ field }) => (
																			<NabuSelect
																				{...field}
																				placeholder='Select Agreement'
																				options={getUnionAgreement(
																					contractForm.watch('unionType')
																				)}
																			/>
																		)}
																	/>
																	{contractForm.formState?.errors?.unionAgreement?.message && (
																		<FormHelperText error={true}>
																			{contractForm.formState.errors.unionAgreement.message}
																		</FormHelperText>
																	)}
																</FormControl>
															</Grid>
														)}
													</Grid>
												</div>
											)}
											{/* Main Contract Form Start */}
											<>
												<div className={styles.contractFormBlock}>
													<div className={styles.bgWhite} style={{padding:'20px'}}>
														<tr>
															<h4>{contractForm.watch('contractId') ? `#${contractForm.watch('contractId')}` : ''}</h4>
														</tr>
														<h2 style={{textAlign:'center'}}>SAG AFTRA</h2>
														<h4 style={{textAlign:'center'}}>CORPORATE/EDUCATIONAL & NON-BROADCAST CONTRACT<br></br>
                STANDARD EMPLOYMENT CONTRACT
														</h4>
														<p style={{textAlign:'center'}}><b><em>Artist Cannot Waive Any Portion of the Union Contract Without Prior Consent of SAG-AFTRA</em></b></p>
														<br></br>
														<hr></hr>
														<div style={{display:'flex', alignItems:'center', flexWrap:'wrap', textAlign:'left', gap:'10px'}}>
															<span>This Agreement made this </span> 
															{props?.isPrint ? (
																<span>{contractForm.watch('producerDate') &&format(new Date(contractForm.watch('producerDate')?.toString() || ''),' EEEE do \'of\' MMMM yyyy ')}</span>
															) :(<Controller
																control={contractForm.control}
																name='producerDate'
																render={({ field }) => (
																	<NabuDatepicker
																		{...field}
																		disabled={props?.isPrint}
																	/>
																)}
															/>)}
															{/* <span>{contractForm.watch('producerDate') &&  format(new Date(contractForm.watch('producerDate')?.toString() || ''), 'EEEE do \'of\' MMMM yyyy')}</span>,{' '} */}
              between 
															<FormControl className={styles.tableForms} sx={{minWidth:'200px'}}>
																{/* <Controller
																	control={contractForm.control}
																	name='producerCompanyId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				{...field}
																				placeholder={!props?.isPrint ? 'Producer' : ''}
																				filterProperty='CompanyNames.Name'
																				APICaller={getProducerCompanyForContract}
																				optionGenerator={companyOptionGenerator}
																				isDisabled={props?.isPrint}
																				// defaultFilter={PROD_COM_FILTER}
																				isClearable={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																					contractForm.setValue('producerAddressId', null, { shouldDirty: true});
																					contractForm.trigger('producerAddressId');
																					contractForm.setValue('producerAddressZip','',{ shouldDirty: true});
																					contractForm.setValue('producerAddressState','',{ shouldDirty: true});
																					contractForm.setValue('producerAddressCity','',{ shouldDirty: true});
																					contractForm.setValue('producerName', contractForm.watch('producerCompanyId.label'),{ shouldDirty: true });
																				}}
																				cacheOptions
																				defaultOptions
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																			/>
																			{/* {!props?.isPrint && (
																				<div style={{marginTop:'4px', marginBottom:'-17px'}}>
																					<AddCompanyInline
																						title='Company'
																					/>
																				</div>
																			)} 
																		</>
																	)}
																/> */}
																<Controller
																	control={contractForm.control}
																	name='producerCompany'
																	defaultValue=''
																	render={({ field }) => (
																		<NabuTextbox
																			{...field}
																			disabled={props?.isPrint}
																			placeholder={!props?.isPrint ? 'Producer' : ''}
																		/>
																	)}
																/>
																{/* {contractForm.formState.errors?.producerCompanyId
																	?.value?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper-producerCompanyId'
																	>
																		{
																			contractForm.formState?.errors
																				?.producerCompanyId.value.message
																		}
																	</FormHelperText>
																)} */}
															</FormControl>
            (Signatory Producer)<sup></sup>,and 
															<FormControl
																sx={{minWidth:'230px'}}
																className={styles.tableForms}
															>
																<Controller
																	control={contractForm.control}
																	name='performerId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				data-cy='name_select'
																				{...field}
																				key={`performer-name
																												-${contractForm.watch('performerId.value')}
																												-${contractForm.watch('performerPhone')}-${contractForm.watch('performerEmail')}`}
																				isClearable={true}
																				placeholder={!props?.isPrint ? 'Performer' : ''}
																				filterProperty='PersonName.FirstName'
																				APICaller={(
																					filterModel,
																					searchText
																				) =>
																					GetByWithFullNameSearchForBasicDetail(
																						filterModel,
																						searchText
																					)
																				}
																				isSearchText={true}
																				isDisabled={props?.isPrint}
																				optionGenerator={
																					performerOptionGenerator
																				}
																				defaultFilter={getPerformerFilter()}
																				onChange={(e: any) => {
																					field.onChange(e);
																					contractForm.setValue('performerPhone', '', { shouldDirty: true });
																					contractForm.setValue('performerEmail', '', { shouldDirty: true });
																					contractForm.setValue('performerZip', '', { shouldDirty: true });
																					contractForm.setValue('performerState', '', { shouldDirty: true });
																					contractForm.setValue('performerCity', '', { shouldDirty: true });
																					contractForm.setValue('talentAgencyStaffId', null, { shouldDirty: true });
																					contractForm.setValue('agentId', undefined, { shouldDirty: true });
																					contractForm.trigger('performerPhone');
																					contractForm.trigger('performerEmail');
																					contractForm.setValue('performerAddressId', null, { shouldDirty: true });
																				}}
																				cacheOptions={false}
																				defaultOptions
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																			/>
																			{!props?.isPrint && (
																				<div style={{marginTop:'-7px', marginBottom:'-17px'}}>
																				
																					<AddPersonInline
																						title='Person'
																						onPersonCreated={() => ({})}
																						initialValues={{personType: ['TALENT'], isMinor: false}}
																					/>
																				
																				</div>
																			)}
																		</>
																	)}
																/>																							
																{contractForm.formState?.errors?.performerId?.message && (
																	<FormHelperText
																		data-cy='errorHelper_first_session_date'
																		error={true}
																	>
																		{
																			contractForm.formState?.errors
																				?.performerId?.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
              (Performer)<sup>*</sup>. 
														</div>
														<br></br>
														<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
															<tr>
																<td width={25}>
																	<b>1</b>
																</td>
																<td colSpan={3}>
																	<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																		<b>SERVICES.</b>  Producer engages Performer and Performer agrees to perform services portraying the role of 
																		<FormControl
																			className={styles.tableForms}
																			style={{ width: '250px' }}
																		>
																			<Controller
																				control={contractForm.control}
																				name='partName'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'Role' : ''}
																					/>
																				)}
																			/>
																			{/* {contractForm.formState.errors?.partName
													?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState?.errors?.partName
																.message
														}
													</FormHelperText>
												)} */}
																		</FormControl>
in a program tentatively entitled 
																		<FormControl
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='tentativeProgramTitle'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'Program Title' : ''}
																					/>
																				)}
																			/>
																			{/* {contractForm.formState.errors?.tentativeProgramTitle
													?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState?.errors?.tentativeProgramTitle
																.message
														}
													</FormHelperText>
												)} */}
																		</FormControl>
  on behalf of 
																		<FormControl
																			className={styles.tableForms}
																			sx={{minWidth:'200px'}} 
																			// style={{ width: '280px' }}
																		>
																			<Controller
																				control={contractForm.control}
																				name='advertiserId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							{...field}
																							placeholder={!props?.isPrint ? 'Advertiser' : ''}
																							key={`advertiser-${contractForm.watch('advertiserId.value')}`}
																							filterProperty='CompanyNames.Name'
																							APICaller={getCompanyBasicDetails}
																							optionGenerator={companyOptionGenerator}
																							isDisabled={props?.isPrint}
																							isClearable={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																								// setJPCAuthorizer();
																							}}
																							cacheOptions={false}
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						<Box sx={{mt:0.5, height:'15px'}}>
																							{!props?.isPrint && (
																					
																								<AddCompanyInline
																									title='Company'
																									companyType={{ mainType: 'ADVERTISER' as CompanyMainType, subType: [] }} />
																					
																							)}
																						</Box>
																					</>
																				)}
																			/>
																			{contractForm.formState.errors?.advertiserId?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState.errors?.advertiserId?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>{' '}
(Client).
Services to be performed in 
																		<FormControl
																			className={styles.tableForms}
																			sx={{minWidth:'100px'}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='serviceState'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuSelect
																						{...field}
																						options={getStateOptions()}
																						disabled={props?.isPrint}
																						convertValueToUpperCase={false}
																						placeholder={!props?.isPrint ? 'Select State' : ''}
																					/>
																				)}
																			/>
																			{/* {contractForm?.formState?.errors?.serviceState
													?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState?.errors?.serviceState
																.message
														}
													</FormHelperText>
												)} */}
																		</FormControl>
                      State
																		<FormControl
																			className={styles.tableForms}
																			sx={{minWidth:'100px'}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='serviceCity'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuSelect
																						{...field}
																						disabled={props?.isPrint}
																						options={getCityOptions(
																							contractForm.watch('serviceState')
																						)}
																						convertValueToUpperCase={true}
																						placeholder={!props?.isPrint ? 'Select City' : ''}
																					/>
																				)}
																			/>
																			{/* {contractForm.formState?.errors?.serviceCity
													?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState?.errors?.serviceCity
																.message
														}
													</FormHelperText>
												)} */}
																		</FormControl>
                      City.
																	</div>
																</td>
															</tr>
															<tr>
																<td width={25}>
																	<b>2</b>
																</td>
																<td colSpan={3}>
																	<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																		<b>CATEGORY.</b> Indicate the initial, primary use of the program. 
																		<FormControl className={styles.tableForms}>
																			<Controller
																				control={contractForm.control}
																				name='category'
																				defaultValue=''
																				render={({ field }) => (
																					<>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								type='checkbox'
																								checked={field.value === 'category1'}
																								onChange={() => field.onChange(field.value === 'category1' ? '' : 'category1')}
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span> Cat. I (Educational/Training)</span>
																						</Box>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								type='checkbox'
																								checked={field.value === 'category2'}
																								onChange={() => field.onChange(field.value === 'category2' ? '' : 'category2')}
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span> Cat. II (Includes Category I)</span>
																						</Box>
																					</>
																				)}
																			/>
																		</FormControl>

																	</div>
																</td>
															</tr>
															<tr>
																<td width={25}>
																	<b>3</b>
																</td>
																<td colSpan={3}>
																	<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																		<b>NUMBER OF CLIENTS. </b>Indicate the number of clients for which the program will be used.
																		{/* <Box display={'flex'} alignItems={'flex-start'}>
												<input type='checkbox' style={{ marginTop: '3px', }}/>
												<span> Single Client</span>
											</Box> */}
																		<FormControl className={styles.tableForms}>
																			<Controller
																				control={contractForm.control}
																				name='singleClient'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => {
																							(!props?.isPrint) && field.onChange(!field.value);
																							// handleCustomErrorLogic();
																						}}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => {
																									field.onChange(e);
																									// handleCustomErrorLogic();
																								}}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span> Single Client</span>{' '}
																						</Box>
																					</div>
																				)}
																			/>
																		</FormControl>
																		{/* <Box display={'flex'} alignItems={'flex-start'}>
												<input type='checkbox' style={{ marginTop: '3px', }}/>
												<span> Multiple Clients</span>
											</Box> */}
																		<FormControl className={styles.tableForms}>
																			<Controller
																				control={contractForm.control}
																				name='multipleClients'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() => {
																							(!props?.isPrint) && field.onChange(!field.value);
																							// handleCustomErrorLogic();
																						}}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => {
																									field.onChange(e);
																									// handleCustomErrorLogic();
																								}}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span> Multiple Clients</span>{' '}
																						</Box>
																					</div>
																				)}
																			/>
																		</FormControl>
																	</div>
																</td>
															</tr>
															<tr>
																<td width={25}>
																	<b>4</b>
																</td>
																<td colSpan={3}>
																	<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																		<b>TERM</b>. Continuous period commencing
																		{props?.isPrint ? (
																			<p>{contractForm.watch('termDate') &&  format(new Date(contractForm.watch('termDate')?.toString() || ''), 'EEEE do \'of\' MMMM yyyy')}</p>
																		) :(<Controller
																			control={contractForm.control}
																			name='termDate'
																			render={({ field }) => (
																				<NabuDatepicker
																					{...field}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/>)}
																		{/* <span>{contractForm.watch('termDate') &&  format(new Date(contractForm.watch('termDate')?.toString() || ''), 'EEEE do \'of\' MMMM yyyy')}</span>{' '}  */}
                  , and continuing until completion of the role. 
																		<b>Exception</b> (for Day
Performers Only): May be dismissed and recalled (without payment for intervening period) provided Performer is given a firm recall date
at time of engagement. If applicable, Performer`&apos;`s firm recall date is 
																		{props?.isPrint ? (
																			<p>{contractForm.watch('termDateExtends') &&  format(new Date(contractForm.watch('termDateExtends')?.toString() || ''), 'EEEE do \'of\' MMMM yyyy')}</p>
																		) :(<Controller
																			control={contractForm.control}
																			name='termDateExtends'
																			render={({ field }) => (
																				<NabuDatepicker
																					{...field}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/>)}
																		{/* <span>{contractForm.watch('termDateExtends') &&  format(new Date(contractForm.watch('termDateExtends')?.toString() || ''), 'EEEE do \'of\' MMMM yyyy')}</span>{' '} */}
																	</div>
																</td>
															</tr>
															<tr>
																<td width={25}>
																	<b>5</b>
																</td>
																<td colSpan={3}>
																	<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																		<b>COMPENSATION. Producer employs Performer as:</b>
																		<div
																			style={{
																				display: 'flex',
																				alignItems: 'center',
																				gap: '8px',
																				flexWrap: 'wrap',
																			}}
																		>
																			{/* <span>
																. COMPENSATION. Producer employs Performer as
																Classification
												</span> */}
																			<Controller
																				control={contractForm.control}
																				name='classification'
																				defaultValue={'OFF-CAMERA'}
																				render={({ field }) => (
																					<RadioGroup
																						className={styles.radioGroupPrint}
																						row
																						{...field}
																					>
																						<FormControlLabel
																							value={'ON-CAMERA'}
																							control={<Radio data-cy=' On-Camera' />}
																							label=' On-Camera'
																							disabled={props?.isPrint}
																						/>
																						<FormControlLabel
																							value={'OFF-CAMERA'}
																							control={<Radio data-cy='On-Camera' />}
																							label='Off-Camera'
																							disabled={props?.isPrint}
																						/>
																						<FormControlLabel
																							value={'ON-CAMERA-NARRATOR/SPOKESPERSON'}
																							control={<Radio data-cy='On-Camera' />}
																							label='On-Camera Narrator/Spokesperson'
																							disabled={props?.isPrint}
																						/>
																					</RadioGroup>
																				)}
																			/>															
																		</div>
																		<table
																			width={'100%'}
																			cellPadding={0}
																			cellSpacing={0}
																			className={styles.contractFormsInner}
																		>
																			<tr>
																				<td>
																					<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																						<Controller
																							control={contractForm.control}
																							name='performerTypes'
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('DAY_PERFORMER')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='DAY_PERFORMER'
																											checked={field.value === 'DAY_PERFORMER'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{ marginTop: '3px' }}
																										/>
																										<span>Day Performer</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																				<td>
																					<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																						<Controller
																							control={contractForm.control}
																							name='performerTypes'
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('HALF_DAY_PERFORMER')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='HALF_DAY_PERFORMER'
																											checked={field.value === 'HALF_DAY_PERFORMER'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{ marginTop: '3px' }}
																										/>
																										<span>1/2 Day Performer (Restricted Terms)</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																				<td>
																					<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																						<Controller
																							control={contractForm.control}
																							name='performerTypes'
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('SINGER_SOLO_DUO')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='SINGER_SOLO_DUO'
																											checked={field.value === 'SINGER_SOLO_DUO'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{ marginTop: '3px' }}
																										/>
																										<span>Singer, Solo/Duo</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																				<td>
																					<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																						<Controller
																							control={contractForm.control}
																							name='performerTypes'
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('GENERAL_BACKGROUND_ACTOR')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='GENERAL_BACKGROUND_ACTOR'
																											checked={field.value === 'GENERAL_BACKGROUND_ACTOR'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{ marginTop: '3px' }}
																										/>
																										<span>General Background Actor</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td>
																					<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																						<Controller
																							control={contractForm.control}
																							name='performerTypes'
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('THREE_DAY_PERFORMER')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='THREE_DAY_PERFORMER'
																											checked={field.value === 'THREE_DAY_PERFORMER'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{ marginTop: '3px' }}
																										/>
																										<span>3-Day Performer</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																				<td>
																					<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																						<Controller
																							control={contractForm.control}
																							name='performerTypes'
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('DANCER_SOLO_OR_DUO')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='DANCER_SOLO_OR_DUO'
																											checked={field.value === 'DANCER_SOLO_OR_DUO'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{ marginTop: '3px' }}
																										/>
																										<span>Dancer, Solo/Duo</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																				<td>
																					<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																						<Controller
																							control={contractForm.control}
																							name='performerTypes'
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('SINGER_GROUP')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='SINGER_GROUP'
																											checked={field.value === 'SINGER_GROUP'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{ marginTop: '3px' }}
																										/>
																										<span>Singer, Group</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																				<td>
																					<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																						<Controller
																							control={contractForm.control}
																							name='performerTypes'
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('SPECIAL_ABILITY_BACKGROUND_ACTOR')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='SPECIAL_ABILITY_BACKGROUND_ACTOR'
																											checked={field.value === 'SPECIAL_ABILITY_BACKGROUND_ACTOR'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{ marginTop: '3px' }}
																										/>
																										<span>Special Ability Background Actor</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td>
																					<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																						<Controller
																							control={contractForm.control}
																							name='performerTypes'
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('WEEKLY_PERFORMER')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='WEEKLY_PERFORMER'
																											checked={field.value === 'WEEKLY_PERFORMER'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{ marginTop: '3px' }}
																										/>
																										<span>Weekly Performer</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																				<td>
																					<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																						<Controller
																							control={contractForm.control}
																							name='performerTypes'
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('DANCER_GROUP')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='DANCER_GROUP'
																											checked={field.value === 'DANCER_GROUP'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{ marginTop: '3px' }}
																										/>
																										<span>Dancer, Group</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																				<td>
																					<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																						<Controller
																							control={contractForm.control}
																							name='performerTypes'
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('STEP_OUT_SINGER')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='STEP_OUT_SINGER'
																											checked={field.value === 'STEP_OUT_SINGER'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{ marginTop: '3px' }}
																										/>
																										<span>Singer, Step Out</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																				<td>
																					<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																						<Controller
																							control={contractForm.control}
																							name='performerTypes'
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('SILENT_BIT_BACKGROUND_ACTOR')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='SILENT_BIT_BACKGROUND_ACTOR'
																											checked={field.value === 'SILENT_BIT_BACKGROUND_ACTOR'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{ marginTop: '3px' }}
																										/>
																										<span>Silent Bit Background Actor</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																			</tr>
																		</table>

																		<div style={{display:'block'}}>
																			<br></br>
																			<Box><b>At the salary of:</b></Box>
																			<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																				{/* On-Camera $ */}
																				<span>On-Camera $</span>{' '} 
																				<FormControl
																					className={styles.tableForms}
																					style={{ width: '200px' }}
																				>
																					<Controller
																						control={contractForm.control}
																						name='onCameraSalary'
																						render={({ field }) => (
																							<NabuTextbox
																								{...field}
																								disabled={props?.isPrint}
																								placeholder={!props?.isPrint ? 'On Camera Salary' : ''}
																								onChange={(e: any) => {
																									const value = e?.target?.value || '';
																									if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																										field.onChange(value);
																									} else {
																										const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																										field.onChange(num);
																									}
																								}}
																								onBlur={() => {
																									const numericValue = parseFloat((field.value ?? '0').toString());
																									if (!isNaN(numericValue)) {
																										field.onChange(numericValue.toFixed(2));
																									} else {
																										field.onChange('0.00');
																									}
																								}}
																							/>
																						)}
																					/>
																					{/* {contractForm.formState.errors?.onCameraSalary
															?.message && (
															<FormHelperText error={true}>
																{
																	contractForm.formState?.errors
																		?.onCameraSalary.message
																}
															</FormHelperText>
														)} */}
																				</FormControl>{' '}
																				{/* per
													<Box display={'flex'} alignItems={'flex-start'}>
														<input type='checkbox' style={{ marginTop: '3px', }}/>
														<span> Day </span>
													</Box>
													<Box display={'flex'} alignItems={'flex-start'}>
														<input type='checkbox' style={{ marginTop: '3px', }}/>
														<span>3-Days </span>
													</Box>
													<Box display={'flex'} alignItems={'flex-start'}>
														<input type='checkbox' style={{ marginTop: '3px', }}/>
														<span>Week </span>
													</Box> */}
																				<span>per</span>{' '}
																				<Controller
																					control={contractForm.control}
																					name='salaryPayPeriod'
																					defaultValue={'DAY'}
																					render={({ field }) => (
																						<RadioGroup row {...field} className={styles.radioGroupPrint}>
																							<FormControlLabel
																								value={'DAY'}
																								control={<Radio />}
																								label='Day'
																								disabled={props?.isPrint}
																							/>
																							<FormControlLabel
																								value={'3-DAY'}
																								control={<Radio />}
																								label='3-Day'
																								disabled={props?.isPrint}
																							/>
																							<FormControlLabel
																								value={'WEEK'}
																								control={<Radio />}
																								label='Week'
																								disabled={props?.isPrint}
																							/>
																						</RadioGroup>
																					)}
																				/>
																			</div>
																			<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																				<span>Off-Camera</span>{' '}
																				<FormControl
																					className={styles.tableForms}
																					style={{ width: '200px' }}
																				>
																					<Controller
																						control={contractForm.control}
																						name='offCameraSalaryForFirstHour'
																						render={({ field }) => (
																							<NabuTextbox
																								{...field}
																								disabled={props?.isPrint}
																								placeholder={!props?.isPrint ? 'Off Camera Salary' : ''}
																								onChange={(e: any) => {
																									const value = e?.target?.value || '';
																									if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																										field.onChange(value);
																									} else {
																										const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																										field.onChange(num);
																									}
																								}}
																								onBlur={() => {
																									const numericValue = parseFloat((field.value ?? '0').toString());
																									if (!isNaN(numericValue)) {
																										field.onChange(numericValue.toFixed(2));
																									} else {
																										field.onChange('0.00');
																									}
																								}}
																							/>
																						)}
																					/>
																					{/* {contractForm.formState.errors?.offCameraSalaryForFirstHour
															?.message && (
															<FormHelperText error={true}>
																{
																	contractForm.formState?.errors
																		?.offCameraSalaryForFirstHour.message
																}
															</FormHelperText>
														)} */}
																				</FormControl>{' '}
																				<span>for the first hour,</span>{' '}
																				<FormControl className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						name='offCameraSalaryForAdditionalHour'
																						render={({ field }) => (
																							<NabuTextbox
																								{...field}
																								disabled={props?.isPrint}
																								placeholder={!props?.isPrint ? 'Off Camera Salary' : ''}
																								onChange={(e: any) => {
																									const value = e?.target?.value || '';
																									if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																										field.onChange(value);
																									} else {
																										const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																										field.onChange(num);
																									}
																								}}
																								onBlur={() => {
																									const numericValue = parseFloat((field.value ?? '0').toString());
																									if (!isNaN(numericValue)) {
																										field.onChange(numericValue.toFixed(2));
																									} else {
																										field.onChange('0.00');
																									}
																								}}
																							/>
																						)}
																					/>
																					{/* {contractForm.formState.errors?.offCameraSalaryForAdditionalHour
															?.message && (
															<FormHelperText error={true}>
																{
																	contractForm.formState?.errors
																		?.offCameraSalaryForAdditionalHour.message
																}
															</FormHelperText>
														)} */}
																				</FormControl>{' '}
																				<span>for each additional half hour </span>
																			</div>
																			<div>
																				<b><em>Producer must mail payment not later than thirty (30) calendar days following the day(s) of employment. </em></b>
																			</div>
																		</div>
																	</div>
																</td>
															</tr>
															<tr>
																<td width={25}>
																	<b>6</b>
																</td>
																<td colSpan={3}>
																	<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																		<b>WEEKLY CONVERSION RATE.</b> See Section 19.B.5 of the Agreement for details (Day Performers and 3-Day Performers only).
The Performer &apos;s weekly conversion rate is $ 
																		<FormControl
																			className={styles.tableForms}
																			style={{ width: '250px' }}
																		>
																			<Controller
																				control={contractForm.control}
																				name='weeklyConversionRate'
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'Weekly Conversion Rate' : ''}
																						onChange={(e: any) => {
																							const value = e?.target?.value || '';
																							if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																								field.onChange(value);
																							} else {
																								const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																								field.onChange(num);
																							}
																						}}
																						onBlur={() => {
																							const numericValue = parseFloat((field.value ?? '0').toString());
																							if (!isNaN(numericValue)) {
																								field.onChange(numericValue.toFixed(2));
																							} else {
																								field.onChange('0.00');
																							}
																						}}
																					/>
																				)}
																			/>
																			{/* {contractForm.formState.errors?.weeklyConversionRate
													?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState?.errors
																?.weeklyConversionRate.message
														}
													</FormHelperText>
												)} */}
																		</FormControl>{' '}
per week
																	</div>
																</td>
															</tr>
															<tr>
																<td width={25}>
																	<b>7</b>
																</td>
																<td colSpan={3}>
																	<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																		<b>PAYMENT ADDRESS.</b> Performer’s payment shall be sent to:
																		<Controller
																			control={contractForm.control}
																			name='sendToPerformerAddress'
																			defaultValue={false}
																			render={({ field }) => (
																				<div
																					onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																					style={{ userSelect: 'none' }}
																				>
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							{...field}
																							value={field.value + ''}
																							checked={field.value}
																							onChange={(e) => field.onChange(e)}
																							type='checkbox'
																							disabled={props?.isPrint}
																							style={{ marginTop: '3px' }}
																						/>
																						<span>Performer at W4 address</span>
																					</Box>
																				</div>
																			)}
																		/>
																				OR
																		<Controller
																			control={contractForm.control}
																			name='sendToAgentAddress'
																			defaultValue={false}
																			render={({ field }) => (
																				<div
																					onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																					style={{ userSelect: 'none' }}
																				>
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							{...field}
																							value={field.value + ''}
																							checked={field.value}
																							onChange={(e) => field.onChange(e)}
																							type='checkbox'
																							disabled={props?.isPrint}
																							style={{ marginTop: '3px' }}
																						/>
																					</Box>
																				</div>
																			)}
																		/>
																		<span>Sent </span>
																				c/o: 
																		<FormControl
																			className={styles.tableForms}
																			sx={{minWidth:'200px'}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='agentId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							{...field} 
																							key={`agency-${contractForm.watch('performerId.value')}`}
																							placeholder='Agency'
																							filterProperty='CompanyNames.Name'
																							APICaller={(filter) => {
																								filter.filters = filter.filters?.map((f) => f.property == 'CompanyNames.Name' ? {...f, value: f?.value?.trim() || '' } : f) || [];
																								return getAgentInfo(filter, contractForm.watch('performerId.value') || '');
																							}}
																							optionGenerator={companyOptionGenerator}
																							onChange={(e: any) => {
																								field.onChange(e);
																								// contractForm.trigger('producerAddressId');
																								// // setJPCAuthorizer();
																								// contractForm.setValue('agentAddressId', null, { shouldDirty: true});
																								// contractForm.setValue('agentZip', '', { shouldDirty: true});
																								// contractForm.setValue('agentState', '', { shouldDirty: true});
																								// contractForm.setValue('agentCity', '', { shouldDirty: true});
																							}}
																							cacheOptions
																							isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
																							defaultOptions
																							dropDownsize={25}
																							isClearable={true}
																							onOptionLoaded={(options) => {
																								if(options?.length && !contractForm.watch('agentId.value')){
																									contractForm.setValue('agentId', options[0], { shouldDirty: true});
																									contractForm.trigger('agentId', { shouldFocus: true});
																								}
																							}}
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{!props?.isPrint && contractForm.watch('performerId.value') && (<><div style={{float:'right', marginTop:'-7px'}}><AddTalentAgentInline
																							onCompanyCreated={(createdAgent) => contractForm.setValue('agentId', createdAgent, { shouldDirty: true})}
																							onClose={() => setCompanyPopoverInline({ isOpen: false})}
																							onPopOverOpen={(e) => setCompanyPopoverInline({ isOpen: true, event: e?.currentTarget })}
																							event={companyPopoverInline?.event}
																							isOpen={companyPopoverInline.isOpen}
																							personId={contractForm.watch('performerId.value') || ''}
																						/></div></>)}
																					</>
																				)}
																			/>
																			{contractForm.formState.errors?.agentId?.message && (
																				<FormHelperText
																					error={true}
																					data-cy='errorHelper-agentId'
																				>
																					{
																						contractForm.formState?.errors?.agentId.message
																					}
																				</FormHelperText>
																			)} 								
																		</FormControl>
																				Attn: 
																		<FormControl className={styles.tableForms} >
																			<Controller
																				control={contractForm.control}
																				name='talentAgencyStaffId'
																				// disabled={
																				// 	!contractForm.watch(
																				// 		'talentAgencyStaffId.value'
																				// 	) || props?.isPrint
																				// }
																				render={({ field }) => (
																					<Autocomplete
																						data-cy='StaffSelect'
																						id='combo-box-demo'
																						options={staffOptions}
																						{...field}
																						onChange={(e, item :any) => {
																							contractForm.setValue('talentAgencyStaffId', item);
																						}}
																						disabled={
																							props?.isPrint ||
																				!contractForm.watch(
																					'agentId.value'
																				)
																						}
																						// onInputChange={(e: any,item) => {
																						// 	const lookAheadDefaultFilter: IFilterOption = {
																						// 		isCaseSensitive: false,
																						// 		operator: OPERATORS.CONTAINS,
																						// 		property: 'PersonName.FirstName',
																						// 		value: item,
																						// 	};
																						// 	setStaffPage({
																						// 		pageNumber: 0,
																						// 		pageSize: DefaultPageSize,
																						// 		sortDescending: true,
																						// 		sortField: 'PersonName.FirstName',
																						// 		filters: [lookAheadDefaultFilter]
																						// 	});
																						// 	item ? getStaffOptions(staffPage) : getStaffOptions({
																						// 		pageNumber: 0,
																						// 		pageSize: DefaultPageSize,
																						// 		sortDescending: true,
																						// 		sortField: 'PersonName.FirstName',
																						// 		filters: []
																						// 	});
																						// }}
																						sx={{ width: 180 }}
																						renderInput={(params) => <TextField placeholder='Agent Name' data-cy='StaffSelectTextField' {...params} />}
																					/>
																				)}
																			/> 
																		</FormControl>
[Upon SAG-AFTRA’s request, performer’s payment shall be sent to the appropriate SAG-AFTRA office in the city nearest recording site.]

																	</div>
																</td>
															</tr>
															<tr>
																<td width={25}>
																	<b>8</b>
																</td>
																<td colSpan={3}>
																	<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																		<b>ADDITIONAL COMPENSATION FOR SUPPLEMENTAL USE.</b> Producer may acquire the following supplemental use rights by the
payment of the indicated amounts. (Check appropriate items below.) See Section 9 of Agreement for details of payment.
Group Dancers: Supplemental Use for group dancers is capped. See Section 9.B.1.c. of the Contract for payment provisions
																	</div>
																</td>
															</tr>
															<tr>
																<td width={25}>

																</td>
																<td width={25}>
                  
																</td>
																<td width={150}>
																	<b>Paid Within 90 Days<br></br>
																		<u>(% Total Applicable Salary)</u></b>
																</td>
																<td width={150}>
																	<b>  Paid Beyond 90 Days<br></br>
																		<u>(% Total Applicable Salary)</u> </b>
																</td>
															</tr>
															<tr>
																<td width={25}> <FormControl className={styles.tableForms}>
																	<Controller
																		control={contractForm.control}
																		name='isAdditionalCompensation0'
																		defaultValue={false}
																		render={({ field }) => (
																			<div
																				onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																				style={{ userSelect: 'none' }}
																			>
																				<Box display={'flex'} alignItems={'flex-start'}>
																					<input
																						{...field}
																						value={field.value + ''}
																						checked={field.value}
																						onChange={(e) => field.onChange(e)}
																						type='checkbox'
																						disabled={props?.isPrint}
																						style={{ marginTop: '3px' }}
																					/>
																				</Box>
																			</div>
																		)}
																	/>
																</FormControl></td>
																<td width={400}>Category II use of a program originally made as a Category I program</td>
																<td> 50% </td>
																<td> 100% </td>
															</tr>
															<tr>
																<td width={25}> 
																	{/* <Box display={'flex'} alignItems={'flex-start'}><input type='checkbox' style={{ marginTop: '3px', }}/></Box> */}
																	<FormControl className={styles.tableForms}>
																		<Controller
																			control={contractForm.control}
																			name='isAdditionalCompensation1'
																			defaultValue={false}
																			render={({ field }) => (
																				<div
																					onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																					style={{ userSelect: 'none' }}
																				>
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							{...field}
																							value={field.value + ''}
																							checked={field.value}
																							onChange={(e) => field.onChange(e)}
																							type='checkbox'
																							disabled={props?.isPrint}
																							style={{ marginTop: '3px' }}
																						/>
																					</Box>
																				</div>
																			)}
																		/>
																	</FormControl>
																</td>
																<td width={400}>1. Basic Cable Television: 3 years</td>
																<td> 15% </td>
																<td> 65% </td>
															</tr>
															<tr>
																<td width={25}>																				
																	<FormControl className={styles.tableForms}>
																		<Controller
																			control={contractForm.control}
																			name='isAdditionalCompensation2'
																			defaultValue={false}
																			render={({ field }) => (
																				<div
																					onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																					style={{ userSelect: 'none' }}
																				>
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							{...field}
																							value={field.value + ''}
																							checked={field.value}
																							onChange={(e) => field.onChange(e)}
																							type='checkbox'
																							disabled={props?.isPrint}
																							style={{ marginTop: '3px' }}
																						/>
																					</Box>
																				</div>
																			)}
																		/>
																	</FormControl></td>
																<td width={400}>2. Non-Network Television, Unlimited runs</td>
																<td> 75% </td>
																<td> 125% </td>
															</tr>
															<tr>
																<td width={25}> <FormControl className={styles.tableForms}>
																	<Controller
																		control={contractForm.control}
																		name='isAdditionalCompensation3'
																		defaultValue={false}
																		render={({ field }) => (
																			<div
																				onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																				style={{ userSelect: 'none' }}
																			>
																				<Box display={'flex'} alignItems={'flex-start'}>
																					<input
																						{...field}
																						value={field.value + ''}
																						checked={field.value}
																						onChange={(e) => field.onChange(e)}
																						type='checkbox'
																						disabled={props?.isPrint}
																						style={{ marginTop: '3px' }}
																					/>
																				</Box>
																			</div>
																		)}
																	/>
																</FormControl></td>
																<td width={400}>3. Theatrical Exhibition, Unlimited runs</td>
																<td> 100% </td>
																<td> 150% </td>
															</tr>
															<tr>
																<td width={25}> <FormControl className={styles.tableForms}>
																	<Controller
																		control={contractForm.control}
																		name='isAdditionalCompensation4'
																		defaultValue={false}
																		render={({ field }) => (
																			<div
																				onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																				style={{ userSelect: 'none' }}
																			>
																				<Box display={'flex'} alignItems={'flex-start'}>
																					<input
																						{...field}
																						value={field.value + ''}
																						checked={field.value}
																						onChange={(e) => field.onChange(e)}
																						type='checkbox'
																						disabled={props?.isPrint}
																						style={{ marginTop: '3px' }}
																					/>
																				</Box>
																			</div>
																		)}
																	/>
																</FormControl></td>
																<td width={400}>4. Foreign Television, Unlimited runs outside U.S. and Canada</td>
																<td> 25% </td>
																<td> 75% </td>
															</tr>
															<tr>
																<td width={25}> 																			<FormControl className={styles.tableForms}>
																	<Controller
																		control={contractForm.control}
																		name='isAdditionalCompensation5'
																		defaultValue={false}
																		render={({ field }) => (
																			<div
																				onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																				style={{ userSelect: 'none' }}
																			>
																				<Box display={'flex'} alignItems={'flex-start'}>
																					<input
																						{...field}
																						value={field.value + ''}
																						checked={field.value}
																						onChange={(e) => field.onChange(e)}
																						type='checkbox'
																						disabled={props?.isPrint}
																						style={{ marginTop: '3px' }}
																					/>
																				</Box>
																			</div>
																		)}
																	/>
																</FormControl></td>
																<td width={400}>5. Sale and/or Rental Within an Industry</td>
																<td> 15% </td>
																<td> 25% </td>
															</tr>
															<tr>
																<td width={25}> <FormControl className={styles.tableForms}>
																	<Controller
																		control={contractForm.control}
																		name='isAdditionalCompensation6'
																		defaultValue={false}
																		render={({ field }) => (
																			<div
																				onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																				style={{ userSelect: 'none' }}
																			>
																				<Box display={'flex'} alignItems={'flex-start'}>
																					<input
																						{...field}
																						value={field.value + ''}
																						checked={field.value}
																						onChange={(e) => field.onChange(e)}
																						type='checkbox'
																						disabled={props?.isPrint}
																						style={{ marginTop: '3px' }}
																					/>
																				</Box>
																			</div>
																		)}
																	/>
																</FormControl></td>
																<td width={400}>6. Integration and/or Customization</td>
																<td> 100% </td>
																<td> 105% </td>
															</tr>
															<tr>
																<td width={25}> <FormControl className={styles.tableForms}>
																	<Controller
																		control={contractForm.control}
																		name='isAdditionalCompensation7'
																		defaultValue={false}
																		render={({ field }) => (
																			<div
																				onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																				style={{ userSelect: 'none' }}
																			>
																				<Box display={'flex'} alignItems={'flex-start'}>
																					<input
																						{...field}
																						value={field.value + ''}
																						checked={field.value}
																						onChange={(e) => field.onChange(e)}
																						type='checkbox'
																						disabled={props?.isPrint}
																						style={{ marginTop: '3px' }}
																					/>
																				</Box>
																			</div>
																		)}
																	/>
																</FormControl></td>
																<td width={400}>7. `&quot;`Package`&quot;` Rights to 1, 2, 3, 4, 5, and 6 above.</td>
																<td> 200% </td>
																<td> Not available </td>
															</tr>
															<tr>
																<td width={25}><FormControl className={styles.tableForms}>
																	<Controller
																		control={contractForm.control}
																		name='isAdditionalCompensation8'
																		defaultValue={false}
																		render={({ field }) => (
																			<div
																				onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																				style={{ userSelect: 'none' }}
																			>
																				<Box display={'flex'} alignItems={'flex-start'}>
																					<input
																						{...field}
																						value={field.value + ''}
																						checked={field.value}
																						onChange={(e) => field.onChange(e)}
																						type='checkbox'
																						disabled={props?.isPrint}
																						style={{ marginTop: '3px' }}
																					/>
																				</Box>
																			</div>
																		)}
																	/>
																</FormControl></td>
																<td width={400}>8. Network Television*</td>
																<td rowSpan={3} colSpan={2}> 
								*AVAILABLE <b>ONLY</b> BY PRIOR NEGOTIATION
                WITH AND APPROVAL OF SAG-AFTRA </td>
															</tr>
															<tr>
																<td width={25}> 																				<FormControl className={styles.tableForms}>
																	<Controller
																		control={contractForm.control}
																		name='isAdditionalCompensation9'
																		defaultValue={false}
																		render={({ field }) => (
																			<div
																				onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																				style={{ userSelect: 'none' }}
																			>
																				<Box display={'flex'} alignItems={'flex-start'}>
																					<input
																						{...field}
																						value={field.value + ''}
																						checked={field.value}
																						onChange={(e) => field.onChange(e)}
																						type='checkbox'
																						disabled={props?.isPrint}
																						style={{ marginTop: '3px' }}
																					/>
																				</Box>
																			</div>
																		)}
																	/>
																</FormControl></td>
																<td width={400}>9. Pay Cable Television*</td>
															</tr>
															<tr>
																<td width={25}> <FormControl className={styles.tableForms}>
																	<Controller
																		control={contractForm.control}
																		name='isAdditionalCompensation10A'
																		defaultValue={false}
																		render={({ field }) => (
																			<div
																				onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																				style={{ userSelect: 'none' }}
																			>
																				<Box display={'flex'} alignItems={'flex-start'}>
																					<input
																						{...field}
																						value={field.value + ''}
																						checked={field.value}
																						onChange={(e) => field.onChange(e)}
																						type='checkbox'
																						disabled={props?.isPrint}
																						style={{ marginTop: '3px' }}
																					/>
																				</Box>
																			</div>
																		)}
																	/>
																</FormControl></td>
																<td width={400}>10A. Audio Only Programs sold to the general public (Section 9.E.2)*</td>
															</tr>
															<tr>
																<td width={25}> <FormControl className={styles.tableForms}>
																	<Controller
																		control={contractForm.control}
																		name='isAdditionalCompensation10B'
																		defaultValue={false}
																		render={({ field }) => (
																			<div
																				onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																				style={{ userSelect: 'none' }}
																			>
																				<Box display={'flex'} alignItems={'flex-start'}>
																					<input
																						{...field}
																						value={field.value + ''}
																						checked={field.value}
																						onChange={(e) => field.onChange(e)}
																						type='checkbox'
																						disabled={props?.isPrint}
																						style={{ marginTop: '3px' }}
																					/>
																				</Box>
																			</div>
																		)}
																	/>
																</FormControl></td>
																<td width={400}>10B. Sale or Rental to General Public (Over the Counter) - Except Audio Only, see above</td>
																<td colSpan={2}>200% for # of days worked excluding OT & penalties  </td>
															</tr>
															<tr>
																<td width={25}> <FormControl className={styles.tableForms}>
																	<Controller
																		control={contractForm.control}
																		name='isAdditionalCompensation11'
																		defaultValue={false}
																		render={({ field }) => (
																			<div
																				onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																				style={{ userSelect: 'none' }}
																			>
																				<Box display={'flex'} alignItems={'flex-start'}>
																					<input
																						{...field}
																						value={field.value + ''}
																						checked={field.value}
																						onChange={(e) => field.onChange(e)}
																						type='checkbox'
																						disabled={props?.isPrint}
																						style={{ marginTop: '3px' }}
																					/>
																				</Box>
																			</div>
																		)}
																	/>
																</FormControl></td>
																<td width={400}>11. Programs for Government Service (Use: Non-network and foreign TV, theatrical)</td>
																<td width={150}>40%  </td>
																<td width={150}> Not available </td>
															</tr>
															<tr>
																<td width={25}><FormControl className={styles.tableForms}>
																	<Controller
																		control={contractForm.control}
																		name='isAdditionalCompensation12'
																		defaultValue={false}
																		render={({ field }) => (
																			<div
																				onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																				style={{ userSelect: 'none' }}
																			>
																				<Box display={'flex'} alignItems={'flex-start'}>
																					<input
																						{...field}
																						value={field.value + ''}
																						checked={field.value}
																						onChange={(e) => field.onChange(e)}
																						type='checkbox'
																						disabled={props?.isPrint}
																						style={{ marginTop: '3px' }}
																					/>
																				</Box>
																			</div>
																		)}
																	/>
																</FormControl></td>
																<td width={400} colSpan={3}><b>Performer </b>does <b>not</b> consent to the use of his/her services made hereunder for Network Television</td>
															</tr>
															<tr>
																<td width={25}><FormControl className={styles.tableForms}>
																	<Controller
																		control={contractForm.control}
																		name='isAdditionalCompensation13'
																		defaultValue={false}
																		render={({ field }) => (
																			<div
																				onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																				style={{ userSelect: 'none' }}
																			>
																				<Box display={'flex'} alignItems={'flex-start'}>
																					<input
																						{...field}
																						value={field.value + ''}
																						checked={field.value}
																						onChange={(e) => field.onChange(e)}
																						type='checkbox'
																						disabled={props?.isPrint}
																						style={{ marginTop: '3px' }}
																					/>
																				</Box>
																			</div>
																		)}
																	/>
																</FormControl></td>
																<td width={400} colSpan={3}> <b>Performer </b>does <b>not</b> consent to the use of his/her services made hereunder for Pay Cable Television
																</td>
															</tr>
															<tr>
																<td width={25}> <FormControl className={styles.tableForms}>
																	<Controller
																		control={contractForm.control}
																		name='isAdditionalCompensation14'
																		defaultValue={false}
																		render={({ field }) => (
																			<div
																				onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																				style={{ userSelect: 'none' }}
																			>
																				<Box display={'flex'} alignItems={'flex-start'}>
																					<input
																						{...field}
																						value={field.value + ''}
																						checked={field.value}
																						onChange={(e) => field.onChange(e)}
																						type='checkbox'
																						disabled={props?.isPrint}
																						style={{ marginTop: '3px' }}
																					/>
																				</Box>
																			</div>
																		)}
																	/>
																</FormControl></td>
																<td width={400} colSpan={3}> <b>Performer </b>does <b>not</b>  consent to the use of his/her services made hereunder for OTC sales of Audio Only Program </td>
															</tr>
															<tr>
																<td width={25}>
																	<b>9</b>
																</td>
																<td colSpan={3}>
																	<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																		<b>WARDROBE FURNISHED BY PERFORMER.</b> - Fee covers use of wardrobe for: PRINCIPAL – <b>2 days; EXTRA – 1 day.</b>
PRINCIPAL: If required to bring one change and wears it, pay fee; if not worn, no fee is due. If required to bring more than one change, pay fee for each
change even if not worn. EXTRA: Pay fee for each change Extra is required to bring.
																	</div>
																	<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																		<b>Minimum Fees:</b> Day Wear (Principal)/1st Change (Extra): 
																		<FormControl
																			className={styles.tableForms}
																			style={{ maxWidth: '75px' }}
																		>
																			<Controller
																				control={contractForm.control}
																				name='dayWear'
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'Day Wear' : ''}
																						onChange={(e: any) => {
																							const value = e.target.value || '';
																							if (/^\d*$/.test(value)) {
																								field.onChange(value);
																						
																								const numericValue = Number(value);
																								contractForm.setValue('dayWearTotal', numericValue * 19, { shouldDirty: true });
																							}
																						}}																						
																						type='number'
																					/>
																				)}
																			/>
																		</FormControl>  
											x $19 = $ 
																		<FormControl
																			className={styles.tableForms}
																			style={{ maxWidth: '120px' }}
																		>
																			<Controller
																				control={contractForm.control}
																				name='dayWearTotal'
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={true}
																						value={contractForm.watch('dayWearTotal')}
																					/>
																				)}
																			/>
																		</FormControl> ;
											2nd + Change (Extras Only):
																		<FormControl
																			className={styles.tableForms}
																			style={{ maxWidth: '75px' }}
																		>
																			<Controller
																				control={contractForm.control}
																				name='change2nd'
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? '2nd + change' : ''}
																						onChange={(e: any) => {
																							const value = e.target.value || '';
																							if (/^\d*$/.test(value)) {
																								field.onChange(value);
																						
																								const numericValue = Number(value);
																								contractForm.setValue('change2ndTotal', numericValue * 6, { shouldDirty: true });
																							}
																						}}
																						type='number'
																					/>
																				)}
																			/>
																		</FormControl>  
											x $6 =$ 
																		<FormControl
																			className={styles.tableForms}
																			style={{ maxWidth: '75px' }}
																		>
																			<Controller
																				control={contractForm.control}
																				name='change2ndTotal'
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={true}
																						value={contractForm.watch('change2ndTotal')}
																					/>
																				)}
																			/>
																		</FormControl>;

																	</div>
																	<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>

                  Black Tie/Specialty: 
																		<FormControl
																			className={styles.tableForms}
																			style={{ maxWidth: '75px' }}
																		>
																			<Controller
																				control={contractForm.control}
																				name='blackTie'
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'BlackTie/Speciality' : ''}
																						onChange={(e: any) => {
																							const value = e.target.value || '';
																							if (/^\d*$/.test(value)) {
																								field.onChange(value);
																						
																								const numericValue = Number(value);
																								contractForm.setValue('blackTieTotal', numericValue * 29, { shouldDirty: true });
																							}
																						}}
																						type='number'
																					/>
																				)}
																			/>
																		</FormControl>  
											x $29 =$ 
																		<FormControl
																			className={styles.tableForms}
																			style={{ minWidth: '75px' }}
																		>
																			<Controller
																				control={contractForm.control}
																				name='blackTieTotal'
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={true}
																						value={contractForm.watch('blackTieTotal')}
																					/>
																				)}
																			/>
																		</FormControl>  
																	</div>
																</td>
															</tr>
															<tr>
																<td width={25}>
																	<b>10</b>
																</td>
																<td colSpan={3}>
																	<div style={{textAlign:'left'}}>
																		<b>SPECIAL PROVISIONS.</b> 
																		<div className={styles.textareaSection}> 														
																			{
																				props?.isPrint ? (
																					<p className={styles.descriptionPrintText}>{contractForm.watch('specialProvisions')}</p>
																				) : (<div className={styles.textareaSection} > 
																					<Controller
																						control={contractForm.control}
																						name='specialProvisions'
																						render={({ field }) => (
																							<NabuBaseTextArea
																								className={styles.specialProvision}
																								{...field}
																								rows={5}
																								disabled={props?.isPrint}
																								placeholder={!props?.isPrint ? 'SPECIAL PROVISIONS' : ''}
																							/>
																						)}
																					/>
																					{/* {contractForm.formState.errors?.specialProvisions
															?.message && (
															<FormHelperText error={true}>
																{
																	contractForm.formState?.errors
																		?.specialProvisions.message
																}
															</FormHelperText>
														)} */}
																				</div> )
																			}
																		</div>							
																	</div>
									
																</td>
															</tr>
															<tr>
																<td width={25}>
																	<b>11</b>
																</td>
																<td colSpan={3}>
																	<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																		<b>GENERAL.</b>  All terms and conditions of the current SAG-AFTRA Corporate/Educational & Non-Broadcast Contract shall be applicable to such employment.
																	</div>
																</td>
															</tr>
														</table>
														<br></br>
														<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
															<tr>
																<td width={200}>
																	<b>Producer { !props?.isPrint && (
																		<Tooltip
																			placement='right-end'
																			title={'Signatory'}
																		>
																			<HelpOutline fontSize='inherit' />
																		</Tooltip>)
																	} </b>
																</td>
																<td>
																	<FormControl fullWidth className={styles.tableForms}><NabuTextbox placeholder='Signature'/></FormControl> 
																</td>
																<td width={200}>
																	<b>Performer</b>
																</td>
																<td>
																	<FormControl fullWidth className={styles.tableForms}><NabuTextbox placeholder="Signature (if minor, parent's or guardian's signature)"/></FormControl> 
																</td>
															</tr>
															<tr>
																<td width={200}>
																	<b>by</b>
																</td>
																<td>
																	<FormControl fullWidth className={styles.tableForms}>
																		{/* <Controller
																			key={`producer-name-${contractForm.watch(
																				'producerCompanyId.value'
																			)}`}
																			control={contractForm.control}
																			name='producerName'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					key={`producer-name-${contractForm.watch(
																						'producerCompanyId.value'
																					)}`}
																					{...field}
																					placeholder={!props?.isPrint ? 'Name and Title' : ''}
																					disabled={true}
																				/>
																			)}
																		/> */}
																		<Controller
																			control={contractForm.control}
																			name='producerCompany'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={!props?.isPrint ? 'Producer' : ''}
																				/>
																			)}
																		/>
																	</FormControl>
																</td>
																<td width={200}>
																	<b>Soc. Sec. #</b>
																</td>
																<td>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<Controller
																			control={contractForm.control}
																			name='ssn'
																			render={({ field }) => (
																				<>
																					<input
																						{...field}
																						placeholder={!props?.isPrint ? 'SSN' : ''}
																						disabled={props?.isPrint}
																						value={getPerformerSSN()}
																					/>
																				</>
																			)}
																		/>
																	</FormControl>  
																</td>
															</tr>
															<tr>
																<td width={200}>
																	<b>Address</b>
																</td>
																<td>
																	<FormControl className={styles.tableForms} fullWidth>
																		{/* <Controller
																			control={contractForm.control}
																			key={`producer-address-cr-${contractForm.watch(
																				'producerCompanyId.value'
																			)}`}
																			name='producerAddressId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						{...field}
																						placeholder={!props?.isPrint ? 'Select Address' : ''}
																						key={`producer-address-${contractForm.watch(
																							'producerCompanyId.value'
																						)}-${contractForm.watch('producerAddressId.value')}`}
																						filterProperty='Line1'
																						sortField='IsPrimary'
																						sortDescending={true}
																						onOptionLoaded={(options :any) => {
																							if(options?.length && !contractForm.watch('producerAddressId.value')){
																								contractForm.setValue('producerAddressId', options[0], { shouldDirty: true});
																								contractForm.trigger('producerAddressId', { shouldFocus: true});
																								onAddressChange(contractForm.watch('producerAddressId.value') || '', 'producerAddress');
																							}
																						}}
																						APICaller={(filterModel) =>
																							getAllAddressByCompany(
																								filterModel,
																								contractForm.watch(
																									'producerCompanyId.value'
																								) || ''
																							)
																						}
																						optionGenerator={
																							producerAddressOptionGenerator
																						}
																						isDisabled={
																							!contractForm.watch(
																								'producerCompanyId.value'
																							) || props?.isPrint
																						}
																						dropDownsize={50}
																						isClearable={true}
																						isSearchText={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																							onAddressChange(contractForm.watch('producerAddressId.value') || '', 'producerAddress');
																						}}
																						cacheOptions={false}
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{/* {contractForm.watch('producerCompanyId.value') &&
																		(!props?.isPrint)  && <div style={{float:'right'}}>
															<Box sx={{mt:0.9}}>
																<AddCompanyAddressInline
																	companyId={contractForm.watch('producerCompanyId.value')}
																	title='ADDRESS'
																	isOpen={addressPopOver?.isProducerAddressOpen || false}
																	event={addressPopOver?.event}
																	onAddressCreated={(d) => {
																		onAddressCreated(d, contractForm.watch('producerCompanyId.value'), 'producerAddressId');
																		onAddressChange(contractForm.watch('producerAddressId.value') || '', 'producerAddress', d);
																		contractForm.trigger('producerAddressId');
																	}}
																	onClose={() => setAddressPopOver({ isProducerAddressOpen: false })}
																	onPopOverOpen={(e) => setAddressPopOver({
																		isProducerAddressOpen: true,
																		event: e?.currentTarget,
																	})}
																	isPersistent={true} />
															</Box>
														</div>} 
																				</>
																			)}
																		/> */}
																		{/* {contractForm.formState.errors?.producerAddressId
												?.value?.message && (
												<FormHelperText
													error={true}
													data-cy='errorHelper_producer'
												>
													{
														contractForm.formState?.errors
															?.producerAddressId.value.message
													}
												</FormHelperText>
											)} */}
																		<Controller
																			control={contractForm.control}
																			name='producerCompanyAddress'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={!props?.isPrint ? 'Producer Address' : ''}
																				/>
																			)}
																		/>
																	</FormControl>
																</td>
																<td width={200}>
																	<b>Address</b>
																</td>
																<td>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<Controller
																			control={contractForm.control}
																			key={`select_Address_${contractForm.watch(
																				'performerId.value'
																			)}`}
																			name='performerAddressId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						{...field}
																						placeholder={!props?.isPrint ? 'Select Address' : ''}
																						key={`performer-address-${contractForm.watch(
																							'performerId.value'
																						)}`}
																						filterProperty='Line1'
																						sortField='IsPrimary'
																						sortDescending={true}
																						onOptionLoaded={(options) => {
																							if(options?.length && !contractForm.watch('performerAddressId.value')){
																								contractForm.setValue('performerAddressId', options[0], { shouldDirty: true});
																								contractForm.trigger('performerAddressId', { shouldFocus: true});
																								onAddressChange(contractForm.watch('performerAddressId.value') || '', 'performer');
																							}
																						}}
																						APICaller={(filterModel) =>
																							getAllAddressByPerson(
																								filterModel,
																								contractForm.watch(
																									'performerId.value'
																								) || ''
																							)
																						}
																						optionGenerator={
																							adAgencyAddressOptionGenerator
																						}
																						dropDownsize={50}
																						isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
																						isClearable={true}
																						isSearchText={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																							onAddressChange(contractForm.watch('performerAddressId.value') || '', 'performer');
																						}}
																						cacheOptions={false}
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{																							
																						<Box sx={{mt:1.3}}>
																							{(contractForm.watch('performerId.value') && (!props.isPrint)) && 
																									<AddPersonAddressInline
																										personId={contractForm.watch('performerId.value')}
																										title='ADDRESS'
																										isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'performer-address' || false}
																										event={addressPopOver?.event}
																										onAddressCreated={(d) => onPersonAddressCreated(d)}
																										onClose={() => setAddressPopOver({ isOpen: false })}
																										onPopOverOpen={(e) => setAddressPopOver({
																											isOpen: true,
																											event: e?.currentTarget,
																											for: 'performer-address'
																										})}
																										isPersistent={true} />
																							}
																						</Box>
																					}
																				</>
																			)}
																		/>
																		{contractForm.formState?.errors?.performerAddressId?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState.errors
																						?.performerAddressId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td width={200}>
																	<b>City/State/Zip</b>
																</td>
																<td>
																	<div>
																		<div>
																			<FormControl
																				className={styles.tableForms}
																				fullWidth
																			>
																				<Controller
																					control={contractForm.control}
																					name='producerAddressZip'
																					defaultValue=''
																					render={({ field }) => (
																						<NabuTextbox
																							{...field}
																							placeholder={!props?.isPrint ? 'ZIP' : ''}
																							disabled={props?.isPrint}
																							onChange={(e) => {
																								field.onChange(e);
																								onZipCodeChange(
																									contractForm.watch('producerAddressZip') || '',
																									'producerAddress'
																								);
																							}}
																						/>
																					)}
																				/>
																			</FormControl>
																			<span>(ZIP) </span>
																		</div>
																		<div>
																			<FormControl
																				className={styles.tableForms}
																				fullWidth
																				style={{
																					paddingTop: '12px'
																				}}
																			>
																				<Controller
																					control={contractForm.control}
																					name='producerAddressState'
																					defaultValue=''
																					render={({ field }) => (
																						<NabuSelect
																							{...field}
																							disabled={props?.isPrint}
																							options={getStateOptions()}
																							convertValueToUpperCase={false}
																							placeholder={!props?.isPrint ? 'Select State' : ''}
																						/>
																					)}
																				/>
																			</FormControl>
																			<span>(State) </span>
																		</div>
																		<div>
																			<FormControl
																				className={styles.tableForms}
																				fullWidth
																				style={{
																					paddingTop: '12px'
																				}}
																			>
																				<Controller
																					control={contractForm.control}
																					name='producerAddressCity'
																					defaultValue=''
																					render={({ field }) => (
																						<NabuSelect
																							{...field}
																							options={getCityOptions(
																								contractForm.watch(
																									'producerAddressState'
																								)
																							)}
																							disabled={props?.isPrint}
																							convertValueToUpperCase={true}
																							placeholder={!props?.isPrint ? 'Select City' : ''}
																						/>
																					)}
																				/>
																			</FormControl>
																			<span>(City)</span>
																		</div>
																	</div>
																</td>
																<td width={200}>
																	<b>City/State/Zip</b>
																</td>
																<td>
																	<div>
																		<div>
																			<FormControl
																				className={styles.tableForms}
																				fullWidth
																			>
																				<Controller
																					control={contractForm.control}
																					key={`select-${contractForm.watch('performerAddressId.value')}-${contractForm.watch('performerId.value')}`}
																					name='performerZip'
																					defaultValue=''
																					render={({ field }) => (
																						<NabuTextbox
																							key={`select-${contractForm.watch('performerAddressId.value')}-${contractForm.watch('performerId.value')}`}
																							{...field}
																							placeholder={!props?.isPrint ? 'ZIP' : ''}
																							disabled={props?.isPrint}
																							onChange={(e) => {
																								field.onChange(e);
																								onZipCodeChange(
																									contractForm.watch('performerZip') || '',
																									'performer'
																								);
																							}}
																						/>
																					)}
																				/>
																				{/* {contractForm.formState.errors?.performerZip
														?.message && (
														<FormHelperText
															data-cy='errorHelper-performerZip'
															error={true}
														>
															{
																contractForm.formState?.errors?.performerZip
																	.message
															}
														</FormHelperText>
													)} */}
																			</FormControl>
																			<span>(ZIP) </span>
																		</div>
																		<div>
																			<FormControl
																				className={styles.tableForms}
																				fullWidth
																				key={`performer-state-${contractForm.watch('performerState')}`}
																			>
																				<Controller
																					key={`performer-state-${contractForm.watch('performerState')}`}
																					control={contractForm.control}
																					name='performerState'
																					defaultValue=''
																					render={({ field }) => (
																						<>
																							<NabuSelect
																								displayEmpty={true}
																								{...field}
																								disabled={props?.isPrint}
																								options={getStateOptions()}
																								convertValueToUpperCase={true}
																								placeholder={!props?.isPrint ? 'Select State' : ''}
																							/>
																						</>
																					)}
																				/>
																				{/* {contractForm.formState?.errors?.performerState
														?.message && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.performerState
																	.message
															}
														</FormHelperText>
													)} */}
																			</FormControl>
																			<span>(State) </span>
																		</div>
																		<div>
																			<FormControl
																				className={styles.tableForms}
																				fullWidth
																			>
																				<Controller
																					key={`performer-city-${contractForm.watch('performerCity')}`}
																					control={contractForm.control}
																					name='performerCity'
																					defaultValue=''
																					render={({ field }) => (
																						<>
																							<NabuSelect
																								key={`performer-city-${contractForm.watch('performerCity')}`}
																								displayEmpty={true}
																								{...field}
																								disabled={props?.isPrint}
																								options={getCityOptions(
																									contractForm.watch('performerState')
																								)}
																								convertValueToUpperCase={true}
																								placeholder={!props?.isPrint ? 'Select City' : ''}
																							/>
																						</>
																					)}
																				/>
																				{/* {contractForm.formState?.errors?.performerCity
														?.message && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.performerCity
																	.message
															}
														</FormHelperText>
													)} */}
																			</FormControl>
																			<span>(City)</span>
																		</div>
																	</div>
																</td>
															</tr>
															<tr>
																<td colSpan={2}>
																	<b> Performer’s or Agent’s E-mail Address (optional)</b>
																</td>
																<td colSpan={2}>
																	<FormControl
																		className={styles.emaillowerCase}
																		fullWidth
																	>
																		<Controller
																			key={`select-${contractForm.watch('performerId.value')}-${contractForm.watch('performerEmail')}`}
																			control={contractForm.control}
																			name='performerEmail'
																			defaultValue=''
																			render={({ field }) => (
																				<>
																					<NabuSelect
																						{...field}
																						defaultCase={true}
																						key={`select-${contractForm.watch('performerId.value')}-${contractForm.watch('performerEmail')}`}
																						options={getPerformerEmailsOptions()}
																						disabled={props?.isPrint || !contractForm.watch('performerId.value')}
																						convertValueToUpperCase={false}
																						placeholder={!props?.isPrint ? 'Select Email' : ''}
																						handleDefaultSelection={() => {
																							return !contractForm.watch('performerEmail');
																						}}
																					/>
																					{/* {
																								<Box sx={{mt:1.3}}>
																									{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																						<AddEmailInline
																							formData={{ ...new EmailFormModel()}}
																							isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Performer-email-address'|| false}	
																							onSubmit={onPerformerEmailInlineSubmit}
																							onClose={closeEmailInlinePopOver}
																							title='Email address'
																							onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Performer-email-address')}
																							event={emailPopOver?.event}
																						/>
																									}
																								</Box>	
																							} */}
																				</>
																			)}
																		/>
																		{/* {contractForm?.formState?.errors?.performerEmail
																				?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.performerEmail
																							.message
																					}
																				</FormHelperText>
																			)} */}
																	</FormControl>
																</td>
								
															</tr>
														</table>
														<br></br>
														<p style={{textAlign:'center'}}><b>NOTE: PERFORMER MUST COMPLETE W-4 FORM ATTACHED</b></p>

													</div>
												</div>
											</>
											{/* Main Contract Form End */}

											{/* W4 Short form */}
											{packetControlForm.watch('isW4shortForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW4ShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
											{/* W8 Ben Short form */}
											{packetControlForm.watch('isW8BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW8BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
											{/* W9 Ben Short form */}
											{packetControlForm.watch('isW9BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW9BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
											{/* Minor Trust form */}
											{packetControlForm.watch('isMinorTrust') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractMinorTrustForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* NY-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isNYTheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractNYWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* CA-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isCATheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractCAWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>
					{!props?.isPrint && (
						<div className={styles.contractTemplateBtn}>
							<Grid container>
								<Grid xs={12} md={6}>
									<NabuButton variant='filled' onClick={onClickSnippetBtn}>
										{!contractFormState?.isPacketOpen ? (
											<>
												<NoteAdd /> Create
											</>
										) : (
											'Close'
										)}
								&nbsp; Packet
									</NabuButton>
								</Grid>
								<Grid xs={12} md={6} dir='rtl'>
									<NabuButton
										variant='outlined'
										data-cy='cancel_btn'
										shouldHandleConfirmAction={contractForm.formState.isDirty}
										onClick={() => handleFormClose()}
									>
								Cancel
									</NabuButton>{' '}
							&nbsp;
									<NabuButton
										variant='filled'
										type='submit'
										data-cy='save_btn'
										disabled={
											!(
												contractForm.formState.isDirty ||
										packetControlForm.formState.isDirty
											) ||
									isAddingContract ||
									isEditingContract
										}
									>
										{isAddingContract || isEditingContract ? 'Saving' : 'Save'}
										<Save />
									</NabuButton>{' '}
							&nbsp;
									<NabuButton
										variant='filled' 
										onClick={contractForm.handleSubmit(handleSaveAndDuplicate)}
										disabled={
											!(
												contractForm.formState.isDirty ||
										packetControlForm.formState.isDirty
											) ||
									isAddingContract ||
									isEditingContract || (!(props?.mainForm?.watch('contractDisplayName'))&& !props?.isEdit)
										}
									>
								Save & Duplicate <CopyAll />
									</NabuButton>
							&nbsp;
								</Grid>
							</Grid>
						</div>
					)}
				</form>
			</div>
			<TalentDetailsForm handleTalentDetailsForm={(value) => handleTalentDetailsForm(value)} noOfTalentsSelected={noOfTalentsSelected} setNoOfTalentsSelected={(value) => setNoOfTalentsSelected(value)} performerCategory={SagAftraCOEDHTPerformerTypeOptions} isUnion={true}/>
		</>
	);
});
export default SagAftraCOED;
