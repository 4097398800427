import { ILookAheadSelectOption } from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import { OPERATORS, UNION_TYPE } from '@/common/enums';
import { StatusCodes } from '@/common/enums/StatusCodes';
import useNotification from '@/common/hooks/useNotification';
import {
	CompanyMainType,
	ICompanyAddress,
	ICompanyRecord,
} from '@/common/types/company';
import {
	IBaseContract,
	ICommercialDetail,
	IContractSagAftraSdAudComForm, IPacketControlForm
} from '@/common/types/contract';
import { IFilterOption } from '@/common/types/filterModel';
import {
	displayAddress,
	displayOnlyEmail,
	displayPhoneNumberWithoutLabel,
	getBaseUrl,
	getCompanyDisplayName,
	getLocaleDate,
	getLocaleDateTime,
	getNotificationText,
	getPersonFullName,
	mutateContractAdditionalFormsData,
	sortEmailOrPhoneNumberForDropDown
} from '@/common/utils/utils';
import {
	addContractSagAftraSdAudCom,
	addMultipleContract,
	updateContractSagAftraSdAudCom,
} from '@/services/contract';
import { IAssetRecord } from '@/services/models/asset';
import { IPersonAddress, IPersonRecord } from '@/services/models/person';
import {
	GetByWithFullNameSearchForBasicDetail,
	createPersonEmail,
	createPersonPhoneNumber,
	getAgentInfo,
	getAllAddressByPerson
} from '@/services/person';
import { yupResolver } from '@hookform/resolvers/yup';
import { HttpStatusCode } from 'axios';
import { City, State } from 'country-state-city';
import { addDays } from 'date-fns';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { SagAftraSdAcsAudComY22Schema } from '../contarctFormSchema';
import { IContractFormState } from './standardSagAftraEmployment2022';

import NabuButton from '@/common/components/atoms/button/button';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import styles from '@/modules/contracts/components/addContracts.module.scss';
import {
	Box, FormControl,
	Tooltip
} from '@mui/material';

import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import NabuDynamicInputDropdown from '@/common/components/atoms/dynamicInputDropdown/dynamicInputDropdown';
import LookAheadSelect from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuBaseTextArea } from '@/common/components/atoms/textArea/textArea';
import NabuTimePicker from '@/common/components/molecules/timepicker/timePicker';
import AddCompanyAddressInline from '@/common/components/templates/pages/add/addCompanyAddressInline';
import AddCompanyInline from '@/common/components/templates/pages/add/addCompanyInline';
import AddEmailInline from '@/common/components/templates/pages/add/addEmailInline';
import { IPopOverState } from '@/common/components/templates/pages/add/addNewAsset';
import AddPersonAddressInline from '@/common/components/templates/pages/add/addPersonAddressInline';
import AddPersonInline from '@/common/components/templates/pages/add/addPersonInline';
import AddPhoneNumberInline from '@/common/components/templates/pages/add/addPhoneNumberInline';
import AddTalentAgentInline from '@/common/components/templates/pages/add/addTalentAgentInline';
import { CONTRACT_GUARDIAN, CONTRACT_REF_ID, ContractStatus, ContractStatusOptions, ContractUnionType, DefaultPageSize, SagAftraAudioCommercialsPerformerTypeOptions } from '@/common/constants/constants';
import useScrollToField from '@/common/hooks/useScrollToField';
import { EmailFormModel, PhoneNumberFormModel } from '@/modules/company/shared/models/company';
import { getAllAssetForBasicDetail, getAllSignatory, retriveAssetProducts } from '@/services/asset';
import {
	addNewCompanyEmail,
	createCompanyAddressPhoneNumber,
	getAllAddressByCompany,
	getAllProducts,
	getCompanyBasicDetails,
	getCompanyPrimaryEmailIdsForContract
} from '@/services/company';
import store from '@/store';
import { updateContractFormState } from '@/store/contract/reducer';
import {
	Add,
	CopyAll,
	DeleteForever,
	HelpOutline,
	NoteAdd,
	Save,
} from '@mui/icons-material';
import {
	Checkbox,
	FormHelperText,
	FormLabel,
	Grid,
	ListItemButton,
	ListItemText,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import ContractCAWageTheftPreventionForm from '../contractCAWageyTheftPreventionForm';
import ContractMinorTrustForm from '../contractMinorTrustForm';
import ContractNYWageTheftPreventionForm from '../contractNYWageTheftPreventionForm';
import ContractW4ShortForm from '../contractW4ShortForm';
import ContractW8BenShortForm from '../contractW8BenShortForm';
import ContractW9BenShortForm from '../contractW9BenShortForm';
import TalentDetailsForm from '../talentDetailsForm';
import LocationFreeTextSelect from './locationFreeTextSelect';
import AddAssetInline from '@/common/components/templates/pages/add/addAssetInline';

interface Props {
	mainForm?: UseFormReturn<IBaseContract, any, undefined>;
	onCancel?: VoidFunction;
	onSave?: CallableFunction;
	handlePrintStateOfContract?: CallableFunction;
	initialValue?: IContractSagAftraSdAudComForm;
	isPrint?: boolean;
	isEdit?: boolean;
	contractId?: string;
	defaultValue?: IBaseContract | any,
	address?: ICompanyAddress[];
	people?: IPersonRecord[];
	assets?: IAssetRecord[];
}

// eslint-disable-next-line react/display-name
const SagAftraSdAcsAudComY22 = forwardRef((props: Props, ref) => {
	const [params, setParams] = useSearchParams();
	const { scroll } = useScrollToField<IContractSagAftraSdAudComForm>();
	useImperativeHandle(ref, () => ({
		handleContractSagAftraSdAcsAudComY22FormCancel: (data: IContractSagAftraSdAudComForm, statusValue: string) => {
			if(data){
				const commercials = data?.commercials?.map((d) => ({
					...d,
					assetId: d?.id,	
				}));
				const workDays = data?.workDays?.filter((d) => d?.date) || [];
				const submitData = {
					...data,
					status: statusValue ? statusValue : ContractStatus.Draft,
					contractSagAftraSdAudCom: undefined,
					payrollVendorId: data?.payrollVendorId?.value,
					performerId: data?.performerId?.value,
					advertiserId: data?.advertiserId?.value,
					adAgencyId: data?.adAgencyId?.value,
					adAgencyAddressId: data?.adAgencyAddressId?.value,
					productId: data?.productId?.map(product => product?.value),
					performerAddressId: data?.performerAddressId?.value,
					performerAdAgencyId: data?.performerAdAgencyId?.value,
					performerAdAgencyAddressId: data?.performerAdAgencyAddressId?.value,
					producerCompanyId: data?.producerCompanyId?.value,
					producerCompanyAddressId: data?.producerCompanyAddressId?.value,
					payrollVendorAddressId: data?.payrollVendorAddressId?.value || '',
					commercials,
					workDays
				};
				editContractDetails(submitData);
			}
		},
	}));

	useEffect(() => {
		if(props?.defaultValue?.advertiserId)
			contractForm.setValue('advertiserId', props?.defaultValue?.advertiserId, { shouldDirty: true, shouldValidate: true});
		if(props?.defaultValue?.productId)
			contractForm.setValue('productId', props?.defaultValue?.productId, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.advertiserId]);

	useEffect(() => {
		contractForm.setValue('isJPCAuthorizer', props?.defaultValue?.isJPCAuthorizer, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.isJPCAuthorizer]);

	const DEFAULT_COUNTRY_CODE = 'US';

	const notification = useNotification();

	const COMPENSATION_OPTIONS = [ 'SCALE', 'DOUBLE SCALE' ];


	const contractForm = useForm<IContractSagAftraSdAudComForm>({
		defaultValues: !props?.initialValue
			? {
				...(props?.mainForm?.getValues() || {}),
				...(props?.defaultValue || {}),
				workDays: [{}],
				commercials: [{}],
			}
			: {
				...(props?.initialValue || {}),
				commercials: props?.initialValue?.commercials?.length ? props?.initialValue?.commercials : [{}], 
				workDays: props?.initialValue?.workDays?.length ? props?.initialValue?.workDays : [{}],
			},
		resolver: yupResolver(SagAftraSdAcsAudComY22Schema), 
		shouldFocusError: false
	});

	const [addressPopOver, setAddressPopOver] = useState<IPopOverState>({
		isOpen: false,
	});
	const [companyPopoverInline, setCompanyPopoverInline] = useState<IPopOverState>({
		isOpen: false,
	});

	const packetControlForm = useForm<IPacketControlForm>({
		defaultValues: {
			isW4shortForm: !!contractForm.watch('contractW4Form.firstAndMiddleName'),
			isW8BenShort: !!contractForm.watch('contractW8BenShortForm'),
			isW9BenShort: !!contractForm.watch('contractW9BenShortForm'),
			isMinorTrust: !!contractForm.watch('contractMinorTrustForm'),
			isNYTheftPreventionForm: !!contractForm.watch(
				'contractNYTheftPreventionForm'
			),
			isCATheftPreventionForm: !!contractForm.watch(
				'contractCATheftPreventionForm'
			),
		},
	});

	const [companyOptions, setCompanyOptions] = useState<ICompanyRecord[]>([]);
	const [personOptions, setPersonOptions] = useState<IPersonRecord[]>(
		props?.people || []
	);
	const [assetOptions, setAssetOptions] = useState<IAssetRecord[]>(
		props?.assets || []
	);
	const [addressOptions, setAddressOptions] = useState<ICompanyAddress[]>(
		props?.address || []
	);
	const [contractFormState, setContractFormState] =
		useState<IContractFormState>({});

	const workDayFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'workDays',
	});

	const commercialFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'commercials',
	});

	const AD_AGENCY_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'AD AGENCY',
		},
	];

	const ADVERTISER_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'ADVERTISER',
		},
	];

	const PRODUCT_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.NOT_EQUAL,
			property: 'ProductName',
			value: '',
		},
	];

	const VENDOR_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'VENDOR',
		},
	];

	// helper functions
	const getCommercialFilters = () => {
		const assetFilter: IFilterOption[] = [
			{
				isCaseSensitive: false,
				operator: OPERATORS.NOT_EQUAL,
				property: 'Title',
				value: '',
			},
			{
				isCaseSensitive: false,
				operator: OPERATORS.IN,
				property: 'Advertisers.Id',
				value: JSON.stringify([contractForm.watch('advertiserId.value')]),
			},
		];
		return assetFilter;
	};

	const getPerformerFilter = (): IFilterOption[] => {
		const DEFAULT_FILTER: IFilterOption = {
			value: JSON.stringify(['TALENT']),
			property: 'PersonType',
			operator: OPERATORS.IN,
			isCaseSensitive: false,
		};
		return [DEFAULT_FILTER];
	};

	const onAddWorkDay = () => {
		if (workDayFieldArray.fields.length) {
			const previousRow = {
				...contractForm.watch(
					`workDays.${workDayFieldArray.fields.length - 1}`
				),
				id: undefined,
			};
			workDayFieldArray.append({
				...previousRow,
				date: previousRow?.date ? addDays(new Date(previousRow?.date || ''), 1)?.toISOString() : '',
			});
		}
	};

	
	const onPayrollVendorAddressCreated = (createdAddress: ICompanyAddress) => {
		contractForm.setValue('payrollVendorAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};	
	
	const onAdAgencyAddressCreated = (createdAddress: ICompanyAddress) => {
		setCompanyOptions((prev) => {
			const company = companyOptions.find(
				(op) => op.id === contractForm.watch('adAgencyId.value')
			);

			!company?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				company?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === company?.id ? company : c));
		});
		contractForm.setValue('adAgencyAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};
	const companyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		})) || [];
	};

	const onRemoveWorkDay = (index: number) => {
		if (workDayFieldArray.fields.length > 1) {
			workDayFieldArray.remove(index);
		}
	};

	const onAddCommercial = () => {
		commercialFieldArray.append({} as ICommercialDetail);
	};

	const getProducts = async () => {
		const commercials = contractForm.watch('commercials');
		let totalProducts: any = [];
		
		if(commercials){
			for(let i = 0; i < commercials.length; i++){
				const filterModel = {
					pageSize: DefaultPageSize,
					sortDescending: false,
					sortField: 'CreatedAt',
					pageNumber: 0,
					filters: [{
						property: 'Asset.Id',
						value: commercials[i].assetId?.value ?? '',
						operator: OPERATORS.EQUAL,
						isCaseSensitive: false
					}]
				};
		
				const res: any = await retriveAssetProducts(filterModel);
		
				if(res?.data?.records){
					const products: any = res?.data?.records?.map(product => {
						return { value: product.id, label: product.productName };
					});
					
					totalProducts = [...totalProducts, ...products];
				}
			}

			contractForm.setValue('productId', totalProducts, { shouldDirty: true, shouldValidate: true });
		}
	};

	const onRemoveCommercial = (index: number) => {
		if (commercialFieldArray.fields.length > 1) {
			commercialFieldArray.remove(index);
			getProducts();
		}
	};

	const handlePackageNotification = (key: keyof IPacketControlForm) => {
		notification.notify({
			message: `Form ${packetControlForm.watch(key) ? 'Added to' : 'Removed from'} Packet.`,
			type: 'info',
		});
	};

	const handleStateOptions = (
		responseData: any[],
		type: 'COMPANY' | 'PERSON' | 'ASSET' | 'ADDRESS'
	) => {
		const cb = {
			COMPANY: setCompanyOptions,
			PERSON: setPersonOptions,
			ASSET: setAssetOptions,
			ADDRESS: setAddressOptions,
		}[type];

		if (responseData && Array.isArray(responseData)) {
			cb((prev) => {
				const oldFilter = prev?.filter(
					(d) => !responseData?.some((r) => r?.id === d?.id)
				);
				return [...oldFilter, ...responseData];
			});
		}
	};

	const getAssetAdIdByAssetIndex = (index: number) => {
		const assetId = contractForm.watch(`commercials.${index}.assetId`)?.value;
		return assetOptions?.find((d) => d?.id === assetId)?.adId || '';
	};

	const getStateOptions = () => {
		const states = State.getStatesOfCountry(DEFAULT_COUNTRY_CODE).map(
			(state) => ({ label: state.name?.toUpperCase(), value: state.isoCode })
		);
		if (!states.length) states.unshift({ label: 'NA', value: 'NA' });
		return states;
	};

	const getCityOptions = (stateCode?: string) =>
		stateCode
			? City.getCitiesOfState(DEFAULT_COUNTRY_CODE, stateCode)?.map((city) => ({
				label: city.name?.toUpperCase(),
				value: city.name?.toUpperCase(),
			})) || []
			: [];

	const companyOptionGenerator = (responseData: ICompanyRecord[]) => {
		handleStateOptions(responseData, 'COMPANY');
		
		return responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));
	};

	const performerOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');
		return responseData?.map((d) => ({
			value: d?.id,
			label: getPersonFullName(d?.personName),
		}));
	};

	const adAgencyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const performerAdAgencyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};
	const PerformerAdAgencyAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('performerAdAgencyId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('performerAdAgencyAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const productOptionGenerator = (responseData) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: d?.productName?.toUpperCase(),
		}));
	};

	const lookAheadAssetOptionGenerator = (responseData: IAssetRecord[]) => {
		handleStateOptions(responseData, 'ASSET');

		return responseData?.map((d) => ({
			value: d?.id || '',
			label: d?.title || '',
		}));
	};

	const producerAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const getPerformerPhoneNumberOptions = () => {
		const unsortedPhoneNumbers =
			personOptions?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.phoneNumbers || [];

		const phoneNumbers = sortEmailOrPhoneNumberForDropDown(unsortedPhoneNumbers);
		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	const getPerformerEmailsOptions = () => {
		const unsortedEmails =
			personOptions?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.emails || [];

		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const setJPCAuthorizer = () => {
		const adAgency = !!companyOptions?.find(
			(d) => d?.id === contractForm.watch('adAgencyId.value')
		)?.isJPCAuthorizer;
		const advertiser = !!companyOptions?.find(
			(d) => d?.id === contractForm.watch('advertiserId.value')
		)?.isJPCAuthorizer;

		contractForm.setValue('isJPCAuthorizer', adAgency || advertiser, {
			shouldDirty: true,
		});
	};

	const getUnions = (): any[] =>
		[UNION_TYPE.AFM, UNION_TYPE.SAG_AFTRA].map((value) => ({
			label: value,
			value: value,
		})) || [];

	const getUnionAgreement = (unionType: string): any[] =>
		ContractUnionType[unionType]?.agreements?.map((op) => ({
			label: op?.toUpperCase(),
			value: op?.toUpperCase(),
		})) || [];

	const getCompanyEmailOptions = (addressId?: string) => {
		const unsortedEmails =
			addressOptions?.find((a) => a?.id === addressId)?.emails || [];
		
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const getCompanyPhoneOptions = (addressId?: string) => {
		const unsortedPhoneNumbers =
			addressOptions?.find((a) => a?.id === addressId)?.phoneNumbers || [];

		const phoneNumbers = sortEmailOrPhoneNumberForDropDown(unsortedPhoneNumbers);
		return (
			phoneNumbers?.map((e) => ({
				label: displayPhoneNumberWithoutLabel(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const onClickSnippetBtn = () => {
		setContractFormState((prev) => ({
			isPacketOpen: !prev?.isPacketOpen,
		}));
	};

	const handleFormClose = () => {
		contractForm?.reset();
		props?.onCancel && props?.onCancel();
	};

	const { mutate: addContractDetails, isLoading: isAddingContract } =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addContractSagAftraSdAudCom(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave) {
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const { mutate: editContractDetails, isLoading: isEditingContract } =
		useMutation({
			mutationKey: ['contract-edit'],
			mutationFn: (payload: any) =>
				updateContractSagAftraSdAudCom(props?.contractId || '', payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'UPDATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave) {
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'UPDATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const handleContractSubmission = async (
		data: IContractSagAftraSdAudComForm,
		handleMultipleContractAdd?: CallableFunction
	) => {
		if (!packetControlForm.watch('isW4shortForm')) {
			data.w4FormId = undefined;
			data.contractW4Form = undefined;
		}
		if (!packetControlForm.watch('isW8BenShort')) {
			data.w8BenShortFormId = undefined;
			data.contractW8BenShortForm = undefined;
		}
		if (!packetControlForm.watch('isW9BenShort')) {
			data.w9BenShortFormId = undefined;
			data.contractW9BenShortForm = undefined;
		}
		if (!packetControlForm.watch('isMinorTrust')) {
			data.minorTrustFormId = undefined;
			data.contractMinorTrustForm = undefined;
		}
		if (!packetControlForm.watch('isNYTheftPreventionForm')) {
			data.nYTheftPreventionFormId = undefined;
			data.contractNYTheftPreventionForm = undefined;
		}
		if (!packetControlForm.watch('isCATheftPreventionForm')) {
			data.cATheftPreventionFormId = undefined;
			data.contractCATheftPreventionForm = undefined;
		}

		try {
			const notify = (message: string) => {
				notification.notify({
					message,
					type: 'error',
				});
			};
			// NOTE: These linked form's handled separately because the payload size is huge.
			const updatedContractIds = await mutateContractAdditionalFormsData(
				data,
				data?.performerId?.value || '',
				notify
			);
			data.w4FormId = updatedContractIds?.w4FormId;
			data.w8BenShortFormId = updatedContractIds?.w8BenShortFormId;
			data.w9BenShortFormId = updatedContractIds?.w9BenShortFormId;
			data.minorTrustFormId = updatedContractIds?.minorTrustFormId;
			data.nYTheftPreventionFormId =
				updatedContractIds?.nYTheftPreventionFormId;
			data.cATheftPreventionFormId =
				updatedContractIds?.cATheftPreventionFormId;
		} catch (error) {
			notification.notify({
				message: getNotificationText('Form', 'UPDATE', true),
				type: 'error',
			});
		} finally {
			data.contractW4Form = undefined;
			data.contractW8BenShortForm = undefined;
			data.contractW9BenShortForm = undefined;
			data.contractMinorTrustForm = undefined;
			data.contractNYTheftPreventionForm = undefined;
			data.contractCATheftPreventionForm = undefined;

			const commercials = data?.commercials?.map((d) => ({
				...d,
				assetId: d?.assetId?.value,
			}));

			const mainFormValues = props?.mainForm?.getValues();
			const addMainFormValues = !props?.isEdit ? {
				contractType: mainFormValues?.contractType,
				contractDisplayName: mainFormValues?.contractDisplayName,
				unionType: mainFormValues?.unionType,
				unionAgreement: mainFormValues?.unionAgreement,
				payrollVendorAddressId: mainFormValues?.payrollVendorAddressId || '',
			} : {};

			const workDays = data?.workDays?.filter((d) => d?.date) || [];

			const submitData = {
				...data,
				...addMainFormValues,
				payrollVendorAddressId: data?.payrollVendorAddressId?.value,
				contractSagAftraSdAudCom: undefined,
				payrollVendorId: data?.payrollVendorId?.value,
				performerId: data?.performerId?.value,
				advertiserId: data?.advertiserId?.value,
				adAgencyId: data?.adAgencyId?.value,
				adAgencyAddressId: data?.adAgencyAddressId?.value,
				productId: data?.productId?.map((product) => product?.value),
				performerAddressId: data?.performerAddressId?.value,
				performerAdAgencyId: data?.performerAdAgencyId?.value,
				performerAdAgencyAddressId: data?.performerAdAgencyAddressId?.value,
				producerCompanyId: data?.producerCompanyId?.value,
				producerCompanyAddressId: data?.producerCompanyAddressId?.value,
				commercials,
				workDays
			};

			if (!props?.isEdit) {
				addContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					},
				});
			} else {
				editContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			} 
		}
	};
	
	const onSubmitError = (data) => {
		scroll(data);	
		if(!props?.mainForm?.watch('contractDisplayName') && !props?.isEdit){
			notification.notify({
				message: 'Please fill Contract Display Name.',
				type: 'error'
			});
		}
		
		notification.notify({
			message: 'Please fill all required fields',
			type: 'error',
		});
	};

	const onSubmit = (data: IContractSagAftraSdAudComForm) => {
		if(!props?.mainForm?.watch('contractDisplayName') && !props?.isEdit){
			notification.notify({
				message: 'Please fill Contract Display Name.',
				type: 'error'
			});
		}
		handleContractSubmission(data);
	};

	const { mutate: addMultipleContractDetails } = useMutation({
		mutationKey: ['contract-add'],
		mutationFn: (payload: any) => addMultipleContract(payload),
		onSuccess: (res) => {
			if (res?.status === HttpStatusCode.Ok) {
				const baseURL = getBaseUrl(window.location.href);
				const contractIds: any = res?.data || [];
				const urls =
					contractIds.map((contract) => {
						return `${baseURL}/#/contracts/${contract}/contract-view?isEdit=true`;
					}) || [];

				urls.forEach((url) => {
					const a = document.createElement('a');
					a.href = url;
					a.target = '_blank';
					const evt = document.createEvent('MouseEvents');
					evt.initMouseEvent(
						'click',
						true,
						true,
						window,
						0,
						0,
						0,
						0,
						0,
						false,
						false,
						false,
						false,
						0,
						null
					);
					a.dispatchEvent(evt);
				});
				notification.notify({
					message: getNotificationText('Contract', 'CREATE'),
					type: 'success',
				});
			} else
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
		},
		onError: () => {
			notification.notify({
				message: getNotificationText('Contract', 'CREATE', true),
				type: 'error',
			});
		},
	});

	const [contractFormData, setContractFormData] =
		useState<IContractSagAftraSdAudComForm>();

	const [noOfTalentsSelected, setNoOfTalentsSelected] = useState(false);

	const handleSaveAndDuplicate = (data: IContractSagAftraSdAudComForm) => {
		setNoOfTalentsSelected(true);
		setContractFormData(data);
	};

	const handleTalentDetailsForm = (data: any) => {
		setNoOfTalentsSelected(false);
		const handleMultipleContractAdd = (payloadData) => {
			if (data) {
				const contractAddModels = data?.talentRecords?.map((talent, index) => {
					const submitData = {
						...payloadData,
						id: undefined,
						status:ContractStatus?.Draft,
						partPlayed: talent?.role,
						performerAdAgencyId: talent?.agent?.value,
						performerId: talent?.talentName?.value,
						contractDisplayName: `${payloadData?.contractDisplayName}_copy_${index+1}`,
						compensation: talent?.compensation,
						performerType:talent?.perfCategory?.toUpperCase(),
						performerAddressId:undefined,
						performerEmailId:undefined,
						performerPhoneId:undefined,
						performerAdAgencyAddressId:undefined
					};
					return submitData;
				});
				const multipleContractData = {
					contractAddSagAftraSdAudComModels: contractAddModels,
				};
				addMultipleContractDetails(multipleContractData);
			}
		};
		contractFormData &&
			handleContractSubmission(contractFormData, handleMultipleContractAdd);
	};

	useEffect(()=> {
		if(props?.isEdit && commercialFieldArray.fields.length === 0){
			onAddCommercial();
		}
	}, [props?.isEdit]);

	const onPersonAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('performerId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('performerAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const onProducerAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('producerCompanyId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('producerCompanyAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const [phoneNumberPopOver, setPhoneNumberInlinePopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const openPhoneNumberInlinePopOver = (e: React.MouseEvent<HTMLButtonElement | null>, key?: string) => {
		setPhoneNumberInlinePopOver({ isOpen: true, event: e?.currentTarget, for: key });
	};

	const closePhoneNumberInlinePopOver = () => {
		setPhoneNumberInlinePopOver({ isOpen: false });
	};

	const onProducerPhoneNumberInlineSubmit = (formData: any) => {
		const addressId = contractForm.watch('producerCompanyAddressId.value') || '';
		createCompanyAddressPhoneNumber({ addressId, phoneNumber: {...formData, isPrimary: true} }).then((res: any) => {
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Phone Number', 'CREATE'),
					type: 'success',
				});
				closePhoneNumberInlinePopOver();
				setAddressOptions((prev) =>
					prev.map((ad) =>
						ad.id === contractForm.watch('producerCompanyAddressId.value')
							? {
								...ad,
								phoneNumbers: [
									{ id: res?.data?.id, ...formData },
									...(ad?.phoneNumbers || {}),
								],
							}
							: ad
					)
				);
				contractForm.setValue('producerCompanyPhoneId', res.data.id);
				contractForm.trigger('producerCompanyAddressId', { shouldFocus: true });
			}
			else {
				notification.notify({
					message: 'Error While Adding Phone Number.',
					type: 'error',
				});
			}
		});
	};		

	const onPhoneNumberInlineSubmit = (formData: any) => {
		const personId = contractForm.watch('performerId.value') || '';
		createPersonPhoneNumber({ personId, phoneNumber: {...formData, isPrimary: true} }).then((res: any) => {
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Phone Number', 'CREATE'),
					type: 'success',
				});
				closePhoneNumberInlinePopOver();
				setAddressOptions((prev) =>
					prev.map((ad) =>
						ad.id === contractForm.watch('performerId.value')
							? {
								...ad,
								phoneNumbers: [
									{ id: res?.data?.id, ...formData },
									...(ad?.phoneNumbers || {}),
								],
							}
							: ad
					)
				);
				contractForm.trigger('performerId');
				contractForm.trigger('performerId.value');
				contractForm.setValue('performerPhoneId', res?.data?.id);
				// contractForm.setValue('performerId', res.data.id);
				// contractForm.trigger('performerPhoneId.value', { shouldFocus: true });
			}
			else {
				notification.notify({
					message: 'Error While Adding Phone Number.',
					type: 'error',
				});
			}
		});
	};

	const [emailPopOver, setEmailInlinePopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const openEmailInlinePopOver = (e: React.MouseEvent<HTMLButtonElement | null>, key?: string) => {
		setEmailInlinePopOver({ isOpen: true, event: e?.currentTarget, for: key });
	};

	const closeEmailInlinePopOver = () => {
		setEmailInlinePopOver({ isOpen: false });
	};

	const onPerformerEmailInlineSubmit = (formData: any) => {
		const personId = contractForm.watch('performerId.value') || '';
		createPersonEmail({personId, Email: {...formData, isPrimary: true}}).then((res: any) =>{
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Email', 'CREATE'),
					type: 'success',
				});
				closeEmailInlinePopOver();

				setPersonOptions(prev => (
					prev?.map(em => (em?.id == contractForm.watch('performerId.value') ? ({
						...em,
						emails: [
							{ id: res?.data?.id, ...formData },
							...(em?.emails || {}) as any
						]
					}) : em))
				));	

				contractForm.trigger('performerId');
				contractForm.trigger('performerId.value');
				contractForm.setValue('performerEmailId', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Email Address.',
					type: 'error',
				});
			}
		});
	};

	const onProducerEmailInlineSubmit = (formData: any) => {
		const addressId = contractForm.watch('producerCompanyAddressId.value') || '';
		addNewCompanyEmail(addressId, {...formData, isPrimary: true}).then((res: any) =>{
			if(res?.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Email', 'CREATE'),
					type: 'success',
				});
				closeEmailInlinePopOver();

				setAddressOptions((prev) =>
					prev.map((ad) =>
						ad.id === contractForm.watch('producerCompanyAddressId.value')
							? {
								...ad,
								emails: [
									{ id: res?.data?.id, ...formData },
									...(ad?.emails || []),
								],
							}
							: ad
					)
				);
				contractForm.trigger('producerCompanyAddressId', { shouldFocus: true });
				contractForm.setValue('producerCompanyEmailId', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Email Address.',
					type: 'error',
				});
			}
		});
	};

	const handleCommercialChange = async (id: string) => {
		const filterModel = {
			pageSize: DefaultPageSize,
			sortDescending: false,
			sortField: 'CreatedAt',
			pageNumber: 0,
			filters: [{
				property: 'Asset.Id',
				value: id,
				operator: OPERATORS.EQUAL,
				isCaseSensitive: false
			}]
		};

		const res: any = await retriveAssetProducts(filterModel);

		if(res?.data?.records){
			const existingProducts: ILookAheadSelectOption[] = contractForm.getValues('productId') ?? [];
			let products: any = res?.data?.records?.map(product => {
				return { value: product.id, label: product.productName, assetId: id };
			});

			products = products.filter(product => {
				const foundProduct = existingProducts.find(eProduct => eProduct.value === product.value);
				if(foundProduct) return false;
				else return true;
			});

			contractForm.setValue('productId', [...products, ...existingProducts], { shouldDirty: true, shouldValidate: true });
		}
	};

	const handleProducerChange = (index ?: any)=>{
		if(index ==0){
			contractForm.setValue('producerCompanyId',null,{shouldDirty:true});
			contractForm.setValue('producerCompanyAddressId',null,{shouldDirty:true});
			contractForm.setValue('producerCompanyEmailId','',{shouldDirty:true});
			contractForm.setValue('producerCompanyPhoneId','',{shouldDirty:true});
		}
		if(contractForm.watch(`commercials.${0}.assetId.value`)?.length == 0){
			contractForm.setValue('producerCompanyId',null,{shouldDirty:true});
			contractForm.setValue('producerCompanyAddressId',null,{shouldDirty:true});
			contractForm.setValue('producerCompanyEmailId','',{shouldDirty:true});
			contractForm.setValue('producerCompanyPhoneId','',{shouldDirty:true});
		}
	};

	const payload = {
		advertiserCompanyId: contractForm.watch('advertiserId.value'),
		adAgencyCompanyId: contractForm.watch('adAgencyId.value'),
	};

	const [emailsForCompany,getEmailsForCompany]=useState<any>();

	useQuery({
		queryKey: [contractForm.watch('advertiserId.value'), contractForm.watch('adAgencyId.value')],
		queryFn: () => getCompanyPrimaryEmailIdsForContract(payload),
		refetchOnWindowFocus: false,
		enabled: !!contractForm.watch('adAgencyId.value'),
		onSuccess: (res) => {
			if (res?.data?.length > 0) {
				getEmailsForCompany(
					res.data?.map((e) => ({
						label: displayOnlyEmail(e) || '-',  // Formatting the label
						value: e.id || '',  // Using emailId as the value
					})) || []
				);
			}
			else {
				return (
					getEmailsForCompany([])
				);
			}
		},
		onError: () => {
			return (
				getEmailsForCompany([])
			);
		},
	});

	useEffect(() => {
		if(props?.mainForm){
			contractForm.setValue('contractDisplayName', props?.mainForm?.watch('contractDisplayName') || '', { shouldDirty: true});
		}
	}, [props?.mainForm?.watch('contractDisplayName')]);

	return (
		<>
			<div className={props?.isPrint ? styles.formWrapperPreview : ''}>
				<form onSubmit={contractForm.handleSubmit(onSubmit, onSubmitError)}>
					<div className={styles.packetTemplate} id='form'>
						<Grid item container columnSpacing={3}>
							{contractFormState?.isPacketOpen && (
								<Grid item sm={12} md={4} lg={3}>
									<div className={styles.snippetSection}>
										<Grid item container columnSpacing={3}>
											<Grid item md={12}>
												<h3 className={styles.pocketTitle}> Government & Payroll forms</h3>
											</Grid>
										</Grid>
										<div className={styles.contractFormBlockSnippet}>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW4shortForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW4shortForm');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW4shortForm');
																		}}
																	/>{' '}
																	<span>W4- short version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW8BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW8BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW8BenShort');
																		}}
																	/>
																	<span>W8 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW9BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW9BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW9BenShort');
																		}}
																	/>
																	<span>W9 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isMinorTrust'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isMinorTrust');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isMinorTrust');
																		}}
																	/>
																	<span>Minor Trust Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isNYTheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isNYTheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isNYTheftPreventionForm'
																			);
																		}}
																	/>
																	<span>NY Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isCATheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isCATheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isCATheftPreventionForm'
																			);
																		}}
																	/>
																	<span>CA Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
										</div>
									</div>
								</Grid>
							)}
							<Grid
								item
								sm={12}
								md={4}
								lg={!contractFormState?.isPacketOpen ? 12 : 9}
							>
								<div
									className={
										!contractFormState?.isPacketOpen
											? undefined
											: styles.scrollFIle
									}
								>
									<div className={styles.contractFormBlockPacket} id='contract-form-block-packet'>
										<div className={styles.contractFormBlock}>
											{props?.isEdit && (
												<div className={styles.formFiledsBlock}>
													<Grid container item columnSpacing={3}>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
															Contract Name<sup>*</sup>
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='contractDisplayName'
																	render={({ field }) => (
																		<>
																			<NabuTextbox {...field} />
																		</>
																	)}
																/>
																{contractForm.formState?.errors?.contractDisplayName
																	?.message && (
																	<FormHelperText error={true}>
																		{
																			contractForm.formState.errors
																				?.contractDisplayName?.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
															Payroll Vendor <sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='payrollVendorId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				{...field}
																				placeholder={!props?.isPrint ? 'Payroll Vendor' : ''}
																				filterProperty='CompanyNames.Name'
																				APICaller={getCompanyBasicDetails}
																				optionGenerator={companyOptionGenerator}
																				defaultFilter={VENDOR_FILTER}
																				isClearable={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																				}}
																				cacheOptions={false}
																				defaultOptions
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																			/>
																		</>
																	)}
																/>
																{contractForm.formState.errors?.payrollVendorId?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors
																				?.payrollVendorId?.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
															Payroll Vendor Address
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	key={`payroll-address-${contractForm.watch(
																		'payrollVendorAddressId.value'
																	)}`}
																	name='payrollVendorAddressId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				{...field}
																				placeholder={'Select Address'}
																				key={`payroll-vendor-address-${contractForm.watch(
																					'payrollVendorAddressId.value'
																				)}-${contractForm.watch('payrollVendorId.value')}`}
																				filterProperty='Line1'
																				APICaller={(filterModel) =>
																					getAllAddressByCompany(
																						filterModel,
																						contractForm.watch('payrollVendorId.value') || ''
																					)
																				}
																				optionGenerator={
																					companyAddressOptionGenerator
																				}
																				isDisabled={!contractForm.watch('payrollVendorId.value')}
																				dropDownsize={50}
																				isClearable={true}
																				isSearchText={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																					contractForm.trigger('payrollVendorAddressId.value');
																				}}
																				cacheOptions={false}
																				defaultOptions
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																			/>
																			{contractForm.watch('payrollVendorId.value') && <div style={{float:'right'}}>
																				<Box sx={{mt:0.9}}>
																					<AddCompanyAddressInline
																						companyId={contractForm.watch('payrollVendorId.value')}
																						title='ADDRESS'
																						isOpen={addressPopOver?.isOpen}
																						event={addressPopOver?.event}
																						onAddressCreated={(d) => onPayrollVendorAddressCreated(d)}
																						onClose={() => setAddressPopOver({ isOpen: false })}
																						onPopOverOpen={(e) => setAddressPopOver({
																							isOpen: true,
																							event: e?.currentTarget,
																						})}
																						isPersistent={true} />
																				</Box>
																			</div>}
																		</>
																	
																	)}
																/>
																{contractForm.formState.errors?.payrollVendorAddressId?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors
																				?.payrollVendorAddressId?.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Status
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='status'
																	render={({ field }) => (
																		<>
																			<NabuSelect
																				{...field}
																				placeholder={!props?.isPrint ? 'Select Status' : ''}
																				options={ContractStatusOptions || []}
																				convertValueToUpperCase={false}
																				onChange={(e) => field.onChange(e)}
																			/>
																		</>
																	)}
																/>
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Union
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='unionType'
																	defaultValue={undefined}
																	render={({ field }) => (
																		<NabuSelect
																			{...field}
																			enableEmptySelection={true}
																			placeholder='Select Union'
																			options={getUnions()}
																		/>
																	)}
																/>
																{contractForm.formState?.errors?.unionType?.message && (
																	<FormHelperText error={true}>
																		{contractForm.formState.errors.unionType.message}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														{getUnionAgreement(contractForm.watch('unionType'))?.length > 0 && (
															<Grid item sm={12} md={6} lg={2.4}>
																<FormControl fullWidth>
																	<FormLabel>
											Union Agreement <sup />
																	</FormLabel>
																	<Controller
																		control={contractForm.control}
																		name='unionAgreement'
																		defaultValue=''
																		render={({ field }) => (
																			<NabuSelect
																				{...field}
																				placeholder='Select Agreement'
																				options={getUnionAgreement(
																					contractForm.watch('unionType')
																				)}
																			/>
																		)}
																	/>
																	{contractForm.formState?.errors?.unionAgreement?.message && (
																		<FormHelperText error={true}>
																			{contractForm.formState.errors.unionAgreement.message}
																		</FormHelperText>
																	)}
																</FormControl>
															</Grid>
														)}
													</Grid>
												</div>
											)}
											{/* Main Contract Form Start */}
											<>
												<div className={styles.contractFormBlock}>
													<div id={CONTRACT_REF_ID.MAIN_FORM} className={styles.bgWhite} style={{padding:'20px'}}>
														<table
															className={`${styles.contractForms} keep-together`}
															width={'100%'}
														>
															<tbody>
																<tr>
																	<h4>{contractForm.watch('contractId') ? `#${contractForm.watch('contractId')}` : ''}</h4>
																</tr>
																<tr>
																	<td style={{ width: '50%' }}>
																		<h2>
																		2022 Standard SAG-AFTRA<br></br>
																		Employment Contract
																		</h2>{' '}
																	</td>
																	<td style={{ width: '50%' }}>
																		<table width={'100%'} border={1} cellPadding={0} cellSpacing={0}
																		>
																			<tr style={{ background: '#808080' }}>
																				<th align='left' colSpan={2}>
																				PRODUCER TO COMPLETE
																				</th>
																			</tr>
																			<tr>
																				<td style={{ width: '30%' }}>Date</td>
																				<td style={{ width: '70%' }}>
																					<FormControl
																						fullWidth
																						className={styles.tableForms}
																					>
																						{props?.isPrint ? (
																							<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('producerDate')) || ''}</p>
																						) :(<Controller
																							control={contractForm.control}
																							name='producerDate'
																							render={({ field }) => (
																								<NabuDatepicker
																									{...field}
																									disabled={props?.isPrint}
																								/>
																							)}
																						/>)}
																						{contractForm.formState?.errors
																							?.producerDate?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors
																										?.producerDate.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																			<tr>
																				<td>Job#<sup>*</sup></td>
																				<td>
																					<FormControl fullWidth className={styles.tableForms}>
																						<Controller
																							control={contractForm.control}
																							name='jobTitle'
																							defaultValue=''
																							render={({ field }) => (
																								<NabuTextbox
																									{...field}
																									disabled={props?.isPrint}
																								/>
																							)}
																						/>
																						{contractForm.formState.errors
																							?.jobTitle?.message && (
																							<FormHelperText
																								data-cy='errorHelper-jobTitle'
																								error={true}
																							>
																								{
																									contractForm.formState?.errors
																										?.jobTitle.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																			<tr>
																				<td>Please return to</td>
																				<td>
																					<FormControl
																						className={styles.tableForms}
																						fullWidth
																					>
																						<Controller
																							key={`select-email-${contractForm.watch(
																								'advertiserId.value'
																							)}-${contractForm.watch(
																								'adAgencyId.value'
																							)}-${contractForm.watch('returnToEmail')}`}
																							control={contractForm.control}
																							name='returnToEmail'
																							defaultValue=''
																							render={({ field }) => (
																								<>
																									<NabuSelect
																										{...field}
																										key={`select-email-${contractForm.watch(
																											'advertiserId.value'
																										)}-${contractForm.watch(
																											'adAgencyId.value'
																										)}-${contractForm.watch('returnToEmail')}`}
																										defaultCase={true}
																										placeholder={!props?.isPrint ? 'Select Email' : ''}
																										convertValueToUpperCase={false}
																										enableEmptySelection={true}
																										disabled={
																											props?.isPrint 
																										}
																										onChange={(e: any) => {
																											field.onChange(e);
																										}}
																										options={emailsForCompany}
																									/>
																								</>
																							)}
																						/>
																						{contractForm.formState?.errors
																							?.returnToEmail?.message && (
																							<FormHelperText
																								data-cy='errorHelper_first_session_date'
																								error={true}
																							>
																								{
																									contractForm.formState?.errors
																										?.returnToEmail?.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																		</table>
																	</td>
																</tr>
															</tbody>
														</table>
														<table
															className={`${styles.contractFormsInner} keep-together`}
															width={'100%'}
														>
															<tbody>
																<tr>
																	<td>
																		<h4>ACS Audio Commercials</h4>
																	</td>
																	<td
																		align='right'
																		style={{
																			display: 'flex',
																			justifyContent: 'flex-end',
																		}}
																	>
																		<div style={{ display: 'flex' }}>
																			<Controller
																				control={contractForm.control}
																				name='isJPCAuthorizer'
																				render={({ field }) => (
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							{...field}
																							key={`jpc-${contractForm.watch(
																								'adAgencyId.value'
																							)}-${contractForm.watch(
																								'advertiserId.value'
																							)}`}
																							value={`${field.value}`}
																							checked={field.value || false}
																							onChange={undefined}
																							type='checkbox'
																							style={{
																								marginTop: '3px',
																							}}
																						/>
																						<span>
																						JPC Authorizer Agency or Advertiser
																						</span>
																					</Box>
																				)}
																			/>
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>
														<table
															className={`${styles.contractFormsInner} keep-together`}
															width={'100%'}
														>
															<tbody>
																<tr>
																	<td style={{ width: '50%', color: '#515151' }}>
																		<p>
																			<span
																				key={`${contractForm.watch(
																					'payrollVendorId.label'
																				)}-${contractForm.watch('payrollVendorAddressId.label')}`}
																			>
																				{contractForm.watch('payrollVendorId.label')}, 
																				{!contractForm.watch('payrollVendorAddressId.label') ? '' : `${contractForm.watch('payrollVendorAddressId.label')}, ` } 
																			</span>
																			<br /> is the Employer of Record solely
																		for the purpose
																			<br></br>
																		of taxes, workers’ compensation and
																		unemployment insurance.
																		</p>
																		<br></br>
																	</td>
																	<td style={{ width: '50%' }}>
																		<table width={'100%'}>
																			<tr>
																				<td style={{ width: '30%' }}>
																				Between (Ad Agency )<sup>*</sup>:
																				</td>
																				<td style={{ width: '70%' }}>
																					<FormControl
																						fullWidth
																						className={styles.tableForms}
																					>
																						<Controller
																							control={contractForm.control}
																							name='adAgencyId'
																							render={({ field }) => (
																								<>
																									<LookAheadSelect
																										data-cy='ad_agency'
																										{...field}
																										placeholder={!props?.isPrint ? 'Ad-Agency' : ''}
																										filterProperty='CompanyNames.Name'
																										APICaller={
																											getCompanyBasicDetails
																										}
																										optionGenerator={
																											companyOptionGenerator
																										}
																										defaultFilter={
																											AD_AGENCY_FILTER
																										}
																										isClearable={true}
																										isDisabled={props?.isPrint}
																										onChange={(e: any) => {
																											field.onChange(e);
																											setJPCAuthorizer();
																										}}
																										cacheOptions
																										defaultOptions
																										className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																									/>
																									{!props?.isPrint && (
																										<Box sx={{ mt: 1.3 }}>
																											<AddCompanyInline
																												title='Company'
																												companyType={{
																													mainType:
																													'AD AGENCY' as CompanyMainType,
																													subType: [],
																												}}
																											/>
																										</Box>
																									)}
																								</>
																							)}
																						/>
																						{contractForm.formState.errors
																							?.adAgencyId?.message && (
																							<FormHelperText
																								error={true}
																								data-cy='errorHelper_ad_agency'
																							>
																								{
																									contractForm.formState?.errors
																										?.adAgencyId?.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																			<tr>
																				<td style={{ width: '30%' }}>
																				and (Performer)<sup>*</sup>:
																				</td>
																				<td style={{ width: '70%' }}>
																					<FormControl
																						fullWidth
																						className={styles.tableForms}
																					>
																						<Controller
																							control={contractForm.control}
																							name='performerId'
																							render={({ field }) => (
																								<>
																									<LookAheadSelect
																										data-cy='name_select'
																										{...field}
																										key={`performer-name
																										-${contractForm.watch('performerId.value')}
																										-${contractForm.watch('performerPhoneId')}-${contractForm.watch('performerEmailId')}`}
																										isClearable={true}
																										placeholder={!props?.isPrint ? 'Select Performer' : ''}
																										filterProperty='PersonName.FirstName'
																										APICaller={(
																											filterModel,
																											searchText
																										) =>
																											GetByWithFullNameSearchForBasicDetail(
																												filterModel,
																												searchText
																											)
																										}
																										isSearchText={true}
																										isDisabled={props?.isPrint}
																										optionGenerator={
																											performerOptionGenerator
																										}
																										defaultFilter={getPerformerFilter()}
																										onChange={(e: any) => {
																											field.onChange(e);
																											contractForm.setValue('performerPrintName', '',{ shouldDirty: true });
																											contractForm.setValue('performerAddressId', null, { shouldDirty: true});
																											contractForm.setValue('performerAdAgencyId',null,{shouldDirty:true});
																											contractForm.setValue('performerAdAgencyAddressId',null,{shouldDirty:true});
																										}}
																										cacheOptions
																										defaultOptions
																										className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																									/>
																									{!props?.isPrint && (
																										<Box>
																											<AddPersonInline
																												title='Person'
																												onPersonCreated={() => ({})}
																												initialValues={{
																													personType: ['TALENT'],
																													isMinor: false,
																												}}
																											/>
																										</Box>
																									)}
																								</>
																							)}
																						/>
																						{contractForm.formState?.errors
																							?.performerId?.message && (
																							<FormHelperText
																								data-cy='errorHelper_first_session_date'
																								error={true}
																							>
																								{
																									contractForm.formState?.errors
																										?.performerId?.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																		</table>
																	</td>
																</tr>
															</tbody>
														</table>

														<table
															width={'100%'}
															border={1}
															cellPadding={0}
															cellSpacing={0}
															className={`${styles.contractFormsInner} keep-together`}
														>
															<tbody>
																<tr
																	style={{ background: '#515151', color: '#FFF' }}
																>
																	<td colSpan={props?.isPrint ? 5 : 6}>
																		<p>
																		PRODUCER ENGAGES PERFORMER AND PERFORMER
																		AGREES TO PERFORM SERVICES FOR PRODUCER IN
																		AUDIO COMMERCIALS AS FOLLOWS:
																		</p>
																	</td>
																</tr>
																<tr>
																	<td>
																	Commercial Title(s)<sup>*</sup>
																		{ !props?.isPrint && (
																			<Tooltip
																				placement='right-end'
																				title={!contractForm.watch('advertiserId.value') ? 'Select Advertiser before selecting commercials' : 'Commercials are fetched from the selected advertiser.'}
																			>
																				<HelpOutline fontSize='inherit' />
																			</Tooltip>)
																		}
																	</td>
																	<td>Ad-ID(s)</td>
																	<td># Spots</td>
																	<td># Tags</td>
																	<td># Demos</td>
																	{!props?.isPrint && <td></td>}
																</tr>
																{commercialFieldArray.fields.map(
																	(field, index) => (
																		<tr key={`commercial-${field.id}`}>
																			<td>
																				<FormControl
																					fullWidth
																					className={styles.tableForms}
																				>
																					<Controller
																						control={contractForm.control}
																						name={`commercials.${index}.assetId`}
																						render={({ field }) => (
																							<>
																								<LookAheadSelect
																									data-cy='source-asset'
																									{...field}
																									ref={field.ref}
																									placeholder={!props?.isPrint ? 'Commercial' : ''}
																									filterProperty='Title'
																									isDisabled={props?.isPrint || !contractForm.watch('advertiserId.value')}
																									defaultFilter={getCommercialFilters()}
																									APICaller={(filterModel) => getAllAssetForBasicDetail( filterModel, false)}
																									optionGenerator={lookAheadAssetOptionGenerator}
																									onChange={(e: any) => { 
																										handleCommercialChange(e?.value); 
																										handleProducerChange(index);
																										field.onChange(e);}}
																									isClearable={true}
																									cacheOptions
																									defaultOptions
																									dropDownsize={25}
																									className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																								/>
																								{!props?.isPrint && (
																									<Box sx={{mt: 1}}>
																										<AddAssetInline
																											title='Asset' />
																									</Box>
																								)}
																							</>
																						)}
																					/>
																					{contractForm.formState?.errors
																						?.commercials?.length && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState.errors
																									?.commercials[index]?.assetId?.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																			<td>
																				<FormControl
																					fullWidth
																					className={styles.tableForms}
																				>
																					<NabuTextbox
																						value={getAssetAdIdByAssetIndex(
																							index
																						)}
																					/>
																				</FormControl>
																			</td>
																			<td>
																				<FormControl
																					fullWidth
																					className={styles.tableForms}
																				>
																					<Controller
																						control={contractForm.control}
																						name={`commercials.${index}.spots`}
																						defaultValue=''
																						render={({ field }) => (
																							<NabuTextbox
																								{...field}
																								placeholder={!props?.isPrint ? 'Spots' : ''}
																								disabled={props?.isPrint}
																							/>
																						)}
																					/>
																					{contractForm.formState?.errors
																						?.commercials?.length && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState.errors
																									?.commercials[index]?.spots
																									?.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																			<td>
																				<FormControl
																					fullWidth
																					className={styles.tableForms}
																				>
																					<Controller
																						control={contractForm.control}
																						name={`commercials.${index}.tags`}
																						defaultValue=''
																						render={({ field }) => (
																							<NabuTextbox
																								{...field}
																								placeholder={!props?.isPrint ? 'Tags' : ''}
																								disabled={props?.isPrint}
																							/>
																						)}
																					/>
																					{contractForm.formState?.errors
																						?.commercials?.length && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState.errors
																									?.commercials[index]?.tags
																									?.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																			<td>
																				<FormControl
																					fullWidth
																					className={styles.tableForms}
																				>
																					<Controller
																						control={contractForm.control}
																						name={`commercials.${index}.demos`}
																						defaultValue=''
																						render={({ field }) => (
																							<NabuTextbox
																								{...field}
																								placeholder={!props?.isPrint ? 'Demos' : ''}
																								disabled={props?.isPrint}
																							/>
																						)}
																					/>
																					{contractForm.formState?.errors
																						?.commercials?.length && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState.errors
																									?.commercials[index]?.demos
																									?.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																			{!props?.isPrint && (
																				<>
																					<td style={{ display: 'flex' }}>
																						{commercialFieldArray.fields.length -
																						1 ===
																						index && (
																							<NabuButton
																								variant='text'
																								onClick={onAddCommercial}
																							>
																								<Add />
																							</NabuButton>
																						)}
																						{commercialFieldArray?.fields.length >
																						1 && (
																							<NabuButton
																								variant='text'
																								onClick={() =>{
																									handleProducerChange(index);
																									onRemoveCommercial(index);
																								}}
																							>
																								<DeleteForever color='error' />
																							</NabuButton>
																						)}
																					</td>
																				</>
																			)}
																		</tr>
																	)
																)}
																<tr>
																	<td colSpan={3}>
																	(Such commercial(s) are to be produced by) Ad
																	Agency<sup>*</sup>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='adAgencyId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							data-cy='ad_agency'
																							{...field}
																							placeholder={!props?.isPrint ? 'Ad-Agency' : ''}
																							filterProperty='CompanyNames.Name'
																							APICaller={getCompanyBasicDetails}
																							optionGenerator={
																								companyOptionGenerator
																							}
																							defaultFilter={AD_AGENCY_FILTER}
																							isDisabled={props?.isPrint}
																							isClearable={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																								setJPCAuthorizer();
																								contractForm.resetField(
																									'adAgencyAddressId'
																								);
																							}}
																							cacheOptions
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{!props?.isPrint && (
																							<Box sx={{ mt: 1.3 }}>
																								<AddCompanyInline
																									title='Company'
																									companyType={{
																										mainType:
																										'AD AGENCY' as CompanyMainType,
																										subType: [],
																									}}
																								/>
																							</Box>
																						)}
																					</>
																				)}
																			/>
																			<FormHelperText
																				error={true}
																				data-cy='errorHelper_ad_agency'
																			>
																				{
																					contractForm.formState?.errors
																						?.adAgencyId?.value?.message || contractForm.formState?.errors?.adAgencyId?.message
																				}
																			</FormHelperText>
																		</FormControl>
																	</td>
																	<td colSpan={2}>Address<sup></sup>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				key={`agency-address-cr-${contractForm.watch(
																					'adAgencyId.value'
																				)}`}
																				name='adAgencyAddressId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							{...field}
																							placeholder={!props?.isPrint ? 'Select Address' : ''}
																							key={`agency-address-${contractForm.watch(
																								'adAgencyId.value'
																							)}`}
																							filterProperty='Line1'
																							sortField='IsPrimary'
																							sortDescending={true}
																							onOptionLoaded={(options) => {
																								if(options?.length && !contractForm.watch('adAgencyAddressId.value')){
																									contractForm.setValue('adAgencyAddressId', options[0], { shouldDirty: true});
																									contractForm.trigger('adAgencyAddressId', { shouldFocus: true});																						
																								}
																							}}
																							APICaller={(filterModel) =>
																								getAllAddressByCompany(
																									filterModel,
																									contractForm.watch('adAgencyId.value') ||
																					''
																								)
																							}
																							optionGenerator={
																								adAgencyAddressOptionGenerator
																							}
																							isDisabled={
																								!contractForm.watch('adAgencyId.value') ||
																			props?.isPrint
																							}
																							dropDownsize={50}
																							isClearable={true}
																							isSearchText={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																							}}
																							cacheOptions={false}
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{contractForm.watch('adAgencyId.value')&& (!props.isPrint) && <div style={{float:'right'}}>
																							<Box sx = {{mt: 0.9}}>
																								<AddCompanyAddressInline
																									companyId= {contractForm.watch('adAgencyId.value')}
																									title='ADDRESS'
																									isOpen = {addressPopOver?.isOpen && addressPopOver?.for =='AdAgency-address'}
																									event={addressPopOver?.event}
																									onAddressCreated={(d) => onAdAgencyAddressCreated(d)}
																									onClose={() => setAddressPopOver({isOpen: false})}
																									onPopOverOpen={(e) => setAddressPopOver({
																										isOpen: true,
																										event: e?.currentTarget,
																										for: 'AdAgency-address'
																									})}
																									isPersistent={true} />
																							</Box>
																						</div>}
																					</>
																				)}
																			/>
																			{contractForm.formState.errors?.adAgencyAddressId?.message && (
																				<FormHelperText
																					error={true}
																					data-cy='errorHelper_ad_agency'
																				>
																					{
																						contractForm.formState?.errors
																							?.adAgencyAddressId?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																</tr>
																<tr>
																	<td colSpan={3}>
																	(Acting as agent for) Advertiser<sup>*</sup>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='advertiserId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							{...field}
																							placeholder={!props?.isPrint ? 'Advertiser' : ''}
																							filterProperty='CompanyNames.Name'
																							APICaller={getCompanyBasicDetails}
																							optionGenerator={
																								companyOptionGenerator
																							}
																							defaultFilter={ADVERTISER_FILTER}
																							isDisabled={props?.isPrint}
																							isClearable={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																								contractForm.setValue('commercials', [{} as any], { shouldDirty: true});
																								contractForm.setValue('productId', null, { shouldDirty: true});
																								contractForm.setValue('producerCompanyId',null,{shouldDirty:true});
																								contractForm.setValue('producerCompanyAddressId',null,{shouldDirty:true});
																								contractForm.setValue('producerCompanyEmailId','',{shouldDirty:true});
																								contractForm.setValue('producerCompanyPhoneId','',{shouldDirty:true});
																								setJPCAuthorizer();
																							}}
																							cacheOptions
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{!props?.isPrint && (
																							<Box sx={{ mt: 1.3 }}>
																								<AddCompanyInline
																									title='Company'
																									companyType={{
																										mainType:
																										'ADVERTISER' as CompanyMainType,
																										subType: [],
																									}}
																								/>
																							</Box>
																						)}
																					</>
																				)}
																			/>
																			{contractForm.formState.errors?.advertiserId?.message && (
																				<FormHelperText
																					error={true}
																					data-cy='errorHelper_ad_agency'
																				>
																					{
																						contractForm.formState?.errors
																							?.advertiserId?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td colSpan={2}>
																	Products
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='productId'
																				render={({ field }) => (
																					<LookAheadSelect
																						{...field}
																						placeholder={!props?.isPrint ? 'Select Product' : ''}
																						key={`product-${contractForm.watch(
																							'advertiserId.value'
																						)}`}
																						isMulti
																						filterProperty='ProductName'
																						APICaller={(filterModel) =>
																							getAllProducts(
																								filterModel,
																								contractForm.watch(
																									'advertiserId.value'
																								)
																							)
																						}
																						optionGenerator={
																							productOptionGenerator
																						}
																						isDisabled={
																							!contractForm.watch(
																								'advertiserId.value'
																							) || props?.isPrint
																						}																					
																						defaultFilter={PRODUCT_FILTER}
																						dropDownsize={20}
																						isClearable={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																						}}
																						cacheOptions
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																				)}
																			/>
																		</FormControl>
																	</td>
																</tr>
																<tr>
																	<td colSpan={3}>
																		<p>
																			<b>
																			City, State (In which services rendered):
																			</b>
																		</p>
																		<div
																			style={{
																				marginTop: '7px',
																				display: 'inline-block',
																			}}
																		>
																		State<sup>*</sup>:
																		</div>
																		<FormControl
																			className={styles.tableForms}
																			style={{ width: '35%' }}
																		>
																			<Controller
																				control={contractForm.control}
																				name='serviceState'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuSelect
																						{...field}
																						options={getStateOptions()}
																						disabled={props?.isPrint}
																						convertValueToUpperCase={false}
																						placeholder={!props?.isPrint ? 'Select State' : ''}
																					/>
																				)}
																			/>
																			{contractForm?.formState?.errors
																				?.serviceState?.message && (
																				<FormHelperText
																					data-cy='errorHelper_location_state'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.serviceState.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>{' '}
																	&nbsp;
																		<div
																			style={{
																				marginTop: '7px',
																				display: 'inline-block',
																			}}
																		>
																		City<sup>*</sup>:{' '}
																		</div>
																		<FormControl
																			className={styles.tableForms}
																			style={{ width: '35%' }}
																		>
																			<Controller
																				control={contractForm.control}
																				name='serviceCity'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuSelect
																						{...field}
																						disabled={props?.isPrint}
																						options={getCityOptions(
																							contractForm.watch('serviceState')
																						)}
																						convertValueToUpperCase={true}
																						placeholder={!props?.isPrint ? 'Select City' : ''}
																					/>
																				)}
																			/>
																			{contractForm.formState?.errors?.serviceCity
																				?.message && (
																				<FormHelperText
																					data-cy='errorHelper_location_city'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.serviceCity.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td colSpan={2}>
																		<div>Place of Engagement<sup>*</sup></div>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='placeOfEngagement'
																				render={({ field }) => (
																					<LocationFreeTextSelect
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'Place of Engagement' : ''}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.placeOfEngagement?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.placeOfEngagement.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																</tr>
																<tr>
																	<td colSpan={5}>
																		<p>
																			<b>Performer Type</b>
																		</p>
																		<table
																			width={'100%'}
																			cellPadding={0}
																			cellSpacing={0}
																			className={styles.contractFormsInner}
																		>
																			<tr>
																				<td>
																					<div
																						style={{
																							display: 'inline-flex',
																							alignItems: 'center',
																						}}
																					>
																						<Controller
																							control={contractForm.control}
																							name='performerType'
																							// defaultValue={'ANNOUNCER'}
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('ANNOUNCER')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='ANNOUNCER'
																											checked={field.value === 'ANNOUNCER'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{
																												marginTop: '3px',
																											}}
																										/>
																										<span>Announcer</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																				<td>
																					<div
																						style={{
																							display: 'inline-flex',
																							alignItems: 'center',
																						}}
																					>
																						<Controller
																							control={contractForm.control}
																							name='performerType'
																							// defaultValue={'SINGER'}
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('SINGER')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='SINGER'
																											checked={field.value === 'SINGER'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{
																												marginTop: '3px',
																											}}
																										/>
																										<span>Singer</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																				<td>
																					<div
																						style={{
																							display: 'inline-flex',
																							alignItems: 'center',
																						}}
																					>
																						<Controller
																							control={contractForm.control}
																							name='performerType'
																							// defaultValue={'SOLO OR DUO'}
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('SOLO_OR_DUO')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='SOLO_OR_DUO'
																											checked={field.value === 'SOLO_OR_DUO'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{
																												marginTop: '3px',
																											}}
																										/>
																										<span>Solo Or Duo</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																				<td>
																					<div
																						style={{
																							display: 'inline-flex',
																							alignItems: 'center',
																						}}
																					>
																						<Controller
																							control={contractForm.control}
																							name='performerType'
																							// defaultValue={'GROUP 6-8'}
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('GROUP_6_8')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='GROUP_6_8'
																											checked={field.value === 'GROUP_6_8'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{
																												marginTop: '3px',
																											}}
																										/>
																										<span>Group 6-8</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																				<td>
																					<div
																						style={{
																							display: 'inline-flex',
																							alignItems: 'center',
																						}}
																					>
																						<Controller
																							control={contractForm.control}
																							name='performerType'
																							// defaultValue={'CONTRACTOR'}
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('CONTRACTOR')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='CONTRACTOR'
																											checked={field.value === 'CONTRACTOR'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{
																												marginTop: '3px',
																											}}
																										/>
																										<span>Contractor</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																				<td>
																					<div
																						style={{
																							display: 'inline-flex',
																							alignItems: 'center',
																						}}
																					>
																						<Controller
																							control={contractForm.control}
																							name='performerType'
																							// defaultValue={'SPANISH_LANGUAGE_TRANSLATION_SERVICES_PERFORMED'}
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('SPANISH_LANGUAGE_TRANSLATION_PERFORMED')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='SPANISH_LANGUAGE_TRANSLATION_PERFORMED'
																											checked={field.value === 'SPANISH_LANGUAGE_TRANSLATION_PERFORMED'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{
																												marginTop: '3px',
																											}}
																										/>
																										<span>Spanish Language Translation Services Performed</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td>
																					<div
																						style={{
																							display: 'inline-flex',
																							alignItems: 'center',
																						}}
																					>
																						<Controller
																							control={contractForm.control}
																							name='performerType'
																							// defaultValue={'ACTOR_ACTRESS'}
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('ACTOR')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='ACTOR'
																											checked={field.value === 'ACTOR'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{
																												marginTop: '3px',
																											}}
																										/>
																										<span>Actor, Actress</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																				<td>
																					<div
																						style={{
																							display: 'inline-flex',
																							alignItems: 'center',
																						}}
																					>
																						<Controller
																							control={contractForm.control}
																							name='performerType'
																							// defaultValue={'GROUP_SPEAKER'}
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('GROUP_SPEAKER')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='GROUP_SPEAKER'
																											checked={field.value === 'GROUP_SPEAKER'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{
																												marginTop: '3px',
																											}}
																										/>
																										<span>Group Speaker</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																				<td>
																					<div
																						style={{
																							display: 'inline-flex',
																							alignItems: 'center',
																						}}
																					>
																						<Controller
																							control={contractForm.control}
																							name='performerType'
																							// defaultValue={'GROUP_3_5'}
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('GROUP_3_5')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='GROUP_3_5'
																											checked={field.value === 'GROUP_3_5'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{
																												marginTop: '3px',
																											}}
																										/>
																										<span>Group 3-5</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																				<td>
																					<div
																						style={{
																							display: 'inline-flex',
																							alignItems: 'center',
																						}}
																					>
																						<Controller
																							control={contractForm.control}
																							name='performerType'
																							// defaultValue={'SINGER_GROUP_9_MORE'}
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('SINGER_GROUP_9_MORE')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='SINGER_GROUP_9_MORE'
																											checked={field.value === 'SINGER_GROUP_9_MORE'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{
																												marginTop: '3px',
																											}}
																										/>
																										<span>Group 9 or more</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>

																				<td>
																					<div
																						style={{
																							display: 'inline-flex',
																							alignItems: 'center',
																						}}
																					>
																						<Controller
																							control={contractForm.control}
																							name='performerType'
																							// defaultValue={'SOUND_EFFECTS_PERFORMER'}
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange('SOUND_EFFECTS')}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value='SOUND_EFFECTS'
																											checked={field.value === 'SOUND_EFFECTS'}
																											onChange={(e) => field.onChange(e.target.value)}
																											type='radio'
																											disabled={props?.isPrint}
																											style={{
																												marginTop: '3px',
																											}}
																										/>
																										<span>Sound Effects Performer</span>
																									</Box>
																								</div>
																							)}
																						/>
																					</div>
																				</td>
																			</tr>
																		</table>
																	</td>
																</tr>
																<tr>
																	<td colSpan={2}>
																	For Singers, Multiple Tracking or Sweetening
																		<div
																			style={{
																				display: 'flex',
																				alignItems: 'center',
																				gap: '15px',
																			}}
																		>
																			<div
																				style={{
																					display: 'flex',
																					alignItems: 'center',
																				}}
																			>
																				<Controller
																					control={contractForm.control}
																					name='isSingersMultipleTrackingOccur'
																					defaultValue={false}
																					render={({ field }) => (
																						<div
																							onClick={() =>(!props?.isPrint) &&  field.onChange(true)}
																							style={{ userSelect: 'none' }}
																						>
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									{...field}
																									value={field.value + ''}
																									checked={field.value}
																									onChange={() =>
																										field.onChange(true)
																									}
																									type='checkbox'
																									disabled={props?.isPrint}
																									style={{
																										marginTop: '3px',
																									}}
																								/>
																								<span>Did Occur</span></Box>
																						</div>
																					)}
																				/>
																			</div>
																			<div
																				style={{
																					display: 'flex',
																					alignItems: 'center',
																				}}
																			>
																				<Controller
																					control={contractForm.control}
																					name='isSingersMultipleTrackingOccur'
																					defaultValue={false}
																					render={({ field }) => (
																						<div
																							onClick={() =>
																								(!props?.isPrint) && field.onChange(false)
																							}
																							style={{ userSelect: 'none' }}
																						>
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									{...field}
																									value={field.value + ''}
																									checked={!field.value}
																									onChange={() =>
																										field.onChange(false)
																									}
																									type='checkbox'
																									disabled={props?.isPrint}
																									style={{

																										marginTop: '3px',
																									}}
																								/>
																								<span>Did Not Occur</span></Box>
																						</div>
																					)}
																				/>
																			</div>
																		</div>
																	</td>
																	<td colSpan={3}>
																	Sweeten # of Tracks
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='sweetenOfTracks'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						data-cy='job_name_textbox'
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'Sweeten # of Tracks' : ''}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.sweetenOfTracks?.message && (
																				<FormHelperText
																					data-cy='errorHelper-job-title-name'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.sweetenOfTracks.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																</tr>
																<tr>
																	<td colSpan={2}>
																	Compensation{ !props?.isPrint && (
																			<Tooltip
																				placement='right-end'
																				title={'You can manually Enter the required option'}
																			>
																				<HelpOutline fontSize='inherit' />
																			</Tooltip>)
																		}<sup>*</sup>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='compensation'
																				defaultValue='SCALE'
																				render={({ field }) => (
																					<NabuDynamicInputDropdown
																						{...field}
																						disabled={props?.isPrint}
																						options={COMPENSATION_OPTIONS}
																						placeholder={!props?.isPrint ? 'Compensation' : ''}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors?.compensation
																				?.message && (
																				<FormHelperText
																					data-cy='errorHelper-job-title-name'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.compensation.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td>
																	Part to be Played
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='partPlayed'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						data-cy='job_name_textbox'
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'Part to be Played' : ''}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors?.partPlayed
																				?.message && (
																				<FormHelperText
																					data-cy='errorHelper-job-title-name'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.partPlayed.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td colSpan={2}>
																	Date(s) and Hour(s) of Employment<sup>*</sup>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='dateAndHourEmployment'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						data-cy='job_name_textbox'
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'Date(s) and Hour(s) of Employment' : ''}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.dateAndHourEmployment?.message && (
																				<FormHelperText
																					data-cy='errorHelper-job-title-name'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.dateAndHourEmployment.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																</tr>
																<tr>
																	<td colSpan={2}>
																		<p>Please check if applicable:</p>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isInitialUseOnInternet'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() =>
																							(!props?.isPrint) && field.onChange(!field.value)
																						}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{
																									marginTop: '3px',
																								}}
																							/>
																							<span>
																						Commercial(s) made for Initial Use
																						on the Internet
																							</span></Box>
																					</div>
																				)}
																			/>
																		</div>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isInitialUseOnNewMedia'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() =>
																							(!props?.isPrint) && field.onChange(!field.value)
																						}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{
																									marginTop: '3px',
																								}}
																							/>
																							<span>
																						Commercial(s) made for Initial Use
																						on New Media
																							</span></Box>
																					</div>
																				)}
																			/>
																		</div>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isAudioFlexPaymentPackage'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() =>
																							(!props?.isPrint) && field.onChange(!field.value)
																						}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{
																									marginTop: '3px',
																								}}
																							/>
																							<span>
																						Audio Flex Payment Package{' '}
																							</span></Box>
																					</div>
																				)}
																			/>
																		</div>
																	</td>
																	<td colSpan={3}>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isPerformerConcentToUseOnInternet'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() =>
																							(!props?.isPrint) && field.onChange(!field.value)
																						}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{
																									marginTop: '3px',
																								}}
																							/>
																							<span>
																						Performer does not consent to the
																						use of his/her services in
																						commercials made hereunder on the
																						Internet.
																							</span></Box>
																					</div>
																				)}
																			/>
																		</div>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='isPerformerConcentToUseOnNewMedia'
																				defaultValue={false}
																				render={({ field }) => (
																					<div
																						onClick={() =>
																							(!props?.isPrint) && field.onChange(!field.value)
																						}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => field.onChange(e)}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{
																									marginTop: '3px',
																								}}
																							/>
																							<span>
																						Performer does not consent to the
																						use of his/her services in
																						commercials made hereunder in New
																						Media.
																							</span></Box>
																					</div>
																				)}
																			/>
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>
														<br></br>
														<table
															width={'100%'}
															cellPadding={0}
															cellSpacing={0}
															className={`${styles.contractFormsInner} keep-together`}
														>
															<tr>
																<td>
																	<p>
																	The standard provisions printed on the reverse
																	side hereof are a part of this contract. If
																	this contract provides for compensation at
																	SAG-AFTRA minimum, no addition, changes or
																	alterations may be made in this form other
																	than those which are more favorable to the
																	Performer than herein provided. If this
																	contract provides for compensation above the
																	SAG-AFTRA minimum, additions may be agreed to
																	between Producer and Performer which do not
																	conflict with the provisions of the SAG- AFTRA
																	Audio Commercials Contract, provided that such
																	additional provisions are separately set forth
																	under “Special Provisions” hereof and signed
																	by the Performer.
																	</p>
																</td>
															</tr>
														</table>
														<br></br>
														<table
															width={'100%'}
															cellPadding={0}
															cellSpacing={0}
															border={1}
															className={`${styles.contractFormsInner} keep-together`}
														>
															<tr>
																<td
																	style={{ background: '#515151', color: '#FFF' }}
																	colSpan={3}
																>
																SPECIAL PROVISIONS (Including adjustments, if
																any, for Stunt Performers)
																</td>
															</tr>
															<tr>
																<td colSpan={3}>
																	{
																		props?.isPrint ? (
																			<p className={styles.descriptionPrintText}>{contractForm.watch('specialProvisions')}</p>
																		) : (<div className={styles.textareaSection}> 
																			<Controller
																				control={contractForm.control}
																				name='specialProvisions'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuBaseTextArea
																						className={styles.specialProvision}
																						data-cy='job_name_textbox'
																						{...field}
																						rows={5}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'SPECIAL PROVISIONS' : ''}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.specialProvisions?.message && (
																				<FormHelperText
																					data-cy='errorHelper-job-title-name'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.specialProvisions.message
																					}
																				</FormHelperText>
																			)}
																		</div> )
																	}

																</td>
															</tr>
															<tr>
																<td width={'50%'} colSpan={2}>
																	<p>
																	Performer acknowledges that he/she has read
																	all the terms and conditions in the Special
																	Provisions section above and hereby agrees
																	thereto:
																	</p>
																</td>
																<td>
																Performer Signature
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<NabuTextbox disabled />
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td colSpan={3}>
																	<p>
																	Until Performer shall otherwise direct in
																	writing, Performer authorizes Producer to make
																	all payments to which Performer may be
																	entitled hereunder by check payable to
																	Performer and sent to the SAG-AFTRA office
																	nearest the city in which the commercial was
																	made, or as follows:
																	</p>
																</td>
															</tr>
															<tr>
																<td colSpan={2}>
																	<div
																		style={{
																			display: 'inline-flex',
																			alignItems: 'center',
																		}}
																	>
																		<Controller
																			control={contractForm.control}
																			name='isPaymentAtPerformerAddress'
																			// defaultValue={true}
																			render={({ field }) => (
																				<div
																					onClick={() =>{
																						(!props?.isPrint) && field.onChange(!field.value);
																						contractForm.setValue('isPaymentAtPerformerAgencyAddress', !contractForm.watch('isPaymentAtPerformerAddress'));
																					}}
																					style={{ userSelect: 'none' }}
																				>
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							{...field}
																							value={field.value + ''}
																							checked={field.value}
																							onChange={(e) => {
																								field.onChange(e);
																								contractForm.setValue('isPaymentAtPerformerAgencyAddress', !contractForm.watch('isPaymentAtPerformerAddress'));
																							}}
																							type='checkbox'
																							disabled={props?.isPrint}
																							style={{
																								marginTop: '3px',
																							}}
																						/>
																						<span>
																							<b>To Perfomer at (Address):</b>
																						</span>
																					</Box>
																				</div>
																			)}
																		/>
																	</div>
																</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			key={`select_Address_${contractForm.watch(
																				'performerId.value'
																			)}`}
																			name='performerAddressId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						{...field}
																						placeholder={!props?.isPrint ? 'Select Address' : ''}
																						key={`performer-address-${contractForm.watch(
																							'performerId.value'
																						)}`}
																						filterProperty='Line1'
																						sortField='IsPrimary'
																						sortDescending={true}
																						onOptionLoaded={(options) => {
																							if(options?.length && !contractForm.watch('performerAddressId.value')){
																								contractForm.setValue('performerAddressId', options[0], { shouldDirty: true});
																								contractForm.trigger('performerAddressId', { shouldFocus: true});																						
																							}
																						}}
																						APICaller={(filterModel) =>
																							getAllAddressByPerson(
																								filterModel,
																								contractForm.watch(
																									'performerId.value'
																								) || ''
																							)
																						}
																						optionGenerator={
																							adAgencyAddressOptionGenerator
																						}
																						dropDownsize={50}
																						isDisabled={
																							props?.isPrint ||
																						!contractForm.watch(
																							'performerId.value'
																						)
																						}
																						isClearable={true}
																						isSearchText={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																						}}
																						cacheOptions={false}
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{																							
																						<Box sx={{mt:1.3}}>
																							{(contractForm.watch('performerId.value') && (!props.isPrint)) && 
																							<AddPersonAddressInline
																								personId={contractForm.watch('performerId.value')}
																								title='ADDRESS'
																								isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'performer-address' || false}
																								event={addressPopOver?.event}
																								onAddressCreated={(d) => onPersonAddressCreated(d)}
																								onClose={() => setAddressPopOver({ isOpen: false })}
																								onPopOverOpen={(e) => setAddressPopOver({
																									isOpen: true,
																									event: e?.currentTarget,
																									for: 'performer-address'
																								})}
																								isPersistent={true} />
																							}
																						</Box>
																					}
																				</>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.performerAddressId?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState.errors
																						?.performerAddressId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td colSpan={2}>
																	<div
																		style={{
																			display: 'inline-flex',
																			alignItems: 'center',
																		}}
																	>
																		<Controller
																			control={contractForm.control}
																			name='isPaymentAtPerformerAgencyAddress'
																			defaultValue={false}
																			render={({ field }) => (
																				<div
																					onClick={() =>{
																						(!props?.isPrint) && field.onChange(!field.value);
																						contractForm.setValue('isPaymentAtPerformerAddress', !contractForm.watch('isPaymentAtPerformerAgencyAddress'));
																					}}
																					style={{ userSelect: 'none' }}
																				>
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							{...field}
																							value={field.value + ''}
																							checked={field.value}
																							onChange={(e) => {
																								field.onChange(e);
																								contractForm.setValue('isPaymentAtPerformerAddress', !contractForm.watch('isPaymentAtPerformerAgencyAddress'));
																							}}
																							type='checkbox'
																							disabled={props?.isPrint}
																							style={{
																								marginTop: '3px',
																							}}
																						/>
																						<span>
																					To Performer c/o (Talent Agency Name
																					and Address):
																						</span></Box>
																				</div>
																			)}
																		/>
																	</div>
																</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			key={`agency-${contractForm.watch('performerId.value')}`}
																			control={contractForm.control}
																			name='performerAdAgencyId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						{...field} 
																						key={`agency-${contractForm.watch('performerId.value')}`}
																						placeholder='Agency'
																						filterProperty='CompanyNames.Name'
																						APICaller={(filter) => {
																							filter.filters = filter.filters?.map((f) => f.property == 'CompanyNames.Name' ? {...f, value: f?.value?.trim() || '' } : f) || [];
																							return getAgentInfo(filter, contractForm.watch('performerId.value') || '');
																						}}
																						optionGenerator={companyOptionGenerator}
																						onChange={(e: any) => {
																							field.onChange(e);
																							contractForm.setValue('performerAdAgencyAddressId', null, { shouldDirty: true});
																						}}
																						onOptionLoaded={(options) => {
																							if(options?.length && !contractForm.watch('performerAdAgencyId.value')){
																								contractForm.setValue('performerAdAgencyId', options[0], { shouldDirty: true});
																								contractForm.trigger('performerAdAgencyId', { shouldFocus: true});																						
																							}
																						}}
																						cacheOptions
																						isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
																						defaultOptions
																						dropDownsize={25}
																						isClearable={true}
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{!props?.isPrint && contractForm.watch('performerId.value') && (<AddTalentAgentInline
																						onCompanyCreated={(createdAgent) => contractForm.setValue('performerAdAgencyId', createdAgent, { shouldDirty: true})}
																						onClose={() => setCompanyPopoverInline({ isOpen: false})}
																						onPopOverOpen={(e) => setCompanyPopoverInline({ isOpen: true, event: e?.currentTarget })}
																						event={companyPopoverInline?.event}
																						isOpen={companyPopoverInline.isOpen}
																						personId={contractForm.watch('performerId.value') || ''}
																					/>)}
																				</>
																			)}
																		/>
																		{contractForm.formState.errors?.performerAdAgencyId?.message && (
																			<FormHelperText
																				error={true}
																				data-cy='errorHelper_ad_agency'
																			>
																				{
																					contractForm.formState?.errors
																						?.performerAdAgencyId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<Controller
																			control={contractForm.control}
																			key={`select_Address
																			-${contractForm.watch('performerAdAgencyId.value')}
																			-${contractForm.watch('performerAdAgencyPhoneId')}`
																			}
																			name='performerAdAgencyAddressId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						{...field}
																						placeholder={!props?.isPrint ? 'Select Address' : ''}
																						key={`performer-ad-address-${contractForm.watch(
																							'performerAdAgencyId.value'
																						)}`}
																						filterProperty='Line1'
																						sortField='IsPrimary'
																						sortDescending={true}
																						onOptionLoaded={(options) => {
																							if(options?.length && !contractForm.watch('performerAdAgencyAddressId.value')){
																								contractForm.setValue('performerAdAgencyAddressId', options[0], { shouldDirty: true});
																								contractForm.trigger('performerAdAgencyAddressId', { shouldFocus: true});																						
																							}
																						}}
																						isDisabled={props?.isPrint || !contractForm.watch('performerAdAgencyId')}
																						APICaller={(filterModel) =>
																							getAllAddressByCompany(
																								filterModel,
																								contractForm.watch(
																									'performerAdAgencyId.value'
																								) || ''
																							)
																						}
																						optionGenerator={
																							performerAdAgencyAddressOptionGenerator
																						}
																						dropDownsize={50}
																						isClearable={true}
																						isSearchText={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																							contractForm.setValue('performerAdAgencyPhoneId', '', { shouldDirty: true});
																							contractForm.trigger('performerAdAgencyPhoneId');
																						}}
																						cacheOptions
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{
																						<Box sx={{mt:1.3}}>
																							{(contractForm.watch('performerAdAgencyId.value')&& (!props.isPrint)) &&
																							<AddCompanyAddressInline
																								companyId={contractForm.watch('performerAdAgencyId.value')}
																								title = 'ADDRESS'
																								isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'PerformerAdAgency-address'|| false}
																								event={addressPopOver?.event}
																								onAddressCreated={(d) => PerformerAdAgencyAddressCreated(d)}
																								onClose={() => setAddressPopOver({ isOpen: false})}
																								onPopOverOpen={(e) => setAddressPopOver({
																									isOpen: true,
																									event: e?.currentTarget,
																									for: 'PerformerAdAgency-address'
																								})}
																								isPersistent = {true} />
																							}
																						</Box>
																					}
																				</>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.performerAdAgencyAddressId?.value?.message && (
																			<FormHelperText
																				data-cy='errorHelper_?.addressId'
																				error={true}
																			>
																				{
																					contractForm.formState.errors
																						?.performerAdAgencyAddressId?.value?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td colSpan={1}>
															(All notices to) Producer
																	{ !props?.isPrint && (
																		<Tooltip
																			placement='right-end'
																			title={'Signatory'}
																		>
																			<HelpOutline fontSize='inherit' />
																		</Tooltip>)
																	} (Name, Company)<sup></sup>:
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			key={`commercial-assetId
																			-${contractForm.watch(`commercials.${0}.assetId.value`)}
																			`}
																			control={contractForm.control}
																			name='producerCompanyId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						{...field}
																						key={`commercial-assetId
																							-${contractForm.watch(`commercials.${0}.assetId.value`)}
																							`}
																						placeholder={!props?.isPrint ? 'Producer' : ''}
																						filterProperty='CompanyNames.Name'
																						APICaller={(filterModel) => getAllSignatory(contractForm.watch(`commercials.${0}.assetId.value`),filterModel)}
																						optionGenerator={
																							companyOptionGenerator
																						}
																						isClearable={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																							contractForm.setValue('producerCompanyAddressId', null, { shouldDirty: true });
																							contractForm.setValue('producerCompanyEmailId', '', { shouldDirty: true });
																							contractForm.setValue('producerCompanyPhoneId', '', { shouldDirty: true });
																							contractForm.trigger('producerCompanyAddressId');
																							contractForm.trigger('producerCompanyEmailId');
																							contractForm.trigger('producerCompanyPhoneId');
																						}}
																						isDisabled={props?.isPrint}
																						cacheOptions
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{/* {!props?.isPrint && (
																						<Box sx={{ mt: 0.5 }}>
																							<AddCompanyInline title='Company' />
																						</Box>
																					)} */}
																				</>
																			)}
																		/>
																		{contractForm.formState.errors
																			?.producerCompanyId?.message && (
																			<FormHelperText
																				error={true}
																				data-cy='errorHelper-producerCompanyId'
																			>
																				{
																					contractForm.formState?.errors
																						?.producerCompanyId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>															
																</td>
																<td colSpan={1}>
																Producer Address : 
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			key={`producer-address-cr-${contractForm.watch(
																				'producerCompanyId.value'
																			)}`}
																			name='producerCompanyAddressId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						{...field}
																						placeholder={!props?.isPrint ? 'Select Address' : ''}
																						key={`producer-address-${contractForm.watch(
																							'producerCompanyId.value'
																						)}-${contractForm.watch(
																							'producerCompanyPhoneId'
																						)}-${contractForm.watch(
																							'producerCompanyEmailId'
																						)}`}
																						filterProperty='Line1'
																						sortField='IsPrimary'
																						sortDescending={true}
																						onOptionLoaded={(options) => {
																							if(options?.length && !contractForm.watch('producerCompanyAddressId.value')){
																								contractForm.setValue('producerCompanyAddressId', options[0], { shouldDirty: true});
																								contractForm.trigger('producerCompanyAddressId', { shouldFocus: true});																						
																							}
																						}}
																						APICaller={(filterModel) =>
																							getAllAddressByCompany(
																								filterModel,
																								contractForm.watch(
																									'producerCompanyId.value'
																								) || ''
																							)
																						}
																						optionGenerator={
																							producerAddressOptionGenerator
																						}
																						isDisabled={
																							!contractForm.watch(
																								'producerCompanyId.value'
																							) || props?.isPrint
																						}
																						dropDownsize={50}
																						isClearable={true}
																						isSearchText={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																							contractForm.setValue('producerCompanyEmailId', '', { shouldDirty: true });
																							contractForm.setValue('producerCompanyPhoneId', '', { shouldDirty: true });
																							contractForm.trigger('producerCompanyEmailId');
																							contractForm.trigger('producerCompanyPhoneId');
																						}}
																						cacheOptions={false}
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{
																						<Box sx={{mt:1.3}}>
																							{(contractForm.watch('producerCompanyId.value')&& (!props.isPrint)) &&
																							<AddCompanyAddressInline
																								companyId={contractForm.watch('producerCompanyId.value')}
																								title = 'ADDRESS'
																								isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'ProducerCompany-address'|| false}
																								event={addressPopOver?.event}
																								onAddressCreated={(d) => onProducerAddressCreated(d)}
																								onClose={() => setAddressPopOver({ isOpen: false})}
																								onPopOverOpen={(e) => setAddressPopOver({
																									isOpen: true,
																									event: e?.currentTarget,
																									for: 'ProducerCompany-address'
																								})}
																								isPersistent = {true} />
																							}
																						</Box>
																					}
																				</>
																			)}
																		/>
																		{contractForm.formState.errors
																			?.producerCompanyAddressId?.message && (
																			<FormHelperText
																				error={true}
																				data-cy='errorHelper_producer'
																			>
																				{
																					contractForm.formState?.errors
																						?.producerCompanyAddressId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td colSpan={1}>
																Producer Email
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			key={`select-em-${contractForm.watch(
																				'producerCompanyAddressId.value'
																			)}-${contractForm.watch('producerCompanyEmailId')}`}
																			name='producerCompanyEmailId'
																			defaultValue=''
																			render={({ field }) => (
																				<>
																					<NabuSelect
																						{...field}
																						key={`select-em-${contractForm.watch(
																							'producerCompanyAddressId.value'
																						)}-${contractForm.watch('producerCompanyEmailId')}`}
																						placeholder={!props?.isPrint ? 'Select Email' : ''}
																						convertValueToUpperCase={false}
																						enableEmptySelection={true}
																						disabled={
																							props?.isPrint ||
																						!contractForm.watch(
																							'producerCompanyAddressId.value'
																						)
																						}
																						options={getCompanyEmailOptions(
																							contractForm.watch(
																								'producerCompanyAddressId.value'
																							)
																						)}
																						handleDefaultSelection={() => !contractForm.watch('producerCompanyEmailId')}
																					/>
																					{
																						<Box sx={{mt:1.3}}>
																							{(contractForm.watch('producerCompanyAddressId.value') && (!props.isPrint)) &&
																						<AddEmailInline
																							formData={{ ...new EmailFormModel()}}
																							isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Producer-email-address'|| false}	
																							onSubmit={onProducerEmailInlineSubmit}
																							onClose={closeEmailInlinePopOver}
																							title='Email address'
																							onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Producer-email-address')}
																							event={emailPopOver?.event}
																						/>
																							}
																						</Box>	
																					}
																				</>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.producerCompanyEmailId?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.producerCompanyEmailId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
														</table>
														<br></br>
														{/* <p style={{ textAlign: 'center' }}>
															<b>Special Provisions</b> section continues on next
														page
														</p> */}
														<br></br>
													</div>
												</div>
												<div id={CONTRACT_REF_ID.MAIN_FORM_PART_2} style={{padding:'20px'}}>
													<div className={styles.contractFormBlock}>
														<div className={styles.bgWhite}>
															<table
																width={'100%'}
																cellPadding={0}
																cellSpacing={0}
																border={1}
																className={`${styles.contractFormsInner} keep-together`}
															>
																<tr>
																	<td
																		style={{ background: '#515151', color: '#FFF' }}
																		colSpan={2}
																	>
																	MINORS: Performer hereby certifies that he/she
																	is 21 years of age or over. (If under 21 years
																	of age, this contract must be signed below by a
																	parent or guardian.)
																	</td>
																</tr>
																<tr>
																	<td width={'50%'}>
																		<div
																			style={{
																				marginTop: '7px',
																				display: 'inline-block',
																			}}
																		>
																		I, the undersigned hereby state that I am the{' '}
																			<div
																				style={{
																					display: 'inline-flex',
			
																				}}
																			>
																				{' '}
																			I &nbsp;
																				<FormControl fullWidth>
																					<Controller
																						control={contractForm.control}
																						name='guardian'
																						render={({ field }) => (
																							<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																								<div
																									onClick={() => {
																										if (!props?.isPrint) {
																											field.onChange(
																												field.value === CONTRACT_GUARDIAN.FATHER ? '' : CONTRACT_GUARDIAN.FATHER
																											);
																										}
																									}}
																									style={{ userSelect: 'none', display: 'inline-flex' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											checked={field?.value?.toUpperCase() === CONTRACT_GUARDIAN.FATHER}
																											onChange={() =>
																												field.onChange(
																													field.value === CONTRACT_GUARDIAN.FATHER ? '' : CONTRACT_GUARDIAN.FATHER
																												)
																											}
																											type='checkbox'
																											disabled={props?.isPrint}
																											style={{ marginTop: '3px' }}
																										/>
																										<span>
																											<b>{CONTRACT_GUARDIAN.FATHER}</b>
																										</span>
																									</Box>
																								</div>
																								<div
																									onClick={() => {
																										if (!props?.isPrint) {
																											field.onChange(
																												field.value === CONTRACT_GUARDIAN.MOTHER ? '' : CONTRACT_GUARDIAN.MOTHER
																											);
																										}
																									}}
																									style={{ userSelect: 'none', display: 'inline-flex' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											checked={field?.value?.toUpperCase() === CONTRACT_GUARDIAN.MOTHER}
																											onChange={() =>
																												field.onChange(
																													field.value === CONTRACT_GUARDIAN.MOTHER ? '' : CONTRACT_GUARDIAN.MOTHER
																												)
																											}
																											type='checkbox'
																											disabled={props?.isPrint}
																											style={{ marginTop: '3px' }}
																										/>
																										<span>
																											<b>{CONTRACT_GUARDIAN.MOTHER}</b>
																										</span>
																									</Box>
																								</div>
																								<div
																									onClick={() => {
																										if (!props?.isPrint) {
																											field.onChange(
																												field.value === CONTRACT_GUARDIAN.GUARDIAN ? '' : CONTRACT_GUARDIAN.GUARDIAN
																											);
																										}
																									}}
																									style={{ userSelect: 'none', display: 'inline-flex' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											checked={field?.value?.toUpperCase() === CONTRACT_GUARDIAN.GUARDIAN}
																											onChange={() =>
																												field.onChange(
																													field.value === CONTRACT_GUARDIAN.GUARDIAN ? '' : CONTRACT_GUARDIAN.GUARDIAN
																												)
																											}
																											type='checkbox'
																											disabled={props?.isPrint}
																											style={{ marginTop: '3px' }}
																										/>
																										<span>
																											<b>{CONTRACT_GUARDIAN.GUARDIAN}</b>
																										</span>
																									</Box>
																								</div>
																							</div>
																						)}
																					/>
																				</FormControl>
																			</div>
																	&nbsp; of the above named Performer and do hereby
																		consent and give my permission to this
																		agreement:
																		</div>
																	</td>
																	<td>
																	Parent or Guardian Signature
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<NabuTextbox disabled />
																		</FormControl>
																	</td>
																</tr>
															</table>
															<br></br>
															<p>
															This contract is subject to all of the above terms
															and conditions of the SAG-AFTRA Audio Recorded
															Commercials Contract. The Performer has the right to
															consult with his/her representative or SAG-AFTRA
															before signing this contract.
															</p>
															<br></br>
															<table
																width={'100%'}
																cellPadding={0}
																cellSpacing={0}
																border={0}
																className={`${styles.contractFormsInner} keep-together`}
															>
																<tr>
																	<td width={180}>Performer (Print Name):</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				key={`performer-printName-${contractForm.watch(
																					'performerId.value'
																				)}`}
																				control={contractForm.control}
																				name='performerPrintName'
																				render={({ field }) => (
																					<>
																						<NabuTextbox
																							key={`performer-printName-${contractForm.watch(
																								'performerId.value'
																							)}`}
																							{...field}
																							placeholder={!props?.isPrint ? 'Print Name' : ''}
																							value={contractForm.watch('performerId.label')}
																						/>
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors
																				?.performerPrintName?.message && (
																				<FormHelperText
																					data-cy='errorHelper_?.addressId'
																					error={true}
																				>
																					{
																						contractForm.formState.errors
																							?.performerPrintName?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td width={220}>Producer{ !props?.isPrint && (
																		<Tooltip
																			placement='right-end'
																			title={'Signatory'}
																		>
																			<HelpOutline fontSize='inherit' />
																		</Tooltip>)
																	} (Name, Company)<sup></sup>:</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				key={`commercial-assetId
																				-${contractForm.watch(`commercials.${0}.assetId.value`)}
																				`}
																				control={contractForm.control}
																				name='producerCompanyId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							{...field}
																							key={`commercial-assetId
																								-${contractForm.watch(`commercials.${0}.assetId.value`)}
																								`}
																							placeholder={!props?.isPrint ? 'Producer' : ''}
																							filterProperty='CompanyNames.Name'
																							APICaller={(filterModel) => getAllSignatory(contractForm.watch(`commercials.${0}.assetId.value`),filterModel)}
																							// APICaller={getProducerCompanyForContract}
																							optionGenerator={
																								companyOptionGenerator
																							}
																							isClearable={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																								contractForm.setValue('producerCompanyAddressId', null, { shouldDirty: true });
																								contractForm.setValue('producerCompanyEmailId', '', { shouldDirty: true });
																								contractForm.setValue('producerCompanyPhoneId', '', { shouldDirty: true });
																								contractForm.trigger('producerCompanyAddressId');
																								contractForm.trigger('producerCompanyEmailId');
																								contractForm.trigger('producerCompanyPhoneId');
																							}}
																							isDisabled={props?.isPrint}
																							cacheOptions
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{/* {!props?.isPrint && (
																							<Box sx={{ mt: 0.5 }}>
																								<AddCompanyInline title='Company' />
																							</Box>
																						)} */}
																					</>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.producerCompanyId?.message && (
																				<FormHelperText
																					error={true}
																					data-cy='errorHelper-producerCompanyId'
																				>
																					{
																						contractForm.formState?.errors
																							?.producerCompanyId?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																</tr>
																<tr>
																	<td width={180}>Performer Telephone #</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				key={`select-phone-${contractForm.watch(
																					'performerPhoneId'
																				)}-${contractForm.watch(
																					'performerId.value'
																				)}`}
																				control={contractForm.control}
																				name='performerPhoneId'
																				defaultValue=''
																				render={({ field }) => (
																					<>
																						<NabuSelect
																							{...field}
																							key={`select-phone-${contractForm.watch(
																								'performerPhoneId'
																							)}-${contractForm.watch(
																								'performerId.value'
																							)}`}
																							placeholder={!props?.isPrint ? 'Select Phone' : ''}
																							convertValueToUpperCase={false}
																							enableEmptySelection={true}
																							disabled={
																								props?.isPrint ||
																							!contractForm.watch(
																								'performerId.value'
																							)
																							}
																							options={getPerformerPhoneNumberOptions()}
																							handleDefaultSelection={() => !contractForm.watch('performerPhoneId')}
																						/>
																						{
																							<Box sx={{mt:1.3}}>
																								{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																								<AddPhoneNumberInline
																									formData={{ ...new PhoneNumberFormModel()}}
																									onSubmit={onPhoneNumberInlineSubmit}
																									isOpen={phoneNumberPopOver.isOpen && phoneNumberPopOver?.for == 'Performer-address-3'|| false}	
																									onClose={closePhoneNumberInlinePopOver}
																									title='Phone Number'
																									onPopOverOpen={(e) => openPhoneNumberInlinePopOver(e, 'Performer-address-3')}
																									event={phoneNumberPopOver?.event}	
																								/>
																								}
																							</Box>	
																						}
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors
																				?.performerPhoneId?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.performerPhoneId?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td width={220}>Producer Telephone Number:</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='producerCompanyPhoneId'
																				defaultValue=''
																				render={({ field }) => (
																					<>
																						<NabuSelect
																							{...field}
																							key={`select-phone-${contractForm.watch(
																								'producerCompanyPhoneId'
																							)}-${contractForm.watch(
																								'producerCompanyAddressId.value'
																							)}`}
																							placeholder={!props?.isPrint ? 'Select Phone' : ''}
																							convertValueToUpperCase={false}
																							enableEmptySelection={true}
																							disabled={
																								props?.isPrint ||
																							!contractForm.watch(
																								'producerCompanyAddressId.value'
																							)
																							}
																							options={getCompanyPhoneOptions(
																								contractForm.watch(
																									'producerCompanyAddressId.value'
																								)
																							)}
																							handleDefaultSelection={() => !contractForm.watch('producerCompanyPhoneId')}
																						/>
																						{contractForm.watch('producerCompanyAddressId.value') &&
																		(!props?.isPrint) && (
																							<AddPhoneNumberInline
																								formData={{ ...new PhoneNumberFormModel()}}
																								onSubmit={onProducerPhoneNumberInlineSubmit}
																								onClose={closePhoneNumberInlinePopOver}
																								title='Phone Number'
																								onPopOverOpen={(e) =>openPhoneNumberInlinePopOver(e, 'ProducerCompany-Address')}
																								event={phoneNumberPopOver?.event}
																								isOpen={phoneNumberPopOver.isOpen && phoneNumberPopOver?.for == 'ProducerCompany-Address' || false}																		/>
																						)}
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors
																				?.producerCompanyPhoneId?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.producerCompanyPhoneId?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																</tr>
																<tr>
																	<td width={180}>Performer Email:</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.emaillowerCase}
																		>
																			<Controller
																				key={`select-phone-${contractForm.watch(
																					'performerPhoneId'
																				)}-${contractForm.watch(
																					'performerId.value'
																				)}-${contractForm.watch('performerEmailId')}`}
																				control={contractForm.control}
																				name='performerEmailId'
																				defaultValue=''
																				render={({ field }) => (
																					<>
																						<NabuSelect
																							{...field}
																							key={`select-phone-${contractForm.watch(
																								'performerPhoneId'
																							)}-${contractForm.watch(
																								'performerId.value'
																							)}-${contractForm.watch('performerEmailId')}`}
																							defaultCase={true}
																							placeholder={!props?.isPrint ? 'Select Email' : ''}
																							convertValueToUpperCase={false}
																							enableEmptySelection={true}
																							disabled={
																								props?.isPrint ||
																							!contractForm.watch(
																								'performerId.value'
																							)
																							}
																							options={getPerformerEmailsOptions()}
																							handleDefaultSelection={() => !contractForm.watch('performerEmailId')}
																						/>
																						{
																							<Box sx={{mt:1.3}}>
																								{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																						<AddEmailInline
																							formData={{ ...new EmailFormModel()}}
																							isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Performer-email-address'|| false}	
																							onSubmit={onPerformerEmailInlineSubmit}
																							onClose={closeEmailInlinePopOver}
																							title='Email address'
																							onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Performer-email-address')}
																							event={emailPopOver?.event}
																						/>
																								}
																							</Box>	
																						}
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors
																				?.performerPhoneId?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.performerPhoneId?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td width={220}>Producer Email:</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				key={`select-em-${contractForm.watch(
																					'producerCompanyAddressId.value'
																				)}-${contractForm.watch('producerCompanyEmailId')}`}
																				name='producerCompanyEmailId'
																				defaultValue=''
																				render={({ field }) => (
																					<>
																						<NabuSelect
																							{...field}
																							key={`select-em-${contractForm.watch(
																								'producerCompanyAddressId.value'
																							)}-${contractForm.watch('producerCompanyEmailId')}`}
																							placeholder={!props?.isPrint ? 'Select Email' : ''}
																							convertValueToUpperCase={false}
																							enableEmptySelection={true}
																							disabled={
																								props?.isPrint ||
																							!contractForm.watch(
																								'producerCompanyAddressId.value'
																							)
																							}
																							options={getCompanyEmailOptions(
																								contractForm.watch(
																									'producerCompanyAddressId.value'
																								)
																							)}
																							handleDefaultSelection={() => !contractForm.watch('producerCompanyEmailId')}
																						/>
																						{
																							<Box sx={{mt:1.3}}>
																								{(contractForm.watch('producerCompanyAddressId.value') && (!props.isPrint)) &&
																								<AddEmailInline
																									formData={{ ...new EmailFormModel()}}
																									isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Producer-email-address'|| false}	
																									onSubmit={onProducerEmailInlineSubmit}
																									onClose={closeEmailInlinePopOver}
																									title='Email address'
																									onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Producer-email-address')}
																									event={emailPopOver?.event}
																								/>
																								}
																							</Box>	
																						}
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors
																				?.producerCompanyEmailId?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.producerCompanyEmailId?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																</tr>
																<tr>
																	<td width={180}>Performer Signature:</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<NabuTextbox disabled />
																		</FormControl>
																	</td>
																	<td width={220}>Producer Signature:</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<NabuTextbox disabled />
																		</FormControl>
																	</td>
																</tr>
															</table>
															<hr></hr>
															<table
																width={'100%'}
																border={1}
																cellPadding={0}
																cellSpacing={0}
																className={`${styles.contractFormsInner} keep-together`}
															>
																<tr
																	style={{ background: '#515151', color: '#FFF' }}
																>
																	<th style={{ textAlign: 'center' }} colSpan={2}>
																	Dates Worked<sup></sup>
																	</th>
																	<th style={{ textAlign: 'center' }} colSpan={2}>
																	Work Time
																		<div
																			style={{
																				display: 'flex',
																				justifyContent: 'space-around',
																			}}
																		>
																			<span>From</span> <span>To</span>{' '}
																		</div>
																	</th>
																	{!props?.isPrint && (
																		<th style={{ textAlign: 'center' }}></th>
																	)}
																</tr>
																{workDayFieldArray.fields.map((field, index) => (
																	<tr key={`work-field-${field.id}`}>
																		<td height={40} colSpan={2}>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				<Controller
																					control={contractForm.control}
																					name={`workDays.${index}.date`}
																					defaultValue=''
																					render={({ field }) => (
																						<NabuDatepicker
																							{...field}
																							disabled={props?.isPrint}
																						/>
																					)}
																				/>
																				{/* {!!contractForm.formState?.errors?.workDays
																					?.length && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState?.errors
																								?.workDays[index]?.date?.message
																						}
																					</FormHelperText>
																				)} */}
																			</FormControl>
																		</td>
																		<td>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				{/* <Controller
																					control={contractForm.control}
																					name={`workDays.${index}.workTimeStartAt`}
																					render={({ field }) => (
																						<NabuTimePicker
																							{...field}
																							ampm={true}
																							disabled={props?.isPrint}
																						/>
																					)}
																				/> */}
																				{props?.isPrint ? (
																					<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.workTimeStartAt`) || '', 'hh:mm aa')}</p>
																				) : (<Controller
																					control={contractForm.control}
																					name={`workDays.${index}.workTimeStartAt`}
																					render={({ field }) => (
																						<NabuTimePicker
																							{...field}
																							ampm={true}
																							disabled={!(contractForm.watch(`workDays.${index}.date`))}
																						/>
																					)}
																				/>)}
																				{/* {!!contractForm.formState?.errors?.workDays
																					?.length && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState?.errors
																								?.workDays[index]?.workTimeStartAt
																								?.message
																						}
																					</FormHelperText>
																				)} */}
																			</FormControl>
																		</td>
																		<td>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				{/* <Controller
																					control={contractForm.control}
																					name={`workDays.${index}.makeUpTimeEndAt`}
																					render={({ field }) => (
																						<NabuTimePicker
																							{...field}
																							ampm={true}
																							disabled={props?.isPrint}
																						/>
																					)}
																				/> */}
																				{props?.isPrint ? (
																					<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.makeUpTimeEndAt`) || '', 'hh:mm aa')}</p>
																				) : (<Controller
																					control={contractForm.control}
																					name={`workDays.${index}.makeUpTimeEndAt`}
																					render={({ field }) => (
																						<NabuTimePicker
																							{...field}
																							ampm={true}
																							disabled={!(contractForm.watch(`workDays.${index}.date`))}
																						/>
																					)}
																				/>)}
																				{/* {!!contractForm.formState?.errors?.workDays
																					?.length && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState?.errors
																								?.workDays[index]?.makeUpTimeEndAt
																								?.message
																						}
																					</FormHelperText>
																				)} */}
																			</FormControl>
																		</td>
																		{!props?.isPrint && (
																			<>
																				<td style={{ display: 'flex' }}>
																					{workDayFieldArray.fields.length - 1 ===
																					index && (
																						<NabuButton
																							variant='text'
																							onClick={onAddWorkDay}
																						>
																							<Add />
																						</NabuButton>
																					)}
																					{workDayFieldArray?.fields.length > 1 && (
																						<NabuButton
																							variant='text'
																							onClick={() => onRemoveWorkDay(index)}
																						>
																							<DeleteForever color='error' />
																						</NabuButton>
																					)}
																				</td>
																			</>
																		)}
																	</tr>
																))}
																<tr>
																	<td colSpan={4}>
																	Performer Signature
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<NabuTextbox disabled />
																		</FormControl>
																	</td>
																</tr>
															</table>
															<br></br>
															<table
																width={'100%'}
																cellPadding={0}
																cellSpacing={0}
																border={0}
																className={`${styles.contractFormsInner} keep-together`}
															>
																<tr>
																	<td>
																		<p>
																			<b>STANDARD PROVISIONS </b>
																		</p>
																		<p>
																			<b>
																			1. THEATRICAL/INDUSTRIAL USE (Strike the
																			paragraph below if such rights are not
																			granted by Performer)
																			</b>
																		</p>
																		<p>
																		Producer shall have the right to the
																		commercial(s) produced hereunder for
																		Theatrical & Industrial use as defined and for
																		the period permitted in the SAGAFTRA Audio
																		Commercials Contract, for which Producer shall
																		pay Performer not less than the additional
																		compensation therein provided.
																		</p>
																		<br></br>
																		<p>
																			<b>2. ARBITRATION</b>
																		</p>
																		<p>
																		All disputes and controversies of every kind
																		and nature arising out of or in connection
																		with this Contract shall be subject to
																		arbitration as provided in Section 58 of the
																		SAG-AFTRA Commercials Contract.
																		</p>
																		<br></br>
																		<p>
																			<b>3. PRODUCER’S RIGHT</b>
																		</p>
																		<p>
																		Performer acknowledges that Performer has no
																		right, title or interest of any kind or nature
																		whatsoever in or to the commercial(s). A role
																		owned or created by Producer belongs to
																		Producer and not to Performer.
																		</p>
																		<br></br>
																		<p>
																			<b>4 . NON-DISCLOSURE/CONFIDENTIALITY</b>
																		</p>
																		<p>
																		‘Confidential Information’ means trade
																		secrets, confidential data, and other
																		non-public confidential proprietary
																		information (whether or not labeled as
																		confidential) including any and all financial
																		terms of, and products involved in the
																		production and any and all scripts whether
																		communicated orally, in written form, or
																		electronically. Confidential information does
																		not include information that was lawfully in
																		Performer’s possession prior to being
																		disclosed in connection with the employment of
																		Performer, is now, or hereafter becomes
																		generally known to the public, or that
																		Performer rightfully obtained without
																		restriction from a third party. Performer
																		acknowledges that Performer has and will
																		become aware of certain Confidential
																		Information. Unless otherwise required by law,
																		Performer agrees that, without Producer’s
																		prior written approval, Performer shall hold
																		such Confidential Information in the strictest
																		confidence and that Performer will not
																		disclose such Confidential Information to
																		anyone (other than Performer’s representatives
																		in the course of their duties to Performer,
																		which representatives shall be bound by the
																		same restrictions as set forth in this
																		Agreement) or utilize such Confidential
																		Information for Performer’s benefit or for the
																		benefit of a third party. Notwithstanding the
																		foregoing, nothing herein shall prohibit
																		Performer from disclosing Confidential
																		Information concerning Performer’s wages,
																		hours, and other terms and conditions of
																		employment as that term is defined under
																		Section 7 of the National Labor Relations Act.
																		For clarity, except as set forth above,
																		Producer may not demand or request that
																		Performer execute any non-disclosure agreement
																		that has not been approved in advance and in
																		writing by the Union
																		</p>
																	</td>
																</tr>
															</table>
														</div>
													</div>
												</div>
											</>
											{/* Main Contract Form End */}

											{/* W4 Short form */}
											{packetControlForm.watch('isW4shortForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW4ShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
											{/* W8 Ben Short form */}
											{packetControlForm.watch('isW8BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW8BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* W9 Ben Short form */}
											{packetControlForm.watch('isW9BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW9BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* Minor Trust form */}
											{packetControlForm.watch('isMinorTrust') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractMinorTrustForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* NY-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isNYTheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractNYWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* CA-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isCATheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractCAWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>

					{!props?.isPrint && (
						<div className={styles.contractTemplateBtn}>
							<Grid container>
								<Grid xs={12} md={6}>
									<NabuButton variant='filled' onClick={onClickSnippetBtn}>
										{!contractFormState?.isPacketOpen ? (
											<>
												<NoteAdd /> Create
											</>
										) : (
											'Close'
										)}
									&nbsp; Packet
									</NabuButton>
								</Grid>
								<Grid xs={12} md={6} dir='rtl'>
									<NabuButton
										variant='outlined'
										data-cy='cancel_btn'
										shouldHandleConfirmAction={contractForm.formState.isDirty}
										onClick={() => handleFormClose()}
									>
									Cancel
									</NabuButton>{' '}
								&nbsp;
									<NabuButton
										variant='filled'
										type='submit'
										data-cy='save_btn'
										disabled={
											!(
												contractForm.formState.isDirty ||
											packetControlForm.formState.isDirty
											) ||
										isAddingContract ||
										isEditingContract
										}
									>
										{isAddingContract || isEditingContract ? 'Saving' : 'Save'}
										<Save />
									</NabuButton>{' '}
								&nbsp;
									<NabuButton
										onClick={contractForm.handleSubmit(handleSaveAndDuplicate)}
										variant='filled'
										disabled={
											!(
												contractForm.formState.isDirty ||
											packetControlForm.formState.isDirty
											) ||
										isAddingContract ||
										isEditingContract || (!(props?.mainForm?.watch('contractDisplayName'))&& !props?.isEdit)
										}
									>
									Save & Duplicate <CopyAll />
									</NabuButton>
								&nbsp;
								</Grid>
							</Grid>
						</div>
					)}
				</form>
			</div>
			<TalentDetailsForm handleTalentDetailsForm={(value) => handleTalentDetailsForm(value)} noOfTalentsSelected={noOfTalentsSelected} setNoOfTalentsSelected={(value) => setNoOfTalentsSelected(value)} performerCategory={SagAftraAudioCommercialsPerformerTypeOptions} isUnion={true}/>
		</>
	);
});

export default SagAftraSdAcsAudComY22;
