import NabuButton from '@/common/components/atoms/button/button';
import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import LookAheadSelect from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import AddCompanyAddressInline from '@/common/components/templates/pages/add/addCompanyAddressInline';
import AddCompanyInline from '@/common/components/templates/pages/add/addCompanyInline';
import { IPopOverState } from '@/common/components/templates/pages/add/addNewAsset';
import AddPersonInline from '@/common/components/templates/pages/add/addPersonInline';
import { CONTRACT_REF_ID, ContractStatus, ContractStatusOptions } from '@/common/constants/constants';
import { OPERATORS } from '@/common/enums';
import useNotification from '@/common/hooks/useNotification';
import { CompanyMainType, ICompanyAddress, ICompanyRecord } from '@/common/types/company';
import {
	IBaseContract,
	IContractMSAForm, IPacketControlForm
} from '@/common/types/contract';
import { IFilterOption } from '@/common/types/filterModel';
import {
	displayAddress,
	getBaseUrl,
	getCompanyDisplayName,
	getNotificationText,
	getPersonFullName,
	mutateContractAdditionalFormsData
} from '@/common/utils/utils';
import styles from '@/modules/contracts/components/addContracts.module.scss';
import {
	getAllAddressByCompany,
	getCompanyBasicDetails
} from '@/services/company';
import { addContractMSA, addMultipleContract, updateContractMSA } from '@/services/contract';
import { IPersonRecord } from '@/services/models/person';
import { GetByWithFullNameSearchForBasicDetail } from '@/services/person';
import store from '@/store';
import { updateContractFormState } from '@/store/contract/reducer';
import { yupResolver } from '@hookform/resolvers/yup';
import { CopyAll, NoteAdd, Save } from '@mui/icons-material';
import {
	Box, Checkbox,
	FormControl,
	FormHelperText,
	FormLabel,
	Grid,
	ListItemButton,
	ListItemText
} from '@mui/material';
import { HttpStatusCode } from 'axios';
import { addDays, addMonths, addWeeks, addYears, format, isBefore } from 'date-fns';
import { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react';
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { ContractFormGeneralSchema } from '../contarctFormSchema';
import ContractCAWageTheftPreventionForm from '../contractCAWageyTheftPreventionForm';
import ContractMinorTrustForm from '../contractMinorTrustForm';
import ContractNYWageTheftPreventionForm from '../contractNYWageTheftPreventionForm';
import ContractW4ShortForm from '../contractW4ShortForm';
import ContractW8BenShortForm from '../contractW8BenShortForm';
import ContractW9BenShortForm from '../contractW9BenShortForm';
import TalentDetailsForm from '../talentDetailsForm';
import { IContractFormState } from '../unionForms/standardSagAftraEmployment2022';
import { useSearchParams } from 'react-router-dom';
import useScrollToField from '@/common/hooks/useScrollToField';
import ExpiryDateForm from '../expiryDateForm';

interface Props {
	mainForm?: UseFormReturn<IBaseContract, any, undefined>;
	onCancel?: VoidFunction;
	onSave?: CallableFunction;
	handlePrintStateOfContract?: CallableFunction;
	initialValue?: IContractMSAForm;
	isPrint?: boolean;
	isEdit?: boolean;
	contractId?: string;
	defaultValue?: IBaseContract,
	address?: ICompanyAddress[];
	people?: IPersonRecord[];
}

// eslint-disable-next-line react/display-name
const MsaTemplateThree = forwardRef((props: Props, ref) => {
	const { scroll } = useScrollToField();
	const [params, setParams] = useSearchParams();
	useImperativeHandle(ref, () => ({
		handleMsaTemplateThreeFormCancel: (data: IContractMSAForm, statusValue: string) => {
			if(data){
				const submitData = {
					...data,
					status: statusValue ? statusValue : ContractStatus.Draft,
					contractMSA: undefined,
					payrollVendorId: data?.payrollVendorId?.value,
					performerId: data?.performerId?.value,
					advertiserId: data?.advertiserId?.value,
					adAgencyId: data?.adAgencyId?.value,
					adAgencyAddressId: data?.adAgencyAddressId?.value,
					talentAgencyId: data?.talentAgencyId?.value,
					talentAgencyAddressId: data?.talentAgencyAddressId?.value,
					productId: data?.productId?.value,
					// creator Agreement fields,
					lenderCompanyId: data?.lenderCompanyId?.value,
					lenderCompanyAddressId: data?.lenderCompanyAddressId?.value,
					lenderCompanyStaffId: data?.lenderCompanyStaffId?.value,
					onBehalfOfCompanyId: data?.onBehalfOfCompanyId?.value,
					onBehalfOfCompanyAddressId: data?.onBehalfOfCompanyAddressId?.value,
					onBehalfOfCompanyStaffId: data?.onBehalfOfCompanyStaffId?.value,
				};
				editContractDetails(submitData);
			}
		},
	}));
	const notification = useNotification();

	useEffect(() => {
		if(props?.defaultValue?.advertiserId)
			contractForm.setValue('advertiserId', props?.defaultValue?.advertiserId, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.advertiserId]);

	const contractForm = useForm<IContractMSAForm>({
		defaultValues: !props?.initialValue
			? {
				...(props?.mainForm?.getValues() || {}),
				...(props?.defaultValue || {}),
			}
			: props?.initialValue,
		resolver: yupResolver(ContractFormGeneralSchema()),
		shouldFocusError: false
	});

	const [addressPopOver, setAddressPopOver] = useState<IPopOverState>({
		isOpen: false,
	});
	const packetControlForm = useForm<IPacketControlForm>({
		defaultValues: {
			isW4shortForm: !!contractForm.watch('contractW4Form.firstAndMiddleName'),
			isW8BenShort: !!contractForm.watch('contractW8BenShortForm'),
			isW9BenShort: !!contractForm.watch('contractW9BenShortForm'),
			isMinorTrust: !!contractForm.watch('contractMinorTrustForm'),
			isNYTheftPreventionForm: !!contractForm.watch(
				'contractNYTheftPreventionForm'
			),
			isCATheftPreventionForm: !!contractForm.watch(
				'contractCATheftPreventionForm'
			),
		},
	});

	const [, setPersonOptions] = useState<IPersonRecord[]>(
		props?.people || []
	);
	const [, setAddressOptions] = useState<ICompanyAddress[]>(
		props?.address || []
	);
	const [contractFormState, setContractFormState] =
		useState<IContractFormState>({});

	const AD_AGENCY_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'AD AGENCY',
		},
	];

	const ADVERTISER_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'ADVERTISER',
		},
	];

	const VENDOR_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'VENDOR',
		},
	];

	// helper functions
	const getPerformerFilter = (): IFilterOption[] => {
		const DEFAULT_FILTER: IFilterOption = {
			value: JSON.stringify(['TALENT']),
			property: 'PersonType',
			operator: OPERATORS.IN,
			isCaseSensitive: false,
		};
		return [DEFAULT_FILTER];
	};

	const handlePackageNotification = (key: keyof IPacketControlForm) => {
		notification.notify({
			message: `Form ${packetControlForm.watch(key) ? 'Added to' : 'Removed from'} Packet.`,
			type: 'info',
		});
	};

	const handleStateOptions = (
		responseData: any[],
		type: 'PERSON' | 'ADDRESS'
	) => {
		const cb = {
			PERSON: setPersonOptions,
			ADDRESS: setAddressOptions,
		}[type];

		if (responseData && Array.isArray(responseData)) {
			cb((prev) => {
				const oldFilter = prev?.filter(
					(d) => !responseData?.some((r) => r?.id === d?.id)
				);
				return [...oldFilter, ...responseData];
			});
		}
	};

	const companyOptionGenerator = (responseData: ICompanyRecord[]) => {
		const address = responseData?.reduce((acc, curr) => {
			if (curr?.addresses?.length) {
				acc.push(...(curr?.addresses || []));
			}
			return acc;
		}, [] as ICompanyAddress[]);

		handleStateOptions(address, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));
	};

	
	const onPayrollVendorAddressCreated = (createdAddress: ICompanyAddress) => {
		contractForm.setValue('payrollVendorAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const companyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		})) || [];
	};

	const performerOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');
		return responseData?.map((d) => ({
			value: d?.id,
			label: getPersonFullName(d?.personName),
		}));
	};

	const onClickSnippetBtn = () => {
		setContractFormState((prev) => ({
			isPacketOpen: !prev?.isPacketOpen,
		}));
	};

	const handleFormClose = () => {
		contractForm?.reset();
		props?.onCancel && props?.onCancel();
	};

	const { mutate: addContractDetails, isLoading: isAddingContract } =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addContractMSA(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						const tabPath = contractForm.watch('isGuaranteeOpted') ? 'guarantee-payment' : 'contract-view';
						props?.onSave(`/contracts/${res?.data?.id}/${tabPath}`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const { mutate: editContractDetails, isLoading: isEditingContract } =
		useMutation({
			mutationKey: ['contract-edit'],
			mutationFn: (payload: any) =>
				updateContractMSA(props?.contractId || '', payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'UPDATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						const tabPath = contractForm.watch('isGuaranteeOpted') ? 'guarantee-payment' : 'contract-view';
						props?.onSave(`/contracts/${res?.data?.id}/${tabPath}`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'UPDATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const handleContractSubmission = async (data: IContractMSAForm, handleMultipleContractAdd?: CallableFunction) => { 
		
		if (!packetControlForm.watch('isW4shortForm')) {
			data.w4FormId = undefined;
			data.contractW4Form = undefined;
		}
		if (!packetControlForm.watch('isW8BenShort')) {
			data.w8BenShortFormId = undefined;
			data.contractW8BenShortForm = undefined;
		}
		if (!packetControlForm.watch('isW9BenShort')) {
			data.w9BenShortFormId = undefined;
			data.contractW9BenShortForm = undefined;
		}
		if (!packetControlForm.watch('isMinorTrust')) {
			data.minorTrustFormId = undefined;
			data.contractMinorTrustForm = undefined;
		}
		if (!packetControlForm.watch('isNYTheftPreventionForm')) {
			data.nYTheftPreventionFormId = undefined;
			data.contractNYTheftPreventionForm = undefined;
		}
		if (!packetControlForm.watch('isCATheftPreventionForm')) {
			data.cATheftPreventionFormId = undefined;
			data.contractCATheftPreventionForm = undefined;
		}

		try {
			const notify = (message: string) => {
				notification.notify({
					message,
					type: 'error',
				});
			};
			// NOTE: These linked form's handled separately because the payload size is huge.
			const updatedContractIds = await mutateContractAdditionalFormsData(
				data,
				data?.performerId?.value || '',
				notify
			);
			data.w4FormId = updatedContractIds?.w4FormId;
			data.w8BenShortFormId = updatedContractIds?.w8BenShortFormId;
			data.w9BenShortFormId = updatedContractIds?.w9BenShortFormId;
			data.minorTrustFormId = updatedContractIds?.minorTrustFormId;
			data.nYTheftPreventionFormId =
				updatedContractIds?.nYTheftPreventionFormId;
			data.cATheftPreventionFormId =
				updatedContractIds?.cATheftPreventionFormId;
	
		} catch (error) {
			notification.notify({
				message: getNotificationText('Form', 'UPDATE', true),
				type: 'error',
			});
		} finally {
			data.contractW4Form = undefined;
			data.contractW8BenShortForm = undefined;
			data.contractW9BenShortForm = undefined;
			data.contractMinorTrustForm = undefined;
			data.contractNYTheftPreventionForm = undefined;
			data.contractCATheftPreventionForm = undefined;

			const mainFormValues = props?.mainForm?.getValues();
			const addMainFormValues = !props?.isEdit ? {
				contractType: mainFormValues?.contractType,
				contractDisplayName: mainFormValues?.contractDisplayName,
				unionType: mainFormValues?.unionType,
				unionAgreement: mainFormValues?.unionAgreement,
				payrollVendorAddressId: mainFormValues?.payrollVendorAddressId || '',
			} : {};

			const submitData = {
				...data,
				...addMainFormValues,
				contractMSA: undefined,
				payrollVendorId: data?.payrollVendorId?.value,
				payrollVendorAddressId: data?.payrollVendorAddressId?.value,
				performerId: data?.performerId?.value,
				advertiserId: data?.advertiserId?.value,
				adAgencyId: data?.adAgencyId?.value,
				adAgencyAddressId: data?.adAgencyAddressId?.value,
				talentAgencyId: data?.talentAgencyId?.value,
				talentAgencyAddressId: data?.talentAgencyAddressId?.value,
				productId: data?.productId?.value,
				// creator Agreement fields,
				lenderCompanyId: data?.lenderCompanyId?.value,
				lenderCompanyAddressId: data?.lenderCompanyAddressId?.value,
				lenderCompanyStaffId: data?.lenderCompanyStaffId?.value,
				onBehalfOfCompanyId: data?.onBehalfOfCompanyId?.value,
				onBehalfOfCompanyAddressId: data?.onBehalfOfCompanyAddressId?.value,
				onBehalfOfCompanyStaffId: data?.onBehalfOfCompanyStaffId?.value,
			};
			if (!props?.isEdit){
				addContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			}
			else {
				editContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			} 
		}
	};

	const onSubmitError = (data) => {
		scroll(data);
		notification.notify({
			message: 'Please fill all required fields',
			type: 'error',
		});
	};

	const onSubmit = (data: IContractMSAForm) => {
		if(!props?.mainForm?.watch('contractDisplayName') && !props?.isEdit){
			notification.notify({
				message: 'Please fill Contract Display Name.',
				type: 'error'
			});
		}
		else{
			openExpiryDateForm(data);
		}
	};

	useEffect(() => {
		if(props?.mainForm){
			contractForm.setValue('contractDisplayName', props?.mainForm?.watch('contractDisplayName') || '', { shouldDirty: true});
		}
	}, [props?.mainForm?.watch('contractDisplayName')]);

	const { mutate: addMultipleContractDetails} =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addMultipleContract(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					const baseURL = getBaseUrl(window.location.href);
					const contractIds : any = res?.data || [];
					const urls =  contractIds.map((contract) => {
						return `${baseURL}/#/contracts/${contract}/contract-view?isEdit=true`;
					}) || [];

					urls.forEach((url) => {
						const a = document.createElement('a');	
						a.href = url;
						a.target = '_blank';
						const evt = document.createEvent('MouseEvents');
						evt.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
						a.dispatchEvent(evt);
					});
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
				} else
					notification.notify({
						message:
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const [contractFormData, setContractFormData] = useState<IContractMSAForm>();

	const [noOfTalentsSelected, setNoOfTalentsSelected] = useState(false);

	const handleSaveAndDuplicate = (data: IContractMSAForm) => {
		setNoOfTalentsSelected(true);
		setContractFormData(data);
	};

	const handleTalentDetailsForm = (data: any) => {
		setNoOfTalentsSelected(false);
		const handleMultipleContractAdd = (payloadData) => {
			if(data) {
				const contractAddModels = data?.talentRecords?.map((talent, index) => {
					const submitData = {
						...payloadData,
						id: undefined,
						status:ContractStatus?.Draft,
						role: talent?.role,
						performerAdAgencyId: talent?.agent?.value,
						performerId: talent?.talentName?.value,
						contractDisplayName: `${payloadData?.contractDisplayName}_copy_${index+1}`,
						compensation: talent?.compensation,
					};
					return submitData;
				});
				const multipleContractData = {
					contractAddMSAModels: contractAddModels
				};
				addMultipleContractDetails(multipleContractData);
			}
		};
		contractFormData && handleContractSubmission(contractFormData, handleMultipleContractAdd);
	};

	//Expiry Date Dialog Code
	const [isExpiryDateDialogOpen, setExpiryDateDialog] = useState<{isOpen: boolean, contractData: IContractMSAForm | undefined, contractExpiryDate: Date | undefined}>(
		{
			isOpen: false, 
			contractData: undefined, 
			contractExpiryDate: undefined
		});

	const calculateEndDate = (termValues) => {
		if(termValues.termDuration && termValues.termDurationUnit && termValues.termFromFirstUseDate){
			const days = termValues.termDuration;
			const startDate = new Date(termValues.termFromFirstUseDate);
			const date = startDate.setDate(startDate.getDate() - 1);
			const unit = termValues.termDurationUnit;
			if(date){
				let finalDate;
				if(unit == 'WEEK (S)'){
					finalDate = (addWeeks(new Date(date), days));
				}
				else if(unit == 'MONTH (S)'){
					finalDate = addMonths(new Date(date), days);
				}
				else if(unit == 'YEAR (S)'){
					finalDate = addYears(new Date(date), days);
				}
				else{
					finalDate = addDays(new Date(date), days);
				}
				return finalDate;
			}
		}
	};

	const openExpiryDateForm = (data) => {
		const termValues = {
			termDuration: data.termDuration,
			termDurationUnit: data.termDurationUnit,
			termFromFirstUseDate: data.termFromDate || data.termFromFirstUseDate
		};
		const endDate = calculateEndDate(termValues) || undefined;
		setExpiryDateDialog({isOpen: true, contractData: data, contractExpiryDate: endDate});
	};

	const closeExpiryDateForm = () => setExpiryDateDialog({isOpen: false, contractData: undefined, contractExpiryDate: undefined});
	const onSubmitOfExpiryDateForm = (data) => {
		if(isExpiryDateDialogOpen.contractData){
			const expiredStatus = data.expirationDate ? (isBefore(data.expirationDate, new Date()) ? ContractStatus.Expired : undefined) : undefined;
			const submittedData: IContractMSAForm = {
				...isExpiryDateDialogOpen.contractData,
				expirationDate: data.expirationDate,
				status: expiredStatus
			};
			submittedData && handleContractSubmission(submittedData);
		}
	};

	return (
		<>
			<div className={props?.isPrint ? styles.formWrapperPreview : ''}>
				<form onSubmit={contractForm.handleSubmit(onSubmit, onSubmitError)}>
					<div className={styles.packetTemplate} id='form'>
						<Grid item container columnSpacing={3}>
							{contractFormState?.isPacketOpen && (
								<Grid item sm={12} md={4} lg={3}>
									<div className={styles.snippetSection}>
										<Grid item container columnSpacing={3}>
											<Grid item md={12}>
												<h3 className={styles.pocketTitle}> Government & Payroll forms</h3>
											</Grid>
										</Grid>
										<div className={styles.contractFormBlockSnippet}>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW4shortForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW4shortForm');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW4shortForm');
																		}}
																	/>{' '}
																	<span>W4- short version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW8BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW8BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW8BenShort');
																		}}
																	/>
																	<span>W8 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW9BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW9BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW9BenShort');
																		}}
																	/>
																	<span>W9 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isMinorTrust'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isMinorTrust');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isMinorTrust');
																		}}
																	/>
																	<span>Minor Trust Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isNYTheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isNYTheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isNYTheftPreventionForm'
																			);
																		}}
																	/>
																	<span>NY Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isCATheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isCATheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isCATheftPreventionForm'
																			);
																		}}
																	/>
																	<span>CA Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
										</div>
									</div>
								</Grid>
							)}
							<Grid
								item
								sm={12}
								md={4}
								lg={!contractFormState?.isPacketOpen ? 12 : 9}
							>
								<div
									className={
										!contractFormState?.isPacketOpen ? undefined : styles.scrollFIle
									}
								>
									<div className={styles.contractFormBlockPacket} id='contract-form-block-packet'>
										<div className={styles.contractFormBlockPacketMSA}>
											<div className={styles.contractFormBlock}>
												{props?.isEdit && (
													<div className={styles.formFiledsBlock}>
														<Grid container item columnSpacing={3}>
															<Grid item md={6} lg={3}>
																<FormControl fullWidth>
																	<FormLabel>
														Contract Name<sup>*</sup>
																	</FormLabel>
																	<Controller
																		control={contractForm.control}
																		name='contractDisplayName'
																		render={({ field }) => (
																			<>
																				<NabuTextbox
																					{...field}
																					placeholder={!props?.isPrint ? 'Contract Name' : ''}
																				/>
																			</>
																		)}
																	/>
																	{contractForm.formState?.errors?.contractDisplayName
																		?.message && (
																		<FormHelperText error={true}>
																			{
																				contractForm.formState.errors
																					?.contractDisplayName?.message
																			}
																		</FormHelperText>
																	)}
																</FormControl>
															</Grid>
															<Grid item md={6} lg={3}>
																<FormControl fullWidth>
																	<FormLabel>
														Payroll Vendor <sup />
																	</FormLabel>
																	<Controller
																		control={contractForm.control}
																		name='payrollVendorId'
																		render={({ field }) => (
																			<>
																				<LookAheadSelect
																					{...field}
																					placeholder={!props?.isPrint ? 'Payroll Vendor' : ''}
																					filterProperty='CompanyNames.Name'
																					APICaller={getCompanyBasicDetails}
																					optionGenerator={companyOptionGenerator}
																					defaultFilter={VENDOR_FILTER}
																					isClearable={true}
																					onChange={(e: any) => {
																						field.onChange(e);
																					}}
																					cacheOptions={false}
																					defaultOptions
																					className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				/>
																			</>
																		)}
																	/>
																	{contractForm.formState.errors?.payrollVendorId?.message && (
																		<FormHelperText
																			error={true}
																			data-cy='errorHelper_ad_agency'
																		>
																			{
																				contractForm.formState?.errors?.payrollVendorId.message
																			}
																		</FormHelperText>
																	)}
																</FormControl>
															</Grid>
															<Grid item md={6} lg={3}>
																<FormControl fullWidth>
																	<FormLabel>
														Payroll Vendor Address
																		<sup />
																	</FormLabel>
																	<Controller
																		control={contractForm.control}
																		key={`payroll-address-${contractForm.watch(
																			'payrollVendorAddressId.value'
																		)}`}
																		name='payrollVendorAddressId'
																		render={({ field }) => (
																			<>
																				<LookAheadSelect
																					{...field}
																					placeholder={'Select Address'}
																					key={`payroll-vendor-address-${contractForm.watch(
																						'payrollVendorAddressId.value'
																					)}-${contractForm.watch('payrollVendorId.value')}`}
																					filterProperty='Line1'
																					APICaller={(filterModel) =>
																						getAllAddressByCompany(
																							filterModel,
																							contractForm.watch('payrollVendorId.value') || ''
																						)
																					}
																					optionGenerator={
																						companyAddressOptionGenerator
																					}
																					isDisabled={!contractForm.watch('payrollVendorId.value')}
																					dropDownsize={50}
																					isClearable={true}
																					isSearchText={true}
																					onChange={(e: any) => {
																						field.onChange(e);
																						contractForm.trigger('payrollVendorAddressId.value');
																					}}
																					cacheOptions={false}
																					defaultOptions
																					className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				/>
																				{contractForm.watch('payrollVendorId.value') && <div style={{float:'right'}}>
																					<Box sx={{mt:0.9}}>
																						<AddCompanyAddressInline
																							companyId={contractForm.watch('payrollVendorId.value')}
																							title='ADDRESS'
																							isOpen={addressPopOver?.isOpen}
																							event={addressPopOver?.event}
																							onAddressCreated={(d) => onPayrollVendorAddressCreated(d)}
																							onClose={() => setAddressPopOver({ isOpen: false })}
																							onPopOverOpen={(e) => setAddressPopOver({
																								isOpen: true,
																								event: e?.currentTarget,
																							})}
																							isPersistent={true} />
																					</Box>
																				</div>}
																			</>
																	
																		)}
																	/>
																	{contractForm.formState.errors?.payrollVendorAddressId?.message && (
																		<FormHelperText
																			error={true}
																			data-cy='errorHelper_ad_agency'
																		>
																			{
																				contractForm.formState?.errors
																					?.payrollVendorAddressId.message
																			}
																		</FormHelperText>
																	)}
																</FormControl>
															</Grid>
															<Grid item md={6} lg={3}>
																<FormControl fullWidth>
																	<FormLabel>
														Status
																		<sup />
																	</FormLabel>
																	<Controller
																		control={contractForm.control}
																		name='status'
																		render={({ field }) => (
																			<>
																				<NabuSelect
																					{...field}
																					placeholder={!props?.isPrint ? 'Select Status' : ''}
																					options={ContractStatusOptions || []}
																					convertValueToUpperCase={false}
																					onChange={(e) => field.onChange(e)}
																				/>
																			</>
																		)}
																	/>
																</FormControl>
															</Grid>
														</Grid>
													</div>
												)}
												{/* Main Contract Form Start */}
									
												<>
										
													<div className={styles.contractFormBlock}>
														<div id={CONTRACT_REF_ID.MAIN_FORM} className={styles.bgWhite} style={{padding:'20px'}}>
															{/* <table className={styles.contractForms} width={'100%'}>
																<tbody>
																	<tr>
																		<h4>{contractForm.watch('contractId') ? `#${contractForm.watch('contractId')}` : ''}</h4>
																	</tr>
																	<tr>
																		<td>
																			<img src={JACK} width={75} />{' '}
																		</td>
																		<td align='right'>
																			<div
																				className={styles.listDrop}
																				style={{ textAlign: 'right' }}
																			>
																				<b>
																		9357 SPECTRUM CENTER BLVD SAN DIEGO, CA
																		92123<br></br>
																		858.571.2121 JACKINTHEBOX.COM
																				</b>
																			</div>
																		</td>
																	</tr>
																</tbody>
															</table>
															<hr></hr> */}
															<div style={{display:'flex', alignItems:'center', gap:'5px', flexWrap:'wrap'}}>
													
																<Controller
																	control={contractForm.control}
																	name='effectiveDate'
																	defaultValue={new Date()}
																	render={({ field }) => (
																		<NabuDatepicker
																			{...field}
																			disabled={props?.isPrint}
																		/>
																	)}
																/>
																{contractForm.formState?.errors
																	?.effectiveDate?.message && (
																	<FormHelperText error={true}>
																		{
																			contractForm.formState?.errors
																				?.effectiveDate.message
																		}
																	</FormHelperText>
																)}  (“Effective Date”)</div>
															<div style={{display:'flex', alignItems:'center', gap:'5px', flexWrap:'wrap', justifyContent:'flex-start'}}>
																<div style={{display:'flex', alignItems:'center', gap:'5px', flexWrap:'wrap', justifyContent:'flex-start'}}>
																	<b>{contractForm.watch('lenderCompanyId.label') || ''}</b>
																	<br></br>
																	<b>{contractForm.watch('performerId.label') || ''}</b>
																	<br></br>
																</div>
										
													This will serve as the agreement dated as of the
													Effective Date (the “Agreement”), by and between 
																<Box>
																	<FormControl fullWidth className={styles.tableFormsSelect}>
																		<Controller
																			key={`advertiser-${contractForm.watch('advertiserId.value')}-${contractForm.watch('productId.value')}`}
																			control={contractForm.control}
																			name='advertiserId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						{...field}
																						placeholder={!props?.isPrint ? 'Advertiser' : ''}
																						filterProperty='CompanyNames.Name'
																						APICaller={getCompanyBasicDetails}
																						optionGenerator={companyOptionGenerator}
																						defaultFilter={ADVERTISER_FILTER}
																						isDisabled={props?.isPrint}
																						isClearable={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																							contractForm.resetField('productId');
																							contractForm.trigger('advertiserId.label');
																						}}
																						cacheOptions={false}
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{!props?.isPrint && (
																						<div className={styles.inlineAdd}>
																							<AddCompanyInline
																								title='Company'
																								companyType={{
																									mainType:
																					'ADVERTISER' as CompanyMainType,
																									subType: [],
																								}}
																							/>
																						</div>
																					)}
																				</>
																			)}
																		/>
																		{contractForm.formState.errors?.advertiserId?.message && (
																			<FormHelperText
																				error={true}
																				data-cy='errorHelper_ad_agency'
																			>
																				{
																					contractForm.formState?.errors?.advertiserId.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</Box>
													, its parent, affiliates, and designees (current
													agencies <Box>
																	<FormControl fullWidth className={styles.tableFormsSelect}>
																		<Controller
																			control={contractForm.control}
																			name='adAgencyId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						data-cy='ad_agency'
																						{...field}
																						placeholder={!props?.isPrint ? 'Ad-Agency' : ''}
																						filterProperty='CompanyNames.Name'
																						APICaller={getCompanyBasicDetails}
																						optionGenerator={companyOptionGenerator}
																						defaultFilter={AD_AGENCY_FILTER}
																						isClearable={true}
																						isDisabled={props?.isPrint}
																						onChange={(e: any) => {
																							field.onChange(e);
																							contractForm.setValue('adAgencyAddressId', null, { shouldDirty: true});
																							contractForm.trigger('adAgencyAddressId');
																						}}
																						cacheOptions
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{!props?.isPrint && (
																						<div className={styles.inlineAdd}>
																							<AddCompanyInline
																								title='Company'
																								companyType={{
																									mainType:
																					'AD AGENCY' as CompanyMainType,
																									subType: [],
																								}}
																							/>
																						</div>
																					)}
																				</>
																			)}
																		/>
																	</FormControl>
																</Box>
																{contractForm.formState.errors?.adAgencyId?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors?.adAgencyId.message
																		}
																	</FormHelperText>
																)} and any
													future designees (“Advertiser”), and C
													
																<Box >
																	<FormControl fullWidth className={styles.tableFormsSelect}>
																		<Controller
																			key={`lender-${contractForm.watch('lenderCompanyId.value')}-${contractForm.watch('productId.value')}`}
																			control={contractForm.control}
																			name='lenderCompanyId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						{...field}
																						placeholder={!props?.isPrint ? 'Lender' : ''}
																						filterProperty='CompanyNames.Name'
																						APICaller={getCompanyBasicDetails}
																						optionGenerator={companyOptionGenerator}
																						isDisabled={props?.isPrint}
																						isClearable={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																							contractForm.trigger('lenderCompanyId.label');
																						}}
																						cacheOptions={false}
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{!props?.isPrint && (
																						<div className={styles.inlineAdd}>
																							<AddCompanyInline
																								title='Company'
																							/>
																						</div>
																					)}
																				</>
																			)}
																		/>
																	</FormControl>
																</Box>
																{contractForm.formState.errors?.lenderCompanyId?.message && (
																	<FormHelperText
																		error={true}
																	>
																		{
																			contractForm.formState?.errors?.lenderCompanyId.message
																		}
																	</FormHelperText>
																)}. (“Lender”) for the services of 
																<Box>
																	<FormControl fullWidth className={styles.tableFormsSelect}>
																		<Controller
																			control={contractForm.control}
																			name='performerId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						data-cy='name_select'
																						{...field}
																						key={`performer-name-${contractForm.watch('performerId.value')}`}
																						isClearable={true}
																						placeholder={!props?.isPrint ? 'Select Performer' : ''}
																						filterProperty='PersonName.FirstName'
																						APICaller={(
																							filterModel,
																							searchText
																						) =>
																							GetByWithFullNameSearchForBasicDetail(
																								filterModel,
																								searchText
																							)
																						}
																						isSearchText={true}
																						isDisabled={props?.isPrint}
																						optionGenerator={
																							performerOptionGenerator
																						}
																						defaultFilter={getPerformerFilter()}
																						onChange={(e: any) => {
																							field.onChange(e);
																							contractForm.setValue('performerPrintName', contractForm.watch('performerId.label'),{ shouldDirty: true });
																						}}
																						cacheOptions={false}
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{!props?.isPrint && (
																						<Box >
																							<AddPersonInline
																								title='Person'
																								onPersonCreated={() => ({})}
																								initialValues={{personType: ['TALENT'], isMinor: false}}
																							/>
																						</Box>
																					)}
																				</>
																			)}
																		/>
																	</FormControl>
																</Box>
																{contractForm.formState?.errors?.performerId?.message && (
																	<FormHelperText
																		data-cy='errorHelper_first_session_date'
																		error={true}
																	>
																		{
																			contractForm.formState?.errors?.performerId?.message
																		}
																	</FormHelperText>
																)}
													(“Talent”) in connection with Advertiser’s various
													commercials under the SAG-AFTRA Commercials Contract
													(including Digital Media and Social Media waivers) and
													content under the SAG-AFTRA Corporate/Educational and
													Non-Broadcast Contract (“Commercials”).
															</div>
											

															<br></br>
															<div className={styles.listDrop}>
																<b> The terms of the Agreement are as follows: </b>
															</div>
															<div className={styles.listDrop}>
																<ol>
																	<li>
																		<div className={styles.listDrop}>
																			<b>Production Services:</b> Lender shall provide
																Talent’s services during the Session Term and
																Session Option Term (as defined below) as and
																when directed by Advertiser. Talent’s services
																shall be rendered on a first priority basis.
																		</div>
																	</li>
																	<li>
																		<div className={styles.listDrop}>
																			<b>Personal Appearance Services: </b> Lender
																shall provide Talent’s services for personal
																appearances as required by Advertiser, with
																compensation applied to the Fee at a rate of
																Three Thousand US Dollars ($3,000) per 8-hour
																service day (“Personal Appearance Fee”). The
																8-hour service day does not include travel time.
																Any hours beyond the 8 hours will be paid at a
																rate of $562.50 per hour for the 9th and 10th
																hours, and any hours from the 11th hour on will
																be paid at $750 per hour.
																		</div>
																	</li>
																	<li>
																		<div className={styles.listDrop}>
																			<b>Materials:</b> Unlimited Commercials, with
																unlimited versions, cut-downs, edits, lifts,
																tags, and other revisions during the Usage Term
																(as defined below).
																		</div>
																	</li>
																	<li>
																		<div className={styles.listDrop}>
																			<b>Term</b>
																			<ol type='A'>
																				<li>
																		Session Term: The term for all services
																		shall be one (1) year commencing on <Fragment key={`${contractForm.watch('effectiveDate')}`}>
																						{contractForm.watch('effectiveDate') && format(new Date(contractForm.watch('effectiveDate')?.toString() || ''), 'EEEE d \',\' yyyy')}
																					</Fragment> (“Session Term”).
																				</li>
																				<li>
																		Usage Term: The usage term for each
																		Commercial filmed during the Session Term or
																		the Session Option Term shall be twenty-one
																		(21) months from the Talent’s first service
																		date of each respective Commercial (each a
																		“Usage Term”). Additionally, usage from all
																		current spots due to Lender on or after
																					{contractForm.watch('effectiveDate') && format(new Date(contractForm.watch('effectiveDate')?.toString() || ''), 'EEEE d \',\' yyyy')}, shall be credited against
																		the Fee and/or Session Option Fee (if
																		applicable) and such usage shall be
																		twenty-one (21) months from the first
																		service date of each respective Commercial
																		and with the respective agreed-upon rate.
																				</li>
																				<li>
																		In the event of any renewals for spots that
																		expire during the Session term shall be
																		negotiated in good faith by the parties and
																		credited against the Fee (if applicable) at
																		the negotiated amount. For any commercials
																		that have already expired, they are
																		addressed in section 8C.
																				</li>
																				<li>
																		Session Option Term: Advertiser shall have
																		the option to extend the Session Term for an
																		additional one (1) year period (“Session
																		Option Term”) by providing written notice to
																		Lender no later than thirty (30) days prior
																		to the expiration of the Session Term.
																		Session Option Term, if executed, shall
																		commence immediately after the expiration of
																		the initial Session Term.
																				</li>
																				<li>
																		Advertiser shall make commercially
																		reasonable efforts to provide a private
																		dressing room w/bathroom and/or private
																		trailer w/bathroom to Talent on production
																		shoots. Advertiser and/or its designated
																		agency will notify each production company
																		shooting with Talent of this request. To
																		also ensure the private information of the
																		Talent, none of his personal information
																		such as phone number/email will be on the
																		general call sheet for any such shoot
																		production or event.
																				</li>
																				<li>
																		To also ensure the private information of
																		the Talent, none of his personal information
																		such as phone number/email will be on the
																		general call sheet for any such shoot
																		production or event.
																				</li>
																				<li>
																		Advertiser shall make commercially
																		reasonable efforts to provide advance notice
																		for all shoot details and schedule shifts.
																		Advertiser shall release or confirm Talent
																		three days prior to the first shoot day or
																		Personal Appearance day. Notwithstanding the
																		foregoing, there may be unplanned
																		cancellations and/or postponements resulting
																		from the current Covid-19 pandemic,
																		including day of cancellations and/or
																		postponements. In the event of such
																		unplanned cancellations and/or
																		postponements, Advertiser will use
																		commercially reasonable efforts to
																		reschedule any service days within a period
																		of 5 working days. If scheduling must be
																		postponed indefinitely, a cancellation fee
																		(“Cancellation Fee”) will be applied to the
																		Fee. For a Commercial shoot, the
																		Cancellation Fee will be the equivalent of
																		one scale session fee. For a Personal
																		Appearance day, the Cancellation Fee will be
																		the equivalent of 50% of the Personal
																		Appearance Fee.
																				</li>
																				<li>
																		If production (in general) is halted for
																		more than 30 days in Los Angeles due to the
																		COVID19 pandemic, the Agreement will be
																		extended up to three (3) months. A written
																		notification to extend the Agreement will be
																		provided by the Advertiser within 10 working
																		days to Talent upon the start of a general
																		production stoppage.
																				</li>
																				<li>
																		All COVID19 safety precautions as prescribed
																		by SAG-AFTRA, the JPC, and any local
																		governing laws per location will be strictly
																		followed to ensure the safety of the Talent.
																				</li>
																			</ol>
																		</div>
																	</li>
																	<li>
																		<div className={styles.listDrop}>
																			<b>Territory:</b> North America (its
																territories, commonwealths, and possessions),
																with the exception of Internet and New Media,
																the territory for which is worldwide
																(“Territory”).
																		</div>
																	</li>
																	<li>
																		<div className={styles.listDrop}>
																			<b>Media:</b> During the Session Term, Usage
																Term, and Session Option Term of the Agreement
																and in the Territory, Advertiser shall have the
																right to display, distribute, exhibit, and
																otherwise use the Materials in any way
																Advertiser desires in the media set forth below:
																			<ol type='A'>
																				<li>Broadcast Television</li>
																				<li>Internet</li>
																				<li>New Media</li>
																				<li>Industrial</li>
																				<li>
																		PR uses for Advertiser (including
																		behind-the-scenes video and still
																		photography, any B-Roll, online and social
																		media, etc., in any and all media)
																				</li>
																				<li>
																		Any new technology not currently known that
																		will benefit the advertising of the
																		Advertiser and its products
																				</li>
																			</ol>
																		</div>
																	</li>
																</ol>
															</div>
														</div>
													</div>
													<br></br>
													<div id={CONTRACT_REF_ID.MAIN_FORM_PART_2} style={{padding:'20px'}}>
														<div className={styles.contractFormBlock}>
															<div className={styles.bgWhite}>
																<ol>
																	<li value={7}>
																		<div className={styles.listDrop}>
																			<b>Exclusivity:</b> Fast food. Exclusivity shall
																apply during the Session Term, Usage Term, and
																Session Option Term.
																		</div>
																	</li>
																	<li value={8}>
																		<div className={styles.listDrop}>
																			<b>Fee:</b>
																		</div>
																		<div className={styles.listDrop}>
																			<ol type='A'>
																				<li>
																		Advertiser shall pay Lender Two Hundred
																		Twenty-Five Thousand US Dollars ($225,000)
																		(“Fee”) for the Session Term with all sessions
																		and residuals to be credited against all
																		compensation payable at scale + 25%. The Fee
																		shall be payable as follows: 100% within
																		fifteen (15) business days of execution of
																		this Agreement and receipt of invoice. The Fee
																		paid to Lender shall be allocated 100% to SAG
																		union covered services.
																				</li>
																				<li>
																		In the event Advertiser exercises its Session
																		Option Term, Advertiser shall pay Lender Two
																		Hundred Fifty Thousand US Dollars ($250,000)
																		(“Session Option Fee”) for the Session Option
																		Term with all sessions and residual to be
																		credited against the Session Option Fee at
																		scale + 25%. The Session Option Fee shall to
																		be payable as follows: 100% within fifteen
																		(15) business days of the commencement of the
																		Session Option Term and receipt invoice. The
																		Session Option Fee paid to Lender shall be
																		allocated 100% to SAG union covered services.
																				</li>
																				<li>
																		Any reinstatements of already released/expired
																		spots during the Session Term or Session
																		Option Term will be paid an agreed rate of
																		$5000 per spot with use applied at scale + 50%
																		rates.
																				</li>
																				<li>
																		The Personal Appearance Fee shall be credited
																		against the Fee as follows:
																					<ul>
																						<li>
																				Three Thousand US Dollars ($3,000) per
																				8-hour appearance day. The 8 hours does
																				not include travel time. Any hours beyond
																				the 8 hours will be paid at a rate of
																				$562.50 per hour for the 9th and 10th
																				hours, and any hours from the 11th hour on
																				will be paid at $750 per hour.
																						</li>
																						<li>
																				Travel days, if required, at $721 (+ 25%)
																				per day.
																						</li>
																						<li>
																				Per diem, if required for travel, at
																				current IRS rates which is $151.
																						</li>
																						<li>
																				Previous day wardrobe, at $89 (+25%) per
																				hour. This applies to both Personal
																				Appearance days and for Commercial shoots.
																						</li>
																					</ul>
																				</li>
																				<li>
																		In the event that commercials/appearances
																		produced during the Session Term and/or usage
																		exceed the Fee during the Session Term or the
																		Session Option Fee during the Session Option
																		Term, additional compensation owed will be
																		paid to Lender at scale + 25%. Additionally,
																		all usage occurring after the Session Term
																		and/or Session Option Term will be paid to
																		Lender at scale + 25%.
																				</li>
																				<li>
																		Digital Editing Rights: In the event
																		Advertiser creates edits, lifts, and/or
																		revisions of a Commercial for use on the
																		Internet or in new media, Lender shall be
																		entitled to one (1) scale session fee + 25%
																		for each group of up to three (3) new edits,
																		lifts, and/or revisions of such materials
																		(“Digital Editing Fee”). The Digital Editing
																		Fee will be applied to the guarantee or paid
																		to Lender in accordance with this Agreement.
																		By way of example, if Advertiser creates 3 x
																		:06 cut-downs for Instagram and Facebook from
																		a :15 Commercial, one (1) scale session fee
																		for the additional Internet and new media
																		cut-downs will be applied to the guarantee or
																		paid to Lender in accordance with this
																		Agreement.
																				</li>
																			</ol>
																		</div>
																	</li>
																	<li>
																		<div className={styles.listDrop}>
																			<b>Representations:</b>
																		</div>
																		<div className={styles.listDrop}>
																			<ol type='A'>
																				<li>
																		Lender represents and warrants that Lender has
																		the sole right, power and authority: to enter
																		into and to cause Talent to perform this
																		Agreement; to provide or cause Talent to
																		perform (as applicable) the services
																		contemplated hereby; and to grant (and cause
																		Talent to grant) the rights herein granted and
																		may do so without obtaining the permission of
																		any third party. Lender further represents and
																		warrants that (i) no payments to third parties
																		are necessary for Talent in order to enter
																		into this Agreement; and (ii) neither Lender
																		nor Talent has made, nor will make, any
																		agreement or commitment with any third party
																		which prevents or interferes in any way with
																		the full and complete rendition of Talent’s
																		services or the exercise of the rights herein
																		granted to Advertiser.
																				</li>
																				<li>
																		Advertiser represents and warrants that for
																		any union covered services, Advertiser will
																		use designees that are signatory to the
																		SAG-AFTRA Union Commercials Contract, and that
																		such designees will pay all union-related
																		fees, including pension and welfare costs
																		directly to the applicable union/guild funds.
																				</li>
																			</ol>
																		</div>
																	</li>
																	<li>
																		<div className={styles.listDrop}>
																			<b>Indemnity:</b> Lender shall indemnify and hold
																Advertiser and their parents, designees,
																affiliates, successors, and related assigns, and
																their respective officers, directors, employees
																and agents harmless from and against any and all
																third party claims, damages, liabilities, costs
																and expenses (including, without limitation,
																reasonable outside attorneys&apos; fees) arising
																out of or in connection with: (i) any claimed or
																actual breach by Lender or Talent of any warranty,
																representation, promise or undertaking made by
																Lender or Talent hereunder and/or (ii) Lender’s or
																Talent’s gross negligence, intentionally tortious
																conduct or intentional misconduct.
																		</div>
																	</li>
																	<li>
																		<div className={styles.listDrop}>
																			<b> Personal Conduct: </b>Lender and Talent hereby
																acknowledge that Talent’s conduct and behavior are
																of the utmost importance to Advertiser and that
																such conduct and behavior must at all times be
																consistent with the dignity and high standards of
																Advertiser. Accordingly, if during the term of
																this Agreement, Talent commits any act involving
																moral turpitude, and the effect of such act is of
																sufficient magnitude to require in Advertiser’s
																sole determination, for commercial reasons, the
																actual discontinuance of the use of the materials
																produced hereunder utilizing Talent’s services,
																then Advertiser shall have the right to terminate
																this Agreement forthwith. In such event, Lender
																and Talent shall return to Advertiser the fee paid
																to Lender, with the exception of compensation for
																services rendered and usage accrued as of the date
																of termination. Lender represents and warrants
																that Talent will not use the Advertiser name or
																any of its or its affiliates’ brands or product
																names or products in a disparaging, derogatory,
																pejorative, offensive or defamatory manner during
																the Term of this Agreement.
																		</div>
																	</li>
																	<li>
																		<div className={styles.listDrop}>
																			<b>Ownership:</b> Subject to Talent’s approval
																rights hereunder, all material or services
																furnished by Lender or Talent hereunder, whether
																required material or otherwise, shall be subject
																to the approval of Advertiser. All materials
																produced in connection with this Agreement,
																including the results of the services, the
																Materials and advertising ideas or phrases, will
																be and remain the absolute and exclusive property
																of Advertiser in perpetuity. Neither Lender nor
																Talent shall have or claim to have any right,
																title or interest in or to the Materials or any of
																Advertiser’s trademarks, service marks, brand
																names, logos, distinctive identifications, trade
																names, copyrights, or any creative content,
																advertising, ideas, announcements, phrases or
																titles created by or for Advertiser and whether or
																not used by Advertiser. To the extent required by
																applicable copyright laws regarding ownership of
																Materials or other materials produced hereunder or
																the content or use thereof, the product(s) of
																Talent’s services hereunder will be considered
																works made for hire, specifically commissioned
																and/or made in the course of services rendered, or
																if not legally capable of being considered as
																such, then and in such event Lender and Talent
																hereby irrevocably and unconditionally assign to
																Advertiser any and all rights, title and interest
																Lender or Talent may have in or to the Materials
																or such other materials.
																		</div>
																	</li>
																	<li>
																		<div className={styles.listDrop}>
																			<b>Independent Contractor:</b> Lender acknowledges
																that Lender is an independent contractor. Nothing
																in this agreement or in the activities
																contemplated by the parties shall be deemed to
																create any agency, partnership, employment or
																joint venture relationship between the parties.
																Lender shall receive as an independent contractor
																full payment of any compensation and amounts
																payable in connection with any services or rights
																granted hereunder. Lender shall have the entire
																responsibility as employer of Talent as to Talent.
																Lender as employer of Talent will discharge with
																respect to Talent all of the obligations of an
																employer under any federal, state or local law,
																regulation or order now or hereafter in force,
																including but not limited to, those relating to
																taxes, unemployment compensation and health,
																casualty and liability insurance, social security,
																workers compensation, disability benefits, tax
																withholding and employment of minors and including
																the filing of all returns and reports required of
																employers and the payment of all taxes,
																assessments, contributions and other sums required
																of them.
																		</div>
																	</li>
																	<li>
																		<div className={styles.listDrop}>
																			<b>Confidentiality:</b> Lender agrees that both
																Lender and Talent will not disclose any trade
																secrets or confidential information of Advertiser
																to any third parties, including but not limited to
																any content of any advertising that Advertiser has
																not yet released, Talent&apos;s relationship with
																Advertiser (until publicly disclosed by
																Advertiser), and any of the terms of this
																Agreement other than to Talent’s personal
																representatives, to third parties with whom Lender
																and/or Talent are contracted or contracting and
																who have a need to know, in connection with a
																dispute between the parties hereto, and/or as
																required by law. It is further understood and
																agreed that any and all publicity regarding
																Talent’s services hereunder shall be issued only
																by Advertiser; provided, however, that Talent
																shall be entitled to make non-derogatory,
																incidental references to Talent’s services
																hereunder in Talent’s personal publicity
																		</div>
																	</li>
																	<li>
																		<div className={styles.listDrop}>
																			<b>Termination:</b> If Lender or Talent neglect,
																fail or refuse or are unable to provide Talent’s
																services or observe Talent’s or Lender’s
																respective obligations, agreements, or warranties
																hereunder, in addition to any other rights and
																remedies that Advertiser may have hereunder or at
																law or in equity, then Advertiser will have the
																right, at any time during the Term to: (i)
																continue to make or use such Materials as
																permitted under this Agreement; (ii) extend the
																Term by written notice then in effect for a period
																of time equivalent to Talent’s unavailability to
																render services without the payment of any
																additional compensation to Talent and delay
																payment timing for a period equivalent to Talent’s
																unavailability to render services, or (iii)
																terminate this Agreement by written notice and
																Talent’s compensation shall be as set forth in the
																Personal Conduct section of this Agreement.
																		</div>
																	</li>
																</ol>
															</div>
														</div>
														<br></br>
														<div className={styles.contractFormBlock}>
															<div className={styles.bgWhite}>
																<ol>
																	<li value={16}>
																		<div className={styles.listDrop}>
																			<b>Force Majeure:</b> Neither party will be liable
																for, nor will be considered to be in breach of or
																default under this Agreement on account of, any
																delay or failure to perform as required by this
																Agreement as a result of any causes or conditions
																that are beyond such party’s reasonable control
																and that such party is unable to overcome through
																the exercise of commercially reasonable diligence.
																If for any reason beyond Advertiser’s control,
																such as boycotts, war, attacks, terrorist acts,
																delays of commercial carriers, acts of God, labor
																troubles, labor disputes, strikes, or any similar
																or dissimilar occurrence (“Force Majeure
																Occurrence”), Advertiser is prevented from making
																or using any Materials hereunder, or Talent is
																prevented from rendering her services for a period
																of five (5) consecutive days, Advertiser will have
																the right to (i) continue to make or use such
																Materials as permitted under this Agreement; (ii)
																to suspend the Term by written notice and extend
																the then current Term then in effect for a period
																of time equivalent to Advertiser’s inability to
																make or use the Materials or Talent’s inability to
																render Services without the payment of any
																additional compensation to Talent and delay
																payment timing for a period equivalent to the
																Force Majeure Occurrence, or (iii) terminate this
																Agreement and Talent’s compensation shall be as
																set forth in the Personal Conduct section above.
																		</div>
																	</li>
																	<li value={17}>
																		<div className={styles.listDrop}>
																			<b>Governing Law:</b> The present Agreement shall
																in all respects be subject exclusively to the laws
																of California applicable to contracts to be
																performed entirely within California and shall be
																interpreted under such laws without regard to its
																conflicts of law provisions. In the event of any
																dispute related to the validity, performance or
																termination of this Agreement, or the
																interpretation of any provision herein, the
																parties agree to use their good faith efforts to
																reach an amicable solution within thirty (30)
																days. In the absence of an amicable solution, any
																such dispute shall be submitted to arbitration
																before one (1) arbitrator (who shall not be a
																lawyer employed by or associated with either party
																to this Agreement) who shall be appointed in
																accordance with the Commercial Arbitration Rules
																of the American Arbitration Association then in
																effect. Any such arbitration shall take place at
																the office of the American Arbitration Association
																located in Los Angeles, California. Each party is
																entitled to depose one (1) fact witness and any
																expert witness retained by the other party, and to
																conduct such other discovery as the arbitrator
																deems appropriate. The award or decision rendered
																by the arbitrator shall be final, binding and
																conclusive, and judgment may be entered upon such
																award by any court. Each party shall bear its own
																costs in connection with the arbitration. The
																foregoing shall not preclude either party’s right
																to seek injunctive relief.
																		</div>
																	</li>
																</ol>
																<div className={styles.listDrop}>
														The information set forth above sets out the parties’
														understanding and agreement as to the matters
														described herein. If Lender and Talent are in
														agreement with the terms and conditions contained in
														this Agreement, please acknowledge acceptance by
														signing an executed copy and forwarding to Company’s
														office.
																</div>
																<h4>
																	<b>ACCEPTED AND AGREED:</b>
																</h4>
																<table
																	width={'100%'}
																	border={1}
																	cellPadding={0}
																	cellSpacing={0}
																	className={styles.contractFormsInner}
																>
																	<tr
																	>
																		<td colSpan={2} key={`advertiser-column-${contractForm.watch('advertiserId.label')}`}>
																			<b key={`text-${contractForm.watch('advertiserId.label')}`}>ADVERTISER: {contractForm.watch('advertiserId.label') || ''}</b>
																		</td>
																		<td colSpan={2} key={`lender-column-${contractForm.watch('lenderCompanyId.label')}-${contractForm.watch('performerId.label')}`}>
																			<b key={`text-${contractForm.watch('lenderCompanyId.label')}-${contractForm.watch('performerId.label')}`}> {contractForm.watch('lenderCompanyId.label') || ''}, {contractForm.watch('performerId.label') || ''}</b>
																		</td>
																	</tr>
																	<tr>
																		<td>By:</td>
																		<td>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				<NabuTextbox disabled />
																			</FormControl>
																		</td>
																		<td>By:</td>
																		<td>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				<NabuTextbox disabled />
																			</FormControl>
																		</td>
																	</tr>
																	<tr>
																		<td>Title:</td>
																		<td>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				<Controller
																					key={`advertiser-${contractForm.watch('advertiserId.value')}-${contractForm.watch('productId.value')}`}
																					control={contractForm.control}
																					name='advertiserId'
																					render={({ field }) => (
																						<>
																							<LookAheadSelect
																								{...field}
																								placeholder={!props?.isPrint ? 'Client' : ''}
																								filterProperty='CompanyNames.Name'
																								APICaller={getCompanyBasicDetails}
																								optionGenerator={companyOptionGenerator}
																								defaultFilter={ADVERTISER_FILTER}
																								isDisabled={props?.isPrint}
																								isClearable={true}
																								onChange={(e: any) => {
																									field.onChange(e);
																									contractForm.resetField('productId');
																									contractForm.trigger('advertiserId.label');
																								}}
																								cacheOptions={false}
																								defaultOptions
																								className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																							/>
																							{!props?.isPrint && (
																								<div className={styles.inlineAdd}>
																									<AddCompanyInline
																										title='Company'
																										companyType={{
																											mainType:
																					'ADVERTISER' as CompanyMainType,
																											subType: [],
																										}}
																									/>
																								</div>
																							)}
																						</>
																					)}
																				/>
																				{contractForm.formState.errors?.advertiserId?.message && (
																					<FormHelperText
																						error={true}
																						data-cy='errorHelper_ad_agency'
																					>
																						{
																							contractForm.formState?.errors?.advertiserId.message
																						}
																					</FormHelperText>
																				)}
																			</FormControl>
																		</td>
																		<td>Title:</td>
																		<td>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				<Controller
																					key={`lender-${contractForm.watch('lenderCompanyId.value')}-${contractForm.watch('productId.value')}`}
																					control={contractForm.control}
																					name='lenderCompanyId'
																					render={({ field }) => (
																						<>
																							<LookAheadSelect
																								{...field}
																								placeholder={!props?.isPrint ? 'Lender' : ''}
																								filterProperty='CompanyNames.Name'
																								APICaller={getCompanyBasicDetails}
																								optionGenerator={companyOptionGenerator}
																								isDisabled={props?.isPrint}
																								isClearable={true}
																								onChange={(e: any) => {
																									field.onChange(e);
																									contractForm.trigger('lenderCompanyId.label');
																								}}
																								cacheOptions={false}
																								defaultOptions
																								className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																							/>
																							{!props?.isPrint && (
																								<div className={styles.inlineAdd}>
																									<AddCompanyInline
																										title='Company'
																									/>
																								</div>
																							)}
																						</>
																					)}
																				/>
																				{contractForm.formState.errors?.lenderCompanyId?.message && (
																					<FormHelperText
																						error={true}
																					>
																						{
																							contractForm.formState?.errors?.lenderCompanyId.message
																						}
																					</FormHelperText>
																				)}
																			</FormControl>
																		</td>
																	</tr>
																	<tr>
																		<td>Date:</td>
																		<td>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				<NabuTextbox disabled />
																			</FormControl>
																		</td>
																		<td>Date:</td>
																		<td>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				<NabuTextbox disabled />
																			</FormControl>
																		</td>
																	</tr>
																	<tr>
																		<td>Signature:</td>
																		<td>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				<NabuTextbox disabled />
																			</FormControl>
																		</td>
																		<td>SS#/ Federal I.D:</td>
																		<td>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				<Controller
																					control={contractForm.control}
																					name='fedId'
																					defaultValue=''
																					render={({ field }) => (
																						<NabuTextbox
																							{...field}
																							disabled={props?.isPrint}
																							placeholder={!props?.isPrint ? 'Federal ID' : ''}
																						/>
																					)}
																				/>
																				{contractForm.formState.errors?.fedId
																					?.message && (
																					<FormHelperText
																						error={true}
																					>
																						{
																							contractForm.formState?.errors?.fedId
																								.message
																						}
																					</FormHelperText>
																				)}
																			</FormControl>
																		</td>
																	</tr>
																</table>
																<br></br>
																<div className={styles.listDrop}>
														I agree to be bound by and perform the foregoing
														Agreement to the extent that it applies to me.
																	<br></br>
																	<b>{contractForm.watch('performerId.label') || ''}</b>
																</div>
																<Grid item container columnSpacing={3}>
																	<Grid item md={6}>
																		<b>Signature:</b>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<NabuTextbox disabled />
																		</FormControl>
																	</Grid>
																	<Grid item md={6}>
																		<b>Date:</b>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<NabuTextbox disabled />
																		</FormControl>
																	</Grid>
																</Grid>
															</div>
														</div>
													</div>
											
												</>
												{/* Main Contract Form End */}

												{/* W4 Short form */}
												{packetControlForm.watch('isW4shortForm') && (
													<div className={styles.defaultTemplate}>
														<div className={styles.contractFormBlock}>
															<ContractW4ShortForm
																contractForm={contractForm}
																isPrint={props?.isPrint}
															/>
														</div>
													</div>
												)}
												{/* W8 Ben Short form */}
												{packetControlForm.watch('isW8BenShort') && (
													<div className={styles.defaultTemplate}>
														<div className={styles.contractFormBlock}>
															<ContractW8BenShortForm
																contractForm={contractForm}
																isPrint={props?.isPrint}
															/>
														</div>
													</div>
												)}

												{/* W9 Ben Short form */}
												{packetControlForm.watch('isW9BenShort') && (
													<div className={styles.defaultTemplate}>
														<div className={styles.contractFormBlock}>
															<ContractW9BenShortForm
																contractForm={contractForm}
																isPrint={props?.isPrint}
															/>
														</div>
													</div>
												)}

												{/* Minor Trust form */}
												{packetControlForm.watch('isMinorTrust') && (
													<div className={styles.defaultTemplate}>
														<div className={styles.contractFormBlock}>
															<ContractMinorTrustForm
																contractForm={contractForm}
																isPrint={props?.isPrint}
															/>
														</div>
													</div>
												)}

												{/* NY-Wage-Theft-prevention  form */}
												{packetControlForm.watch('isNYTheftPreventionForm') && (
													<div className={styles.defaultTemplate}>
														<div className={styles.contractFormBlock}>
															<ContractNYWageTheftPreventionForm
																contractForm={contractForm}
																isPrint={props?.isPrint}
															/>
														</div>
													</div>
												)}

												{/* CA-Wage-Theft-prevention  form */}
												{packetControlForm.watch('isCATheftPreventionForm') && (
													<div className={styles.defaultTemplate}>
														<div className={styles.contractFormBlock}>
															<ContractCAWageTheftPreventionForm
																contractForm={contractForm}
																isPrint={props?.isPrint}
															/>
														</div>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>

					{!props?.isPrint && (
						<div className={styles.contractTemplateBtn}>
							<Grid container>
								<Grid xs={12} md={6}>
									<NabuButton variant='filled' onClick={onClickSnippetBtn}>
										{!contractFormState?.isPacketOpen ? (
											<>
												<NoteAdd /> Create
											</>
										) : (
											'Close'
										)}
								&nbsp; Packet
									</NabuButton>
								</Grid>
								<Grid xs={12} md={6} dir='rtl'>
									<NabuButton
										variant='outlined'
										data-cy='cancel_btn'
										shouldHandleConfirmAction={contractForm.formState.isDirty}
										onClick={() => handleFormClose()}
									>
								Cancel
									</NabuButton>{' '}
							&nbsp;
									<NabuButton
										variant='filled'
										type='submit'
										data-cy='save_btn'
										disabled={
											!(
												contractForm.formState.isDirty ||
										packetControlForm.formState.isDirty
											) ||
									isAddingContract ||
									isEditingContract
										}
									>
										{isAddingContract || isEditingContract ? 'Saving' : 'Save'}
										<Save />
									</NabuButton>{' '}
							&nbsp;
									<NabuButton variant='filled'
										onClick={contractForm.handleSubmit(handleSaveAndDuplicate)}
										disabled={
											!(
												contractForm.formState.isDirty ||
										packetControlForm.formState.isDirty
											) ||
									isAddingContract ||
									isEditingContract || (!(props?.mainForm?.watch('contractDisplayName'))&& !props?.isEdit)
										}>
								Save & Duplicate <CopyAll />
									</NabuButton>
							&nbsp;
								</Grid>
							</Grid>
						</div>
					)}
				</form>
			</div>
			<TalentDetailsForm handleTalentDetailsForm={(value) => handleTalentDetailsForm(value)} noOfTalentsSelected={noOfTalentsSelected} setNoOfTalentsSelected={(value) => setNoOfTalentsSelected(value)} isUnion={false} />
			{isExpiryDateDialogOpen.isOpen && <ExpiryDateForm
				contractExpiryDate={isExpiryDateDialogOpen.contractExpiryDate || props?.initialValue?.expirationDate}
				isOpen={isExpiryDateDialogOpen.isOpen}
				handleExpiryDateForm={(values) => onSubmitOfExpiryDateForm(values)}
				handleFormClose={closeExpiryDateForm}
			/>}
		</>
	);
});

export default MsaTemplateThree;
