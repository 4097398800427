import NabuButton from '@/common/components/atoms/button/button';
import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import LookAheadSelect, { ILookAheadSelectOption } from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuBaseTextArea } from '@/common/components/atoms/textArea/textArea';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import NabuTimePicker from '@/common/components/molecules/timepicker/timePicker';
import AddAssetInline from '@/common/components/templates/pages/add/addAssetInline';
import AddCompanyAddressInline from '@/common/components/templates/pages/add/addCompanyAddressInline';
import AddCompanyInline from '@/common/components/templates/pages/add/addCompanyInline';
import AddEmailInline from '@/common/components/templates/pages/add/addEmailInline';
import { IPopOverState } from '@/common/components/templates/pages/add/addNewAsset';
import AddPersonInline from '@/common/components/templates/pages/add/addPersonInline';
import AddPhoneNumberInline from '@/common/components/templates/pages/add/addPhoneNumberInline';
import { CONTRACT_GUARDIAN, CONTRACT_REF_ID, ContractStatus, ContractStatusOptions, ContractUnionType, DefaultPageSize, SagAftraPriPerY22PerformerTypeOptions } from '@/common/constants/constants';
import { OPERATORS, UNION_TYPE } from '@/common/enums';
import { StatusCodes } from '@/common/enums/StatusCodes';
import useNotification from '@/common/hooks/useNotification';
import { CompanyMainType, ICompanyAddress, ICompanyRecord } from '@/common/types/company';
import {
	IBaseContract,
	ICommercialDetail,
	IContractSagAftraSdEmpCtrComPriPerY22Form, IPacketControlForm
} from '@/common/types/contract';
import { IFilterOption } from '@/common/types/filterModel';
import { displayAddress, displayOnlyEmail, displayPhoneNumberWithoutLabel, getBaseUrl, getCompanyDisplayName, getLocaleDate, getLocaleDateTime, getNotificationText, getPersonFullName, mutateContractAdditionalFormsData, sortEmailOrPhoneNumberForDropDown } from '@/common/utils/utils';
import { EmailFormModel, PhoneNumberFormModel } from '@/modules/company/shared/models/company';
import styles from '@/modules/contracts/components/addContracts.module.scss';
import { getAllAssetForBasicDetail, getAllSignatory, retriveAssetProducts } from '@/services/asset';
import { addNewCompanyEmail, getAllAddressByCompany, getAllProducts, getCompanyBasicDetails } from '@/services/company';
import { addContractSagAftraSdEmpCtrComPriPerY22, addMultipleContract, updateContractSagAftraSdEmpCtrComPriPerY22 } from '@/services/contract';
import { IAssetRecord } from '@/services/models/asset';
import { IPersonRecord } from '@/services/models/person';
import { GetByWithFullNameSearchForBasicDetail, createPersonEmail, createPersonPhoneNumber } from '@/services/person';
import store from '@/store';
import { updateContractFormState } from '@/store/contract/reducer';
import { yupResolver } from '@hookform/resolvers/yup';
import { Add, CopyAll, DeleteForever, HelpOutline, NoteAdd, PlayArrow, Save } from '@mui/icons-material';
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, ListItemButton, ListItemText, Radio, RadioGroup, Tooltip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { HttpStatusCode } from 'axios';
import { City, State } from 'country-state-city';
import { addDays } from 'date-fns';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Controller, UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import TeamCompanies from '../../forms/img/teamCompanies.png';
import { SagAftraSdEmpCtrComPriPerY22Schema } from '../contarctFormSchema';
import ContractCAWageTheftPreventionForm from '../contractCAWageyTheftPreventionForm';
import ContractMinorTrustForm from '../contractMinorTrustForm';
import ContractNYWageTheftPreventionForm from '../contractNYWageTheftPreventionForm';
import ContractW4ShortForm from '../contractW4ShortForm';
import ContractW8BenShortForm from '../contractW8BenShortForm';
import ContractW9BenShortForm from '../contractW9BenShortForm';
import TalentDetailsForm from '../talentDetailsForm';
import { IContractFormState } from './standardSagAftraEmployment2022';
import { useSearchParams } from 'react-router-dom';
import useScrollToField from '@/common/hooks/useScrollToField';

interface Props {
	mainForm?: UseFormReturn<IBaseContract, any, undefined>;
	onCancel?: VoidFunction;
	onSave?: CallableFunction;
	handlePrintStateOfContract?: CallableFunction;
	initialValue?: IContractSagAftraSdEmpCtrComPriPerY22Form;
	isPrint?: boolean;
	isEdit?: boolean;
	contractId?: string;
	assets?: IAssetRecord[];
	address?: ICompanyAddress[];
	people?: IPersonRecord[];
	defaultValue?: IBaseContract | any;
}

// eslint-disable-next-line react/display-name
const SagAftraSdEmpCtrComPriPerY22 = forwardRef((props: Props, ref) => {
	const { scroll } = useScrollToField();
	const [params, setParams] = useSearchParams();
	useImperativeHandle(ref, () => ({
		handleContractSagAftraSdEmpCtrComPriPerY22FormCancel: (data: IContractSagAftraSdEmpCtrComPriPerY22Form, statusValue: string) => {
			if(data){
				const commercials = data?.commercials?.map((d) => ({
					...d,
					assetId: d?.id,	
				}));
				const workDays = data?.workDays?.filter((d) => d?.date) || [];
				const submitData = {
					...data,
					status: statusValue ? statusValue : ContractStatus.Draft,
					contractSagAftraSdEmpCtrComPriPerY22: undefined,
					producerCompanyId: data?.producerCompanyId?.value,
					performerId: data?.performerId?.value,
					adAgencyId: data?.adAgencyId?.value,
					adAgencyAddressId: data?.adAgencyAddressId?.value,
					advertiserId: data?.advertiserId?.value,
					producerAddressId: data?.producerAddressId?.value,
					productId: data?.productId?.map(product => product?.value),
					payrollVendorId: data?.payrollVendorId?.value,
					payrollVendorAddressId: data?.payrollVendorAddressId?.value || '',
					commercials,
					workDays
				};
				editContractDetails(submitData);
			}
		},
	}));

	useEffect(() => {
		if(props?.defaultValue?.advertiserId)
			contractForm.setValue('advertiserId', props?.defaultValue?.advertiserId, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.advertiserId]);

	
	useEffect(() => {
		contractForm.setValue('isJPCAuthorizer', props?.defaultValue?.isJPCAuthorizer, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.isJPCAuthorizer]);


	const DEFAULT_COUNTRY_CODE = 'US';
	const NON_EVENING_WEAR_COST = 20.36;
	const EVENING_WEAR_COST = 33.91;
	const FLIGHT_INSURANCE_COST = 13.39;
	const DANCER_FOOTWEAR_ALLOWANCE_COST = 13.41;
	
	const notification  = useNotification();
	const contractForm = useForm<IContractSagAftraSdEmpCtrComPriPerY22Form>({
		defaultValues: !props?.initialValue
			? {
				...(props?.mainForm?.getValues() || {}),
				...(props?.defaultValue || {}),
				workDays: [{}],
				commercials: [{}]
			}
			: {...(props?.initialValue || {}), 
				commercials: props?.initialValue?.commercials?.length ? props?.initialValue?.commercials : [{}], 
				workDays: props?.initialValue?.workDays?.length ? props?.initialValue?.workDays : [{}],
			},
		resolver: yupResolver(SagAftraSdEmpCtrComPriPerY22Schema),
		shouldFocusError: false,
	});

	const packetControlForm = useForm<IPacketControlForm>({
		defaultValues: {
			isW4shortForm: !!contractForm.watch('contractW4Form.firstAndMiddleName'),
			isW8BenShort: !!contractForm.watch('contractW8BenShortForm'),
			isW9BenShort: !!contractForm.watch('contractW9BenShortForm'),
			isMinorTrust: !!contractForm.watch('contractMinorTrustForm'),
			isNYTheftPreventionForm: !!contractForm.watch('contractNYTheftPreventionForm'),
			isCATheftPreventionForm: !!contractForm.watch('contractCATheftPreventionForm'),
		},
	});

	const commercialFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'commercials',
	});

	const workDayFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'workDays',
	});
	const [contractFormState, setContractFormState] =
	useState<IContractFormState>({});

	const [companyOptions, setCompanyOptions] = useState<ICompanyRecord[]>([]);
	const [personOptions, setPersonOptions] = useState<IPersonRecord[]>(
		props?.people || []
	);
	const [assetOptions, setAssetOptions] = useState<IAssetRecord[]>(
		props?.assets || []
	);
	const [addressOptions, setAddressOptions] = useState<ICompanyAddress[]>(
		props?.address || []
	);
	const [addressPopOver, setAddressPopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const COMPENSATION_OPTIONS = [ 'SCALE', 'DOUBLE SCALE' ];

	const AD_AGENCY_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'AD AGENCY',
		},
	];

	const ADVERTISER_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'ADVERTISER',
		},
	];

	const PRODUCT_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.NOT_EQUAL,
			property: 'ProductName',
			value: '',
		},
	];

	const VENDOR_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'VENDOR',
		},
	];
	
	// helper functions
	const getCommercialFilters = () => {
		const assetFilter: IFilterOption[] = [
			{
				isCaseSensitive: false,
				operator: OPERATORS.NOT_EQUAL,
				property: 'Title',
				value: '',
			},
			{
				isCaseSensitive: false,
				operator: OPERATORS.IN,
				property: 'Advertisers.Id',
				value: JSON.stringify([contractForm.watch('advertiserId.value')]),
			},
		];
		return assetFilter;
	};

	const getPerformerFilter = (): IFilterOption[] => {
		const DEFAULT_FILTER: IFilterOption = {
			value: JSON.stringify(['TALENT']),
			property: 'PersonType',
			operator: OPERATORS.IN,
			isCaseSensitive: false,
		};
		return [DEFAULT_FILTER];
	};

	const onAddCommercial = () => {
		commercialFieldArray.append({} as ICommercialDetail);
	};

	const getProducts = async () => {
		const commercials = contractForm.watch('commercials');
		let totalProducts: any = [];
		
		if(commercials){
			for(let i = 0; i < commercials.length; i++){
				const filterModel = {
					pageSize: DefaultPageSize,
					sortDescending: false,
					sortField: 'CreatedAt',
					pageNumber: 0,
					filters: [{
						property: 'Asset.Id',
						value: commercials[i].assetId?.value ?? '',
						operator: OPERATORS.EQUAL,
						isCaseSensitive: false
					}]
				};
		
				const res: any = await retriveAssetProducts(filterModel);
		
				if(res?.data?.records){
					const products: any = res?.data?.records?.map(product => {
						return { value: product.id, label: product.productName };
					});
					
					totalProducts = [...totalProducts, ...products];
				}
			}
			contractForm.setValue('productId', totalProducts, { shouldDirty: true, shouldValidate: true });
		}
	};

	const onRemoveCommercial = (index: number) => {
		if (commercialFieldArray.fields.length > 1) {
			commercialFieldArray.remove(index);
			getProducts();
		}
	};

	const onAddWorkDay = () => {
		if (workDayFieldArray.fields.length) {
			const previousRow = {...contractForm.watch(
				`workDays.${workDayFieldArray.fields.length - 1}`
			), id: undefined};

			workDayFieldArray.append({
				...previousRow,
				date: previousRow?.date ? addDays(new Date(previousRow?.date || ''), 1) : '',
			});
		}
	};

	const onRemoveWorkDay = (index: number) => {
		if (workDayFieldArray.fields.length > 1) {
			workDayFieldArray.remove(index);
		}
	};

	const onAddressCreated = (createdAddress: ICompanyAddress) => {
		setCompanyOptions((prev) => {
			const company = companyOptions.find(
				(op) => op.id === contractForm.watch('adAgencyId.value')
			);

			!company?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				company?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === company?.id ? company : c));
		});
		contractForm.setValue('adAgencyAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const getUnions = (): any[] =>
		[UNION_TYPE.AFM, UNION_TYPE.SAG_AFTRA].map((value) => ({
			label: value,
			value: value,
		})) || [];

	const getUnionAgreement = (unionType: string): any[] =>
		ContractUnionType[unionType]?.agreements?.map((op) => ({
			label: op?.toUpperCase(),
			value: op?.toUpperCase(),
		})) || [];

	const handlePackageNotification = (key: keyof IPacketControlForm) => {
		notification.notify({
			message: `Form ${packetControlForm.watch(key) ? 'Added to' : 'Removed from'} Packet.`,
			type: 'info',
		});
	};

	const handleStateOptions = (
		responseData: any[],
		type: 'COMPANY' | 'PERSON' | 'ASSET' | 'ADDRESS'
	) => {
		const cb = {
			COMPANY: setCompanyOptions,
			PERSON: setPersonOptions,
			ASSET: setAssetOptions,
			ADDRESS: setAddressOptions,
		}[type];

		if (responseData && Array.isArray(responseData)) {
			cb((prev) => {
				const oldFilter = prev?.filter(
					(d) => !responseData?.some((r) => r?.id === d?.id)
				);
				return [...oldFilter, ...responseData];
			});
		}
	};

	const setJPCAuthorizer = () => {
		const adAgency = !!companyOptions?.find(
			(d) => d?.id === contractForm.watch('adAgencyId.value')
		)?.isJPCAuthorizer;
		const advertiser = !!companyOptions?.find(
			(d) => d?.id === contractForm.watch('advertiserId.value')
		)?.isJPCAuthorizer;

		contractForm.setValue('isJPCAuthorizer', adAgency || advertiser, {
			shouldDirty: true,
		});
	};

	const calculateTotalWardrobeFee = () => {
		const getNumber = (value: number | string | undefined): number =>
			isNaN(Number(value)) ? 0 : Number(value);

		const eveningWearCost =
			getNumber(contractForm.watch('eveningWearCount')) * EVENING_WEAR_COST;
		const nonEveningWearCost =
			getNumber(contractForm.watch('nonEveningWearCount')) *
			NON_EVENING_WEAR_COST;

		contractForm.setValue(
			'totalWardrobeFee',
			parseFloat((eveningWearCost + nonEveningWearCost).toFixed(4)),
			{ shouldDirty: true }
		);
	};

	const getAssetAdIdByAssetIndex = (index: number) => {
		const assetId = contractForm.watch(`commercials.${index}.assetId`)?.value;
		return assetOptions?.find((d) => d?.id === assetId)?.adId || '';
	};

	const getStateOptions = () => {
		const states = State.getStatesOfCountry(DEFAULT_COUNTRY_CODE).map(
			(state) => ({ label: state.name?.toUpperCase(), value: state.isoCode })
		);
		if (!states.length) states.unshift({ label: 'NA', value: 'NA' });
		return states;
	};

	const getCityOptions = (stateCode?: string) =>
		stateCode ? City.getCitiesOfState(DEFAULT_COUNTRY_CODE, stateCode)?.map((city) => ({
			label: city.name?.toUpperCase(),
			value: city.name?.toUpperCase(),
		})) || [] : [];

	const companyOptionGenerator = (responseData: ICompanyRecord[]) => {
		handleStateOptions(responseData, 'COMPANY');

		return responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));
	};

	const performerOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');
		return responseData?.map((d) => ({
			value: d?.id,
			label: getPersonFullName(d?.personName),
		}));
	};

	const adAgencyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const productOptionGenerator = (responseData) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: d?.productName?.toUpperCase(),
		}));
	};

	const lookAheadAssetOptionGenerator = (responseData: IAssetRecord[]) => {
		handleStateOptions(responseData, 'ASSET');

		return responseData?.map((d) => ({
			value: d?.id || '',
			label: d?.title || '',
		}));
	};

	const producerAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const onClickSnippetBtn = () => {
		setContractFormState((prev) => ({
			isPacketOpen: !prev?.isPacketOpen,
		}));
	};

	const getCompanyEmailOptions = (addressId?: string) => {
		const unsortedEmails =
			addressOptions?.find(
				(a) => a?.id === addressId
			)?.emails || [];
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const { mutate: addContractDetails, isLoading: isAddingContract } =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addContractSagAftraSdEmpCtrComPriPerY22(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const { mutate: editContractDetails, isLoading: isEditingContract } =
		useMutation({
			mutationKey: ['contract-edit'],
			mutationFn: (payload: any) => updateContractSagAftraSdEmpCtrComPriPerY22(props?.contractId || '', payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'UPDATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'UPDATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const handleFormClose = () => {
		contractForm?.reset();
		props?.onCancel && props?.onCancel();
	};

	const getPerformerPhoneNumberOptions = () => {
		const unsortedPhoneNumbers =
			personOptions?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.phoneNumbers || [];
		const phoneNumbers = sortEmailOrPhoneNumberForDropDown(unsortedPhoneNumbers);
		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	
	const onPayrollVendorAddressCreated = (createdAddress: ICompanyAddress) => {
		contractForm.setValue('payrollVendorAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const companyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		})) || [];
	};

	const getPerformerEmailsOptions = () => {
		const unsortedEmails =
			personOptions?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.emails || [];
		
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const handleContractSubmission = async (data: IContractSagAftraSdEmpCtrComPriPerY22Form, handleMultipleContractAdd?: CallableFunction) => {
		const commercials = data?.commercials?.map((d) => ({
			...d,
			assetId: d?.assetId?.value,
		}));
		
		const workDays = data?.workDays?.filter((d) => d?.date) || [];

		if (!packetControlForm.watch('isW4shortForm')) {
			data.w4FormId = undefined;
			data.contractW4Form = undefined;
		}		
		if (!packetControlForm.watch('isW8BenShort')) {
			data.w8BenShortFormId = undefined;
			data.contractW8BenShortForm = undefined;
		}		
		if (!packetControlForm.watch('isW9BenShort')) {
			data.w9BenShortFormId = undefined;
			data.contractW9BenShortForm = undefined;
		}		
		if (!packetControlForm.watch('isMinorTrust')) {
			data.minorTrustFormId = undefined;
			data.contractMinorTrustForm = undefined;
		}		
		if (!packetControlForm.watch('isNYTheftPreventionForm')) {
			data.nYTheftPreventionFormId = undefined;
			data.contractNYTheftPreventionForm = undefined;
		}		
		if (!packetControlForm.watch('isCATheftPreventionForm')) {
			data.cATheftPreventionFormId = undefined;
			data.contractCATheftPreventionForm = undefined;
		}		
		
		try {
			const notify = (message: string) => {
				notification.notify({
					message,
					type: 'error',
				});
			};
			// NOTE: These linked form's handled separately because the payload size is huge.
			const updatedContractIds = await mutateContractAdditionalFormsData(
				data,
				data?.performerId?.value || '',
				notify
			);
			data.w4FormId = updatedContractIds?.w4FormId;
			data.w8BenShortFormId = updatedContractIds?.w8BenShortFormId;
			data.w9BenShortFormId = updatedContractIds?.w9BenShortFormId;
			data.minorTrustFormId = updatedContractIds?.minorTrustFormId;
			data.nYTheftPreventionFormId =
							updatedContractIds?.nYTheftPreventionFormId;
			data.cATheftPreventionFormId =
							updatedContractIds?.cATheftPreventionFormId;

		} catch (error) {
			notification.notify({
				message: getNotificationText('Form', 'UPDATE', true),
				type: 'error',
			});
		} 
		finally {
			data.contractW4Form = undefined;
			data.contractW8BenShortForm = undefined;
			data.contractW9BenShortForm = undefined;
			data.contractMinorTrustForm = undefined;
			data.contractNYTheftPreventionForm = undefined;
			data.contractCATheftPreventionForm = undefined;

			const mainFormValues = props?.mainForm?.getValues();
			const addMainFormValues = !props?.isEdit ? {
				contractType: mainFormValues?.contractType,
				contractDisplayName: mainFormValues?.contractDisplayName,
				unionType: mainFormValues?.unionType,
				unionAgreement: mainFormValues?.unionAgreement,
				payrollVendorAddressId: mainFormValues?.payrollVendorAddressId || '',
			} : {};

			const submitData = {
				...data,
				...addMainFormValues,
				payrollVendorAddressId: data?.payrollVendorAddressId?.value || '',
				contractSagAftraSdEmpCtrComPriPerY22: undefined,
				producerCompanyId: data?.producerCompanyId?.value,
				performerId: data?.performerId?.value,
				adAgencyId: data?.adAgencyId?.value,
				adAgencyAddressId: data?.adAgencyAddressId?.value,
				advertiserId: data?.advertiserId?.value,
				producerAddressId: data?.producerAddressId?.value,
				productId: data?.productId?.map(product => product?.value),
				payrollVendorId: data?.payrollVendorId?.value,
				commercials,
				workDays
			};

			if (!props?.isEdit){
				addContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			}
			else {
				editContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			} 
		}
	};

	const onSubmitError = (data) => {
		scroll(data);
		notification.notify({
			message: 'Please fill all required fields',
			type: 'error',
		});
	};

	const onSubmit = (data: IContractSagAftraSdEmpCtrComPriPerY22Form) => {
		if(!props?.mainForm?.watch('contractDisplayName') && !props?.isEdit){
			notification.notify({
				message: 'Please fill Contract Display Name.',
				type: 'error'
			});
		}
		handleContractSubmission(data);
	};

	useEffect(() => {
		if(props?.mainForm){
			contractForm.setValue('contractDisplayName', props?.mainForm?.watch('contractDisplayName') || '', { shouldDirty: true});
		}
	}, [props?.mainForm?.watch('contractDisplayName')]);

	const { mutate: addMultipleContractDetails} =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addMultipleContract(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					const baseURL = getBaseUrl(window.location.href);
					const contractIds : any = res?.data || [];
					const urls =  contractIds.map((contract) => {
						return `${baseURL}/#/contracts/${contract}/contract-view?isEdit=true`;
					}) || [];

					urls.forEach((url) => {
						const a = document.createElement('a');	
						a.href = url;
						a.target = '_blank';
						const evt = document.createEvent('MouseEvents');
						evt.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
						a.dispatchEvent(evt);
					});
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
				} else
					notification.notify({
						message:
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const [contractFormData, setContractFormData] = useState<IContractSagAftraSdEmpCtrComPriPerY22Form>();

	const [noOfTalentsSelected, setNoOfTalentsSelected] = useState(false);

	const handleSaveAndDuplicate = (data: IContractSagAftraSdEmpCtrComPriPerY22Form) => {
		setNoOfTalentsSelected(true);
		setContractFormData(data);
	};

	const handleTalentDetailsForm = (data: any) => {
		setNoOfTalentsSelected(false);
		const handleMultipleContractAdd = (payloadData) => {
			if(data) {
				const contractAddModels = data?.talentRecords?.map((talent, index) => {
					const submitData = {
						...payloadData,
						id: undefined,
						status:ContractStatus?.Draft,
						partPlayed: talent?.role,
						performerAdAgencyId: talent?.agent?.value,
						performerId: talent?.talentName?.value,
						contractDisplayName: `${payloadData?.contractDisplayName}_copy_${index+1}`,
						compensation: talent?.compensation,
						classification:talent?.perfCategory?.toUpperCase(),
						performerPhone:undefined,
						performerEmail:undefined
					};
					return submitData;
				});
				const multipleContractData = {
					contractAddSagAftraSdEmpCtrComPriPerY22Models: contractAddModels
				};
				addMultipleContractDetails(multipleContractData);
			}
		};
		contractFormData && handleContractSubmission(contractFormData, handleMultipleContractAdd);
	};

	useEffect(()=> {
		if(props?.isEdit && commercialFieldArray.fields.length === 0){
			onAddCommercial();
		}
	}, [props?.isEdit]);

	const onProducerAddressCreated = (createdAddress: ICompanyAddress) => {
		contractForm.setValue('producerAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const [phoneNumberPopOver, setPhoneNumberInlinePopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const openPhoneNumberInlinePopOver = (e: React.MouseEvent<HTMLButtonElement | null>, key?: string) => {
		setPhoneNumberInlinePopOver({ isOpen: true, event: e?.currentTarget, for: key });
	};

	const closePhoneNumberInlinePopOver = () => {
		setPhoneNumberInlinePopOver({ isOpen: false });
	};

	const onPerformerPhoneNumberInlineSubmit = (formData: any) => {
		const personId = contractForm.watch('performerId.value') || '';
		createPersonPhoneNumber({ personId, phoneNumber: {...formData, isPrimary: true} }).then((res: any) => {
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Phone Number', 'CREATE'),
					type: 'success',
				});
				closePhoneNumberInlinePopOver();

				setPersonOptions(prev => (
					prev?.map(pn => (pn?.id == contractForm.watch('performerId.value') ? ({
						...pn,
						phoneNumbers: [
							{ id: res?.data?.id, ...formData },
							...(pn?.phoneNumbers || {}) as any
						]
					}) : pn))
				));	
				
				contractForm.trigger('performerId');
				contractForm.trigger('performerId.value');
				contractForm.setValue('performerPhone', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Phone Number.',
					type: 'error',
				});
			}
		});
	};

	const [emailPopOver, setEmailInlinePopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const openEmailInlinePopOver = (e: React.MouseEvent<HTMLButtonElement | null>, key?: string) => {
		setEmailInlinePopOver({ isOpen: true, event: e?.currentTarget, for: key });
	};

	const closeEmailInlinePopOver = () => {
		setEmailInlinePopOver({ isOpen: false });
	};

	const onPerformerEmailInlineSubmit = (formData: any) => {
		const personId = contractForm.watch('performerId.value') || '';
		createPersonEmail({personId, Email: {...formData, isPrimary: true}}).then((res: any) =>{
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Email', 'CREATE'),
					type: 'success',
				});
				closeEmailInlinePopOver();

				setPersonOptions(prev => (
					prev?.map(em => (em?.id == contractForm.watch('performerId.value') ? ({
						...em,
						emails: [
							{ id: res?.data?.id, ...formData },
							...(em?.emails || {}) as any
						]
					}) : em))
				));	

				contractForm.trigger('performerId');
				contractForm.trigger('performerId.value');
				contractForm.setValue('performerEmail', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Email Address.',
					type: 'error',
				});
			}
		});
	};

	const onProducerEmailInlineSubmit = (formData: any) => {
		const addressId = contractForm.watch('producerAddressId.value') || '';
		addNewCompanyEmail(addressId, {...formData, isPrimary: true}).then((res: any) =>{
			if(res?.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Email', 'CREATE'),
					type: 'success',
				});
				closeEmailInlinePopOver();

				setAddressOptions((prev) =>
					prev.map((ad) =>
						ad.id === contractForm.watch('producerAddressId.value')
							? {
								...ad,
								emails: [
									{ id: res?.data?.id, ...formData },
									...(ad?.emails || []),
								],
							}
							: ad
					)
				);
				contractForm.trigger('producerAddressId', { shouldFocus: true });
				contractForm.setValue('producerEmail', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Email Address.',
					type: 'error',
				});
			}
		});
	};

	const handleCommercialChange = async (id: string) => {
		const filterModel = {
			pageSize: DefaultPageSize,
			sortDescending: false,
			sortField: 'CreatedAt',
			pageNumber: 0,
			filters: [{
				property: 'Asset.Id',
				value: id,
				operator: OPERATORS.EQUAL,
				isCaseSensitive: false
			}]
		};

		const res: any = await retriveAssetProducts(filterModel);

		if(res?.data?.records){
			const existingProducts: ILookAheadSelectOption[] = contractForm.getValues('productId') ?? [];
			let products: any = res?.data?.records?.map(product => {
				return { value: product.id, label: product.productName, assetId: id };
			});

			products = products.filter(product => {
				const foundProduct = existingProducts.find(eProduct => eProduct.value === product.value);
				if(foundProduct) return false;
				else return true;
			});

			contractForm.setValue('productId', [...products, ...existingProducts], { shouldDirty: true, shouldValidate: true });
		}
	};

	const handleProducerChange = (index ?: any)=>{
		if(index ==0){
			contractForm.setValue('producerCompanyId',null,{shouldDirty:true});
			contractForm.setValue('producerAddressId',null,{shouldDirty:true});
			contractForm.setValue('producerPrintName','',{shouldDirty:true});
		}	
		if(contractForm.watch(`commercials.${0}.assetId.value`)?.length == 0){
			contractForm.setValue('producerCompanyId',null,{shouldDirty:true});
			contractForm.setValue('producerAddressId',null,{shouldDirty:true});
			contractForm.setValue('producerPrintName','',{shouldDirty:true});
		}
	};

	return (
		<>
			<div className={props?.isPrint ? styles.formWrapperPreview : ''}>
				<form onSubmit={contractForm.handleSubmit(onSubmit, onSubmitError)}>
					<div className={styles.packetTemplate} id ='form'>
						<Grid item container columnSpacing={3}>
							{contractFormState?.isPacketOpen && (
								<Grid item sm={12} md={4} lg={3}>
									<div className={styles.snippetSection}>
										<Grid item container columnSpacing={3}>
											<Grid item md={12}>
												<h3 className={styles.pocketTitle}> Government & Payroll forms</h3>
											</Grid>
										</Grid>
										<div className={styles.contractFormBlockSnippet}>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW4shortForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW4shortForm');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW4shortForm');
																		}}
																	/>{' '}
																	<span>W4- short version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW8BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW8BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW8BenShort');
																		}}
																	/>
																	<span>W8 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW9BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW9BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW9BenShort');
																		}}
																	/>
																	<span>W9 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isMinorTrust'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isMinorTrust');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isMinorTrust');
																		}}
																	/>
																	<span>Minor Trust Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isNYTheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isNYTheftPreventionForm');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isNYTheftPreventionForm');
																		}}
																	/>
																	<span>NY Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isCATheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isCATheftPreventionForm');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isCATheftPreventionForm');
																		}}
																	/>
																	<span>CA Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
										</div>
									</div>
								</Grid>
							)}
							<Grid
								item
								sm={12}
								md={4}
								lg={!contractFormState?.isPacketOpen ? 12 : 9}
							>
								<div
									className={
										!contractFormState?.isPacketOpen ? undefined : styles.scrollFIle
									}
								>
									<div className={styles.contractFormBlockPacket} id='contract-form-block-packet'>
										<div className={styles.contractFormBlock}>
											{props?.isEdit && (
												<div className={styles.formFiledsBlock}>
													<Grid container item columnSpacing={3}>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>Contract Name<sup>*</sup></FormLabel>
																<Controller
																	control={contractForm.control}
																	name='contractDisplayName'
																	render={({ field }) => (
																		<>
																			<NabuTextbox 
																				{...field}
																				placeholder={!props?.isPrint ? 'Contract Name' : ''}
																			/>
																		</>
																	)}
																/>
																{contractForm.formState?.errors?.contractDisplayName
																	?.message && (
																	<FormHelperText error={true}>
																		{
																			contractForm.formState.errors
																				?.contractDisplayName?.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Payroll Vendor <sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='payrollVendorId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				{...field}
																				placeholder={!props?.isPrint ? 'Payroll Vendor' : ''}
																				filterProperty='CompanyNames.Name'
																				APICaller={getCompanyBasicDetails}
																				optionGenerator={companyOptionGenerator}
																				defaultFilter={VENDOR_FILTER}
																				isClearable={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																				}}
																				cacheOptions
																				defaultOptions
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																			/>
																		</>
																	)}
																/>
																{contractForm.formState.errors?.payrollVendorId?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors?.payrollVendorId?.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Payroll Vendor Address
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	key={`payroll-address-${contractForm.watch(
																		'payrollVendorAddressId.value'
																	)}`}
																	name='payrollVendorAddressId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				{...field}
																				placeholder={'Select Address'}
																				key={`payroll-vendor-address-${contractForm.watch(
																					'payrollVendorAddressId.value'
																				)}-${contractForm.watch('payrollVendorId.value')}`}
																				filterProperty='Line1'
																				APICaller={(filterModel) =>
																					getAllAddressByCompany(
																						filterModel,
																						contractForm.watch('payrollVendorId.value') || ''
																					)
																				}
																				optionGenerator={
																					companyAddressOptionGenerator
																				}
																				isDisabled={!contractForm.watch('payrollVendorId.value')}
																				dropDownsize={50}
																				isClearable={true}
																				isSearchText={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																					contractForm.trigger('payrollVendorAddressId.value');
																				}}
																				cacheOptions={false}
																				defaultOptions
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																			/>
																			{contractForm.watch('payrollVendorId.value') && <div style={{float:'right'}}>
																				<Box sx={{mt:0.9}}>
																					<AddCompanyAddressInline
																						companyId={contractForm.watch('payrollVendorId.value')}
																						title='ADDRESS'
																						isOpen={addressPopOver?.isOpen}
																						event={addressPopOver?.event}
																						onAddressCreated={(d) => onPayrollVendorAddressCreated(d)}
																						onClose={() => setAddressPopOver({ isOpen: false })}
																						onPopOverOpen={(e) => setAddressPopOver({
																							isOpen: true,
																							event: e?.currentTarget,
																						})}
																						isPersistent={true} />
																				</Box>
																			</div>}
																		</>
																	
																	)}
																/>
																{contractForm.formState.errors?.payrollVendorAddressId?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors
																				?.payrollVendorAddressId.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Status
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='status'
																	render={({ field }) => (
																		<>
																			<NabuSelect
																				{...field}
																				placeholder={!props?.isPrint ? 'Select Status' : ''}
																				options={ContractStatusOptions || []}
																				convertValueToUpperCase={false}
																				onChange={(e) => field.onChange(e)}
																			/>
																		</>
																	)}
																/>
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Union
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='unionType'
																	defaultValue={undefined}
																	render={({ field }) => (
																		<NabuSelect
																			{...field}
																			enableEmptySelection={true}
																			placeholder='Select Union'
																			options={getUnions()}
																		/>
																	)}
																/>
																{contractForm.formState?.errors?.unionType?.message && (
																	<FormHelperText error={true}>
																		{contractForm.formState.errors.unionType.message}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														{getUnionAgreement(contractForm.watch('unionType'))?.length > 0 && (
															<Grid item sm={12} md={6} lg={2.4}>
																<FormControl fullWidth>
																	<FormLabel>
											Union Agreement <sup />
																	</FormLabel>
																	<Controller
																		control={contractForm.control}
																		name='unionAgreement'
																		defaultValue=''
																		render={({ field }) => (
																			<NabuSelect
																				{...field}
																				placeholder='Select Agreement'
																				options={getUnionAgreement(
																					contractForm.watch('unionType')
																				)}
																			/>
																		)}
																	/>
																	{contractForm.formState?.errors?.unionAgreement?.message && (
																		<FormHelperText error={true}>
																			{contractForm.formState.errors.unionAgreement.message}
																		</FormHelperText>
																	)}
																</FormControl>
															</Grid>
														)}
													</Grid>
												</div>
											)}
											{/* Main Form start */}
											<>
												<div className={styles.contractFormBlock}>
													<div id={CONTRACT_REF_ID.MAIN_FORM} className={styles.bgWhite} style={{padding:'20px'}}>
														<table className={`${styles.contractForms} keep-together`} width={'100%'}>
															<tbody>
																<tr>
																	<h4>{contractForm.watch('contractId') ? `#${contractForm.watch('contractId')}` : ''}</h4>
																</tr>
																<tr>
																	<td>
																		<img src={TeamCompanies} height={70} />
																	</td>
																	<td style={{ width: '60%' }} align='center'>
																		<h4>
																			<b>EXHIBIT 1</b>
																		</h4>
																		<br></br>
																		<h3>
										2022 SAG-AFTRA STANDARD EMPLOYMENT CONTRACT<br></br>
										COMMERCIALS - Principal Performers
																		</h3>{' '}
																	</td>
																	<td style={{ width: '20%' }}>
																		<table
																			width={'100%'}
																			border={1}
																			cellPadding={0}
																			cellSpacing={0}
																		>
																			<tr>
																				<td style={{ width: '30%' }}>Date</td>
																				<td style={{ width: '70%' }}>
																					<FormControl fullWidth className={styles.tableForms}>
																						<Controller
																							control={contractForm.control}
																							name='producerDate'
																							render={({ field }) => (
																								<NabuDatepicker
																									{...field}
																									disabled={props?.isPrint}
																									placeholder={!props?.isPrint ? 'Date' : ''}
																								/>
																							)}
																						/>
																						{contractForm.formState?.errors
																							?.producerDate?.message && (
																							<FormHelperText
																								data-cy='errorHelper_first_session_date'
																								error={true}
																							>
																								{
																									contractForm.formState?.errors
																										?.producerDate.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																			<tr>
																				<td>Est. #</td>
																				<td>
																					<FormControl fullWidth className={styles.tableForms}>
																						<Controller
																							control={contractForm.control}
																							name='est'
																							defaultValue=''
																							render={({ field }) => (
																								<NabuTextbox
																									{...field}
																									disabled={props?.isPrint}
																									placeholder={!props?.isPrint ? 'Est. #' : ''}
																								/>
																							)}
																						/>
																						{contractForm.formState.errors?.est
																							?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors?.est
																										.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																			<tr>
																				<td>Job. #</td>
																				<td>
																					<FormControl fullWidth className={styles.tableForms}>
																						<Controller
																							control={contractForm.control}
																							name='jobTitle'
																							defaultValue=''
																							render={({ field }) => (
																								<NabuTextbox
																									{...field}
																									disabled={props?.isPrint}
																									placeholder={!props?.isPrint ? 'Job. #' : ''}
																								/>
																							)}
																						/>
																						{contractForm.formState.errors?.jobTitle
																							?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors?.jobTitle
																										.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																		</table>
																	</td>
																</tr>
															</tbody>
														</table>
														<table className={`${styles.contractFormsInner} keep-together`} width={'100%'}>
															<tbody>
																<tr>
																	<td
																		align='left'
																		style={{ display: 'flex', justifyContent: 'flex-start' }}
																	>
																		<div style={{ display: 'flex' }}>
																			<Controller
																				control={contractForm.control}
																				name='isJPCAuthorizer'
																				render={({ field }) => (
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							{...field}
																							key={`jpc-${contractForm.watch(
																								'adAgencyId.value'
																							)}-${contractForm.watch(
																								'advertiserId.value'
																							)}`}
																							value={`${field.value}`}
																							checked={field.value || false}
																							onChange={undefined}
																							type='checkbox'
																							style={{
																								width: '20px',
																								marginTop: '2px',
																							}}
																						/>
																						<span>
													JPC Authorizer Agency or Advertiser
																						</span>
																						
																					</Box>
																				)}
																			/>
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>
														<table width={'100%'} className={'keep-together'}>
															<tr>
																<td width={'15%'}>Between (Producer ){ !props?.isPrint && (
																	<Tooltip
																		placement='right-end'
																		title={'Signatory'}
																	>
																		<HelpOutline fontSize='inherit' />
																	</Tooltip>)
																} <sup></sup>:</td>
																<td width={'35%'}>
																	<FormControl fullWidth className={styles.tableForms}>
																		<Controller
																			key={`commercial-assetId
																			-${contractForm.watch(`commercials.${0}.assetId.value`)}
																			`}
																			control={contractForm.control}
																			name='producerCompanyId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						key={`commercial-assetId
																						-${contractForm.watch(`commercials.${0}.assetId.value`)}
																						`}
																						{...field}
																						placeholder={!props?.isPrint ? 'Producer' : ''}
																						filterProperty='CompanyNames.Name'
																						APICaller={(filterModel) => getAllSignatory(contractForm.watch(`commercials.${0}.assetId.value`),filterModel)}
																						optionGenerator={companyOptionGenerator}
																						isDisabled={props?.isPrint}
																						isClearable={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																							contractForm.setValue('producerAddressId', null, { shouldDirty: true });
																						}}
																						cacheOptions
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{/* {!props?.isPrint && (
																						<Box sx={{mt:0.5}}>
																							<AddCompanyInline
																								title='Company'
																							/>
																						</Box>
																					)} */}
																				</>
																			)}
																		/>
																		{contractForm.formState.errors?.producerCompanyId?.message && (
																			<FormHelperText
																				error={true}
																				data-cy='errorHelper-producerCompanyId'
																			>
																				{
																					contractForm.formState?.errors
																						?.producerCompanyId.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td width={'15%'} align='right'>and (Performer)<sup>*</sup>:</td>
																<td width={'35%'}>
																	<FormControl fullWidth className={styles.tableForms}>
																		<Controller
																			control={contractForm.control}
																			name='performerId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						{...field}
																						key={`performer-name
																						-${contractForm.watch('performerId.value')}
																						-${contractForm.watch('performerPhone')}-${contractForm.watch('performerEmail')}`}
																						isClearable={true}
																						placeholder={!props?.isPrint ? 'Select Performer' : ''}
																						filterProperty='PersonName.FirstName'
																						APICaller={(
																							filterModel,
																							searchText
																						) =>
																							GetByWithFullNameSearchForBasicDetail(
																								filterModel,
																								searchText
																							)
																						}
																						isSearchText={true}
																						isDisabled={props?.isPrint}
																						optionGenerator={
																							performerOptionGenerator
																						}
																						defaultFilter={getPerformerFilter()}
																						onChange={(e: any) => {
																							field.onChange(e);
																							contractForm.setValue('performerPhone', '', {shouldDirty : true});
																							contractForm.setValue('performerEmail', '', {shouldDirty : true});
																							contractForm.trigger('performerPhone');
																							contractForm.trigger('performerEmail');
																							contractForm.setValue('performerPrintName', contractForm.watch('performerId.label'),{ shouldDirty: true });
																						}
																						}
																						cacheOptions
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{!props?.isPrint && (
																						<Box>
																							<AddPersonInline
																								title='Person'
																								onPersonCreated={() => ({})}
																								initialValues={{
																									personType: ['TALENT'],
																									isMinor: false,
																								}}
																							/>
																						</Box>
																					)}
																				</>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.performerId?.message && (
																			<FormHelperText
																				data-cy='errorHelper_first_session_date'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors
																						?.performerId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td align='center' colSpan={4}>
																	<p>
																		<b>
										Producer engages Performer and Performer agrees to perform
										services for Producer in Commercials as follows:
																		</b>
																	</p>
																</td>
															</tr>
														</table>

														<table className={`${styles.contractFormsInner} keep-together`} width={'100%'}>
															<tbody>
																<tr>
																	<td width={'60%'}>
																		<table className={`${styles.contractFormsInner} keep-together`} width={'100%'}>
																			<tr>
																				<td width={100}>For Agency<sup>*</sup>:</td>
																				<td colSpan={4}>
																					<FormControl fullWidth className={styles.tableForms}>
																						<Controller
																							control={contractForm.control}
																							name='adAgencyId'
																							render={({ field }) => (
																								<>
																									<LookAheadSelect
																										data-cy='ad_agency'
																										{...field}
																										placeholder={!props?.isPrint ? 'Ad-Agency' : ''}
																										filterProperty='CompanyNames.Name'
																										APICaller={getCompanyBasicDetails}
																										optionGenerator={companyOptionGenerator}
																										defaultFilter={AD_AGENCY_FILTER}
																										isDisabled={props?.isPrint}
																										isClearable={true}
																										onChange={(e: any) => {
																											field.onChange(e);
																											setJPCAuthorizer();
																											contractForm.resetField(
																												'adAgencyAddressId'
																											);
																										}}
																										cacheOptions
																										defaultOptions
																										className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																									/>
																									{!props?.isPrint && (
																										<Box sx={{mt:0.5}}>
																											<AddCompanyInline
																												title='Company'
																												companyType={{ mainType: 'AD AGENCY' as CompanyMainType, subType: [] }} />
																										</Box>
																									)}
																								</>
																							)}
																						/>
																						{contractForm.formState.errors?.adAgencyId?.message && (
																							<FormHelperText
																								error={true}
																								data-cy='errorHelper_ad_agency'
																							>
																								{
																									contractForm.formState?.errors?.adAgencyId.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																			<tr>
																				<td width={150}>Agency Address:</td>
																				<td colSpan={4}>
																					<FormControl fullWidth className={styles.tableForms}>
																						<div>
																							<Controller
																								control={contractForm.control}
																								key={`agency-address-cr-${contractForm.watch(
																									'adAgencyId.value'
																								)}`}
																								name='adAgencyAddressId'
																								render={({ field }) => (
																									<LookAheadSelect
																										{...field}
																										placeholder={!props?.isPrint ? 'Select Address' : ''}
																										key={`agency-address-${contractForm.watch(
																											'adAgencyId.value'
																										)}`}
																										filterProperty='Line1'
																										sortField='IsPrimary'
																										sortDescending={true}
																										onOptionLoaded={(options) => {
																											if(options?.length && !contractForm.watch('adAgencyAddressId.value')){
																												contractForm.setValue('adAgencyAddressId', options[0], { shouldDirty: true});
																												contractForm.trigger('adAgencyAddressId', { shouldFocus: true});
																											}
																										}}
																										APICaller={(filterModel) =>
																											getAllAddressByCompany(
																												filterModel,
																												contractForm.watch('adAgencyId.value') || ''
																											)
																										}
																										optionGenerator={
																											adAgencyAddressOptionGenerator
																										}
																										isDisabled={
																											!contractForm.watch('adAgencyId.value') ||
																																								props?.isPrint
																										}
																										dropDownsize={50}
																										isClearable={true}
																										isSearchText={true}
																										onChange={(e: any) => {
																											field.onChange(e);
																										}}
																										cacheOptions
																										defaultOptions
																										className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																									/>
																								)}
																							/>
																							{contractForm.watch('adAgencyId.value') && (!props?.isPrint)  && <div style={{float:'right'}}>
																								<Box sx={{mt:0.9}}>
																									<AddCompanyAddressInline
																										companyId={contractForm.watch('adAgencyId.value')}
																										title='ADDRESS'
																										isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'AdAgency-address'}
																										event={addressPopOver?.event}
																										onAddressCreated={(d) => onAddressCreated(d)}
																										onClose={() => setAddressPopOver({ isOpen: false })}
																										onPopOverOpen={(e) => setAddressPopOver({
																											isOpen: true,
																											event: e?.currentTarget,
																											for: 'AdAgency-address'
																										})}
																										isPersistent={true} />
																								</Box>
																							</div>}
																						</div>
																						{contractForm.formState.errors?.adAgencyAddressId?.message && (
																							<FormHelperText
																								error={true}
																								data-cy='errorHelper_ad_agency'
																							>
																								{
																									contractForm.formState?.errors
																										?.adAgencyAddressId.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																			<tr>
																				<td>Advertiser<sup>*</sup>:</td>
																				<td>
																					<FormControl fullWidth className={styles.tableForms}>
																						<Controller
																							control={contractForm.control}
																							name='advertiserId'
																							render={({ field }) => (
																								<>
																									<LookAheadSelect
																										{...field}
																										placeholder={!props?.isPrint ? 'Advertiser' : ''}
																										filterProperty='CompanyNames.Name'
																										APICaller={getCompanyBasicDetails}
																										optionGenerator={companyOptionGenerator}
																										defaultFilter={ADVERTISER_FILTER}
																										isDisabled={props?.isPrint}
																										isClearable={true}
																										onChange={(e: any) => {
																											field.onChange(e);
																											contractForm.setValue('commercials', [{} as any], { shouldDirty: true});
																											contractForm.setValue('productId', null, { shouldDirty: true});
																											setJPCAuthorizer();
																										}}
																										cacheOptions
																										defaultOptions
																										className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																									/>
																									{!props?.isPrint && (
																										<Box sx={{mt:0.5}}>
																											<AddCompanyInline
																												title='Company'
																												companyType={{ mainType: 'ADVERTISER' as CompanyMainType, subType: [] }} />
																										</Box>
																									)}
																								</>
																							)}
																						/>
																						{contractForm.formState.errors?.advertiserId?.message && (
																							<FormHelperText
																								error={true}
																								data-cy='errorHelper_ad_agency'
																							>
																								{
																									contractForm.formState?.errors?.advertiserId.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																				<td>Product:</td>
																				<td>
																					<FormControl fullWidth className={styles.tableForms}>
																						<Controller
																							control={contractForm.control}
																							name='productId'
																							render={({ field }) => (
																								<LookAheadSelect
																									{...field}
																									placeholder={!props?.isPrint ? 'Select Product' : ''}
																									key={`product-${contractForm.watch(
																										'advertiserId.value'
																									)}`}
																									isMulti
																									filterProperty='ProductName'
																									sortField='ProductName'
																									sortDescending={true}
																									APICaller={(filterModel) =>
																										getAllProducts(
																											filterModel,
																											contractForm.watch('advertiserId.value')
																										)
																									}
																									optionGenerator={productOptionGenerator}
																									isDisabled={
																										!contractForm.watch('advertiserId.value') ||
																		props?.isPrint
																									}
																									defaultFilter={PRODUCT_FILTER}
																									dropDownsize={20}
																									isClearable={true}
																									onChange={(e: any) => {
																										field.onChange(e);
																									}}
																									cacheOptions
																									defaultOptions
																									className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																								/>
																							)}
																						/>
																					</FormControl>
																				</td>
																			</tr>
																			<tr>
																				<td>Compensation{ !props?.isPrint && (
																					<Tooltip
																						placement='right-end'
																						title={'You can manually Enter the required option'}
																					>
																						<HelpOutline fontSize='inherit' />
																					</Tooltip>)
																				}<sup>*</sup>:</td>
																				<td>
																					<FormControl fullWidth className={styles.tableForms}>
																						<Controller
																							control={contractForm.control}
																							name='compensation'
																							defaultValue='SCALE'
																							render={({ field }) => (
																								<Autocomplete
																									{...field}
																									options={field.value ? [...COMPENSATION_OPTIONS, field.value] : COMPENSATION_OPTIONS}
																									disabled={props?.isPrint}
																									renderInput={(params) => (
																										<TextField {...params} placeholder={!props?.isPrint ? 'Compensation' : ''} />
																									)}
																									filterOptions={(options, params) => {
																										const { inputValue } = params;
																										const isExisting = options.some((option) => inputValue === option);
																										if (inputValue !== '' && !isExisting) {
																											options.push(inputValue?.toUpperCase() || '');
																										}
																										return options;
																									}}
																									onChange={(e, value) => {
																										contractForm.setValue('compensation', value?.toUpperCase() || '', { shouldDirty: true});																									
																									}}
																								/>
																							)}
																						/>
																						{contractForm.formState.errors?.compensation
																							?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors?.compensation
																										.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																				<td>Part Played:</td>
																				<td>
																					<FormControl fullWidth className={styles.tableForms}>
																						<Controller
																							control={contractForm.control}
																							name='partPlayed'
																							defaultValue=''
																							render={({ field }) => (
																								<NabuTextbox
																									{...field}
																									disabled={props?.isPrint}
																									placeholder={!props?.isPrint ? 'Part Played' : ''}
																								/>
																							)}
																						/>
																						{contractForm.formState.errors?.partPlayed
																							?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors?.partPlayed
																										.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																		</table>
																	</td>
																	<td width={'40%'} style={{verticalAlign:'top'}}>
																		<table border={1} className={styles.contractFormsInner} width={'100%'} cellPadding={0} cellSpacing={0}>
																			<tr>
																				<td width={150}>Engagement Date<sup>*</sup>:</td>
																				<td colSpan={4}>
																					<FormControl fullWidth className={styles.tableForms}>
																						<Controller
																							control={contractForm.control}
																							name='engagementDate'
																							render={({ field }) => (
																								<NabuDatepicker
																									{...field}
																									disabled={props?.isPrint}
																									placeholder={!props?.isPrint ? 'Engagement Date' : ''}
																								/>
																							)}
																						/>
																						{contractForm.formState?.errors
																							?.engagementDate?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors
																										?.engagementDate.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																			<tr>
																				<td>State<sup>*</sup>:</td>
																				<td colSpan={4}>
																					<FormControl fullWidth className={styles.tableForms}>
																						<Controller
																							control={contractForm.control}
																							name='adAgencyState'
																							defaultValue=''
																							render={({ field }) => (
																								<NabuSelect
																									{...field}
																									options={getStateOptions()}
																									disabled={props?.isPrint}
																									convertValueToUpperCase={false}
																									placeholder={!props?.isPrint ? 'Select State' : ''}
																								/>
																							)}
																						/>
																						{contractForm?.formState?.errors?.adAgencyState
																							?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors?.adAgencyState
																										.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																				
																			</tr>
																			<tr>
																				<td>City<sup>*</sup>:</td>
																				<td colSpan={4}>
																					<FormControl fullWidth className={styles.tableForms}>
																						<Controller
																							control={contractForm.control}
																							name='adAgencyCity'
																							defaultValue=''
																							render={({ field }) => (
																								<NabuSelect
																									{...field}
																									disabled={props?.isPrint}
																									options={getCityOptions(
																										contractForm.watch('adAgencyState')
																									)}
																									convertValueToUpperCase={true}
																									placeholder={!props?.isPrint ? 'Select City' : ''}
																								/>
																							)}
																						/>
																						{contractForm.formState?.errors?.adAgencyCity
																							?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors?.adAgencyCity
																										.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																			<tr>
																				<td>Time From:</td>
																				<td>
																					<FormControl fullWidth className={styles.tableForms}>
																						<Controller
																							control={contractForm.control}
																							name='timeFrom'
																							render={({ field }) => (
																								<NabuTimePicker
																									{...field}
																									ampm={true}
																									disabled={props?.isPrint}
																								/>
																							)}
																						/>
																						{!!contractForm.formState?.errors?.timeFrom?.message && (
																							<FormHelperText error={true}>
																								{ contractForm.formState?.errors?.timeFrom?.message }
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																				<td>To:</td>
																				<td>
																					<FormControl fullWidth className={styles.tableForms}>
																						<Controller
																							control={contractForm.control}
																							name='timeTo'
																							render={({ field }) => (
																								<NabuTimePicker
																									{...field}
																									ampm={true}
																									disabled={props?.isPrint}
																								/>
																							)}
																						/>
																						{!!contractForm.formState?.errors?.timeTo?.message && (
																							<FormHelperText error={true}>
																								{ contractForm.formState?.errors?.timeTo?.message }
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																		</table>
																	</td>
																</tr>
															</tbody>
														</table>

														<table className={`${styles.contractFormsInner} keep-together`} width={'100%'}>
															<tr>
																<td align='center'>
																	<p>
																		<b>
										Employer of Record for income tax and unemployment
										insurance:
																		</b>{' '}
									Talent Entertainment and Media Services, LLC dba{' '}
																		<b>
										The Team Companies, LLC, 2300 Empire Ave., 5th Floor,
										Burbank, CA 91504
																		</b>
																	</p>
																</td>
															</tr>
														</table>
														<hr style={{ background: '#000' }}></hr>
														<table
															width={'100%'}
															border={1}
															cellPadding={0}
															cellSpacing={0}
															className={`${styles.contractFormsInner} keep-together`}
															style={{ borderTop: '0px' }}
														>
															<tr>
																<td>
																	Commercial Title(s)<sup>*</sup>
																	{ !props?.isPrint && (
																		<Tooltip 
																			placement='right-end'
																			title={!contractForm.watch('advertiserId.value') ? 'Select Advertiser before selecting commercials' : 'Commercials are fetched from the selected advertiser.'}
																		>
																			<HelpOutline fontSize='inherit' />
																		</Tooltip>)
																	}
																</td>
																<td>Ad-ID(s)</td>
																<td># Spots</td>
																<td># Tags</td>
																<td># Demos</td>
																{!props?.isPrint && <td></td>}
															</tr>
															{commercialFieldArray.fields.map((field, index) => (
																<tr key={`commercial-${field.id}`}>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name={`commercials.${index}.assetId`}
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							data-cy='source-asset'
																							{...field}
																							placeholder={!props?.isPrint ? 'Commercial' : ''}
																							filterProperty='Title'
																							isDisabled={props?.isPrint || !contractForm.watch('advertiserId.value')}
																							defaultFilter={getCommercialFilters()}
																							APICaller={(filterModel) => getAllAssetForBasicDetail(filterModel, false)}
																							optionGenerator={
																								lookAheadAssetOptionGenerator
																							}
																							onChange={(e: any) => { handleCommercialChange(e?.value);handleProducerChange(index);	 field.onChange(e);}}
																							isClearable={true}
																							cacheOptions
																							defaultOptions
																							dropDownsize={25}
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{!props?.isPrint && (
																							<Box sx={{mt: 1}}>
																								<AddAssetInline
																									title='Asset' />
																							</Box>
																						)}
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors?.commercials
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState.errors?.commercials[index]?.assetId?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<NabuTextbox
																				value={getAssetAdIdByAssetIndex(index)}
																			/>
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name={`commercials.${index}.spots`}
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						type='number'
																						{...field}
																						placeholder={!props?.isPrint ? 'Spots' : ''}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/>
																			{contractForm.formState?.errors?.commercials
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState.errors?.commercials[
																							index
																						]?.spots?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name={`commercials.${index}.tags`}
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						type='number'
																						{...field}
																						placeholder={!props?.isPrint ? 'Tags' : ''}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/>
																			{contractForm.formState?.errors?.commercials
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState.errors?.commercials[
																							index
																						]?.tags?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name={`commercials.${index}.demos`}
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						placeholder={!props?.isPrint ? 'Demos' : ''}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/>
																			{contractForm.formState?.errors?.commercials
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState.errors?.commercials[
																							index
																						]?.demos?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	{!props?.isPrint && (
																		<>
																			<td width={75}>
																				<div className={styles.inlineText}>
																					{commercialFieldArray.fields.length - 1 ===
																	index && (
																						<NabuButton
																							variant='text'
																							onClick={onAddCommercial}
																						>
																							<Add />
																						</NabuButton>
																					)}
																					{commercialFieldArray?.fields.length > 1 && (
																						<NabuButton
																							variant='text'
																							onClick={() =>{ onRemoveCommercial(index);handleProducerChange(index);	}}
																						>
																							<DeleteForever color='error' />
																						</NabuButton>
																					)}
																				</div>
																			</td>
																		</>
																	)}
																</tr>
															))}
															<tr>
																<td width={'50%'} colSpan={2}>
																	<div
																		style={{
																			marginTop: '7px',
																			display: 'inline-block',
																		}}
																	>
														Total number of commercials{' '}
																	</div>
																	<FormControl
																		className={styles.tableForms}
																		style={{ width: '50%' }}
																	>
																		<NabuTextbox
																			disabled={true}
																			key={contractForm
																				.watch('commercials')
																				?.map((d) => d?.spots)
																				?.join('-')}
																			value={contractForm
																				?.watch('commercials')
																				?.reduce(
																					(prev, cur) => {
																						const spots = Number(cur?.spots);
																						return prev + (isNaN(spots) ? 0 : spots);
																					},
																					0
																				)}
																		/>
																	</FormControl>
																</td>
																<td width={'50%'} valign='middle' colSpan={4}>
																	<div
																		style={{
																			marginTop: '7px',
																			display: 'inline-block',
																		}}
																	>
														Total number of tags{' '}
																	</div>
																	<FormControl
																		className={styles.tableForms}
																		style={{ width: '50%' }}
																	>
																		<NabuTextbox
																			disabled={true}
																			key={contractForm
																				.watch('commercials')
																				?.map((d) => d?.tags)
																				?.join('-')}
																			value={contractForm
																				.watch('commercials')
																				.reduce(
																					(prev, cur) =>{
																						const tags = Number(cur?.tags);
																						return prev + (isNaN(tags) ? 0 : tags);
																					
																					},
																					0
																				)}
																		/>
																	</FormControl>
																</td>
															</tr>
														</table>
														<br></br>
														<table
															className={`${styles.contractFormsInner} keep-together`}
															width={'100%'}
															border={0}
															cellPadding={0}
															cellSpacing={0}
														>
															<tr>
																<td
																	width={'70%'}
																	style={{ padding: '0', paddingRight: '20px' }}
																	valign='top'
																>
																	<table
																		className={styles.contractFormsInner}
																		width={'100%'}
																		border={0}
																		cellPadding={0}
																		cellSpacing={0}
																		style={{ border: '#515151 solid 1.5px' }}
																	>
																		<tr>
																			<td valign='top'>
																				<p>
																					<b>Classification</b>
																				</p>
																				<br></br>
																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='isOnCamera'
																						defaultValue={false}
																						render={({ field }) => (
																							<RadioGroup className={styles.radioGroupPrint}
																								row
																								{...field}
																								onChange={() => field.onChange(!field.value)}
																							>
																								<FormControlLabel
																									value={true}
																									control={<Radio data-cy=' On-Camera' />}
																									label='On-Camera'
																									disabled={props?.isPrint}
																								/>
																								<FormControlLabel
																									value={false}
																									control={<Radio data-cy=' On-Camera' />}
																									label='Off-Camera'
																									disabled={props?.isPrint}
																								/>
																							</RadioGroup>
																						)}
																					/>
																				</div>
																			</td>
																			<td>
																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='classification'
																						// defaultValue={'PRINCIPAL'}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange('PRINCIPAL_PERFORMER')}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value='PRINCIPAL_PERFORMER'
																										checked={field.value === 'PRINCIPAL_PERFORMER'}
																										onChange={(e) => field.onChange(e.target.value)}
																										type='radio'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>Principal</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</div>

																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='classification'
																						// defaultValue={'CHARACTER VOICE'}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange('CHARACTER_VOICE')}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value='CHARACTER_VOICE'
																										checked={field.value === 'CHARACTER_VOICE'}
																										onChange={(e) => field.onChange(e.target.value)}
																										type='radio'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>Character Voice</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</div>

																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='classification'
																						// defaultValue={'PUPPETEER'}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange('PUPPETEER')}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value='PUPPETEER'
																										checked={field.value === 'PUPPETEER'}
																										onChange={(e) => field.onChange(e.target.value)}
																										type='radio'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>Puppeteer</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</div>

																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='classification'
																						// defaultValue={'STUNT PERFORMER'}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange('STUNT_PERFORMER')}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value='STUNT_PERFORMER'
																										checked={field.value === 'STUNT_PERFORMER'}
																										onChange={(e) => field.onChange(e.target.value)}
																										type='radio'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>Stunt Performer</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</div>

																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='classification'
																						// defaultValue={'STUNT COORDINATOR'}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange('STUNT_COORDINATOR')}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value='STUNT_COORDINATOR'
																										checked={field.value === 'STUNT_COORDINATOR'}
																										onChange={(e) => field.onChange(e.target.value)}
																										type='radio'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>Stunt Coordinator</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</div>

																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='classification'
																						// defaultValue={'DANCER'}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange('DANCER')}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value='DANCER'
																										checked={field.value === 'DANCER'}
																										onChange={(e) => field.onChange(e.target.value)}
																										type='radio'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>Dancer</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</div>
																			</td>
																			<td>
																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='classification'
																						// defaultValue={'CONTRACTOR'}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange('CONTRACTOR')}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value='CONTRACTOR'
																										checked={field.value === 'CONTRACTOR'}
																										onChange={(e) => field.onChange(e.target.value)}
																										type='radio'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>Contractor</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</div>

																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='classification'
																						// defaultValue={'SINGER'}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange('SINGER')}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value='SINGER'
																										checked={field.value === 'SINGER'}
																										onChange={(e) => field.onChange(e.target.value)}
																										type='radio'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>Singer</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</div>

																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='classification'
																						// defaultValue={'SOLO-DUO'}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange('SOLO_DUO')}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value='SOLO_DUO'
																										checked={field.value === 'SOLO_DUO'}
																										onChange={(e) => field.onChange(e.target.value)}
																										type='radio'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>Solo-Duo</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</div>

																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='classification'
																						// defaultValue={'GROUP 3-5 DANCER'}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange('GROUP_3_5_DANCER')}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value='GROUP_3_5_DANCER'
																										checked={field.value === 'GROUP_3_5_DANCER'}
																										onChange={(e) => field.onChange(e.target.value)}
																										type='radio'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>Group 3-5 Dancer</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</div>

																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='classification'
																						// defaultValue={'SINGER GROUP 6-8'}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange('SINGER_GROUP_6_8')}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value='SINGER_GROUP_6_8'
																										checked={field.value === 'SINGER_GROUP_6_8'}
																										onChange={(e) => field.onChange(e.target.value)}
																										type='radio'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>Singer Group 6-8</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</div>

																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='classification'
																						// defaultValue={'SINGER GROUP 9+'}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange('SINGER_GROUP_9_MORE')}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value='SINGER_GROUP_9_MORE'
																										checked={field.value === 'SINGER_GROUP_9_MORE'}
																										onChange={(e) => field.onChange(e.target.value)}
																										type='radio'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>Singer Group 9+</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</div>
																			</td>

																			<td>
																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='classification'
																						// defaultValue={'SPECIALTY ACT'}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange('SPECIALTY_ACT')}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value='SPECIALTY_ACT'
																										checked={field.value === 'SPECIALTY_ACT'}
																										onChange={(e) => field.onChange(e.target.value)}
																										type='radio'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>Specialty Act</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</div>

																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='classification'
																						// defaultValue={'PILOT'}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange('PILOT')}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value='PILOT'
																										checked={field.value === 'PILOT'}
																										onChange={(e) => field.onChange(e.target.value)}
																										type='radio'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>Pilot</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</div>

																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='classification'
																						// defaultValue={'SIGNATURE'}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange('SIGNATURE')}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value='SIGNATURE'
																										checked={field.value === 'SIGNATURE'}
																										onChange={(e) => field.onChange(e.target.value)}
																										type='radio'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>Signature</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</div>

																				<div>
																					<span>Other: </span>
																				</div>

																				{props?.isPrint ? (
																					<p className={styles.descriptionPrintText}>
																						{contractForm.watch('otherClassification')}
																					</p>
																				) : (
																					<>
																						<Controller
																							control={contractForm.control}
																							name='otherClassification'
																							defaultValue=''
																							render={({ field }) => (
																								<textarea
																									{...field}
																									disabled={props?.isPrint}
																									rows={1}
																									placeholder={!props?.isPrint ? 'Other Classification' : ''}
																								/>
																							)}
																						/>
																						{contractForm.formState.errors?.otherClassification?.message && (
																							<FormHelperText data-cy='errorHelper-job-title-name' error={true}>
																								{contractForm.formState?.errors?.otherClassification?.message}
																							</FormHelperText>
																						)}
																					</>
																				)}
																			</td>

																		</tr>
																	</table>
																	<br></br>
																	<table
																		className={styles.contractFormsInner}
																		width={'100%'}
																		border={0}
																		cellPadding={0}
																		cellSpacing={0}
																		style={{ border: '#515151 solid 1.5px' }}
																	>
																		<tr>
																			<td>
																				<p>
																					<b>Wardrobe to be furnished by:</b>
																				</p>
																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name="wardrobeFurnishedBy"
																						defaultValue="none"
																						render={({ field }) => (
																							<div>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value="producer"
																										checked={field.value === 'producer'}
																										onChange={(e) => field.onChange(e.target.value)}
																										type="radio"
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>Producer</span>
																								</Box>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value="performer"
																										checked={field.value === 'performer'}
																										onChange={(e) => field.onChange(e.target.value)}
																										type="radio"
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>Performer</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</div>
																				<br />
																				<p>
																					<b>If furnished by Performer, # of costumes:</b>
																				</p>
																				<ul>
																					<div style={{ display: 'flex', alignItems: 'center' }}>
      Non-Evening @ ${NON_EVENING_WEAR_COST} x
																						<FormControl className={styles.tableForms} fullWidth style={{ maxWidth: 150 }}>
																							<Controller
																								control={contractForm.control}
																								name="nonEveningWearCount"
																								render={({ field }) => (
																									<NabuTextbox
																										data-cy="job_name_textbox"
																										{...field}
																										disabled={props?.isPrint || contractForm.watch('wardrobeFurnishedBy') !== 'performer'}
																										// onChange={(e) => {
																										// 	field.onChange(e);
																										// 	calculateTotalWardrobeFee();
																										// }}
																										onChange={(e) => {
																											const value = e.target.value || '';
																							
																											if (/^\d*$/.test(value)) {
																												field.onChange(value);
																											}
																											calculateTotalWardrobeFee();
																										}}
																										type="number"
																										placeholder={!props?.isPrint ? 'Non-Evening Wear' : ''}
																									/>
																								)}
																							/>
																							{contractForm.formState.errors?.nonEveningWearCount?.message && (
																								<FormHelperText data-cy="errorHelper-job-title-name" error={true}>
																									{contractForm.formState?.errors?.nonEveningWearCount.message}
																								</FormHelperText>
																							)}
																						</FormControl>
      (#)
																					</div>
																					<div style={{ display: 'flex', alignItems: 'center' }}>
      Evening Wear @ ${EVENING_WEAR_COST} x
																						<FormControl className={styles.tableForms} fullWidth style={{ maxWidth: 150 }}>
																							<Controller
																								control={contractForm.control}
																								name="eveningWearCount"
																								render={({ field }) => (
																									<NabuTextbox
																										data-cy="job_name_textbox"
																										{...field}
																										disabled={props?.isPrint || contractForm.watch('wardrobeFurnishedBy') !== 'performer'}
																										onChange={(e) => {
																											const value = e.target.value || '';
																							
																											if (/^\d*$/.test(value)) {
																												field.onChange(value);
																											}
																											calculateTotalWardrobeFee();
																										}}
																										type="number"
																										placeholder={!props?.isPrint ? 'Evening Wear' : ''}
																									/>
																								)}
																							/>
																							{contractForm.formState.errors?.eveningWearCount?.message && (
																								<FormHelperText error={true}>
																									{contractForm.formState?.errors?.eveningWearCount.message}
																								</FormHelperText>
																							)}
																						</FormControl>
      (#)
																					</div>
																					<div style={{ display: 'flex', alignItems: 'center' }}>
																						<b>Total wardrobe fee: $</b>
																						<FormControl className={styles.tableForms} fullWidth style={{ maxWidth: 150 }}>
																							<Controller
																								control={contractForm.control}
																								name="totalWardrobeFee"
																								render={({ field }) => (
																									<NabuTextbox
																										{...field}
																										disabled={true}
																										value={contractForm.watch('totalWardrobeFee')}
																										placeholder={!props?.isPrint ? 'Total Wardrobe Fee' : ''}
																										onChange={(e) => {
																											const value = e?.target?.value ?? '';
																											const num = value.match(/^\d*\.?\d*/)?.[0];
																											if (!isNaN(Number(num))) {
																												field.onChange(Number(num));
																											} else {
																												field.onChange(0);
																											}
																										}}
																									/>
																								)}
																							/>
																							{contractForm.formState.errors?.totalWardrobeFee?.message && (
																								<FormHelperText data-cy="errorHelper-job-title-name" error={true}>
																									{contractForm.formState?.errors?.totalWardrobeFee.message}
																								</FormHelperText>
																							)}
																						</FormControl>
																					</div>
																				</ul>
																			</td>

																			<td>
																				<p>
																					<b>Multiple Tracking or Sweetening:</b>
																				</p>
																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='isMultipleTrackingOrSweeteningOccur'
																						defaultValue={false}
																						render={({ field }) => (
																							<RadioGroup
																								row
																								{...field}
																								onChange={() => field.onChange(!field.value)}
																								className={styles.radioGroupPrint}
																							>
																								<FormControlLabel
																									value={true}
																									control={<Radio data-cy='On-Camera' />}
																									label={<span><b>DID</b> occur</span>}
																									disabled={props?.isPrint}
																								/>
																								<FormControlLabel
																									value={false}
																									control={<Radio data-cy='On-Camera' />}
																									label={<span>Did <b>NOT occur</b></span>}
																									disabled={props?.isPrint}
																								/>
																							</RadioGroup>
																						)}
																					/>
												
																				</div>
																				<br></br>
																				<b>Check if Payable:</b>
																				<ul>
																					<div style={{ display: 'flex', alignItems: 'center' }}>
																						<Controller
																							control={contractForm.control}
																							name='isFlightInsurancePayable'
																							defaultValue={false}
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value={field.value + ''}
																											checked={field.value}
																											onChange={(e) => field.onChange(e)}
																											type='checkbox'
																											disabled={props?.isPrint}
																											style={{ marginTop: '3px' }}
																										/>
																										<span><b>Flight Insurance </b>@ ${FLIGHT_INSURANCE_COST}</span></Box>
																								</div>
																							)}
																						/>
													
																					</div>
																					<div style={{ display: 'flex', alignItems: 'center' }}>
																						<Controller
																							control={contractForm.control}
																							name='isDancersFootwearAllowance'
																							defaultValue={false}
																							render={({ field }) => (
																								<div
																									onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																									style={{ userSelect: 'none' }}
																								>
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value={field.value + ''}
																											checked={field.value}
																											onChange={(e) => field.onChange(e)}
																											type='checkbox'
																											disabled={props?.isPrint}
																											style={{ marginTop: '3px' }}
																										/>
																										<span> <b>Dancer’s Footwear Allowance</b></span></Box>
																								</div>
																							)}
																						/>
																					</div>
																					<div style={{ display: 'flex', alignItems: 'center' }}>
													@ ${DANCER_FOOTWEAR_ALLOWANCE_COST} x
																						<FormControl className={styles.tableForms}>
																							<Controller
																								control={contractForm.control}
																								name='dancersFootwearAllowanceDays'
																								render={({ field }) => (
																									<input
																										{...field}
																										disabled={props?.isPrint}
																										placeholder={!props?.isPrint ? 'Footwear Allowance' : ''}
																										min={0}
																										onChange={(e) => field.onChange(parseInt(`${Number(e?.target?.value || 0) || 0}`))}
																										type='number'
																									/>
																								)}
																							/>
																							{contractForm.formState.errors?.dancersFootwearAllowanceDays
																								?.message && (
																								<FormHelperText
																									data-cy='errorHelper-job-title-name'
																									error={true}
																								>
																									{
																										contractForm.formState?.errors
																											?.dancersFootwearAllowanceDays.message
																									}
																								</FormHelperText>
																							)}
																						</FormControl>{' '}
													days
																					</div>
																					<div style={{ display: 'flex', alignItems: 'center' }}>
													Total footwear:{' '}
																						<FormControl className={styles.tableForms}>
																							<Controller
																								control={contractForm.control}
																								name='totalDancersFootwearAllowance'
																								render={({ field }) => (
																									<NabuTextbox
																										{...field}
																										disabled={props?.isPrint}
																										placeholder={!props?.isPrint ? 'Total Allowance' : ''}
																										onChange={(e: any) => {
																											const value = e?.target?.value || '';
																											if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																												field.onChange(value);
																											} else {
																												const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																												field.onChange(num);
																											}
																										}}
																										onBlur={() => {
																											const numericValue = parseFloat((field.value ?? '0').toString());
																											if (!isNaN(numericValue)) {
																												field.onChange(numericValue.toFixed(2));
																											} else {
																												field.onChange('0.00');
																											}
																										}}
																									/>
																								)}
																							/>
																							{contractForm.formState.errors?.totalDancersFootwearAllowance
																								?.message && (
																								<FormHelperText
																									data-cy='errorHelper-job-title-name'
																									error={true}
																								>
																									{
																										contractForm.formState?.errors
																											?.totalDancersFootwearAllowance.message
																									}
																								</FormHelperText>
																							)}
																						</FormControl>
																					</div>
																				</ul>
																			</td>
																		</tr>
																	</table>
																</td>
																<td width={'30%'} style={{ padding: '0' }} valign='top'>
																	<table
																		className={styles.contractFormsInner}
																		width={'100%'}
																		border={0}
																		cellPadding={0}
																		cellSpacing={0}
																		style={{ border: '#515151 solid 1.5px' }}
																	>
																		<tr>
																			<td>
																				<p>
																					<b>Check if Applicable</b>
																				</p>
																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='isSeasonalCommercial'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										type='checkbox'
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										disabled={props?.isPrint}
																										onChange={() => field.onChange(!field.value)}
																										style={{ marginTop: '3px' }}
																									/>
																									<span> Seasonal Commercial(s)</span></Box>
																							</div>
																						)}
																					/>
																				</div>
																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='isTestCommercial'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										type='checkbox'
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										disabled={props?.isPrint}
																										onChange={() => field.onChange(!field.value)}
																										style={{ marginTop: '3px' }}
																									/>
																									<span> Test or Test Market Commercial(s)</span></Box>
																							</div>
																						)}
																					/>
																				</div>
																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='isNonAirCommercial'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										type='checkbox'
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										disabled={props?.isPrint}
																										onChange={() => field.onChange(!field.value)}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>Non-Air Commercial(s) </span></Box>
																							</div>
																						)}
																					/>
																				</div>
																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='isTraditionalDigitalCommercial'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										type='checkbox'
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										disabled={props?.isPrint}
																										onChange={() => field.onChange(!field.value)}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>
																Traditional Digital Commercial(s) (no
																Streaming/Linear){' '}
																									</span></Box>
																							</div>
																						)}
																					/>
																				</div>
																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='isTheatricalExhibition'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										type='checkbox'
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										disabled={props?.isPrint}
																										onChange={() => field.onChange(!field.value)}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>
																Theatrical/Industrial Exhibition (no Streaming/Linear){' '}
																									</span></Box>
																							</div>
																						)}
																					/>
																				</div>
																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='isTranslationServicesForLanguage'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										type='checkbox'
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										disabled={props?.isPrint}
																										onChange={() => field.onChange(!field.value)}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>Foreign Language Translation Services </span></Box>
																							</div>
																						)}
																					/>
																				</div>
																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='isOtherCommercial'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										type='checkbox'
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										disabled={props?.isPrint}
																										onChange={() => field.onChange(!field.value)}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>Other: </span></Box>
																							</div>
																						)}
																					/>
																				</div>
																				<div>
																					{
																						props?.isPrint ? (
																							<p className={styles.descriptionPrintText}>{contractForm.watch('otherCommercial')}</p>
																						) : (<> 
																							<Controller
																								control={contractForm.control}
																								name='otherCommercial'
																								defaultValue=''
																								render={({ field }) => (
																									<textarea 
																										rows={1}
																										{...field}
																										disabled={props?.isPrint}
																										placeholder={!props?.isPrint ? 'Other Commercial' : ''}
																									/>
																								)}
																							/>
																							{contractForm.formState.errors?.otherCommercial
																								?.message && (
																								<FormHelperText
																									data-cy='errorHelper-job-title-name'
																									error={true}
																								>
																									{
																										contractForm.formState?.errors
																											?.otherCommercial.message
																									}
																								</FormHelperText>
																							)}
																						</> )
																					}
																				</div>
																			
																			</td>
																		</tr>
																	</table>
																</td>
															</tr>
														</table>
														<br></br>
													</div>
													<div id={CONTRACT_REF_ID.MAIN_FORM_PART_2} className={styles.bgWhite} style={{padding:'20px'}}>
														<table
															className={`${styles.contractFormsInner} keep-together`}
															width={'100%'}
															border={0}
															cellPadding={0}
															cellSpacing={0}
														>
															<tr>
																<td colSpan={3}>
																	<p>
									This contract is subject to all of the terms and conditions of
									the SAG-AFTRA Commercials Contract. The standard provisions
									printed on the reverse side hereof are a part of this
									contract. If this contract provides for compensation at
									SAG-AFTRA minimum, no additions, changes or alterations may be
									made to this form other than those which are more favorable to
									Performer than herein provided. If this contract provides for
									compensation above the SAGAFTRA minimum, additions may be
									agreed to between Producer and Performer which do not conflict
									with the provisions of the SAG-AFTRA Commercials Contract,
									provided that such additional provisions are separately set
									forth under “Special Provisions” hereof and signed by
									Performer.
																	</p>
																</td>
															</tr>
															<tr>
																<td colSpan={3}>
																	<b>SPECIAL PROVISIONS</b> (including adjustments, if any, for
								Stunt Performers):
																</td>
															</tr>
														</table>
														<table
															className={`${styles.contractFormsInner} keep-together`}
															width={'100%'}
															border={0}
															cellPadding={0}
															cellSpacing={0}
															style={{ border: '#515151 solid 1.5px' }}
														>
															<tr>
																<td colSpan={3}>
																	{
																		props?.isPrint ? (
																			<p className={styles.descriptionPrintText}>{contractForm.watch('specialProvisions')}</p>
																		) : (<div className={styles.textareaSection}> 
																			<Controller
																				control={contractForm.control}
																				name='specialProvisions'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuBaseTextArea
																						className={styles.specialProvision}
																						rows={5}
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'SPECIAL PROVISIONS' : ''}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors?.specialProvisions
																				?.message && (
																				<FormHelperText
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.specialProvisions.message
																					}
																				</FormHelperText>
																			)}
																		</div> )
																	}
																
																</td>
															</tr>
															<tr>
																<td width={'60%'}>
																	<p>
																		<em>
										Performer acknowledges that he or she has read all the terms
										and<br></br>
										conditions in the Special Provisions herein and hereby
										agrees thereto
																		</em>
																	</p>
																</td>
																<td width={180}>Signature of Performer:</td>
																<td>
																	<FormControl fullWidth className={styles.tableForms}>
																		<NabuTextbox disabled/>
																	</FormControl>
																</td>
															</tr>
														</table>
														<br></br>
														<table
															className={`${styles.contractFormsInner} keep-together`}
															width={'100%'}
															border={0}
															cellPadding={0}
															cellSpacing={0}
															style={{ border: '#515151 solid 1.5px' }}
														>
															<tr>
																<td colSpan={2}>
																	<p>
									Until Performer shall otherwise direct in writing, Performer
									authorizes Producer to make all payments to which Performer
									may be entitled hereunder as follows:
																	</p>
																</td>
															</tr>
															<tr>
																<td width={'60%'} style={{ paddingRight: '25px' }}>
																	<table
																		className={styles.contractFormsInner}
																		width={'100%'}
																		border={0}
																		cellPadding={0}
																		cellSpacing={0}
																	>
																		<tr>
																			<td valign='top' width={250} rowSpan={2}>
																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='isPaymentToPerformerAtW4Address'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										type='checkbox'
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										disabled={props?.isPrint}
																										onChange={() => field.onChange(!field.value)}
																										style={{ marginTop: '3px' }}
																									/>
																									<span> To Performer at W-4 address</span></Box>
																							</div>
																						)}
																					/>
																				</div>
																				<div style={{ display: 'flex', alignItems: 'center' }}>
																					<Controller
																						control={contractForm.control}
																						name='isPaymentToPerformerCareOf'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => {
																									(!props?.isPrint) && field.onChange(!field.value);
																									contractForm.trigger('producerCompanyId');
																								}}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										type='checkbox'
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										disabled={props?.isPrint}
																										onChange={() => {
																											field.onChange(!field.value);
																											contractForm.trigger('producerCompanyId');
																										}}
																										style={{ marginTop: '3px' }}
																									/>
																									<span> To Performer c/o</span></Box>
																							</div>
																						)}
																					/>
																				</div>
																			</td>
																			<td width={50}>(name)</td>
																			<td>
																				<FormControl fullWidth className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						name='paymentNoticeAddressName'
																						render={({ field }) => (
																							<NabuTextbox
																								{...field}
																								disabled={props?.isPrint}
																								placeholder={!props?.isPrint ? 'Name' : ''}
																							/>
																						)}
																					/>
																					{contractForm.formState.errors?.paymentNoticeAddressName
																						?.message && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors
																									?.paymentNoticeAddressName.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																		</tr>
																		<tr>
																			<td>(address)</td>
																			<td>
																				<FormControl fullWidth className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						name='paymentNoticeAddress'
																						render={({ field }) => (
																							<NabuTextbox
																								{...field}
																								disabled={props?.isPrint}
																								placeholder={!props?.isPrint ? 'Address' : ''}
																							/>
																						)}
																					/>
																					{contractForm.formState.errors?.paymentNoticeAddress
																						?.message && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors
																									?.paymentNoticeAddress.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																		</tr>

																		<tr>
																			<td colSpan={4}>
																				<p>
												All notices to Producer shall be addressed as follows:
																				</p>
																			</td>
																		</tr>
																		<tr>
																			<td colSpan={2}>PRODUCER{ !props?.isPrint && (
																				<Tooltip
																					placement='right-end'
																					title={'Signatory'}
																				>
																					<HelpOutline fontSize='inherit' />
																				</Tooltip>)
																			}  (Name of Company){ !contractForm.watch('isPaymentToPerformerCareOf') && <sup></sup>}:</td>
																			<td colSpan={2}>
																				<FormControl fullWidth className={styles.tableForms}>
																					<Controller
																						key={`commercial-assetId
																						-${contractForm.watch(`commercials.${0}.assetId.value`)}
																						`}
																						control={contractForm.control}
																						name='producerCompanyId'
																						render={({ field }) => (
																							<>
																								<LookAheadSelect
																									key={`commercial-assetId
																									-${contractForm.watch(`commercials.${0}.assetId.value`)}
																									`}
																									{...field}
																									placeholder={!props?.isPrint ? 'Producer' : ''}
																									filterProperty='CompanyNames.Name'
																									APICaller={(filterModel) => getAllSignatory(contractForm.watch(`commercials.${0}.assetId.value`),filterModel)}
																									optionGenerator={companyOptionGenerator}
																									isDisabled={props?.isPrint}
																									isClearable={true}
																									onChange={(e: any) => {
																										field.onChange(e);
																										contractForm.setValue('producerPrintName', contractForm.watch('producerCompanyId')?.label || '', { shouldDirty: true });
																										contractForm.setValue('producerAddressId', null, { shouldDirty: true});
																										contractForm.setValue('producerEmail', '', { shouldDirty: true});
																										contractForm.trigger('producerAddressId');
																									}}
																									cacheOptions
																									defaultOptions
																									className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																								/>
																								{/* {!props?.isPrint && (
																									<Box sx={{mt:0.5}}>
																										<AddCompanyInline
																											title='Company'
																										/>
																									</Box>
																								)} */}
																							</>
																						)}
																					/>
																					{contractForm.formState.errors?.producerCompanyId?.message && (
																						<FormHelperText
																							error={true}
																							data-cy='errorHelper-producerCompanyId'
																						>
																							{
																								contractForm.formState?.errors
																									?.producerCompanyId.message
																							}
																						</FormHelperText>
																					)}												
																				</FormControl>
																			</td>
																		</tr>
																		<tr>
																			<td colSpan={2}>Producer Address:</td>
																			<td colSpan={2}>
																				<FormControl fullWidth className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						key={`producer-address-cr-${contractForm.watch(
																							'producerCompanyId.value'
																						)}-${contractForm.watch('producerAddressId')}`}
																						name='producerAddressId'
																						render={({ field }) => (
																							<>
																								<LookAheadSelect
																									{...field}
																									placeholder={!props?.isPrint ? 'Select Address' : ''}
																									key={`producer-address-${contractForm.watch(
																										'producerCompanyId.value'
																									)}-${contractForm.watch(
																										'producerAddressId'
																									)}`}
																									filterProperty='Line1'
																									sortField='IsPrimary'
																									sortDescending={true}
																									onOptionLoaded={(options) => {
																										if(options?.length && !contractForm.watch('producerAddressId.value')){
																											contractForm.setValue('producerAddressId', options[0], { shouldDirty: true});
																											contractForm.trigger('producerAddressId', { shouldFocus: true});
																										}
																									}}
																									APICaller={(filterModel) =>
																										getAllAddressByCompany(
																											filterModel,
																											contractForm.watch(
																												'producerCompanyId.value'
																											) || ''
																										)
																									}
																									optionGenerator={
																										producerAddressOptionGenerator
																									}
																									isDisabled={
																										!contractForm.watch(
																											'producerCompanyId.value'
																										) || props?.isPrint
																									}
																									dropDownsize={50}
																									isClearable={true}
																									isSearchText={true}
																									onChange={(e: any) => {
																										field.onChange(e);
																										contractForm.setValue('producerEmail', '', { shouldDirty: true});
																										contractForm.trigger('producerEmail');
																									}}
																									cacheOptions={false}
																									defaultOptions
																									className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																								/>
																								{ contractForm.watch('producerCompanyId.value')&& (!props.isPrint) && <div style={{float:'right'}}>
																									<Box sx = {{mt: 0.9}}>
																										<AddCompanyAddressInline
																											companyId= {contractForm.watch('producerCompanyId.value')}
																											title='ADDRESS'
																											isOpen = {addressPopOver?.isOpen && addressPopOver?.for =='producerCompany-address'}
																											event={addressPopOver?.event}
																											onAddressCreated={(d) => onProducerAddressCreated(d)}
																											onClose={() => setAddressPopOver({isOpen: false})}
																											onPopOverOpen={(e) => setAddressPopOver({
																												isOpen: true,
																												event: e?.currentTarget,
																												for: 'producerCompany-address'
																											})}
																											isPersistent={true} />
																									</Box>
																								</div>}
																							</>
																						)}
																					/>
																					{contractForm.formState.errors?.producerAddressId?.message && (
																						<FormHelperText
																							error={true}
																							data-cy='errorHelper_producer'
																						>
																							{
																								contractForm.formState?.errors
																									?.producerAddressId.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																		</tr>
																		<tr>
																			<td colSpan={2}>Producer Email:</td>
																			<td colSpan={2}>
																				<FormControl fullWidth className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						key={`select-em-${contractForm.watch(
																							'producerAddressId.value'
																						)}-${contractForm.watch('producerEmail')}`}
																						name='producerEmail'
																						defaultValue=''
																						render={({ field }) => (
																							<>
																								<NabuSelect
																									{...field}
																									key={`select-em-${contractForm.watch(
																										'producerAddressId.value'
																									)}-${contractForm.watch('producerEmail')}`}
																									placeholder={!props?.isPrint ? 'Select Email' : ''}
																									convertValueToUpperCase={false}
																									enableEmptySelection={true}
																									disabled={props?.isPrint || !contractForm.watch('producerAddressId.value')}
																									options={getCompanyEmailOptions(contractForm.watch('producerAddressId.value'))}
																									handleDefaultSelection={() => {
																										return !contractForm.watch('producerEmail');
																									}}
																								/>
																								{
																									<Box sx={{mt:1.3}}>
																										{(contractForm.watch('producerAddressId.value') && (!props.isPrint)) &&
																										<AddEmailInline
																											formData={{ ...new EmailFormModel()}}
																											isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Producer-email-address'|| false}	
																											onSubmit={onProducerEmailInlineSubmit}
																											onClose={closeEmailInlinePopOver}
																											title='Email address'
																											onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Producer-email-address')}
																											event={emailPopOver?.event}
																										/>
																										}
																									</Box>	
																								}
																							</>
																						)}
																					/>
																					{contractForm.formState?.errors
																						?.producerEmail?.message && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors
																									?.producerEmail?.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																		</tr>
																		<tr>
																			<td colSpan={2}>Producer (Print Name):</td>
																			<td colSpan={2}>
																				<FormControl fullWidth className={styles.tableForms}>
																					<Controller
																						key={`producer-name
																						-${contractForm.watch('producerCompanyId.label')}
																						`}
																						control={contractForm.control}
																						name='producerPrintName'
																						render={({ field }) => (
																							<>
																								<NabuTextbox
																									key={`producer-name
																									-${contractForm.watch('producerCompanyId.label')}
																									`}
																									{...field}
																									disabled={props?.isPrint}
																									value={contractForm.watch('producerCompanyId.label')}
																								/>
																							</>
																						)}
																					/>
																					{contractForm.formState?.errors?.producerPrintName
																						?.message && (
																						<FormHelperText
																							error={true}
																						>
																							{
																								contractForm.formState.errors
																									?.producerPrintName?.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																		</tr>
																		<tr>
																			<td colSpan={2}>SIGNED BY PRODUCER:</td>
																			<td colSpan={2}>
																				<FormControl fullWidth className={styles.tableForms}>
																					<NabuTextbox disabled={true}/>
																				</FormControl>
																			</td>
																		</tr>
																	</table>
																</td>
																<td valign='top'>
																	<table width={'100%'}>
																		<tr>
																			<td colSpan={2}>
																				{' '}
																				<p>
																					<em>
													All notices to Performer shall be sent to the address
													designated at left for payments and, if Performer
													desires, to one other address as follows:
																					</em>
																				</p>
																			</td>
																		</tr>
																		<tr>
																			<td width={70}>Name:</td>
																			<td>
																				<FormControl fullWidth className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						name='paymentNoticeAdditionalAddressName'
																						render={({ field }) => (
																							<>
																								<NabuTextbox
																									{...field}
																									placeholder={!props?.isPrint ? 'Address Name' : ''}
																									disabled={props?.isPrint}
																								/>
																							</>
																						)}
																					/>
																					{contractForm.formState?.errors?.paymentNoticeAdditionalAddressName
																						?.message && (
																						<FormHelperText
																							error={true}
																						>
																							{
																								contractForm.formState.errors
																									?.paymentNoticeAdditionalAddressName?.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																		</tr>
																		<tr>
																			<td>Address:</td>
																			<td>
																				<FormControl fullWidth className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						name='paymentNoticeAdditionalAddress'
																						render={({ field }) => (
																							<>
																								<NabuTextbox
																									{...field}
																									placeholder={!props?.isPrint ? 'Select Address' : ''}
																									disabled={props?.isPrint}
																								/>
																							</>
																						)}
																					/>
																					{contractForm.formState?.errors?.paymentNoticeAdditionalAddress
																						?.message && (
																						<FormHelperText
																							error={true}
																						>
																							{
																								contractForm.formState.errors
																									?.paymentNoticeAdditionalAddress?.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																		</tr>
																	</table>

																	<table width={'100%'}>
																		<tr>
																			<td colSpan={2}>
																				<p>
																					<em>
													The Performer has the right to consult with his/her
													representative or SAG-AFTRA before signing this
													contract
																					</em>
																				</p>
																			</td>
																		</tr>
																		<tr>
																			<td width={170}>Performer (Print Name):</td>
																			<td>
																				<FormControl fullWidth className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						name='performerPrintName'
																						render={({ field }) => (
																							<>
																								<NabuTextbox
																									{...field}
																									disabled={props?.isPrint}
																									value={contractForm.watch('performerId.label')}
																								/>
																							</>
																						)}
																					/>
																					{contractForm.formState?.errors?.performerPrintName
																						?.message && (
																						<FormHelperText
																							data-cy='errorHelper_?.addressId'
																							error={true}
																						>
																							{
																								contractForm.formState.errors
																									?.performerPrintName?.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																		</tr>
																		<tr>
																			<td>SIGNED BY PERFORMER:</td>
																			<td>
																				<FormControl fullWidth className={styles.tableForms}>
																					<NabuTextbox disabled={true}/>
																				</FormControl>
																			</td>
																		</tr>
																	</table>
																</td>
															</tr>
														</table>
														<br></br>
														<table
															className={`${styles.contractFormsInner} keep-together`}
															width={'100%'}
															border={0}
															cellPadding={0}
															cellSpacing={0}
															style={{ border: '#515151 solid 1.5px' }}
														>
															<tbody>
																<tr>
																	<td rowSpan={2}>
																		<p>
																			<b>
																				<em>MINORS ONLY</em>
																			</b>
																			<br></br>
										Performer hereby certifies that he/she is 21 years of age or
										over (if under 21 years of age this contract must be signed
										here by a parent or guardian).
																		</p>
																	</td>
																	<td colSpan={3}>
																		<p>
										I, the undersigned, hereby state that I am the
										parent/guardian of the above named Performer and do hereby
										consent and give my permission to this agreement.
																		</p>
																	</td>
																</tr>
																<tr>
																	<td>Signature of Parent/Guardian:</td>
																	<td>
																		<FormControl fullWidth className={styles.tableForms}>
																			<NabuTextbox disabled={true}/>
																		</FormControl>
																	</td>
																	<td>
																		<div
																			style={{
																				display: 'flex',
																				alignItems: 'center',
																				gap: '10px',
																				minWidth: '130px',
																			}}
																		>
																			<FormControl fullWidth>
																				<Controller
																					control={contractForm.control}
																					name='guardian'
																					render={({ field }) => (
																						<div style={{ display: 'inline-flex', alignItems: 'center' }}>
																							<div
																								onClick={() => {
																									if (!props?.isPrint) {
																										field.onChange(
																											field.value === CONTRACT_GUARDIAN.FATHER ? '' : CONTRACT_GUARDIAN.FATHER
																										);
																									}
																								}}
																								style={{ userSelect: 'none', display: 'inline-flex' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										checked={field?.value?.toUpperCase() === CONTRACT_GUARDIAN.FATHER}
																										onChange={() =>
																											field.onChange(
																												field.value === CONTRACT_GUARDIAN.FATHER ? '' : CONTRACT_GUARDIAN.FATHER
																											)
																										}
																										type='checkbox'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>
																										<b>{CONTRACT_GUARDIAN.FATHER}</b>
																									</span>
																								</Box>
																							</div>
																							<div
																								onClick={() => {
																									if (!props?.isPrint) {
																										field.onChange(
																											field.value === CONTRACT_GUARDIAN.MOTHER ? '' : CONTRACT_GUARDIAN.MOTHER
																										);
																									}
																								}}
																								style={{ userSelect: 'none', display: 'inline-flex' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										checked={field?.value?.toUpperCase() === CONTRACT_GUARDIAN.MOTHER}
																										onChange={() =>
																											field.onChange(
																												field.value === CONTRACT_GUARDIAN.MOTHER ? '' : CONTRACT_GUARDIAN.MOTHER
																											)
																										}
																										type='checkbox'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>
																										<b>{CONTRACT_GUARDIAN.MOTHER}</b>
																									</span>
																								</Box>
																							</div>
																							<div
																								onClick={() => {
																									if (!props?.isPrint) {
																										field.onChange(
																											field.value === CONTRACT_GUARDIAN.GUARDIAN ? '' : CONTRACT_GUARDIAN.GUARDIAN
																										);
																									}
																								}}
																								style={{ userSelect: 'none', display: 'inline-flex' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										checked={field?.value?.toUpperCase() === CONTRACT_GUARDIAN.GUARDIAN}
																										onChange={() =>
																											field.onChange(
																												field.value === CONTRACT_GUARDIAN.GUARDIAN ? '' : CONTRACT_GUARDIAN.GUARDIAN
																											)
																										}
																										type='checkbox'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>
																										<b>{CONTRACT_GUARDIAN.GUARDIAN}</b>
																									</span>
																								</Box>
																							</div>
																						</div>
																					)}
																				/>
																			</FormControl>
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
												<div id={CONTRACT_REF_ID.MAIN_FORM_PART_3} className={styles.contractFormBlock} style={{padding:'20px'}}>
													<div className={styles.bgWhite}>
														<table className={`${styles.contractForms} keep-together`} width={'100%'}>
															<tbody>
																<tr>
																	<td>
																		<img src={TeamCompanies} height={70} />
																	</td>
																	<td style={{ width: '60%' }} align='center'>
																		<h4>
																			<b>EXHIBIT 1</b>
																		</h4>
																		<br></br>
																		<h3 style={{ color: '#000' }}>
										TIME SHEET & STANDARD PROVISIONS
																		</h3>{' '}
																	</td>
																	<td style={{ width: '20%' }} align='right'>
																		<b>Page 2 of 2</b>
																	</td>
																</tr>
															</tbody>
														</table>

														<br></br>
														<table
															width={'100%'}
															border={1}
															cellPadding={0}
															cellSpacing={0}
															className={`${styles.contractFormsInner} keep-together`}
														>
															<tr style={{ background: '#808080' }}>
																<th style={{ textAlign: 'center' }} colSpan={2}>
													Date
																</th>
																<th style={{ textAlign: 'center' }} colSpan={2}>
													Work Time
																	<div
																		style={{
																			display: 'flex',
																			justifyContent: 'space-around',
																		}}
																	>
																		<span>From</span> <span>To</span>{' '}
																	</div>
																</th>
																<th style={{ textAlign: 'center' }} colSpan={2}>
													Meals
																	<div
																		style={{
																			display: 'flex',
																			justifyContent: 'space-around',
																		}}
																	>
																		<span>From</span> <span>To</span>{' '}
																	</div>
																</th>
																<th style={{ textAlign: 'center' }} colSpan={2}>
							Makeup/Fitting/Test
																	<div
																		style={{
																			display: 'flex',
																			justifyContent: 'space-around',
																		}}
																	>
																		<span>From</span> <span>To</span>{' '}
																	</div>
																</th>
																<th style={{ textAlign: 'center' }} colSpan={2}>
													Travel to Location
																	<div
																		style={{
																			display: 'flex',
																			justifyContent: 'space-around',
																		}}
																	>
																		<span>From</span> <span>To</span>{' '}
																	</div>
																</th>
																<th style={{ textAlign: 'center' }} colSpan={2}>
													Travel from Location
																	<div
																		style={{
																			display: 'flex',
																			justifyContent: 'space-around',
																		}}
																	>
																		<span>From</span> <span>To</span>{' '}
																	</div>
																</th>							
															</tr>
															{workDayFieldArray.fields.map((field, index) => (
																<tr key={`work-field-${field.id}`}>
																	<td height={40} colSpan={2}>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.date`}
																				render={({ field }) => (
																					<NabuDatepicker
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'Date' : ''}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch(`workDays.${index}.date`) || '')}</p>
																			) :(<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.date`}
																				render={({ field }) => (
																					<NabuDatepicker
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'Date' : ''}
																					/>
																				)}
																			/>)}
																			{!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.date?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.workTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.workTimeStartAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.workTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.workTimeStartAt?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.workTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.workTimeEndAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.workTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.workTimeEndAt?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.mealTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.mealTimeStartAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.mealTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.mealTimeStartAt?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.mealTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.mealTimeEndAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.mealTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.mealTimeEndAt?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl className={styles.tableForms} fullWidth>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.makeUpTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.makeUpTimeStartAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.makeUpTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.makeUpTimeStartAt?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.makeUpTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.makeUpTimeEndAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.makeUpTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.makeUpTimeEndAt?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeToLocationStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeToLocationStartAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeToLocationStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.travelTimeToLocationStartAt?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeToLocationEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeToLocationEndAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeToLocationEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.travelTimeToLocationEndAt?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeFromLocationStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeFromLocationStartAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeFromLocationStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.travelTimeFromLocationStartAt?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeFromLocationEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeFromLocationEndAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeFromLocationEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.travelTimeFromLocationEndAt?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>								
																	{/* <td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.performersInitials`}
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																						placeholder={!props?.isPrint ? 'Initials' : ''}
																					/>
																				)}
																			/>
																			{!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.performersInitials?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>								 */}
																	{!props?.isPrint && (
																		<>
																			<td style={{ display: 'flex' }}>
																				{workDayFieldArray.fields.length - 1 ===
																	index && (
																					<NabuButton
																						variant='text'
																						onClick={onAddWorkDay}
																					>
																						<Add />
																					</NabuButton>
																				)}
																				{workDayFieldArray?.fields.length > 1 && (
																					<NabuButton
																						variant='text'
																						onClick={() => onRemoveWorkDay(index)}
																					>
																						<DeleteForever color='error' />
																					</NabuButton>
																				)}
																			</td>
																		</>
																	)}
																</tr>
															))}
														</table>
														<div>
																						Performer &apos;s Signature or Initials
														</div>
														<div>
															<FormControl
																fullWidth
																className={styles.tableForms}
															>
																<NabuTextbox disabled={true}/>
															</FormControl>
														</div>
														<td colSpan={2}></td>
														<br></br>
														<table className={`${styles.contractForms} keep-together`} width={'100%'}>
															<tr>
																<td colSpan={2} align='center'>
																	<h4>
																		<b>STANDARD PROVISIONS</b>{' '}
																	</h4>
																</td>
															</tr>
															<tr>
																<td colSpan={2}>
																	<p>
																		<b>1. RIGHT TO CONTRACT</b>
																		<br></br>
									Performer states that to the best of their knowledge, they
									have not authorized the use of their name, likeness or
									identifiable voice in any commercial advertising any
									competitive product or service during the term of permissible
									use of commercial(s) hereunder and that they are free to enter
									into this Contract and to grant the rights and uses as herein
									set forth.
																	</p>
																</td>
															</tr>
															<tr>
																<td colSpan={2}>
																	<p>
																		<b>2. EXCLUSIVITY</b>
																		<br></br>
									Performer states that since accepting employment in the
									commercial(s) covered by this Contract, they have not accepted
									employment in nor authorized the use of their name or likeness
									or identifiable voice in any commercial(s) advertising any
									competitive product or service and that they will not
									hereafter, during the term of permissible use of the
									commercial(s) for which they are employed hereunder, accept
									employment in or authorize the use of their name or likeness
									or identifiable voice in any commercial(s) advertising any
									competitive product or service. This paragraph shall not apply
									to off-camera solo or duo singers or to group performers
									(other than named groups) or to performers employed in
									seasonal commercials or commercials used exclusively on
									Traditional Digital or Gaming Platforms/Virtual
									Worlds/Augmented Reality/Emerging Platforms, absent Performer
									consent and payment by Producer of required fee(s) for
									exclusivity set forth in the SAG-AFTRA Commercials Contract.
																	</p>
																</td>
															</tr>
															<tr>
																<td width={'50%'} valign='top'>
																	<p>
																		<b>3. ARBITRATION</b>
																		<br></br>
									All disputes and controversies of every kind and nature
									arising out of or in connection with this Contract shall be
									subject to arbitration as provided in Section 58 of the
									SAG-AFTRA Commercials Contract.
																	</p>
																</td>
																<td width={'50%'} valign='top'>
																	<p>
																		<b>4. PRODUCER’S RIGHTS</b> <br></br>
									Performer acknowledges that Performer has no right, title or
									interest of any kind or nature whatsoever in or to the
									commercial(s). A role owned or created by Producer belongs to
									Producer and not to Performer
																	</p>
																</td>
															</tr>
															<tr>
																<td colSpan={2}>
																	<b>5. CONFIDENTIALITY CLAUSE</b>
																	<br></br>
								“Confidential Information” means trade secrets, confidential
								data, and other non-public confidential proprietary information
								(whether or not labeled as confidential) including any and all
								financial terms of and products involved in the production and
								any and all information regarding the creative elements
								(including, without limitation, scripts and storyboards) whether
								communicated orally, in written form, or electronically.
								Confidential information does not include information that was
								lawfully in Performer’s possession prior to being disclosed in
								connection with the employment of Performer, is now, or
								hereafter becomes generally known to the public, or that
								Performer rightfully obtained without restriction from a third
								party. Performer acknowledges that Performer has and will become
								aware of certain Confidential Information. Unless otherwise
								required by law, Performer agrees that, without Producer’s prior
								written approval, Performer shall hold such Confidential
								Information in the strictest confidence and that Performer will
								not disclose such Confidential Information to anyone (other than
								Performer’s representatives in the course of their duties to
								Performer, which representatives shall be bound by the same
								restrictions as set forth in this Agreement) or utilize such
								Confidential Information for Performer’s benefit or for the
								benefit of a third party. Notwithstanding the foregoing, nothing
								herein shall prohibit Performer from disclosing Confidential
								Information concerning Performer’s wages, hours, and other terms
								and conditions of employment as that term is defined under
								Section 7 of the National Labor Relations Act. For clarity,
								except as set forth above, Producer may not demand or request
								that Performer execute any non-disclosure agreement that has not
								been approved in advance and in writing by the Union. This
								provision is not intended to supersede any confidentiality
								provisions in celebrity agreements.
																</td>
															</tr>
														</table>

														<br></br>
														<table
															className={`${styles.contractFormsInner} keep-together`}
															width={'100%'}
															border={0}
															cellPadding={0}
															cellSpacing={0}
															style={{ border: '#515151 solid 1.5px' }}
														>
															<tr>
																<td>
																	<div
																		style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
																	>
																		<b>LOAN-OUT CORPORATION</b>
																		<Controller
																			control={contractForm.control}
																			name='isPerformerWorkingThoughLoc'
																			defaultValue={false}
																			render={({ field }) => (
																				<div
																					onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																					style={{ userSelect: 'none' }}
																				>
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							{...field}
																							value={field.value + ''}
																							checked={field.value}
																							onChange={(e) => field.onChange(e)}
																							type='checkbox'
																							disabled={props?.isPrint}
																							style={{ marginTop: '3px' }}
																						/>
																						<span>Performer is working through a loan-out Corporation.</span></Box>
																				</div>
																			)}
																		/>
																		<PlayArrow /> Submit W-9 if incorporated.
																	</div>
																</td>
															</tr>
														</table>
														<br></br>
														<table
															className={`${styles.contractFormsInner} keep-together`}
															width={'100%'}
															border={0}
															cellPadding={0}
															cellSpacing={0}
															style={{ border: '#515151 solid 1.5px' }}
														>
															<tr>
																<td width={120}>
																	<b>Performer’s Tel</b>
																</td>
																<td>
																	<FormControl fullWidth className={styles.tableForms}>
																		<Controller
																			control={contractForm.control}
																			key={`select-phone-ctr${contractForm.watch(
																				'performerPhone'
																			)}-${contractForm.watch(
																				'performerId.value'
																			)}-${personOptions.length}`}
																			name='performerPhone'
																			defaultValue=''
																			render={({ field }) => (
																				<>
																					<NabuSelect
																						{...field}
																						key={`select-phone-${contractForm.watch(
																							'performerPhone'
																						)}-${contractForm.watch(
																							'performerId.value'
																						)}-${personOptions.length}`}
																						placeholder={!props?.isPrint ? 'Select Phone' : ''}
																						convertValueToUpperCase={false}
																						enableEmptySelection={true}
																						disabled={
																							props?.isPrint ||
																						!contractForm.watch(
																							'performerId.value'
																						)
																						}
																						options={getPerformerPhoneNumberOptions()}
																						handleDefaultSelection={() => {
																							return !contractForm.watch('performerPhone');
																						}}
																					/>
																					{
																						<Box sx={{mt:1.3}}>
																							{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																							<AddPhoneNumberInline
																								formData={{ ...new PhoneNumberFormModel()}}
																								onSubmit={onPerformerPhoneNumberInlineSubmit}
																								isOpen={phoneNumberPopOver.isOpen && phoneNumberPopOver?.for == 'Performer-address-3'|| false}	
																								onClose={closePhoneNumberInlinePopOver}
																								title='Phone Number'
																								onPopOverOpen={(e) => openPhoneNumberInlinePopOver(e, 'Performer-address-3')}
																								event={phoneNumberPopOver?.event}	
																							/>
																							}
																						</Box>	
																					}
																				</>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.performerPhone?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.performerPhone?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td width={140}>
																	<b>Performer’s Email:</b>
																</td>
																<td>
																	<FormControl fullWidth className={styles.emaillowerCase}>
																		<Controller
																			control={contractForm.control}
																			key={`select-phone-${contractForm.watch(
																				'performerId.value'
																			)}-${contractForm.watch('performerEmail')}`}
																			name='performerEmail'
																			defaultValue=''
																			render={({ field }) => (
																				<>
																					<NabuSelect
																						{...field}
																						key={`select-phone-${contractForm.watch(
																							'performerId.value'
																						)}-${contractForm.watch('performerEmail')}`}
																						placeholder={!props?.isPrint ? 'Select Email' : ''}
																						convertValueToUpperCase={false}
																						defaultCase={true}
																						enableEmptySelection={true}
																						disabled={
																							props?.isPrint ||
																						!contractForm.watch(
																							'performerId.value'
																						)
																						}
																						options={getPerformerEmailsOptions()}
																						handleDefaultSelection={() => {
																							return !contractForm.watch('performerEmail');
																						}}
																					/>
																					{
																						<Box sx={{mt:1.3}}>
																							{
																								(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																						<AddEmailInline
																							formData={{ ...new EmailFormModel()}}
																							isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Performer-email-address'|| false}	
																							onSubmit={onPerformerEmailInlineSubmit}
																							onClose={closeEmailInlinePopOver}
																							title='Email address'
																							onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Performer-email-address')}
																							event={emailPopOver?.event}
																						/>
																							}
																						</Box>	
																					}
																				</>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.performerEmail?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.performerEmail?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
														</table>
													</div>
												</div>
											</>

											{/* Main Form End */}

											{/* W4 Short form */}
											{packetControlForm.watch('isW4shortForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW4ShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
											{/* W8 Ben Short form */}
											{packetControlForm.watch('isW8BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW8BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
									
											{/* W9 Ben Short form */}
											{packetControlForm.watch('isW9BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW9BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
									
											{/* Minor Trust form */}
											{packetControlForm.watch('isMinorTrust') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractMinorTrustForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
									
											{/* NY-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isNYTheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractNYWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
									
											{/* CA-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isCATheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractCAWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>

					{!props?.isPrint && (
						<div className={styles.contractTemplateBtn}>
							<Grid container>
								<Grid xs={12} md={6}>
									<NabuButton variant='filled' onClick={onClickSnippetBtn}>
										{!contractFormState?.isPacketOpen ? (
											<>
												<NoteAdd /> Create
											</>
										) : (
											'Close'
										)}
								&nbsp; Packet
									</NabuButton>
								</Grid>
								<Grid xs={12} md={6} dir='rtl'>
									<NabuButton
										variant='outlined'
										data-cy='cancel_btn'
										shouldHandleConfirmAction={contractForm.formState.isDirty}
										onClick={() => handleFormClose()}
									>
								Cancel
									</NabuButton>{' '}
							&nbsp;
									<NabuButton
										variant='filled'
										type='submit'
										data-cy='save_btn'
										disabled={
											!(contractForm.formState.isDirty ||
									packetControlForm.formState.isDirty) ||
									isAddingContract ||
									isEditingContract
										}
									>
										{isAddingContract || isEditingContract ? 'Saving' : 'Save'}
										<Save />
									</NabuButton>{' '}
							&nbsp;
									<NabuButton
										variant='filled' 
										onClick={contractForm.handleSubmit(handleSaveAndDuplicate)}
										disabled={
											!(contractForm.formState.isDirty ||
									packetControlForm.formState.isDirty) ||
									isAddingContract ||
									isEditingContract || (!(props?.mainForm?.watch('contractDisplayName'))&& !props?.isEdit)
										}
									>
								Save & Duplicate <CopyAll />
									</NabuButton>
							&nbsp;
								</Grid>
							</Grid>
						</div>
					)}
				</form>
			</div>
			<TalentDetailsForm handleTalentDetailsForm={(value) => handleTalentDetailsForm(value)} noOfTalentsSelected={noOfTalentsSelected} setNoOfTalentsSelected={(value) => setNoOfTalentsSelected(value)} performerCategory={SagAftraPriPerY22PerformerTypeOptions} isUnion={true}/>
		</>
	);
});

export default SagAftraSdEmpCtrComPriPerY22;
