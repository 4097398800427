import NabuButton from '@/common/components/atoms/button/button';
import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import LookAheadSelect from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import NabuDialog from '@/common/components/molecules/dialog/dialog';
import NabuTimePicker from '@/common/components/molecules/timepicker/timePicker';
import TreeLevelMenu, { ICheckedNodeProps } from '@/common/components/molecules/treelevelmenu/treeLevelMenu';
import AddAssetInline from '@/common/components/templates/pages/add/addAssetInline';
import AddCompanyAddressInline from '@/common/components/templates/pages/add/addCompanyAddressInline';
import AddCompanyInline from '@/common/components/templates/pages/add/addCompanyInline';
import AddEmailInline from '@/common/components/templates/pages/add/addEmailInline';
import { IPopOverState } from '@/common/components/templates/pages/add/addNewAsset';
import AddPersonAddressInline from '@/common/components/templates/pages/add/addPersonAddressInline';
import AddPhoneNumberInline from '@/common/components/templates/pages/add/addPhoneNumberInline';
import AddTalentAgentInline from '@/common/components/templates/pages/add/addTalentAgentInline';
import {
	AdditionalFeeSplProOptions,
	CONTRACT_REF_ID,
	ContractStatus,
	ContractStatusOptions,
	DefaultPageSize,
	EmploymentType,
	mediaMainTypeValue,
	mediaSubTypeValue,
	mediaType,
	mediaTypeOptions,
	mediaTypeValue,
} from '@/common/constants/constants';
import { OPERATORS } from '@/common/enums';
import { StatusCodes } from '@/common/enums/StatusCodes';
import useNotification from '@/common/hooks/useNotification';
import useScrollToField from '@/common/hooks/useScrollToField';
import {
	CompanyMainType,
	ICompanyAddress,
	ICompanyRecord
} from '@/common/types/company';
import {
	IBaseContract,
	ICommercialDetail,
	IContractNonUnionForm,
	IPacketControlForm,
} from '@/common/types/contract';
import { IFilterOption } from '@/common/types/filterModel';
import {
	displayAddress,
	displayOnlyEmail,
	displayPhoneNumberWithoutLabel,
	findNodeAndAncestors,
	getBaseUrl,
	getCompanyDisplayName,
	getLocaleDate,
	getLocaleDateTime,
	getNotificationText,
	getPersonFullName,
	getStateName,
	mutateContractAdditionalFormsData,
} from '@/common/utils/utils';
import { EmailFormModel, PhoneNumberFormModel } from '@/modules/company/shared/models/company';
import styles from '@/modules/contracts/components/addContracts.module.scss';
import { getAllAssetForBasicDetail } from '@/services/asset';
import {
	addNewCompanyEmail,
	createCompanyAddressPhoneNumber,
	getAllAddressByCompany,
	getAllProducts,
	getBrandsByCompany,
	getCompanyBasicDetails,
	getStaffListByCompany
} from '@/services/company';
import {
	addContractNonUnion,
	addMultipleContract,
	updateContractNonUnion,
} from '@/services/contract';
import { IAssetRecord } from '@/services/models/asset';
import { IPersonAddress, IPersonRecord } from '@/services/models/person';
import {
	GetByWithFullNameSearchForBasicDetail,
	createPersonEmail,
	createPersonPhoneNumber,
	getAgentInfo,
	getAllAddressByPerson
} from '@/services/person';
import store from '@/store';
import { updateContractFormState } from '@/store/contract/reducer';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	Add,
	CopyAll,
	NoteAdd,
	Save
} from '@mui/icons-material';
import DeleteForever from '@mui/icons-material/DeleteForever';
import {
	Autocomplete,
	Box,
	Checkbox,
	FormControl,
	FormHelperText,
	FormLabel,
	Grid,
	ListItemButton,
	ListItemText,
	TextField
} from '@mui/material';
import { Tree } from 'antd';
import { HttpStatusCode } from 'axios';
import { City, State } from 'country-state-city';
import { addDays } from 'date-fns';
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState
} from 'react';
import {
	Controller,
	UseFormReturn,
	useFieldArray,
	useForm,
} from 'react-hook-form';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { nonUnionPerformerSchema } from '../contarctFormSchema';
import ContractCAWageTheftPreventionForm from '../contractCAWageyTheftPreventionForm';
import ContractMinorTrustForm from '../contractMinorTrustForm';
import ContractNYWageTheftPreventionForm from '../contractNYWageTheftPreventionForm';
import ContractW4ShortForm from '../contractW4ShortForm';
import ContractW8BenShortForm from '../contractW8BenShortForm';
import ContractW9BenShortForm from '../contractW9BenShortForm';
import TalentDetailsForm from '../talentDetailsForm';
import { IContractFormState } from '../unionForms/standardSagAftraEmployment2022';

interface Props {
	mainForm?: UseFormReturn<IBaseContract, any, undefined>;
	onCancel?: VoidFunction;
	onSave?: CallableFunction;
	handlePrintStateOfContract?: CallableFunction;
	initialValue?: IContractNonUnionForm;
	isPrint?: boolean;
	isEdit?: boolean;
	contractId?: string;
	defaultValue?: IBaseContract,
	address?: ICompanyAddress[];
	people?: IPersonRecord[];
	assets?: IAssetRecord[];
}

// eslint-disable-next-line react/display-name
const NonUnionPerAgrmntOnCamPriTalentAgrmnt = forwardRef((props: Props, ref) => {
	const { scroll } = useScrollToField();
	const [params, setParams] = useSearchParams();
	useImperativeHandle(ref, () => ({
		handleContractNonUnionTalentAgreementFormCancel: (
			data: IContractNonUnionForm,
			statusValue: string
		) => {
			if (data) {
				const commercials = data?.commercials?.map((d) => ({
					...d,
					assetId: d?.assetId?.value,
				}));
				const workDays = data?.workDays?.filter((d) => d?.date) || [];
				const submitData = {
					...data,
					status: statusValue ? statusValue : ContractStatus.Draft,
					contractNonUnion: undefined,
					payrollVendorId: data?.payrollVendorId?.value,
					payrollVendorAddressId: data?.payrollVendorAddressId?.value,
					performerId: data?.performerId?.value,
					advertiserId: data?.advertiserId?.value,
					adAgencyId: data?.adAgencyId?.value,
					productId: data?.productId?.value,
					brandId:data?.brandId?.value,
					performerAddressId: data?.performerAddressId?.value,
					recordingCompanyId: data?.recordingCompanyId?.value,
					adAgencyAddressId: data?.adAgencyAddressId?.value,
					talentAgencyId: data?.talentAgencyId?.value,
					talentAgencyAddressId: data?.talentAgencyAddressId?.value,
					talentAgencyStaffId: data?.talentAgencyStaffId?.value,
					productionCompanyId:data?.productionCompanyId?.value,
					commercials,
					workDays
				};
				editContractDetails(submitData);
				
			}
		},
	}));
	const notification = useNotification();
	const DEFAULT_COUNTRY_CODE = 'US';

	const contractFormValues = props?.initialValue as any;

	const contractForm = useForm<IContractNonUnionForm>({
		defaultValues: !props?.initialValue
			? {
				...(props?.mainForm?.getValues() || {}),
				workDays: [{}],
				...(props?.defaultValue || {}),
				commercials: [{}],
			}
			: {...(props?.initialValue),
				performerAddressId:contractFormValues?.contractNonUnion?.performerAddressData?.id,
				performerEmailId:undefined,
				performerPhoneId:undefined,
				talentAgencyId:undefined,
				talentAgencyAddressId:undefined,
				talentAgencyEmailId:undefined,
				talentAgencyPhoneId:undefined,
				talentAgencyStaffId:undefined
			},
		resolver: yupResolver(nonUnionPerformerSchema),
		shouldFocusError: false
	});

	const packetControlForm = useForm<IPacketControlForm>({
		defaultValues: {
			isW4shortForm: !!contractForm.watch('contractW4Form.firstAndMiddleName'),
			isW8BenShort: !!contractForm.watch('contractW8BenShortForm'),
			isW9BenShort: !!contractForm.watch('contractW9BenShortForm'),
			isMinorTrust: !!contractForm.watch('contractMinorTrustForm'),
			isNYTheftPreventionForm: !!contractForm.watch(
				'contractNYTheftPreventionForm'
			),
			isCATheftPreventionForm: !!contractForm.watch(
				'contractCATheftPreventionForm'
			),
		},
	});

	const workDayFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'workDays',
	});

	const commercialFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'commercials',
	});

	const [personOptions, setPersonOptions] = useState<IPersonRecord[]>(
		props?.people || []
	);
	const [addressOptions, setAddressOptions] = useState<ICompanyAddress[]>(
		props?.address || []
	);
	const [contractFormState, setContractFormState] =
		useState<IContractFormState>({});
	const [addressPopOver, setAddressPopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	// const [companyOptions, setCompanyOptions] = useState<ICompanyRecord[]>([]);

	const AD_AGENCY_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'AD AGENCY',
		},
	];

	const ADVERTISER_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'ADVERTISER',
		},
	];

	const assetFilterOptions = () =>{
		const ASSET_FILTER: IFilterOption[] = [
			{
				isCaseSensitive: false,
				operator: OPERATORS.NOT_EQUAL,
				property: 'Title',
				value: '',
			},
			{
				isCaseSensitive: false,
				operator: OPERATORS.IN,
				property: 'Advertisers.Id',
				value: JSON.stringify([contractForm.watch('advertiserId.value')]),
			},
		];
		return ASSET_FILTER;
	};

	const VENDOR_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'VENDOR',
		},
		{
			property: 'CompanyType.SubType',
			operator: OPERATORS.IN,
			value: '["PAYROLL SERVICES"]',
			isCaseSensitive: false
		}
	];
	const PRODUCT_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.NOT_EQUAL,
			property: 'ProductName',
			value: '',
		},
	];

	const BRAND_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.NOT_EQUAL,
			property: 'BrandName',
			value: '',
		},
	];

	// helper functions
	const getPerformerFilter = (): IFilterOption[] => {
		const DEFAULT_FILTER: IFilterOption = {
			value: JSON.stringify(['TALENT']),
			property: 'PersonType',
			operator: OPERATORS.IN,
			isCaseSensitive: false,
		};
		return [DEFAULT_FILTER];
	};

	const onAddCommercial = () => {
		commercialFieldArray.append({} as ICommercialDetail);
	};

	const onRemoveCommercial = (index: number) => {
		if (commercialFieldArray.fields.length > 1) {
			commercialFieldArray.remove(index);
		}
	};

	const handlePackageNotification = (key: keyof IPacketControlForm) => {
		notification.notify({
			message: `Form ${
				packetControlForm.watch(key) ? 'Added to' : 'Removed from'
			} Packet.`,
			type: 'info',
		});
	};

	
	const onPayrollVendorAddressCreated = (createdAddress: ICompanyAddress) => {
		contractForm.setValue('payrollVendorAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const companyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		})) || [];
	};

	const handleStateOptions = (
		responseData: any[],
		type: 'PERSON' | 'ADDRESS' | 'ASSET'
	) => {
		const cb = {
			PERSON: setPersonOptions,
			ADDRESS: setAddressOptions,
			ASSET: setAssetOptions
		}[type];

		if (responseData && Array.isArray(responseData)) {
			cb((prev) => {
				const oldFilter = prev?.filter(
					(d) => !responseData?.some((r) => r?.id === d?.id)
				);
				return [...oldFilter, ...responseData];
			});
		}
	};

	const companyOptionGenerator = (responseData: ICompanyRecord[]) => {
		const address = responseData?.reduce((acc, curr) => {
			if (curr?.addresses?.length) {
				acc.push(...(curr?.addresses || []));
			}
			return acc;
		}, [] as ICompanyAddress[]);

		handleStateOptions(address, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));
	};

	// const payrollVendorOptionGenerator =()=>{
	// 	return payrollVendorData?.map((d) => ({ label:
	// 		getCompanyDisplayName(contractData?.payrollVendor?.companyNames) ||
	// 		'-',
	// 	value: contractData?.payrollVendor?.id || '',
	// 	}));

	// };

	const lookAheadAssetOptionGenerator = (responseData: IAssetRecord[]) => {
		handleStateOptions(responseData, 'ASSET');

		return responseData?.map((d) => ({
			value: d?.id || '',
			label: d?.adId || '',
		}));
	};


	const performerOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');
		return responseData?.map((d) => ({
			value: d?.id,
			label: getPersonFullName(d?.personName),
		}));
	};

	const adAgencyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const getPhoneNumberOptions = (addressId: string = '') => {
		const phoneNumbers =
			addressOptions?.find((a) => a?.id === addressId)?.phoneNumbers || [];

		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	const getPerformerEmailsOptions = () => {
		const emails =
			personOptions?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.emails || [];

		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const getCompanyEmailOptions = (addressId?: string) => {
		const emails =
			addressOptions?.find((a) => a?.id === addressId)?.emails || [];
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const getPerformerPhoneNumberOptions = () => {
		const phoneNumbers =
			personOptions?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.phoneNumbers || [];

		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	const productOptionGenerator = (responseData) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: d?.productName?.toUpperCase(),
		}));
	};

	const brandOptionGenerator = (responseData) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: d?.brandName?.toUpperCase(),
		}));
	};

	const onClickSnippetBtn = () => {
		setContractFormState((prev) => ({
			isPacketOpen: !prev?.isPacketOpen,
		}));
	};

	const [ isMediaTypeVisible, setMediaTypeVisibility] = useState<boolean>(false);
	const openMediaType = () => setMediaTypeVisibility(true);
	const closeMediaType = () =>{ 
		setMediaTypeVisibility(false);
		
	};

	const handleMediaTypeViewClick = (fullySelectedNodes: any) => {
		const selectedNodes: any = mediaType.filter(e => selectedIds.includes(e.id));
		setSelectedMediaTypeDialog({
			isOpen: true, 
			mediaType: findNodeAndAncestors(mediaTypeOptions, fullySelectedNodes.filter((item, index) => fullySelectedNodes.indexOf(item) === index)), 
			selectedNodes : selectedNodes
		});
	};

	const handleFormClose = () => {
		contractForm?.reset();
		props?.onCancel && props?.onCancel();
	};

	const { mutate: addContractDetails, isLoading: isAddingContract } =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addContractNonUnion(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const { mutate: editContractDetails, isLoading: isEditingContract } =
		useMutation({
			mutationKey: ['contract-edit'],
			mutationFn: (payload: any) =>
				updateContractNonUnion(props?.contractId || '', payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'UPDATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'UPDATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const handleContractSubmission = async (
		data: IContractNonUnionForm,
		handleMultipleContractAdd?: CallableFunction
	) => {
		if (!packetControlForm.watch('isW4shortForm')) {
			data.w4FormId = undefined;
			data.contractW4Form = undefined;
		}
		if (!packetControlForm.watch('isW8BenShort')) {
			data.w8BenShortFormId = undefined;
			data.contractW8BenShortForm = undefined;
		}
		if (!packetControlForm.watch('isW9BenShort')) {
			data.w9BenShortFormId = undefined;
			data.contractW9BenShortForm = undefined;
		}
		if (!packetControlForm.watch('isMinorTrust')) {
			data.minorTrustFormId = undefined;
			data.contractMinorTrustForm = undefined;
		}
		if (!packetControlForm.watch('isNYTheftPreventionForm')) {
			data.nYTheftPreventionFormId = undefined;
			data.contractNYTheftPreventionForm = undefined;
		}
		if (!packetControlForm.watch('isCATheftPreventionForm')) {
			data.cATheftPreventionFormId = undefined;
			data.contractCATheftPreventionForm = undefined;
		}

		try {
			const notify = (message: string) => {
				notification.notify({
					message,
					type: 'error',
				});
			};
			// NOTE: These linked form's handled separately because the payload size is huge.
			const updatedContractIds = await mutateContractAdditionalFormsData(
				data,
				data?.performerId?.value || '',
				notify
			);
			data.w4FormId = updatedContractIds?.w4FormId;
			data.w8BenShortFormId = updatedContractIds?.w8BenShortFormId;
			data.w9BenShortFormId = updatedContractIds?.w9BenShortFormId;
			data.minorTrustFormId = updatedContractIds?.minorTrustFormId;
			data.nYTheftPreventionFormId =
				updatedContractIds?.nYTheftPreventionFormId;
			data.cATheftPreventionFormId =
				updatedContractIds?.cATheftPreventionFormId;

		} catch (error) {
			notification.notify({
				message: getNotificationText('Form', 'UPDATE', true),
				type: 'error',
			});
		} finally {
			data.contractW4Form = undefined;
			data.contractW8BenShortForm = undefined;
			data.contractW9BenShortForm = undefined;
			data.contractMinorTrustForm = undefined;
			data.contractNYTheftPreventionForm = undefined;
			data.contractCATheftPreventionForm = undefined;

			const commercials = data?.commercials?.map((d) => ({
				...d,
				assetId: d?.assetId?.value,
			}));

			const mainFormValues = props?.mainForm?.getValues();
			const addMainFormValues = !props?.isEdit ? {
				contractType: mainFormValues?.contractType,
				contractDisplayName: mainFormValues?.contractDisplayName,
				unionType: mainFormValues?.unionType,
				unionAgreement: mainFormValues?.unionAgreement,
				payrollVendorAddressId: mainFormValues?.payrollVendorAddressId || '',
			} : {};

			const submitData = {
				...data,
				...addMainFormValues,
				payrollVendorAddressId: data?.payrollVendorAddressId?.value,
				contractNonUnion: undefined,
				payrollVendorId: data?.payrollVendorId?.value,
				performerId: data?.performerId?.value,
				advertiserId: data?.advertiserId?.value,
				adAgencyId: data?.adAgencyId?.value,
				productId: data?.productId?.value,
				brandId:data?.brandId?.value,
				performerAddressId: data?.performerAddressId?.value,
				recordingCompanyId: data?.recordingCompanyId?.value,
				adAgencyAddressId: data?.adAgencyAddressId?.value,
				talentAgencyId: data?.talentAgencyId?.value,
				talentAgencyAddressId: data?.talentAgencyAddressId?.value,
				talentAgencyStaffId: data?.talentAgencyStaffId?.value,
				elementBlobId:data?.elementBlobId,
				productionCompanyId:data?.productionCompanyId?.value,
				commercials,
			};

			if (!props?.isEdit) {
				addContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					},
				});
			} else {
				editContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			} 
		}
	};

	const onSubmitError = (data) => {
		scroll(data);
		notification.notify({
			message: 'Please fill all required fields',
			type: 'error',
		});
	};

	const onSubmit = (data: IContractNonUnionForm) => {
		handleContractSubmission(data);
	};

	const { mutate: addMultipleContractDetails } = useMutation({
		mutationKey: ['contract-add'],
		mutationFn: (payload: any) => addMultipleContract(payload),
		onSuccess: (res) => {
			if (res?.status === HttpStatusCode.Ok) {
				const baseURL = getBaseUrl(window.location.href);
				const contractIds: any = res?.data || [];
				const urls =
					contractIds.map((contract) => {
						return `${baseURL}/#/contracts/${contract}/contract-view?isEdit=true`;
					}) || [];

				urls.forEach((url) => {
					const a = document.createElement('a');
					a.href = url;
					a.target = '_blank';
					const evt = document.createEvent('MouseEvents');
					evt.initMouseEvent(
						'click',
						true,
						true,
						window,
						0,
						0,
						0,
						0,
						0,
						false,
						false,
						false,
						false,
						0,
						null
					);
					a.dispatchEvent(evt);
				});
				notification.notify({
					message: getNotificationText('Contract', 'CREATE'),
					type: 'success',
				});
			} else
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
		},
		onError: () => {
			notification.notify({
				message: getNotificationText('Contract', 'CREATE', true),
				type: 'error',
			});
		},
	});

	const [contractFormData, setContractFormData] =
		useState<IContractNonUnionForm>();

	const [noOfTalentsSelected, setNoOfTalentsSelected] = useState(false);

	const handleSaveAndDuplicate = (data: IContractNonUnionForm) => {
		setNoOfTalentsSelected(true);
		setContractFormData(data);
	};

	const handleTalentDetailsForm = (data: any) => {
		setNoOfTalentsSelected(false);
		const handleMultipleContractAdd = (payloadData) => {
			if(data) {
				const contractAddModels = data?.talentRecords?.map((talent, index) => {
					const submitData = {
						...payloadData,
						id: undefined,
						performerRole: talent?.role,
						talentAgencyId: talent?.agent?.value,
						performerId: talent?.talentName?.value,
						performerCategory:talent?.perfCategory.toUpperCase(),
						contractDisplayName: `${payloadData?.contractDisplayName}_copy_${index+1}`,
						compensation: talent?.compensation,
						performerAddressId:undefined,
						performerEmailId:undefined,
						performerPhoneId:undefined,
						talentAgencyAddressId:undefined,
						talentAgencyStaffId:undefined,
						talentAgencyPhoneId:undefined,
						talentAgencyEmailId:undefined
					};
					return submitData;
				});
				const multipleContractData = {
					contractAddNonUnionModels: contractAddModels,
				};
				addMultipleContractDetails(multipleContractData);
			}
		};
		contractFormData &&
			handleContractSubmission(contractFormData, handleMultipleContractAdd);
	};

	useEffect(() => {
		if (props?.isEdit && commercialFieldArray.fields.length === 0) {
			onAddCommercial();
		}
	}, [props?.isEdit]);

	const getStateOptions = () => {
		const states = State.getStatesOfCountry(DEFAULT_COUNTRY_CODE).map(
			(state) => ({ label: state.name?.toUpperCase(), value: state.isoCode })
		);
		if (!states.length) states.unshift({ label: 'NA', value: 'NA' });
		return states;
	};

	const getCityOptions = (stateCode?: string) =>
		stateCode
			? City.getCitiesOfState(DEFAULT_COUNTRY_CODE, stateCode)?.map((city) => ({
				label: city.name?.toUpperCase(),
				value: city.name?.toUpperCase(),
			})) || []
			: [];	

	//Media type selection logics
	const [selectedIds, setSelectedIds] = useState<any[]>([]);
	const [checkedNodes, setCheckedNodes] = useState<any[]>([]);
	const [allCheckedNodes, setAllCheckedNodes] = useState<any[]>([]);

	const onSaveMediaFiles = (selectedNodes: ICheckedNodeProps) => {
		const nodesSelected: any[] = selectedNodes.firstColumnData.concat(selectedNodes.secondColumnData, selectedNodes.thirdColumnData, selectedNodes.fourthColumnData, selectedNodes.fifthColumnData);
		const fullySelectedNodes = selectedNodes.firstColSelectedKeys.concat(selectedNodes.secondColSelectedKeys, selectedNodes.thirdColSelectedKeys, selectedNodes.fourthColSelectedKeys, selectedNodes.fifthColSelectedKeys);
		setCheckedNodes(fullySelectedNodes);
		const mediaTypes = mediaType?.filter(e => nodesSelected.includes(e?.id) && e.type === mediaTypeValue).map((e) => e?.label) || undefined;
		const mediaMainTypes = mediaType?.filter(e => nodesSelected.includes(e?.id) && e.type === mediaMainTypeValue).map((e) => e?.label) || undefined;
		const mediaSubTypes = mediaType?.filter(e => nodesSelected.includes(e?.id) && e.type === mediaSubTypeValue).map((e) => e?.label) || undefined;
		contractForm.setValue('mediaType', mediaTypes, {shouldDirty: true});
		mediaTypes && contractForm.clearErrors('mediaType');
		contractForm.setValue('mediaMainType', mediaMainTypes);
		contractForm.setValue('mediaSubType', mediaSubTypes);
		fullySelectedNodes && (setCheckedNodes(fullySelectedNodes), contractForm.setValue('checkedMediaTypeIds', fullySelectedNodes));
		nodesSelected && setAllCheckedNodes(nodesSelected);
		closeMediaType();
		handleMediaTypeViewClick(fullySelectedNodes);
	};

	const [selectedMediaTypeDialog, setSelectedMediaTypeDialog] = useState<{
		isOpen: boolean,
		mediaType: any,
		selectedNodes: []
	}>({
		isOpen: false,
		mediaType: [],
		selectedNodes: []
	});

	const TABLEVIEW = 'TABLEVIEW';
	const [mediaTypeView, ] = useState<string>(TABLEVIEW);

	const handleMediaTypeView = (data: any) => {
		const checkedMediaTypes = data?.checkedMediaTypeIds;
		const selectedNodes: any = mediaType?.filter(e => checkedMediaTypes?.includes(e.id));
		setSelectedMediaTypeDialog({
			isOpen: true, 
			mediaType: findNodeAndAncestors(mediaTypeOptions, checkedMediaTypes?.filter((item, index) => checkedMediaTypes?.indexOf(item) === index)), 
			selectedNodes : selectedNodes
		});
		// setCheckedNodes(data?.checkedMediaTypeIds);
	};
	
	useEffect(() => {
		if(props.isEdit){
			const selectedValues: any[] = [];
			selectedValues.push(contractForm?.watch('mediaType'));
			contractForm.watch('mediaType')?.map(e => selectedValues.push(e));
			contractForm.watch('mediaMainType')?.map(e => selectedValues.push(e));
			contractForm.watch('mediaSubType')?.map(e => selectedValues.push(e));
			const selectedValuesIds = mediaType.filter(e => selectedValues.includes(e?.label)).map(e => e?.id);
			setSelectedIds(selectedValuesIds);
			setCheckedNodes(contractForm.watch('checkedMediaTypeIds')|| []);
			setAllCheckedNodes(selectedValuesIds);
		}
		handleMediaTypeView(props?.initialValue && props?.initialValue);
	}, []);

	const [assetOptions, setAssetOptions] = useState<IAssetRecord[]>(
		props?.assets || []
	);

	useEffect(()=> {
		if(props?.isEdit && commercialFieldArray.fields.length === 0){
			onAddCommercial();
		}
	}, [props?.isEdit]);

	const getAssetNameByAssetIndex = (index: number) => {
		const assetName = contractForm.watch(`commercials.${index}.assetId`)?.value;
		return assetOptions?.find((d) => d?.id === assetName)?.title || '';
	};

	const getAssetTypeByAssetIndex = (index: number) => {
		const assetLength = contractForm.watch(`commercials.${index}.assetId`)?.value;
		return assetOptions?.find((d) => d?.id === assetLength)?.assetFile?.fileType || '';
	};

	const getAssetLengthByAssetIndex = (index: number) => {
		const assetLength = contractForm.watch(`commercials.${index}.assetId`)?.value;
		return assetOptions?.find((d) => d?.id === assetLength)?.assetFile?.durationInSeconds || '';
	};

	const onAddWorkDay = () => {
		if (workDayFieldArray.fields.length) {
			const previousRow = {
				...contractForm.watch(
					`workDays.${workDayFieldArray.fields.length - 1}`
				),
				id: undefined,
			};

			workDayFieldArray.append({
				...previousRow,
				date: previousRow?.date ? addDays(new Date(previousRow?.date || ''), 1) : '',
			});
		}
	};
	
	const onRemoveWorkDay = (index: number) => {
		if (workDayFieldArray.fields.length > 1) {
			workDayFieldArray.remove(index);
		}
	};

	const onPersonAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('performerId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('performerAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const [phoneNumberPopOver, setPhoneNumberInlinePopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const openPhoneNumberInlinePopOver = (e: React.MouseEvent<HTMLButtonElement | null>, key?: string) => {
		setPhoneNumberInlinePopOver({ isOpen: true, event: e?.currentTarget, for: key });
	};

	const closePhoneNumberInlinePopOver = () => {
		setPhoneNumberInlinePopOver({ isOpen: false });
	};

	const onPerformerPhoneNumberInlineSubmit = (formData: any) => {
		const personId = contractForm.watch('performerId.value') || '';
		createPersonPhoneNumber({ personId, phoneNumber: {...formData, isPrimary: true} }).then((res: any) => {
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Phone Number', 'CREATE'),
					type: 'success',
				});
				closePhoneNumberInlinePopOver();

				setPersonOptions(prev => (
					prev?.map(pn => (pn?.id == contractForm.watch('performerId.value') ? ({
						...pn,
						phoneNumbers: [
							{ id: res?.data?.id, ...formData },
							...(pn?.phoneNumbers || {}) as any
						]
					}) : pn))
				));	
				
				contractForm.trigger('performerId');
				contractForm.trigger('performerId.value');
				contractForm.setValue('performerPhoneId', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Phone Number.',
					type: 'error',
				});
			}
		});
	};
	
	const [companyPopoverInline, setCompanyPopoverInline] = useState<IPopOverState>({
		isOpen: false,
	});

	const [emailPopOver, setEmailInlinePopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const openEmailInlinePopOver = (e: React.MouseEvent<HTMLButtonElement | null>, key?: string) => {
		setEmailInlinePopOver({ isOpen: true, event: e?.currentTarget, for: key });
	};

	const onPerformerEmailInlineSubmit = (formData: any) => {
		const personId = contractForm.watch('performerId.value') || '';
		createPersonEmail({personId, Email: {...formData, isPrimary: true}}).then((res: any) =>{
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Email', 'CREATE'),
					type: 'success',
				});
				closeEmailInlinePopOver();

				setPersonOptions(prev => (
					prev?.map(em => (em?.id == contractForm.watch('performerId.value') ? ({
						...em,
						emails: [
							{ id: res?.data?.id, ...formData },
							...(em?.emails || {}) as any
						]
					}) : em))
				));	

				contractForm.trigger('performerId');
				contractForm.trigger('performerId.value');
				contractForm.setValue('performerEmailId', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Email Address.',
					type: 'error',
				});
			}
		});
	};

	const onAgentEmailInlineSubmit = (formData: any) => {
		const addressId = contractForm.watch('talentAgencyAddressId.value') || '';
		addNewCompanyEmail(addressId, {...formData, isPrimary: true}).then((res: any) =>{
			if(res?.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Email', 'CREATE'),
					type: 'success',
				});
				closeEmailInlinePopOver();

				setAddressOptions((prev) =>
					prev.map((ad) =>
						ad.id === contractForm.watch('talentAgencyAddressId.value')
							? {
								...ad,
								emails: [
									{ id: res?.data?.id, ...formData },
									...(ad?.emails || []),
								],
							}
							: ad
					)
				);
				contractForm.trigger('talentAgencyAddressId', { shouldFocus: true });
				contractForm.setValue('talentAgencyAddressId', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Email Address.',
					type: 'error',
				});
			}
		});
	};

	const closeEmailInlinePopOver = () => {
		setEmailInlinePopOver({ isOpen: false });
	};

	const adAgencyAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('talentAgencyId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('talentAgencyAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const onPhoneNumberInlineSubmit = (formData: any) => {
		const addressId = contractForm.watch('talentAgencyAddressId.value') || '';
		createCompanyAddressPhoneNumber({ addressId, phoneNumber: {...formData, isPrimary: true} }).then((res: any) => {
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Phone Number', 'CREATE'),
					type: 'success',
				});
				closePhoneNumberInlinePopOver();
				setAddressOptions((prev) =>
					prev.map((ad) =>
						ad.id === contractForm.watch('talentAgencyAddressId.value')
							? {
								...ad,
								phoneNumbers: [
									{ id: res?.data?.id, ...formData },
									...(ad?.phoneNumbers || {}),
								],
							}
							: ad
					)
				);
				contractForm.trigger('talentAgencyAddressId', { shouldFocus: true });
				contractForm.setValue('talentAgencyPhoneId', res.data.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Phone Number.',
					type: 'error',
				});
			}
		});
	};
	
	const getStateLabel = ()=>{
		const result= getStateName( DEFAULT_COUNTRY_CODE,contractForm?.watch('sessionState')||'')?.toUpperCase();
		return result;
	};

	const [staffOptions, setStaffOptions] = useState([] as any);
	
	useEffect(() => {
		if(contractForm.watch('talentAgencyId')){
			const getStaffOptions = (filter: any) => {
				getStaffListByCompany(filter, contractForm.watch('talentAgencyId')?.value).then((res) => {
					const options: any = res?.data?.records?.map((staff: any) => {
						return {value: staff?.id, label: getPersonFullName(staff?.personName)};
					}) ?? [];
					setStaffOptions(options);
				});
			};
			getStaffOptions({
				pageNumber: 0,
				pageSize: DefaultPageSize,
				sortDescending: true,
				sortField: 'createdAt',
			});
		}
	}, [contractForm.watch('talentAgencyId')]);

	const isMinor = () => {
		return personOptions?.find(
			(a) => a?.id === contractForm.watch('performerId.value')
		)?.isMinor;
	};

	return (
		<>
			<div className={props?.isPrint ? styles.formWrapperPreview : ''}>
				<form onSubmit={contractForm.handleSubmit(onSubmit, onSubmitError)}>
					<div className={styles.packetTemplate} id='form'>
						<Grid item container columnSpacing={3}>
							{contractFormState?.isPacketOpen && (
								<Grid item sm={12} md={4} lg={3}>
									<div className={styles.snippetSection}>
										<Grid item container columnSpacing={3}>
											<Grid item md={12}>
												<h3 className={styles.pocketTitle}>
													{' '}
												Government & Payroll forms
												</h3>
											</Grid>
										</Grid>
										<div className={styles.contractFormBlockSnippet}>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW4shortForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW4shortForm');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW4shortForm');
																		}}
																	/>{' '}
																	<span>W4- short version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW8BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW8BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW8BenShort');
																		}}
																	/>
																	<span>W8 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW9BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW9BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW9BenShort');
																		}}
																	/>
																	<span>W9 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isMinorTrust'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isMinorTrust');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isMinorTrust');
																		}}
																	/>
																	<span>Minor Trust Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isNYTheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isNYTheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isNYTheftPreventionForm'
																			);
																		}}
																	/>
																	<span>NY Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isCATheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isCATheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isCATheftPreventionForm'
																			);
																		}}
																	/>
																	<span>CA Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
										</div>
									</div>
								</Grid>
							)}
							<Grid
								item
								sm={12}
								md={4}
								lg={!contractFormState?.isPacketOpen ? 12 : 9}
							>
								<div
									className={
										!contractFormState?.isPacketOpen
											? undefined
											: styles.scrollFIle
									}
								>
									<div className={styles.contractFormBlockPacket} id='contract-form-block-packet'>
										<div className={styles.contractFormBlock}>
											{props?.isEdit && (
												<div className={styles.formFiledsBlock}>
													<Grid container item columnSpacing={3}>
														<Grid item md={6} lg={3}>
															<FormControl fullWidth>
																<FormLabel>
															Contract Name<sup>*</sup>
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='contractDisplayName'
																	render={({ field }) => (
																		<>
																			<NabuTextbox
																				{...field}
																				placeholder={
																					!props?.isPrint ? 'Contract Name' : ''
																				}
																			/>
																		</>
																	)}
																/>
																{contractForm.formState?.errors?.contractDisplayName
																	?.message && (
																	<FormHelperText error={true}>
																		{
																			contractForm.formState.errors
																				?.contractDisplayName?.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={3}>
															<FormControl fullWidth>
																<FormLabel>
															Payroll Vendor <sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='payrollVendorId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				{...field}
																				placeholder={
																					!props?.isPrint ? 'Payroll Vendor' : ''
																				}
																				filterProperty='CompanyNames.Name'
																				APICaller={getCompanyBasicDetails}
																				optionGenerator={companyOptionGenerator}
																				defaultFilter={VENDOR_FILTER}
																				isClearable={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																				}}
																				cacheOptions={false}
																				defaultOptions
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																			/>
																		</>
																	)}
																/>
																{contractForm.formState.errors?.payrollVendorId?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors
																				?.payrollVendorId.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={3}>
															<FormControl fullWidth>
																<FormLabel>
															Payroll Vendor Address
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	key={`payroll-address-${contractForm.watch(
																		'payrollVendorAddressId.value'
																	)}`}
																	name='payrollVendorAddressId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				{...field}
																				placeholder={'Select Address'}
																				key={`payroll-vendor-address-${contractForm.watch(
																					'payrollVendorAddressId.value'
																				)}-${contractForm.watch('payrollVendorId.value')}`}
																				filterProperty='Line1'
																				APICaller={(filterModel) =>
																					getAllAddressByCompany(
																						filterModel,
																						contractForm.watch('payrollVendorId.value') || ''
																					)
																				}
																				optionGenerator={
																					companyAddressOptionGenerator
																				}
																				isDisabled={!contractForm.watch('payrollVendorId.value')}
																				dropDownsize={50}
																				isClearable={true}
																				isSearchText={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																					contractForm.trigger('payrollVendorAddressId.value');
																				}}
																				cacheOptions={false}
																				defaultOptions
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																			/>
																			{contractForm.watch('payrollVendorId.value') && <div style={{float:'right'}}>
																				<Box sx={{mt:0.9}}>
																					<AddCompanyAddressInline
																						companyId={contractForm.watch('payrollVendorId.value')}
																						title='ADDRESS'
																						isOpen={addressPopOver?.isOpen}
																						event={addressPopOver?.event}
																						onAddressCreated={(d) => onPayrollVendorAddressCreated(d)}
																						onClose={() => setAddressPopOver({ isOpen: false })}
																						onPopOverOpen={(e) => setAddressPopOver({
																							isOpen: true,
																							event: e?.currentTarget,
																						})}
																						isPersistent={true} />
																				</Box>
																			</div>}
																		</>
																	
																	)}
																/>
																{contractForm.formState.errors?.payrollVendorAddressId?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors
																				?.payrollVendorAddressId.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={3}>
															<FormControl fullWidth>
																<FormLabel>
															Status
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='status'
																	render={({ field }) => (
																		<>
																			<NabuSelect
																				{...field}
																				placeholder={
																					!props?.isPrint ? 'Select Status' : ''
																				}
																				options={ContractStatusOptions || []}
																				convertValueToUpperCase={false}
																				onChange={(e) => field.onChange(e)}
																			/>
																		</>
																	)}
																/>
															</FormControl>
														</Grid>
													</Grid>
												</div>
											)}
											{/* Main Contract Form Start */}
											<>
												<div className={styles.contractFormBlock}>
													<div id={CONTRACT_REF_ID.MAIN_FORM} className={styles.bgWhite} style={{padding:'20px'}}>
														<table className={styles.contractForms} width={'100%'}>
															<tbody>
																<tr>
																	<h4>{contractForm.watch('contractId') ? `#${contractForm.watch('contractId')}` : ''}</h4>
																</tr>
																<tr>
																	<td align='center'>
																		<h2>NON-UNION PERFORMER AGREEMENT– ON CAMERA PRINCIPAL</h2>{' '}
																		<h3> NON-UNION TALENT AGREEMENT </h3>
																	</td>
																</tr>
															</tbody>
														</table>
														<hr></hr>
														<br></br>
													
														<table 	width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner} align='center' >
															<tr> 
																<td valign='middle' width={120}>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<FormLabel>Agency: <sup>*</sup> </FormLabel>
																	</FormControl>
																</td>
																<td width={200} valign='middle'>
																	<Controller
																		key={`adAgency-${contractForm.watch(
																			'adAgencyId.value'
																		)}`}
																		control={contractForm.control}
																		name='adAgencyId'
																		render={({ field }) => (
																			<>
																				<LookAheadSelect
																					data-cy='ad_agency'
																					{...field}
																					placeholder={
																						!props?.isPrint
																							? 'Ad-Agency'
																							: ''
																					}
																					filterProperty='CompanyNames.Name'
																					APICaller={getCompanyBasicDetails}
																					optionGenerator={
																						companyOptionGenerator
																					}
																					defaultFilter={AD_AGENCY_FILTER}
																					isDisabled={props?.isPrint}
																					isClearable={true}
																					onChange={(e: any) => {
																						field.onChange(e);
																						contractForm.setValue(
																							'adAgencyAddressId',
																							null,
																							{ shouldDirty: true }
																						);
																						contractForm.setValue(
																							'adAgencyPhoneId',
																							'',
																							{ shouldDirty: true }
																						);
																						contractForm.setValue(
																							'adAgencyEmailId',
																							'',
																							{ shouldDirty: true }
																						);
																					}}
																					cacheOptions={false}
																					defaultOptions
																					className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				/>
																			</>
																		)}
																	/>
																	{contractForm.formState.errors?.adAgencyId?.message && (
																		<FormHelperText
																			error={true}
																			data-cy='errorHelper_ad_agency'
																		>
																			{
																				contractForm.formState?.errors
																					?.adAgencyId.message
																			}
																		</FormHelperText>
																	)}
																</td>
																<td valign='middle' width={120}>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<FormLabel>Agency Job# <sup>*</sup> </FormLabel>
																	</FormControl>
																</td>
																<td width={200} valign='middle'>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			name='agencyJobTitle'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					data-cy='job_name_textbox'
																					placeholder='Agency Job'
																					{...field}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors
																			?.agencyJobTitle?.message && (
																			<FormHelperText
																				data-cy='errorHelper-agencyJobTitle'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors
																						?.agencyJobTitle?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td valign='middle' width={120}>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<FormLabel>PO# <sup></sup> </FormLabel>
																	</FormControl>
																</td>
																<td width={200} valign='middle'>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			name='agencyPo'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					data-cy='job_name_textbox'
																					placeholder='PO#'
																					{...field}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/>
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td>
																	<FormLabel>Advertiser <sup></sup> </FormLabel>
																</td>
																<td>
																	<FormControl fullWidth className={styles.tableForms}>
																		<Controller
																			control={contractForm.control}
																			name='advertiserId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						{...field}
																						placeholder={!props?.isPrint ? 'Advertiser' : ''}
																						filterProperty='CompanyNames.Name'
																						APICaller={getCompanyBasicDetails}
																						optionGenerator={companyOptionGenerator}
																						defaultFilter={ADVERTISER_FILTER}
																						isDisabled={props?.isPrint}
																						isClearable={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																							contractForm.resetField('productId');
																							contractForm.resetField('brandId');
																						// setJPCAuthorizer();
																						}}
																						cacheOptions
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{!props?.isPrint && (
																						<Box sx={{mt:0.5}}>
																							<AddCompanyInline
																								title='Company'
																								companyType={{ mainType: 'ADVERTISER' as CompanyMainType, subType: [] }} />
																						</Box>
																					)}
																				</>
																			)}
																		/>
																		{contractForm.formState.errors?.advertiserId?.message && (
																			<FormHelperText
																				error={true}
																				data-cy='errorHelper_ad_agency'
																			>
																				{
																					contractForm.formState?.errors?.advertiserId.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td valign='middle' width={120}>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<FormLabel>Product Name<sup></sup> </FormLabel>
																	</FormControl>
																</td>
																<td width={200} valign='middle'>
																	<FormControl fullWidth className={styles.tableForms}>
																		<Controller
																			control={contractForm.control}
																			name='productId'
																			render={({ field }) => (
																				<LookAheadSelect
																					{...field}
																					placeholder={!props?.isPrint ? 'Select Product' : ''}
																					key={`product-${contractForm.watch(
																						'advertiserId.value'
																					)}`}
																					filterProperty='ProductName'
																					APICaller={(filterModel) =>
																						getAllProducts(
																							filterModel,
																							contractForm.watch('advertiserId.value')
																						)
																					}
																					optionGenerator={productOptionGenerator}
																					isDisabled={
																						!contractForm.watch('advertiserId.value') ||
																		props?.isPrint
																					}
																					defaultFilter={PRODUCT_FILTER}
																					dropDownsize={20}
																					isClearable={true}
																					onChange={(e: any) => {
																						field.onChange(e);
																					}}
																					cacheOptions
																					defaultOptions
																					className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				/>
																			)}
																		/>
																		{contractForm.formState?.errors?.productId?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors?.productId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															
																<td valign='middle' width={60}>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<FormLabel>Brand<sup></sup> </FormLabel>
																	</FormControl>
																</td>
																<td width={200} valign='middle'>
																	<FormControl fullWidth className={styles.tableForms}>
																		<Controller
																			control={contractForm.control}
																			name='brandId'
																			render={({ field }) => (
																				<LookAheadSelect
																					{...field}
																					placeholder={!props?.isPrint ? 'Select Brand' : ''}
																					key={`brand-${contractForm.watch(
																						'advertiserId.value'
																					)}`}
																					filterProperty='BrandName'
																					APICaller={(filterModel) =>
																						getBrandsByCompany(
																							filterModel,
																							contractForm.watch('advertiserId.value')
																						)
																					}
																					optionGenerator={brandOptionGenerator}
																					isDisabled={
																						!contractForm.watch('advertiserId.value') ||
																		props?.isPrint
																					}
																					defaultFilter={BRAND_FILTER}
																					dropDownsize={20}
																					isClearable={true}
																					onChange={(e: any) => {
																						field.onChange(e);
																					}}
																					cacheOptions
																					defaultOptions
																					className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				/>
																			)}
																		/>
																		{contractForm.formState?.errors?.productId?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors?.productId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
													
															<tr>

																<td valign='middle' width={120}>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<FormLabel>Campaign Name: <sup></sup> </FormLabel>
																	
																	</FormControl>
																</td>
																<td width={200} valign='middle'>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			name='campaignName'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					data-cy='job_name_textbox'
																					placeholder='Campaign Name'
																					{...field}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/>
																	</FormControl>
																</td>
																<td valign='middle' width={120}>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<FormLabel>Campaign ID<sup></sup> </FormLabel>
																	</FormControl>
																</td>
																<td width={200} valign='middle' colSpan={3}>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			name='campaignId'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					data-cy='job_name_textbox'
																					placeholder='Campaign ID'
																					{...field}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/>
																	</FormControl>
																</td>
														
															</tr>

															<tr>
		
																<td valign='middle' width={120}>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<FormLabel>Performer Name:<sup>*</sup> </FormLabel>
																	</FormControl>
																</td>
																<td width={200} valign='middle'>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			name='performerId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						data-cy='name_select'
																						{...field}
																						placeholder={
																							!props?.isPrint
																								? 'Select Performer'
																								: ''
																						}
																						key={`performer-name-${contractForm.watch(
																							'performerId.value'
																						)}`}
																						isClearable={true}
																						filterProperty='PersonName.FirstName'
																						APICaller={(
																							filterModel,
																							searchText
																						) =>
																							GetByWithFullNameSearchForBasicDetail(
																								filterModel,
																								searchText
																							)
																						}
																						isSearchText={true}
																						isDisabled={props?.isPrint}
																						optionGenerator={
																							performerOptionGenerator
																						}
																						defaultFilter={getPerformerFilter()}
																						onChange={(e: any) => {
																							field.onChange(e);
																							contractForm.trigger(
																								'performerEmailId'
																							);
																							contractForm.setValue(
																								'performerEmailId',
																								'',
																								{ shouldDirty: true }
																							);
																							contractForm.trigger(
																								'performerPhoneId'
																							);
																							contractForm.setValue(
																								'performerPhoneId',
																								'',
																								{ shouldDirty: true }
																							);
																							contractForm.trigger(
																								'performerEmailId'
																							);
																							contractForm.trigger(
																								'performerPhoneId'
																							);
																							contractForm.setValue(
																								'printName',
																								contractForm.watch(
																									'performerId.label'
																								),
																								{ shouldDirty: true }
																							);
																							contractForm.setValue('performerAddressId',
																								null,
																								{ shouldDirty: true}
																							);
																							contractForm.setValue('talentAgencyId',
																								null,
																								{ shouldDirty: true}
																							);
																							contractForm.setValue('talentAgencyAddressId', null, { shouldDirty: true } );
																							contractForm.setValue('talentAgencyEmailId', '', { shouldDirty: true } );
																							contractForm.setValue('talentAgencyPhoneId', '', { shouldDirty: true } );
																						}}
																						cacheOptions={false}
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																				</>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.performerId?.message && (
																			<FormHelperText
																				data-cy='errorHelper_first_session_date'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors
																						?.performerId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td valign='middle' width={140}>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<FormLabel>Performer Category <sup>*</sup> </FormLabel>
																	</FormControl>
																</td>
																<td width={200} valign='middle'>
																	<FormControl
																		className={styles.tableForms}
																		style={{ width: '100%' }}
																	>
																		<Controller
																			control={contractForm.control}
																			name='performerCategory'
																			// defaultValue=''
																			render={({ field }) => (
																				<NabuSelect
																					{...field}
																					disabled={props?.isPrint}
																					options={EmploymentType?.sort().map(op => ({label: op?.toUpperCase(), value: op?.toUpperCase()}))}
																					convertValueToUpperCase={true}
																					placeholder={!props?.isPrint ? 'Performer Category' : ''}
																				/>
																			)}
																		/>
																		{contractForm.formState?.errors?.performerCategory
																			?.message && (
																			<FormHelperText
																				data-cy='errorHelper_location_city'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors
																						?.performerCategory.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																	{/* <NabuTextbox placeholder="Performer Category" />
																	{contractForm.formState.errors?.performerCategory?.message && (
																		<FormHelperText
																			error={true}
																			data-cy='errorHelper_ad_agency'
																		>
																			{
																				contractForm.formState.errors?.performerCategory?.message
																			}
																		</FormHelperText>
																	)} */}
																	{/* </FormControl> */}
																</td>
																<td valign='middle' width={120}>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<FormLabel>Role: <sup></sup> </FormLabel>
																	</FormControl>
																</td>
																<td width={200} valign='middle'>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			name='performerRole'
																			render={({ field }) => (
																				<NabuTextbox
																					data-cy='job_name_textbox'
																					placeholder='Role'
																					{...field}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/>
																	</FormControl>
																</td>
															</tr>
														</table>
	
														<br></br>				

														<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner} align='center'>
															<tr>
																<td valign='middle' colSpan={4}>
																	<div style={{display:'flex', gap:'5px'}}>
																		<FormControl>
																			{(!props?.isPrint)?<FormLabel>	Select Media Type <sup>*</sup></FormLabel> : <FormLabel>	Selected Media Type</FormLabel> }
																		</FormControl><br></br>
																		{(!props?.isPrint) && (<NabuButton onClick={openMediaType} variant='filled'>{'Select MediaType'}</NabuButton>)}
																		{contractForm.formState?.errors?.mediaType && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState.errors?.mediaType?.message
																				}
																			</FormHelperText>
																		)}
																		<NabuDialog
																			title='Select MediaType'
																			open={isMediaTypeVisible}
																			maxWidth='lg'
																			handleClose={closeMediaType}
																			fullWidth={true}
																			data-cy='remove_dialog' 
																			dialogActions={[]}
																		>						
																			<TreeLevelMenu checkedNodes={checkedNodes} selectedIds={selectedIds} allCheckedNodes={allCheckedNodes} onSelectValues={(selectedNodes) => onSaveMediaFiles(selectedNodes)} />
																		</NabuDialog>
																	</div>
																</td>
															</tr>
														</table>
														{/* <table className={styles.customTable} width={'100%'} border={0} cellPadding={0} cellSpacing={0}>
															<tr>
																<th>Media Type</th>
																<th>Media Main Type</th>
															</tr>
															{selectedMediaTypeDialog.mediaType.map((selectedNode: any, index) => (
																<tr key={index}>
																	<td width={200}>{selectedNode?.title}</td>
																	<td>{selectedNode?.children?.map((mainType: any, mainTypeTableIndex) => (
																		<table key={mainTypeTableIndex} className={styles.customTableMediaType} width={'100%'} border={0} cellPadding={0} cellSpacing={0}>
																			<tr >
																				<td width={400}>{mainType?.title}</td>
																				<td>{mainType?.children?.map((subType: any, subTypeIndex) => (
																					<table key={mainTypeTableIndex} className={styles.customTableMediaType} width={'100%'} border={0} cellPadding={0} cellSpacing={0}>
																						<tr key={subTypeIndex}>
																							<td>
																								{subType?.title}
																								{subType?.children?.length > 0 && 
																				<table className={styles.customTableMediaType} width={'100%'} border={0} cellPadding={0} cellSpacing={0}>
																					<tr>
																						<td>
																							{subType?.children?.map((nestedSubType, nestedSubTypeIndex) => (
																								<span key={nestedSubTypeIndex}>{nestedSubType?.title}{subType?.children?.length != nestedSubTypeIndex+1 ? ', ' : null}</span>
																							))}
																						</td>
																					</tr>
																				</table>
																								}
																							</td>
																						</tr>
																					</table>
																				))}</td>
																			</tr>
																		</table>
																	))}</td>
																</tr>
															))}
														</table>  */}	
														<Grid container>
															<Grid item sm={12} dir='rtl'>
																{/* <ToggleButtonGroup 
																dir="ltr"
																color="primary"
																value={mediaTypeView}
																exclusive
																aria-label="Platform"
																className={styles.trackerGridListSection}
															>
																<ToggleButton 
																	value={TABLEVIEW} 
																	onClick={() => setMediaTypeView(TABLEVIEW)} 
																	className={styles.buttonsList}
																>
																	<Tooltip title='Tabular View' arrow><TableView /></Tooltip>
																</ToggleButton>
																<ToggleButton 
																	value={TREEVIEW}
																	onClick={() => setMediaTypeView(TREEVIEW)}
																	className={styles.buttonsGrid}
																>
																	<Tooltip title='Tree View' arrow><AccountTree/></Tooltip>
																</ToggleButton>
															</ToggleButtonGroup> */}
															</Grid>
															<Grid item sm={12}>
																{mediaTypeView === TABLEVIEW ? 
																	<table className={styles.customTable} width={'100%'} border={0} cellPadding={0} cellSpacing={0}>
																		<tr>
																			<th>Media Type</th>
																			<th>Media Main Type</th>
																		</tr>
																		{selectedMediaTypeDialog.mediaType.map((selectedNode: any, index) => (
																			<tr key={index}>
																				<td width={200}>{selectedNode?.title}</td>
																				<td>{selectedNode?.children?.map((mainType: any, mainTypeTableIndex) => (
																					<table key={mainTypeTableIndex} className={styles.customTableMediaType} width={'100%'} border={0} cellPadding={0} cellSpacing={0}>
																						<tr >
																							<td width={400}>{mainType?.title}</td>
																							<td>{mainType?.children?.map((subType: any, subTypeIndex) => (
																								<table key={mainTypeTableIndex} className={styles.customTableMediaType} width={'100%'} border={0} cellPadding={0} cellSpacing={0}>
																									<tr key={subTypeIndex}>
																										<td>
																											{subType?.title}
																											{subType?.children?.length > 0 && 
																				<table className={styles.customTableMediaType} width={'100%'} border={0} cellPadding={0} cellSpacing={0}>
																					<tr>
																						<td>
																							{subType?.children?.map((nestedSubType, nestedSubTypeIndex) => (
																								<span key={nestedSubTypeIndex}>{nestedSubType?.title}{subType?.children?.length != nestedSubTypeIndex+1 ? ', ' : null}</span>
																							))}
																						</td>
																					</tr>
																				</table>
																											}
																										</td>
																									</tr>
																								</table>
																							))}</td>
																						</tr>
																					</table>
																				))}</td>
																			</tr>
																		))}
																	</table> :
																	<Tree
																		defaultCheckedKeys={[]}
																		treeData={selectedMediaTypeDialog.mediaType}
																		rootStyle={{display: 'flex'}}
																		defaultExpandedKeys={checkedNodes ? checkedNodes : []}	
																		showLine={true}	
																	>
																	</Tree>}
															</Grid>
														</Grid>
														<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner} align='center' >
															<tr>
																<td>Asset-ID<sup>*</sup></td>
																<td>Title<sup>*</sup></td>
																<td>Length</td>
																<td>Media</td>
																{!props?.isPrint && <td></td>}
															</tr>
															{commercialFieldArray.fields.map((field, index) => (
																<tr key={`commercial-${field.id}`}>
																	<td width={300}>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name={`commercials.${index}.assetId`}
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							data-cy='source-asset'
																							{...field}
																							placeholder={!props?.isPrint ? 'Asset-ID' : ''}
																							filterProperty='Title'
																							isDisabled={props?.isPrint}
																							defaultFilter={assetFilterOptions()}
																							APICaller={(filterModel) => getAllAssetForBasicDetail(filterModel, false)}
																							optionGenerator={
																								lookAheadAssetOptionGenerator
																							}
																							onChange={(e: any) => field.onChange(e)}
																							isClearable={true}
																							cacheOptions
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{!props?.isPrint && (
																							<Box sx={{mt: 1}}>
																								<AddAssetInline
																									title='Asset' />
																							</Box>
																						)}
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors?.commercials
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState.errors?.commercials[
																							index
																						]?.assetId?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<NabuTextbox
																				placeholder={!props?.isPrint ? 'Title' : ''}
																				isDisabled={props?.isPrint}
																				value={getAssetNameByAssetIndex(index)}
																			/>
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<NabuTextbox
																				placeholder={!props?.isPrint ? 'Length' : ''}
																				isDisabled={props?.isPrint}
																				value={getAssetLengthByAssetIndex(index)}
																			/>
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<NabuTextbox
																				placeholder={!props?.isPrint ? 'Media Type' : ''}
																				isDisabled={props?.isPrint}
																				value={getAssetTypeByAssetIndex(index)}
																			/>
																		</FormControl>
																	</td>
																	{!props?.isPrint && (
																		<>
																			<td>
																				{commercialFieldArray.fields.length - 1 ===
																	index && (
																					<NabuButton
																						variant='text'
																						onClick={onAddCommercial}
																					>
																						<Add />
																					</NabuButton>
																				)}
																				{commercialFieldArray?.fields.length > 1 && (
																					<NabuButton
																						variant='text'
																						onClick={() => onRemoveCommercial(index)}
																					>
																						<DeleteForever color='error' />
																					</NabuButton>
																				)}
																			</td>
																		</>
																	)}
																</tr>
															))}
														</table>			
														<br></br>
														<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner} align='center' >
															<tr>
																<td width={120}>
																	<FormControl className={styles.tableForms}>
																		<FormLabel>Session Work Date: <sup>*</sup> </FormLabel>
																	</FormControl>

																</td>
																<td width={100} valign='middle'>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<Controller
																			control={contractForm.control}
																			name='sessionWorkDays'
																			render={({ field }) => (
																				<NabuDatepicker
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={!props?.isPrint ? 'Session Work Date' : ''}
																				/>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.sessionWorkDays?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.sessionWorkDays.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td width={100}>
																	<FormControl className={styles.tableForms}>
																		<FormLabel>Location Name: <sup>*</sup> </FormLabel>
																	</FormControl>
																</td>
																<td width={200} valign='middle'>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			name='location'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					data-cy='job_name_textbox'
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={!props?.isPrint ? 'Location Name' : ''}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors
																			?.location?.message && (

																			<FormHelperText
																				data-cy='errorHelper-agencyJobTitle'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors
																						?.location?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td width={60}>
																	<FormControl className={styles.tableForms}>
																		<FormLabel>State:<sup>*</sup> </FormLabel>
																	</FormControl>
																</td>
																<td width={200} valign='middle'>
																	<FormControl
																		className={styles.tableForms}
																		style={{ width: '100%' }}
																	>
																		<Controller
																			control={contractForm.control}
																			name='sessionState'
																			// defaultValue={[]}
																			render={({ field }) => (
																				<NabuSelect
																					{...field}
																					options={getStateOptions()}
																					disabled={props?.isPrint}
																					convertValueToUpperCase={false}
																					placeholder={!props?.isPrint ? 'Select State' : ''}
																					onChange={(e: any) => {
																						field.onChange(e);
																						contractForm.setValue(
																							'printState',
																							contractForm.watch(
																								'sessionState'
																							),
																							{ shouldDirty: true }
																						);
																						getStateLabel();
																					}}
																				/>
																			)}
																		/>
																		{contractForm?.formState?.errors
																			?.sessionState?.message && (
																			<FormHelperText
																				data-cy='errorHelper_location_state'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors
																						?.sessionState?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td valign='middle' width={40}>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<FormLabel>City:<sup>*</sup> </FormLabel>
																	</FormControl>
																</td>
																<td valign='middle'>
																	<FormControl
																		className={styles.tableForms}
																		style={{ width: '95%' }}
																	>
																		<Controller
																			control={contractForm.control}
																			name='sessionCity'
																			// defaultValue=''
																			render={({ field }) => (
																				<NabuSelect
																					{...field}
																					disabled={props?.isPrint}
																					options={getCityOptions(
																						contractForm.watch('sessionState')
																					)}
																					convertValueToUpperCase={true}
																					placeholder={!props?.isPrint ? 'Select City' : ''}
																				/>
																			)}
																		/>
																		{contractForm.formState?.errors?.sessionCity
																			?.message && (
																			<FormHelperText
																				data-cy='errorHelper_location_city'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors
																						?.sessionCity?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td width={120}>
																	<FormControl className={styles.tableForms}>
																		<FormLabel>Travel Date: <sup>*</sup> </FormLabel>
																	</FormControl>
																</td>
																<td width={200} valign='middle'>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<Controller
																			control={contractForm.control}
																			name='sessionTravelDate'
																			render={({ field }) => (
																				<NabuDatepicker
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={!props?.isPrint ? 'Travel Date' : ''}
																				/>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.sessionTravelDate?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.sessionTravelDate.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td width={100}>
																	<FormControl className={styles.tableForms}>
																		<FormLabel>Fitting Date: <sup>*</sup> </FormLabel>
																	</FormControl>
																</td>
																<td width={200} valign='middle' colSpan={5}>
																	<FormControl
																		className={styles.tableForms}
																	
																	>
																		<Controller
																			control={contractForm.control}
																			name='sessionFittingDate'
																			render={({ field }) => (
																				<NabuDatepicker
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={!props?.isPrint ? 'Fitting Date' : ''}
																				/>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.sessionFittingDate?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.sessionFittingDate.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
														
															</tr>
														</table>
														<br></br>
														<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner} align='center' >
															<tr style={{background:'#7c7c7c', color:'#FFF'}}>
																<td colSpan={2}>
																Date
																</td>
																<td>
																Time In
																</td>
																<td>
															Time Out
																</td>
																<td>
															Lunch In
																</td>
																<td>
															Lunch Out
																</td>
															</tr>
															{workDayFieldArray.fields.map((field, index) => (
																<tr key={`work-field-${field.id}`}>
																	<td height={40} colSpan={2}>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch(`workDays.${index}.date`) || '')}</p>
																			) :(<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.date`}
																				defaultValue=''
																				render={({ field }) => (
																					<NabuDatepicker
																						{...field}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/>)}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.workTimeStartAt`) || '','hh:mm aa')}</p>
																			) :(<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.workTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.workTimeEndAt`) || '','hh:mm aa')}</p>
																			) :(<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.workTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.mealTimeStartAt`) || '','hh:mm aa')}</p>
																			) :(<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.mealTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.mealTimeEndAt`) || '','hh:mm aa')}</p>
																			) :(<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.mealTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																		</FormControl>
																	</td>
																	{!props?.isPrint && (
																		<>
																			<td style={{ display: 'flex' }}>
																				{workDayFieldArray.fields.length - 1 ===
																					index && (
																					<NabuButton
																						variant='text'
																						onClick={onAddWorkDay}
																					>
																						<Add />
																					</NabuButton>
																				)}
																				{workDayFieldArray?.fields.length > 1 && (
																					<NabuButton
																						variant='text'
																						onClick={() => onRemoveWorkDay(index)}
																					>
																						<DeleteForever color='error' />
																					</NabuButton>
																				)}
																			</td>
																		</>
																	)}
																</tr>
															))}
														</table>
														<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner} align='center' >
															<tr style={{background:'#7c7c7c', color:'#FFF'}}>
																<td colSpan={4}>
															Compensation
																</td>
															</tr>
															<tr>
																<td style={{verticalAlign:'bottom'}} width={400}>
																	<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
																		<span>Session Fee:<sup>*</sup></span>
																	</div>
																	<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
																		<span> $ </span> <FormControl className={styles.tableForms} style={{width:'120px'}}>
																			<Controller
																				control={contractForm.control}
																				name='sessionFee'
																				render={({ field }) => (
																					<>
																						<NabuTextbox
																							data-cy='job_name_textbox'
																							placeholder='Session Fee'
																							{...field}
																							disabled={props?.isPrint}
																						/>
																					</>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.sessionFee?.message && (
																				<FormHelperText
																					data-cy='errorHelper-agencyJobTitle'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.sessionFee.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl><span> for a</span><FormControl className={styles.tableForms} style={{width:'120px'}}>
																			<Controller
																				control={contractForm.control}
																				name='sessionFeeHour'
																				render={({ field }) => (
																					<>
																						<NabuTextbox
																							data-cy='job_name_textbox'
																							placeholder='Session Fee'
																							{...field}
																							disabled={props?.isPrint}		
																						/>
																					</>
																				)}																			
																			/>
																			{contractForm.formState.errors
																				?.sessionFeeHour?.message && (
																				<FormHelperText
																					data-cy='errorHelper-agencyJobTitle'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.sessionFeeHour.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl><span>Hour Day</span>
																	</div>
																</td>
																<td>
																	<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
																		<span>Overtime Rate: <sup></sup></span>
																	</div>
																	<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
																		<span> $ </span> <FormControl className={styles.tableForms}>
																			<Controller
																				control={contractForm.control}
																				name='overTimeRate'
																				render={({ field }) => (
																					<>
																						<NabuTextbox
																							data-cy='job_name_textbox'
																							placeholder='Overtime Rate'
																							{...field}
																							disabled={props?.isPrint}
																						/>
																					</>
																				)}
																			/>
																		</FormControl><span>per hour</span>
																	</div>
																</td>
																<td>
																	<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
																		<sup></sup> <span>Fitting Fee</span>
																	</div>
																	<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
																		<FormControl fullWidth className={styles.tableForms}>
																			<Controller
																				control={contractForm.control}
																				name='fittingFee'
																				render={({ field }) => (
																					<>
																						<NabuTextbox
																							data-cy='job_name_textbox'
																							placeholder='Fitting Fee'
																							{...field}
																							disabled={props?.isPrint}
																						/>
																					</>
																				)}
																			/>
																		</FormControl>
																	</div>
																</td>
																<td>
																	<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
																		<sup></sup> <span>Agent Fee</span>
																	</div>
																	<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
																		<FormControl fullWidth className={styles.tableForms}>
																			<Controller
																				control={contractForm.control}
																				name='compensationAgentFee'
																				render={({ field }) => (
																					<>
																						<NabuTextbox
																							data-cy='job_name_textbox'
																							placeholder='Agent Fee'
																							{...field}
																							disabled={props?.isPrint}
																						/>
																					</>
																				)}
																			/>
																		</FormControl>
																	</div>
																</td>
															</tr>
															<tr>
																<td colSpan={4} valign='middle'>
																	<div style={{display:'flex', gap:'7px', alignItems:'center'}}>
																		<span>Additional Fees/Special Provisions: </span>
																		<FormControl>
																			<Controller
																				control={contractForm.control}
																				name='additionalFeeSplProvisions'
																				render={({ field }) => (
																					<>
																						<NabuSelect
																							{...field}
																							placeholder={
																								!props?.isPrint ? 'Select Additional Fee' : ''
																							}
																							options={AdditionalFeeSplProOptions || []}
																							convertValueToUpperCase={false}
																							onChange={(e) => field.onChange(e)}
																							disabled={props?.isPrint}
																						/>
																					</>
																				)}
																			/>
																		</FormControl>
																	</div>
																</td>
															</tr>
															<tr>
																<td colSpan={2} width={'50%'}>
																	<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
																		<span>	Use Fee <sup>*</sup></span>
																		<FormControl
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='useFee'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						data-cy='job_name_textbox'
																						placeholder='Use Fee'
																						{...field}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.useFee?.message && (
																				<FormHelperText
																					data-cy='errorHelper-agencyJobTitle'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.useFee.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
																<td colSpan={2}>
																	<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
																		<span>	Agent Fee <sup>*</sup></span>
																		<FormControl
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='agentFee'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						data-cy='job_name_textbox'
																						placeholder='Agent Fee'
																						{...field}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.agentFee?.message && (
																				<FormHelperText
																					data-cy='errorHelper-agencyJobTitle'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.agentFee.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
															</tr>
															<tr>
																<td valign='middle'>
																	<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
																		<span>	Term <sup>*</sup></span>
																		<FormControl
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='term'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						data-cy='job_name_textbox'
																						placeholder='Term'
																						{...field}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.term?.message && (
																				<FormHelperText
																					data-cy='errorHelper-agencyJobTitle'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.term?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
																<td style={{verticalAlign:'bottom'}} width={'300px'}>
																	<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
																		<span>	Media Use <sup>*</sup></span>
																		<FormControl
																			className={styles.tableForms}
																			style={{maxWidth:200}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='allMediaUseRightDetail'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						data-cy='job_name_textbox'
																						placeholder='Media Use'
																						{...field}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.allMediaUseRightDetail?.message && (
																				<FormHelperText
																					data-cy='errorHelper-agencyJobTitle'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.allMediaUseRightDetail?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
																<td valign='middle' colSpan={2}>
																	<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
																		<span>	Territory <sup>*</sup></span>
																		<FormControl
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='mediaTerritoryDetail'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						data-cy='job_name_textbox'
																						placeholder='Territory'
																						{...field}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.mediaTerritoryDetail?.message && (
																				<FormHelperText
																					data-cy='errorHelper-agencyJobTitle'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.mediaTerritoryDetail?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
															</tr>
														</table>

														<br></br>

														<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner} align='center' >
															<tr style={{background:'#7c7c7c', color:'#FFF'}}>
																<td>
															Exclusivity
																</td>
															</tr>
															<tr>
																<td>
																	<b>Historical Usage Rights:</b> Advertising materials may be used anywhere in the world by the Client and its Agency at sales meetings and for intra-company, research, award, publicity (including, without limitation, on Client’s Ad Agency ‘reel’ and website), file and reference purposes to be granted in perpetuity.
																</td>
															</tr>
															<tr style={{background:'#7c7c7c', color:'#FFF'}}>
																<td>
															Renewal Option Terms:
																</td>
															</tr>
															<tr>
																<td>
																	<div style={{display:'inline', gap:'5px', alignItems:'center'}}>
																		<b>Conditions:</b><span> TALENT WILL BE PAID FROM TIMECARD/CONTRACT. TALENT AND AGENT FEES WILL BE PAID THROUGH A PAYROLL VENDOR </span>
																		<FormControl className={styles.tableForms} >
																			<Controller
																				control={contractForm.control}
																				name='payrollVendorName'
																				render={({ field }) => (
																					<>
																						<input
																							data-cy='job_name_textbox'
																							placeholder='Payroll Vendor Name'
																							{...field}
																							value={contractForm.watch('payrollVendorId.label')}
																							disabled={props?.isPrint}
																						/>
																					</>
																				)}
																			/> </FormControl>
																		<span>. NO AGENT INVOICES ARE NEEDED. AGENCY OR ADVERTISER SHALL CAUSE A THIRD-PARTY PAYROLL PROVIDER TO PROCESS AND ISSUE TALENT PAYMENTS TO TALENT. ACCORDINGLY, ADVERTISER UNDERSTANDS THAT THE PAYROLL VENDOR </span>
																		<FormControl className={styles.tableForms} >
																			<Controller
																				control={contractForm.control}
																				name='payrollVendorName'
																				render={({ field }) => (
																					<>
																						<NabuTextbox
																							data-cy='job_name_textbox'
																							placeholder='Payroll Vendor Name'
																							{...field}
																							value={contractForm.watch('payrollVendorId.label')}
																							disabled={props?.isPrint}
																						/>
																					</>
																				)}
																			/> </FormControl>
																		<span>SHALL BE THE EMPLOYER OF RECORD FOR PAYROLL PURPOSES AND SHALL PROCESS ALL THE APPLICABLE STATE, FEDERAL AND LOCAL TAXES ON SUCH FEES AS NOTED ABOVE. AGENCY IS THE HIRING ENTITY.</span>
																	</div>
																</td>
															</tr>
														</table>
														<br></br>
														<hr></hr>
														<p>
															<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
																<span>The <b>Agency</b> will administer performer payments on behalf of the</span>
												
																<sup>* </sup> 
																<td width={200} valign='middle'>
																	<Controller
																		control={contractForm.control}
																		name='advertiserId'
																		render={({ field }) => (
																			<>
																				<LookAheadSelect
																					{...field}
																					placeholder={!props?.isPrint ? 'Advertiser' : ''}
																					filterProperty='CompanyNames.Name'
																					APICaller={getCompanyBasicDetails}
																					optionGenerator={
																						companyOptionGenerator
																					}
																					defaultFilter={ADVERTISER_FILTER}
																					isDisabled={props?.isPrint}
																					isClearable={true}
																					onChange={(e: any) => {
																						field.onChange(e);
																						contractForm.resetField(
																							'productId'
																						);
																					// setJPCAuthorizer();
																					}}
																					cacheOptions
																					defaultOptions
																					className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				/>
																			</>
																		)}
																	/>
																	{contractForm.formState.errors?.advertiserId?.message && (
																		<FormHelperText
																			error={true}
																			data-cy='errorHelper_ad_agency'
																		>
																			{
																				contractForm.formState?.errors
																					?.advertiserId.message
																			}
																		</FormHelperText>
																	)}
																</td>
																<span>though </span>
																<sup>* </sup> 
																<td width={200} valign='middle'>
																	<FormControl
																		className={styles.tableForms}
																		style={{ width: '100%' }}
																	>
																		<Controller
																			control={contractForm.control}
																			name='payrollVendorId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						{...field}
																						placeholder='Payroll Vendor'
																						filterProperty='CompanyNames.Name'
																						APICaller={getCompanyBasicDetails}
																						optionGenerator={companyOptionGenerator}
																						defaultFilter={VENDOR_FILTER}
																						isClearable={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																							contractForm.setValue('payrollVendorName', contractForm.watch('payrollVendorId.label'),{shouldDirty:true});
																						}}
																						cacheOptions
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{/* <Box sx={{mt:1.3}}>
																					<AddCompanyInline
																						title='Company'
																						companyType={{ mainType: 'VENDOR' as CompanyMainType, subType: [] }}
																					/>
																				</Box> */}
																				</>
																			)}
																		/>
																		{contractForm.formState.errors?.contractPayrollVendorId?.message && (
																			<FormHelperText
																				error={true}
																				data-cy='errorHelper_ad_agency'
																			>
																				{
																					contractForm.formState?.errors?.contractPayrollVendorId.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</div>
														</p>
														<p>
															<b>For good and valuable consideration, receipt and sufficiency of which is hereby acknowledged, I hereby agree as follows:</b>
														</p>
														<br></br>
														<p>
													I am appearing as a performer in advertising material being produced by Agency and Advertiser on the work schedule listed above.  I hereby agree to be so filmed, and I grant to Agency and Advertiser, and to other such persons as Agency or Advertiser may designate from time to time, the absolute right and permission to use any such material, including, without limitation, my likeness as portrayed therein, during the Term in the Territory in accordance with the terms of Permitted Use.  
														</p>
														<br></br>
														<p>
													I agree that I will not hold Agency, Advertiser, or anyone who receives permission from either of them, responsible for any liability resulting from the use of my likeness and film(s) in accordance with the terms hereof, including what might be deemed to be misrepresentation of me, my character or my person due to distortion, optical illusion or faulty reproduction which may occur in the finished product. I hereby waive any right that I may have to inspect or approve the finished product or products or the advertising copy or other matter that may be used in connection therewith.  
														</p>
														<br></br>
														<p>
													I hereby agree to not post any advertising material or behind the scenes material to my social media or any other venue which can be viewed by the public without the express written consent of Advertiser. I also agree that I shall abide by any restrictions placed by client on my use of the advertising material.  I shall not use or disclose any of the Advertiser and or Agency trade secrets or confidential information (including, without limitation, the terms of this agreement). Any advertising materials placed during the Term which remain on display or in circulation following the expiration of the Term shall not give rise to a claim against the Advertiser and or Agency, provided, however that they shall not place any additional advertising materials produced hereunder in any media (other than as permitted herein) following the expiration of the Term. 
														</p>
														<br></br>
														<p>
													I understand that I will not receive any other payments and my compensation is outlined above. I understand that all payments and notices to me shall be delivered at the address listed on this release. I also understand that it is my sole responsibility to inform Agency and Advertiser of any change of address in writing.  If a payment does not reach me due to a change of address, Agency and Advertiser will hold such payments until notified by me in writing of the new mailing address.  Notices with respect to any provisions herein, including but not limited to notices to renew, extend or exercise any rights or options herein shall be deemed accepted by me if Agency and/or Advertiser sent such notice to the address which I provided and is unable to reach me due to my failure to provide change of address notification to Agency and/or Client in writing.
														</p>
														<br></br>
														<p>
													During the Term and throughout the Territory, I will not accept employment in, nor authorize use of my name or voice in any advertising material, for a competitive product or service of Client, as defined above in the Exclusivity clause.
														</p>
														<br></br>
														<p>
															<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
													This agreement is governed by the laws of the State of <sup>*</sup><FormControl className={styles.tableForms} >
																	{/* <NabuTextbox placeholder='Enter State'/>  */}
																	<Controller
																		control={contractForm.control}
																		name='printState'
																		// defaultValue=''
																		render={({ field }) => (
																			<NabuTextbox
																				placeholder={!props?.isPrint ? 'Enter State' : ''}
																				data-cy='job_name_textbox'
																				{...field}
																				value={getStateLabel()}
																				isDisabled={props?.isPrint}
																			/>
																		)}
																	/>
																</FormControl>,  and the parties agree to that jurisdiction if a dispute arises.
															</div> 
														</p>
														<br></br>
														<p>
													I warrant and represent that this agreement does not in any way conflict with any existing commitments on my part.  Nothing herein will constitute any obligation on the licensed parties to make any use of any of the rights set forth herein.
														</p>
														<br></br>
														<p>
													I am over eighteen (18) years of age and have the right to make this agreement and am authorized to grant these rights.
														</p>
														<br></br>

														<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner} align='center' >
															<tr>
																<td>
																	<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
																		<b>Signature</b>
																		<FormControl className={styles.tableForms} >
																			<NabuTextbox placeholder='Signature'/> 
																		</FormControl>
																	</div>
																	<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
																		<b>Print Name</b>
																		<FormControl className={styles.tableForms} >
																			<NabuTextbox placeholder='Signature'/> 
																		</FormControl>
																	</div>
																</td>
																<td>
																	<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
																		<b>Date</b>
																		<FormControl className={styles.tableForms} >
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('agreementDate') || '')}</p>
																			) :(<Controller
																				control={contractForm.control}
																				name='agreementDate'
																				render={({ field }) => (
																					<NabuDatepicker
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'Date' : ''}
																					/>
																				)}
																			/> )}
																		</FormControl>
																	</div>
																</td>
															</tr>
														</table>
														<br></br>
														<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner} align='center' >
															<tr>
																<td>
																	<div>
																Performer Address
																		<FormControl className={styles.tableForms} fullWidth >
																			<Controller
																				control={contractForm.control}
																				key={`select_Address_${contractForm.watch(
																					'performerId.value'
																				)}`}
																				name='performerAddressId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							{...field}
																							placeholder={
																								!props?.isPrint
																									? 'Select Address'
																									: ''
																							}
																							key={`performer-address-${contractForm.watch(
																								'performerId.value'
																							)}`}
																							filterProperty='Line1'
																							sortField='IsPrimary'
																							sortDescending={true}
																							onOptionLoaded={(options) => {
																								if(options?.length && !contractForm.watch('performerAddressId.value')){
																									contractForm.setValue('performerAddressId', options[0], { shouldDirty: true});
																									contractForm.trigger('performerAddressId', { shouldFocus: true});																						
																								}
																							}}
																							APICaller={(filterModel) =>
																								getAllAddressByPerson(
																									filterModel,
																									contractForm.watch(
																										'performerId.value'
																									) || ''
																								)
																							}
																							optionGenerator={
																								adAgencyAddressOptionGenerator
																							}
																							dropDownsize={50}
																							isDisabled={
																								props?.isPrint ||
																						!contractForm.watch(
																							'performerId.value'
																						)
																							}
																							isClearable={true}
																							isSearchText={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																							}}
																							cacheOptions={false}
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{																							
																							<Box sx={{mt:1.3}}>
																								{(contractForm.watch('performerId.value') && (!props.isPrint)) && 
																							<AddPersonAddressInline
																								personId={contractForm.watch('performerId.value')}
																								title='ADDRESS'
																								isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'performer-address' || false}
																								event={addressPopOver?.event}
																								onAddressCreated={(d) => onPersonAddressCreated(d)}
																								onClose={() => setAddressPopOver({ isOpen: false })}
																								onPopOverOpen={(e) => setAddressPopOver({
																									isOpen: true,
																									event: e?.currentTarget,
																									for: 'performer-address'
																								})}
																								isPersistent={true} />
																								}
																							</Box>
																						}
																					</>
																				)}
																			/>
																		</FormControl>
																	</div>
																</td>
																<td>
																	<div>
																Performer Phone
																		<FormControl className={styles.tableForms} fullWidth >
																			<Controller
																				control={contractForm.control}
																				key={`select-phone-ctr${contractForm.watch(
																					'performerPhoneId'
																				)}-${contractForm.watch(
																					'performerId.value'
																				)}-${personOptions.length}`}
																				name='performerPhoneId'
																				render={({ field }) => (
																					<>
																						<NabuSelect
																							{...field}
																							key={`select-phone-${contractForm.watch(
																								'performerPhoneId'
																							)}-${contractForm.watch(
																								'performerId.value'
																							)}-${personOptions.length}`}
																							placeholder={!props?.isPrint ? 'Select Phone' : ''}
																							convertValueToUpperCase={false}
																							enableEmptySelection={true}
																							disabled={
																								props?.isPrint ||
																						!contractForm.watch(
																							'performerId.value'
																						)
																							}
																							options={getPerformerPhoneNumberOptions()}
																							handleDefaultSelection={() => !contractForm.watch('performerPhoneId')}
																						/>
																						{
																							<Box>
																								{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																							<AddPhoneNumberInline
																								formData={{ ...new PhoneNumberFormModel()}}
																								onSubmit={onPerformerPhoneNumberInlineSubmit}
																								isOpen={phoneNumberPopOver.isOpen && phoneNumberPopOver?.for == 'Performer-address-3'|| false}	
																								onClose={closePhoneNumberInlinePopOver}
																								title='Phone Number'
																								onPopOverOpen={(e) => openPhoneNumberInlinePopOver(e, 'Performer-address-3')}
																								event={phoneNumberPopOver?.event}	
																							/>
																								}
																							</Box>	
																						} 
																					</>
																				)}
																			/>
																		</FormControl>
																	</div>
																</td>
																<td>
																	<div>
															Performer Email
																		<FormControl className={styles.tableForms} fullWidth >
																			<Controller
																				key={`select-email-ctr${contractForm.watch(
																					'performerEmailId'
																				)}-${contractForm.watch(
																					'performerId.value'
																				)}-${personOptions.length}`}
																				control={contractForm.control}
																				name='performerEmailId'
																				defaultValue=''
																				render={({ field }) => (
																					<>
																						<NabuSelect
																							{...field}
																							key={`select-phone-${contractForm.watch(
																								'performerEmailId'
																							)}-${contractForm.watch(
																								'performerId.value'
																							)}`}
																							placeholder={
																								!props?.isPrint ? 'Select Email' : ''
																							}
																							convertValueToUpperCase={false}
																							enableEmptySelection={true}
																							disabled={
																								props?.isPrint ||
																					!contractForm.watch(
																						'performerId.value'
																					)
																							}
																							options={getPerformerEmailsOptions()}
																							handleDefaultSelection={() => !contractForm.watch('performerEmailId')}
																						/>
																						{
																							<Box>
																								{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																						<AddEmailInline
																							formData={{ ...new EmailFormModel()}}
																							isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Performer-email-address'|| false}	
																							onSubmit={onPerformerEmailInlineSubmit}
																							onClose={closeEmailInlinePopOver}
																							title='Email address'
																							onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Performer-email-address')}
																							event={emailPopOver?.event}
																						/>
																								}
																							</Box>	
																						}
																					</>
																				
																				)}
																			/>
																		</FormControl>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<div>
															Talent Agency
																		<FormControl className={styles.tableForms} fullWidth >
																			<Controller
																				key={`agency-cr-${contractForm.watch('performerId.value')}`}
																				control={contractForm.control}
																				name='talentAgencyId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							data-cy='ad_agency'
																							key={`agency-${contractForm.watch('performerId.value')}`}
																							{...field}
																							placeholder={
																								!props?.isPrint ? 'Ad-Agency' : ''
																							}
																							filterProperty='CompanyNames.Name'
																							APICaller={(filter) => {
																								filter.filters = filter.filters?.map((f) => f.property == 'CompanyNames.Name' ? {...f, value: f?.value?.trim() || '' } : f) || [];
																								return getAgentInfo(filter, contractForm.watch('performerId.value') || '');
																							}}
																							optionGenerator={
																								companyOptionGenerator
																							}
																							isClearable={true}
																							isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
																							onChange={(e: any) => {
																								field.onChange(e);
																								contractForm.setValue('talentAgencyAddressId', null, { shouldDirty: true } );
																								contractForm.setValue('talentAgencyEmailId', '', { shouldDirty: true } );
																								contractForm.setValue('talentAgencyPhoneId', '', { shouldDirty: true } );
																								contractForm.setValue('talentAgencyStaffId', null, { shouldDirty: true } );
																								contractForm.trigger('talentAgencyStaffId' );
																								contractForm.trigger('talentAgencyAddressId' );
																								contractForm.trigger('talentAgencyEmailId');
																								contractForm.trigger('talentAgencyPhoneId');
																							}}
																							onOptionLoaded={(options) => {
																								if(options?.length && !contractForm.watch('talentAgencyId.value')){
																									contractForm.setValue('talentAgencyId', options[0], { shouldDirty: true});
																									contractForm.trigger('talentAgencyId', { shouldFocus: true});
																								}
																							}}
																							cacheOptions
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{!props?.isPrint && contractForm.watch('performerId.value') && <div style={{float:'right'}}><AddTalentAgentInline
																							onCompanyCreated={(createdAgent) => contractForm.setValue('talentAgencyId', createdAgent, { shouldDirty: true})}
																							onClose={() => setCompanyPopoverInline({ isOpen: false})}
																							onPopOverOpen={(e) => setCompanyPopoverInline({ isOpen: true, event: e?.currentTarget, for: 'talentAgency' })}
																							event={companyPopoverInline?.event}
																							isOpen={companyPopoverInline.isOpen && companyPopoverInline.for === 'talentAgency'}
																							personId={contractForm.watch('performerId.value') || ''}
																						/></div>}
																					</>
																				)}
																			/>
																		</FormControl>
																	</div>
																</td>
																<td>
																	<div>
															Agent Name
																		<FormControl className={styles.tableForms} fullWidth >
																			<Controller
																				key={`agency-cr-${contractForm.watch('talentAgencyId.value')}`}
																				control={contractForm.control}
																				name='talentAgencyStaffId'
																				// disabled={
																				// 	!contractForm.watch(
																				// 		'talentAgencyStaffId.value'
																				// 	) || props?.isPrint
																				// }
																				render={({ field }) => (
																					<Autocomplete
																						data-cy='StaffSelect'
																						id="combo-box-demo"
																						options={staffOptions}
																						{...field}
																						onChange={(e, item :any) => {
																							contractForm.setValue('talentAgencyStaffId', item);
																						}}
																						disabled={
																							props?.isPrint ||
																				!contractForm.watch(
																					'talentAgencyId.value'
																				)
																						}
																						// onInputChange={(e: any,item) => {
																						// 	const lookAheadDefaultFilter: IFilterOption = {
																						// 		isCaseSensitive: false,
																						// 		operator: OPERATORS.CONTAINS,
																						// 		property: 'PersonName.FirstName',
																						// 		value: item,
																						// 	};
																						// 	setStaffPage({
																						// 		pageNumber: 0,
																						// 		pageSize: DefaultPageSize,
																						// 		sortDescending: true,
																						// 		sortField: 'PersonName.FirstName',
																						// 		filters: [lookAheadDefaultFilter]
																						// 	});
																						// 	item ? getStaffOptions(staffPage) : getStaffOptions({
																						// 		pageNumber: 0,
																						// 		pageSize: DefaultPageSize,
																						// 		sortDescending: true,
																						// 		sortField: 'PersonName.FirstName',
																						// 		filters: []
																						// 	});
																						// }}
																						renderInput={(params) => <TextField placeholder='Agent Name' data-cy='StaffSelectTextField' {...params} />}
																					/>
																				)}
																			/> 
																		</FormControl>
																	</div>
																</td>
																<td>
																	<div>
															Agent Address
																		<FormControl className={styles.tableForms} fullWidth >
																			<Controller
																				control={contractForm.control}
																				key={`agency-address-cr-${contractForm.watch(
																					'talentAgencyId.value'
																				)}`}
																				name='talentAgencyAddressId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							{...field}
																							placeholder={
																								!props?.isPrint
																									? 'Select Address'
																									: ''
																							}
																							key={`agency-address-${contractForm.watch(
																								'talentAgencyId.value'
																							)}`}
																							filterProperty='Line1'
																							sortField='IsPrimary'
																							sortDescending={true}
																							onOptionLoaded={(options) => {
																								if(options?.length && !contractForm.watch('talentAgencyAddressId.value')){
																									contractForm.setValue('talentAgencyAddressId', options[0], { shouldDirty: true});
																									contractForm.trigger('talentAgencyAddressId', { shouldFocus: true});																						
																								}
																							}}
																							APICaller={(filterModel) =>
																								getAllAddressByCompany(
																									filterModel,
																									contractForm.watch(
																										'talentAgencyId.value'
																									) || ''
																								)
																							}
																							optionGenerator={
																								adAgencyAddressOptionGenerator
																							}
																							isDisabled={
																								!contractForm.watch(
																									'talentAgencyId.value'
																								) || props?.isPrint
																							}
																							dropDownsize={50}
																							isClearable={true}
																							isSearchText={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																								contractForm.setValue('talentAgencyEmailId','',{ shouldDirty: true });
																								contractForm.setValue('talentAgencyPhoneId','',{ shouldDirty: true });
																								contractForm.trigger('talentAgencyEmailId');
																								contractForm.trigger('talentAgencyPhoneId');
																							}}
																							cacheOptions={false}
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{
																							<Box sx={{mt:1.3}}>
																								{(contractForm.watch('talentAgencyId.value')&& (!props.isPrint)) &&
																							<AddCompanyAddressInline
																								companyId={contractForm.watch('talentAgencyId.value')}
																								title = 'ADDRESS'
																								isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'AdAgency-address'|| false}
																								event={addressPopOver?.event}
																								onAddressCreated={(d) => adAgencyAddressCreated(d)}
																								onClose={() => setAddressPopOver({ isOpen: false})}
																								onPopOverOpen={(e) => setAddressPopOver({
																									isOpen: true,
																									event: e?.currentTarget,
																									for: 'AdAgency-address'
																								})}
																								isPersistent = {true} />
																								}
																							</Box>
																						}
																					</>
																				)}
																			/>
																		</FormControl>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<div>
															Agent Phone
																		<FormControl className={styles.tableForms} fullWidth>
																			<Controller
																				control={contractForm.control}
																				key={`select-phone-${contractForm.watch(
																					'talentAgencyPhoneId'
																				)}-${contractForm.watch(
																					'talentAgencyAddressId.value'
																				)}`}
																				name='talentAgencyPhoneId'
																				defaultValue=''
																				render={({ field }) => (
																					<>
																						<NabuSelect
																							{...field}
																							key={`select-phone-${contractForm.watch(
																								'talentAgencyPhoneId'
																							)}-${contractForm.watch(
																								'talentAgencyAddressId.value'
																							)}`}
																							placeholder={
																								!props?.isPrint ? 'Select Phone' : ''
																							}
																							convertValueToUpperCase={false}
																							enableEmptySelection={true}
																							disabled={
																								props?.isPrint ||
																					!contractForm.watch(
																						'talentAgencyAddressId.value'
																					)
																							}
																							options={getPhoneNumberOptions(
																								contractForm.watch(
																									'talentAgencyAddressId.value'
																								)
																							)}
																							handleDefaultSelection={() => !contractForm.watch('talentAgencyPhoneId')}
																						/>
																						{contractForm.watch('talentAgencyAddressId.value') &&
																		(!props?.isPrint) && (
																							<AddPhoneNumberInline
																								formData={{ ...new PhoneNumberFormModel()}}
																								onSubmit={onPhoneNumberInlineSubmit}
																								onClose={closePhoneNumberInlinePopOver}
																								title='Phone Number'
																								onPopOverOpen={(e) =>openPhoneNumberInlinePopOver(e, 'AdAgency-Adress')}
																								event={phoneNumberPopOver?.event}
																								isOpen={phoneNumberPopOver.isOpen && phoneNumberPopOver?.for == 'AdAgency-Adress' || false}																		/>
																						)}
																					</>
																				)}
																			/>
																		</FormControl>
																	</div>
																</td>
																<td>
																	<div>
																	Agent Email
																		<FormControl className={styles.tableForms} fullWidth >
																			<Controller
																				control={contractForm.control}
																				key={`select-em-${contractForm.watch(
																					'talentAgencyAddressId.value'
																				)}-${contractForm.watch('talentAgencyEmailId')}`}
																				name='talentAgencyEmailId'
																				render={({ field }) => (
																					<>
																						<NabuSelect
																							{...field}
																							key={`select-em-${contractForm.watch(
																								'talentAgencyAddressId.value'
																							)}-${contractForm.watch('talentAgencyEmailId')}`}
																							placeholder={
																								!props?.isPrint ? 'Select Email' : ''
																							}
																							convertValueToUpperCase={false}
																							enableEmptySelection={true}
																							disabled={
																								props?.isPrint ||
																					!contractForm.watch(
																						'talentAgencyAddressId.value'
																					)
																							}
																							options={getCompanyEmailOptions(
																								contractForm.watch(
																									'talentAgencyAddressId.value'
																								)
																							)}
																							handleDefaultSelection={() => !contractForm.watch('talentAgencyEmailId')}
																						/>
																						{
																							<Box sx={{mt:1.3}}>
																								{(contractForm.watch('talentAgencyAddressId.value') && (!props.isPrint)) &&
																						<AddEmailInline
																							formData={{ ...new EmailFormModel()}}
																							isOpen={emailPopOver.isOpen && emailPopOver?.for == 'agent-email-address'|| false}	
																							onSubmit={onAgentEmailInlineSubmit}
																							onClose={closeEmailInlinePopOver}
																							title='Email address'
																							onPopOverOpen={(e) => openEmailInlinePopOver(e, 'agent-email-address')}
																							event={emailPopOver?.event}
																						/>
																								}
																							</Box>	
																						}
																					</>	
																				)}
																			/> 
																		</FormControl>
																	</div>
																</td>
																<td>
														
																</td>
															</tr>
														</table>
														<br></br>
														<b>Payment Authorization: </b>
														<p>Performer authorizes payments to which Performer may be entitled hereunder, as follows:</p>
														<br></br>
														<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
															<div
																style={{
																	display: 'inline-flex',
																	alignItems: 'center',
																}}
															>
																<Controller
																	control={contractForm.control}
																	name='isPaymentAtPerformerAddress'
																	// defaultValue={true}
																	disabled={props?.isPrint}
																	render={({ field }) => (
																		<div
																			onClick={props?.isPrint ? undefined :  (() => {
																				field.onChange(!field.value);
																				(!props?.isPrint) && (contractForm.setValue('isPaymentAtAgentAddress', !contractForm.watch('isPaymentAtPerformerAddress'))) ;
																			})}
																			style={{ userSelect: 'none' }}
																		>
																			<Box display={'flex'} alignItems={'flex-start'}>
																				<input
																					{...field}
																					value={field.value + ''}
																					checked={field.value}
																					onChange={(e) => {
																						field.onChange(e);
																						contractForm.setValue('isPaymentAtAgentAddress', !contractForm.watch('isPaymentAtPerformerAddress'));
																					}}
																					type='checkbox'
																					disabled={props?.isPrint}
																					style={{ marginTop: '3px' }}
																				/>
																				<span>
																					<b>To Performer at address listed above</b>
																				</span>
																			</Box>
																		</div>
																	)}
																/>
															</div>
															<div
																style={{
																	display: 'inline-flex',
																	alignItems: 'center',
																}}
															>
																<Controller
																	control={contractForm.control}
																	name='isPaymentAtAgentAddress'
																	disabled={props?.isPrint}
																	render={({ field }) => (
																		<div
																			onClick={props?.isPrint ? undefined : () => {
																				field.onChange(!field.value);
																				(!props?.isPrint) && contractForm.setValue('isPaymentAtPerformerAddress', !contractForm.watch('isPaymentAtAgentAddress'));
																			}}
																			style={{ userSelect: 'none' }}
																		>
																			<Box display={'flex'} alignItems={'flex-start'}>
																				<input
																					{...field}
																					value={field.value + ''}
																					checked={field.value}
																					onChange={(e) => {
																						field.onChange(e);
																						contractForm.setValue('isPaymentAtPerformerAddress', !contractForm.watch('isPaymentAtAgentAddress'));
																					}}
																					type='checkbox'
																					disabled={props?.isPrint}
																					style={{marginTop: '3px' }}
																				/>
																				<span>
																					<b>To Agency at address listed above</b>
																				</span>
																			</Box>
																		</div>
																	)}
																/>
															</div>
														
														</div>
														<br></br>
													
														<b>Minor: </b>
														<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
															<span>I, as parent/legal guardian of </span>
															<FormControl className={styles.tableForms} >
																<Controller
																	control={contractForm.control}
																	name='printName'
																	disabled={true}
																	render={({ field }) => (
																		<>
																			<NabuTextbox
																				data-cy='job_name_textbox'
																				placeholder='Performer'
																				{...field}
																				value={isMinor() && field.value || ''}
																			/>
																		</>
																	)}
																/> 
															</FormControl>
															<span>, agree to the above and I sign this document to signify my agreement.</span>
														</div>

														<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner} align='center' >
															<tr>
																<td>
																	<b>Parent/Guardian Signature</b>
																	<FormControl fullWidth className={styles.tableForms} >
																		<NabuTextbox placeholder='Parent/Guardian Signature'/> 
																	</FormControl>
																	<p>
																		<b>Parent/Guardian Print Name</b>
																		<FormControl fullWidth className={styles.tableForms} >
																			<NabuTextbox placeholder='Parent/Guardian Print Name'/> 
																		</FormControl>
																	</p>
																</td>
																<td>
																	Date
																	<p>
																		<FormControl  className={styles.tableForms} >
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('signatureDate') || '')}</p>
																			) :(<Controller
																				control={contractForm.control}
																				name='signatureDate'
																				render={({ field }) => (
																					<NabuDatepicker
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'Date' : ''}
																					/>
																				)}
																			/>)}
																		</FormControl>
																	</p>
																</td>
															</tr>
															<tr>
																<td>
																	<b>Producer’s Signature</b>
																	<FormControl fullWidth className={styles.tableForms} >
																		<NabuTextbox placeholder='Producer’s Signature'/> 
																	</FormControl>
																</td>
																<td>
																Date
																	<p>
																		<FormControl  className={styles.tableForms} >
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('producerSignatureDate') || '')}</p>
																			) :(<Controller
																				control={contractForm.control}
																				name='producerSignatureDate'
																				render={({ field }) => (
																					<NabuDatepicker
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'Date' : ''}
																					/>
																				)}
																			/>)}
																		</FormControl>
																	</p>
																</td>
															</tr>
															<tr>
																<td>
																	<b>Business Affairs Mgr</b>
																	<FormControl fullWidth className={styles.tableForms} >
																		<NabuTextbox placeholder='Business Affairs Mgr'/> 
																	</FormControl>
																</td>
																<td>
																Date
																	<p>
																		<FormControl  className={styles.tableForms} >
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('businessAffairDate') || '')}</p>
																			) :(<Controller
																				control={contractForm.control}
																				name='businessAffairDate'
																				render={({ field }) => (
																					<NabuDatepicker
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'Date' : ''}
																					/>
																				)}
																			/>)}
																		</FormControl>
																	</p>
																</td>
															</tr>
														</table>
													</div>
												</div>

											</>
											{/* Main Contract Form End */}

											{/* W4 Short form */}
											{packetControlForm.watch('isW4shortForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW4ShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
											{/* W8 Ben Short form */}
											{packetControlForm.watch('isW8BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW8BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* W9 Ben Short form */}
											{packetControlForm.watch('isW9BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW9BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* Minor Trust form */}
											{packetControlForm.watch('isMinorTrust') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractMinorTrustForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* NY-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isNYTheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractNYWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* CA-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isCATheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractCAWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>

					{!props?.isPrint && (
						<div className={styles.contractTemplateBtn}>
							<Grid container>
								<Grid xs={12} md={6}>
									<NabuButton variant='filled' onClick={onClickSnippetBtn}>
										{!contractFormState?.isPacketOpen ? (
											<>
												<NoteAdd /> Create
											</>
										) : (
											'Close'
										)}
									&nbsp; Packet
									</NabuButton>
								</Grid>
								<Grid xs={12} md={6} dir='rtl'>
									<NabuButton
										variant='outlined'
										data-cy='cancel_btn'
										shouldHandleConfirmAction={contractForm.formState.isDirty}
										onClick={() => handleFormClose()}
									>
									Cancel
									</NabuButton>{' '}
								&nbsp;
									<NabuButton
										variant='filled'
										type='submit'
										data-cy='save_btn'
										disabled={
											!(
												contractForm.formState.isDirty ||
											packetControlForm.formState.isDirty
											) ||
										isAddingContract ||
										isEditingContract
										}
									>
										{isAddingContract || isEditingContract ? 'Saving' : 'Save'}
										<Save />
									</NabuButton>{' '}
								&nbsp;
									<NabuButton
										variant='filled'
										onClick={contractForm.handleSubmit(handleSaveAndDuplicate)}
										disabled={
											!(
												contractForm.formState.isDirty ||
											packetControlForm.formState.isDirty
											) ||
										isAddingContract ||
										isEditingContract
										}
									>
									Save & Duplicate <CopyAll />
									</NabuButton>
								&nbsp;
								</Grid>
							</Grid>
						</div>
					)}
				</form>
			</div>
			<TalentDetailsForm
				handleTalentDetailsForm={(value) => handleTalentDetailsForm(value)}
				noOfTalentsSelected={noOfTalentsSelected}
				setNoOfTalentsSelected={(value) => setNoOfTalentsSelected(value)}
			/>
		</>
	);
});

export default NonUnionPerAgrmntOnCamPriTalentAgrmnt;
