import NabuButton from '@/common/components/atoms/button/button';
import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import NabuDynamicInputDropdown from '@/common/components/atoms/dynamicInputDropdown/dynamicInputDropdown';
import LookAheadSelect, { ILookAheadSelectOption } from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuBaseTextArea } from '@/common/components/atoms/textArea/textArea';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import NabuTimePicker from '@/common/components/molecules/timepicker/timePicker';
import AddAssetInline from '@/common/components/templates/pages/add/addAssetInline';
import AddCompanyAddressInline from '@/common/components/templates/pages/add/addCompanyAddressInline';
import AddCompanyInline from '@/common/components/templates/pages/add/addCompanyInline';
import AddEmailInline from '@/common/components/templates/pages/add/addEmailInline';
import { IPopOverState } from '@/common/components/templates/pages/add/addNewAsset';
import AddPersonAddressInline from '@/common/components/templates/pages/add/addPersonAddressInline';
import AddPersonInline from '@/common/components/templates/pages/add/addPersonInline';
import AddTalentAgentInline from '@/common/components/templates/pages/add/addTalentAgentInline';
import { CONTRACT_REF_ID, ContractStatus, DefaultPageSize, SagAftraAudioCommercialsPerformerTypeOptions } from '@/common/constants/constants';
import { OPERATORS } from '@/common/enums';
import { StatusCodes } from '@/common/enums/StatusCodes';
import { CompanyMainType, ICompanyAddress, ICompanyRecord } from '@/common/types/company';
import {
	IBaseContract,
	ICommercialDetail,
	IContractSagAftraAudioComHTForm
} from '@/common/types/contract';
import { IFilterOption } from '@/common/types/filterModel';
import {
	displayAddress,
	displayOnlyEmail,
	getCompanyDisplayName,
	getLocaleDate,
	getLocaleDateTime,
	getNotificationText,
	getPersonFullName,
	sortEmailOrPhoneNumberForDropDown
} from '@/common/utils/utils';
import { EmailFormModel } from '@/modules/company/shared/models/company';
import styles from '@/modules/contracts/components/addContracts.module.scss';
import { getAllAssetForBasicDetail, getAllSignatory, retriveAssetProducts } from '@/services/asset';
import { addNewCompanyEmail, getAllAddressByCompany, getAllProducts, getCompanyBasicDetails } from '@/services/company';
import {
	addContractSagAftraAudioComHT,
	updateContractSagAftraAudioComHT
} from '@/services/contract';
import { IAssetRecord } from '@/services/models/asset';
import { IPersonAddress, IPersonRecord } from '@/services/models/person';
import { GetByWithFullNameSearchForBasicDetail, createPersonEmail, getAgentInfo, getAllAddressByPerson } from '@/services/person';
import store from '@/store';
import { updateContractFormState } from '@/store/contract/reducer';
import { yupResolver } from '@hookform/resolvers/yup';
import { Add, DeleteForever, HelpOutline } from '@mui/icons-material';
import {
	Box,
	FormControl, FormHelperText,
	Grid,
	Tooltip
} from '@mui/material';
import { HttpStatusCode } from 'axios';
import { City, State } from 'country-state-city';
import { addDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { Controller, UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { SagAftraAudioComHTComSchema } from '../contarctFormSchema';
import ContractMutationWrapper from '../contractMutationWrapper/contractMutationWrapper';
import useContractMutation from '../contractMutationWrapper/useContractMutation';
import LocationFreeTextSelect from './locationFreeTextSelect';
import { IContractFormState } from './standardSagAftraEmployment2022';
interface Props {
	mainForm?: UseFormReturn<IBaseContract, any, undefined>;
	onCancel?: VoidFunction;
	onSave?: CallableFunction;
	handlePrintStateOfContract?: CallableFunction;
	defaultValue?: IBaseContract,
	initialValue?: IContractSagAftraAudioComHTForm;
	isPrint?: boolean;
	isEdit?: boolean;
	contractId?: string;
	address?: ICompanyAddress[];
	people?: IPersonRecord[];
	assets?: IAssetRecord[];
}

const SagAftraForAudioCommercialsHT = (props: Props) => {
	const { 
		handlePreContractSubmissionTasks, 
		addMultipleContractDetails, 
		notification, 
		setPacketFormState, 
		params, 
		setParams, 
		onSubmitError ,
		DEFAULT_COUNTRY_CODE, 
	} = useContractMutation<IContractSagAftraAudioComHTForm>();


	useEffect(() => {
		if(props?.defaultValue?.advertiserId)
			contractForm.setValue('advertiserId', props?.defaultValue?.advertiserId, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.advertiserId]);

	const contractForm = useForm<IContractSagAftraAudioComHTForm>({
		defaultValues: !props?.initialValue
			? {
				...(props?.mainForm?.getValues() || {}),
				...(props?.defaultValue || {}),
				workDays: [{}],
				commercials: [{}],
			}
			: {
				...(props?.initialValue || {}),
				commercials: props?.initialValue?.commercials?.length ? props?.initialValue?.commercials : [{}],
				workDays: props?.initialValue?.workDays?.length ? props?.initialValue?.workDays : [{}],
			},
		resolver: yupResolver(SagAftraAudioComHTComSchema),
		shouldFocusError: false
	});

	const [companyOptions, setCompanyOptions] = useState<ICompanyRecord[]>([]);
	const [personOptions, setPersonOptions] = useState<IPersonRecord[]>(
		props?.people || []
	);
	const [assetOptions, setAssetOptions] = useState<IAssetRecord[]>(
		props?.assets || []
	);
	const [addressOptions, setAddressOptions] = useState<ICompanyAddress[]>(
		props?.address || []
	);
	const [contractFormState, setContractFormState] =
		useState<IContractFormState>({});	
	const [addressPopOver, setAddressPopOver] = useState<IPopOverState>({
		isOpen: false,
	});
	const [companyPopoverInline, setCompanyPopoverInline] = useState<IPopOverState>({
		isOpen: false,
	});
	
	const [emailPopOver, setEmailInlinePopOver] = useState<IPopOverState>({
		isOpen: false,
	});


	const workDayFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'workDays',
	});

	const commercialFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'commercials',
	});

	const COMPENSATION_OPTIONS = [ 'SCALE', 'DOUBLE SCALE' ];

	const AD_AGENCY_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'AD AGENCY',
		},
	];

	const ADVERTISER_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'ADVERTISER',
		},
	];

	const PRODUCT_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.NOT_EQUAL,
			property: 'ProductName',
			value: '',
		},
	];

	// helper functions
	const getCommercialFilters = () => {
		const assetFilter: IFilterOption[] = [
			{
				isCaseSensitive: false,
				operator: OPERATORS.NOT_EQUAL,
				property: 'Title',
				value: '',
			},
			{
				isCaseSensitive: false,
				operator: OPERATORS.IN,
				property: 'Advertisers.Id',
				value: JSON.stringify([contractForm.watch('advertiserId.value')]),
			},
		];
		return assetFilter;
	};

	const getPerformerFilter = (): IFilterOption[] => {
		const DEFAULT_FILTER: IFilterOption = {
			value: JSON.stringify(['TALENT']),
			property: 'PersonType',
			operator: OPERATORS.IN,
			isCaseSensitive: false,
		};
		return [DEFAULT_FILTER];
	};

	const onAddWorkDay = () => {
		if (workDayFieldArray.fields.length) {
			const previousRow = {
				...contractForm.watch(
					`workDays.${workDayFieldArray.fields.length - 1}`
				),
				id: undefined,
			};

			workDayFieldArray.append({
				...previousRow,
				date: previousRow?.date ? addDays(new Date(previousRow?.date || ''), 1)?.toISOString() : '',
			});
		}
	};

	const onRemoveWorkDay = (index: number) => {
		if (workDayFieldArray.fields.length > 1) {
			workDayFieldArray.remove(index);
		}
	};

	const onAddCommercial = () => {
		commercialFieldArray.append({} as ICommercialDetail);
	};
	
	const onAddressChange = (addressId: string, field: 'performer' | 'adAgency' | 'performerAdAgency' | 'producer') => {
		const address = addressOptions?.find( ad => ad?.id === addressId);
		if(address) {
			if(field !== 'adAgency')
				contractForm.setValue(`${field}Zip`, address?.zipCode, {shouldDirty: true});
			contractForm.setValue(`${field}State`, address?.state, {shouldDirty: true});
			contractForm.setValue(`${field}City`, address?.city?.toUpperCase(), {shouldDirty: true});
		} else {
			contractForm.setValue(`${field}State`, '', {shouldDirty: true});
			contractForm.setValue(`${field}City`, '', {shouldDirty: true});
			if(field !== 'adAgency')
				contractForm.setValue(`${field}Zip`, '', {shouldDirty: true});
		}
	};

	const handleStateOptions = (
		responseData: any[],
		type: 'COMPANY' | 'PERSON' | 'ASSET' | 'ADDRESS'
	) => {
		const cb = {
			COMPANY: setCompanyOptions,
			PERSON: setPersonOptions,
			ASSET: setAssetOptions,
			ADDRESS: setAddressOptions,
		}[type];

		if (responseData && Array.isArray(responseData)) {
			cb((prev) => {
				const oldFilter = prev?.filter(
					(d) => !responseData?.some((r) => r?.id === d?.id)
				);
				return [...oldFilter, ...responseData];
			});
		}
	};

	const getAssetAdIdByAssetIndex = (index: number) => {
		const assetId = contractForm.watch(`commercials.${index}.assetId`)?.value;
		return assetOptions?.find((d) => d?.id === assetId)?.adId || '';
	};

	const getStateOptions = () => {
		const states = State.getStatesOfCountry(DEFAULT_COUNTRY_CODE).map(
			(state) => ({ label: state.name?.toUpperCase(), value: state.isoCode })
		);
		if (!states.length) states.unshift({ label: 'NA', value: 'NA' });
		return states;
	};

	const getCityOptions = (stateCode?: string) =>
		stateCode
			? City.getCitiesOfState(DEFAULT_COUNTRY_CODE, stateCode)?.map((city) => ({
				label: city.name?.toUpperCase(),
				value: city.name?.toUpperCase()
			})) || []
			: [];

	const companyOptionGenerator = (responseData: ICompanyRecord[]) => {
		handleStateOptions(responseData, 'COMPANY');

		return responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));
	};

	const performerOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');
		return responseData?.map((d) => ({
			value: d?.id,
			label: getPersonFullName(d?.personName),
		}));
	};

	const performerAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');
		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const producerAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const productOptionGenerator = (responseData) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: d?.productName?.toUpperCase(),
		}));
	};

	const lookAheadAssetOptionGenerator = (responseData: IAssetRecord[]) => {
		handleStateOptions(responseData, 'ASSET');

		return responseData?.map((d) => ({
			value: d?.id || '',
			label: d?.title || '',
		}));
	};

	const adAgencyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const getPerformerSSN = () =>{
		const performerSsn = personOptions?.find((a) => a?.id === contractForm.watch('performerId.value'))?.ssn || '';
		if (performerSsn) {
			return `***-**-${performerSsn.slice(-4)}`;
		}
		return '';
	};

	const getPerformerEmailsOptions = () => {
		const unsortedEmails =
			personOptions?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.emails || [];		
		
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};
	const getCompanyEmailOptions = (addressId?: string) => {
		const unsortedEmails =
			addressOptions?.find(
				(a) => a?.id === addressId
			)?.emails || [];
		
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const handleFormClose = () => {
		contractForm?.reset();
		props?.onCancel && props?.onCancel();
	};

	const { mutate: addContractDetails, isLoading: isAddingContract } =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addContractSagAftraAudioComHT(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave) {
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const { mutate: editContractDetails, isLoading: isEditingContract } =
		useMutation({
			mutationKey: ['contract-edit'],
			mutationFn: (payload: any) =>
				updateContractSagAftraAudioComHT(
					props?.contractId || '',
					payload
				),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'UPDATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave) {
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'UPDATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const handleContractSubmission = async (
		submitData: IContractSagAftraAudioComHTForm,
		handleMultipleContractAdd?: CallableFunction
	) => {
		handlePreContractSubmissionTasks(submitData, (data) => {
			const commercials = data?.commercials?.map((d) => ({
				...d,
				assetId: d?.assetId?.value,
			}));

			const mainFormValues = props?.mainForm?.getValues();
			const addMainFormValues = !props?.isEdit ? {
				contractType: mainFormValues?.contractType,
				contractDisplayName: mainFormValues?.contractDisplayName,
				unionType: mainFormValues?.unionType,
				unionAgreement: mainFormValues?.unionAgreement,
				payrollVendorAddressId: mainFormValues?.payrollVendorAddressId || '',
			} : {};

			const workDays = data?.workDays?.filter((d) => d?.date) || [];

			const submitData = {
				...data,
				...addMainFormValues,
				payrollVendorAddressId: data?.payrollVendorAddressId?.value,
				contractSagAftraAudioComHT: undefined,
				payrollVendorId: data?.payrollVendorId?.value,
				performerId: data?.performerId?.value,
				advertiserId: data?.advertiserId?.value,
				returnToEmail: data?.returnToEmail?.value,
				adAgencyId: data?.adAgencyId?.value,
				adAgencyAddressId: data?.adAgencyAddressId?.value,
				productId: data?.productId?.map(product => product?.value),
				performerAddressId: data?.performerAddressId?.value,
				performerAdAgencyId: data?.performerAdAgencyId?.value,
				performerAdAgencyAddressId: data?.performerAdAgencyAddressId?.value,
				producerCompanyId: data?.producerCompanyId?.value,
				producerCompanyAddressId: data?.producerCompanyAddressId?.value,
				commercials,
				workDays
			};
						
			if (!props?.isEdit){
				addContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			}
			else {
				editContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			} 
		});
	};

	const onSubmit = (data: IContractSagAftraAudioComHTForm) => {
		handleContractSubmission(data);
	};
	
	const [contractFormData, setContractFormData] = useState<IContractSagAftraAudioComHTForm>();

	const handleSaveAndDuplicate = (data: IContractSagAftraAudioComHTForm) => {
		setContractFormData(data);
	};

	const handleTalentDetailsForm = (data: any) => {
		const handleMultipleContractAdd = (payloadData) => {
			if(data) {
				const contractAddModels = data?.talentRecords?.map((talent, index) => {
					const submitData = {
						...payloadData,
						id: undefined,
						status:ContractStatus?.Draft,
						partPlayed: talent?.role,
						performerAdAgencyId: talent?.agent?.value,
						performerId: talent?.talentName?.value,
						contractDisplayName: `${payloadData?.contractDisplayName}_copy_${index+1}`,
						performerType:talent?.perfCategory?.toUpperCase(),
						compensation: talent?.compensation,
						performerAddressId:undefined,
						performerEmailId:undefined,
						performerPhoneId:undefined,
						performerAdAgencyAddressId:undefined
					};
					return submitData;
				});
				const multipleContractData = {
					contractAddSagAftraAudioComHTModels: contractAddModels
				};
				addMultipleContractDetails(multipleContractData);
			}
		};
		contractFormData && handleContractSubmission(contractFormData, handleMultipleContractAdd);
	};

	useEffect(()=> {
		if(props?.isEdit && commercialFieldArray.fields.length === 0){
			onAddCommercial();
		}
	}, [props?.isEdit]);

	const onAdAgencyAddressCreated = (createdAddress: ICompanyAddress) => {
		setCompanyOptions((prev) => {
			const company = companyOptions.find(
				(op) => op.id === contractForm.watch('adAgencyId.value')
			);

			!company?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				company?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === company?.id ? company : c));
		});
		contractForm.setValue('adAgencyAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const onPersonAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('performerId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('performerAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
		onAddressChange(contractForm.watch('performerAddressId.value') || '', 'performer');
	};

	const onProducerAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('producerCompanyId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('producerCompanyAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const openEmailInlinePopOver = (e: React.MouseEvent<HTMLButtonElement | null>, key?: string) => {
		setEmailInlinePopOver({ isOpen: true, event: e?.currentTarget, for: key });
	};

	const closeEmailInlinePopOver = () => {
		setEmailInlinePopOver({ isOpen: false });
	};

	const onPerformerEmailInlineSubmit = (formData: any) => {
		const personId = contractForm.watch('performerId.value') || '';
		createPersonEmail({personId, Email: {...formData, isPrimary: true}}).then((res: any) =>{
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Email', 'CREATE'),
					type: 'success',
				});
				closeEmailInlinePopOver();

				setPersonOptions(prev => (
					prev?.map(em => (em?.id == contractForm.watch('performerId.value') ? ({
						...em,
						emails: [
							{ id: res?.data?.id, ...formData },
							...(em?.emails || {}) as any
						]
					}) : em))
				));	

				contractForm.trigger('performerId');
				contractForm.trigger('performerId.value');
				contractForm.setValue('performerEmailId', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Email Address.',
					type: 'error',
				});
			}
		});
	};

	const onProducerEmailInlineSubmit = (formData: any) => {
		const addressId = contractForm.watch('producerCompanyAddressId.value') || '';
		addNewCompanyEmail(addressId, {...formData, isPrimary: true}).then((res: any) =>{
			if(res?.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Email', 'CREATE'),
					type: 'success',
				});
				closeEmailInlinePopOver();

				setAddressOptions((prev) =>
					prev.map((ad) =>
						ad.id === contractForm.watch('producerCompanyAddressId.value')
							? {
								...ad,
								emails: [
									{ id: res?.data?.id, ...formData },
									...(ad?.emails || []),
								],
							}
							: ad
					)
				);
				contractForm.trigger('producerCompanyAddressId', { shouldFocus: true });
				contractForm.setValue('producerCompanyEmailId', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Email Address.',
					type: 'error',
				});
			}
		});
	};

	const handleCommercialChange = async (id: string) => {
		const filterModel = {
			pageSize: DefaultPageSize,
			sortDescending: false,
			sortField: 'CreatedAt',
			pageNumber: 0,
			filters: [{
				property: 'Asset.Id',
				value: id,
				operator: OPERATORS.EQUAL,
				isCaseSensitive: false
			}]
		};

		const res: any = await retriveAssetProducts(filterModel);

		if(res?.data?.records){
			const existingProducts: ILookAheadSelectOption[] = contractForm.getValues('productId') ?? [];
			let products: any = res?.data?.records?.map(product => {
				return { value: product.id, label: product.productName, assetId: id };
			});

			products = products.filter(product => {
				const foundProduct = existingProducts.find(eProduct => eProduct.value === product.value);
				if(foundProduct) return false;
				else return true;
			});

			contractForm.setValue('productId', [...products, ...existingProducts], { shouldDirty: true, shouldValidate: true });
		}
	};

	const handleProducerChange = (index ?: any)=>{
		if(index ==0){
			contractForm.setValue('producerCompanyId',null,{shouldDirty:true});
			contractForm.setValue('producerCompanyAddressId',null,{shouldDirty:true});
			contractForm.setValue('producerCompanyEmailId','',{shouldDirty:true});
			contractForm.setValue('producerPrintName','',{shouldDirty:true});
		
		}
		if(contractForm.watch(`commercials.${0}.assetId.value`)?.length == 0){
			contractForm.setValue('producerCompanyId',null,{shouldDirty:true});
			contractForm.setValue('producerCompanyAddressId',null,{shouldDirty:true});
			contractForm.setValue('producerCompanyEmailId','',{shouldDirty:true});
			contractForm.setValue('producerPrintName','',{shouldDirty:true});
		}
	};

	return (
		<>
			<ContractMutationWrapper
				isEdit={props?.isEdit}
				isPrint={props?.isPrint}
				contractForm={contractForm}
				contractFormState={contractFormState}
				setContractFormState={setContractFormState}
				handleFormClose={handleFormClose}
				handleSaveAndDuplicate={handleSaveAndDuplicate}
				handleTalentDetailsForm={handleTalentDetailsForm}
				isAddingContract={isAddingContract}
				isEditingContract={isEditingContract}
				onSubmit={contractForm.handleSubmit(onSubmit, onSubmitError)}
				setPacketFormState={setPacketFormState}
				performerCategory={SagAftraAudioCommercialsPerformerTypeOptions}
				isUnion={true}
				mainForm={props?.mainForm}
			>
				<div className={styles.contractFormBlock}>
					<div id={CONTRACT_REF_ID.MAIN_FORM} className={styles.bgWhite} style={{padding:'20px'}}>
						<Grid item container columnSpacing={3}>
	
							<Grid item md={7} >												
								<h4>{contractForm.watch('contractId') ? `#${contractForm.watch('contractId')}` : ''}</h4>														
								<Box sx={{pt:1}}>
									<h2 style={{textAlign:'left'}}>SAG-AFTRA STANDARD EMPLOYMENT CONTRACT FOR AUDIO COMMERCIALS</h2>
									<h4 style={{textAlign:'left'}}>EXHIBIT A </h4>
								</Box>
							</Grid>
							<Grid item md={5}>
								<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
									<tr>
										<td>Ad Agency</td>
										<td>
											<FormControl
												fullWidth
												className={styles.tableForms}
											>
												<Controller
													control={contractForm.control}
													name='adAgencyId'
													render={({ field }) => (
														<>
															<LookAheadSelect
																data-cy='ad_agency'
																{...field}
																placeholder={!props?.isPrint ? 'Ad-Agency' : ''}
																filterProperty='CompanyNames.Name'
																APICaller={getCompanyBasicDetails}
																optionGenerator={companyOptionGenerator}
																defaultFilter={AD_AGENCY_FILTER}
																isDisabled={props?.isPrint}
																isClearable={true}
																onChange={(e: any) => {
																	field.onChange(e);
																	contractForm.setValue('adAgencyAddressId', null, { shouldDirty: true });
																	contractForm.trigger('adAgencyAddressId');
																}}
																cacheOptions={false}
																defaultOptions
																className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
															/>
															{!props?.isPrint && (
																<Box sx={{mt:0.5}}>
																	<AddCompanyInline
																		title='Company'
																		companyType={{ mainType: 'AD AGENCY' as CompanyMainType, subType: [] }} />
																</Box>
															)}
																								
														</>
													)}
												/>
											</FormControl>
										</td>
									</tr>
									<tr>
										<td>Producer{ !props?.isPrint && (
											<Tooltip
												placement='right-end'
												title={'Signatory'}
											>
												<HelpOutline fontSize='inherit' />
											</Tooltip>)
										}<sup></sup></td>
										<td> 
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<Controller
													key={`commercial-assetId
													-${contractForm.watch(`commercials.${0}.assetId.value`)}
													`}
													control={contractForm.control}
													name='producerCompanyId'
													render={({ field }) => (
														<>
															<LookAheadSelect
																{...field}
																key={`commercial-assetId
																	-${contractForm.watch(`commercials.${0}.assetId.value`)}
																	`}
																placeholder={!props?.isPrint ? 'Producer' : ''}
																filterProperty='CompanyNames.Name'
																APICaller={(filterModel) => getAllSignatory(contractForm.watch(`commercials.${0}.assetId.value`),filterModel)}
																optionGenerator={companyOptionGenerator}
																isClearable={true}
																onChange={(e: any) => {
																	field.onChange(e);
																	contractForm.setValue('producerCompanyAddressId', null, { shouldDirty: true});
																	contractForm.setValue('producerCompanyEmailId', '', { shouldDirty: true});
																	contractForm.trigger('producerCompanyAddressId');
																	contractForm.trigger('producerCompanyEmailId');
																	contractForm.setValue('producerPrintName','',{shouldDirty:true});
																	onAddressChange(contractForm.watch('producerCompanyAddressId.value') || '', 'producer');
																}}
																isDisabled={props?.isPrint}
																cacheOptions
																defaultOptions
																className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
															/>
															{/* {!props?.isPrint && (
																<Box sx={{mt:0.5}}>
																	<AddCompanyInline
																		title='Company' />
																</Box>
															)} */}
														</>
													)}
												/>
												{contractForm.formState.errors
													?.producerCompanyId?.message && (
													<FormHelperText
														error={true}
														data-cy='errorHelper-producerCompanyId'
													>
														{
															contractForm.formState?.errors
																?.producerCompanyId?.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
									</tr>
									<tr>
										<td>Product</td>
										<td>
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<Controller
													control={contractForm.control}
													name='productId'
													render={({ field }) => (
														<LookAheadSelect
															{...field}
															isMulti
															placeholder={!props?.isPrint ? 'Select Product' : ''}
															key={`product-${contractForm.watch(
																'advertiserId.value'
															)}`}
															filterProperty='ProductName'
															APICaller={(filterModel) =>
																getAllProducts(
																	filterModel,
																	contractForm.watch('advertiserId.value')
																)
															}
															optionGenerator={productOptionGenerator}
															isDisabled={
																!contractForm.watch('advertiserId.value') ||
																		props?.isPrint
															}
															defaultFilter={PRODUCT_FILTER}
															dropDownsize={20}
															isClearable={true}
															onChange={(e: any) => {
																field.onChange(e);
															}}
															cacheOptions={false}
															defaultOptions
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														/>
													)}
												/>	
											</FormControl>
										</td>
									</tr>
								</table>
							</Grid>
						</Grid>
						<hr></hr>
						<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
							<tr>
								<td width={50}>Job #</td>
								<td>
									<FormControl
										fullWidth
										className={styles.tableForms}
									>
										<Controller
											control={contractForm.control}
											name='jobTitle'
											defaultValue=''
											render={({ field }) => (
												<NabuTextbox
													data-cy='job_name_textbox'
													{...field}
													disabled={props?.isPrint}
													placeholder={!props?.isPrint ? 'Job' : ''}
												/>
											)}
										/>
									</FormControl>
								</td>
								<td width={200}>COMMERCIAL TITLE(S) <br></br>AND Ad-ID®(s)<sup>*</sup></td>
								<td>
									{/* <FormControl fullWidth className={styles.tableForms}><NabuTextbox placeholder='Enter COMMERCIAL TITLE(S) AND Ad-ID®(s)'/></FormControl>  */}
									{commercialFieldArray.fields.map((field, index) => (
										<div key={`commercial-${field.id}`}>
											<FormControl
												fullWidth
												className={styles.tableForms}
											>
												<Controller
													control={contractForm.control}
													name={`commercials.${index}.assetId`}
													render={({ field }) => (
														<>
															<LookAheadSelect
																data-cy='source-asset'
																{...field}
																key={`commercial-${contractForm.watch(`commercials.${index}.assetId`)}`}
																placeholder={!props?.isPrint ? 'Commercial' : ''}
																filterProperty='Title'
																isDisabled={props?.isPrint || !contractForm.watch('advertiserId.value')}
																defaultFilter={getCommercialFilters()}
																APICaller={(filterModel) => getAllAssetForBasicDetail(filterModel, false)}
																optionGenerator={
																	lookAheadAssetOptionGenerator
																}
																onChange={(e: any) => { handleCommercialChange(e?.value), field.onChange(e);

																	contractForm.setValue('commercialTitle','', { shouldDirty: true });
																	contractForm.setValue('numberOfCommercials','', { shouldDirty: true });
																	handleProducerChange(index);							
																}}
																isClearable={true}
																cacheOptions
																defaultOptions
																dropDownsize={25}
																className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
															/>
															{!props?.isPrint && (
																<Box sx={{mt: 1}}>
																	<AddAssetInline
																		title='Asset' />
																</Box>
															)}
														</>
													)}
												/>
												{contractForm.formState?.errors?.commercials
													?.length && (
													<FormHelperText error={true}>
														{
															contractForm.formState.errors?.commercials[index]?.assetId?.message
														}
													</FormHelperText>
												)}
											</FormControl>
											{(contractForm.watch(`commercials.${index}.assetId`)) && (<div>
												<span>AD-ID</span>
												<FormControl
													fullWidth
													className={styles.tableForms}
												>
													<NabuTextbox
														disabled={props?.isPrint}
														value={getAssetAdIdByAssetIndex(index)}
													/>
												</FormControl>
											</div>)}
										</div>
									))}
								</td>
							</tr>
						</table>
						<br></br>
						<table
							width={'100%'}
							border={1}
							cellPadding={0}
							cellSpacing={0}
							className={`${styles.contractFormsInner} keep-together`}
						>
							<tr style={{ background: '#808080' }}>
								<th style={{ textAlign: 'center' }} colSpan={2}>
													Dates Worked<sup></sup>
								</th>
								<th style={{ textAlign: 'center' }} colSpan={2}>
													Work Time
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-around',
										}}
									>
										<span>From</span> <span>To</span>{' '}
									</div>
								</th>
								{!props?.isPrint && (
									<th style={{ textAlign: 'center' }}></th>
								)}
							</tr>
							{workDayFieldArray.fields.map((field, index) => (
								<tr key={`work-field-${field.id}`}>
									<td height={40} colSpan={2}>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											<Controller
												control={contractForm.control}
												name={`workDays.${index}.date`}
												defaultValue=''
												render={({ field }) => (
													<NabuDatepicker
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Date' : ''}
													/>
												)}
											/>
										</FormControl>
									</td>
									<td>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.workTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
											{props?.isPrint ? (
												<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.workTimeStartAt`) || '', 'hh:mm aa')}</p>
											) : (<Controller
												control={contractForm.control}
												name={`workDays.${index}.workTimeStartAt`}
												render={({ field }) => (
													<NabuTimePicker
														{...field}
														ampm={true}
														disabled={!(contractForm.watch(`workDays.${index}.date`))}
													/>
												)}
											/>)}
										</FormControl>
									</td>
									<td>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											{props?.isPrint ? (
												<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.makeUpTimeEndAt`) || '', 'hh:mm aa')}</p>
											) : (<Controller
												control={contractForm.control}
												name={`workDays.${index}.makeUpTimeEndAt`}
												render={({ field }) => (
													<NabuTimePicker
														{...field}
														ampm={true}
														disabled={!(contractForm.watch(`workDays.${index}.date`))}
													/>
												)}
											/>)}
										</FormControl>
									</td>
									{!props?.isPrint && (
										<>
											<td style={{ display: 'flex' }}>
												{workDayFieldArray.fields.length - 1 ===
																	index && (
													<NabuButton
														variant='text'
														onClick={onAddWorkDay}
													>
														<Add />
													</NabuButton>
												)}
												{workDayFieldArray?.fields.length > 1 && (
													<NabuButton
														variant='text'
														onClick={() => onRemoveWorkDay(index)}
													>
														<DeleteForever color='error' />
													</NabuButton>
												)}
											</td>
										</>
									)}
								</tr>
							))}
						</table>
						<br></br>
						<Grid item container columnSpacing={3}>
							<Grid item md={12}>
								<Box>
									<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
										<span>Performer’s Signature or Initials:</span>	 
										<FormControl sx={{minWidth:'350px'}} className={styles.tableForms}><NabuTextbox placeholder='Performer’s Signature or Initials' disabled={true}/></FormControl>
									</div>
								</Box>
							</Grid>
						</Grid>
						<hr></hr>
						<table width={'100%'} border={0} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
							<tr>
								<td width={35}>
									<span>Date</span>
								</td>
								<td width={200}>
									<FormControl
										className={styles.tableForms}
										fullWidth
									>
										{props?.isPrint ? (
											<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('producerDate')) || ''}</p>
										) :(<Controller
											control={contractForm.control}
											name='producerDate'
											render={({ field }) => (
												<NabuDatepicker
													{...field}
													disabled={props?.isPrint}
													placeholder={!props?.isPrint ? 'Date' : ''}
												/>
											)}
										/>)}
									</FormControl>
								</td>
								<td width={60}>
									<span>Between</span>
								</td>
								<td>
									<FormControl
										className={styles.tableForms}
										fullWidth
									>
										<Controller
											key={`commercial-assetId
													-${contractForm.watch(`commercials.${0}.assetId.value`)}
													`}
											control={contractForm.control}
											name='producerCompanyId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														{...field}
														key={`commercial-assetId
																	-${contractForm.watch(`commercials.${0}.assetId.value`)}
																	`}
														placeholder={!props?.isPrint ? 'Producer' : ''}
														filterProperty='CompanyNames.Name'
														APICaller={(filterModel) => getAllSignatory(contractForm.watch(`commercials.${0}.assetId.value`),filterModel)}
														optionGenerator={companyOptionGenerator}
														isClearable={true}
														onChange={(e: any) => {
															field.onChange(e);
															contractForm.setValue('producerCompanyAddressId', null, { shouldDirty: true});
															contractForm.setValue('producerCompanyEmailId', '', { shouldDirty: true});
															contractForm.trigger('producerCompanyAddressId');
															contractForm.trigger('producerCompanyEmailId');
															contractForm.setValue('producerPrintName','',{shouldDirty:true});
															onAddressChange(contractForm.watch('producerCompanyAddressId.value') || '', 'producer');
														}}
														isDisabled={props?.isPrint}
														cacheOptions
														defaultOptions
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													/>
													{/* {!props?.isPrint && (
																<Box sx={{mt:0.5}}>
																	<AddCompanyInline
																		title='Company' />
																</Box>
															)} */}
												</>
											)}
										/>
										{contractForm.formState.errors
											?.producerCompanyId?.message && (
											<FormHelperText
												error={true}
												data-cy='errorHelper-producerCompanyId'
											>
												{
													contractForm.formState?.errors
														?.producerCompanyId?.message
												}
											</FormHelperText>
										)}
									</FormControl>
							
								</td>
								<td width={100}>
								, <span>Producer{ !props?.isPrint && (
										<Tooltip
											placement='right-end'
											title={'Signatory'}
										>
											<HelpOutline fontSize='inherit' />
										</Tooltip>)
									}<sup></sup>and</span>
								</td>
								<td>
									<FormControl
										className={styles.tableForms}
										fullWidth
									>
										<Controller
											control={contractForm.control}
											name='performerId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														data-cy='name_select'
														{...field}
														key={`performer-name
																									-${contractForm.watch('performerId.value')}
																									-${contractForm.watch('performerPhoneId')}-${contractForm.watch('performerEmailId')}`
														}
														isClearable={true}
														placeholder={!props?.isPrint ? 'Select Performer' : ''}
														filterProperty='PersonName.FirstName'
														APICaller={(
															filterModel,
															searchText
														) =>
															GetByWithFullNameSearchForBasicDetail(
																filterModel,
																searchText
															)
														}
														dropDownsize={50}
														isSearchText={true}
														isDisabled={props?.isPrint}
														optionGenerator={
															performerOptionGenerator
														}
														defaultFilter={getPerformerFilter()}
														onChange={(e: any) => {
															field.onChange(e);
															contractForm.setValue('performerEmailId', '', { shouldDirty: true});
															contractForm.trigger('performerEmailId');
															contractForm.setValue('performerPhoneId', '', { shouldDirty: true});
															contractForm.setValue('performerPrintName', contractForm.watch('performerId.label'),{ shouldDirty: true });
															contractForm.setValue('performerAddressId', null, { shouldDirty: true});
															contractForm.setValue('performerZip', '', { shouldDirty: true});
															contractForm.setValue('performerState', '', { shouldDirty: true});
															contractForm.setValue('performerCity', '', { shouldDirty: true});
															contractForm.setValue('performerAdAgencyId',null,{shouldDirty:true});
														}}
														cacheOptions={false}
														defaultOptions
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													/>
													{!props?.isPrint && (
														<Box sx={{marginBottom:'-27px'}}>
															<AddPersonInline
																title='Person'
																onPersonCreated={() => ({})}
																initialValues={{personType: ['TALENT'], isMinor: false}}
															/>
														</Box>
													)}
												</>
											)}
										/>
										{contractForm.formState?.errors?.performerId
											?.message && (
											<FormHelperText
												data-cy='errorHelper_first_session_date'
												error={true}
											>
												{
													contractForm.formState?.errors
														?.performerId?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</td>
								<td>
									<span>Performer<sup>*</sup>.</span> 
								</td>
							</tr>
						</table>

						<br></br>
						<div className='keep-together'>
							<p><b>Producer engages Performer and Performer agrees to perform services for Producer in Audio Commercials as follows:</b></p>

							<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
								<tr>
									<td colSpan={3}>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<span>Commercial Title(s) and Ad-ID® No.(s)</span>
											<FormControl
												fullWidth
												className={styles.tableForms}
											>
												<Controller
													key={`commercial-title-${contractForm.watch(`commercials.${0}.assetId`)}`}
													control={contractForm.control}
													name='commercialTitle'
													render={({ field }) => (
														<NabuTextbox
															{...field}
															placeholder={!props?.isPrint ? 'Commercial Title' : ''}
															disabled={true}
															value={contractForm.watch(`commercials.${0}.assetId.label`)}
														/>
													)}
												/>
											</FormControl>
											<FormControl
												fullWidth
												className={styles.tableForms}
											>
												<NabuTextbox
													disabled={props?.isPrint}
													value={getAssetAdIdByAssetIndex(0)}
												/>
											</FormControl>
										</div>

									</td>
								</tr>
								<tr>
									<td>
								# of Commercials
										<FormControl
											className={styles.tableForms}
											style={{ width: '50%' }}
										><Controller
												control={contractForm.control}
												name='numberOfCommercials'
												defaultValue=''
												render={() => (
													<NabuTextbox
														disabled={true}
														key={contractForm
															.watch('commercials')
															?.map((d) => d?.assetId)
															?.join('-')}
														value={contractForm
															?.watch(`commercials.${0}.assetId`)
															? 1:0
														}
													/>)}
											/>
										</FormControl>
									</td>
									<td>
								# of Tags
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											<Controller
												control={contractForm.control}
												name='tags'
												defaultValue=''
												render={({ field }) => (
													<NabuTextbox
														type='number'
														{...field}
														placeholder={!props?.isPrint ? 'Tags' : ''}
														disabled={props?.isPrint}
													/>
												)}
											/>
										</FormControl> 
									</td>
									<td>
								# of Demos
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											<Controller
												control={contractForm.control}
												name='demos'
												defaultValue=''
												render={({ field }) => (
													<NabuTextbox
														{...field}
														placeholder={!props?.isPrint ? 'Demos' : ''}
														disabled={props?.isPrint}
													/>
												)}
											/>
										</FormControl>
									</td>
								</tr>
								<tr>
									<td colSpan={3}>
										<Box><b>Please check if applicable:</b></Box>
										<div
											style={{
												display: 'inline-flex',
												alignItems: 'center',
											}}
										>
											<Controller
												control={contractForm.control}
												name='isInitialUseOnInternet'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Commercial(s) made for Initial Use on the Internet</span>{' '}</Box>
													</div>
												)}
											/>
											<Controller
												control={contractForm.control}
												name='isInitialUseOnNewMedia'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Commercial(s) made for Initial Use on New Media </span>{' '}</Box>
													</div>
												)}
											/>
											<Controller
												control={contractForm.control}
												name='isAudioFlexPaymentPackage'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Audio Flex Payment Package </span>{' '}</Box>
													</div>
												)}
											/>
										</div>

																		
									</td>
								</tr>
								<tr>
									<td width={350}>
								Such commercial(s) are to be produced by
									</td>
									<td>
									Advertiser Agency
										<FormControl
											className={styles.tableForms}
											fullWidth
										>
											<Controller
												control={contractForm.control}
												name='adAgencyId'
												render={({ field }) => (
													<>
														<LookAheadSelect
															data-cy='ad_agency'
															{...field}
															placeholder={!props?.isPrint ? 'Ad-Agency' : ''}
															filterProperty='CompanyNames.Name'
															APICaller={getCompanyBasicDetails}
															optionGenerator={
																companyOptionGenerator
															}
															defaultFilter={AD_AGENCY_FILTER}
															isClearable={true}
															isDisabled={props?.isPrint}
															onChange={(e: any) => {
																field.onChange(e);
																contractForm.setValue('adAgencyAddressId', null, { shouldDirty: true });
															}}
															cacheOptions
															defaultOptions
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														/>
														{!props?.isPrint && (
															<Box sx={{mt:1}}>
																<AddCompanyInline
																	title='Company'
																	companyType={{ mainType: 'AD AGENCY' as CompanyMainType, subType: [] }} />
															</Box>
														)}
																				
													</>
												)}
											/>
										</FormControl>
									</td>
									<td>
									Address
										<FormControl
											className={styles.tableForms}
											fullWidth
										>
											<Controller
												control={contractForm.control}
												key={`agency-address-cr-${contractForm.watch(
													'adAgencyId.value'
												)}`}
												name='adAgencyAddressId'
												render={({ field }) => (
													<>
														<LookAheadSelect
															{...field}
															placeholder={!props?.isPrint ? 'Select Address' : ''}
															key={`agency-address-${contractForm.watch(
																'adAgencyId.value'
															)}`}
															filterProperty='Line1'
															sortField='IsPrimary'
															sortDescending={true}
															onOptionLoaded={(options) => {
																if(options?.length && !contractForm.watch('adAgencyAddressId.value')){
																	contractForm.setValue('adAgencyAddressId', options[0], { shouldDirty: true});
																	contractForm.trigger('adAgencyAddressId', { shouldFocus: true});																						
																}
															}}
															APICaller={(filterModel) =>
																getAllAddressByCompany(
																	filterModel,
																	contractForm.watch('adAgencyId.value') ||
																					''
																)
															}
															optionGenerator={
																adAgencyAddressOptionGenerator
															}
															isDisabled={
																!contractForm.watch('adAgencyId.value') ||
																			props?.isPrint
															}
															dropDownsize={50}
															isClearable={true}
															isSearchText={true}
															onChange={(e: any) => {
																field.onChange(e);
															}}
															cacheOptions={false}
															defaultOptions
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														/>
														{contractForm.watch('adAgencyId.value')&& (!props.isPrint) && <div style={{float:'right'}}>
															<Box sx = {{mt: 0.9, marginBottom:'-27px'}}>
																<AddCompanyAddressInline
																	companyId= {contractForm.watch('adAgencyId.value')}
																	title='ADDRESS'
																	isOpen = {addressPopOver?.isOpen && addressPopOver?.for =='AdAgency-address'}
																	event={addressPopOver?.event}
																	onAddressCreated={(d) => onAdAgencyAddressCreated(d)}
																	onClose={() => setAddressPopOver({isOpen: false})}
																	onPopOverOpen={(e) => setAddressPopOver({
																		isOpen: true,
																		event: e?.currentTarget,
																		for: 'AdAgency-address'
																	})}
																	isPersistent={true} />
															</Box>
														</div>}
													</>
												)}
											/>
										</FormControl>
									</td>
								</tr>
								<tr>
									<td width={350}>
								acting as an agent for
									</td>
									<td>
									Advertiser
										<FormControl
											className={styles.tableForms}
											fullWidth
										>
											<Controller
												control={contractForm.control}
												name='advertiserId'
												render={({ field }) => (
													<>
														<LookAheadSelect
															{...field}
															placeholder={!props?.isPrint ? 'Advertiser' : ''}
															filterProperty='CompanyNames.Name'
															APICaller={getCompanyBasicDetails}
															optionGenerator={companyOptionGenerator}
															defaultFilter={ADVERTISER_FILTER}
															isDisabled={props?.isPrint}
															isClearable={true}
															onChange={(e: any) => {
																field.onChange(e);
																contractForm.setValue('commercials', [{} as any], { shouldDirty: true});
																contractForm.setValue(`commercials.${0}.assetId`, null, { shouldDirty: true});
																contractForm.setValue('productId', null, { shouldDirty: true});
																contractForm.setValue('producerCompanyId',null,{shouldDirty:true});
																contractForm.setValue('producerCompanyAddressId',null,{shouldDirty:true});
																contractForm.setValue('producerCompanyEmailId','',{shouldDirty:true});
																contractForm.setValue('producerPrintName','',{shouldDirty:true});
															}}
															cacheOptions={false}
															defaultOptions
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														/>
														{!props?.isPrint && (
															<Box sx={{mt:0.5}}>
																<AddCompanyInline
																	title='Company'
																	companyType={{ mainType: 'ADVERTISER' as CompanyMainType, subType: [] }} />
															</Box>
														)}
													</>
												)}
											/>
										</FormControl> 
									</td>
									<td>
										<div >Products(S)</div>
										<FormControl
											className={styles.tableForms}
											fullWidth
										>
											<Controller
												control={contractForm.control}
												name='productId'
												render={({ field }) => (
													<LookAheadSelect
														{...field}
														isMulti
														placeholder={!props?.isPrint ? 'Select Product' : ''}
														key={`product-${contractForm.watch(
															'advertiserId.value'
														)}`}
														filterProperty='ProductName'
														APICaller={(filterModel) =>
															getAllProducts(
																filterModel,
																contractForm.watch('advertiserId.value')
															)
														}
														optionGenerator={productOptionGenerator}
														isDisabled={
															!contractForm.watch('advertiserId.value') ||
																		props?.isPrint
														}
														defaultFilter={PRODUCT_FILTER}
														dropDownsize={20}
														isClearable={true}
														onChange={(e: any) => {
															field.onChange(e);
														}}
														cacheOptions={false}
														defaultOptions
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													/>
												)}
											/>	
										</FormControl> 
									</td>
								</tr>
								<tr>
									<td>
										<p>
											<b>City, State (In which services rendered):</b>
										</p>
										<div
											style={{
												marginTop: '7px',
												display: 'inline-block',
											}}
										>
											{' '}
																State<sup></sup>:{' '}
										</div>
										<FormControl
											className={styles.tableForms}
																		
											fullWidth
										>
											<Controller
												control={contractForm.control}
												name='serviceState'
												defaultValue=''
												render={({ field }) => (
													<NabuSelect
														{...field}
														disabled={props?.isPrint}
														options={getStateOptions()}
														convertValueToUpperCase={false}
														placeholder={!props?.isPrint ? 'Select State' : ''}
													/>
												)}
											/>
										</FormControl>{' '}
															&nbsp;
										<div
											style={{
																	
												display: 'inline-block',
											}}
										>
																City<sup></sup>:{' '}
										</div>
										<FormControl
											className={styles.tableForms}
											fullWidth
										>
											<Controller
												control={contractForm.control}
												name='serviceCity'
												defaultValue=''
												render={({ field }) => (
													<NabuSelect
														{...field}
														disabled={props?.isPrint}
														options={getCityOptions(
															contractForm.watch('serviceState')
														)}
														convertValueToUpperCase={true}
														placeholder={!props?.isPrint ? 'Select City' : ''}
													/>
												)}
											/>
										</FormControl>
									</td>
									<td style={{verticalAlign:'top'}}>
								Place of Engagement 
										<FormControl
											className={styles.tableForms}
											fullWidth
																		
										>
											<Controller
												control={contractForm.control}
												name='placeOfEngagement'
												render={({ field }) => (
													<LocationFreeTextSelect
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Place of Engagement' : ''}
													/>
												)}
											/>
										</FormControl>
									</td>
									<td style={{verticalAlign:'top'}}>
								Date and Hour of Engagement:
										<FormControl
											className={styles.tableForms}
											fullWidth
										>
											<Controller
												control={contractForm.control}
												name='engagementDate'
												render={({ field }) => (
													<NabuDatepicker
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Engagement Date' : ''}
													/>
												)}
											/>
										</FormControl>
									</td>
								</tr>
							</table>
						</div>
						<br></br>
						<Grid item container columnSpacing={3}>
							<Grid item md={12}>
								<table
									width={'100%'}
									border={0}
									cellPadding={0}
									cellSpacing={0}
									className={`${styles.contractFormsInner} keep-together`}
									style={{ border: '#515151 solid 1.5px' }}
								>
									<tr>
										<td colSpan={5}>
											<b>Performer Type</b>
										</td>
									</tr>
									<tr>
										<td valign='top'>
											<div
												style={{
													display: 'inline-flex',
													alignItems: 'center',
												}}
											>
												<Controller
													control={contractForm.control}
													name='performerType'
													defaultValue=''
													render={({ field }) => (
														<div
															onClick={() =>
																!props?.isPrint && field.onChange('ANNOUNCER')
															}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	value='ANNOUNCER'
																	checked={field.value === 'ANNOUNCER'}
																	onChange={(e) =>
																		field.onChange(e.target.value)
																	}
																	type='radio'
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
																<span>Announcer</span>
															</Box>
														</div>
													)}
												/>
											</div>
										</td>
										<td valign='top'>
											<div
												style={{
													display: 'inline-flex',
													alignItems: 'center',
												}}
											>
												<Controller
													control={contractForm.control}
													name='performerType'
													defaultValue=''
													render={({ field }) => (
														<div
															onClick={() =>
																!props?.isPrint && field.onChange('SINGER')
															}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	value='SINGER'
																	checked={field.value === 'SINGER'}
																	onChange={(e) =>
																		field.onChange(e.target.value)
																	}
																	type='radio'
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
																<span>Singer</span>
															</Box>
														</div>
													)}
												/>
											</div>
										</td>
										<td valign='top'>
											<div
												style={{
													display: 'inline-flex',
													alignItems: 'center',
												}}
											>
												<Controller
													control={contractForm.control}
													name='performerType'
													defaultValue=''
													render={({ field }) => (
														<div
															onClick={() =>
																!props?.isPrint && field.onChange('SOLO_OR_DUO')
															}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	value='SOLO_OR_DUO'
																	checked={field.value === 'SOLO_OR_DUO'}
																	onChange={(e) =>
																		field.onChange(e.target.value)
																	}
																	type='radio'
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
																<span>Solo or Duo</span>
															</Box>
														</div>
													)}
												/>
											</div>
										</td>
										<td valign='top'>
											<div
												style={{
													display: 'inline-flex',
													alignItems: 'center',
												}}
											>
												<Controller
													control={contractForm.control}
													name='performerType'
													defaultValue=''
													render={({ field }) => (
														<div
															onClick={() =>
																!props?.isPrint && field.onChange('GROUP_6_8')
															}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	value='GROUP_6_8'
																	checked={field.value === 'GROUP_6_8'}
																	onChange={(e) =>
																		field.onChange(e.target.value)
																	}
																	type='radio'
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
																<span>Group 6-8</span>
															</Box>
														</div>
													)}
												/>
											</div>
										</td>
										<td valign='top'>
											<div
												style={{
													display: 'inline-flex',
													alignItems: 'center',
												}}
											>
												<Controller
													control={contractForm.control}
													name='performerType'
													defaultValue=''
													render={({ field }) => (
														<div
															onClick={() =>
																!props?.isPrint && field.onChange('CONTRACTOR')
															}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	value='CONTRACTOR'
																	checked={field.value === 'CONTRACTOR'}
																	onChange={(e) =>
																		field.onChange(e.target.value)
																	}
																	type='radio'
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
																<span>Contractor</span>
															</Box>
														</div>
													)}
												/>
											</div>
										</td>
																	
									</tr>
									<tr>
										<td valign='top'>
											<div
												style={{
													display: 'inline-flex',
													alignItems: 'center',
												}}
											>
												<Controller
													control={contractForm.control}
													name='performerType'
													defaultValue=''
													render={({ field }) => (
														<div
															onClick={() =>
																!props?.isPrint && field.onChange('ACTOR')
															}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	value='ACTOR'
																	checked={field.value === 'ACTOR'}
																	onChange={(e) =>
																		field.onChange(e.target.value)
																	}
																	type='radio'
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
																<span>Actor,Actress</span>
															</Box>
														</div>
													)}
												/>
											</div>
										</td>
										<td valign='top'>
											<div
												style={{
													display: 'inline-flex',
													alignItems: 'center',
												}}
											>
												<Controller
													control={contractForm.control}
													name='performerType'
													defaultValue=''
													render={({ field }) => (
														<div
															onClick={() =>
																!props?.isPrint && field.onChange('GROUP_SPEAKER')
															}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	value='GROUP_SPEAKER'
																	checked={field.value === 'GROUP_SPEAKER'}
																	onChange={(e) =>
																		field.onChange(e.target.value)
																	}
																	type='radio'
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
																<span>Group Speaker</span>
															</Box>
														</div>
													)}
												/>
											</div>
										</td>
										<td valign='top'>
											<div
												style={{
													display: 'inline-flex',
													alignItems: 'center',
												}}
											>
												<Controller
													control={contractForm.control}
													name='performerType'
													defaultValue=''
													render={({ field }) => (
														<div
															onClick={() =>
																!props?.isPrint && field.onChange('GROUP_3_5')
															}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	value='GROUP_3_5'
																	checked={field.value === 'GROUP_3_5'}
																	onChange={(e) =>
																		field.onChange(e.target.value)
																	}
																	type='radio'
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
																<span>Group 3-5</span>
															</Box>
														</div>
													)}
												/>
											</div>
										</td>
										<td valign='top'>
											<div
												style={{
													display: 'inline-flex',
													alignItems: 'center',
												}}
											>
												<Controller
													control={contractForm.control}
													name='performerType'
													defaultValue=''
													render={({ field }) => (
														<div
															onClick={() =>
																!props?.isPrint && field.onChange('SINGER_GROUP_9_MORE')
															}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	value='SINGER_GROUP_9_MORE'
																	checked={field.value === 'SINGER_GROUP_9_MORE'}
																	onChange={(e) =>
																		field.onChange(e.target.value)
																	}
																	type='radio'
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
																<span>Group 9 or More</span>
															</Box>
														</div>
													)}
												/>
											</div>
										</td>
										<td valign='top'>
											<div
												style={{
													display: 'inline-flex',
													alignItems: 'center',
												}}
											>
												<Controller
													control={contractForm.control}
													name='performerType'
													defaultValue=''
													render={({ field }) => (
														<div
															onClick={() =>
																!props?.isPrint && field.onChange('SOUND_EFFECTS')
															}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	value='SOUND_EFFECTS'
																	checked={field.value === 'SOUND_EFFECTS'}
																	onChange={(e) =>
																		field.onChange(e.target.value)
																	}
																	type='radio'
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
																<span>Sound Effects Performer </span>
															</Box>
														</div>
													)}
												/>
											</div>
										</td>
																	
									</tr>
									<tr>
										<td colSpan={5}>
																		
											<div
												style={{
													display: 'inline-flex',
													alignItems: 'center',
												}}
											>
												<Controller
													control={contractForm.control}
													name='performerType'
													defaultValue=''
													render={({ field }) => (
														<div
															onClick={() =>
																!props?.isPrint &&
                                field.onChange('SPANISH_LANGUAGE_TRANSLATION_PERFORMED'
                                )
															}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	value='SPANISH_LANGUAGE_TRANSLATION_PERFORMED'
																	checked={
																		field.value ===
                                        'SPANISH_LANGUAGE_TRANSLATION_PERFORMED'
																	}
																	onChange={(e) =>
																		field.onChange(e.target.value)
																	}
																	type='radio'
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
																							Spanish Language Translation Services Performed
															</Box>
														</div>
													)}
												/>
											</div>
																		
										</td>
									</tr>
								</table>

							</Grid>
						</Grid>
						<br></br>
						<div className='keep-together'>
							<p><b>Multiple Tracking or Sweetening (for singers)</b></p>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '15px',
								}}
							>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<Controller
										control={contractForm.control}
										name='isSingersMultipleTrackingOccur'
										defaultValue={false}
										render={({ field }) => (
											<div
												onClick={() => (!props?.isPrint) && field.onChange(true)}
												style={{ userSelect: 'none' }}
											>
												<Box display={'flex'} alignItems={'flex-start'}>
													<input
														{...field}
														value={field.value + ''}
														checked={field.value}
														onChange={() => field.onChange(true)}
														type='checkbox'
														disabled={props?.isPrint}
														style={{ marginTop: '3px' }}
													/>
													<span>Did Occur</span></Box>
											</div>
										)}
									/>
								</div>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<Controller
										control={contractForm.control}
										name='isSingersMultipleTrackingOccur'
										defaultValue={false}
										render={({ field }) => (
											<div
												onClick={() => (!props?.isPrint) && field.onChange(false)}
												style={{ userSelect: 'none' }}
											>
												<Box display={'flex'} alignItems={'flex-start'}>
													<input
														{...field}
														value={field.value + ''}
														checked={!field.value}
														onChange={() => field.onChange(false)}
														type='checkbox'
														disabled={props?.isPrint}
														style={{ marginTop: '3px' }}
													/>
													<span>Did Not Occur</span></Box>
											</div>
										)}
									/>
								</div>
							</div>
						</div>
						<br></br>

						<div className='keep-together' style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
							<span>Compensation{ !props?.isPrint && (
								<Tooltip
									placement='right-end'
									title={'You can manually Enter the required option'}
								>
									<HelpOutline fontSize='inherit' />
								</Tooltip>)
							}</span>
							<FormControl
								className={styles.tableForms}
								style={{minWidth:'160px'}}
							>
								<Controller
									control={contractForm.control}
									name='compensation'
									defaultValue='SCALE'
									render={({ field }) => (
										<NabuDynamicInputDropdown
											{...field}
											options={COMPENSATION_OPTIONS}
											disabled={props?.isPrint}
											placeholder={!props?.isPrint ? 'Compensation' : ''}
										/>
									)}
								/>
							</FormControl>
							<span>  Part to be played</span>
							<FormControl
								className={styles.tableForms}
								fullWidth style={{maxWidth:'400px'}}
							>
								<Controller
									control={contractForm.control}
									name='partPlayed'
									defaultValue=''
									render={({ field }) => (
										<NabuTextbox
											data-cy='job_name_textbox'
											{...field}
											disabled={props?.isPrint}
											placeholder={!props?.isPrint ? 'Part Played' : ''}
										/>
									)}
								/>
							</FormControl> 
						</div>

						<br></br>
						<div className='keep-together'
							style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}
						>
							<Controller
								control={contractForm.control}
								name='isPerformerConcentToUseOnInternet'
								defaultValue={false}
								render={({ field }) => (
									<div
										onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
										style={{ userSelect: 'none' }}
									>
										<Box display={'flex'} alignItems={'flex-start'}>
											<input
												{...field}
												value={field.value + ''}
												checked={field.value}
												onChange={(e) => field.onChange(e)}
												type='checkbox'
												disabled={props?.isPrint}
												style={{ marginTop: '3px' }}
											/>
											<span>Performer does not consent to the use of his/her services in commercials made hereunder on the Internet</span></Box>
									</div>
								)}
							/>
							<Controller
								control={contractForm.control}
								name='isPerformerConcentToUseOnNewMedia'
								defaultValue={false}
								render={({ field }) => (
									<div
										onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
										style={{ userSelect: 'none' }}
									>
										<Box display={'flex'} alignItems={'flex-start'}>
											<input
												{...field}
												value={field.value + ''}
												checked={field.value}
												onChange={(e) => field.onChange(e)}
												type='checkbox'
												disabled={props?.isPrint}
												style={{ marginTop: '3px' }}
											/>
											<span>Performer does not consent to the use of his/her services in commercials made hereunder in New Media.</span></Box>
									</div>
								)}
							/>
						</div>
						<br></br>
						<p>
						The standard provisions printed on Page 2 hereof are a part of this contract. If this contract provides for compensation at SAG-AFTRA minimum, no additions,
changes or alterations may be made in this form other than those which are more favorable to the Performer than herein provided. If this contract provides for
compensation above the SAG-AFTRA minimum, additions may be agreed to between Producer and Performer which do not conflict with the provisions of the
SAG-AFTRA Audio Recorded Commercials Contract, provided that such additional provisions are separately set forth under &quot;Special Provisions&quot; hereof and
signed by the Performer
						</p>
						<br></br>
						<div className='keep-together'>
							<p>
								<b>Until Performer shall otherwise direct in writing, Performer authorizes Producer to make all payments to which Performer may be entitled hereunder as follows:</b>
							</p>
							<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
								<tr>
									<td width={230}>
							All payments to Performer c/o:
									</td>
									<td>
										<FormControl
											className={styles.tableForms}
											fullWidth
										>
											<Controller
												control={contractForm.control}
												name='performerAdAgencyId'
												render={({ field }) => (
													<>
														<LookAheadSelect
															{...field} 
															key={`agency-${contractForm.watch('performerId.value')}`}
															placeholder='Agency'
															filterProperty='CompanyNames.Name'
															APICaller={(filter) => {
																filter.filters = filter.filters?.map((f) => f.property == 'CompanyNames.Name' ? {...f, value: f?.value?.trim() || '' } : f) || [];
																return getAgentInfo(filter, contractForm.watch('performerId.value') || '');
															}}
															optionGenerator={companyOptionGenerator}
															onChange={(e: any) => {
																field.onChange(e);
																contractForm.setValue('performerAdAgencyAddressId', null, { shouldDirty: true});
															}}																						
															onOptionLoaded={(options) => {
																if(options?.length && !contractForm.watch('performerAdAgencyId.value')){
																	contractForm.setValue('performerAdAgencyId', options[0], { shouldDirty: true});
																	contractForm.trigger('performerAdAgencyId', { shouldFocus: true});																						
																}
															}}
															cacheOptions
															isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
															defaultOptions
															dropDownsize={25}
															isClearable={true}
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														/>
														{!props?.isPrint && contractForm.watch('performerId.value') && (<div style={{float:'right'}}><AddTalentAgentInline
															onCompanyCreated={(createdAgent) => contractForm.setValue('performerAdAgencyId', createdAgent, { shouldDirty: true})}
															onClose={() => setCompanyPopoverInline({ isOpen: false})}
															onPopOverOpen={(e) => setCompanyPopoverInline({ isOpen: true, event: e?.currentTarget })}
															event={companyPopoverInline?.event}
															isOpen={companyPopoverInline.isOpen}
															personId={contractForm.watch('performerId.value') || ''}
														/></div>)}
													</>
												)}
											/>
										</FormControl>
									</td>
									<td width={200}>
							All payments to Performer
									</td>
									<td>
										<FormControl
											className={styles.tableForms}
											fullWidth
										>
											<Controller
												control={contractForm.control}
												key={`select_Address_${contractForm.watch(
													'performerId.value'
												)}`}
												name='performerAddressId'
												render={({ field }) => (
													<>
														<LookAheadSelect
															{...field}
															placeholder={!props?.isPrint ? 'Select Address' : ''}
															key={`performer-address-${contractForm.watch(
																'performerId.value'
															)}`}
															filterProperty='Line1'
															sortField='IsPrimary'
															sortDescending={true}
															onOptionLoaded={(options) => {
																if(options?.length && !contractForm.watch('performerAddressId.value')){
																	contractForm.setValue('performerAddressId', options[0], { shouldDirty: true});
																	contractForm.trigger('performerAddressId', { shouldFocus: true});																						
																}
															}}
															APICaller={(filterModel) =>
																getAllAddressByPerson(
																	filterModel,
																	contractForm.watch(
																		'performerId.value'
																	) || ''
																)
															}
															optionGenerator={
																performerAddressOptionGenerator
															}
															dropDownsize={50}
															isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
															isClearable={true}
															isSearchText={true}
															onChange={(e: any) => {
																field.onChange(e);
																onAddressChange(contractForm.watch('performerAddressId.value'), 'performer');
															}}
															cacheOptions={false}
															defaultOptions
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														/>
														{																							
															<Box sx={{mt:1.3}}>
																{(contractForm.watch('performerId.value') && (!props.isPrint)) && 
																							<AddPersonAddressInline
																								personId={contractForm.watch('performerId.value')}
																								title='ADDRESS'
																								isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'performer-address' || false}
																								event={addressPopOver?.event}
																								onAddressCreated={(d) => onPersonAddressCreated(d)}
																								onClose={() => setAddressPopOver({ isOpen: false })}
																								onPopOverOpen={(e) => setAddressPopOver({
																									isOpen: true,
																									event: e?.currentTarget,
																									for: 'performer-address'
																								})}
																								isPersistent={true} />
																}
															</Box>
														}
													</>
												)}
											/>
										</FormControl>
									</td>
								</tr>

							</table>
						</div>	
						<br></br>
						<div className='keep-together'>
							<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
								<tr>
									<td width={400}>
								All notices to Performer shall be sent to the address designated above for payments unless indicated here:
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}><NabuTextbox placeholder="" disabled={props?.isPrint}/></FormControl> 
									</td>
									<td width={200}>
								All notices to Producer shall be addressed as follows
									</td>
									<td>
										<FormControl
											className={styles.tableForms}
											fullWidth
										>
											<Controller
												control={contractForm.control}
												key={`producer-address-cr-${contractForm.watch(
													'producerCompanyId.value'
												)}`}
												name='producerCompanyAddressId'
												render={({ field }) => (
													<>
														<LookAheadSelect
															{...field}
															placeholder={!props?.isPrint ? 'Select Address' : ''}
															key={`producer-address-${contractForm.watch(
																'producerCompanyId.value'
															)}-${contractForm.watch(
																'producerCompanyEmailId'
															)}-${contractForm.watch(
																'producerCompanyPhoneId'
															)}`}
															filterProperty='Line1'
															sortField='IsPrimary'
															sortDescending={true}
															onOptionLoaded={(options) => {
																if(options?.length && !contractForm.watch('producerCompanyAddressId.value')){
																	contractForm.setValue('producerCompanyAddressId', options[0], { shouldDirty: true});
																	contractForm.trigger('producerCompanyAddressId', { shouldFocus: true});	
																	onAddressChange(contractForm.watch('producerCompanyAddressId.value') || '', 'producer');																					
																}
															}}
															APICaller={(filterModel) =>
																getAllAddressByCompany(
																	filterModel,
																	contractForm.watch(
																		'producerCompanyId.value'
																	) || ''
																)
															}
															optionGenerator={
																producerAddressOptionGenerator
															}
															isDisabled={
																!contractForm.watch(
																	'producerCompanyId.value'
																) || props?.isPrint
															}
															dropDownsize={50}
															isClearable={true}
															isSearchText={true}
															onChange={(e: any) => {
																field.onChange(e);
																contractForm.setValue('producerCompanyPhoneId', '', { shouldDirty: true});
																contractForm.trigger('producerCompanyPhoneId');
																contractForm.setValue('producerCompanyEmailId', '', { shouldDirty: true});
																contractForm.trigger('producerCompanyEmailId');
																onAddressChange(contractForm.watch('producerCompanyAddressId.value') || '', 'producer');
															}}
															cacheOptions={false}
															defaultOptions
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														/>
														{
															<Box sx={{mt:1.3}}>
																{(contractForm.watch('producerCompanyId.value')&& (!props.isPrint)) &&
																							<AddCompanyAddressInline
																								companyId={contractForm.watch('producerCompanyId.value')}
																								title = 'ADDRESS'
																								isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'ProducerCompany-address'|| false}
																								event={addressPopOver?.event}
																								onAddressCreated={(d) => onProducerAddressCreated(d)}
																								onClose={() => setAddressPopOver({ isOpen: false})}
																								onPopOverOpen={(e) => setAddressPopOver({
																									isOpen: true,
																									event: e?.currentTarget,
																									for: 'ProducerCompany-address'
																								})}
																								isPersistent = {true} />
																}
															</Box>
														}
													</>
												)}
											/>
										</FormControl> 
									</td>
								</tr>
							</table>
						</div>	
						<br></br>
						<p><b>This contract is subject to all of the terms and conditions of the SAG-AFTRA Audio Recorded Commercials Contract. </b></p>
						<br></br>
						<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
Employer of Record for income tax and unemployment insurance purposes is:<u>Highland Talent Payments Inc. {contractForm.watch('payrollVendorAddressId.label') ? contractForm.watch('payrollVendorAddressId.label'): '5885 Landerbrook Dr., Cleveland, OH 44124'}</u>
						</div>
						<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
PRODUCER (name of Company) 
							<FormControl
								className={styles.tableForms}
								style={{minWidth:'400px'}}
							>
								<Controller
									key={`producer-name-${contractForm.watch(
										'producerCompanyId.value'
									)}`}
									control={contractForm.control}
									name='producerPrintName'
									render={({ field }) => (
										<>
											<NabuTextbox
												key={`producer-name-${contractForm.watch(
													'producerCompanyId.value'
												)}`}
												{...field}
												placeholder={!props?.isPrint ? 'Producer' : ''}
												disabled={true}
												value={contractForm.watch('producerCompanyId.label')}
											/>
										</>
									)}
								/>
							</FormControl> 
						</div>

						<br></br>
						<div className='keep-together'>
							<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
								<tr>
									<td width={100} style={{width:'100px'}}>
								By 
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}><NabuTextbox placeholder="Signature" disabled={true} /></FormControl> 
									</td>
									<td width={100} style={{width:'110px'}}>
								PERFORMER
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}><NabuTextbox placeholder="Signature" disabled={true} /></FormControl> 
									</td>
								</tr>
								<tr>
									<td width={100} style={{width:'100px'}}>
								Producer Email 
									</td>
									<td>
										<FormControl
											className={styles.tableForms}
											fullWidth
										>
											<Controller
												key={`select-em-${contractForm.watch(
													'producerCompanyAddressId.value'
												)}-${contractForm.watch('producerCompanyEmailId')}`}
												control={contractForm.control}
												name='producerCompanyEmailId'
												defaultValue=''
												render={({ field }) => (
													<>
														<NabuSelect
															{...field}
															key={`select-em-${contractForm.watch(
																'producerCompanyAddressId.value'
															)}-${contractForm.watch('producerCompanyEmailId')}`}
															placeholder={!props?.isPrint ? 'Select Email' : ''}
															convertValueToUpperCase={false}
															enableEmptySelection={true}
															disabled={props?.isPrint || !contractForm.watch('producerCompanyAddressId.value')}
															options={getCompanyEmailOptions(contractForm.watch('producerCompanyAddressId.value'))}
															handleDefaultSelection={() => !contractForm.watch('producerCompanyEmailId')}
														/>
														{
															<Box>
																{(contractForm.watch('producerCompanyAddressId.value') && (!props.isPrint)) &&
																						<AddEmailInline
																							formData={{ ...new EmailFormModel()}}
																							isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Producer-email-address'|| false}	
																							onSubmit={onProducerEmailInlineSubmit}
																							onClose={closeEmailInlinePopOver}
																							title='Email address'
																							onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Producer-email-address')}
																							event={emailPopOver?.event}
																						/>
																}
															</Box>	
														}
													</>
												)}
											/>
										</FormControl>
									</td>
									<td width={110} style={{width:'110px'}}>
								Performer Email
									</td>
									<td>
										<FormControl
											className={styles.emaillowerCase}
											fullWidth
										>
											<Controller
												control={contractForm.control}
												key={`select-email-ctr${contractForm.watch(
													'performerEmailId'
												)}-${contractForm.watch(
													'performerId.value'
												)}`}
												name='performerEmailId'
												defaultValue=''
												render={({ field }) => (
													<>
														<NabuSelect
															{...field}
															key={`select-email-${contractForm.watch(
																'performerEmailId'
															)}-${contractForm.watch(
																'performerId.value'
															)}`}
															defaultCase={true}
															placeholder={!props?.isPrint ? 'Select Email' : ''}
															convertValueToUpperCase={false}
															enableEmptySelection={true}
															disabled={props?.isPrint || !contractForm.watch('performerId.value')}
															options={getPerformerEmailsOptions()}
															handleDefaultSelection={() => !contractForm.watch('performerEmailId')}
														/>
														{
															<Box sx={{mt:0}}>
																{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																						<AddEmailInline
																							formData={{ ...new EmailFormModel()}}
																							isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Performer-email-address'|| false}	
																							onSubmit={onPerformerEmailInlineSubmit}
																							onClose={closeEmailInlinePopOver}
																							title='Email address'
																							onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Performer-email-address')}
																							event={emailPopOver?.event}
																						/>
																}
															</Box>	
														}
													</>
												)}
											/>
										</FormControl> 
									</td>
								</tr>

							</table>
						</div>
						<br></br>
						<div className="keep-together">
							<p><b>The Performer has the right to consult with his/her representative or AFTRA before signing this contract</b></p>

							<br></br>
							<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
						Performer hereby certifies that he or she is 21 years of age or over. (If under 21 years of age, this contract must be signed below by a parent or guardian.)
I, the undersigned, hereby state that I am the <FormControl
									className={styles.tableForms}
									style={{minWidth:'300px'}}
								>
									<Controller
										control={contractForm.control}
										name='guardian'
										render={({ field }) => (
											<>
												<NabuTextbox
													{...field}
													placeholder={!props?.isPrint ? '(Mother,Father,Guardian)' : ''}
													disabled={props?.isPrint}
												/>
											</>
										)}
									/>
								</FormControl>   of the above name Performer and do hereby consent and give my
permission to this agreement.
							</div>
						</div>
						<br></br>

						<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
							<tr>
								<td colSpan={4} style={{ background: '#515151', color: '#FFF' }}>
									<p><b>SPECIAL PROVISIONS </b></p>
								</td>
							</tr>
							<tr>
								<td colSpan={4}>
									{
										props?.isPrint ? (
											<p className={styles.descriptionPrintText}>{contractForm.watch('specialProvisions')}</p>
										) : (<div className={styles.textareaSection}> 
											<Controller
												control={contractForm.control}
												name='specialProvisions'
												defaultValue=''
												render={({ field }) => (
													<NabuBaseTextArea
														className={styles.specialProvision}
														data-cy='job_name_textbox'
														{...field}
														rows={5}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'SPECIAL PROVISIONS' : ''}
													/>
												)}
											/>
										</div> )
									}
									<p>Performer acknowledges that he or she has read all the terms and conditions in the Special Provisions above and hereby agrees thereto.</p>
								</td>
							</tr>
							
						</table>
						<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`} style={{borderTop:'0px'}}>
							<tr>
								<td width={150} style={{width:'150px'}}>
								Performer’s Signature
								</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<NabuTextbox placeholder="Signature"
											disabled={props?.isPrint} />
									</FormControl> 
								</td>
								<td width={180} style={{width:'180px'}}>
								Social Security Number
								</td>
								<td>
									<FormControl
										className={styles.tableForms}
									>
										<Controller
											control={contractForm.control}
											name='performerSSN'
											render={({ field }) => (
												<>
													<input
														{...field}
														placeholder={!props?.isPrint ? 'SSN' : ''}
														disabled={props?.isPrint}
														value={getPerformerSSN()}
													/>
												</>
											)}
										/>
									</FormControl>  
								</td>
							</tr>
						</table>

						<br></br>
						<div className='keep-together'>
							<h3 style={{textAlign:'center'}}><b>IMPORTANT PROVISIONS ON PAGE 2. PLEASE READ CAREFULLY.</b></h3>
							<h5 style={{textAlign:'center'}}>STANDARD SAG-AFTRA EMPLOYMENT CONTRACT FOR AUDIO COMMERCIALS</h5>
							<h5 style={{textAlign:'center'}}>STANDARD PROVISIONS</h5>
							<ol>
								<li>
									<b>THEATRICAL/INDUSTRIAL USE (Strike the paragraph below if such rights are not granted by Performer)</b><br></br>
									<p>Producer shall have the right to the commercial(s) produced hereunder for Theatrical & Industrial use as defined and
for the period permitted in the SAG-AFTRA Audio Recorded Commercials Contract, for which Producer shall pay
Performer not less than the additional compensation therein provided.</p>
									<br></br>
								</li>
								<li>
									<b> ARBITRATION</b>
									<p>All disputes and controversies of every kind and nature arising out of or in connection with this Contract shall be
								subject to arbitration as provided in Section 64 of the SAG-AFTRA Audio Recorded Commercials Contract.</p>
									<br></br>
								</li>
								<li>
									<b>PRODUCER&apos;S RIGHTS</b>
									<p>Performer acknowledges that Performer has no right, title or interest of any kind or nature whatsoever in or to the
								commercial(s). A role owned or created by Producer belongs to Producer and not to Performer</p>
									<br></br>
								</li>
								<li>
									<b>CONFIDENTIALITY</b>
									<p>&apos;Confidential Information&apos; means trade secrets, confidential data, and other non-public confidential proprietary
information (whether or not labeled as confidential) including any and all financial terms of and products involved in
the production and any and all scripts whether communicated orally, in written form, or electronically. Confidential
information does not include information that was lawfully in Performer&apos;s possession prior to being disclosed in
connection with the employment of Performer, is now, or hereafter becomes generally known to the public, or that
Performer rightfully obtained without restriction from a third party. Performer acknowledges that Performer has and
will become aware of certain Confidential Information. Unless otherwise required by law, Performer agrees that,
without Producer&apos;s prior written approval, Performer shall hold such Confidential Information in the strictest
confidence and that Performer will not disclose such Confidential Information to anyone (other than Performer&apos;s
representatives in the course of their duties to Performer, which representatives shall be bound by the same
restrictions as set forth in this Agreement) or utilize such Confidential Information for Performer&apos;s benefit or for the
benefit of a third party. Notwithstanding the foregoing, nothing herein shall prohibit Performer from disclosing
Confidential Information concerning Performer&apos;s wages, hours, and other terms and conditions of employment as that
term is defined under Section 7 of the National Labor Relations Act. For clarity, except as set forth above, Producer
may not demand or request that Performer execute any non-disclosure agreement that has not been approved in
advance and in writing by the Union. This provision is not intended to supersede any confidentiality provisions in
celebrity agreements.</p>
									<br></br>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</ContractMutationWrapper>
			{/* <TalentDetailsForm handleTalentDetailsForm={(value) => handleTalentDetailsForm(value)} noOfTalentsSelected={noOfTalentsSelected} setNoOfTalentsSelected={(value) => setNoOfTalentsSelected(value)} performerCategory={SagAftraAudioCommercialsPerformerTypeOptions} /> */}
		</>
	);
};

export default SagAftraForAudioCommercialsHT;
