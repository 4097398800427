import NabuButton from '@/common/components/atoms/button/button';
import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import LookAheadSelect from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import NabuTimePicker from '@/common/components/molecules/timepicker/timePicker';
import AddCompanyAddressInline from '@/common/components/templates/pages/add/addCompanyAddressInline';
import AddCompanyInline from '@/common/components/templates/pages/add/addCompanyInline';
import { IPopOverState } from '@/common/components/templates/pages/add/addNewAsset';
import AddPersonInline from '@/common/components/templates/pages/add/addPersonInline';
import {
	CONTRACT_REF_ID,
	ContractStatus,
	ContractStatusOptions,
} from '@/common/constants/constants';
import { OPERATORS } from '@/common/enums';
import useNotification from '@/common/hooks/useNotification';
import useScrollToField from '@/common/hooks/useScrollToField';
import {
	CompanyMainType,
	ICompanyAddress,
	ICompanyRecord,
} from '@/common/types/company';
import {
	IBaseContract,
	IContractNonUnionForm,
	IPacketControlForm,
} from '@/common/types/contract';
import { IFilterOption } from '@/common/types/filterModel';
import {
	displayAddress,
	displayOnlyEmail,
	displayPhoneNumberWithoutLabel,
	getBaseUrl,
	getCompanyDisplayName,
	getLocaleDate,
	getLocaleDateTime,
	getNotificationText,
	getPersonFullName,
	mutateContractAdditionalFormsData,
	sortEmailOrPhoneNumberForDropDown,
} from '@/common/utils/utils';
import styles from '@/modules/contracts/components/addContracts.module.scss';
import {
	getAllAddressByCompany,
	getAllProducts,
	getCompanyBasicDetails,
} from '@/services/company';
import {
	addContractNonUnion,
	addMultipleContract,
	updateContractNonUnion,
} from '@/services/contract';
import { IPersonRecord } from '@/services/models/person';
import {
	GetByWithFullNameSearchForBasicDetail,
	getAgentInfo,
	getAllAddressByPerson,
} from '@/services/person';
import store from '@/store';
import { updateContractFormState } from '@/store/contract/reducer';
import { yupResolver } from '@hookform/resolvers/yup';
import { CopyAll, NoteAdd, Save } from '@mui/icons-material';
import {
	Box,
	Checkbox,
	FormControl,
	FormHelperText,
	FormLabel,
	Grid,
	ListItemButton,
	ListItemText,
} from '@mui/material';
import { HttpStatusCode } from 'axios';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { ContractFormGeneralSchema } from '../contarctFormSchema';
import ContractCAWageTheftPreventionForm from '../contractCAWageyTheftPreventionForm';
import ContractMinorTrustForm from '../contractMinorTrustForm';
import ContractNYWageTheftPreventionForm from '../contractNYWageTheftPreventionForm';
import ContractW4ShortForm from '../contractW4ShortForm';
import ContractW8BenShortForm from '../contractW8BenShortForm';
import ContractW9BenShortForm from '../contractW9BenShortForm';
import TalentDetailsForm from '../talentDetailsForm';
import { IContractFormState } from '../unionForms/standardSagAftraEmployment2022';

interface Props {
	mainForm?: UseFormReturn<IBaseContract, any, undefined>;
	onCancel?: VoidFunction;
	onSave?: CallableFunction;
	handlePrintStateOfContract?: CallableFunction;
	initialValue?: IContractNonUnionForm;
	isPrint?: boolean;
	isEdit?: boolean;
	contractId?: string;
	defaultValue?: IBaseContract,
	address?: ICompanyAddress[];
	people?: IPersonRecord[];
}

// eslint-disable-next-line react/display-name
const NonUnionPerAgrmntOffCamPri = forwardRef((props: Props, ref) => {
	const { scroll } = useScrollToField();
	const [params, setParams] = useSearchParams();
	useImperativeHandle(ref, () => ({
		handleContractNonUnionPerAgrmntOffCamPriFormCancel: (
			data: IContractNonUnionForm,
			statusValue: string
		) => {
			if (data) {
				const submitData = {
					...data,
					status: statusValue ? statusValue : ContractStatus.Draft,
					contractNonUnionPerAgrmntOffCamPri: undefined,
					payrollVendorId: data?.payrollVendorId?.value,
					performerId: data?.performerId?.value,
					advertiserId: data?.advertiserId?.value,
					adAgencyId: data?.adAgencyId?.value,
					productId: data?.productId?.value,
					performerAddressId: data?.performerAddressId?.value,
					recordingCompanyId: data?.recordingCompanyId?.value,
					adAgencyAddressId: data?.adAgencyAddressId?.value,
					brandId:data?.brandId?.value,
					productionCompanyId:data?.productionCompanyId?.value
				};
				editContractDetails(submitData);
			}
		},
	}));
	
	const START_DATE = undefined;
	const END_DATE = undefined;
	const LUNCH_START_DATE = undefined;
	const LUNCH_END_DATE = undefined;

	useEffect(() => {
		if(props?.defaultValue?.advertiserId)
			contractForm.setValue('advertiserId', props?.defaultValue?.advertiserId, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.advertiserId]);

	const notification = useNotification();

	const contractFormValues = props?.initialValue as any;

	const contractForm = useForm<IContractNonUnionForm>({
		defaultValues: !props?.initialValue
			? {
				...(props?.mainForm?.getValues() || {}),
				...(props?.defaultValue || {}),
			}
			: {...(props?.initialValue),
				performerAddressId:contractFormValues?.contractNonUnion?.performerAddressData?.id,
				performerEmailId:undefined,
				talentAgencyId:undefined,
				talentAgencyAddressId:undefined,
				talentAgencyEmailId:undefined,
				talentAgencyPhoneId:undefined,
			},
		resolver: yupResolver(ContractFormGeneralSchema()),
		shouldFocusError: false
	});
	const [addressPopOver, setAddressPopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const packetControlForm = useForm<IPacketControlForm>({
		defaultValues: {
			isW4shortForm: !!contractForm.watch('contractW4Form.firstAndMiddleName'),
			isW8BenShort: !!contractForm.watch('contractW8BenShortForm'),
			isW9BenShort: !!contractForm.watch('contractW9BenShortForm'),
			isMinorTrust: !!contractForm.watch('contractMinorTrustForm'),
			isNYTheftPreventionForm: !!contractForm.watch(
				'contractNYTheftPreventionForm'
			),
			isCATheftPreventionForm: !!contractForm.watch(
				'contractCATheftPreventionForm'
			),
		},
	});

	const [personOptions, setPersonOptions] = useState<IPersonRecord[]>(
		props?.people || []
	);
	const [addressOptions, setAddressOptions] = useState<ICompanyAddress[]>(
		props?.address || []
	);
	const [contractFormState, setContractFormState] =
		useState<IContractFormState>({});

	const AD_AGENCY_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'AD AGENCY',
		},
	];

	const ADVERTISER_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'ADVERTISER',
		},
	];

	const PRODUCT_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.NOT_EQUAL,
			property: 'ProductName',
			value: '',
		},
	];

	const VENDOR_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'VENDOR',
		},
	];
	

	// helper functions
	const getPerformerFilter = (): IFilterOption[] => {
		const DEFAULT_FILTER: IFilterOption = {
			value: JSON.stringify(['TALENT']),
			property: 'PersonType',
			operator: OPERATORS.IN,
			isCaseSensitive: false,
		};
		return [DEFAULT_FILTER];
	};

	const handlePackageNotification = (key: keyof IPacketControlForm) => {
		notification.notify({
			message: `Form ${
				packetControlForm.watch(key) ? 'Added to' : 'Removed from'
			} Packet.`,
			type: 'info',
		});
	};

	const handleStateOptions = (
		responseData: any[],
		type: 'PERSON' | 'ADDRESS'
	) => {
		const cb = {
			PERSON: setPersonOptions,
			ADDRESS: setAddressOptions,
		}[type];

		if (responseData && Array.isArray(responseData)) {
			cb((prev) => {
				const oldFilter = prev?.filter(
					(d) => !responseData?.some((r) => r?.id === d?.id)
				);
				return [...oldFilter, ...responseData];
			});
		}
	};

	const companyOptionGenerator = (responseData: ICompanyRecord[]) => {
		const address = responseData?.reduce((acc, curr) => {
			if (curr?.addresses?.length) {
				acc.push(...(curr?.addresses || []));
			}
			return acc;
		}, [] as ICompanyAddress[]);

		handleStateOptions(address, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));
	};

	
	const onPayrollVendorAddressCreated = (createdAddress: ICompanyAddress) => {
		contractForm.setValue('payrollVendorAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const companyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		})) || [];
	};

	const performerOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');
		return responseData?.map((d) => ({
			value: d?.id,
			label: getPersonFullName(d?.personName),
		}));
	};

	const adAgencyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const productOptionGenerator = (responseData) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: d?.productName?.toUpperCase(),
		}));
	};

	const getPhoneNumberOptions = (addressId: string = '') => {
		const unsortedPhoneNumbers =
			addressOptions?.find((a) => a?.id === addressId)?.phoneNumbers || [];
		
		const phoneNumbers = sortEmailOrPhoneNumberForDropDown(unsortedPhoneNumbers);
		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	const getPerformerEmailsOptions = () => {
		const unsortedEmails =
			personOptions?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.emails || [];
		
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const getCompanyEmailOptions = (addressId?: string) => {
		const unsortedEmails =
			addressOptions?.find((a) => a?.id === addressId)?.emails || [];
		
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const onClickSnippetBtn = () => {
		setContractFormState((prev) => ({
			isPacketOpen: !prev?.isPacketOpen,
		}));
	};

	const handleFormClose = () => {
		contractForm?.reset();
		props?.onCancel && props?.onCancel();
	};

	const { mutate: addContractDetails, isLoading: isAddingContract } =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addContractNonUnion(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const { mutate: editContractDetails, isLoading: isEditingContract } =
		useMutation({
			mutationKey: ['contract-edit'],
			mutationFn: (payload: any) =>
				updateContractNonUnion(props?.contractId || '', payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'UPDATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'UPDATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const handleContractSubmission = async (
		data: IContractNonUnionForm,
		handleMultipleContractAdd?: CallableFunction
	) => {
		if (!packetControlForm.watch('isW4shortForm')) {
			data.w4FormId = undefined;
			data.contractW4Form = undefined;
		}
		if (!packetControlForm.watch('isW8BenShort')) {
			data.w8BenShortFormId = undefined;
			data.contractW8BenShortForm = undefined;
		}
		if (!packetControlForm.watch('isW9BenShort')) {
			data.w9BenShortFormId = undefined;
			data.contractW9BenShortForm = undefined;
		}
		if (!packetControlForm.watch('isMinorTrust')) {
			data.minorTrustFormId = undefined;
			data.contractMinorTrustForm = undefined;
		}
		if (!packetControlForm.watch('isNYTheftPreventionForm')) {
			data.nYTheftPreventionFormId = undefined;
			data.contractNYTheftPreventionForm = undefined;
		}
		if (!packetControlForm.watch('isCATheftPreventionForm')) {
			data.cATheftPreventionFormId = undefined;
			data.contractCATheftPreventionForm = undefined;
		}

		try {
			const notify = (message: string) => {
				notification.notify({
					message,
					type: 'error',
				});
			};
			// NOTE: These linked form's handled separately because the payload size is huge.
			const updatedContractIds = await mutateContractAdditionalFormsData(
				data,
				data?.performerId?.value || '',
				notify
			);
			data.w4FormId = updatedContractIds?.w4FormId;
			data.w8BenShortFormId = updatedContractIds?.w8BenShortFormId;
			data.w9BenShortFormId = updatedContractIds?.w9BenShortFormId;
			data.minorTrustFormId = updatedContractIds?.minorTrustFormId;
			data.nYTheftPreventionFormId =
				updatedContractIds?.nYTheftPreventionFormId;
			data.cATheftPreventionFormId =
				updatedContractIds?.cATheftPreventionFormId;

		} catch (error) {
			notification.notify({
				message: getNotificationText('Form', 'UPDATE', true),
				type: 'error',
			});
		} finally {
			data.contractW4Form = undefined;
			data.contractW8BenShortForm = undefined;
			data.contractW9BenShortForm = undefined;
			data.contractMinorTrustForm = undefined;
			data.contractNYTheftPreventionForm = undefined;
			data.contractCATheftPreventionForm = undefined;

			const mainFormValues = props?.mainForm?.getValues();
			const addMainFormValues = !props?.isEdit ? {
				contractType: mainFormValues?.contractType,
				contractDisplayName: mainFormValues?.contractDisplayName,
				unionType: mainFormValues?.unionType,
				unionAgreement: mainFormValues?.unionAgreement,
				payrollVendorAddressId: mainFormValues?.payrollVendorAddressId || '',
			} : {};

			const submitData = {
				...data,
				...addMainFormValues,
				payrollVendorAddressId: data?.payrollVendorAddressId?.value,
				contractNonUnion: undefined,
				payrollVendorId: data?.payrollVendorId?.value,
				performerId: data?.performerId?.value,
				advertiserId: data?.advertiserId?.value,
				adAgencyId: data?.adAgencyId?.value,
				productId: data?.productId?.value,
				performerAddressId: data?.performerAddressId?.value,
				recordingCompanyId: data?.recordingCompanyId?.value,
				adAgencyAddressId: data?.adAgencyAddressId?.value,
				talentAgencyAddressId: data?.talentAgencyAddressId?.value,
				talentAgencyId: data?.talentAgencyId?.value,
				talentAgencyStaffId: data?.talentAgencyStaffId?.value,
				productionCompanyId:data?.productionCompanyId?.value,
				brandId:data?.brandId?.value,
			};

			if (!props?.isEdit) {
				addContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					},
				});
			} else {
				editContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			} 
		}
	};

	const onSubmitError = (data) => {
		scroll(data);
		notification.notify({
			message: 'Please fill all required fields',
			type: 'error',
		});
	};

	const onSubmit = (data: IContractNonUnionForm) => {
		handleContractSubmission(data);
	};

	const { mutate: addMultipleContractDetails } = useMutation({
		mutationKey: ['contract-add'],
		mutationFn: (payload: any) => addMultipleContract(payload),
		onSuccess: (res) => {
			if (res?.status === HttpStatusCode.Ok) {
				const baseURL = getBaseUrl(window.location.href);
				const contractIds: any = res?.data || [];
				const urls =
					contractIds.map((contract) => {
						return `${baseURL}/#/contracts/${contract}/contract-view?isEdit=true`;
					}) || [];

				urls.forEach((url) => {
					const a = document.createElement('a');
					a.href = url;
					a.target = '_blank';
					const evt = document.createEvent('MouseEvents');
					evt.initMouseEvent(
						'click',
						true,
						true,
						window,
						0,
						0,
						0,
						0,
						0,
						false,
						false,
						false,
						false,
						0,
						null
					);
					a.dispatchEvent(evt);
				});
				notification.notify({
					message: getNotificationText('Contract', 'CREATE'),
					type: 'success',
				});
			} else
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
		},
		onError: () => {
			notification.notify({
				message: getNotificationText('Contract', 'CREATE', true),
				type: 'error',
			});
		},
	});

	const [contractFormData, setContractFormData] =
		useState<IContractNonUnionForm>();

	const [noOfTalentsSelected, setNoOfTalentsSelected] = useState(false);

	const handleSaveAndDuplicate = (data: IContractNonUnionForm) => {
		setNoOfTalentsSelected(true);
		setContractFormData(data);
	};

	const handleTalentDetailsForm = (data: any) => {
		setNoOfTalentsSelected(false);
		const handleMultipleContractAdd = (payloadData) => {
			if(data) {
				const contractAddModels = data?.talentRecords?.map((talent, index) => {
					const submitData = {
						...payloadData,
						id: undefined,
						partName: talent?.role,
						talentAgencyId: talent?.agent?.value,
						performerId: talent?.talentName?.value,
						contractDisplayName: `${payloadData?.contractDisplayName}_copy_${index+1}`,
						compensation: talent?.compensation,
						performerAddressId:undefined,
						performerEmailId:undefined,
						talentAgencyAddressId:undefined,
						talentAgencyPhoneId:undefined,
						talentAgencyEmailId:undefined
					};
					return submitData;
				});
				const multipleContractData = {
					contractAddNonUnionModels: contractAddModels,
				};
				addMultipleContractDetails(multipleContractData);
			}
		};
		contractFormData &&
			handleContractSubmission(contractFormData, handleMultipleContractAdd);
	};

	const isMinor = () => {
		return personOptions?.find(
			(a) => a?.id === contractForm.watch('performerId.value')
		)?.isMinor;
	};

	return (
		<>
			<div className={props?.isPrint ? styles.formWrapperPreview : ''}>
				<form onSubmit={contractForm.handleSubmit(onSubmit, onSubmitError)}>
					<div className={styles.packetTemplate} id='form'>
						<Grid item container columnSpacing={3}>
							{contractFormState?.isPacketOpen && (
								<Grid item sm={12} md={4} lg={3}>
									<div className={styles.snippetSection}>
										<Grid item container columnSpacing={3}>
											<Grid item md={12}>
												<h3 className={styles.pocketTitle}>
													{' '}
												Government & Payroll forms
												</h3>
											</Grid>
										</Grid>
										<div className={styles.contractFormBlockSnippet}>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW4shortForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW4shortForm');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW4shortForm');
																		}}
																	/>{' '}
																	<span>W4- short version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW8BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW8BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW8BenShort');
																		}}
																	/>
																	<span>W8 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW9BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW9BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW9BenShort');
																		}}
																	/>
																	<span>W9 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isMinorTrust'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isMinorTrust');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isMinorTrust');
																		}}
																	/>
																	<span>Minor Trust Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isNYTheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isNYTheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isNYTheftPreventionForm'
																			);
																		}}
																	/>
																	<span>NY Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isCATheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isCATheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isCATheftPreventionForm'
																			);
																		}}
																	/>
																	<span>CA Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
										</div>
									</div>
								</Grid>
							)}
							<Grid
								item
								sm={12}
								md={4}
								lg={!contractFormState?.isPacketOpen ? 12 : 9}
							>
								<div
									className={
										!contractFormState?.isPacketOpen
											? undefined
											: styles.scrollFIle
									}
								>
									<div className={styles.contractFormBlockPacket} id='contract-form-block-packet'>
										<div className={styles.contractFormBlock}>
											{props?.isEdit && (
												<div className={styles.formFiledsBlock}>
													<Grid container item columnSpacing={3}>
														<Grid item md={6} lg={3}>
															<FormControl fullWidth>
																<FormLabel>
															Contract Name<sup>*</sup>
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='contractDisplayName'
																	render={({ field }) => (
																		<>
																			<NabuTextbox
																				{...field}
																				placeholder={
																					!props?.isPrint ? 'Contract Name' : ''
																				}
																			/>
																		</>
																	)}
																/>
																{contractForm.formState?.errors?.contractDisplayName
																	?.message && (
																	<FormHelperText error={true}>
																		{
																			contractForm.formState.errors
																				?.contractDisplayName?.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={3}>
															<FormControl fullWidth>
																<FormLabel>
															Payroll Vendor <sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='payrollVendorId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				{...field}
																				placeholder={
																					!props?.isPrint ? 'Payroll Vendor' : ''
																				}
																				filterProperty='CompanyNames.Name'
																				APICaller={getCompanyBasicDetails}
																				optionGenerator={companyOptionGenerator}
																				defaultFilter={VENDOR_FILTER}
																				isClearable={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																				}}
																				cacheOptions={false}
																				defaultOptions
																			/>
																		</>
																	)}
																/>
																{contractForm.formState.errors?.payrollVendorId?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors
																				?.payrollVendorId.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={3}>
															<FormControl fullWidth>
																<FormLabel>
															Payroll Vendor Address
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	key={`payroll-address-${contractForm.watch(
																		'payrollVendorAddressId.value'
																	)}`}
																	name='payrollVendorAddressId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				{...field}
																				placeholder={'Select Address'}
																				key={`payroll-vendor-address-${contractForm.watch(
																					'payrollVendorAddressId.value'
																				)}-${contractForm.watch('payrollVendorId.value')}`}
																				filterProperty='Line1'
																				APICaller={(filterModel) =>
																					getAllAddressByCompany(
																						filterModel,
																						contractForm.watch('payrollVendorId.value') || ''
																					)
																				}
																				optionGenerator={
																					companyAddressOptionGenerator
																				}
																				isDisabled={!contractForm.watch('payrollVendorId.value')}
																				dropDownsize={50}
																				isClearable={true}
																				isSearchText={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																					contractForm.trigger('payrollVendorAddressId.value');
																				}}
																				cacheOptions={false}
																				defaultOptions
																			/>
																			{contractForm.watch('payrollVendorId.value') && <div style={{float:'right'}}>
																				<Box sx={{mt:0.9}}>
																					<AddCompanyAddressInline
																						companyId={contractForm.watch('payrollVendorId.value')}
																						title='ADDRESS'
																						isOpen={addressPopOver?.isOpen}
																						event={addressPopOver?.event}
																						onAddressCreated={(d) => onPayrollVendorAddressCreated(d)}
																						onClose={() => setAddressPopOver({ isOpen: false })}
																						onPopOverOpen={(e) => setAddressPopOver({
																							isOpen: true,
																							event: e?.currentTarget,
																						})}
																						isPersistent={true} />
																				</Box>
																			</div>}
																		</>
																	
																	)}
																/>
																{contractForm.formState.errors?.payrollVendorAddressId?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors
																				?.payrollVendorAddressId.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={3}>
															<FormControl fullWidth>
																<FormLabel>
															Status
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='status'
																	render={({ field }) => (
																		<>
																			<NabuSelect
																				{...field}
																				placeholder={
																					!props?.isPrint ? 'Select Status' : ''
																				}
																				options={ContractStatusOptions || []}
																				convertValueToUpperCase={false}
																				onChange={(e) => field.onChange(e)}
																			/>
																		</>
																	)}
																/>
															</FormControl>
														</Grid>
													</Grid>
												</div>
											)}
											{/* Main Contract Form Start */}
											<>
												<div className={styles.contractFormBlock}>
													<div id={CONTRACT_REF_ID.MAIN_FORM} className={styles.bgWhite} style={{padding:'20px'}}>
														<table className={styles.contractForms} width={'100%'}>
															<tbody>
																<tr>
																	<h4>{contractForm.watch('contractId') ? `#${contractForm.watch('contractId')}` : ''}</h4>
																</tr>
																<tr>
																	<td align='center'>
																		<h2>
																		NON-UNION PERFORMER AGREEMENT
																			<br></br>
																			<span style={{ color: '#000' }}>
																			OFF CAMERA PRINCIPAL
																			</span>
																		</h2>{' '}
																	</td>
																</tr>
															</tbody>
														</table>
														<br></br>
														<table
															width={'100%'}
															border={1}
															cellPadding={0}
															cellSpacing={0}
															className={styles.contractFormsInner}
														>
															<tr>
																<td width={'50%'}>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>Agency: </span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				key={`adAgency-${contractForm.watch(
																					'adAgencyId.value'
																				)}`}
																				control={contractForm.control}
																				name='adAgencyId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																							data-cy='ad_agency'
																							{...field}
																							placeholder={
																								!props?.isPrint ? 'Ad-Agency' : ''
																							}
																							filterProperty='CompanyNames.Name'
																							APICaller={getCompanyBasicDetails}
																							optionGenerator={
																								companyOptionGenerator
																							}
																							defaultFilter={AD_AGENCY_FILTER}
																							isDisabled={props?.isPrint}
																							isClearable={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																								contractForm.setValue(
																									'adAgencyAddressId',
																									null,
																									{ shouldDirty: true }
																								);
																								contractForm.setValue(
																									'adAgencyPhoneId',
																									'',
																									{ shouldDirty: true }
																								);
																								contractForm.setValue(
																									'adAgencyEmailId',
																									'',
																									{ shouldDirty: true }
																								);
																							}}
																							cacheOptions={false}
																							defaultOptions
																						/>
																					</>
																				)}
																			/>
																			{contractForm.formState.errors?.adAgencyId?.message && (
																				<FormHelperText
																					error={true}
																					data-cy='errorHelper_ad_agency'
																				>
																					{
																						contractForm.formState?.errors?.adAgencyId.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																	{!props?.isPrint && (
																		<div className={styles.inlineAdd}>
																			<AddCompanyInline
																				title='Company'
																				companyType={{
																					mainType:
																					'AD AGENCY' as CompanyMainType,
																					subType: [],
																				}}
																			/>
																		</div>
																	)}
																</td>
																<td>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>
																		Agency Job#{' '}
																		</span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='agencyJobTitle'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						data-cy='job_name_textbox'
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={
																							!props?.isPrint ? 'Job' : ''
																						}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.agencyJobTitle?.message && (
																				<FormHelperText
																					data-cy='errorHelper-job-title-name'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.agencyJobTitle.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
															</tr>
															<tr>
																<td width={'50%'}>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>Client: </span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				key={`advertiser-${contractForm.watch(
																					'advertiserId.value'
																				)}-${contractForm.watch(
																					'productId.value'
																				)}`}
																				control={contractForm.control}
																				name='advertiserId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																							{...field}
																							placeholder={
																								!props?.isPrint ? 'Client' : ''
																							}
																							filterProperty='CompanyNames.Name'
																							APICaller={getCompanyBasicDetails}
																							optionGenerator={
																								companyOptionGenerator
																							}
																							defaultFilter={ADVERTISER_FILTER}
																							isDisabled={props?.isPrint}
																							isClearable={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																								contractForm.resetField(
																									'productId'
																								);
																							}}
																							cacheOptions={false}
																							defaultOptions
																						/>
																					</>
																				)}
																			/>
																			{contractForm.formState.errors?.advertiserId?.message && (
																				<FormHelperText
																					error={true}
																					data-cy='errorHelper_ad_agency'
																				>
																					{
																						contractForm.formState?.errors
																							?.advertiserId.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																	{!props?.isPrint && (
																		<div className={styles.inlineAdd}>
																			<AddCompanyInline title='Company' />
																		</div>
																	)}
																</td>
																<td>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>
																		Product:{' '}
																		</span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='productId'
																				render={({ field }) => (
																					<LookAheadSelect
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						{...field}
																						placeholder={
																							!props?.isPrint
																								? 'Select Product'
																								: ''
																						}
																						key={`product-${contractForm.watch(
																							'advertiserId.value'
																						)}`}
																						filterProperty='ProductName'
																						APICaller={(filterModel) =>
																							getAllProducts(
																								filterModel,
																								contractForm.watch(
																									'advertiserId.value'
																								)
																							)
																						}
																						optionGenerator={
																							productOptionGenerator
																						}
																						isDisabled={
																							!contractForm.watch(
																								'advertiserId.value'
																							) || props?.isPrint
																						}
																						defaultFilter={PRODUCT_FILTER}
																						dropDownsize={20}
																						isClearable={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																						}}
																						cacheOptions={false}
																						defaultOptions
																					/>
																				)}
																			/>
																			{contractForm.formState?.errors?.productId?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.productId?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
															</tr>
															<tr>
																<td width={'50%'}>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>
																		Recording Co:{' '}
																		</span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				key={`recordingCompany-${contractForm.watch(
																					'recordingCompanyId.value'
																				)}`}
																				control={contractForm.control}
																				name='recordingCompanyId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																							{...field}
																							placeholder={
																								!props?.isPrint
																									? 'Recording Co'
																									: ''
																							}
																							filterProperty='CompanyNames.Name'
																							APICaller={getCompanyBasicDetails}
																							optionGenerator={
																								companyOptionGenerator
																							}
																							isDisabled={props?.isPrint}
																							isClearable={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																							}}
																							cacheOptions={false}
																							defaultOptions
																						/>
																					</>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.recordingCompanyId?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.recordingCompanyId.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																	{!props?.isPrint && (
																		<div className={styles.inlineAdd}>
																			<AddCompanyInline title='Company' />
																		</div>
																	)}
																</td>
																<td>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>
																		Campaign Name:{' '}
																		</span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='campaignName'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						data-cy='job_name_textbox'
																						{...field}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors?.campaignName
																				?.message && (
																				<FormHelperText
																					data-cy='errorHelper-job-title-name'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.campaignName.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
															</tr>
															<tr>
																<td width={'50%'}>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>
																		Performer Name:{' '}
																		</span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																							data-cy='name_select'
																							{...field}
																							key={`performer-name-${contractForm.watch(
																								'performerId.value'
																							)}`}
																							isClearable={true}
																							placeholder={
																								!props?.isPrint
																									? 'Select Performer'
																									: ''
																							}
																							filterProperty='PersonName.FirstName'
																							APICaller={(
																								filterModel,
																								searchText
																							) =>
																								GetByWithFullNameSearchForBasicDetail(
																									filterModel,
																									searchText
																								)
																							}
																							isSearchText={true}
																							isDisabled={props?.isPrint}
																							optionGenerator={
																								performerOptionGenerator
																							}
																							defaultFilter={getPerformerFilter()}
																							onChange={(e: any) => {
																								field.onChange(e);
																								contractForm.trigger( 'performerEmailId' );
																								contractForm.setValue( 'performerEmailId', '', { shouldDirty: true } );
																								contractForm.trigger( 'performerEmailId' );
																								contractForm.setValue( 'printName', contractForm.watch( 'performerId.label' ), { shouldDirty: true } );
																								contractForm.setValue('talentAgencyId',null,{ shouldDirty: true });
																								contractForm.setValue('performerAddressId',null,{ shouldDirty: true });
																								contractForm.setValue('talentAgencyAddressId',null,{ shouldDirty: true });
																								contractForm.trigger('talentAgencyAddressId');
																								contractForm.setValue('talentAgencyEmailId', '',{ shouldDirty: true });
																								contractForm.setValue('talentAgencyPhoneId', '',{ shouldDirty: true });
																								contractForm.trigger('talentAgencyId');
																								contractForm.trigger('talentAgencyEmailId');
																								contractForm.trigger('talentAgencyPhoneId');
																							}}
																							cacheOptions={false}
																							defaultOptions
																						/>
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors?.performerId?.message && (
																				<FormHelperText
																					data-cy='errorHelper_first_session_date'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.performerId?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																	{!props?.isPrint && (
																		<div className={styles.inlineAdd}>
																			<AddPersonInline
																				title='Person'
																				onPersonCreated={() => ({})}
																				initialValues={{
																					personType: ['TALENT'],
																					isMinor: false,
																				}}
																			/>
																		</div>
																	)}
																</td>
																<td>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>
																		TV Spots:
																		</span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='tvSport'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						data-cy='tvSport_textbox'
																						{...field}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors?.tvSport
																				?.message && (
																				<FormHelperText
																					data-cy='errorHelper-tvSport'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.tvSport.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
															</tr>
															<tr>
																<td width={'50%'}>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>Role: </span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='partName'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors?.partName
																				?.message && (
																				<FormHelperText
																					data-cy='errorHelper-partName'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.partName.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
																<td>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>
																		Radio Spots:
																		</span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='radioSpot'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors?.radioSpot
																				?.message && (
																				<FormHelperText
																					data-cy='errorHelper-radioSpot'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.radioSpot.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
															</tr>
															<tr>
																<td width={'50%'}>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>
																		Session Workday (s):{' '}
																		</span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='sessionWorkDays'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.sessionWorkDays?.message && (
																				<FormHelperText
																					data-cy='errorHelper-sessionWorkDays'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.sessionWorkDays.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
																<td>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>
																		Location (City/State):
																		</span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='location'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors?.location
																				?.message && (
																				<FormHelperText
																					data-cy='errorHelper-location'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.location.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
															</tr>
														</table>
														<br></br>
														<table
															width={'100%'}
															border={1}
															cellPadding={0}
															cellSpacing={0}
															className={styles.contractFormsInner}
														>
															<tr style={{ background: '#808080' }}>
																<th className={styles.formTableBorder} colSpan={2}>
																	<div
																		style={{
																			display: 'flex',
																			justifyContent: 'space-around',
																		}}
																	>
																		<span>Time In</span> <span>Out</span>{' '}
																	</div>
																</th>
																<th className={styles.formTableBorder} colSpan={2}>
																	<div
																		style={{
																			display: 'flex',
																			justifyContent: 'space-around',
																		}}
																	>
																		<span>Lunch In</span> <span>Out</span>{' '}
																	</div>
																</th>
															</tr>
															<tr>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		{props?.isPrint ? (
																			<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch('timeIn') || '', 'hh:mm aa')}</p>
																		) :(<Controller
																			control={contractForm.control}
																			name='timeIn'
																			defaultValue={START_DATE}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/>)}
																		{!!contractForm.formState?.errors?.timeIn
																			?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors?.timeIn
																						?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		{props?.isPrint ? (
																			<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch('timeOut') || '', 'hh:mm aa')}</p>
																		) :(<Controller
																			control={contractForm.control}
																			name='timeOut'
																			defaultValue={END_DATE}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/>)}
																		{!!contractForm.formState?.errors?.timeOut
																			?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors?.timeOut
																						?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		{props?.isPrint ? (
																			<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch('lunchIn') || '', 'hh:mm aa')}</p>
																		) :(<Controller
																			control={contractForm.control}
																			name='lunchIn'
																			defaultValue={LUNCH_START_DATE}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/>)}
																		{!!contractForm.formState?.errors?.lunchIn
																			?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors?.lunchIn
																						?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		{props?.isPrint ? (
																			<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch('lunchOut') || '', 'hh:mm aa')}</p>
																		) :(<Controller
																			control={contractForm.control}
																			name='lunchOut'
																			defaultValue={LUNCH_END_DATE}
																			render={({ field }) => (
																				<NabuTimePicker
																					{...field}
																					ampm={true}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/>)}
																		{!!contractForm.formState?.errors?.lunchOut
																			?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors?.lunchOut
																						?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
														</table>
														<p>
															<em>
																<b>**see attached timecard for more details</b>
															</em>
														</p>

														<br></br>
														<table
															width={'100%'}
															border={1}
															cellPadding={0}
															cellSpacing={0}
															className={styles.contractFormsInner}
														>
															<tr>
																<td colSpan={2}>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>Term: </span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='term'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors?.term
																				?.message && (
																				<FormHelperText
																					data-cy='errorHelper-term'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors?.term
																							.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
															</tr>
															<tr>
																<td colSpan={2}>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>
																		Territory:{' '}
																		</span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='territory'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors?.territory
																				?.message && (
																				<FormHelperText
																					data-cy='errorHelper-territory'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.territory.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
															</tr>
															<tr>
																<td width={'50%'}>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>
																		Exclusivity:{' '}
																		</span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='exclusivity'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors?.exclusivity
																				?.message && (
																				<FormHelperText
																					data-cy='errorHelper-exclusivity'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.exclusivity.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
																<td>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>
																		Estimated First Air Date:{' '}
																		</span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('estimatedFirstAirDate')) || ''}</p>
																			) :(<Controller
																				control={contractForm.control}
																				name='estimatedFirstAirDate'
																				render={({ field }) => (
																					<NabuDatepicker
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={
																							!props?.isPrint
																								? 'Estimated First Air Date'
																								: ''
																						}
																					/>
																				)}
																			/>)}
																			{contractForm.formState.errors
																				?.estimatedFirstAirDate?.message && (
																				<FormHelperText
																					data-cy='errorHelper-estimatedFirstAirDate'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.estimatedFirstAirDate.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>
																		Radio Session fee:{' '}
																		</span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='radioSessionFee'
																				render={({ field }) => (
																					<input
																						{...field}
																						disabled={props?.isPrint}
																						min={0}
																						type='number'
																					/>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.radioSessionFee?.message && (
																				<FormHelperText
																					data-cy='errorHelper-radioSessionFee'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.radioSessionFee.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
																<td>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>
																		TV/Video Session fee:{' '}
																		</span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='tvVideoSessionFee'
																				render={({ field }) => (
																					<input
																						{...field}
																						disabled={props?.isPrint}
																						min={0}
																						type='number'
																					/>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.tvVideoSessionFee?.message && (
																				<FormHelperText
																					data-cy='errorHelper-tvVideoSessionFee'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.tvVideoSessionFee.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>
																		Use Fee: Radio Buyout Use fee:{' '}
																		</span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='radioBuyOutUseFee'
																				render={({ field }) => (
																					<input
																						{...field}
																						disabled={props?.isPrint}
																						min={0}
																						type='number'
																					/>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.radioBuyOutUseFee?.message && (
																				<FormHelperText
																					data-cy='errorHelper-radioBuyOutUseFee'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.radioBuyOutUseFee.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																	<p>
																	To be paid within 30 business days of first
																	use, only if performer makes final edit and if
																	used.
																	</p>
																</td>
																<td>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>
																		TV/Video Buyout Use Fee:{' '}
																		</span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='tvVideoBuyOutUseFee'
																				render={({ field }) => (
																					<input
																						{...field}
																						disabled={props?.isPrint}
																						min={0}
																						type='number'
																					/>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.tvVideoBuyOutUseFee?.message && (
																				<FormHelperText
																					data-cy='errorHelper-tvVideoBuyOutUseFee'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.tvVideoBuyOutUseFee.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
															</tr>
															<tr>
																<td colSpan={2}>
																	<p>
																		<b>Renewal Option:</b> Advertiser may extend
																	all above terms and conditions for a
																	non-consecutive term for an additional payment
																	of
																	</p>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>
																		Radio Buyout Use fee:{' '}
																		</span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='radioBuyOutUseFeeForRenewal'
																				render={({ field }) => (
																					<input
																						{...field}
																						disabled={props?.isPrint}
																						min={0}
																						type='number'
																					/>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.radioBuyOutUseFeeForRenewal
																				?.message && (
																				<FormHelperText
																					data-cy='errorHelper-radioBuyOutUseFeeForRenewal'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.radioBuyOutUseFeeForRenewal
																							.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '10px',
																		}}
																	>
																		<span style={{ flex: 'none' }}>
																		TV/Video Buyout Use Fee:{' '}
																		</span>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='tvVideoBuyOutUseFeeForRenewal'
																				render={({ field }) => (
																					<input
																						{...field}
																						disabled={props?.isPrint}
																						min={0}
																						type='number'
																					/>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.tvVideoBuyOutUseFeeForRenewal
																				?.message && (
																				<FormHelperText
																					data-cy='errorHelper-tvVideoBuyOutUseFeeForRenewal'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.tvVideoBuyOutUseFeeForRenewal
																							.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																	<div>
																		{
																			props?.isPrint ? (
																				<p className={styles.descriptionPrintText}>{contractForm.watch('renewalOptionDetail')}</p>
																			) : (<> 
																				<p style={{ marginTop: '4px' }}>
																					<Controller
																						control={contractForm.control}
																						name='renewalOptionDetail'
																						defaultValue='for an additional 1-year use period, only if exercised. Option can be exercised by Advertiser within the original term from first use and if talent remains in final edit. Renewal option to be available for a 5-year window from first use date. Agent’s fee will only be paid if talent is represented by agent.'
																						render={({ field }) => (
																							<textarea
																								{...field}
																								className={styles.specialProvision}
																								rows={3}
																								disabled={props?.isPrint}
																								placeholder={
																									!props?.isPrint
																										? 'Renewal Option Detail'
																										: ''
																								}
																							/>
																						)}
																					/>
																					{contractForm.formState.errors
																						?.renewalOptionDetail?.message && (
																						<FormHelperText
																							data-cy='errorHelper-renewalOptionDetail'
																							error={true}
																						>
																							{
																								contractForm.formState?.errors
																									?.renewalOptionDetail.message
																							}
																						</FormHelperText>
																					)}
																				</p>
																			</> )
																		}
																	</div>

																</td>
															</tr>
															<tr>
																<td colSpan={2}>
																	<p>
																		<b>Permitted Use:</b>
																		<div>
																			{
																				props?.isPrint ? (
																					<p className={styles.descriptionPrintText}>{contractForm.watch('permittedUseDetail')}</p>
																				) : (<> 
																					<Controller
																						control={contractForm.control}
																						name='permittedUseDetail'
																						defaultValue='All audio recordings to be used in any and all types of media, either known now or in the future including but not limited to broadcast, internet, radio, industrial, and new media including all lifts, edits, variations and cut downs.'
																						render={({ field }) => (
																							<textarea
																								{...field}
																								className={styles.specialProvision}
																								rows={3}
																								disabled={props?.isPrint}
																								placeholder={
																									!props?.isPrint
																										? 'Permitted Use Detail'
																										: ''
																								}
																							/>
																						)}
																					/>
																					{contractForm.formState.errors
																						?.permittedUseDetail?.message && (
																						<FormHelperText
																							data-cy='errorHelper-permittedUseDetail'
																							error={true}
																						>
																							{
																								contractForm.formState?.errors
																									?.permittedUseDetail.message
																							}
																						</FormHelperText>
																					)}
																				</> )
																			}
																		</div>
																	
																	</p>
																</td>
															</tr>
															<tr>
																<td colSpan={2}>
																	<p>
																		<b>Historical uses:</b>
																		<div>
																			{
																				props?.isPrint ? (
																					<p className={styles.descriptionPrintText}>{contractForm.watch('historicalUseDetail')}</p>
																				) : (<> 
																					<Controller
																						control={contractForm.control}
																						name='historicalUseDetail'
																						defaultValue=' Advertising materials may be used anywhere in the world by the Client and its Agency at sales meetings and for intra-company, research, award, publicity (including, without limitation, on Client’s Ad Agency ‘reel’ and website), file and reference purposes to be granted in perpetuity.'
																						render={({ field }) => (
																							<textarea
																								{...field}
																								className={styles.specialProvision}
																								rows={3}
																								disabled={props?.isPrint}
																								placeholder={
																									!props?.isPrint
																										? 'Historical Use Detail'
																										: ''
																								}
																							/>
																						)}
																					/>
																					{contractForm.formState.errors
																						?.historicalUseDetail?.message && (
																						<FormHelperText
																							data-cy='errorHelper-historicalUseDetail'
																							error={true}
																						>
																							{
																								contractForm.formState?.errors
																									?.historicalUseDetail.message
																							}
																						</FormHelperText>
																					)}
																				</> )
																			}
																		</div>
																	
																	</p>
																</td>
															</tr>
														</table>
														<br></br>
														<p>
														The Agency will administer performer payments on
														behalf of the Client though {contractForm.watch('payrollVendorId.label')}{' '}
														</p>
														<br></br>
														<p>
														For good and valuable consideration, receipt and
														sufficiency of which is hereby acknowledged, I
														hereby agree as follows: I am appearing as a
														performer in advertising material being produced by
														Agency and Client on the work schedule listed above.
														I hereby agree to be so recorded, and I grant to
														Agency and Client, and to other such persons as
														Agency or Client may designate from time to time,
														the absolute right and permission to use any such
														material, including, without limitation, my likeness
														as portrayed therein, during the Term in the
														Territory in accordance with the terms of Permitted
														Use.{' '}
														</p>
														<br></br>
														<p>
														I agree that I will not hold Agency, Client, or
														anyone who receives permission from either of them,
														responsible for any liability resulting from the use
														of my likeness and recordings (s) in accordance with
														the terms hereof, including what might be deemed to
														be misrepresentation of me, my character or my
														person due to distortion, optical illusion or faulty
														reproduction which may occur in the finished
														product. I hereby waive any right that I may have to
														inspect or approve the finished product or products
														or the advertising copy or other matter that may be
														used in connection therewith.
														</p>											
													</div>
												</div>

												<div id={CONTRACT_REF_ID.MAIN_FORM_PART_2} className={styles.contractFormBlock} style={{padding:'20px'}}>
													<div className={styles.bgWhite}>
														<p>
														I hereby agree to not post any advertising material
														or behind the scenes material to my social media or
														any other venue which can be viewed by the public
														without the express written consent of Client. I
														also agree that I shall abide by any restrictions
														placed by client on my use of the advertising
														material. I shall not use or disclose any of the
														Client and or Agency trade secrets or confidential
														information (including, without limitation, the
														terms of this agreement). Any advertising materials
														placed during the Term which remain on display or in
														circulation following the expiration of the Term
														shall not give rise to a claim against the Client
														and or Agency, provided, however that they shall not
														place any additional advertising materials produced
														hereunder in any media (other than as permitted
														herein) following the expiration of the Term.
														</p>
														<br />
														<p>
														I understand that I will not receive any other
														payments and my compensation is outlined above. I
														understand that all payments and notices to me shall
														be delivered to the address listed on this release.
														I also understand that it is my sole responsibility
														to inform the Agency and Client of any change of
														address in writing. If a payment does not reach me
														due to a change of address, Agency and Client will
														hold such payments until notified by me in writing
														of the new mailing address. Notices with respect to
														any provisions herein, including but not limited to
														notices to renew, extend or exercise any rights or
														options herein shall be deemed accepted by me if
														Agency and/or Client sent such notice to the address
														which I provided and is unable to reach me due to my
														failure to provide change of address notification to
														Agency and/or Client in writing.
														</p>
														<br></br>
														<p>
														During the Term and throughout the Territory, I will
														not accept employment in, nor authorize use of my
														name or voice in any advertising material, for a
														competitive product or service of Client, as defined
														above in the Exclusivity clause.
														</p>
														<br></br>
														<p>
														This agreement is governed by the laws of the State
														of California, and the parties agree to that
														jurisdiction if a dispute arises.
														</p>
														<br></br>
														<p>
														I warrant and represent that this agreement does not
														in any way conflict with any existing commitments on
														my part. Nothing herein will constitute any
														obligation on the licensed parties to make any use
														of any of the rights set forth herein.
														</p>
														<br></br>
														<p>
														I am over eighteen (18) years of age and have the
														right to make this agreement and am authorized to
														grant these rights.
														</p>
														<hr></hr>

														<table
															width={'100%'}
															border={0}
															cellPadding={0}
															cellSpacing={0}
															className={styles.contractFormsInner}
														>
															<tr>
																<td width={210}>Signature</td>
																<td width={400}>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<NabuTextbox disabled={true} />
																	</FormControl>
																</td>
																<td width={180}>Date:</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<NabuTextbox disabled={true} />
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td width={210}>Performer Print Name:</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			name='printName'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={
																						!props?.isPrint
																							? 'Performer Print Name'
																							: ''
																					}
																					value={contractForm.watch('performerId.label')}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.printName
																			?.message && (
																			<FormHelperText
																				data-cy='errorHelper-printName'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors
																						?.printName.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td width={180}>Performer Address</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			key={`select_Address_${contractForm.watch(
																				'performerId.value'
																			)}`}
																			name='performerAddressId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						{...field}
																						placeholder={
																							!props?.isPrint
																								? 'Select Address'
																								: ''
																						}
																						key={`performer-address-${contractForm.watch(
																							'performerId.value'
																						)}`}
																						filterProperty='Line1'
																						sortField='IsPrimary'
																						sortDescending={true}
																						onOptionLoaded={(options) => {
																							if(options?.length && !contractForm.watch('performerAddressId.value')){
																								contractForm.setValue('performerAddressId', options[0], { shouldDirty: true});
																								contractForm.trigger('performerAddressId', { shouldFocus: true});																						
																							}
																						}}
																						APICaller={(filterModel) =>
																							getAllAddressByPerson(
																								filterModel,
																								contractForm.watch(
																									'performerId.value'
																								) || ''
																							)
																						}
																						optionGenerator={
																							adAgencyAddressOptionGenerator
																						}
																						dropDownsize={50}
																						isDisabled={
																							props?.isPrint ||
																						!contractForm.watch(
																							'performerId.value'
																						)
																						}
																						isClearable={true}
																						isSearchText={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																						}}
																						cacheOptions={false}
																						defaultOptions
																					/>
																				</>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.performerAddressId?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState.errors
																						?.performerAddressId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td width={200}>Performer Email:</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			key={`select-email-${contractForm.watch(
																				'performerEmailId'
																			)}-${contractForm.watch(
																				'performerId.value'
																			)}`}
																			name='performerEmailId'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuSelect
																					{...field}
																					key={`select-phone-${contractForm.watch(
																						'performerEmailId'
																					)}-${contractForm.watch(
																						'performerId.value'
																					)}`}
																					placeholder={
																						!props?.isPrint ? 'Select Email' : ''
																					}
																					convertValueToUpperCase={false}
																					enableEmptySelection={true}
																					disabled={
																						props?.isPrint ||
																					!contractForm.watch(
																						'performerId.value'
																					)
																					}
																					options={getPerformerEmailsOptions()}
																					handleDefaultSelection={() => !contractForm.watch('performerEmailId')}
																				/>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.performerEmailId?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.performerEmailId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td width={180}>Talent Agency / Agent :</td>
																<td>
																	<div>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				key={`agency-${contractForm.watch('performerId.value')}`}
																				control={contractForm.control}
																				name='talentAgencyId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																							data-cy='ad_agency'
																							key={`agency-${contractForm.watch('performerId.value')}`}
																							{...field}
																							placeholder={
																								!props?.isPrint ? 'Ad-Agency' : ''
																							}
																							filterProperty='CompanyNames.Name'
																							APICaller={(filter) => {
																								filter.filters = filter.filters?.map((f) => f.property == 'CompanyNames.Name' ? {...f, value: f?.value?.trim() || '' } : f) || [];
																								return getAgentInfo(filter, contractForm.watch('performerId.value') || '');
																							}}
																							optionGenerator={
																								companyOptionGenerator
																							}
																							isClearable={true}
																							isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
																							onChange={(e: any) => {
																								field.onChange(e);
																								contractForm.setValue('talentAgencyAddressId', null, { shouldDirty: true } );
																								contractForm.trigger('talentAgencyAddressId' );
																								contractForm.setValue('talentAgencyEmailId', '', { shouldDirty: true } );
																								contractForm.setValue('talentAgencyPhoneId', '', { shouldDirty: true } );
																								contractForm.setValue('talentAgencyStaffId', null, { shouldDirty: true } );
																								contractForm.trigger('talentAgencyStaffId' );
																							}}
																							onOptionLoaded={(options) => {
																								if(options?.length && !contractForm.watch('talentAgencyId.value')){
																									contractForm.setValue('talentAgencyId', options[0], { shouldDirty: true});
																									contractForm.trigger('talentAgencyId', { shouldFocus: true});
																								}
																							}}
																							cacheOptions
																							defaultOptions
																						/>
																						{!props?.isPrint && (
																							<Box sx={{ mt: 0.5 }}>
																								<AddCompanyInline
																									title='Company'
																									companyType={{
																										mainType:
																									'AD AGENCY' as CompanyMainType,
																										subType: [],
																									}}
																								/>
																							</Box>
																						)}
																					</>
																				)}
																			/>
																			{contractForm.formState.errors?.talentAgencyId?.message && (
																				<FormHelperText
																					error={true}
																					data-cy='errorHelper_ad_agency'
																				>
																					{
																						contractForm.formState?.errors
																							?.talentAgencyId.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</div>
																</td>
															</tr>
															<tr>
																<td width={200}>Agent Address:</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			name='talentAgencyAddressId'
																			render={({ field }) => (
																				<LookAheadSelect
																					className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					{...field}
																					placeholder={
																						!props?.isPrint
																							? 'Select Address'
																							: ''
																					}
																					key={`talentAgencyId-address-${contractForm.watch(
																						'talentAgencyId.value'
																					)}`}
																					filterProperty='Line1'
																					sortField='IsPrimary'
																					sortDescending={true}
																					onOptionLoaded={(options) => {
																						if(options?.length && !contractForm.watch('talentAgencyAddressId.value')){
																							contractForm.setValue('talentAgencyAddressId', options[0], { shouldDirty: true});
																							contractForm.trigger('talentAgencyAddressId', { shouldFocus: true});																						
																						}
																					}}
																					APICaller={(filterModel) =>
																						getAllAddressByCompany(
																							filterModel,
																							contractForm.watch(
																								'talentAgencyId.value'
																							) || ''
																						)
																					}
																					optionGenerator={
																						adAgencyAddressOptionGenerator
																					}
																					isDisabled={
																						!contractForm.watch(
																							'talentAgencyId.value'
																						) || props?.isPrint
																					}
																					dropDownsize={50}
																					isClearable={true}
																					isSearchText={true}
																					onChange={(e: any) => {
																						field.onChange(e);
																						contractForm.setValue('talentAgencyEmailId', '', { shouldDirty: true });
																						contractForm.setValue('talentAgencyPhoneId', '', { shouldDirty: true } );
																						contractForm.trigger('talentAgencyEmailId');
																						contractForm.trigger('talentAgencyPhoneId');
																					}}
																					cacheOptions={false}
																					defaultOptions
																				/>
																			)}
																		/>
																		{contractForm.formState.errors
																			?.talentAgencyAddressId?.message && (
																			<FormHelperText
																				error={true}
																				data-cy='errorHelper_ad_agency'
																			>
																				{
																					contractForm.formState?.errors
																						?.talentAgencyAddressId.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
																<td width={180}>Agent Phone:</td>
																<td>
																	<FormControl
																		className={styles.tableForms}
																		key={`select-phone-fc-${contractForm.watch(
																			'talentAgencyPhoneId'
																		)}-${contractForm.watch(
																			'talentAgencyAddressId.value'
																		)}`}
																		fullWidth
																	>
																		<Controller
																			control={contractForm.control}
																			key={`select-phone-c-${contractForm.watch(
																				'talentAgencyPhoneId'
																			)}-${contractForm.watch(
																				'talentAgencyAddressId.value'
																			)}`}
																			name='talentAgencyPhoneId'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuSelect
																					{...field}
																					key={`select-phone-${contractForm.watch(
																						'talentAgencyPhoneId'
																					)}-${contractForm.watch(
																						'talentAgencyAddressId.value'
																					)}`}
																					placeholder={
																						!props?.isPrint ? 'Select Phone' : ''
																					}
																					convertValueToUpperCase={false}
																					enableEmptySelection={true}
																					disabled={
																						props?.isPrint ||
																					!contractForm.watch(
																						'talentAgencyAddressId.value'
																					)
																					}
																					options={getPhoneNumberOptions(
																						contractForm.watch(
																							'talentAgencyAddressId.value'
																						)
																					)}
																					handleDefaultSelection={() => !contractForm.watch('talentAgencyPhoneId')}
																				/>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.talentAgencyPhoneId?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.talentAgencyPhoneId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td width={200}>Agent Email:</td>
																<td>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																		key={`select-fc-${contractForm.watch(
																			'talentAgencyAddressId.value'
																		)}-${contractForm.watch('talentAgencyEmailId')}`}
																	>
																		<Controller
																			control={contractForm.control}
																			key={`select-c-${contractForm.watch(
																				'talentAgencyAddressId.value'
																			)}-${contractForm.watch('talentAgencyEmailId')}`}
																			name='talentAgencyEmailId'
																			render={({ field }) => (
																				<NabuSelect
																					{...field}
																					key={`select-em-${contractForm.watch(
																						'talentAgencyAddressId.value'
																					)}-${contractForm.watch('talentAgencyEmailId')}`}
																					placeholder={
																						!props?.isPrint ? 'Select Email' : ''
																					}
																					convertValueToUpperCase={false}
																					enableEmptySelection={true}
																					disabled={
																						props?.isPrint ||
																					!contractForm.watch(
																						'talentAgencyAddressId.value'
																					)
																					}
																					options={getCompanyEmailOptions(
																						contractForm.watch(
																							'talentAgencyAddressId.value'
																						)
																					)}
																					handleDefaultSelection={() => !contractForm.watch('talentAgencyEmailId')}
																				/>
																			)}
																		/>
																		{contractForm.formState?.errors
																			?.talentAgencyEmailId?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.talentAgencyEmailId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
														</table>
														<hr></hr>

														<br></br>
														<b>Payment Authorization:</b>
														<p>
														Performer authorizes payments to which Performer may
														be entitled hereunder, as follows:
														</p>

														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																gap: '10px',
															}}
														>
															<Controller
																control={contractForm.control}
																name='isPaymentAtAgentAddress'
																defaultValue={false}
																render={({ field }) => (
																	<div
																		onClick={props?.isPrint ? undefined : () => field.onChange(false)}
																		style={{ userSelect: 'none' }}
																	>
																		<Box display={'flex'} alignItems={'flex-start'}>
																			<input
																				{...field}
																				value={field.value + ''}
																				checked={!field.value}
																				onChange={() => field.onChange(false)}
																				type='checkbox'
																				disabled={props?.isPrint}
																				style={{ marginTop: '3px' }}
																			/>
																			<span>
																		To <b>Performer</b> at address listed above.
																			</span></Box>
																	</div>
																)}
															/>
														Or
															<Controller
																control={contractForm.control}
																name='isPaymentAtAgentAddress'
																defaultValue={false}
																render={({ field }) => (
																	<div
																		onClick={props?.isPrint ? undefined : () => field.onChange(true)}
																		style={{ userSelect: 'none' }}
																	>
																		<Box display={'flex'} alignItems={'flex-start'}>
																			<input
																				{...field}
																				value={field.value + ''}
																				checked={field.value}
																				onChange={() => field.onChange(true)}
																				type='checkbox'
																				disabled={props?.isPrint}
																				style={{ marginTop: '3px' }}
																			/>
																			<span>
																		To <b>Agent</b> at address listed above
																			</span>
																		</Box>
																	</div>
																)}
															/>
														</div>
														<p>
															<b>Minor:</b>
														</p>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																gap: '10px',
															}}
														>
															<span style={{ flex: 'none' }}>
															I, as parent/legal guardian of:{' '}
															</span>
															<FormControl className={styles.tableForms} >
																<Controller
																	control={contractForm.control}
																	name='printName'
																	disabled={true}
																	render={({ field }) => (
																		<>
																			<NabuTextbox
																				data-cy='job_name_textbox'
																				placeholder='Performer'
																				{...field}
																				value={isMinor() && field.value || ''}
																			/>
																		</>
																	)}
																/> 
															</FormControl>
															<span>
															agree to the above and I sign this document to
															signify my agreement.
															</span>
														</div>
														<hr></hr>
														<table
															width={'100%'}
															border={0}
															cellPadding={0}
															cellSpacing={0}
															className={styles.contractFormsInner}
														>
															<tr>
																<td width={220}>Parent/Guardian Signature</td>
																<td>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<NabuTextbox disabled />
																	</FormControl>
																</td>
																<td width={50}>Date</td>
																<td>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<NabuTextbox disabled />
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td width={220}>Parent/Guardian Print Name:</td>
																<td colSpan={3}>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<Controller
																			control={contractForm.control}
																			name='guardianPrintName'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={
																						!props?.isPrint
																							? 'Guardian Print Name'
																							: ''
																					}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors
																			?.guardianPrintName?.message && (
																			<FormHelperText
																				data-cy='errorHelper-guardianPrintName'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors
																						?.guardianPrintName.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td width={220}>Producer’s Signature</td>
																<td>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<NabuTextbox disabled />
																	</FormControl>
																</td>
																<td width={50}>Date</td>
																<td>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<NabuTextbox disabled />
																	</FormControl>
																</td>
															</tr>
															<tr>
																<td width={220}>Business Affairs Mgr.</td>
																<td>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<NabuTextbox disabled />
																	</FormControl>
																</td>
																<td width={50}>Date</td>
																<td>
																	<FormControl
																		className={styles.tableForms}
																		fullWidth
																	>
																		<NabuTextbox disabled />
																	</FormControl>
																</td>
															</tr>
														</table>
													</div>
												</div>
											</>
											{/* Main Contract Form End */}

											{/* W4 Short form */}
											{packetControlForm.watch('isW4shortForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW4ShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
											{/* W8 Ben Short form */}
											{packetControlForm.watch('isW8BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW8BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* W9 Ben Short form */}
											{packetControlForm.watch('isW9BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW9BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* Minor Trust form */}
											{packetControlForm.watch('isMinorTrust') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractMinorTrustForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* NY-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isNYTheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractNYWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* CA-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isCATheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractCAWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>

					{!props?.isPrint && (
						<div className={styles.contractTemplateBtn}>
							<Grid container>
								<Grid xs={12} md={6}>
									<NabuButton variant='filled' onClick={onClickSnippetBtn}>
										{!contractFormState?.isPacketOpen ? (
											<>
												<NoteAdd /> Create
											</>
										) : (
											'Close'
										)}
									&nbsp; Packet
									</NabuButton>
								</Grid>
								<Grid xs={12} md={6} dir='rtl'>
									<NabuButton
										variant='outlined'
										data-cy='cancel_btn'
										shouldHandleConfirmAction={contractForm.formState.isDirty}
										onClick={() => handleFormClose()}
									>
									Cancel
									</NabuButton>{' '}
								&nbsp;
									<NabuButton
										variant='filled'
										type='submit'
										data-cy='save_btn'
										disabled={
											!(
												contractForm.formState.isDirty ||
											packetControlForm.formState.isDirty
											) ||
										isAddingContract ||
										isEditingContract
										}
									>
										{isAddingContract || isEditingContract ? 'Saving' : 'Save'}
										<Save />
									</NabuButton>{' '}
								&nbsp;
									<NabuButton
										variant='filled'
										onClick={contractForm.handleSubmit(handleSaveAndDuplicate)}
										disabled={
											!(
												contractForm.formState.isDirty ||
											packetControlForm.formState.isDirty
											) ||
										isAddingContract ||
										isEditingContract
										}
									>
									Save & Duplicate <CopyAll />
									</NabuButton>
								&nbsp;
								</Grid>
							</Grid>
						</div>
					)}
				</form>
			</div>
			<TalentDetailsForm
				handleTalentDetailsForm={(value) => handleTalentDetailsForm(value)}
				noOfTalentsSelected={noOfTalentsSelected}
				setNoOfTalentsSelected={(value) => setNoOfTalentsSelected(value)}
			/>
		</>
	);
});

export default NonUnionPerAgrmntOffCamPri;
