import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import LookAheadSelect from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuBaseTextArea } from '@/common/components/atoms/textArea/textArea';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import AddCompanyInline from '@/common/components/templates/pages/add/addCompanyInline';
import { IPopOverState } from '@/common/components/templates/pages/add/addNewAsset';
import AddPersonInline from '@/common/components/templates/pages/add/addPersonInline';
import AddPhoneNumberInline from '@/common/components/templates/pages/add/addPhoneNumberInline';
import AddTalentAgentInline from '@/common/components/templates/pages/add/addTalentAgentInline';
import { ContractStatus, DefaultPageSize, TeamSagAftraIndustrialOptions } from '@/common/constants/constants';
import { OPERATORS } from '@/common/enums';
import { StatusCodes } from '@/common/enums/StatusCodes';
import { CompanyMainType, ICompanyAddress, ICompanyRecord } from '@/common/types/company';
import {
	IBaseContract,
	IContractTeamSagAftraSdEmpCtrIndEduForm
} from '@/common/types/contract';
import { IFilterOption } from '@/common/types/filterModel';
import { displayOnlyEmail, displayPhoneNumberWithoutLabel, getCompanyDisplayName, getNotificationText, getPersonFullName, sortEmailOrPhoneNumberForDropDown } from '@/common/utils/utils';
import { PhoneNumberFormModel } from '@/modules/company/shared/models/company';
import styles from '@/modules/contracts/components/addContracts.module.scss';
import { getCompanyBasicDetails, getStaffListByCompany } from '@/services/company';
import { addContractTeamSagAftraCOED, updateContractTeamSagAftraCOED } from '@/services/contract';
import { IAssetRecord } from '@/services/models/asset';
import { IPersonRecord } from '@/services/models/person';
import { GetByWithFullNameSearchForBasicDetail, createPersonPhoneNumber, getAgentInfo } from '@/services/person';
import store from '@/store';
import { updateContractFormState } from '@/store/contract/reducer';
import {
	Autocomplete,
	Box,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	Radio,
	RadioGroup,
	TextField
} from '@mui/material';
import { HttpStatusCode } from 'axios';
import { addYears, format, isBefore } from 'date-fns';
import { useEffect, useState } from 'react';
import {
	Controller,
	UseFormReturn,
	useForm
} from 'react-hook-form';
import { useMutation } from 'react-query';
import TEAMS from '../../forms/img/teamCompanies.png';
import ContractMutationWrapper from '../contractMutationWrapper/contractMutationWrapper';
import useContractMutation from '../contractMutationWrapper/useContractMutation';
import { IContractFormState } from './standardSagAftraEmployment2022';


interface Props {
	mainForm?: UseFormReturn<IBaseContract, any, undefined>;
	onCancel?: VoidFunction;
	onSave?: CallableFunction;
	handlePrintStateOfContract?: CallableFunction;
	initialValue?: IContractTeamSagAftraSdEmpCtrIndEduForm;
	isPrint?: boolean;
	isEdit?: boolean;
	contractId?: string;
	defaultValue?: IBaseContract,
	assets?: IAssetRecord[];
	address?: ICompanyAddress[];
	people?: IPersonRecord[];
}

// eslint-disable-next-line react/display-name
const TeamSagAftraSdEmpCtrIndEduFilmVideoPgm = (props: Props) => {
	const { 
		handlePreContractSubmissionTasks,
		addMultipleContractDetails, 
		notification, 
		setPacketFormState, 
		params, 
		setParams, 
		onSubmitError ,
	} = useContractMutation<IContractTeamSagAftraSdEmpCtrIndEduForm>();
	useEffect(() => {
		if(props?.defaultValue?.advertiserId)
			contractForm.setValue('advertiserId', props?.defaultValue?.advertiserId, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.advertiserId]);

	const contractForm = useForm<IContractTeamSagAftraSdEmpCtrIndEduForm>(
		{
			defaultValues: !props?.initialValue
				? {
					...(props?.mainForm?.getValues() || {}),
					...(props?.defaultValue || {}),
					// workDays: [{}],
				}
				: {
					...(props?.initialValue || {} ),
					
				},
			// resolver: yupResolver(StandardSagAftraCOEDSchema),
		}
	);

	const [, setCompanyOptions] = useState<ICompanyRecord[]>([]);
	const [phoneNumberPopOver, setPhoneNumberInlinePopOver] = useState<IPopOverState>({
		isOpen: false,
	});
	
	const [personOptions, setPersonOptions] = useState<IPersonRecord[]>(props?.people || []);
	const [, setAssetOptions] = useState<IAssetRecord[]>([]);
	const [addressOptions, setAddressOptions] = useState<ICompanyAddress[]>(
		props?.address || []
	);
	const [selectedAddress, setSelectedAddress] = useState<{id:any, type:any}>();
	const [contractFormState, setContractFormState] =
		useState<IContractFormState>({});

	const [companyPopoverInline, setCompanyPopoverInline] = useState<IPopOverState>({
		isOpen: false,
	});

	
	// helper functions
	const getPerformerFilter = (): IFilterOption[] => {
		const DEFAULT_FILTER: IFilterOption = {
			value: JSON.stringify(['TALENT']),
			property: 'PersonType',
			operator: OPERATORS.IN,
			isCaseSensitive: false,
		};
		return [DEFAULT_FILTER];
	};

	
	const onAddressChange = (addressId: string, field: 'performer' | 'agent' | 'producerAddress') => {
		setSelectedAddress({type: field, id: addressId});
		const address = addressOptions?.find( ad => ad?.id === addressId);
		
		if(address) {
			contractForm.setValue(`${field}Zip`, address?.zipCode, {shouldDirty: true});
			contractForm.setValue(`${field}State`, address?.state, {shouldDirty: true});
			contractForm.setValue(`${field}City`, address?.city?.toUpperCase(), {shouldDirty: true});
		} else {
			contractForm.setValue(`${field}State`, '', {shouldDirty: true});
			contractForm.setValue(`${field}Zip`, '', {shouldDirty: true});
			contractForm.setValue(`${field}City`, '', {shouldDirty: true});
		}
		contractForm.trigger(`${field}State`);
		contractForm.trigger(`${field}City`);
	};

	const handleStateOptions = (
		responseData: any[],
		type: 'COMPANY' | 'PERSON' | 'ASSET' | 'ADDRESS'
	) => {
		const cb = {
			COMPANY: setCompanyOptions,
			PERSON: setPersonOptions,
			ASSET: setAssetOptions,
			ADDRESS: setAddressOptions,
		}[type];

		if (responseData && Array.isArray(responseData)) {
			cb((prev) => {
				const oldFilter = prev?.filter(
					(d) => !responseData?.some((r) => r?.id === d?.id)
				);

				return [...oldFilter, ...responseData];
			});
		}
	};

	useEffect(() => {
		if(selectedAddress?.type !== ''){
			onAddressChange(selectedAddress?.id, selectedAddress?.type);
		}
	}, [addressOptions]);

	const getPerformerEmailsOptions = () => {
		const unsortedEmails =
			[...(personOptions || []), ...(props?.people || [])]?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.emails || [];
	
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
			
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const companyOptionGenerator = (responseData: ICompanyRecord[]) => {
		handleStateOptions(responseData, 'COMPANY');

		return (responseData) && responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));
	};



	const performerOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');
		return responseData?.map((d) => ({
			value: d?.id,
			label: getPersonFullName(d?.personName),
		}));
	};

	const handleFormClose = () => {
		contractForm?.reset();
		props?.onCancel && props?.onCancel();
	};

	const { mutate: addContractDetails, isLoading: isAddingContract } =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addContractTeamSagAftraCOED(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const { mutate: editContractDetails, isLoading: isEditingContract } =
		useMutation({
			mutationKey: ['contract-edit'],
			mutationFn: (payload: any) => updateContractTeamSagAftraCOED(props?.contractId || '', payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'UPDATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'UPDATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});



	const handleContractSubmission = async (submitData: IContractTeamSagAftraSdEmpCtrIndEduForm, handleMultipleContractAdd?: CallableFunction) => { 

		handlePreContractSubmissionTasks(submitData, (data) => {
			const mainFormValues = props?.mainForm?.getValues();
			const addMainFormValues = !props?.isEdit ? {
				contractType: mainFormValues?.contractType,
				contractDisplayName: mainFormValues?.contractDisplayName,
				unionType: mainFormValues?.unionType,
				unionAgreement: mainFormValues?.unionAgreement,
				payrollVendorAddressId: mainFormValues?.payrollVendorAddressId || '',
			} : {};
			const contractExpiryDate = data.termDate ? addYears(new Date(data.termDate), 3) : undefined;
			const isExpired = contractExpiryDate ? (isBefore(contractExpiryDate, new Date()) ? ContractStatus.Expired : undefined) : undefined;

			const submitData = {
				...data,
				...addMainFormValues,
				payrollVendorAddressId: data?.payrollVendorAddressId?.value,
				contractTeamSagAftraCOED: undefined,
				payrollVendorId: data?.payrollVendorId?.value,
				returnToEmail: data?.returnToEmail?.value,
				performerId: data?.performerId?.value,
				advertiserId: data?.advertiserId?.value,
				talentAgencyStaffId: data?.talentAgencyStaffId?.value,
				producerCompanyId: data?.producerCompanyId?.value,
				producerCompanyName: data?.producerCompanyName,
				performerAddressId: data?.performerAddressId?.value,
				adAgencyId: data?.adAgencyId?.value,
				agentId:	data?.agentId?.value,
				agentAddressId: data?.agentAddressId?.value,
				producerAddressId: data?.producerAddressId?.value,
				expirationDate: contractExpiryDate,
				status: isExpired,
			};

			if (!props?.isEdit){
				addContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			}
			else {
				editContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			} 
		});
	};

	const onSubmit = (data: IContractTeamSagAftraSdEmpCtrIndEduForm) => {		
		handleContractSubmission(data);
	};


	const [contractFormData, setContractFormData] = useState<IContractTeamSagAftraSdEmpCtrIndEduForm>();

	const [, setNoOfTalentsSelected] = useState(false);

	const handleSaveAndDuplicate = (data: IContractTeamSagAftraSdEmpCtrIndEduForm) => {
		setNoOfTalentsSelected(true);
		setContractFormData(data);
	};

	const handleTalentDetailsForm = (data: any) => {
		setNoOfTalentsSelected(false);
		const handleMultipleContractAdd = (payloadData) => {
			if(data) {
				const contractAddModels = data?.talentRecords?.map((talent, index) => {
					const submitData = {
						...payloadData,
						id: undefined,
						status:ContractStatus?.Draft,
						partName: talent?.role,
						agentId: talent?.agent?.value,
						performerId: talent?.talentName?.value,
						contractDisplayName: `${payloadData?.contractDisplayName}_copy_${index+1}`,
						compensation: talent?.compensation,
						performerType:talent?.perfCategory?.toUpperCase(),
						performerAddressId:undefined,
						performerEmail:undefined,
						talentAgencyStaffId:undefined
					};
					return submitData;
				});
				const multipleContractData = {
					ContractAddTeamSagAftraCOEDModels: contractAddModels
				};
				addMultipleContractDetails(multipleContractData);
			}
		};
		contractFormData && handleContractSubmission(contractFormData, handleMultipleContractAdd);
	};

	const getPerformerPhoneNumberOptions = () => {
		const unsortedPhoneNumbers =
			personOptions?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.phoneNumbers || [];
		
		const phoneNumbers = sortEmailOrPhoneNumberForDropDown(unsortedPhoneNumbers);
		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	const closePhoneNumberInlinePopOver = () => {
		setPhoneNumberInlinePopOver({ isOpen: false });
	};

	const openPhoneNumberInlinePopOver = (e: React.MouseEvent<HTMLButtonElement | null>, key?: string) => {
		setPhoneNumberInlinePopOver({ isOpen: true, event: e?.currentTarget, for: key });
	};

	const onPerformerPhoneNumberInlineSubmit = (formData: any) => {
		const personId = contractForm.watch('performerId.value') || '';
		createPersonPhoneNumber({ personId, phoneNumber: {...formData, isPrimary: true} }).then((res: any) => {
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Phone Number', 'CREATE'),
					type: 'success',
				});
				closePhoneNumberInlinePopOver();

				setPersonOptions(prev => (
					prev?.map(pn => (pn?.id == contractForm.watch('performerId.value') ? ({
						...pn,
						phoneNumbers: [
							{ id: res?.data?.id, ...formData },
							...(pn?.phoneNumbers || {}) as any
						]
					}) : pn))
				));	
				
				contractForm.trigger('performerId');
				contractForm.trigger('performerId.value');
				contractForm.setValue('performerPhoneId', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Phone Number.',
					type: 'error',
				});
			}
		});
	};


	const getPerformerSSN = () =>{
		const performerSsn = personOptions?.find((a) => a?.id === contractForm.watch('performerId.value'))?.ssn || '';
		if (performerSsn) {
			return `***-**-${performerSsn.slice(-4)}`;
		}
		return '';
	};	

	const [staffOptions, setStaffOptions] = useState([] as any);
	useEffect(() => {
		if(contractForm.watch('agentId')){
			const getStaffOptions = (filter: any) => {
				getStaffListByCompany(filter, contractForm.watch('agentId')?.value).then((res) => {
					const options: any = res?.data?.records?.map((staff: any) => {
						return {value: staff?.id, label: getPersonFullName(staff?.personName)};
					}) ?? [];
					setStaffOptions(options);
				});
			};
			getStaffOptions({
				pageNumber: 0,
				pageSize: DefaultPageSize,
				sortDescending: true,
				sortField: 'createdAt',
			});
		}
	}, [contractForm.watch('agentId')]);

	return (
		<>
			<ContractMutationWrapper
				isEdit={props?.isEdit}
				isPrint={props?.isPrint}
				contractForm={contractForm}
				contractFormState={contractFormState}
				setContractFormState={setContractFormState}
				handleFormClose={handleFormClose}
				handleSaveAndDuplicate={handleSaveAndDuplicate}
				handleTalentDetailsForm={handleTalentDetailsForm}
				isAddingContract={isAddingContract}
				isEditingContract={isEditingContract}
				onSubmit={contractForm.handleSubmit(onSubmit, onSubmitError)}
				setPacketFormState={setPacketFormState}
				performerCategory = {TeamSagAftraIndustrialOptions}
				isUnion={true}
				mainForm={props?.mainForm}
			>
				{/* Main Contract Form Start */}
				<>
					<div className={styles.contractFormBlock}>
						<div className={styles.bgWhite} style={{padding:'20px'}}>
							<Grid item container columnSpacing={3}>
								<Grid item md={2}>
									<img src={TEAMS} width={'100%'} style={{marginTop:35}}/>
								</Grid>
								<Grid item md={10} >
									<Box sx={{pt:4}}>
										<h2 style={{textAlign:'center', fontSize:'28px'}}>CORPORATE/EDUCATIONAL & NON-BROADCAST CONTRACT</h2>
										<h4 style={{textAlign:'center'}}>SAG-AFTRA - Standard Employment Contract</h4>
										<p style={{textAlign:'center'}}><b>Artist Cannot Waive Any Portion of the Union Contract Without Prior Consent of SAG-AFTRA</b></p>
									</Box>
								</Grid>
							</Grid>
							<hr></hr>
							<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
								<span> This Agreement made this </span>
								<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'300px'}}>
									{props?.isPrint ? (
										<span>{contractForm.watch('producerDate') &&format(new Date(contractForm.watch('producerDate')?.toString() || ''),' EEEE do \'of\' MMMM yyyy ')}</span>
									) :(<Controller
										control={contractForm.control}
										name='producerDate'
										render={({ field }) => (
											<NabuDatepicker
												{...field}
												disabled={props?.isPrint}
											/>
										)}
									/>)}
								</FormControl> 
								{/* <span>day of</span>
														<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'100px'}}>
															<NabuTextbox/>
														</FormControl> 
														<span>20</span>
														<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'100px'}}>
															<NabuTextbox/>
														</FormControl>  */}
							</div>
							<br></br>
							<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
								<span>between</span>
								<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'250px'}}>
									<Controller
										control={contractForm.control}
										name='producerCompanyName'
										render={({ field }) => (
											<>
												<NabuTextbox
													{...field}
													disabled={props?.isPrint}
													placeholder={!props?.isPrint ? 'Producer Company' : ''}
													onChange={(e:any) => {
														field.onChange(e);
														contractForm.setValue('producerName', contractForm.watch('producerCompanyName'),{ shouldDirty: true });
													}}
												/>
											</>
										)}
									/>
									{/* {contractForm.formState.errors?.producerCompanyId
																?.value?.message && (
																<FormHelperText
																	error={true}
																	data-cy='errorHelper-producerCompanyId'
																>
																	{
																		contractForm.formState?.errors
																			?.producerCompanyId.value.message
																	}
																</FormHelperText>
															)} */}
								</FormControl> 
								<span>(Signatory Producer) and</span>
								<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'250px'}}>
									<Controller
										control={contractForm.control}
										name='performerId'
										render={({ field }) => (
											<>
												<LookAheadSelect
													data-cy='name_select'
													{...field}
													key={`performer-name
																												-${contractForm.watch('performerId.value')}
																												-${contractForm.watch('performerPhone')}-${contractForm.watch('performerEmail')}`}
													isClearable={true}
													placeholder={!props?.isPrint ? 'Performer' : ''}
													filterProperty='PersonName.FirstName'
													APICaller={(
														filterModel,
														searchText
													) =>
														GetByWithFullNameSearchForBasicDetail(
															filterModel,
															searchText
														)
													}
													isSearchText={true}
													isDisabled={props?.isPrint}
													optionGenerator={
														performerOptionGenerator
													}
													defaultFilter={getPerformerFilter()}
													onChange={(e: any) => {
														field.onChange(e);
														contractForm.setValue('performerPhoneId', '', { shouldDirty: true });
														contractForm.setValue('performerEmail', '', { shouldDirty: true });
														contractForm.setValue('performerZip', '', { shouldDirty: true });
														contractForm.setValue('performerState', '', { shouldDirty: true });
														contractForm.setValue('performerCity', '', { shouldDirty: true });
														contractForm.setValue('talentAgencyStaffId', null, { shouldDirty: true });
														contractForm.setValue('agentId', undefined, { shouldDirty: true });
														contractForm.trigger('performerPhoneId');
														contractForm.trigger('performerEmail');
														contractForm.setValue('performerAddressId', null, { shouldDirty: true });
													}}
													cacheOptions={false}
													defaultOptions
													className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
												/>
												{!props?.isPrint && (
													<div style={{marginTop:'-7px', marginBottom:'-17px'}}>
																				
														<AddPersonInline
															title='Person'
															onPersonCreated={() => ({})}
															initialValues={{personType: ['TALENT'], isMinor: false}}
														/>
																				
													</div>
												)}
											</>
										)}
									/>																							
									{/* {contractForm.formState?.errors?.performerId
																?.value?.message && (
																<FormHelperText
																	data-cy='errorHelper_first_session_date'
																	error={true}
																>
																	{
																		contractForm.formState?.errors
																			?.performerId?.value?.message
																	}
																</FormHelperText>
															)} */}
								</FormControl> 
								<span>(Performer).</span>
							</div>
							<br></br>
							<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
								<tr>
									<td width={5}>
									1
									</td>
									<td colSpan={2}>
										<div style={{display:'flex', alignItems:'center', flexWrap:'wrap', gap:'5px'}}>
											<span><b>SERVICES:</b> Producer engages Performer and Performer agrees to perform services portraying the role of</span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'250px'}}>
												<Controller
													control={contractForm.control}
													name='partName'
													defaultValue=''
													render={({ field }) => (
														<NabuTextbox
															{...field}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? 'Role' : ''}
														/>
													)}
												/>
												{/* {contractForm.formState.errors?.partName
																			?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors?.partName
																						.message
																				}
																			</FormHelperText>
																		)} */}
											</FormControl> 
											<span>in a program tentatively entitled</span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'250px'}}>
												<Controller
													control={contractForm.control}
													name='tentativeProgramTitle'
													defaultValue=''
													render={({ field }) => (
														<NabuTextbox
															{...field}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? 'Program Title' : ''}
														/>
													)}
												/>
												{/* {contractForm.formState.errors?.tentativeProgramTitle
																			?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors?.tentativeProgramTitle
																						.message
																				}
																			</FormHelperText>
																		)} */}
											</FormControl> 
											<span>on behalf of</span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'250px'}}>
												<Controller
													control={contractForm.control}
													name='advertiserId'
													render={({ field }) => (
														<>
															<LookAheadSelect
																{...field}
																placeholder={!props?.isPrint ? 'Advertiser' : ''}
																key={`advertiser-${contractForm.watch('advertiserId.value')}`}
																filterProperty='CompanyNames.Name'
																APICaller={getCompanyBasicDetails}
																optionGenerator={companyOptionGenerator}
																isDisabled={props?.isPrint}
																isClearable={true}
																onChange={(e: any) => {
																	field.onChange(e);
																	// setJPCAuthorizer();
																}}
																cacheOptions={false}
																defaultOptions
																className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
															/>
															<Box sx={{mt:0.5, height:'15px'}}>
																{!props?.isPrint && (
																					
																	<AddCompanyInline
																		title='Company'
																		companyType={{ mainType: 'ADVERTISER' as CompanyMainType, subType: [] }} />
																					
																)}
															</Box>
														</>
													)}
												/>
												{/* {contractForm.formState.errors?.advertiserId?.value
																			?.message && (
																			<FormHelperText
																				error={true}
																				data-cy='errorHelper_ad_agency'
																			>
																				{
																					contractForm.formState?.errors?.advertiserId
																						.value.message
																				}
																			</FormHelperText>
																		)} */}
											</FormControl> 
											<span>(Client).</span>

										</div>
									</td>
								</tr>
								<tr>
									<td>
									2
									</td>
									<td>
										<div style={{display:'flex', alignItems:'center', flexWrap:'wrap', gap:'5px'}}>
											<span><b>CATEGORY:</b> Indicate the initial, primary use of the program.</span>
										</div>
									
									</td>
									<td>
										<div style={{display:'flex', alignItems:'center', flexWrap:'wrap', gap:'5px'}}>
											<Box display={'flex'} alignItems={'flex-start'}>
												<Controller
													control={contractForm.control}
													name='category1'
													defaultValue={false}
													render={({ field }) => (
														<div
															onClick={() => {
																(!props?.isPrint) && field.onChange(!field.value);
																// handleCustomErrorLogic();
															}}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	value={field.value + ''}
																	checked={field.value}
																	onChange={(e) => {
																		field.onChange(e);
																		// handleCustomErrorLogic();
																	}}
																	type='checkbox'
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
																<span> Category I (Educational/Training)  </span>{' '}
															</Box>
														</div>
													)}
												/>
											</Box>
											<Box display={'flex'} alignItems={'flex-start'}>
												<Controller
													control={contractForm.control}
													name='category2'
													defaultValue={false}
													render={({ field }) => (
														<div
															onClick={() => {
																(!props?.isPrint) && field.onChange(!field.value);
																// handleCustomErrorLogic();
															}}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	value={field.value + ''}
																	checked={field.value}
																	onChange={(e) => {
																		field.onChange(e);
																		// handleCustomErrorLogic();
																	}}
																	type='checkbox'
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
																<span> Category II (Includes Category I) </span>{' '}
															</Box>
														</div>
													)}
												/>
											</Box>
										</div>
									</td>
								</tr>
								<tr>
									<td>
									3
									</td>
									<td>
										<div style={{display:'flex', alignItems:'center', flexWrap:'wrap', gap:'5px'}}>
											<b>NUMBER OF CLIENTS: </b> <span>Indicate the number of clients for which program will be used.</span>
										</div>
									
									</td>
									<td><div style={{display:'flex', alignItems:'center', flexWrap:'wrap', gap:'5px'}}>

										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='singleClient'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => {
															(!props?.isPrint) && field.onChange(!field.value);
															// handleCustomErrorLogic();
														}}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => {
																	field.onChange(e);
																	// handleCustomErrorLogic();
																}}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span> Single Client</span>{' '}
														</Box>
													</div>
												)}
											/>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='multipleClients'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => {
															(!props?.isPrint) && field.onChange(!field.value);
															// handleCustomErrorLogic();
														}}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => {
																	field.onChange(e);
																	// handleCustomErrorLogic();
																}}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span> Multiple Clients</span>{' '}
														</Box>
													</div>
												)}
											/>
										</Box>
									</div></td>
								</tr>
								<tr>
									<td>
									4
									</td>
									<td colSpan={2}>
										<div style={{display:'flex', alignItems:'center', flexWrap:'wrap', gap:'5px'}}>
											<span><b>TERM:</b> Continuous period commencing</span>
											<FormControl className={styles.tableForms} style={{minWidth:'150px'}}>
												{props?.isPrint ? (
													<p>{contractForm.watch('termDate') &&  format(new Date(contractForm.watch('termDate')?.toString() || ''), 'EEEE do \'of\' MMMM yyyy')}</p>
												) :(<Controller
													control={contractForm.control}
													name='termDate'
													render={({ field }) => (
														<NabuDatepicker
															{...field}
															disabled={props?.isPrint}
														/>
													)}
												/>)}
											</FormControl> 
											{/* <span>20</span>
																	<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'50px'}}>
																		<NabuTextbox/>
																	</FormControl>  */}
											<span>and continuing until completion of the role</span>
										</div>

										<div style={{display:'flex', alignItems:'center', flexWrap:'wrap', gap:'5px'}}>
											<span><b>EXCEPTION </b> (Day Performers only) - May be dismissed and recalled (without payment for intervening period) provided Performer is given a firm</span>
											<span>recall date at time of engagement. If applicable, Performer’s firm recall date is:</span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'250px'}}>
												{props?.isPrint ? (
													<p>{contractForm.watch('termDateExtends') &&  format(new Date(contractForm.watch('termDateExtends')?.toString() || ''), 'EEEE do \'of\' MMMM yyyy')}</p>
												) :(<Controller
													control={contractForm.control}
													name='termDateExtends'
													render={({ field }) => (
														<NabuDatepicker
															{...field}
															disabled={props?.isPrint}
														/>
													)}
												/>)}
											</FormControl> 
											{/* <span>20</span>
																	<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'50px'}}>
																		<NabuTextbox/>
																	</FormControl>  */}
										</div>
									
									</td>
								</tr>
								<tr>
									<td width={5}>
									5
									</td>
									<td colSpan={2}>
										<div style={{display:'flex', alignItems:'center', flexWrap:'wrap', gap:'5px'}}>
											<b>COMPENSATION:</b> Producer employs Performer as: 
											
										</div>
										<div style={{display:'flex', alignItems:'center', flexWrap:'wrap', gap:'5px'}}>
											<Controller
												control={contractForm.control}
												name='classification'
												defaultValue={'OFF-CAMERA'}
												render={({ field }) => (
													<RadioGroup
														className={styles.radioGroupPrint}
														row
														{...field}
													>
														<FormControlLabel
															value={'ON-CAMERA'}
															control={<Radio data-cy=' On-Camera' />}
															label=' On-Camera'
															disabled={props?.isPrint}
														/>
														<FormControlLabel
															value={'OFF-CAMERA'}
															control={<Radio data-cy='On-Camera' />}
															label='Off-Camera'
															disabled={props?.isPrint}
														/>
														<FormControlLabel
															value={'ON-CAMERA-NARRATOR/SPOKESPERSON'}
															control={<Radio data-cy='On-Camera' />}
															label='On-Camera Narrator/Spokesperson'
															disabled={props?.isPrint}
														/>
													</RadioGroup>
												)}
											/>
										</div>
										<table width={'100%'} border={0} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
											<tr>
												<td colSpan={3}>
													<Grid item container columnSpacing={3}>
														<Grid item md={4}>
															<Box display={'flex'} alignItems={'flex-start'}>
																<Controller
																	control={contractForm.control}
																	name='performerType'
																	defaultValue=''
																	render={({ field }) => (
																		<div
																			onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																			style={{ userSelect: 'none' }}
																		>
																			<Box display={'flex'} alignItems={'flex-start'}>
																				<input
																					{...field}
																					value='DAY_PERFORMER'
																					checked={field.value === 'DAY_PERFORMER' }
																					onChange={(e) => field.onChange(e.target.value)}
																					type='radio'
																					disabled={props?.isPrint}
																					style={{ marginTop: '3px' }}
																				/>
																				<span>Day Performer</span>
																			</Box>
																		</div>
																	)}
																/>
															</Box>
															<Box display={'flex'} alignItems={'flex-start'}>
																<Controller
																	control={contractForm.control}
																	name='performerType'
																	defaultValue=''
																	render={({ field }) => (
																		<div
																			onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																			style={{ userSelect: 'none' }}
																		>
																			<Box display={'flex'} alignItems={'flex-start'}>
																				<input
																					{...field}
																					value='HALF_DAY_PERFORMER'
																					checked={field.value === 'HALF_DAY_PERFORMER'}
																					onChange={(e) => field.onChange(e.target.value)}
																					type='radio'
																					disabled={props?.isPrint}
																					style={{ marginTop: '3px' }}
																				/>
																				<span>
																							½ Day Performer <em>(restricted terms) </em>
																				</span>
																			</Box>
																		</div>
																	)}
																/>
															</Box>
															<Box display={'flex'} alignItems={'flex-start'}>
																<Controller
																	control={contractForm.control}
																	name='performerType'
																	defaultValue=''
																	render={({ field }) => (
																		<div
																			onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																			style={{ userSelect: 'none' }}
																		>
																			<Box display={'flex'} alignItems={'flex-start'}>
																				<input
																					{...field}
																					value='THREE_DAY_PERFORMER'
																					checked={field.value === 'THREE_DAY_PERFORMER'}
																					onChange={(e) => field.onChange(e.target.value)}
																					type='radio'
																					disabled={props?.isPrint}
																					style={{ marginTop: '3px' }}
																				/>
																				<span>3-Day Performer </span>
																			</Box>
																		</div>
																	)}
																/>
															</Box>
															<Box display={'flex'} alignItems={'flex-start'}>
																<Controller
																	control={contractForm.control}
																	name='performerType'
																	defaultValue=''
																	render={({ field }) => (
																		<div
																			onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																			style={{ userSelect: 'none' }}
																		>
																			<Box display={'flex'} alignItems={'flex-start'}>
																				<input
																					{...field}
																					value='WEEKLY_PERFORMER'
																					checked={field.value === 'WEEKLY_PERFORMER'}
																					onChange={(e) => field.onChange(e.target.value)}
																					type='radio'
																					disabled={props?.isPrint}
																					style={{ marginTop: '3px' }}
																				/>
																				<span>Weekly Performer </span>
																			</Box>
																		</div>
																	)}
																/>
															</Box>
														</Grid>
														<Grid item md={4}>
															<Box display={'flex'} alignItems={'flex-start'}>
																<Controller
																	control={contractForm.control}
																	name='performerType'
																	defaultValue=''
																	render={({ field }) => (
																		<div
																			onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																			style={{ userSelect: 'none' }}
																		>
																			<Box display={'flex'} alignItems={'flex-start'}>
																				<input
																					{...field}
																					value='DANCER_SOLO_DUO'
																					checked={field.value === 'DANCER_SOLO_DUO'}
																					onChange={(e) => field.onChange(e.target.value)}
																					type='radio'
																					disabled={props?.isPrint}
																					style={{ marginTop: '3px' }}
																				/>
																				<span>Dancer, Solo/Duo </span>
																			</Box>
																		</div>
																	)}
																/>
															</Box>
															<Box display={'flex'} alignItems={'flex-start'}>
																<Controller
																	control={contractForm.control}
																	name='performerType'
																	defaultValue=''
																	render={({ field }) => (
																		<div
																			onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																			style={{ userSelect: 'none' }}
																		>
																			<Box display={'flex'} alignItems={'flex-start'}>
																				<input
																					{...field}
																					value='DANCER_GROUP'
																					checked={field.value === 'DANCER_GROUP'}
																					onChange={(e) => field.onChange(e.target.value)}
																					type='radio'
																					disabled={props?.isPrint}
																					style={{ marginTop: '3px' }}
																				/>
																				<span>Dancer, Group </span>
																			</Box>
																		</div>
																	)}
																/>
															</Box>
															<Box display={'flex'} alignItems={'flex-start'}>
																<Controller
																	control={contractForm.control}
																	name='performerType'
																	defaultValue=''
																	render={({ field }) => (
																		<div
																			onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																			style={{ userSelect: 'none' }}
																		>
																			<Box display={'flex'} alignItems={'flex-start'}>
																				<input
																					{...field}
																					value='SINGER_SOLO_DUO'
																					checked={field.value === 'SINGER_SOLO_DUO'}
																					onChange={(e) => field.onChange(e.target.value)}
																					type='radio'
																					disabled={props?.isPrint}
																					style={{ marginTop: '3px' }}
																				/>
																				<span>Singer Solo/Duo</span>
																			</Box>
																		</div>
																	)}
																/>
															</Box>
															<Box display={'flex'} alignItems={'flex-start'}>
																<Controller
																	control={contractForm.control}
																	name='performerType'
																	defaultValue=''
																	render={({ field }) => (
																		<div
																			onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																			style={{ userSelect: 'none' }}
																		>
																			<Box display={'flex'} alignItems={'flex-start'}>
																				<input
																					{...field}
																					value='SINGER_GROUP'
																					checked={field.value === 'SINGER_GROUP'}
																					onChange={(e) => field.onChange(e.target.value)}
																					type='radio'
																					disabled={props?.isPrint}
																					style={{ marginTop: '3px' }}
																				/>
																				<span>Singer, Group</span>
																			</Box>
																		</div>
																	)}
																/>
															</Box>
														</Grid>
														<Grid item md={4}>
															<Box display={'flex'} alignItems={'flex-start'}>
																<Controller
																	control={contractForm.control}
																	name='performerType'
																	defaultValue=''
																	render={({ field }) => (
																		<div
																			onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																			style={{ userSelect: 'none' }}
																		>
																			<Box display={'flex'} alignItems={'flex-start'}>
																				<input
																					{...field}
																					value='SINGER_STEP_OUT'
																					checked={field.value === 'SINGER_STEP_OUT'}
																					onChange={(e) => field.onChange(e.target.value)}
																					type='radio'
																					disabled={props?.isPrint}
																					style={{ marginTop: '3px' }}
																				/>
																				<span>Singer, Step Out</span>
																			</Box>
																		</div>
																	)}
																/>
															</Box>
															<Box display={'flex'} alignItems={'flex-start'}>
																<Controller
																	control={contractForm.control}
																	name='performerType'
																	defaultValue=''
																	render={({ field }) => (
																		<div
																			onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																			style={{ userSelect: 'none' }}
																		>
																			<Box display={'flex'} alignItems={'flex-start'}>
																				<input
																					{...field}
																					value='GENERAL_BACKGROUND_ACTOR'
																					checked={field.value === 'GENERAL_BACKGROUND_ACTOR'}
																					onChange={(e) => field.onChange(e.target.value)}
																					type='radio'
																					disabled={props?.isPrint}
																					style={{ marginTop: '3px' }}
																				/>
																				<span>General Background Actor</span>
																			</Box>
																		</div>
																	)}
																/>
															</Box>
															<Box display={'flex'} alignItems={'flex-start'}>
																<Controller
																	control={contractForm.control}
																	name='performerType'
																	defaultValue=''
																	render={({ field }) => (
																		<div
																			onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																			style={{ userSelect: 'none' }}
																		>
																			<Box display={'flex'} alignItems={'flex-start'}>
																				<input
																					{...field}
																					value='SPECIAL_ABILITY_BACKGROUND_ACTOR'
																					checked={field.value === 'SPECIAL_ABILITY_BACKGROUND_ACTOR'}
																					onChange={(e) => field.onChange(e.target.value)}
																					type='radio'
																					disabled={props?.isPrint}
																					style={{ marginTop: '3px' }}
																				/>
																				<span>Special Ability Background Actor</span>
																			</Box>
																		</div>
																	)}
																/>
															</Box>
															<Box display={'flex'} alignItems={'flex-start'}>
																<Controller
																	control={contractForm.control}
																	name='performerType'
																	defaultValue=''
																	render={({ field }) => (
																		<div
																			onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																			style={{ userSelect: 'none' }}
																		>
																			<Box display={'flex'} alignItems={'flex-start'}>
																				<input
																					{...field}
																					value='SILENT_BIT_BACKGROUND_ACTOR'
																					checked={field.value === 'SILENT_BIT_BACKGROUND_ACTOR'}
																					onChange={(e) => field.onChange(e.target.value)}
																					type='radio'
																					disabled={props?.isPrint}
																					style={{ marginTop: '3px' }}
																				/>
																				<span>Silent Bit Background Actor</span>
																			</Box>
																		</div>
																	)}
																/>
															</Box>
														</Grid>
													</Grid>
													<br></br>
													<p><b>At the salary of:</b></p>
													<div style={{display:'flex', alignItems:'center', flexWrap:'wrap', gap:'5px'}}>
														<span>On-Camera $</span>
														<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'200px'}}>
															<Controller
																control={contractForm.control}
																name='onCameraSalary'
																render={({ field }) => (
																	<NabuTextbox
																		{...field}
																		disabled={props?.isPrint}
																		placeholder={!props?.isPrint ? 'On Camera Salary' : ''}
																		onChange={(e: any) => {
																			const value = e?.target?.value || '';
																			if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																				field.onChange(value);
																			} else {
																				const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																				field.onChange(num);
																			}
																		}}
																		onBlur={() => {
																			const numericValue = parseFloat((field.value ?? '0').toString());
																			if (!isNaN(numericValue)) {
																				field.onChange(numericValue.toFixed(2));
																			} else {
																				field.onChange('0.00');
																			}
																		}}
																	/>
																)}
															/>
															{/* {contractForm.formState.errors?.onCameraSalary
																			?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.onCameraSalary.message
																				}
																			</FormHelperText>
																		)} */}
														</FormControl> 
														<span>per</span>
														<Controller
															control={contractForm.control}
															name='salaryPayPeriod'
															defaultValue={'DAY'}
															render={({ field }) => (
																<RadioGroup row {...field} className={styles.radioGroupPrint}>
																	<FormControlLabel
																		value={'DAY'}
																		control={<Radio />}
																		label='Day'
																		disabled={props?.isPrint}
																	/>
																	<FormControlLabel
																		value={'3-DAY'}
																		control={<Radio />}
																		label='3-Day'
																		disabled={props?.isPrint}
																	/>
																	<FormControlLabel
																		value={'WEEK'}
																		control={<Radio />}
																		label='Week'
																		disabled={props?.isPrint}
																	/>
																</RadioGroup>
															)}
														/>
													</div>
													<div style={{display:'flex', alignItems:'center', flexWrap:'wrap', gap:'5px'}}>
														<span>Off-Camera $</span>
														<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'200px'}}>
															<Controller
																control={contractForm.control}
																name='offCameraSalaryForFirstHour'
																render={({ field }) => (
																	<NabuTextbox
																		{...field}
																		disabled={props?.isPrint}
																		placeholder={!props?.isPrint ? 'Off Camera Salary' : ''}
																		onChange={(e: any) => {
																			const value = e?.target?.value || '';
																			if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																				field.onChange(value);
																			} else {
																				const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																				field.onChange(num);
																			}
																		}}
																		onBlur={() => {
																			const numericValue = parseFloat((field.value ?? '0').toString());
																			if (!isNaN(numericValue)) {
																				field.onChange(numericValue.toFixed(2));
																			} else {
																				field.onChange('0.00');
																			}
																		}}
																	/>
																)}
															/>
															{/* {contractForm.formState.errors?.offCameraSalaryForFirstHour
																			?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.offCameraSalaryForFirstHour.message
																				}
																			</FormHelperText>
																		)} */}
														</FormControl> 
														<span>for first hour, $</span>
														<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'200px'}}>
															<Controller
																control={contractForm.control}
																name='offCameraSalaryForAdditionalHour'
																render={({ field }) => (
																	<NabuTextbox
																		{...field}
																		disabled={props?.isPrint}
																		placeholder={!props?.isPrint ? 'Off Camera Salary' : ''}
																		onChange={(e: any) => {
																			const value = e?.target?.value || '';
																			if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																				field.onChange(value);
																			} else {
																				const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																				field.onChange(num);
																			}
																		}}
																		onBlur={() => {
																			const numericValue = parseFloat((field.value ?? '0').toString());
																			if (!isNaN(numericValue)) {
																				field.onChange(numericValue.toFixed(2));
																			} else {
																				field.onChange('0.00');
																			}
																		}}
																	/>
																)}
															/>
															{/* {contractForm.formState.errors?.offCameraSalaryForAdditionalHour
																			?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.offCameraSalaryForAdditionalHour.message
																				}
																			</FormHelperText>
																		)} */}
														</FormControl> 
														{/* <span>Attn:</span>
																	<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'200px'}}>
																		<NabuTextbox/>
																	</FormControl>  */}

													</div>
													<p ><b><em>PRODUCER MUST MAIL PAYMENT NOT LATER THAN THIRTY (30) CALENDAR DAYS FOLLOWING THE DAY(S) OF EMPLOYMENT.</em></b></p>
												</td>
											</tr>
										</table>
									</td>
								</tr>
								
								<tr>
									<td width={5}>
									6
									</td>
									<td colSpan={2}>
									
										<p><b>WEEKLY CONVERSION RATE:</b> See Section 19.B.5 of the Agreement for details (Day Performers or 3-Day Performers only).</p>
										<div style={{display:'flex', alignItems:'center', flexWrap:'wrap', gap:'5px'}}>
											<span>The Performer’s weekly conversion rate is: $ </span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'300px'}}>
												<Controller
													control={contractForm.control}
													name='weeklyConversionRate'
													render={({ field }) => (
														<NabuTextbox
															{...field}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? 'Weekly Conversion Rate' : ''}
															onChange={(e: any) => {
																const value = e?.target?.value || '';
																if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																	field.onChange(value);
																} else {
																	const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																	field.onChange(num);
																}
															}}
															onBlur={() => {
																const numericValue = parseFloat((field.value ?? '0').toString());
																if (!isNaN(numericValue)) {
																	field.onChange(numericValue.toFixed(2));
																} else {
																	field.onChange('0.00');
																}
															}}
														/>
													)}
												/>
												{/* {contractForm.formState.errors?.weeklyConversionRate
																			?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.weeklyConversionRate.message
																				}
																			</FormHelperText>
																		)} */}
											</FormControl> 
										</div>
									
									</td>
								</tr>
								<tr>
									<td width={5}>
									7
									</td>
									<td colSpan={2}>
										<p><b> PAYMENT ADDRESS:</b> Performer’s payment shall be sent to:</p>
										<div style={{display:'flex', alignItems:'center', flexWrap:'wrap', gap:'5px'}}>
											<Box display={'flex'} alignItems={'flex-start'}>
												<Controller
													control={contractForm.control}
													name='sendToPerformerAddress'
													defaultValue={false}
													render={({ field }) => (
														<div
															onClick={() => {(!props?.isPrint) && field.onChange(!field.value);
																contractForm.setValue('sendToAgentAddress', !contractForm.watch('sendToPerformerAddress'));
															}
															}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	value={field.value + ''}
																	checked={field.value}
																	onChange={(e) => {field.onChange(e);
																		contractForm.setValue('sendToAgentAddress', !contractForm.watch('sendToPerformerAddress'));
																	}}
																	type='checkbox'
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
																<span>Performer at W4 address; </span>
															</Box>
														</div>
													)}
												/>
											</Box>
											<Box display={'flex'} alignItems={'flex-start'}>
												<Controller
													control={contractForm.control}
													name='sendToAgentAddress'
													defaultValue={false}
													render={({ field }) => (
														<div
															onClick={() => {(!props?.isPrint) && field.onChange(!field.value);
																contractForm.setValue('sendToPerformerAddress', !contractForm.watch('sendToAgentAddress'));
															}
															}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	value={field.value + ''}
																	checked={field.value}
																	onClick={() => {(!props?.isPrint) && field.onChange(!field.value);
																		contractForm.setValue('sendToPerformerAddress', !contractForm.watch('sendToAgentAddress'));
																	}
																	}
																	type='checkbox'
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
															</Box>
														</div>
													)}
												/>
												<span> OR Sent c/o: </span>
											</Box>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'300px'}}>
												<Controller
													key={`agency-${contractForm.watch('performerId.value')}`}
													control={contractForm.control}
													name='agentId'
													render={({ field }) => (
														<>
															<LookAheadSelect
																{...field} 
																key={`agency-${contractForm.watch('performerId.value')}`}
																placeholder='Agency'
																filterProperty='CompanyNames.Name'
																APICaller={(filter) => {
																	filter.filters = filter.filters?.map((f) => f.property == 'CompanyNames.Name' ? {...f, value: f?.value?.trim() || '' } : f) || [];
																	return getAgentInfo(filter, contractForm.watch('performerId.value') || '');
																}}
																optionGenerator={companyOptionGenerator}
																onChange={(e: any) => {
																	field.onChange(e);
																	contractForm.setValue('talentAgencyStaffId',null,{shouldDirty: true});
																}}
																cacheOptions
																isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
																defaultOptions
																dropDownsize={25}
																isClearable={true}
																onOptionLoaded={(options) => {
																	if(options?.length && !contractForm.watch('agentId.value')){
																		contractForm.setValue('agentId', options[0], { shouldDirty: true});
																		contractForm.trigger('agentId', { shouldFocus: true});
																	}
																}}
																className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
															/>
															{!props?.isPrint && contractForm.watch('performerId.value') && (<><div style={{float:'right', marginTop:'-7px', marginBottom:'-13px'}}><AddTalentAgentInline
																onCompanyCreated={(createdAgent) => contractForm.setValue('agentId', createdAgent, { shouldDirty: true})}
																onClose={() => setCompanyPopoverInline({ isOpen: false})}
																onPopOverOpen={(e) => setCompanyPopoverInline({ isOpen: true, event: e?.currentTarget })}
																event={companyPopoverInline?.event}
																isOpen={companyPopoverInline.isOpen}
																personId={contractForm.watch('performerId.value') || ''}
															/></div></>)}
														</>
													)}
												/>
												{/* {contractForm.formState.errors?.agentId
																			?.value?.message && (
																			<FormHelperText
																				error={true}
																				data-cy='errorHelper-agentId'
																			>
																				{
																					contractForm.formState?.errors
																						?.agentId.value.message
																				}
																			</FormHelperText>
																		)} */}
											</FormControl> 
											<span>Attn: </span>
											<FormControl fullWidth  style={{maxWidth:'300px'}}>
												<Controller
													control={contractForm.control}
													name='talentAgencyStaffId'
													// disabled={
													// 	!contractForm.watch(
													// 		'talentAgencyStaffId.value'
													// 	) || props?.isPrint
													// }
													key={`${contractForm.watch('agentId.value')}-${contractForm.watch('talentAgencyStaffId')}`}
													render={({ field }) => (
														<Autocomplete
															data-cy='StaffSelect'
															id="combo-box-demo"
															options={staffOptions}
															{...field}
															onChange={(e, item :any) => {
																contractForm.setValue('talentAgencyStaffId', item);
															}}
															disabled={
																props?.isPrint ||
																				!contractForm.watch(
																					'agentId.value'
																				)
															}
															// onInputChange={(e: any,item) => {
															// 	const lookAheadDefaultFilter: IFilterOption = {
															// 		isCaseSensitive: false,
															// 		operator: OPERATORS.CONTAINS,
															// 		property: 'PersonName.FirstName',
															// 		value: item,
															// 	};
															// 	setStaffPage({
															// 		pageNumber: 0,
															// 		pageSize: DefaultPageSize,
															// 		sortDescending: true,
															// 		sortField: 'PersonName.FirstName',
															// 		filters: [lookAheadDefaultFilter]
															// 	});
															// 	item ? getStaffOptions(staffPage) : getStaffOptions({
															// 		pageNumber: 0,
															// 		pageSize: DefaultPageSize,
															// 		sortDescending: true,
															// 		sortField: 'PersonName.FirstName',
															// 		filters: []
															// 	});
															// }}
															sx={{ width: 300}}
															renderInput={(params) => <TextField placeholder='Agent Name' data-cy='StaffSelectTextField' {...params} />}
														/>
													)}
												/> 
											</FormControl> 
										</div>
										<br></br>
										<p>[Upon SAG-AFTRA’s request, performer’s payment shall be sent to the appropriate SAG-AFTRA office in the city nearest recording site.]</p>
									</td>
								</tr>
								<tr>
									<td width={5}>
									8
									</td>
									<td colSpan={2}>
										<p><b>ADDITIONAL COMPENSATION FOR SUPPLEMENTAL USE:</b> Producer may acquire the following supplemental use rights by the payment of the
									indicated amounts. (Check appropriate items below.) See Section 9 of Agreement for details of payment.</p>
										<div style={{display:'flex', alignItems:'center', flexWrap:'wrap', gap:'5px'}}>
										</div>
									</td>
								</tr>
								<tr>
									<td colSpan={3} style={{padding:'0'}} >
										<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`} style={{border:'0px'}}>
											<tr>
												<td colSpan={3}>
													<p><b>Group Dancers:</b> Supplemental Use for group dancers is capped. See Section 9.B.1.c. of the Contract for payment provisions</p>
												</td>
											</tr>
											<tr>
												<td>
													<b>% of Total Applicable Salary when paid </b>
												</td>
												<td>
													<b>Within 90 Days of Session </b>
												</td>
												<td>
													<b>Beyond 90 Days of Session</b>
												</td>
											</tr>
											<tr>
												<td>
													<Box display={'flex'} alignItems={'flex-start'}>
														<Controller
															control={contractForm.control}
															name='isAdditionalCompensation1'
															defaultValue={false}
															render={({ field }) => (
																<div
																	onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																	style={{ userSelect: 'none' }}
																>
																	<Box display={'flex'} alignItems={'flex-start'}>
																		<input
																			{...field}
																			value={field.value + ''}
																			checked={field.value}
																			onChange={(e) => field.onChange(e)}
																			type='checkbox'
																			disabled={props?.isPrint}
																			style={{ marginTop: '3px' }}
																		/>
																	</Box>
																</div>
															)}
														/>
														<span> Category II use of a program originally made as a Category I program </span>
													</Box>
												</td>
												<td width={200}>
											50% 
												</td>
												<td width={200}>
											100%
												</td>
											</tr>
											<tr>
												<td>
													<Box display={'flex'} alignItems={'flex-start'}>
														<input type='checkbox' style={{ marginTop: '3px', }}/>
														<span>1. Basic Cable Television: 3 years  </span>
													</Box>
												</td>
												<td width={200}>
											15%
												</td>
												<td width={200}>
											65%
												</td>
											</tr>
											<tr>
												<td>
													<Box display={'flex'} alignItems={'flex-start'}>
														<Controller
															control={contractForm.control}
															name='isAdditionalCompensation2'
															defaultValue={false}
															render={({ field }) => (
																<div
																	onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																	style={{ userSelect: 'none' }}
																>
																	<Box display={'flex'} alignItems={'flex-start'}>
																		<input
																			{...field}
																			value={field.value + ''}
																			checked={field.value}
																			onChange={(e) => field.onChange(e)}
																			type='checkbox'
																			disabled={props?.isPrint}
																			style={{ marginTop: '3px' }}
																		/>
																	</Box>
																</div>
															)}
														/>
														<span> 2. Non-Network Television: Unlimited runs  </span>
													</Box>
												</td>
												<td width={200}>
											75% 
												</td>
												<td width={200}>
											125%
												</td>
											</tr>
											<tr>
												<td>
													<Box display={'flex'} alignItems={'flex-start'}>
														<Controller
															control={contractForm.control}
															name='isAdditionalCompensation3'
															defaultValue={false}
															render={({ field }) => (
																<div
																	onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																	style={{ userSelect: 'none' }}
																>
																	<Box display={'flex'} alignItems={'flex-start'}>
																		<input
																			{...field}
																			value={field.value + ''}
																			checked={field.value}
																			onChange={(e) => field.onChange(e)}
																			type='checkbox'
																			disabled={props?.isPrint}
																			style={{ marginTop: '3px' }}
																		/>
																	</Box>
																</div>
															)}
														/>
														<span> 3. Theatrical Exhibition: Unlimited runs  </span>
													</Box>
												</td>
												<td width={200}>
											100% 
												</td>
												<td width={200}>
											150%
												</td>
											</tr>
											<tr>
												<td>
													<Box display={'flex'} alignItems={'flex-start'}>
														<Controller
															control={contractForm.control}
															name='isAdditionalCompensation4'
															defaultValue={false}
															render={({ field }) => (
																<div
																	onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																	style={{ userSelect: 'none' }}
																>
																	<Box display={'flex'} alignItems={'flex-start'}>
																		<input
																			{...field}
																			value={field.value + ''}
																			checked={field.value}
																			onChange={(e) => field.onChange(e)}
																			type='checkbox'
																			disabled={props?.isPrint}
																			style={{ marginTop: '3px' }}
																		/>
																	</Box>
																</div>
															)}
														/>
														<span> 4. Foreign Television: Unlimited runs outside of U.S. & Canada  </span>
													</Box>
												</td>
												<td width={200}>
											25% 
												</td>
												<td width={200}>
											75%
												</td>
											</tr>
											<tr>
												<td>
													<Box display={'flex'} alignItems={'flex-start'}>
														<Controller
															control={contractForm.control}
															name='isAdditionalCompensation5'
															defaultValue={false}
															render={({ field }) => (
																<div
																	onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																	style={{ userSelect: 'none' }}
																>
																	<Box display={'flex'} alignItems={'flex-start'}>
																		<input
																			{...field}
																			value={field.value + ''}
																			checked={field.value}
																			onChange={(e) => field.onChange(e)}
																			type='checkbox'
																			disabled={props?.isPrint}
																			style={{ marginTop: '3px' }}
																		/>
																	</Box>
																</div>
															)}
														/>
														<span> 5. Sale and/or Rental within an industry  </span>
													</Box>
												</td>
												<td width={200}>
											15% 
												</td>
												<td width={200}>
											25%
												</td>
											</tr>
											<tr>
												<td>
													<Box display={'flex'} alignItems={'flex-start'}>
														<Controller
															control={contractForm.control}
															name='isAdditionalCompensation6'
															defaultValue={false}
															render={({ field }) => (
																<div
																	onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																	style={{ userSelect: 'none' }}
																>
																	<Box display={'flex'} alignItems={'flex-start'}>
																		<input
																			{...field}
																			value={field.value + ''}
																			checked={field.value}
																			onChange={(e) => field.onChange(e)}
																			type='checkbox'
																			disabled={props?.isPrint}
																			style={{ marginTop: '3px' }}
																		/>
																	</Box>
																</div>
															)}
														/>
														<span> 6. Integration and/or Customization </span>
													</Box>
												</td>
												<td width={200}>
											100% 
												</td>
												<td width={200}>
											100% 
												</td>
											</tr>
											<tr>
												<td>
													<Box display={'flex'} alignItems={'flex-start'}>
														<Controller
															control={contractForm.control}
															name='isAdditionalCompensation7'
															defaultValue={false}
															render={({ field }) => (
																<div
																	onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																	style={{ userSelect: 'none' }}
																>
																	<Box display={'flex'} alignItems={'flex-start'}>
																		<input
																			{...field}
																			value={field.value + ''}
																			checked={field.value}
																			onChange={(e) => field.onChange(e)}
																			type='checkbox'
																			disabled={props?.isPrint}
																			style={{ marginTop: '3px' }}
																		/>
																	</Box>
																</div>
															)}
														/>
														<span> 7. “Package” rights to 1, 2, 3, 4, 5 and 6 above </span>
													</Box>
												</td>
												<td width={200}>
											200% 
												</td>
												<td width={200}>
											Not available
												</td>
											</tr>
											<tr>
												<td>
													<Box display={'flex'} alignItems={'flex-start'}>
													
														<span> AVAILABLE ONLY BY PRIOR NEGOTIATION WITH AND APPROVAL OF SAG-AFTRA:  </span>
													</Box>
												</td>
												<td colSpan={2} rowSpan={4}>
													<p>PERFORMER does NOT consent to use of his/her services made hereunder
											for:</p> 
													<Box display={'flex'} alignItems={'flex-start'}>
														<Controller
															control={contractForm.control}
															name='isAddCompensation1'
															defaultValue={false}
															render={({ field }) => (
																<div
																	onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																	style={{ userSelect: 'none' }}
																>
																	<Box display={'flex'} alignItems={'flex-start'}>
																		<input
																			{...field}
																			value={field.value + ''}
																			checked={field.value}
																			onChange={(e) => field.onChange(e)}
																			type='checkbox'
																			disabled={props?.isPrint}
																			style={{ marginTop: '3px' }}
																		/>
																	</Box>
																</div>
															)}
														/>
														<span> Network Television </span>
													</Box>
													<Box display={'flex'} alignItems={'flex-start'}>
														<Controller
															control={contractForm.control}
															name='isAddCompensation2'
															defaultValue={false}
															render={({ field }) => (
																<div
																	onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																	style={{ userSelect: 'none' }}
																>
																	<Box display={'flex'} alignItems={'flex-start'}>
																		<input
																			{...field}
																			value={field.value + ''}
																			checked={field.value}
																			onChange={(e) => field.onChange(e)}
																			type='checkbox'
																			disabled={props?.isPrint}
																			style={{ marginTop: '3px' }}
																		/>
																	</Box>
																</div>
															)}
														/>
														<span> Pay Cable Television </span>
													</Box>
													<Box display={'flex'} alignItems={'flex-start'}>
														<Controller
															control={contractForm.control}
															name='isAddCompensation3'
															defaultValue={false}
															render={({ field }) => (
																<div
																	onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																	style={{ userSelect: 'none' }}
																>
																	<Box display={'flex'} alignItems={'flex-start'}>
																		<input
																			{...field}
																			value={field.value + ''}
																			checked={field.value}
																			onChange={(e) => field.onChange(e)}
																			type='checkbox'
																			disabled={props?.isPrint}
																			style={{ marginTop: '3px' }}
																		/>
																	</Box>
																</div>
															)}
														/>
														<span> OTC sales of Audio Only Program </span>
													</Box>
												</td>
											</tr>
											<tr>
												<td>
													<Box display={'flex'} alignItems={'flex-start'}>
														<Controller
															control={contractForm.control}
															name='isAdditionalCompensation8'
															defaultValue={false}
															render={({ field }) => (
																<div
																	onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																	style={{ userSelect: 'none' }}
																>
																	<Box display={'flex'} alignItems={'flex-start'}>
																		<input
																			{...field}
																			value={field.value + ''}
																			checked={field.value}
																			onChange={(e) => field.onChange(e)}
																			type='checkbox'
																			disabled={props?.isPrint}
																			style={{ marginTop: '3px' }}
																		/>
																	</Box>
																</div>
															)}
														/>
														<span> 8. Network Television </span>
													</Box>
												</td>
											</tr>
											<tr>
												<td>
													<Box display={'flex'} alignItems={'flex-start'}>
														<Controller
															control={contractForm.control}
															name='isAdditionalCompensation9'
															defaultValue={false}
															render={({ field }) => (
																<div
																	onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																	style={{ userSelect: 'none' }}
																>
																	<Box display={'flex'} alignItems={'flex-start'}>
																		<input
																			{...field}
																			value={field.value + ''}
																			checked={field.value}
																			onChange={(e) => field.onChange(e)}
																			type='checkbox'
																			disabled={props?.isPrint}
																			style={{ marginTop: '3px' }}
																		/>
																	</Box>
																</div>
															)}
														/>
														<span> 9. Pay Cable Television </span>
													</Box>
												</td>
											</tr>
											<tr>
												<td>
													<Box display={'flex'} alignItems={'flex-start'}>
														<Controller
															control={contractForm.control}
															name='isAdditionalCompensation10A'
															defaultValue={false}
															render={({ field }) => (
																<div
																	onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																	style={{ userSelect: 'none' }}
																>
																	<Box display={'flex'} alignItems={'flex-start'}>
																		<input
																			{...field}
																			value={field.value + ''}
																			checked={field.value}
																			onChange={(e) => field.onChange(e)}
																			type='checkbox'
																			disabled={props?.isPrint}
																			style={{ marginTop: '3px' }}
																		/>
																	</Box>
																</div>
															)}
														/>
														<span>10A. Audio Only Programs sold to the general public (Section 9.E.2) </span>
													</Box>
												</td>
											</tr>
											<tr>
												<td>
													<Box display={'flex'} alignItems={'flex-start'}>
														<Controller
															control={contractForm.control}
															name='isAdditionalCompensation10B'
															defaultValue={false}
															render={({ field }) => (
																<div
																	onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																	style={{ userSelect: 'none' }}
																>
																	<Box display={'flex'} alignItems={'flex-start'}>
																		<input
																			{...field}
																			value={field.value + ''}
																			checked={field.value}
																			onChange={(e) => field.onChange(e)}
																			type='checkbox'
																			disabled={props?.isPrint}
																			style={{ marginTop: '3px' }}
																		/>
																	</Box>
																</div>
															)}
														/>
														<span>10B. Sale or Rental to General Public (Over the Counter) – Except Audio Only, see above </span>
													</Box>
												</td>
												<td colSpan={2}>
												200% for number of days worked excluding OT & penalties
												</td>
											</tr>
											<tr>
												<td>
													<Box display={'flex'} alignItems={'flex-start'}>
														<Controller
															control={contractForm.control}
															name='isAdditionalCompensation11'
															defaultValue={false}
															render={({ field }) => (
																<div
																	onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																	style={{ userSelect: 'none' }}
																>
																	<Box display={'flex'} alignItems={'flex-start'}>
																		<input
																			{...field}
																			value={field.value + ''}
																			checked={field.value}
																			onChange={(e) => field.onChange(e)}
																			type='checkbox'
																			disabled={props?.isPrint}
																			style={{ marginTop: '3px' }}
																		/>
																	</Box>
																</div>
															)}
														/>
														<span>11. Programs for Government Service (Use: Non-network and foreign TV, theatrical) </span>
													</Box>
												</td>
												<td>40% </td>
												<td>Not available</td>
											</tr>
										</table>
									</td>
								</tr>
							</table>
							<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`} style={{borderTop:'0px'}}>
								<tr>
									<td width={5} style={{width:'5px', maxWidth:'5px'}}>
									9
									</td>
									<td>
										<p><b>WARDROBE FURNISHED BY PERFORMER</b> - Fee covers use of wardrobe for:<b>PRINCIPAL – 2 days; EXTRA – 1 day.</b> </p>
										<p>PRINCIPAL: If required to bring one change and wears it, pay fee; if not worn, no fee is due. If required to bring more than one change, pay fee for
									each change even if not worn. EXTRA: Pay fee for each change Extra is required to bring.</p>
										<div style={{display:'flex', alignItems:'center', flexWrap:'wrap', gap:'5px'}}>
											<span>Minimum Fees: Day Wear (Principal)/1st Change (Extra):</span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'70px'}}>
												<Controller
													control={contractForm.control}
													name='dayWear'
													render={({ field }) => (
														<NabuTextbox
															{...field}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? 'Day Wear' : ''}
															onChange={(e: any) => {
																const value = e.target.value || '';
																if (/^\d*$/.test(value)) {
																	field.onChange(value);
															
																	const numericValue = Number(value);
																	contractForm.setValue('dayWearTotal', numericValue * 19, { shouldDirty: true });
																}
															}}
															type='number'
														/>
													)}
												/>
											</FormControl> 
											<span>x$19 = $</span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'70px'}}>
												<Controller
													control={contractForm.control}
													name='dayWearTotal'
													render={({ field }) => (
														<NabuTextbox
															{...field}
															disabled={true}
															value={contractForm.watch('dayWearTotal')}
														/>
													)}
												/>
											</FormControl> 
											<span>; 2nd + Change (Extras Only):</span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'70px'}}>
												<Controller
													control={contractForm.control}
													name='change2nd'
													render={({ field }) => (
														<NabuTextbox
															{...field}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? '2nd + change' : ''}
															onChange={(e: any) => {
																const value = e.target.value || '';
																if (/^\d*$/.test(value)) {
																	field.onChange(value);
															
																	const numericValue = Number(value);
																	contractForm.setValue('change2ndTotal', numericValue * 6, { shouldDirty: true });
																}
															}}
															type='number'
														/>
													)}
												/>
											</FormControl> 
											<span>x $6 =$</span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'70px'}}>
												<Controller
													control={contractForm.control}
													name='change2ndTotal'
													render={({ field }) => (
														<NabuTextbox
															{...field}
															disabled={true}
															value={contractForm.watch('change2ndTotal')}
														/>
													)}
												/>
											</FormControl> 
											<span>; Black Tie/Specialty:</span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'70px'}}>
												<Controller
													control={contractForm.control}
													name='blackTie'
													render={({ field }) => (
														<NabuTextbox
															{...field}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? 'BlackTie/Speciality' : ''}
															onChange={(e: any) => {
																const value = e.target.value || '';
																if (/^\d*$/.test(value)) {
																	field.onChange(value);
															
																	const numericValue = Number(value);
																	contractForm.setValue('blackTieTotal', numericValue * 29, { shouldDirty: true });
																}
															}}
															type='number'
														/>
													)}
												/>
											</FormControl> 
											<span>x $29 =$</span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'70px'}}>
												<Controller
													control={contractForm.control}
													name='blackTieTotal'
													render={({ field }) => (
														<NabuTextbox
															{...field}
															disabled={true}
															value={contractForm.watch('blackTieTotal')}
														/>
													)}
												/>
											</FormControl> 
										</div>
									</td>
								</tr>
								<tr>
									<td colSpan={2} style={{ background: '#515151', color: '#FFF' }}>
										<b>10. SPECIAL PROVISIONS</b>: 
									</td>
								</tr>
								<tr>
									<td colSpan={2} >
										{/* <div className={styles.textareaSection}>
																	<NabuBaseTextArea 	className={styles.specialProvision} rows={5} />
																</div> */}
										<div className={styles.textareaSection}> 														
											{
												props?.isPrint ? (
													<p className={styles.descriptionPrintText}>{contractForm.watch('specialProvisions')}</p>
												) : (<div className={styles.textareaSection} > 
													<Controller
														control={contractForm.control}
														name='specialProvisions'
														render={({ field }) => (
															<NabuBaseTextArea
																className={styles.specialProvision}
																{...field}
																rows={5}
																disabled={props?.isPrint}
																placeholder={!props?.isPrint ? 'SPECIAL PROVISIONS' : ''}
															/>
														)}
													/>
													{contractForm.formState.errors?.specialProvisions
														?.message && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors
																	?.specialProvisions.message
															}
														</FormHelperText>
													)}
												</div> )
											}
										</div>
									</td>
								</tr>
								<tr>
									<td width={5} style={{width:'5px', maxWidth:'5px'}}>
									11
									</td>
									<td>
										<p><b>GENERAL:</b> All terms and conditions of the SAG-AFTRA Corporate/Educational & Non-Broadcast Contract shall be applicable to such employment.</p>
									</td>
								</tr>
							</table>
							<br></br>
							<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
								<tr>
									<td width={130}>Producer: </td>
									<td>
										<FormControl fullWidth className={styles.tableForms}><NabuTextbox disabled={true} placeholder="Signature" /></FormControl> 
									</td>
									<td width={130}>Performer:</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}><NabuTextbox disabled={true} placeholder="Signature – (if minor, Parent’s or Guardian’s signature)" /></FormControl> 
									</td>
								</tr>
								<tr>
									<td>
								By:
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='producerName'
												defaultValue=''
												render={({ field }) => (
													<NabuTextbox
														{...field}
														placeholder={!props?.isPrint ? 'Name and Title' : ''}
														disabled={true}
													/>
												)}
											/>
										</FormControl> 
									</td>
									<td>
								Soc. Security #:
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='performerSSN'
												render={({ field }) => (
													<>
														<input
															{...field}
															placeholder={!props?.isPrint ? 'SSN' : ''}
															disabled={props?.isPrint}
															value={getPerformerSSN()}
														/>
													</>
												)}
											/>
										</FormControl> 
									</td>
								</tr>
								<tr>
									<td>
								Address:
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												// name='producerAddressId'
												name='producerAddress'
												render={({ field }) => (
													<>
														{/* <LookAheadSelect
																					{...field}
																					placeholder={!props?.isPrint ? 'Select Address' : ''}
																					key={`producer-address-${contractForm.watch(
																						'producerCompanyId.value'
																					)}-${contractForm.watch('producerAddressId.value')}`}
																					filterProperty='Line1'
																					sortField='IsPrimary'
																					sortDescending={true}
																					onOptionLoaded={(options :any) => {
																						if(options?.length && !contractForm.watch('producerAddressId.value')){
																							contractForm.setValue('producerAddressId', options[0], { shouldDirty: true});
																							contractForm.trigger('producerAddressId', { shouldFocus: true});
																							onAddressChange(contractForm.watch('producerAddressId.value') || '', 'producerAddress');
																						}
																					}}
																					APICaller={(filterModel) =>
																						getAllAddressByCompany(
																							filterModel,
																							contractForm.watch(
																								'producerCompanyId.value'
																							) || ''
																						)
																					}
																					optionGenerator={
																						producerAddressOptionGenerator
																					}
																					isDisabled={
																						!contractForm.watch(
																							'producerCompanyId.value'
																						) || props?.isPrint
																					}
																					dropDownsize={50}
																					isClearable={true}
																					isSearchText={true}
																					onChange={(e: any) => {
																						field.onChange(e);
																						onAddressChange(contractForm.watch('producerAddressId.value') || '', 'producerAddress');
																					}}
																					cacheOptions={false}
																					defaultOptions
																					className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				/> */}
														<NabuTextbox
															{...field}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? 'Address' : ''}
															onChange={(e:any) => {
																field.onChange(e);
															}}
														/>
													</>
												)}
											/>
											{/* {contractForm.formState.errors?.producerAddressId
																		?.value?.message && (
																		<FormHelperText
																			error={true}
																			data-cy='errorHelper_producer'
																		>
																			{
																				contractForm.formState?.errors
																					?.producerAddressId.value.message
																			}
																		</FormHelperText>
																	)} */}
										</FormControl> 
									</td>
									<td>
								Phone:
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												key={`select-phone-${contractForm.watch(
													'performerPhoneId'
												)}-${contractForm.watch(
													'performerId.value'
												)}`}
												control={contractForm.control}
												name='performerPhoneId'
												defaultValue=''
												render={({ field }) => (
													<>
														<NabuSelect
															{...field}
															key={`select-phone-${contractForm.watch(
																'performerPhoneId'
															)}-${contractForm.watch(
																'performerId.value'
															)}`}
															placeholder={!props?.isPrint ? 'Select Phone' : ''}
															convertValueToUpperCase={false}
															enableEmptySelection={true}
															disabled={props?.isPrint || !contractForm.watch('performerId.value')}
															options={getPerformerPhoneNumberOptions()}
															handleDefaultSelection={() => !contractForm.watch('performerPhoneId')}
														/>
														{
															<Box sx={{mt:1.3}}>
																{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																							<AddPhoneNumberInline
																								formData={{ ...new PhoneNumberFormModel()}}
																								onSubmit={onPerformerPhoneNumberInlineSubmit}
																								isOpen={phoneNumberPopOver.isOpen && phoneNumberPopOver?.for == 'PerformerTelephone-address'|| false}	
																								onClose={closePhoneNumberInlinePopOver}
																								title='Phone Number'
																								onPopOverOpen={(e) => openPhoneNumberInlinePopOver(e, 'PerformerTelephone-address')}
																								event={phoneNumberPopOver?.event}	
																							/>
																}
															</Box>	
														}
													</>
												)}
											/>
											{contractForm.formState?.errors
												?.performerPhoneId?.message && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors
															?.performerPhoneId?.message
													}
												</FormHelperText>
											)}
										</FormControl> 
									</td>
								</tr>
								<tr>
									<td>
								Email / Phone #:
									</td>
									<td>
										<table width={'100%'} border={0} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
											<tr>
												<td colSpan={2} >
													<FormControl fullWidth className={styles.tableForms}>
														<Controller
															control={contractForm.control}
															name='producerCompanyEmailId'
															render={({ field }) => (
															// <>
															// 	<NabuSelect
															// 		{...field}
															// 		key={`select-em-${contractForm.watch(
															// 			'producerAddressId.value'
															// 		)}-${contractForm.watch('producerCompanyEmailId')}`}
															// 		placeholder={!props?.isPrint ? 'Select Email' : ''}
															// 		convertValueToUpperCase={false}
															// 		enableEmptySelection={true}
															// 		disabled={props?.isPrint || !contractForm.watch('producerAddressId.value')}
															// 		options={getCompanyEmailOptions(contractForm.watch('producerAddressId.value'))}
															// 		handleDefaultSelection={() => !contractForm.watch('producerCompanyEmailId')}
															// 	/>
															// 	{
															// 		<Box sx={{mt:1.3}}>
															// 			{(contractForm.watch('producerAddressId.value') && (!props.isPrint)) &&
															// 	<AddEmailInline
															// 		formData={{ ...new EmailFormModel()}}
															// 		isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Producer-email-address'|| false}	
															// 		onSubmit={onProducerEmailInlineSubmit}
															// 		onClose={closeEmailInlinePopOver}
															// 		title='Email address'
															// 		onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Producer-email-address')}
															// 		event={emailPopOver?.event}
															// 	/>
															// 			}
															// 		</Box>	
															// 	}
															// </>
																<NabuTextbox
																	{...field}
																	disabled={props?.isPrint}
																	placeholder={!props?.isPrint ? 'Producer Email' : ''}
																	onChange={(e:any) => {
																		field.onChange(e);
																	}}
																/>
															)}
														/>
														{contractForm.formState?.errors
															?.producerCompanyEmailId?.message && (
															<FormHelperText error={true}>
																{
																	contractForm.formState?.errors
																		?.producerCompanyEmailId?.message
																}
															</FormHelperText>
														)}
													</FormControl>
													<FormControl
														fullWidth
														className={styles.tableForms}
													>
														<Controller
															control={contractForm.control}
															name='producerCompanyPhoneId'
															// defaultValue=''
															render={({ field }) => (
																<>
																	{/* <NabuSelect
																							{...field}
																							key={`select-phone-${contractForm.watch(
																								'producerCompanyPhoneId'
																							)}-${contractForm.watch(
																								'producerAddressId.value'
																							)}`}
																							placeholder={!props?.isPrint ? 'Select Phone' : ''}
																							convertValueToUpperCase={false}
																							enableEmptySelection={true}
																							disabled={
																								props?.isPrint ||
																							!contractForm.watch(
																								'producerAddressId.value'
																							)
																							}
																							options={getCompanyPhoneOptions(
																								contractForm.watch(
																									'producerAddressId.value'
																								)
																							)}
																							handleDefaultSelection={() => !contractForm.watch('producerCompanyPhoneId')}
																						/>
																						{contractForm.watch('producerAddressId.value') &&
																		(!props?.isPrint) && (
																							<AddPhoneNumberInline
																								formData={{ ...new PhoneNumberFormModel()}}
																								onSubmit={onProducerPhoneNumberInlineSubmit}
																								onClose={closePhoneNumberInlinePopOver}
																								title='Phone Number'
																								onPopOverOpen={(e) =>openPhoneNumberInlinePopOver(e, 'ProducerCompany-Address')}
																								event={phoneNumberPopOver?.event}
																								isOpen={phoneNumberPopOver.isOpen && phoneNumberPopOver?.for == 'ProducerCompany-Address' || false}																		/>
																						)} */}
																	<input
																		className={styles.numberInputTeams}
																		{...field}
																		disabled={props?.isPrint}
																		type='number'
																		placeholder={!props?.isPrint ? 'Producer Phone' : ''}
																		onChange={(e:any) => {
																			const value = e.target.value;
																			if (value.length <= 10) {
																				field.onChange(e);
																			}
																		}}
																	/>
																</>
															)}
														/>
														{contractForm.formState?.errors
															?.producerCompanyPhoneId?.message && (
															<FormHelperText error={true}>
																{
																	contractForm.formState?.errors
																		?.producerCompanyPhoneId?.message
																}
															</FormHelperText>
														)}
														
													</FormControl>
												</td>
											</tr>
										</table>
									</td>
									<td>
								Email (Optional):
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												key={`select-${contractForm.watch('performerId.value')}-${contractForm.watch('performerEmail')}`}
												control={contractForm.control}
												name='performerEmail'
												defaultValue=''
												render={({ field }) => (
													<>
														<NabuSelect
															{...field}
															defaultCase={true}
															key={`select-${contractForm.watch('performerId.value')}-${contractForm.watch('performerEmail')}`}
															options={getPerformerEmailsOptions()}
															disabled={props?.isPrint || !contractForm.watch('performerId.value')}
															convertValueToUpperCase={false}
															placeholder={!props?.isPrint ? 'Select Email' : ''}
															handleDefaultSelection={() => {
																return !contractForm.watch('performerEmail');
															}}
														/>
														{/* {
																								<Box sx={{mt:1.3}}>
																									{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																						<AddEmailInline
																							formData={{ ...new EmailFormModel()}}
																							isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Performer-email-address'|| false}	
																							onSubmit={onPerformerEmailInlineSubmit}
																							onClose={closeEmailInlinePopOver}
																							title='Email address'
																							onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Performer-email-address')}
																							event={emailPopOver?.event}
																						/>
																									}
																								</Box>	
																							} */}
													</>
												)}
											/>
											{/* {contractForm?.formState?.errors?.performerEmail
																		?.message && (
																		<FormHelperText error={true}>
																			{
																				contractForm.formState?.errors?.performerEmail
																					.message
																			}
																		</FormHelperText>
																	)} */}
										</FormControl> 
									</td>
								</tr>

							</table>

						</div>
					</div>
				</>
				{/* Main Contract Form End */}
			</ContractMutationWrapper>
		</>
	);
};
export default TeamSagAftraSdEmpCtrIndEduFilmVideoPgm;
