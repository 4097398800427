import NabuButton from '@/common/components/atoms/button/button';
import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import LookAheadSelect from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuMultiselect from '@/common/components/atoms/multiselect/multiselect';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import NabuTimePicker from '@/common/components/molecules/timepicker/timePicker';
import AddCompanyInline from '@/common/components/templates/pages/add/addCompanyInline';
import { IPopOverState } from '@/common/components/templates/pages/add/addNewAsset';
import AddPersonAddressInline from '@/common/components/templates/pages/add/addPersonAddressInline';
import AddPersonInline from '@/common/components/templates/pages/add/addPersonInline';
import AddTalentAgentInline from '@/common/components/templates/pages/add/addTalentAgentInline';
import { CONTRACT_PERMITTED_USE, ContractStatus, DefaultPageSize, PeriodUnitsOptions } from '@/common/constants/constants';
import { COMPANY_MAIN_TYPE, OPERATORS } from '@/common/enums';
import { CompanyMainType, ICompanyAddress, ICompanyRecord } from '@/common/types/company';
import { IBaseContract, IContractNonUnionForm } from '@/common/types/contract';
import { IFilterOption } from '@/common/types/filterModel';
import { displayAddress, displayOnlyEmail, displayPhoneNumberWithoutLabel, getCompanyDisplayName, getCountryName, getLocaleDate, getLocaleDateTime, getNotificationText, getPersonAliasFullName, getPersonFullName, getStateName, numberOnChangeEventHandler, sortEmailOrPhoneNumberForDropDown } from '@/common/utils/utils';
import { getAllAddressByCompany, getAllProducts, getCompanyBasicDetails, getStaffListByCompany } from '@/services/company';
import { addContractNonUnion, updateContractNonUnion } from '@/services/contract';
import { IAssetRecord } from '@/services/models/asset';
import { IPersonAddress, IPersonRecord } from '@/services/models/person';
import { getAgentInfo, getAllAddressByPerson, GetByWithFullNameSearchForBasicDetail } from '@/services/person';
import store from '@/store';
import { updateContractFormState } from '@/store/contract/reducer';
import { yupResolver } from '@hookform/resolvers/yup';
import { Add, DeleteForever } from '@mui/icons-material';
import { Autocomplete, Box, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { HttpStatusCode } from 'axios';
import { continents, countries } from 'countries-list';
import { City, Country, State } from 'country-state-city';
import { addDays, addMonths, addWeeks, addYears, isBefore } from 'date-fns';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm, UseFormReturn } from 'react-hook-form';
import { useMutation } from 'react-query';
import styles from '../../addContracts.module.scss';
import ContractMutationWrapper, { IContractFormState } from '../contractMutationWrapper/contractMutationWrapper';
import useContractMutation from '../contractMutationWrapper/useContractMutation';
import ExpiryDateForm from '../expiryDateForm';
import contractTerritorySchema from './schema';

interface Props {
	mainForm?: UseFormReturn<IBaseContract, any, undefined>;
	onCancel?: VoidFunction;
	onSave?: CallableFunction;
	handlePrintStateOfContract?: CallableFunction;
	initialValue?: IContractNonUnionForm;
	isPrint?: boolean;
	isEdit?: boolean;
	contractId?: string;
	defaultValue?: IBaseContract,
	address?: ICompanyAddress[];
	people?: IPersonRecord[];
	assets?:IAssetRecord[];
}

const NonUnionTalentPrintModel = (props: Props) => {

	// #region State variable & hooks.
	const contractForm = useForm<IContractNonUnionForm>({
		defaultValues: !props?.initialValue
			? {
				...(props?.mainForm?.getValues() || {}),
				...(props?.defaultValue || {}),
				workDays: [{}],
				commercials: [{}],
				territories: [{countries: [], region: ''}],
				additionalFees:[{fees: 0,additionalFeeFlatFee:true,additionalFeeHourly:false,additionalFeeDefine:'',otherFee:''}]
			}
			: {...(props?.initialValue || {}), 
				commercials: props?.initialValue?.commercials?.length ? props?.initialValue?.commercials  :[{}], 
				workDays: props?.initialValue?.workDays?.length ? props?.initialValue?.workDays : [{}],
				additionalFees:props?.initialValue?.additionalFees?.length ? props?.initialValue?.additionalFees : [{}],
			},
		shouldFocusError: false,
		resolver: yupResolver(contractTerritorySchema),
	});

	const { 
		handlePreContractSubmissionTasks, 
		addMultipleContractDetails, 
		notification, 
		setPacketFormState, 
		params, 
		setParams, 
		onSubmitError ,
		ADVERTISER_FILTER, 
		AD_AGENCY_FILTER, 
		DEFAULT_COUNTRY_CODE, 
	} = useContractMutation<IContractNonUnionForm>();

	const [contractFormState, setContractFormState] = useState<IContractFormState>({});
	const [contractFormData, setContractFormData] = useState<IContractNonUnionForm>();
	const [personOptions, setPersonOptions] = useState<IPersonRecord[]>(
		props?.people || []
	);
	const [addressOptions, setAddressOptions] = useState<ICompanyAddress[]>(
		props?.address || []
	);
	const [, setAssetOptions] = useState<IAssetRecord[]>(
		props?.assets || []
	);
	const [addressPopOver, setAddressPopOver] = useState<IPopOverState>({
		isOpen: false,
	});
	const [companyPopoverInline, setCompanyPopoverInline] = useState<IPopOverState>({
		isOpen: false,
	});
	const [staffOptions, setStaffOptions] = useState([] as any);

	const workDayFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'workDays',
	});
	const territoryFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'territories'
	});
	// #endregion

	// #region constants and other variables.
	const PRODUCT_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.NOT_EQUAL,
			property: 'ProductName',
			value: '',
		},
	];
	// #endregion

	// #region Look a head option generator helper functions.
	const companyOptionGenerator = (responseData: ICompanyRecord[]) => {
		const address = responseData?.reduce((acc, curr) => {
			if (curr?.addresses?.length) {
				acc.push(...(curr?.addresses || []));
			}
			return acc;
		}, [] as ICompanyAddress[]);

		handleStateOptions(address, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));
	};

	const performerOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');
		return responseData?.map((d) => ({
			value: d?.id,
			label: getPersonFullName(d?.personName),
		}));
	};

	const adAgencyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const getCompanyEmailOptions = (addressId?: string) => {
		const unsortedEmails =
			addressOptions?.find((a) => a?.id === addressId)?.emails || [];
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const getPerformerPhoneNumberOptions = () => {
		const phoneNumbers =
			personOptions?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.phoneNumbers || [];

		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	const getPhoneNumberOptions = (addressId: string = '') => {
		const unsortedPhoneNumbers =
			addressOptions?.find((a) => a?.id === addressId)?.phoneNumbers || [];
		
		const phoneNumbers = sortEmailOrPhoneNumberForDropDown(unsortedPhoneNumbers);
		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	const getPerformerEmailsOptions = () => {
		const unsortedEmails =
			personOptions?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.emails || [];

		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const productOptionGenerator = (responseData) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: d?.productName?.toUpperCase(),
		}));
	};
	// #endregion

	// #region Helper function section.
	const handleStateOptions = (
		responseData: any[],
		type: 'PERSON' | 'ADDRESS' | 'ASSET'
	) => {
		const cb = {
			PERSON: setPersonOptions,
			ADDRESS: setAddressOptions,
			ASSET:setAssetOptions,
		}[type];

		if (responseData && Array.isArray(responseData)) {
			cb((prev) => {
				const oldFilter = prev?.filter(
					(d) => !responseData?.some((r) => r?.id === d?.id)
				);
				return [...oldFilter, ...responseData];
			});
		}
	};

	const getPerformerFilter = (): IFilterOption[] => {
		const DEFAULT_FILTER: IFilterOption = {
			value: JSON.stringify(['TALENT']),
			property: 'PersonType',
			operator: OPERATORS.IN,
			isCaseSensitive: false,
		};
		return [DEFAULT_FILTER];
	};

	const onPersonAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('performerId.value')
			);
		
			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
						person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('performerAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const getTalentAliasName = () => {
		const person = personOptions?.find((a) => a?.id === contractForm.watch('performerId.value'));
		const aliasName = getPersonAliasFullName(person?.aliasName);
		const formData: any = contractForm.getValues();
		return aliasName === '' && formData?.contractNonUnion?.akaName || aliasName;
	};

	const continentsOptions = Object.keys(continents).map((key) => ({
		value: key,
		label: continents[key]
	}));

	const getCountryOptions = Country.getAllCountries().map( country => ({ label: country.name?.toUpperCase(), value: country.isoCode }));
	
	const getStateOptions = () => {
		const states = State.getStatesOfCountry(DEFAULT_COUNTRY_CODE).map(
			(state) => ({ label: state.name?.toUpperCase(), value: state.isoCode })
		);
		if (!states.length) states.unshift({ label: 'NA', value: 'NA' });
		return states;
	};

	const getCityOptions = (stateCode?: string) =>
		stateCode
			? City.getCitiesOfState(DEFAULT_COUNTRY_CODE, stateCode)?.map((city) => ({
				label: city.name?.toUpperCase(),
				value: city.name?.toUpperCase(),
			})) || []
			: [];	
	
	const getCountryLabel = () =>{
		const result= getCountryName(contractForm.watch('sessionCountry')|| '' )?.toUpperCase();
		return result;
	};

	const getStateLabel = ()=>{
		const result= getStateName( DEFAULT_COUNTRY_CODE,contractForm?.watch('sessionState')||'')?.toUpperCase();
		return result;
	};

	const onAddWorkDay = () => {
		if (workDayFieldArray.fields.length) {
			const previousRow = {
				...contractForm.watch(
					`workDays.${workDayFieldArray.fields.length - 1}`
				),
				id: undefined,
			};

			workDayFieldArray.append({
				...previousRow,
				date: previousRow?.date ? addDays(new Date(previousRow?.date || ''), 1) : '',
			});
		}
	};

	const onRemoveWorkDay = (index: number) => {
		if (workDayFieldArray.fields.length > 1) {
			workDayFieldArray.remove(index);
		}
	};

	const resetTerritoriesValues = () => {
		contractForm.getValues('territories')?.map((_,index) => {
			contractForm.setValue(`territories.${index}`, {countries: [], region: ''});
		});
		contractForm.setValue('territories', [{countries: [], region: ''}]);
	};

	const getContinentCountries = (continent: string) => {
		const continentCountries = Object.keys(countries)
			.filter((k) => k !== 'AQ')
			.map((k) => ({ ...countries[k], iso: k }))
			.sort((a, b) => a.name.localeCompare(b.name))
			.filter((c) => c.continent == continent)
			.map((c) => ({label: c.name.toUpperCase(), value: c.iso}));
		return continentCountries;
	};

	const getPermittedUseChangeData = (key: keyof (typeof CONTRACT_PERMITTED_USE)): string[] => {
		const currentPermittedUses = contractForm.watch('permittedUse') || [];
		return [
			...(
				currentPermittedUses?.includes(CONTRACT_PERMITTED_USE[key]?.toUpperCase())
					? currentPermittedUses?.filter((p) => p?.toUpperCase() !== CONTRACT_PERMITTED_USE[key]?.toUpperCase()) ?? []
					: [...(currentPermittedUses || []), CONTRACT_PERMITTED_USE[key]?.toUpperCase()]
			)
		];
	};
	// #endregion

	// #region Form submission helper functions.
	const handleFormClose = () => {
		contractForm?.reset();
		props?.onCancel && props?.onCancel();
	};
	
	const handleSaveAndDuplicate = (data: IContractNonUnionForm) => {
		setContractFormData(data);
	};

	const handleTalentDetailsForm = (data) => {
		const handleMultipleContractAdd = (payloadData) => {
			if(data) {
				const contractAddModels = data?.talentRecords?.map((talent, index) => {
					const submitData = {
						...payloadData,
						id: undefined,
						status:ContractStatus?.Draft,
						partName: talent?.role,
						talentAgencyId: talent?.agent?.value,
						performerId: talent?.talentName?.value,
						contractDisplayName: `${payloadData?.contractDisplayName}_copy_${index+1}`,
						compensation: talent?.compensation,
						performerAddressId:undefined,
						performerEmailId:undefined,
						performerPhoneId:undefined,
						talentAgencyAddressId:undefined,
						talentAgencyStaffId:undefined,
						talentAgencyPhoneId:undefined,
						talentAgencyEmailId:undefined
					};
					return submitData;
				});
				const multipleContractData = {
					contractAddNonUnionModels: contractAddModels,
				};
				addMultipleContractDetails(multipleContractData);
			}
		};
		contractFormData &&
			handleContractSubmission(contractFormData, handleMultipleContractAdd);
	};


	const handleContractSubmission = async (submitData: IContractNonUnionForm, handleMultipleContractAdd?: CallableFunction) => {
		handlePreContractSubmissionTasks(submitData, (data) => {

			const workDays = data?.workDays?.filter((d) => d?.date) || [];
			const mainFormValues = props?.mainForm?.getValues();
			const addMainFormValues = !props?.isEdit ? {
				contractType: mainFormValues?.contractType,
				contractDisplayName: mainFormValues?.contractDisplayName,
				unionType: mainFormValues?.unionType,
				unionAgreement: mainFormValues?.unionAgreement,
				payrollVendorAddressId: mainFormValues?.payrollVendorAddressId || '',
			} : {};
	
			const commercials = data?.commercials?.map((d) => ({
				...d,
				assetId: d?.assetId?.value,
			}));
	
			const submitData = {
				...data,
				...addMainFormValues,
				payrollVendorAddressId: data?.payrollVendorAddressId?.value,
				contractNonUnion: undefined,
				payrollVendorId: data?.payrollVendorId?.value,
				performerId: data?.performerId?.value,
				advertiserId: data?.advertiserId?.value,
				adAgencyId: data?.adAgencyId?.value,
				productId: data?.productId?.value,
				performerAddressId: data?.performerAddressId?.value,
				recordingCompanyId: data?.recordingCompanyId?.value,
				adAgencyAddressId: data?.adAgencyAddressId?.value,
				talentAgencyAddressId: data?.talentAgencyAddressId?.value,
				talentAgencyId: data?.talentAgencyId?.value,
				talentAgencyStaffId: data?.talentAgencyStaffId?.value,
				brandId:data?.brandId?.value,
				workDays,
				commercials,
				productionCompanyId: data?.productionCompanyId?.value,
			};
	
			if (!props?.isEdit) {
				addContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					},
				});
			} else {
				editContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			} 

		});
	};

	const onSubmit = (data: IContractNonUnionForm) => {
		if(!props?.mainForm?.watch('contractDisplayName')){
			notification.notify({
				message: 'Please fill Contract Display Name.',
				type: 'error'
			});
		}
		else{
			openExpiryDateForm(data);
		}
	};
	// #endregion

	// #region API logics
	const { mutate: addContractDetails, isLoading: isAddingContract } =
	useMutation({
		mutationKey: ['contract-add'],
		mutationFn: (payload: any) => addContractNonUnion(payload),
		onSuccess: (res) => {
			if (res?.status === HttpStatusCode.Ok) {
				if (res.data?.error?.errorMessage) {
					notification.notify({
						message: res.data?.error?.errorMessage ?? getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
					return;
				}
				notification.notify({
					message: getNotificationText('Contract', 'CREATE'),
					type: 'success',
				});
				handleFormClose();
				if(res?.data?.id && props?.onSave){
					props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
				}
				store.dispatch(updateContractFormState({
					isContractUpdated: true,
					updatedContractId: res?.data?.id
				}));
			} else
				notification.notify({
					message:
						res?.data?.error?.errorMessage ??
						getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
		},
		onError: () => {
			notification.notify({
				message: getNotificationText('Contract', 'CREATE', true),
				type: 'error',
			});
		},
	});

	const { mutate: editContractDetails, isLoading: isEditingContract } =
	useMutation({
		mutationKey: ['contract-edit'],
		mutationFn: (payload : any) => updateContractNonUnion(props?.contractId || '', payload),
		onSuccess: (res) => {
			if (res?.status === HttpStatusCode.Ok) {
				if (res.data?.error?.errorMessage) {
					notification.notify({
						message: res.data?.error?.errorMessage ?? getNotificationText('Contract', 'UPDATE', true),
						type: 'error',
					});
					return;
				}
				notification.notify({
					message: getNotificationText('Contract', 'UPDATE'),
					type: 'success',
				});
				handleFormClose();
				if(res?.data?.id && props?.onSave){
					props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
				}
				store.dispatch(updateContractFormState({
					isContractUpdated: true,
					updatedContractId: res?.data?.id
				}));
			} else
				notification.notify({
					message:
						res?.data?.error?.errorMessage ??
						getNotificationText('Contract', 'UPDATE', true),
					type: 'error',
				});
		},
		onError: () => {
			notification.notify({
				message: getNotificationText('Contract', 'CREATE', true),
				type: 'error',
			});
		},
	});
	// #endregion

	// #region Side Effects.
	useEffect(()=> {
		if (props?.isEdit) {
			if (!workDayFieldArray?.fields?.length) {
				onAddWorkDay();
			}
		}
	}, [props?.isEdit]);

	useEffect(() => {
		if(contractForm.watch('talentAgencyId')){
			const getStaffOptions = (filter: any) => {
				getStaffListByCompany(filter, contractForm.watch('talentAgencyId')?.value).then((res) => {
					const options: any = res?.data?.records?.map((staff: any) => {
						return {value: staff?.id, label: getPersonFullName(staff?.personName)};
					}) ?? [];
					setStaffOptions(options);
				});
			};
			getStaffOptions({
				pageNumber: 0,
				pageSize: DefaultPageSize,
				sortDescending: true,
				sortField: 'createdAt',
			});
		}
	}, [contractForm.watch('talentAgencyId')]);

	const isMinor = () => {
		return personOptions?.find(
			(a) => a?.id === contractForm.watch('performerId.value')
		)?.isMinor;
	};
	// #endregion

	//Expiry Date Dialog Code
	const [isExpiryDateDialogOpen, setExpiryDateDialog] = useState<{isOpen: boolean, contractData: IContractNonUnionForm | undefined, contractExpiryDate: Date | undefined}>({isOpen: false, contractData: undefined, contractExpiryDate: undefined});

	const calculateEndDate = (termValues) => {
		if(termValues.termDuration && termValues.termDurationUnit && termValues.termFromFirstUseDate){
			const days = termValues.termDuration;
			const startDate = new Date(termValues.termFromFirstUseDate);
			const date = startDate.setDate(startDate.getDate() - 1);
			const unit = termValues.termDurationUnit;
			if(date){
				let finalDate;
				if(unit == 'WEEK (S)'){
					finalDate = (addWeeks(new Date(date), days));
				}
				else if(unit == 'MONTH (S)'){
					finalDate = addMonths(new Date(date), days);
				}
				else if(unit == 'YEAR (S)'){
					finalDate = addYears(new Date(date), days);
				}
				else{
					finalDate = addDays(new Date(date), days);
				}
				return finalDate;
			}
		}
	};

	const openExpiryDateForm = (data) => {
		const termValues = {
			termDuration: data.termDuration,
			termDurationUnit: data.termDurationUnit,
			termFromFirstUseDate: data.termFromDate || data.termFromFirstUseDate
		};
		const endDate = calculateEndDate(termValues) || undefined;
		setExpiryDateDialog({isOpen: true, contractData: data, contractExpiryDate: endDate});
	};

	const closeExpiryDateForm = () => setExpiryDateDialog({isOpen: false, contractData: undefined, contractExpiryDate: undefined});
	const onSubmitOfExpiryDateForm = (data) => {
		if(isExpiryDateDialogOpen.contractData){
			const expiredStatus = data.expirationDate ? (isBefore(data.expirationDate, new Date()) ? ContractStatus.Expired : undefined) : undefined;
			const submittedData: IContractNonUnionForm = {
				...isExpiryDateDialogOpen.contractData,
				expirationDate: data.expirationDate,
				status: expiredStatus
			};
			submittedData && handleContractSubmission(submittedData);
		}
	};

	return (
		<>
			<ContractMutationWrapper
				isEdit={props?.isEdit}
				isPrint={props?.isPrint}
				contractForm={contractForm}
				contractFormState={contractFormState}
				setContractFormState={setContractFormState}
				handleFormClose={handleFormClose}
				handleSaveAndDuplicate={handleSaveAndDuplicate}
				handleTalentDetailsForm={handleTalentDetailsForm}
				isAddingContract={isAddingContract}
				isEditingContract={isEditingContract}
				onSubmit={contractForm.handleSubmit(onSubmit, onSubmitError)}
				setPacketFormState={setPacketFormState}
				isUnion={false}
				isPrintModel={true}
			>
				<div className={styles.contractFormBlock}>
					<div className={styles.bgWhite} style={{ padding: '20px' }}>
						<Grid item container columnSpacing={3}>
							<Grid item md={12}>
								<Box sx={{ pt: 4 }}>
									<h2 style={{ textAlign: 'center', fontSize: '28px' }}>
										PRINT MODEL AGREEMENT
									</h2>
								</Box>
							</Grid>
						</Grid>
						<hr></hr>
						<table
							width={'100%'}
							border={1}
							cellPadding={0}
							cellSpacing={0}
							className={`${styles.contractFormsInner} keep-together`}
						>
							<tr>
								<td width={90} style={{maxWidth:'90px'}}>Agency</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											key={`adAgency-${contractForm.watch('adAgencyId.value')}`}
											control={contractForm.control}
											name='adAgencyId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														className={
															props?.isPrint
																? styles.lookAheadSelectContract
																: styles.asyncSelect
														}
														data-cy='ad_agency'
														{...field}
														placeholder={!props?.isPrint ? 'Ad-Agency' : ''}
														filterProperty='CompanyNames.Name'
														APICaller={getCompanyBasicDetails}
														optionGenerator={companyOptionGenerator}
														defaultFilter={AD_AGENCY_FILTER}
														isDisabled={props?.isPrint}
														isClearable={true}
														onChange={(e: any) => {
															field.onChange(e);
															contractForm.setValue('adAgencyAddressId', null, {
																shouldDirty: true,
															});
															contractForm.setValue('adAgencyPhoneId', '', {
																shouldDirty: true,
															});
															contractForm.setValue('adAgencyEmailId', '', {
																shouldDirty: true,
															});
														}}
														cacheOptions={false}
														defaultOptions
													/>
												</>
											)}
										/>
										<FormHelperText error={true}>
											{contractForm.formState.errors?.adAgencyId?.message}
										</FormHelperText>
									</FormControl>
								</td>
								<td width={110} style={{maxWidth:'110px'}}>Agency Job#</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='agencyJobTitle'
											defaultValue=''
											render={({ field }) => (
												<NabuTextbox
													{...field}
													disabled={props?.isPrint}
													placeholder={!props?.isPrint ? 'Job' : ''}
												/>
											)}
										/>
										<FormHelperText error={true}>
											{contractForm.formState.errors?.agencyJobTitle?.message}
										</FormHelperText>
									</FormControl>
								</td>
							</tr>
							<tr>
								<td>Advertiser</td>
								<td>
									<div>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												key={`advertiser-${contractForm.watch(
													'advertiserId.value'
												)}-${contractForm.watch('productId.value')}`}
												control={contractForm.control}
												name='advertiserId'
												render={({ field }) => (
													<>
														<LookAheadSelect
															className={
																props?.isPrint
																	? styles.lookAheadSelectContract
																	: styles.asyncSelect
															}
															{...field}
															placeholder={!props?.isPrint ? 'Advertiser' : ''}
															filterProperty='CompanyNames.Name'
															APICaller={getCompanyBasicDetails}
															optionGenerator={companyOptionGenerator}
															defaultFilter={ADVERTISER_FILTER}
															isDisabled={props?.isPrint}
															isClearable={true}
															onChange={(e: any) => {
																field.onChange(e);
																contractForm.setValue(
																	'commercials',
																	[{} as any],
																	{ shouldDirty: true }
																);
																contractForm.setValue('productId', undefined, { shouldDirty: true});
															}}
															cacheOptions={false}
															defaultOptions
														/>
													</>
												)}
											/>
											<FormHelperText error={true}>
												{contractForm.formState.errors?.advertiserId?.message}
											</FormHelperText>
										</FormControl>
										{!props?.isPrint && (
											<div className={styles.inlineAdd}>
												<AddCompanyInline
													title='Company'
													companyType={{
														mainType:
															COMPANY_MAIN_TYPE.ADVERTISER as CompanyMainType,
														subType: [],
													}}
												/>
											</div>
										)}
									</div>
								</td>
								<td>Product</td>
								<td>
									<Controller
										control={contractForm.control}
										name='productId'
										render={({ field }) => (
											<LookAheadSelect
												{...field}
												placeholder={!props?.isPrint ? 'SELECT PRODUCT' : ''}
												key={`product-${contractForm.watch(
													'advertiserId.value'
												)}`}
												filterProperty='ProductName'
												APICaller={(filterModel) =>
													getAllProducts(
														filterModel,
														contractForm.watch('advertiserId.value')
													)
												}
												optionGenerator={productOptionGenerator}
												isDisabled={
													!contractForm.watch('advertiserId.value') ||
													props?.isPrint
												}
												defaultFilter={PRODUCT_FILTER}
												dropDownsize={20}
												isClearable={true}
												onChange={(e: any) => {
													field.onChange(e);
												}}
												cacheOptions
												defaultOptions
												className={
													props?.isPrint
														? styles.lookAheadSelectContract
														: styles.asyncSelect
												}
											/>
										)}
									/>
									<FormHelperText error={true}>
										{contractForm?.formState?.errors?.productId?.message}
									</FormHelperText>
								</td>
							</tr>
							<tr>
								<td>Job Name</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='advertiserJobTitle'
											defaultValue=''
											render={({ field }) => (
												<NabuTextbox
													{...field}
													disabled={props?.isPrint}
													placeholder={!props?.isPrint ? 'Job Name' : ''}
												/>
											)}
										/>
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.advertiserJobTitle
													?.message
											}
										</FormHelperText>
									</FormControl>
								</td>
								<td>Production Co.</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										{/* <Controller
											key={`productionCompany-${contractForm.watch(
												'productionCompanyId.value'
											)}`}
											control={contractForm.control}
											name='productionCompanyId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														data-cy='productionCompanyId'
														{...field}
														placeholder={
															!props?.isPrint ? 'Production Company' : ''
														}
														filterProperty='CompanyNames.Name'
														APICaller={getCompanyBasicDetails}
														optionGenerator={companyOptionGenerator}
														defaultFilter={PRODUCTION_COMPANY_FILTER}
														isDisabled={props?.isPrint}
														isClearable={true}
														onChange={(e: any) => {
															field.onChange(e);
														}}
														cacheOptions={false}
														defaultOptions
														className={
															props?.isPrint
																? styles.lookAheadSelectContract
																: styles.asyncSelect
														}
													/>
												</>
											)}
										/> */}
										<Controller
											control={contractForm.control}
											name='producerCompany'
											defaultValue=''
											render={({ field }) => (
												<NabuTextbox
													{...field}
													disabled={props?.isPrint}
													placeholder={!props?.isPrint ? 'Production Company' : ''}
												/>
											)}
										/>
									</FormControl>
									{/* {!props?.isPrint && (
										<div className={styles.inlineAdd}>
											<AddCompanyInline
												title='Company'
												companyType={{
													mainType:
														COMPANY_MAIN_TYPE.PRODUCTION_COMPANY as CompanyMainType,
													subType: [],
												}}
											/>
										</div>
									)} */}
								</td>
							</tr>
							<tr>
								<td>Talent Name</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='performerId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														className={
															props?.isPrint
																? styles.lookAheadSelectContract
																: styles.asyncSelect
														}
														{...field}
														placeholder={!props?.isPrint ? 'Select Talent' : ''}
														key={`performer-name-${contractForm.watch(
															'performerId.value'
														)}`}
														isClearable={true}
														filterProperty='PersonName.FirstName'
														APICaller={(filterModel, searchText) =>
															GetByWithFullNameSearchForBasicDetail(
																filterModel,
																searchText
															)
														}
														isSearchText={true}
														isDisabled={props?.isPrint}
														optionGenerator={performerOptionGenerator}
														defaultFilter={getPerformerFilter()}
														onChange={(e: any) => {
															field.onChange(e);
															contractForm.trigger('performerEmailId');
															contractForm.setValue('performerEmailId', '', {
																shouldDirty: true,
															});
															contractForm.setValue('performerAddressId', undefined, {
																shouldDirty: true,
															});
															contractForm.trigger('performerEmailId');
															contractForm.setValue(
																'printName',
																contractForm.watch('performerId.label'),
																{ shouldDirty: true }
															);
															contractForm.setValue(
																'akaName',
																(contractForm.watch('performerId.label') &&
																	getTalentAliasName()) ||
																	'',
																{ shouldDirty: true }
															);
															contractForm.setValue('printName', contractForm.watch('performerId.label') || '', { shouldDirty: true });
															contractForm.setValue('performerPhoneId', null, { shouldDirty: true });
															contractForm.setValue('talentAgencyId', null, { shouldDirty: true });
															contractForm.setValue('talentAgencyAddressId', null, { shouldDirty: true });
															contractForm.setValue('talentAgencyEmailId', null, { shouldDirty: true });
															contractForm.setValue('talentAgencyPhoneId', null, { shouldDirty: true });
															contractForm.setValue('talentAgencyStaffId', null, { shouldDirty: true });
														}}
														cacheOptions={false}
														defaultOptions
													/>
												</>
											)}
										/>
										{contractForm.formState?.errors?.performerId?.message && (
											<FormHelperText
												data-cy='errorHelper_first_session_date'
												error={true}
											>
												{
													contractForm.formState?.errors?.performerId?.message
												}
											</FormHelperText>
										)}
									</FormControl>
									{!props?.isPrint && (
										
										<AddPersonInline
											title='Person'
											onPersonCreated={() => ({})}
											initialValues={{
												personType: ['TALENT'],
												isMinor: false,
											}}
										/>
										
									)}
								</td>
								<td>Role</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='partName'
											defaultValue=''
											render={({ field }) => (
												<NabuTextbox
													{...field}
													disabled={props?.isPrint}
													placeholder={
														!props?.isPrint ? 'Role' : ''
													}
												/>
											)}
										/>
										<FormHelperText error={true}>
											{contractForm.formState.errors?.partName?.message}
										</FormHelperText>
									</FormControl>
								</td>
							</tr>
							<tr>
								<td>Shoot Date</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										{props?.isPrint ? (
											<p
												className={styles.dateAndTimePrintText}
												style={{ padding: '1px' }}
											>
												{getLocaleDate(contractForm.watch('shootDate') || '')}
											</p>
										) : (
											<Controller
												control={contractForm.control}
												name='shootDate'
												render={({ field }) => (
													<NabuDatepicker
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Shoot Date' : ''}
													/>
												)}
											/>
										)}
										<FormHelperText error={true}>
											{contractForm.formState?.errors?.shootDate?.message}
										</FormHelperText>
									</FormControl>
								</td>
								<td>Photographer</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='photographerName'
											defaultValue=''
											render={({ field }) => (
												<NabuTextbox
													{...field}
													disabled={props?.isPrint}
													placeholder={!props?.isPrint ? 'Photographer' : ''}
												/>
											)}
										/>
										<FormHelperText error={true}>
											{contractForm.formState.errors?.photographerName?.message}
										</FormHelperText>
									</FormControl>
								</td>
							</tr>
							<tr>
								<td>
								Location
									
								</td>
								<td colSpan={3}>
									<table>
										{!props?.isPrint && (
											<tr>
												<td>
													<FormControl>
														<FormLabel>Country: </FormLabel>
													</FormControl>
												</td>
												<td width={200} valign='middle'>
													<FormControl style={{ minWidth: '60%' }}>
														<Controller
															control={contractForm.control}
															name='sessionCountry'
															defaultValue={DEFAULT_COUNTRY_CODE}
															render={({ field }) => (
																<NabuSelect
																	{...field}
																	disabled={props?.isPrint}
																	options={getCountryOptions}
																	onChange={(e) => {
																		field.onChange(e);
																		contractForm.setValue('printState', '', {
																			shouldDirty: true,
																		});
																		contractForm.setValue('sessionCity', '', {
																			shouldDirty: true,
																		});
																		contractForm.setValue('sessionState', '', {
																			shouldDirty: true,
																		});
																		getCountryLabel();
																	}}
																	placeholder={!props?.isPrint ? '' : 'Select Country'}
																/>
															)}
														/>
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.sessionCountry
																	?.message
															}
														</FormHelperText>
													</FormControl>
												</td>
												{contractForm.watch('sessionCountry') ===
													DEFAULT_COUNTRY_CODE && (
													<>
														<td>
															<FormControl>
																<FormLabel>State: </FormLabel>
															</FormControl>
														</td>
														<td width={200} valign='middle'>
															<FormControl style={{ minWidth: '60%' }}>
																<Controller
																	control={contractForm.control}
																	name='sessionState'
																	defaultValue=''
																	render={({ field }) => (
																		<NabuSelect
																			{...field}
																			options={getStateOptions()}
																			disabled={props?.isPrint}
																			convertValueToUpperCase={false}
																			placeholder={
																				!props?.isPrint ? 'Select State' : ''
																			}
																			onChange={(e: any) => {
																				field.onChange(e);
																				contractForm.setValue(
																					'printState',
																					contractForm.watch('sessionState'),
																					{ shouldDirty: true }
																				);
																				contractForm.setValue(
																					'sessionCity',
																					'',
																					{ shouldDirty: true }
																				);
																				getStateLabel();
																			}}
																		/>
																	)}
																/>
																<FormHelperText error={true}>
																	{
																		contractForm?.formState?.errors
																			?.sessionState?.message
																	}
																</FormHelperText>
															</FormControl>
														</td>
													</>
												)}
												<td valign='middle'>
													<FormControl fullWidth>
														<FormLabel>City: </FormLabel>
													</FormControl>
												</td>
												<td width={200} valign='middle'>
													<FormControl style={{ minWidth: '60%' }}>
														<Controller
															control={contractForm.control}
															name='sessionCity'
															defaultValue=''
															render={({ field }) =>
																contractForm.watch('sessionCountry') ===
																DEFAULT_COUNTRY_CODE ? (
																		<NabuSelect
																			{...field}
																			disabled={props?.isPrint}
																			options={getCityOptions(
																				contractForm.watch('sessionState')
																			)}
																			convertValueToUpperCase={true}
																			placeholder={
																				!props?.isPrint ? 'Select City' : ''
																			}
																		/>
																	) : (
																		<NabuTextbox
																			{...field}
																			placeholder={!props?.isPrint ? 'City' : ''}
																			disabled={props?.isPrint}
																		/>
																	)
															}
														/>
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.sessionCity
																	?.message
															}
														</FormHelperText>
													</FormControl>
												</td>
											</tr>
										)}
										{props?.isPrint && (
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													flexWrap: 'wrap',
													textAlign: 'left',
												}}
											>
												{(contractForm.watch('sessionCountry') ||
													contractForm.watch('sessionState') ||
													contractForm.watch('sessionCity')) && (
													<span>{`${contractForm.watch('sessionCity') || ''}${
														contractForm.watch('sessionCity') &&
														(getCountryLabel() ||
															contractForm.watch('sessionState'))
															? '.'
															: ''
													}${getCountryLabel() || ''}${
														contractForm.watch('sessionState')
															? '/' + getStateLabel()
															: ''
													}`}</span>
												)}
											</div>
										)}
									</table>
								</td>
							</tr>
						</table>
						<br></br>
						<table
							width={'100%'}
							border={1}
							cellPadding={0}
							cellSpacing={0}
							className={`${styles.contractFormsInner} keep-together`}
						>
							<tr style={{ background: '#808080' }}>
								<th style={{ textAlign: 'center' }} colSpan={2}>
									Date<sup></sup>
								</th>
								<th style={{ textAlign: 'center' }} colSpan={2}>
									Time
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-around',
										}}
									>
										<span>In</span> <span>Out</span>{' '}
									</div>
								</th>
								<th style={{ textAlign: 'center' }} colSpan={2}>
									Lunch
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-around',
										}}
									>
										<span>In</span> <span>Out</span>{' '}
									</div>
								</th>
								{!props?.isPrint && <th style={{ textAlign: 'center' }}></th>}
							</tr>
							{workDayFieldArray.fields.map((field, index) => (
								<tr key={`work-field-${field.id}`}>
									<td height={40} colSpan={2}>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name={`workDays.${index}.date`}
												defaultValue=''
												render={({ field }) => (
													<NabuDatepicker
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Date' : ''}
													/>
												)}
											/>
											{!!contractForm.formState?.errors?.workDays?.length && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors?.workDays[index]
															?.date?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											{props?.isPrint ? (
												<p
													className={styles.dateAndTimePrintText}
													style={{ padding: '10px' }}
												>
													{getLocaleDateTime(
														contractForm.watch(
															`workDays.${index}.workTimeStartAt`
														) || '',
														'hh:mm aa'
													)}
												</p>
											) : (
												<Controller
													control={contractForm.control}
													name={`workDays.${index}.workTimeStartAt`}
													render={({ field }) => (
														<NabuTimePicker
															{...field}
															ampm={true}
															disabled={
																!contractForm.watch(`workDays.${index}.date`)
															}
														/>
													)}
												/>
											)}
											{!!contractForm.formState?.errors?.workDays?.length && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors?.workDays[index]
															?.workTimeStartAt?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											{props?.isPrint ? (
												<p
													className={styles.dateAndTimePrintText}
													style={{ padding: '10px' }}
												>
													{getLocaleDateTime(
														contractForm.watch(
															`workDays.${index}.workTimeEndAt`
														) || '',
														'hh:mm aa'
													)}
												</p>
											) : (
												<Controller
													control={contractForm.control}
													name={`workDays.${index}.workTimeEndAt`}
													render={({ field }) => (
														<NabuTimePicker
															{...field}
															ampm={true}
															disabled={
																!contractForm.watch(`workDays.${index}.date`)
															}
														/>
													)}
												/>
											)}
											{!!contractForm.formState?.errors?.workDays?.length && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors?.workDays[index]
															?.workTimeEndAt?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											{props?.isPrint ? (
												<p
													className={styles.dateAndTimePrintText}
													style={{ padding: '10px' }}
												>
													{getLocaleDateTime(
														contractForm.watch(
															`workDays.${index}.mealTimeStartAt`
														) || '',
														'hh:mm aa'
													)}
												</p>
											) : (
												<Controller
													control={contractForm.control}
													name={`workDays.${index}.mealTimeStartAt`}
													render={({ field }) => (
														<NabuTimePicker
															{...field}
															ampm={true}
															disabled={
																!contractForm.watch(`workDays.${index}.date`)
															}
														/>
													)}
												/>
											)}
											{!!contractForm.formState?.errors?.workDays?.length && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors?.workDays[index]
															?.mealTimeStartAt?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											{props?.isPrint ? (
												<p
													className={styles.dateAndTimePrintText}
													style={{ padding: '10px' }}
												>
													{getLocaleDateTime(
														contractForm.watch(
															`workDays.${index}.mealTimeEndAt`
														) || '',
														'hh:mm aa'
													)}
												</p>
											) : (
												<Controller
													control={contractForm.control}
													name={`workDays.${index}.mealTimeEndAt`}
													render={({ field }) => (
														<NabuTimePicker
															{...field}
															ampm={true}
															disabled={
																!contractForm.watch(`workDays.${index}.date`)
															}
														/>
													)}
												/>
											)}
											{!!contractForm.formState?.errors?.workDays?.length && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors?.workDays[index]
															?.mealTimeEndAt?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									{!props?.isPrint && (
										<>
											<td style={{ display: 'flex' }}>
												{workDayFieldArray.fields.length - 1 === index && (
													<NabuButton variant='text' onClick={onAddWorkDay}>
														<Add />
													</NabuButton>
												)}
												{workDayFieldArray?.fields.length > 1 && (
													<NabuButton
														variant='text'
														onClick={() => onRemoveWorkDay(index)}
													>
														<DeleteForever color='error' />
													</NabuButton>
												)}
											</td>
										</>
									)}
								</tr>
							))}
						</table>
						<br></br>
						<table
							width={'100%'}
							border={1}
							cellPadding={0}
							cellSpacing={0}
							className={`${styles.contractFormsInner} keep-together`}
						>
							<tr style={{ background: '#808080', color: '#FFF' }}>
								<td colSpan={5}>
									<b>Compensation:</b>
								</td>
							</tr>
							<tr>
								<td colSpan={5}>
									<b>Rate for Services Per:</b>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '10px',
											flexWrap: 'wrap',
											textAlign: 'left',
										}}
									>
										<Controller
											control={contractForm.control}
											name='rateOfServicePerHour'
											defaultValue={12}
											render={({ field }) => (
												<div
													onClick={() => {
														!props?.isPrint && field.onChange(12);
													}}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}>
														<input
															{...field}
															type='checkbox'
															value={field.value + ''}
															checked={
																contractForm.watch('rateOfServicePerHour') == 12
															}
															onChange={() => {
																field.onChange(12);
															}}
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span>12 hrs </span>
													</Box>
												</div>
											)}
										/>
										<Controller
											control={contractForm.control}
											name='rateOfServicePerHour'
											defaultValue={12}
											render={({ field }) => (
												<div
													onClick={() => {
														!props?.isPrint && field.onChange(10);
													}}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}>
														<input
															{...field}
															type='checkbox'
															value={field.value + ''}
															checked={
																contractForm.watch('rateOfServicePerHour') == 10
															}
															onChange={() => {
																field.onChange(10);
															}}
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span>10 hrs </span>
													</Box>
												</div>
											)}
										/>
										<Controller
											control={contractForm.control}
											name='rateOfServicePerHour'
											defaultValue={12}
											render={({ field }) => (
												<div
													onClick={() => {
														!props?.isPrint && field.onChange(8);
													}}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}>
														<input
															{...field}
															type='checkbox'
															value={field.value + ''}
															checked={
																contractForm.watch('rateOfServicePerHour') == 8
															}
															onChange={() => {
																field.onChange(8);
															}}
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span>8 hrs </span>
													</Box>
												</div>
											)}
										/>
										<Controller
											control={contractForm.control}
											name='rateOfServicePerHour'
											defaultValue={12}
											render={({ field }) => (
												<div
													onClick={() => {
														!props?.isPrint && field.onChange(1);
													}}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}>
														<input
															{...field}
															type='checkbox'
															value={field.value + ''}
															checked={
																contractForm.watch('rateOfServicePerHour') == 1
															}
															onChange={() => {
																field.onChange(1);
															}}
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span>Hourly </span>
													</Box>
												</div>
											)}
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<span>Overtime per hour</span>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='overTimeRate'
											render={({ field }) => (
												<input
													{...field}
													disabled={props?.isPrint}
													min={0}
													type='number'
													placeholder={
														!props?.isPrint ? 'Overtime per hour' : ''
													}
													onChange={(e: any) => {
														const value = e.target.value || '';
														if (/^\d*$/.test(value)) {
															field.onChange(value);
														}
													}}
												/>
											)}
										/>
										<FormHelperText error={true}>
											{contractForm.formState.errors?.overTimeRate?.message}
										</FormHelperText>
									</FormControl>
								</td>
								<td>
									<span>Fitting Fee</span>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='fittingFee'
											render={({ field }) => (
												<input
													{...field}
													disabled={props?.isPrint}
													min={0}
													type='number'
													placeholder={!props?.isPrint ? 'Fitting Fee' : ''}
													onChange={(e: any) => {
														const value = e.target.value || '';
														if (/^\d*$/.test(value)) {
															field.onChange(value);
														}
													}}
												/>
											)}
										/>
										<FormHelperText error={true}>
											{contractForm.formState.errors?.fittingFee?.message}
										</FormHelperText>
									</FormControl>
								</td>
								<td>
									<span>Travel & Reimbursements (if any)</span>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='travelFee'
											render={({ field }) => (
												<input
													{...field}
													disabled={props?.isPrint}
													min={0}
													type='number'
													placeholder={
														!props?.isPrint ? 'Travel & Reimbursements' : ''
													}
													onChange={(e: any) => {
														const value = e.target.value || '';
														if (/^\d*$/.test(value)) {
															field.onChange(value);
														}
													}}
												/>
											)}
										/>
										<FormHelperText error={true}>
											{contractForm.formState.errors?.travelFee?.message}
										</FormHelperText>
									</FormControl>
								</td>
								<td>
									<span>Usage Fee</span>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='useFee'
											render={({ field }) => (
												<input
													{...field}
													disabled={props?.isPrint}
													min={0}
													type='number'
													placeholder={!props?.isPrint ? 'Usage Fee' : ''}
													onChange={(e: any) => {
														const value = e.target.value || '';
														if (/^\d*$/.test(value)) {
															field.onChange(value);
														}
													}}
												/>
											)}
										/>
										<FormHelperText error={true}>
											{contractForm.formState.errors?.useFee?.message}
										</FormHelperText>
									</FormControl>
								</td>
								<td>
									<span>Agent Fee</span>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='agentFee'
											render={({ field }) => (
												<input
													{...field}
													disabled={props?.isPrint}
													min={0}
													type='number'
													placeholder={!props?.isPrint ? 'Usage Fee' : ''}
													onChange={(e: any) => {
														const value = e.target.value || '';
														if (/^\d*$/.test(value)) {
															field.onChange(value);
														}
													}}
												/>
											)}
										/>
										<FormHelperText error={true}>
											{contractForm.formState.errors?.agentFee?.message}
										</FormHelperText>
									</FormControl>
								</td>
							</tr>
						</table>
						<br></br>
						<table
							width={'100%'}
							border={1}
							cellPadding={0}
							cellSpacing={0}
							className={`${styles.contractFormsInner} keep-together`}
						>
							<tr style={{ background: '#808080', color: '#FFF' }}>
								<td colSpan={4}>
									<b>Term of Use:</b>
								</td>
							</tr>
							<tr>
								<td>
									<span>Term Start Date</span>
									<FormControl fullWidth className={styles.tableForms}>
										{props?.isPrint ? (
											<p
												className={styles.dateAndTimePrintText}
												style={{ padding: '10px' }}
											>
												{getLocaleDate(
													contractForm.watch('termFromDate') || ''
												)}
											</p>
										) : (
											<Controller
												control={contractForm.control}
												name='termFromDate'
												render={({ field }) => (
													<NabuDatepicker
														{...field}
														disabled={props?.isPrint}
														placeholder={
															!props?.isPrint ? 'Term Start Date' : ''
														}
													/>
												)}
											/>
										)}
									</FormControl>
								</td>
								<td>
									<span>Term Length</span>
									<FormControl fullWidth className={styles.tableForms}>
										<div style={{ display: 'flex', gap: '12px' }}>
											<Controller
												control={contractForm.control}
												name='termDuration'
												defaultValue=''
												render={({ field }) => (
													<NabuTextbox
														{...field}
														data-cy='termDuration_input'
														placeholder='Duration'
														disabled={props?.isPrint}
														onChange={(e) => {
															if (
																parseInt(e?.target?.value) >= 1 ||
																e?.target?.value === ''
															)
																field.onChange(e);
														}}
													/>
												)}
											/>
											<Controller
												control={contractForm.control}
												name='termDurationUnit'
												defaultValue=''
												render={({ field }) => (
													<>
														<NabuSelect 
															{...field}
															placeholder='Select Unit'
															isClearable={true}
															options={PeriodUnitsOptions?.map(op => ({ label: op?.toUpperCase(), value: op?.toUpperCase() }))}
															convertValueToUpperCase={false}
															shouldOverrideDefaultSort={true}
															disabled={props?.isPrint}
														/>
													</>
												)}
											/>
										</div>
									</FormControl>
								</td>
								<td>
									<span>In Perpetuity</span>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='isPerpetuity'
											defaultValue={false}
											render={({ field }) => (
												<RadioGroup
													className={styles.radioGroupPrint}
													row
													{...field}
													onChange={() => field.onChange(!field.value)}
												>
													<FormControlLabel
														value={true}
														control={<Radio />}
														label=' Yes'
														disabled={props?.isPrint}
													/>
													<FormControlLabel
														value={false}
														control={<Radio />}
														label='No'
														disabled={props?.isPrint}
													/>
												</RadioGroup>
											)}
										/>
									</FormControl>
								</td>
								<td>
									<span>Exclusivity</span>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='exclusitivityYes'
											defaultValue={false}
											render={({ field }) => (
												<RadioGroup
													className={styles.radioGroupPrint}
													row
													{...field}
													onChange={() => field.onChange(!field.value)}
												>
													<FormControlLabel
														value={true}
														control={<Radio />}
														label=' Yes'
														disabled={props?.isPrint}
													/>
													<FormControlLabel
														value={false}
														control={<Radio />}
														label='No'
														disabled={props?.isPrint}
													/>
												</RadioGroup>
											)}
										/>
									</FormControl>
								</td>
							</tr>
						</table>
						<br></br>
						<table
							width={'100%'}
							border={1}
							cellPadding={0}
							cellSpacing={0}
							className={`${styles.contractFormsInner} keep-together`}
						>
							<tr>
								<td width={100}>
									<span>
										<b>Territory : </b>
									</span>
								</td>

								<td>
									<div>
										{contractForm.watch('isWorldWide') && props?.isPrint && (
											<Controller
												control={contractForm.control}
												name={'isWorldWide'}
												render={({ field }) => (
													<Box display={'flex'} alignItems={'flex-start'}>
														<input
															{...field}
															checked={
																field.value || contractForm.watch('isWorldWide')
															}
															value={field.value + ''}
															onChange={(e) => {
																if (e.target.checked) {
																	resetTerritoriesValues();
																} else {
																	territoryFieldArray.fields.length == 0 &&
																		territoryFieldArray.append({
																			countries: [],
																			region: '',
																		});
																}
																field.onChange(e);
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span>WorldWide</span>
													</Box>
												)}
											/>
										)}
										{!props?.isPrint && (
											<Controller
												control={contractForm.control}
												name={'isWorldWide'}
												render={({ field }) => (
													<Box display={'flex'} alignItems={'flex-start'}>
														<input
															{...field}
															checked={
																field.value || contractForm.watch('isWorldWide')
															}
															value={field.value + ''}
															onChange={(e) => {
																if (e.target.checked) {
																	resetTerritoriesValues();
																} else {
																	territoryFieldArray.fields.length == 0 &&
																		territoryFieldArray.append({
																			countries: [],
																			region: '',
																		});
																}
																field.onChange(e);
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span>WorldWide</span>
													</Box>
												)}
											/>
										)}
										{props?.isPrint && (
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													flexWrap: 'wrap',
													textAlign: 'left',
												}}
											>
												{contractForm
													.watch('territories')
													?.filter((t) => t.region && t.region != '')
													.map((territory, index) => {
														return (
															<span key={index}>{`${
																continents[territory?.region]
															} - ${territory?.countries
																?.map((iso) => {
																	return getCountryName(iso);
																})
																.join(', ')}`}</span>
														);
													})}
											</div>
										)}
										<Controller
											control={contractForm.control}
											name={'isAllPermittedUse'}
											render={({ field }) => (
												<Box display={'flex'} alignItems={'flex-start'}
													onClick={() => !props?.isPrint && field.onChange(!field.value)}
												>
													<input
														{...field}
														checked={field.value || contractForm.watch('isAllPermittedUse')}
														value={field.value + ''}
														onChange={(e) => {
															field.onChange(e);
														}}
														type='checkbox'
														disabled={props?.isPrint}
														style={{ marginTop: '2px' }}
													/>
													<span>For All Permitted Use</span>
												</Box>
											)}
										/>
										<Controller
											control={contractForm.control}
											name={'isDigitalUseOnly'}
											render={({ field }) => (
												<Box display={'flex'} alignItems={'flex-start'}
													onClick={() => !props?.isPrint && field.onChange(!field.value)}
												>
													<input
														{...field}
														checked={field.value || contractForm.watch('isDigitalUseOnly')}
														value={field.value + ''}
														onChange={(e) => {
															field.onChange(e);
														}}
														type='checkbox'
														disabled={props?.isPrint}
														style={{ marginTop: '2px' }}
													/>
													<span>For Digital Only</span>
												</Box>
											)}
										/>
									</div>
								</td>
								<td>
									{!props?.isPrint &&
										territoryFieldArray.fields.map((region, index) => {
											return (
												<Grid item md={12} key={index}>
													<Grid item container columnSpacing={3}>
														<Grid item xs={12} md={4}>
															<FormControl fullWidth>
																<FormLabel
																	id='select-field-demo-label'
																	htmlFor='select-field-demo-button'
																	data-cy='region_label'
																>
																	Region <sup></sup>
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name={`territories.${index}.region`}
																	defaultValue={''}
																	render={({ field }) => (
																		<>
																			<NabuSelect
																				className={
																					props?.isPrint
																						? styles.lookAheadSelectContract
																						: styles.asyncSelect
																				}
																				size='small'
																				placeholder={
																					!props?.isPrint
																						? 'Select Region Type'
																						: ''
																				}
																				data-cy='region_select_input'
																				{...field}
																				onChange={(e: any) => {
																					field.onChange(e);
																				}}
																				options={continentsOptions}
																				disabled={
																					props?.isPrint ||
																					contractForm.watch('isWorldWide') ||
																					false
																				}
																			></NabuSelect>
																		</>
																	)}
																/>
															</FormControl>
														</Grid>
														<Grid item xs={12} md={4}>
															<FormControl fullWidth>
																<FormLabel
																	id='select-field-demo-label'
																	htmlFor='select-field-demo-button'
																	data-cy='country_label'
																>
																	Country <sup></sup>
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name={`territories.${index}.countries`}
																	defaultValue={[]}
																	render={({ field }) => (
																		<NabuMultiselect
																			size='small'
																			placeholder={
																				!props?.isPrint
																					? 'SELECT COUNTRY TYPE'
																					: ''
																			}
																			data-cy='country_select_input'
																			selectedItems={field.value}
																			{...field}
																			onChange={(e: any) => { field.onChange(e); contractForm.trigger('territories');}}
																			options={getContinentCountries(
																				contractForm.watch(
																					`territories.${index}.region`
																				)
																			)}
																			disabled={
																				props?.isPrint ||
																				contractForm.watch('isWorldWide') ||
																				false
																			}
																		></NabuMultiselect>
																	)}
																/>
															</FormControl>
														</Grid>
														{!props?.isPrint && (
															<>
																{index > 0 && (
																	<Grid item xs={12} md={0.5}>
																		<Box sx={{ pt: 3.5 }}>
																			<NabuButton
																				onClick={() =>
																					territoryFieldArray.remove(index)
																				}
																				variant='text'
																				disabled={
																					props?.isPrint ||
																					contractForm.watch('isWorldWide') ||
																					false
																				}
																			>
																				<DeleteForever color='error' />
																			</NabuButton>{' '}
																			&nbsp;
																		</Box>
																	</Grid>
																)}
																{index ==
																	territoryFieldArray.fields?.length - 1 && (
																	<Grid item xs={12} md={3}>
																		<Box sx={{ pt: 3.5 }}>
																			<NabuButton
																				variant='text'
																				onClick={() =>
																					territoryFieldArray.append({
																						countries: [],
																						region: '',
																					})
																				}
																				disabled={
																					props?.isPrint ||
																					contractForm.watch('isWorldWide') ||
																					false
																				}
																			>
																				<Add />
																			</NabuButton>
																		</Box>
																	</Grid>
																)}
															</>
														)}
													</Grid>
												</Grid>
											);
										})}
									{!props?.isPrint && <Grid item xs={12} >
										<FormHelperText error={true} key={`territory_${contractForm.watch('territories')}_${territoryFieldArray.fields}_${contractForm.formState.errors?.territories?.message}`}>
											{contractForm.formState.errors?.territories?.message || ''}
										</FormHelperText>
									</Grid>}
								</td>
							</tr>
						</table>
						<br></br>
						<table
							width={'100%'}
							border={1}
							cellPadding={0}
							cellSpacing={0}
							className={`${styles.contractFormsInner} keep-together`}
						>
							<tr>
								<td colSpan={4}>
									<p>
										<b>Permitted Use</b>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<Controller
										control={contractForm.control}
										name={'permittedUse'}
										render={({ field }) => (
											<>
												<div
													onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('ALL_PRINT_MEDIA'))}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}
														onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('ALL_PRINT_MEDIA'))}

													>
														<input
															checked={contractForm.watch('permittedUse')?.includes(CONTRACT_PERMITTED_USE?.ALL_PRINT_MEDIA?.toUpperCase()) || false}
															onChange={() => {
																field.onChange(getPermittedUseChangeData('ALL_PRINT_MEDIA'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span
															onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('ALL_PRINT_MEDIA'))}
														>
															{CONTRACT_PERMITTED_USE.ALL_PRINT_MEDIA}
														</span>
													</Box>
												</div>
												<div
													onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('BANNERS'))}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}
														onChange={() => {
															field.onChange(getPermittedUseChangeData('BANNERS'));
														}}
													>
														<input
															checked={contractForm.watch('permittedUse')?.includes(CONTRACT_PERMITTED_USE?.BANNERS?.toUpperCase()) || false}
															onChange={() => {
																field.onChange(getPermittedUseChangeData('BANNERS'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('BANNERS'))}>
															{CONTRACT_PERMITTED_USE.BANNERS}
														</span>
													</Box>
												</div>
												<div
													onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('BTS'))}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}
														onChange={() => {
															field.onChange(getPermittedUseChangeData('BTS'));
														}}
													>
														<input
															checked={contractForm.watch('permittedUse')?.includes(CONTRACT_PERMITTED_USE?.BTS?.toUpperCase()) || false}
															onChange={() => {
																field.onChange(getPermittedUseChangeData('BTS'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('BTS'))}>
															{CONTRACT_PERMITTED_USE.BTS}
														</span>
													</Box>
												</div>
												<div
													onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('BILLBOARD'))}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}
														onChange={() => {
															field.onChange(getPermittedUseChangeData('BILLBOARD'));
														}}
													>
														<input
															checked={contractForm.watch('permittedUse')?.includes(CONTRACT_PERMITTED_USE?.BILLBOARD?.toUpperCase()) || false}
															onChange={() => {
																field.onChange(getPermittedUseChangeData('BILLBOARD'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('BILLBOARD'))}>
															{CONTRACT_PERMITTED_USE.BILLBOARD}
														</span>
													</Box>
												</div>
												<div
													onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('PR'))}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}
														onChange={() => {
															field.onChange(getPermittedUseChangeData('PR'));
														}}
													>
														<input
															checked={contractForm.watch('permittedUse')?.includes(CONTRACT_PERMITTED_USE?.PR?.toUpperCase()) || false}
															onChange={() => {
																field.onChange(getPermittedUseChangeData('PR'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('PR'))}>
															{CONTRACT_PERMITTED_USE.PR}
														</span>
													</Box>
												</div>
											</>
										)}
									/>
								</td>
								<td>
									<Controller
										control={contractForm.control}
										name={'permittedUse'}
										render={({ field }) => (
											<>
												<div
													onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('CATALOG'))}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}
														onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('CATALOG'))}
													>
														<input
															checked={contractForm.watch('permittedUse')?.includes(CONTRACT_PERMITTED_USE?.CATALOG?.toUpperCase()) || false}
															onChange={() => {
																field.onChange(getPermittedUseChangeData('CATALOG'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('CATALOG'))}>
															{CONTRACT_PERMITTED_USE.CATALOG}
														</span>
													</Box>
												</div>

												<div
													onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('CIRCULAR'))}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}
														onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('CIRCULAR'))}
													>
														<input
															checked={contractForm.watch('permittedUse')?.includes(CONTRACT_PERMITTED_USE?.CIRCULAR?.toUpperCase()) || false}
															onChange={() => {
																field.onChange(getPermittedUseChangeData('CIRCULAR'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('CIRCULAR'))}>
															{CONTRACT_PERMITTED_USE.CIRCULAR}
														</span>
													</Box>
												</div>

												<div
													onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('COLLATERAL'))}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}
														onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('COLLATERAL'))}
													>
														<input
															checked={contractForm.watch('permittedUse')?.includes(CONTRACT_PERMITTED_USE?.COLLATERAL?.toUpperCase()) || false}
															onChange={() => {
																field.onChange(getPermittedUseChangeData('COLLATERAL'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('COLLATERAL'))}>
															{CONTRACT_PERMITTED_USE.COLLATERAL}
														</span>
													</Box>
												</div>

												<div
													onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('CORP_INDUSTRIAL'))}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}
														onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('CORP_INDUSTRIAL'))}
													>
														<input
															checked={contractForm.watch('permittedUse')?.includes(CONTRACT_PERMITTED_USE?.CORP_INDUSTRIAL?.toUpperCase()) || false}
															onChange={() => {
																field.onChange(getPermittedUseChangeData('CORP_INDUSTRIAL'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('CORP_INDUSTRIAL'))}>
															{CONTRACT_PERMITTED_USE.CORP_INDUSTRIAL}
														</span>
													</Box>
												</div>

												<div
													onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('PUBLICATIONS'))}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}
														onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('PUBLICATIONS'))}
													>
														<input
															checked={contractForm.watch('permittedUse')?.includes(CONTRACT_PERMITTED_USE?.PUBLICATIONS?.toUpperCase()) || false}
															onChange={() => {
																field.onChange(getPermittedUseChangeData('PUBLICATIONS'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('PUBLICATIONS'))}>
															{CONTRACT_PERMITTED_USE.PUBLICATIONS}
														</span>
													</Box>
												</div>

											</>
										)}
									/>
								</td>
								<td>
									<Controller
										control={contractForm.control}
										name={'permittedUse'}
										render={({ field }) => (
											<>
												<div
													onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('DIGITAL'))}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}
														onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('DIGITAL'))}
													>
														<input
															checked={contractForm.watch('permittedUse')?.includes(CONTRACT_PERMITTED_USE?.DIGITAL?.toUpperCase()) || false}
															onChange={() => {
																field.onChange(getPermittedUseChangeData('DIGITAL'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('DIGITAL'))}>
															{CONTRACT_PERMITTED_USE.DIGITAL}
														</span>
													</Box>
												</div>
												<div
													onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('DIRECT_MAIL'))}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}
														onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('DIRECT_MAIL'))}
													>
														<input
															checked={contractForm.watch('permittedUse')?.includes(CONTRACT_PERMITTED_USE?.DIRECT_MAIL?.toUpperCase()) || false}
															onChange={() => {
																field.onChange(getPermittedUseChangeData('DIRECT_MAIL'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('DIRECT_MAIL'))}>
															{CONTRACT_PERMITTED_USE.DIRECT_MAIL}
														</span>
													</Box>
												</div>												
												<div
													onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('EDITORIAL'))}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}
														onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('EDITORIAL'))}
													>
														<input
															checked={contractForm.watch('permittedUse')?.includes(CONTRACT_PERMITTED_USE?.EDITORIAL?.toUpperCase()) || false}
															onChange={() => {
																field.onChange(getPermittedUseChangeData('EDITORIAL'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('EDITORIAL'))}>
															{CONTRACT_PERMITTED_USE.EDITORIAL}
														</span>
													</Box>
												</div>												
												<div
													onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('IN_STORE'))}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}
														onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('IN_STORE'))}
													>
														<input
															checked={contractForm.watch('permittedUse')?.includes(CONTRACT_PERMITTED_USE?.IN_STORE?.toUpperCase()) || false}
															onChange={() => {
																field.onChange(getPermittedUseChangeData('IN_STORE'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('IN_STORE'))}>
															{CONTRACT_PERMITTED_USE.IN_STORE}
														</span>
													</Box>
												</div>												
												<div
													onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('RETAIL'))}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}
														onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('RETAIL'))}
													>
														<input
															checked={contractForm.watch('permittedUse')?.includes(CONTRACT_PERMITTED_USE?.RETAIL?.toUpperCase()) || false}
															onChange={() => {
																field.onChange(getPermittedUseChangeData('RETAIL'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('RETAIL'))}>
															{CONTRACT_PERMITTED_USE.RETAIL}
														</span>
													</Box>
												</div>											
											</>
										)}
									/>
								</td>
								<td>
									<Controller
										control={contractForm.control}
										name={'permittedUse'}
										render={({ field }) => (
											<>
												<div
													onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('LOOK_BOOK'))}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}
														onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('LOOK_BOOK'))}
													>
														<input
															checked={contractForm.watch('permittedUse')?.includes(CONTRACT_PERMITTED_USE?.LOOK_BOOK?.toUpperCase()) || false}
															onChange={() => {
																field.onChange(getPermittedUseChangeData('LOOK_BOOK'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('LOOK_BOOK'))}>
															{CONTRACT_PERMITTED_USE.LOOK_BOOK}
														</span>
													</Box>
												</div>

												<div
													onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('PACKAGING'))}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}
														onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('PACKAGING'))}
													>
														<input
															checked={contractForm.watch('permittedUse')?.includes(CONTRACT_PERMITTED_USE?.PACKAGING?.toUpperCase()) || false}
															onChange={() => {
																field.onChange(getPermittedUseChangeData('PACKAGING'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('PACKAGING'))}>
															{CONTRACT_PERMITTED_USE.PACKAGING}
														</span>
													</Box>
												</div>

												<div
													onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('POP'))}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}
														onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('POP'))}
													>
														<input
															checked={contractForm.watch('permittedUse')?.includes(CONTRACT_PERMITTED_USE?.POP?.toUpperCase()) || false}
															onChange={() => {
																field.onChange(getPermittedUseChangeData('POP'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('POP'))}>
															{CONTRACT_PERMITTED_USE.POP}
														</span>
													</Box>
												</div>

												<div
													onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('POS'))}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}
														onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('POS'))}
													>
														<input
															checked={contractForm.watch('permittedUse')?.includes(CONTRACT_PERMITTED_USE?.POS?.toUpperCase()) || false}
															onChange={() => {
																field.onChange(getPermittedUseChangeData('POS'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('POS'))}>
															{CONTRACT_PERMITTED_USE.POS}
														</span>
													</Box>
												</div>

												<div
													onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('OOH'))}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}
														onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('OOH'))}
													>
														<input
															checked={contractForm.watch('permittedUse')?.includes(CONTRACT_PERMITTED_USE?.OOH?.toUpperCase()) || false}
															onChange={() => {
																field.onChange(getPermittedUseChangeData('OOH'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span onClick={() => !props?.isPrint && field.onChange(getPermittedUseChangeData('OOH'))}>
															{CONTRACT_PERMITTED_USE.OOH}
														</span>
													</Box>
												</div>
									
											</>
										)}
									/>
								</td>
							</tr>
						</table>
						<br></br>
						<p>
							<b>Historical Uses:</b> the Advertising Materials may be used in
							perpetuity anywhere in the world by the Advertiser and its Agency
							or by their Successors and Assigns, at sales meetings and for
							research and other internal company purposes, award shows,
							publicity (including, without limitation, on Agency’s ‘reel’ and
							website), and as a historical archive file for reference and
							educational purposes, and no additional compensation shall be dur
							Talent in connection therewith. It is understood that social posts
							will remain in perpetuity on the Advertiser’s social media sites
							as long as no new reposts are made after the Term of Use.
						</p>
						<hr></hr>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								flexWrap: 'wrap',
								gap: '5px',
							}}
						>
							<span>
								<b>Renewal Option:</b> Advertiser may extend the above Term of
								Use, subject to the same terms and conditions as set forth
								therein, for upto{' '}
							</span>
							<FormControl
								fullWidth
								className={styles.tableForms}
								style={{ maxWidth: '150px' }}
							>
								<Controller
									control={contractForm.control}
									name='renewalOption'
									defaultValue=''
									render={({ field }) => (
										<NabuTextbox
											{...field}
											disabled={props?.isPrint}
										/>
									)}
								/>
								<FormHelperText error={true}>
									{contractForm.formState.errors?.renewalOption?.message}
								</FormHelperText>
							</FormControl>{' '}
							<span>additional periods of </span>
							<FormControl
								fullWidth
								className={styles.tableForms}
								style={{ maxWidth: '150px' }}
							>
								<Controller
									control={contractForm.control}
									name='renewalDuration'
									render={({ field }) => (
										<NabuTextbox
											{...field}
											type='number'
											onChange={e => {
												numberOnChangeEventHandler(e, field.onChange);
											}}
											disabled={props?.isPrint}
										/>
									)}
								/>
								<FormHelperText error={true}>
									{contractForm.formState.errors?.renewalDuration?.message}
								</FormHelperText>
							</FormControl>{' '}
							<span>
								months/years each (each such period a “Renewal term”) as elected
								by Advertiser in its sole discretion, exercisable in writing
								prior to expiration of initial Term of Use, or then then-current
								Renewal Term, as applicable, for a{' '}
							</span>
							<span>renewal fee of $</span>
							<FormControl
								fullWidth
								className={styles.tableForms}
								style={{ maxWidth: '100px' }}
							>
								<Controller
									control={contractForm.control}
									name='renewalFee'
									render={({ field }) => (
										<NabuTextbox
											{...field}
											type='number'
											min={0}
											onChange={e => {
												numberOnChangeEventHandler(e, field.onChange);
											}}
											disabled={props?.isPrint}
										/>
									)}
								/>
								<FormHelperText error={true}>
									{contractForm.formState.errors?.renewalFee?.message}
								</FormHelperText>
							</FormControl>
							<span> plus</span>{' '}
							<FormControl
								fullWidth
								className={styles.tableForms}
								style={{ maxWidth: '100px' }}
							>
								<Controller
									control={contractForm.control}
									name='renewalFeePlus'
									render={({ field }) => (
										<NabuTextbox
											{...field}
											type='number'
											max={100}
											onChange={e => {
												numberOnChangeEventHandler(e, (v) => {
													const value = Number(v);
													if((value <= 100) && value >=0){
														field.onChange(value);
													}
												});
											}}
											disabled={props?.isPrint}
										/>
									)}
								/>
								<FormHelperText error={true}>
									{contractForm.formState.errors?.renewalFeePlus?.message}
								</FormHelperText>
							</FormControl>{' '}
							<span>
								% agent fee for each Renewal Term in consideration for continued
								use of Talent’s Likeness as appearing therein.
							</span>
						</div>
						<hr></hr>
						<div className='keep-together'>
							<b>Conditions:</b>
							<span>All fees are plus</span>{' '}
							<FormControl
								fullWidth
								className={styles.tableForms}
								style={{ maxWidth: '100px' }}
							>
								<Controller
									control={contractForm.control}
									name='allFeePercentage'
									render={({ field }) => (
										<NabuTextbox
											{...field}
											type='number'
											min={0}
											max={100}
											onChange={e => {														
												if((new RegExp(/^\d*\.?\d*$/).test(e?.target?.value || '') && Number(e?.target?.value) <= 100)) {
													field.onChange(e);
												}
											}}
											disabled={props?.isPrint}
										/>
									)}
								/>
								<FormHelperText error={true}>
									{contractForm.formState.errors?.allFeePercentage?.message}
								</FormHelperText>
							</FormControl>{' '}
							% agent fee, only if talent is represented. Agency shall cause a
							third-party payroll provider <br></br>to process and issue talent
							payments to Talent. The Pay Master shall be the employer of record
							for payroll purposes and shall process all the applicable state,
							federal and local taxes on such fees as noted above. Agent fees to
							be paid thru a payroll provider, no agent invoices needed.
						</div>
						<hr></hr>
						<p>
							<b>
								For good and valuable consideration, receipt and sufficiency of
								which is hereby acknowledged, I hereby agree as follows:
							</b>
						</p>
						<p>
							I am appearing as a print model in advertising material being
							produced by Agency and Advertiser on the work schedule listed
							above. I hereby agree to be so photographed, and I grant to Agency
							and Advertiser, and their affiliated companies, agents,
							successors, assigns and such other persons as Agency or Advertiser
							may designate from time to time (collectively, their “Successors
							and Assigns”), the absolute right and permission to use any such
							photography in which I appear (the “Advertising Material”),
							including, without limitation, my name, image, photograph and
							likeness (“Likeness”) as portrayed therein for the duration of the
							Term of Use in the Territory in accordance with the terms of
							Permitted Use and Historical Use Rights outlines above. The
							foregoing grant of rights includes the right to edit, alter, use,
							reuse, publish and republish the same, individually or in
							conjunction with other materials, consistent with the terms and
							conditions of this agreement.
						</p>
						<br></br>
						<p>
							I agree that the results and proceeds of my services hereunder
							will be considered as “work made for hire” for the benefit of
							Agency and Advertiser, together with their Successors and Assigns,
							or if not legally capable of being considered as such, then in
							such event, I hereby assign to Agency and Advertiser, and their
							Successors and Assigns, and all right, title and interest I may
							have in or to such materials.
						</p>
						<br></br>
						<p>
							I agree that I will not hold Agency or Advertiser, or their
							Successors or Assigns, responsible for any liability resulting
							from the use of my Likeness in the Advertising Material in
							accordance with the terms hereof, including what may be deemed to
							be misrepresentation of me, my character or my person due to
							distortion, optical illusion or faulty reproduction which may
							occur in the finished product. I hereby waive any right that I may
							have to inspect or approve the finished product or products or the
							advertising copy or other advertising materials that may be used
							in connection herewith.
						</p>
						<br></br>
						<p>
							I hereby agree not to post any of the Advertising Material to my
							social media or any other venue which can be viewed by the public
							without the express written consent of the Advertiser. I also
							agree that I shall abide by any restrictions placed by the
							Advertiser on my use of the Advertising Material. I shall not use
							or disclose to any third parties, other than to my professional
							advisors and agent, any of the Advertiser and or Agency trade
							secrets or confidential information I may learn during the course
							of my engagement hereunder (including, without limitation, the
							terms of this agreement). Any Advertising Material placed during
							the Term which remains on display or in circulation following the
							expiration of the Term shall not give rise to a claim against the
							Advertiser and or Agency, or their Successors or Assigns,
							provided, however that they shall not place any additional
							Advertising Material produced hereunder in any media (other than
							as permitted herein) following the expiration of the Term.
						</p>
						<br></br>
						<p>
							I further agree in the event of a retake of any or all images and
							photographs in which I participate, or if additional retakes of
							images are required (whether originally completed or not), I will
							return to work and render my services in such retakes at the same
							rate of compensation as agreed for the original production hourly
							rate.
						</p>
						<br></br>
						<p>
							I understand that I will not receive any other payments, and my
							sole compensation is as outlined above. I understand that all
							payments and notices to me shall be delivered to the address
							listed on this agreement. I also understand that it is my sole
							responsibility to inform the Agency and Advertiser of any change
							in address in writing. If a payment does not reach me due to a
							change of address, Agency and Advertiser will hold such payments
							until notified by me in writing of the new mailing address.
							Notices with respect to any provisions herein, including but not
							limited to notices to renew, extend or exercise any rights or
							options herein shall be deemed accepted by me if Agency and/or
							Advertiser sent such notice to the address which I provided and is
							unable to reach me dur to my failure to provide change of address
							notification to Agency and/or Advertiser in writing.
						</p>
						<br></br>
						<p>
							Commencing upon the date of full execution of this agreement and
							continuing thereafter for the duration of the Term of Use and
							throughout the Territory, I will not accept employment in, nor
							authorize use of my Likeness in any Advertising Material, for a
							competitive product or service of Advertiser, as defined above in
							Exclusivity clause. I warrant and represent that this agreement
							does not in any way conflict with any existing exclusivity
							commitments on my part. Nothing herein will constitute any
							obligation on the Agency or Advertiser to make use of any of the
							rights set forth herein.
						</p>
						<br></br>
						<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
							This agreement is governed by the laws of the State of{' '}
							<FormControl
								fullWidth
								className={styles.tableForms}
								style={{ maxWidth: '300px' }}
							>
								<Controller
									control={contractForm.control}
									name='printState'
									render={({ field }) => (
										(contractForm.watch('sessionCountry') === DEFAULT_COUNTRY_CODE) ? (<NabuTextbox
											placeholder={!props?.isPrint ? 'Enter State' : ''}
											data-cy='job_name_textbox'
											{...field}
											value={getStateLabel()}
											disabled={props?.isPrint}
										/>):
											(<input
												{...field}
												placeholder={!props?.isPrint ? 'State' : ''}
												disabled={props?.isPrint}
											/>
											)
									)}
								/>
							</FormControl>
							, and the parties agree to that <br></br>
						</div>
						jurisdiction if a dispute arises. This Agreement contains the entire
						understanding between the parties regarding the subject matter
						hereof and supersedes all prior understandings. No waiver,
						modifications or additions to this Agreement shall be valid unless
						in writing and signed by the parties hereto.
						<br></br>
						<br></br>
						<p>
							By signing this agreement, I hereby agree that the Agency and/or
							Advertiser may take deductions from my earnings to adjust previous
							overpayments if and when such overpayments occur. I am over the
							age of 18 (19 in Alabama or Nebraska) and have the right to enter
							into this Agreement on my own behalf. By signing below, I
							acknowledge that I have read and understood all the terms and
							conditions of this Agreement and agree to be bound thereby.
						</p>
						<br></br>
						<p>
							<b>ACCEPTED AND AGREED TO BY:</b>
						</p>
						<table
							width={'100%'}
							border={1}
							cellPadding={0}
							cellSpacing={0}
							className={`${styles.contractFormsInner} keep-together`}
						>
							<tr>
								<td width={170}>Print Legal Talent Name:</td>
								<td width={500}>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											key={`performer-printName-${contractForm.watch(
												'performerId.value'
											)}`}
											control={contractForm.control}
											name='printName'
											disabled={true}
											render={({ field }) => (
												<>
													<NabuTextbox
														key={`performer-printName-${contractForm.watch(
															'performerId.value'
														)}`}
														data-cy='job_name_textbox'
														placeholder={props?.isPrint ? '' : 'Performer'}
														{...field}
														value={contractForm.watch('performerId.label')}
													/>
												</>
											)}
										/> 
									</FormControl>
								</td>
								<td width={70}>Address</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											key={`select_Address_${contractForm.watch(
												'performerId.value'
											)}`}
											name='performerAddressId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														{...field}
														placeholder={
															!props?.isPrint
																? 'Select Address'
																: ''
														}
														key={`performer-address-${contractForm.watch(
															'performerId.value'
														)}`}
														filterProperty='Line1'
														sortField='IsPrimary'
														sortDescending={true}
														onOptionLoaded={(options) => {
															if(options?.length && !contractForm.watch('performerAddressId.value')){
																contractForm.setValue('performerAddressId', options[0], { shouldDirty: true});
																contractForm.trigger('performerAddressId', { shouldFocus: true});																						
															}
														}}
														APICaller={(filterModel) =>
															getAllAddressByPerson(
																filterModel,
																contractForm.watch(
																	'performerId.value'
																) || ''
															)
														}
														optionGenerator={
															adAgencyAddressOptionGenerator
														}
														dropDownsize={50}
														isDisabled={
															props?.isPrint ||
																!contractForm.watch(
																	'performerId.value'
																)
														}
														isClearable={true}
														isSearchText={true}
														onChange={(e: any) => {
															field.onChange(e);
														}}
														cacheOptions={false}
														defaultOptions
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													/>
													{																							
														<Box sx={{mt:1.3}}>
															{(contractForm.watch('performerId.value') && (!props.isPrint)) && 
																<AddPersonAddressInline
																	personId={contractForm.watch('performerId.value')}
																	title='ADDRESS'
																	isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'performer-address' || false}
																	event={addressPopOver?.event}
																	onAddressCreated={(d) => onPersonAddressCreated(d)}
																	onClose={() => setAddressPopOver({ isOpen: false })}
																	onPopOverOpen={(e) => setAddressPopOver({
																		isOpen: true,
																		event: e?.currentTarget,
																		for: 'performer-address'
																	})}
																	isPersistent={true} 
																/>
															}
														</Box>
													}
												</>
											)}
										/>
									</FormControl>
								</td>
							</tr>
							<tr>
								<td>Phone:</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											key={`performer-phone-${contractForm.watch(
												'performerPhoneId'
											)}-${contractForm.watch(
												'performerId'
											)}`}
											control={contractForm.control}
											name='performerPhoneId'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuSelect
														{...field}
														key={`performer-phone-${contractForm.watch(
															'performerPhoneId'
														)}-${contractForm.watch(
															'performerId'
														)}`}
														placeholder={!props?.isPrint ? 'Select Phone' : ''}
														convertValueToUpperCase={false}
														enableEmptySelection={true}
														disabled={
															props?.isPrint ||
																!contractForm.watch(
																	'performerId.value'
																)
														}
														options={getPerformerPhoneNumberOptions() || [] }
														handleDefaultSelection={(options) => {
															if((!contractForm.watch('performerPhoneId') && options?.length)){
																contractForm.setValue('performerPhoneId', options[0]?.value, { shouldDirty: true, shouldValidate: true});
															}
														}}
													/>
												</>
											)}
										/>
									</FormControl>
								</td>
								<td>Email:</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											key={`select-phone-${contractForm.watch(
												'performerEmailId'
											)}-${contractForm.watch(
												'performerId.value'
											)}`}
											control={contractForm.control}
											name='performerEmailId'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuSelect
														{...field}
														key={`select-phone-${contractForm.watch(
															'performerEmailId'
														)}-${contractForm.watch(
															'performerId.value'
														)}`}
														placeholder={
															!props?.isPrint ? 'Select Email' : ''
														}
														convertValueToUpperCase={false}
														enableEmptySelection={true}
														disabled={
															props?.isPrint ||
																					!contractForm.watch(
																						'performerId.value'
																					)
														}
														options={getPerformerEmailsOptions()}
														handleDefaultSelection={() => !contractForm.watch('performerEmailId')}
													/>
												</>																				
											)}
										/>
									</FormControl>
								</td>
							</tr>
							<tr>
								<td>Signature:</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<NabuTextbox placeholder={!props?.isPrint ? 'Enter Signature' : ''} value=''/>
									</FormControl>
								</td>
								<td>Date:</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										{props?.isPrint ? (
											<p className={styles.dateAndTimePrintText} style={{padding:'1px'}}>{getLocaleDate(contractForm.watch('agreementDate') || '')}</p>
										) :(<Controller
											control={contractForm.control}
											name='agreementDate'
											render={({ field }) => (
												<NabuDatepicker
													{...field}
													disabled={props?.isPrint}
													placeholder={!props?.isPrint ? 'Date' : ''}
												/>
											)}
										/> )}
									</FormControl>
								</td>
							</tr>
						</table>
						<br></br>
						<table
							width={'100%'}
							border={1}
							cellPadding={0}
							cellSpacing={0}
							className={`${styles.contractFormsInner} keep-together`}
						>
							<tr>
								<td width={170}>Talent Agency:</td>
								<td width={500}>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											key={`agency-cr-${contractForm.watch('performerId.value')}`}
											control={contractForm.control}
											name='talentAgencyId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														data-cy='ad_agency'
														key={`agency-${contractForm.watch('performerId.value')}`}
														{...field}
														placeholder={
															!props?.isPrint ? 'Ad-Agency' : ''
														}
														filterProperty='CompanyNames.Name'
														APICaller={(filter) => {
															filter.filters = filter.filters?.map((f) => f.property == 'CompanyNames.Name' ? {...f, value: f?.value?.trim() || '' } : f) || [];
															return getAgentInfo(filter, contractForm.watch('performerId.value') || '');
														}}
														optionGenerator={
															companyOptionGenerator
														}
														isClearable={true}
														isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
														onChange={(e: any) => {
															field.onChange(e);
															contractForm.setValue('talentAgencyAddressId', null, { shouldDirty: true } );
															contractForm.setValue('talentAgencyEmailId', '', { shouldDirty: true } );
															contractForm.setValue('talentAgencyPhoneId', null, { shouldDirty: true } );
															contractForm.setValue('talentAgencyStaffId', null, { shouldDirty: true } );
															contractForm.trigger('talentAgencyStaffId' );
															contractForm.trigger('talentAgencyAddressId' );
															contractForm.trigger('talentAgencyEmailId');
															contractForm.trigger('talentAgencyPhoneId');
														}}
														onOptionLoaded={(options) => {
															if(options?.length && !contractForm.watch('talentAgencyId.value')){
																contractForm.setValue('talentAgencyId', options[0], { shouldDirty: true});
																contractForm.trigger('talentAgencyId', { shouldFocus: true});
															}
														}}
														cacheOptions
														defaultOptions
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													/>
													{!props?.isPrint && contractForm.watch('performerId.value') && (<AddTalentAgentInline
														onCompanyCreated={(createdAgent) => contractForm.setValue('talentAgencyId', createdAgent, { shouldDirty: true})}
														onClose={() => setCompanyPopoverInline({ isOpen: false})}
														onPopOverOpen={(e) => setCompanyPopoverInline({ isOpen: true, event: e?.currentTarget, for: 'talentAgency' })}
														event={companyPopoverInline?.event}
														isOpen={companyPopoverInline.isOpen && companyPopoverInline.for === 'talentAgency'}
														personId={contractForm.watch('performerId.value') || ''}
													/>)}
												</>
											)}
										/>
									</FormControl>
								</td>
								<td width={100}>Talent Agent:</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											key={`talentAgency_StaffName-${contractForm.watch(
												'performerId.value'
											)}-${contractForm.watch('talentAgencyId.value')}`}
											control={contractForm.control}
											name='talentAgencyStaffId'
											render={({ field }) => (
												<Autocomplete
													key={`talentAgency_StaffName-${contractForm.watch(
														'performerId.value'
													)}-${contractForm.watch('talentAgencyId.value')}`}
													data-cy='StaffSelect'
													id="combo-box-demo"
													options={staffOptions}
													{...field}
													onChange={(e, item :any) => {
														contractForm.setValue('talentAgencyStaffId', item);
													}}
													disabled={
														props?.isPrint ||
																				!contractForm.watch(
																					'talentAgencyId.value'
																				)
													}
													renderInput={(params) => <TextField placeholder='Agent Name' data-cy='StaffSelectTextField' {...params} />}
												/>
											)}
										/> 
									</FormControl>
								</td>
							</tr>
							<tr>
								<td>Talent Agency Address:</td>
								<td colSpan={3}>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											key={`agency-address-cr-${contractForm.watch(
												'talentAgencyId.value'
											)}`}
											name='talentAgencyAddressId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														{...field}
														placeholder={
															!props?.isPrint
																? 'Select Address'
																: ''
														}
														key={`agency-address-${contractForm.watch(
															'talentAgencyId.value'
														)}`}
														filterProperty='Line1'
														sortField='IsPrimary'
														sortDescending={true}
														onOptionLoaded={(options) => {
															if(options?.length && !contractForm.watch('talentAgencyAddressId.value')){
																contractForm.setValue('talentAgencyAddressId', options[0], { shouldDirty: true});
																contractForm.trigger('talentAgencyAddressId', { shouldFocus: true});																						
															}
														}}
														APICaller={(filterModel) =>
															getAllAddressByCompany(
																filterModel,
																contractForm.watch(
																	'talentAgencyId.value'
																) || ''
															)
														}
														optionGenerator={
															adAgencyAddressOptionGenerator
														}
														isDisabled={
															!contractForm.watch(
																'talentAgencyId.value'
															) || props?.isPrint
														}
														dropDownsize={50}
														isClearable={true}
														isSearchText={true}
														onChange={(e: any) => {
															field.onChange(e);
															contractForm.setValue('talentAgencyEmailId','',{ shouldDirty: true });
															contractForm.setValue('talentAgencyPhoneId',null,{ shouldDirty: true });
															contractForm.trigger('talentAgencyEmailId');
															contractForm.trigger('talentAgencyPhoneId');
														}}
														cacheOptions={false}
														defaultOptions
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													/>
												</>
											)}
										/>
									</FormControl>
								</td>
							</tr>
							<tr>
								<td>Agent Phone:</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											key={`select-phone-${contractForm.watch(
												'talentAgencyPhoneId'
											)}-${contractForm.watch(
												'talentAgencyAddressId.value'
											)}`}
											name='talentAgencyPhoneId'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuSelect
														{...field}
														key={`select-phone-${contractForm.watch(
															'talentAgencyPhoneId'
														)}-${contractForm.watch(
															'talentAgencyAddressId.value'
														)}`}
														placeholder={
															!props?.isPrint ? 'Select Phone' : ''
														}
														convertValueToUpperCase={false}
														enableEmptySelection={true}
														disabled={
															props?.isPrint ||
																					!contractForm.watch(
																						'talentAgencyAddressId.value'
																					)
														}
														options={getPhoneNumberOptions(
															contractForm.watch(
																'talentAgencyAddressId.value'
															)
														)}
														handleDefaultSelection={(options) => {
															if((!contractForm.watch('talentAgencyPhoneId') && options?.length)){
																contractForm.setValue('talentAgencyPhoneId', options[0]?.value, { shouldDirty: true, shouldValidate: true});
															}
														}}
													/>
												</>
											)}
										/>
									</FormControl>
								</td>
								<td>Agent Email:</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											key={`select-em-${contractForm.watch(
												'talentAgencyAddressId.value'
											)}-${contractForm.watch('talentAgencyEmailId')}`}
											defaultValue = ''
											name='talentAgencyEmailId'
											render={({ field }) => (
												<>
													<NabuSelect
														{...field}
														key={`select-em-${contractForm.watch(
															'talentAgencyAddressId.value'
														)}-${contractForm.watch('talentAgencyEmailId')}`}
														placeholder={
															!props?.isPrint ? 'Select Email' : ''
														}
														convertValueToUpperCase={false}
														enableEmptySelection={true}
														disabled={
															props?.isPrint ||
																					!contractForm.watch(
																						'talentAgencyAddressId.value'
																					)
														}
														options={getCompanyEmailOptions(
															contractForm.watch(
																'talentAgencyAddressId.value'
															)
														)}
														handleDefaultSelection={() => !contractForm.watch('talentAgencyEmailId')}
													/>
												</>	
											)}
										/>
									</FormControl>
								</td>
							</tr>
						</table>
						<br></br>
						<div className='keep-together'>
							<p>
								<b>Payment Authorization:</b>
							</p>
							<p>
							Talent authorizes payments to which Talent may be entitled
							hereunder, as follows:
							</p>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									flexWrap: 'wrap',
									gap: '5px',
								}}
							>
								<Controller
									control={contractForm.control}
									name='isPaymentAtPerformerAddress'
									defaultValue={true}
									disabled={props?.isPrint}
									render={({ field }) => (
										<div
											onClick={props?.isPrint ? undefined :  (() => {
												field.onChange(!field.value);
												(!props?.isPrint) && (contractForm.setValue('isPaymentAtAgentAddress', !contractForm.watch('isPaymentAtPerformerAddress'))) ;
											})}
											style={{ userSelect: 'none' }}
										>
											<Box display={'flex'} alignItems={'flex-start'}>
												<input
													{...field}
													value={field.value + ''}
													checked={field.value}
													onChange={(e) => {
														field.onChange(e);
														contractForm.setValue('isPaymentAtAgentAddress', !contractForm.watch('isPaymentAtPerformerAddress'));
													}}
													type='checkbox'
													disabled={props?.isPrint}
													style={{ marginTop: '3px' }}
												/>
												<span> To Talent at address listed above OR </span>
											</Box>
										</div>
									)}
								/>
								<Controller
									control={contractForm.control}
									name='isPaymentAtAgentAddress'
									disabled={props?.isPrint}
									render={({ field }) => (
										<div
											onClick={props?.isPrint ? undefined : () => {
												field.onChange(!field.value);
												(!props?.isPrint) && contractForm.setValue('isPaymentAtPerformerAddress', !contractForm.watch('isPaymentAtAgentAddress'));
											}}
											style={{ userSelect: 'none' }}
										>
											<Box display={'flex'} alignItems={'flex-start'}>
												<input
													{...field}
													value={field.value + ''}
													checked={field.value}
													onChange={(e) => {
														field.onChange(e);
														contractForm.setValue('isPaymentAtPerformerAddress', !contractForm.watch('isPaymentAtAgentAddress'));
													}}
													type='checkbox'
													disabled={props?.isPrint}
													style={{marginTop: '3px' }}
												/>
												<span> To Agent at address listed above </span>
											</Box>
										</div>
									)}
								/>
							</div>
						</div>
						<br></br>
						<div className='keep-together'>
							<b>If Minor:</b>
							<div
								style={{
									alignItems: 'center',
									flexWrap: 'wrap',
									gap: '5px',
								}}
							>
										I, as parent/legal guardian of{' '}
								<FormControl
									fullWidth
									className={styles.tableForms}
									style={{ maxWidth: 250 }}
									key={`performer-printMinorName-${contractForm.watch(
										'performerId.value'
									)}`}
								>
									<Controller
										control={contractForm.control}
										name='printName'
										render={({ field }) => (
											<>
												<NabuTextbox
													key={`performer-printMinorName-${contractForm.watch(
														'performerId.value'
													)}`}
													data-cy='job_name_textbox'
													placeholder={props?.isPrint ? '' : 'Performer'}
													{...field}
													disabled={props?.isPrint}
													value={isMinor() && contractForm.watch('performerId.label') || ''}
												/>
											</>
										)}
									/> 
								</FormControl>
										, warrant that I have full authority to authorize and sign
										this agreement, which I have read, understand and approve. I
										hereby release and agree to defend, indemnify and hold
										Agency and Advertiser, together with their Successors and
										Assigns, harmless from and against any all liability arising
										out of the rights granted by the above agreement.
							</div>
						</div>
						<table
							width={'100%'}
							border={1}
							cellPadding={0}
							cellSpacing={0}
							className={`${styles.contractFormsInner} keep-together`}
						>
							<tr>
								<td width={200} style={{maxWidth:'200px'}}>Parent/Guardian Signature</td>
								<td width={500}>
									<FormControl fullWidth className={styles.tableForms}>
										<NabuTextbox placeholder={props?.isPrint ? '' : 'Parent/Guardian Signature'} value='' />
									</FormControl>
								</td>
								<td width={30}>Date</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										{props?.isPrint ? (
											<p className={styles.dateAndTimePrintText} style={{padding:'1px'}}>{getLocaleDate(contractForm.watch('signatureDate') || '')}</p>
										) :(<Controller
											control={contractForm.control}
											name='signatureDate'
											render={({ field }) => (
												<NabuDatepicker
													{...field}
													disabled={props?.isPrint}
													placeholder={!props?.isPrint ? 'Date' : ''}
												/>
											)}
										/>)}
									</FormControl>
								</td>
							</tr>
							<tr>
								<td>Business Affairs Mgr</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='businessAffairMgrName'
											render={({ field }) => (
												<NabuTextbox
													{...field}
													disabled={props?.isPrint}
													placeholder={!props?.isPrint ? 'Business Affairs Mgr' : ''}
												/>
											)}
										/>
									</FormControl>
								</td>
								<td width={30}>Date</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										{props?.isPrint ? (
											<p className={styles.dateAndTimePrintText} style={{padding:'2px'}}>{getLocaleDate(contractForm.watch('businessAffairDate') || '')}</p>
										) :(<Controller
											control={contractForm.control}
											name='businessAffairDate'
											render={({ field }) => (
												<NabuDatepicker
													{...field}
													disabled={props?.isPrint}
													placeholder={!props?.isPrint ? 'Date' : ''}
												/>
											)}
										/>)}
									</FormControl>
								</td>
							</tr>
						</table>
						<br></br>
						<div className='keep-together'>
							<p>
								<b>Witnesses:</b>
							</p>
							<table
								width={'100%'}
								border={1}
								cellPadding={0}
								cellSpacing={0}
								className={styles.contractFormsInner}
							>
								<tr>
									<td width={200}>Producer’s Signature</td>
									<td width={500}>
										<FormControl fullWidth className={styles.tableForms}><NabuTextbox placeholder={!props?.isPrint ? '' : 'Producer’s Signature'} /></FormControl> 
									</td>
									<td width={30}>Date</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											{props?.isPrint ? (
												<p className={styles.dateAndTimePrintText} style={{padding:'1px'}}>{getLocaleDate(contractForm.watch('producerSignatureDate') || '')}</p>
											) :(<Controller
												control={contractForm.control}
												name='producerSignatureDate'
												render={({ field }) => (
													<NabuDatepicker
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Date' : ''}
													/>
												)}
											/>)}
										</FormControl>
									</td>
								</tr>
								<tr>
									<td width={200} style={{maxWidth:'200px'}}>Parent/Guardian Print Name</td>
									<td colSpan={3}>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='guardianPrintName'
												render={({ field }) => (
													<NabuTextbox 
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Parent/Guardian Print Name' : ''} 
													/>
												)}
											/>
										</FormControl>
									</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</ContractMutationWrapper>
			{isExpiryDateDialogOpen.isOpen && <ExpiryDateForm
				contractExpiryDate={isExpiryDateDialogOpen.contractExpiryDate || props?.initialValue?.expirationDate}
				isOpen={isExpiryDateDialogOpen.isOpen}
				handleExpiryDateForm={(values) => onSubmitOfExpiryDateForm(values)}
				handleFormClose={closeExpiryDateForm}
			/>}
		</>
	);
};

export default NonUnionTalentPrintModel;
