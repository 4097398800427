import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import NabuButton from '@/common/components/atoms/button/button';
import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import LookAheadSelect from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuSelect from '@/common/components/atoms/select/select';

import { NabuBaseTextArea } from '@/common/components/atoms/textArea/textArea';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import NabuTimePicker from '@/common/components/molecules/timepicker/timePicker';
import AddCompanyAddressInline from '@/common/components/templates/pages/add/addCompanyAddressInline';
import AddCompanyInline from '@/common/components/templates/pages/add/addCompanyInline';
import AddEmailInline from '@/common/components/templates/pages/add/addEmailInline';
import { IPopOverState } from '@/common/components/templates/pages/add/addNewAsset';
import AddPersonAddressInline from '@/common/components/templates/pages/add/addPersonAddressInline';
import AddPersonInline from '@/common/components/templates/pages/add/addPersonInline';
import AddPhoneNumberInline from '@/common/components/templates/pages/add/addPhoneNumberInline';
import AddTalentAgentInline from '@/common/components/templates/pages/add/addTalentAgentInline';
import { CONTRACT_REF_ID, ContractStatus, ContractStatusOptions, ContractUnionType, SagAftraVideoProgramsPerformerTypeOptions } from '@/common/constants/constants';
import { OPERATORS, UNION_TYPE } from '@/common/enums';
import { StatusCodes } from '@/common/enums/StatusCodes';
import useNotification from '@/common/hooks/useNotification';
import useScrollToField from '@/common/hooks/useScrollToField';
import { CompanyMainType, ICompanyAddress, ICompanyRecord } from '@/common/types/company';
import {
	IBaseContract,
	IContractSagAftraSdEmpCtrIndEduFilmVideoPgmForm, IPacketControlForm
} from '@/common/types/contract';
import { IFilterOption } from '@/common/types/filterModel';
import { addressShortner, displayAddress, displayOnlyEmail, displayPhoneNumberWithoutLabel, getBaseUrl, getCompanyDisplayName, getLocaleDate, getLocaleDateTime, getNotificationText, getPersonFullName, mutateContractAdditionalFormsData, sortEmailOrPhoneNumberForDropDown } from '@/common/utils/utils';
import { EmailFormModel, PhoneNumberFormModel } from '@/modules/company/shared/models/company';
import styles from '@/modules/contracts/components/addContracts.module.scss';
import { getAllAddressByCompany, getBusinessTaxInfo, getCompanyBasicDetails, getCompanyPrimaryEmailIdsForContract } from '@/services/company';
import { addContractSagAftraSdEmpCtrIndEduFilmVideoPgm, addMultipleContract, updateContractSagAftraSdEmpCtrIndEduFilmVideoPgm } from '@/services/contract';
import { IAssetRecord } from '@/services/models/asset';
import { IPersonAddress, IPersonRecord } from '@/services/models/person';
import { GetByWithFullNameSearchForBasicDetail, createPersonEmail, createPersonPhoneNumber, getAddressBasedOnPinCode, getAgentInfo, getAllAddressByPerson } from '@/services/person';
import store from '@/store';
import { setDisableLoader } from '@/store/app/reducer';
import { updateContractFormState } from '@/store/contract/reducer';
import { yupResolver } from '@hookform/resolvers/yup';
import { Add, CopyAll, DeleteForever, HelpOutline, NoteAdd, Save } from '@mui/icons-material';
import {
	Box, Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	ListItemButton,
	ListItemText,
	Radio,
	RadioGroup,
	Tooltip
} from '@mui/material';
import { HttpStatusCode } from 'axios';
import { City, State } from 'country-state-city';
import { addDays, addYears, isBefore } from 'date-fns';
import {
	Controller,
	UseFormReturn,
	useFieldArray,
	useForm,
} from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { SagAftraSdEmpCtrIndEduFilmVideoPgmSchema } from '../contarctFormSchema';
import ContractCAWageTheftPreventionForm from '../contractCAWageyTheftPreventionForm';
import ContractMinorTrustForm from '../contractMinorTrustForm';
import ContractNYWageTheftPreventionForm from '../contractNYWageTheftPreventionForm';
import ContractW4ShortForm from '../contractW4ShortForm';
import ContractW8BenShortForm from '../contractW8BenShortForm';
import ContractW9BenShortForm from '../contractW9BenShortForm';
import TalentDetailsForm from '../talentDetailsForm';
import LocationFreeTextSelect from './locationFreeTextSelect';
import { IContractFormState } from './standardSagAftraEmployment2022';

interface Props {
	mainForm?: UseFormReturn<IBaseContract, any, undefined>;
	onCancel?: VoidFunction;
	onSave?: CallableFunction;
	handlePrintStateOfContract?: CallableFunction;
	initialValue?: IContractSagAftraSdEmpCtrIndEduFilmVideoPgmForm;
	isPrint?: boolean;
	isEdit?: boolean;
	contractId?: string;
	defaultValue?: IBaseContract | any;
	assets?: IAssetRecord[];
	address?: ICompanyAddress[];
	people?: IPersonRecord[];
}

// eslint-disable-next-line react/display-name
const SagAftraSdEmpCtrIndEduFilmVideoPgm = forwardRef((props: Props, ref) => {
	const { scroll } = useScrollToField();
	const [params, setParams] = useSearchParams();
	useImperativeHandle(ref, () => ({
		handleContractSagAftraSdEmpCtrIndEduFilmVideoPgmFormCancel: (data: IContractSagAftraSdEmpCtrIndEduFilmVideoPgmForm, statusValue: string) => {
			if(data){
				const workDays = data?.workDays?.filter((d) => d?.date) || [];
				const submitData = {
					...data,
					status: statusValue ? statusValue : ContractStatus.Draft,
					contractSagAftraSdEmpCtrIndEduFilmVideoPgm: undefined,
					payrollVendorId: data?.payrollVendorId?.value,
					performerId: data?.performerId?.value,
					advertiserId: data?.advertiserId?.value,
					adAgencyId:data?.adAgencyId?.value,
					producerCompanyId: data?.producerCompanyId?.value,
					performerAddressId: data?.performerAddressId?.value,
					agentId: data?.agentId?.value,
					agentAddressId: data?.agentAddressId?.value,
					producerAddressId: data?.producerAddressId?.value,
					payrollVendorAddressId: data?.payrollVendorAddressId?.value || '',
					workDays
				};
				editContractDetails(submitData);
			}
		},
	}));

	useEffect(() => {
		if(props?.defaultValue?.advertiserId)
			contractForm.setValue('advertiserId', props?.defaultValue?.advertiserId, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.advertiserId]);

	useEffect(() => {
		contractForm.setValue('isJPCAuthorizer', props?.defaultValue?.isJPCAuthorizer, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.isJPCAuthorizer]);

	const DEFAULT_COUNTRY_CODE = 'US';
	const notification = useNotification();

	const contractForm = useForm<IContractSagAftraSdEmpCtrIndEduFilmVideoPgmForm>(
		{
			defaultValues: !props?.initialValue
				? {
					...(props?.mainForm?.getValues() || {}),
					...(props?.defaultValue || {}),
					workDays: [{}],
				}
				: {...(props?.initialValue),
					workDays: props?.initialValue?.workDays?.length ? props?.initialValue?.workDays : [{}],
				},
			resolver: yupResolver(SagAftraSdEmpCtrIndEduFilmVideoPgmSchema),
			shouldFocusError: false,
		}
	);

	const packetControlForm = useForm<IPacketControlForm>({
		defaultValues: {
			isW4shortForm: !!contractForm.watch('contractW4Form.firstAndMiddleName'),
			isW8BenShort: !!contractForm.watch('contractW8BenShortForm'),
			isW9BenShort: !!contractForm.watch('contractW9BenShortForm'),
			isMinorTrust: !!contractForm.watch('contractMinorTrustForm'),
			isNYTheftPreventionForm: !!contractForm.watch(
				'contractNYTheftPreventionForm'
			),
			isCATheftPreventionForm: !!contractForm.watch(
				'contractCATheftPreventionForm'
			),
		},
	});

	const [companyOptions, setCompanyOptions] = useState<ICompanyRecord[]>([]);
	const [personOptions, setPersonOptions] = useState<IPersonRecord[]>(props?.people || []);
	const [, setAssetOptions] = useState<IAssetRecord[]>([]);
	const [addressOptions, setAddressOptions] = useState<ICompanyAddress[]>(
		props?.address || []
	);
	const [selectedAddress, setSelectedAddress] = useState<{id:any, type:any}>();
	const [contractFormState, setContractFormState] =
		useState<IContractFormState>({});
	const [addressPopOver, setAddressPopOver] = useState<IPopOverState>({
		isOpen: false,
	});
	const [companyPopoverInline, setCompanyPopoverInline] = useState<IPopOverState>({
		isOpen: false,
	});
	const [selectedWardrobe, setSelectedWardrobe] = useState<any>();
	const workDayFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'workDays',
	});

	const VENDOR_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'VENDOR',
		},
	];
	
	// helper functions
	const getPerformerFilter = (): IFilterOption[] => {
		const DEFAULT_FILTER: IFilterOption = {
			value: JSON.stringify(['TALENT']),
			property: 'PersonType',
			operator: OPERATORS.IN,
			isCaseSensitive: false,
		};
		return [DEFAULT_FILTER];
	};

	const onAddWorkDay = () => {
		if (workDayFieldArray.fields.length) {
			const previousRow = {...contractForm.watch(
				`workDays.${workDayFieldArray.fields.length - 1}`
			), id: undefined};

			workDayFieldArray.append({
				...previousRow,
				date: previousRow?.date ? addDays(new Date(previousRow?.date || ''), 1)?.toISOString() : '',
			});
		}
	};

	const onRemoveWorkDay = (index: number) => {
		if (workDayFieldArray.fields.length > 1) {
			workDayFieldArray.remove(index);
		}
	};

	const handlePackageNotification = (key: keyof IPacketControlForm) => {
		notification.notify({
			message: `Form ${packetControlForm.watch(key) ? 'Added to' : 'Removed from'} Packet.`,
			type: 'info',
		});
	};
	
	const onAddressChange = (addressId: string, field: 'performer' | 'agent') => {
		setSelectedAddress({type: field, id: addressId});
		const address = addressOptions?.find( ad => ad?.id === addressId);
		
		if(address) {
			contractForm.setValue(`${field}Zip`, address?.zipCode, {shouldDirty: true});
			contractForm.setValue(`${field}State`, address?.state, {shouldDirty: true});
			contractForm.setValue(`${field}City`, address?.city?.toUpperCase(), {shouldDirty: true});
		} else {
			contractForm.setValue(`${field}State`, '', {shouldDirty: true});
			contractForm.setValue(`${field}Zip`, '', {shouldDirty: true});
			contractForm.setValue(`${field}City`, '', {shouldDirty: true});
		}
		contractForm.trigger(`${field}State`);
		contractForm.trigger(`${field}City`);
	};

	const handleStateOptions = (
		responseData: any[],
		type: 'COMPANY' | 'PERSON' | 'ASSET' | 'ADDRESS'
	) => {
		const cb = {
			COMPANY: setCompanyOptions,
			PERSON: setPersonOptions,
			ASSET: setAssetOptions,
			ADDRESS: setAddressOptions,
		}[type];

		if (responseData && Array.isArray(responseData)) {
			cb((prev) => {
				const oldFilter = prev?.filter(
					(d) => !responseData?.some((r) => r?.id === d?.id)
				);

				// if(type === 'ADDRESS'){
				// 	return [...responseData];
				// }

				return [...oldFilter, ...responseData];
			});
		}
	};

	useEffect(() => {
		if(selectedAddress?.type !== ''){
			onAddressChange(selectedAddress?.id, selectedAddress?.type);
		}
	}, [addressOptions]);

	
	const onPersonAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('performerId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('performerAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
		onAddressChange(contractForm.watch('performerAddressId.value') || '', 'performer');
	};

	const setJPCAuthorizer = () => {
		const adAgency = !!companyOptions?.find(
			(d) => d?.id === contractForm.watch('agentId.value')
		)?.isJPCAuthorizer;
		const advertiser = !!companyOptions?.find(
			(d) => d?.id === contractForm.watch('advertiserId.value')
		)?.isJPCAuthorizer;

		contractForm.setValue('isJPCAuthorizer', adAgency || advertiser, {
			shouldDirty: true,
		});
	};

	const getUnions = (): any[] =>
		[UNION_TYPE.AFM, UNION_TYPE.SAG_AFTRA].map((value) => ({
			label: value,
			value: value,
		})) || [];

	const getUnionAgreement = (unionType: string): any[] =>
		ContractUnionType[unionType]?.agreements?.map((op) => ({
			label: op?.toUpperCase(),
			value: op?.toUpperCase(),
		})) || [];

	const getStateOptions = () => {
		const states = State.getStatesOfCountry(DEFAULT_COUNTRY_CODE).map(
			(state) => ({ label: state.name?.toUpperCase(), value: state.isoCode })
		);
		if (!states.length) states.unshift({ label: 'NA', value: 'NA' });
		return states;
	};

	const getCityOptions = (stateCode?: string) =>
		stateCode ? City.getCitiesOfState(DEFAULT_COUNTRY_CODE, stateCode)?.map((city) => ({
			label: city.name?.toUpperCase(),
			value: city.name?.toUpperCase(),
		})) || [] : [];

	const getPerformerEmailsOptions = () => {
		const unsortedEmails =
			[...(personOptions || []), ...(props?.people || [])]?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.emails || [];
	
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
			
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const companyOptionGenerator = (responseData: ICompanyRecord[]) => {
		handleStateOptions(responseData, 'COMPANY');

		return responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));
	};

	
	const onPayrollVendorAddressCreated = (createdAddress: ICompanyAddress) => {
		contractForm.setValue('payrollVendorAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const companyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		})) || [];
	};

	const performerOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');
		return responseData?.map((d) => ({
			value: d?.id,
			label: getPersonFullName(d?.personName),
		}));
	};

	const adAgencyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: addressShortner(d),
		}));
	};

	const agentAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: addressShortner(d),
		}));
	};

	const getPerformerPhoneNumberOptions = () => {
		const unsortedPhoneNumbers =
			[...(personOptions || []), ...(props?.people || [])]?.find((a) => a?.id === contractForm.watch('performerId.value'))?.phoneNumbers || [];
		
		const phoneNumbers = sortEmailOrPhoneNumberForDropDown(unsortedPhoneNumbers);
		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	const onZipCodeChange = (
		zipCode: string,
		field: 'performer' | 'agent'
	) => {
		if (zipCode) {
			store.dispatch(setDisableLoader(true));
			getAddressBasedOnPinCode(zipCode)
				.then((res) => {
					if (res?.data?.['country abbreviation'] === 'US') {
						if(res.data){
							const place = res.data.places[0];
							contractForm.setValue(`${field}State`, place['state abbreviation'], { shouldDirty: true });
							contractForm.setValue(`${field}City`, place['place name']?.toUpperCase() ?? '', { shouldDirty: true });
						}
					}
				})
				.catch((e) => console.error(e?.message))
				.finally(() => {
					store.dispatch(setDisableLoader(false));
				});
		}
	};

	const onClickSnippetBtn = () => {
		setContractFormState((prev) => ({
			isPacketOpen: !prev?.isPacketOpen,
		}));
	};

	const handleFormClose = () => {
		contractForm?.reset();
		props?.onCancel && props?.onCancel();
	};

	const { mutate: addContractDetails, isLoading: isAddingContract } =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addContractSagAftraSdEmpCtrIndEduFilmVideoPgm(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const { mutate: editContractDetails, isLoading: isEditingContract } =
		useMutation({
			mutationKey: ['contract-edit'],
			mutationFn: (payload: any) => updateContractSagAftraSdEmpCtrIndEduFilmVideoPgm(props?.contractId || '', payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'UPDATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'UPDATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const handleContractSubmission = async (data: IContractSagAftraSdEmpCtrIndEduFilmVideoPgmForm, handleMultipleContractAdd?: CallableFunction) => { 
		if (!packetControlForm.watch('isW4shortForm')) {
			data.w4FormId = undefined;
			data.contractW4Form = undefined;
		}		
		if (!packetControlForm.watch('isW8BenShort')) {
			data.w8BenShortFormId = undefined;
			data.contractW8BenShortForm = undefined;
		}		
		if (!packetControlForm.watch('isW9BenShort')) {
			data.w9BenShortFormId = undefined;
			data.contractW9BenShortForm = undefined;
		}		
		if (!packetControlForm.watch('isMinorTrust')) {
			data.minorTrustFormId = undefined;
			data.contractMinorTrustForm = undefined;
		}		
		if (!packetControlForm.watch('isNYTheftPreventionForm')) {
			data.nYTheftPreventionFormId = undefined;
			data.contractNYTheftPreventionForm = undefined;
		}		
		if (!packetControlForm.watch('isCATheftPreventionForm')) {
			data.cATheftPreventionFormId = undefined;
			data.contractCATheftPreventionForm = undefined;
		}

		
		try {
			const notify = (message: string) => {
				notification.notify({
					message,
					type: 'error',
				});
			};
			// NOTE: These linked form's handled separately because the payload size is huge.
			const updatedContractIds = await mutateContractAdditionalFormsData(data, data?.performerId?.value || '', notify);
			data.w4FormId = updatedContractIds?.w4FormId;
			data.w8BenShortFormId = updatedContractIds?.w8BenShortFormId;
			data.w9BenShortFormId = updatedContractIds?.w9BenShortFormId;
			data.minorTrustFormId = updatedContractIds?.minorTrustFormId;
			data.nYTheftPreventionFormId = updatedContractIds?.nYTheftPreventionFormId;
			data.cATheftPreventionFormId = updatedContractIds?.cATheftPreventionFormId;

		} catch (error) {
			notification.notify({
				message: getNotificationText('Form', 'UPDATE', true),
				type: 'error',
			});
		} 
		finally {
			data.contractW4Form = undefined;
			data.contractW8BenShortForm = undefined;
			data.contractW9BenShortForm = undefined;
			data.contractMinorTrustForm = undefined;
			data.contractNYTheftPreventionForm = undefined;
			data.contractCATheftPreventionForm = undefined;

			const mainFormValues = props?.mainForm?.getValues();
			const addMainFormValues = !props?.isEdit ? {
				contractType: mainFormValues?.contractType,
				contractDisplayName: mainFormValues?.contractDisplayName,
				unionType: mainFormValues?.unionType,
				unionAgreement: mainFormValues?.unionAgreement,
				payrollVendorAddressId: mainFormValues?.payrollVendorAddressId || '',
			} : {};

			const workDays = data?.workDays?.filter((d) => d?.date) || [];
			const earliestDate = workDays.length > 0 ? workDays?.map(item => new Date(item.date))?.reduce((minDate, currentDate) => currentDate < minDate ? currentDate : minDate) : null;
			const contractExpiryDate = earliestDate ? addYears(new Date(earliestDate), 3) : undefined;
			const isExpired = contractExpiryDate ? (isBefore(contractExpiryDate, new Date()) ? ContractStatus.Expired : undefined) : undefined;
			const submitData = {
				...data,
				...addMainFormValues,
				payrollVendorAddressId: data?.payrollVendorAddressId?.value,
				contractSagAftraSdEmpCtrIndEduFilmVideoPgm: undefined,
				payrollVendorId: data?.payrollVendorId?.value,
				performerId: data?.performerId?.value,
				advertiserId: data?.advertiserId?.value,
				adAgencyId:data?.adAgencyId?.value,
				producerCompanyId: data?.producerCompanyId?.value,
				performerAddressId: data?.performerAddressId?.value,
				agentId: data?.agentId?.value,
				agentAddressId: data?.agentAddressId?.value,
				producerAddressId: data?.producerAddressId?.value,
				workDays,
				expirationDate: contractExpiryDate,
				status: isExpired,
			};
			
			if (!props?.isEdit){
				addContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			}
			else {
				editContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			} 
		}
	};

	const onSubmitError = (data) => {
		scroll(data);
		notification.notify({
			message: 'Please fill all required fields',
			type: 'error',
		});
	};

	const onSubmit = (data: IContractSagAftraSdEmpCtrIndEduFilmVideoPgmForm) => {		
		if(!props?.mainForm?.watch('contractDisplayName') && !props?.isEdit){
			notification.notify({
				message: 'Please fill Contract Display Name.',
				type: 'error'
			});
		}
		handleContractSubmission(data);
	};

	useEffect(() => {
		if(props?.mainForm){
			contractForm.setValue('contractDisplayName', props?.mainForm?.watch('contractDisplayName') || '', { shouldDirty: true});
		}
	}, [props?.mainForm?.watch('contractDisplayName')]);

	const { mutate: addMultipleContractDetails} =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addMultipleContract(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					const baseURL = getBaseUrl(window.location.href);
					const contractIds : any = res?.data || [];
					const urls =  contractIds.map((contract) => {
						return `${baseURL}/#/contracts/${contract}/contract-view?isEdit=true`;
					}) || [];

					urls.forEach((url) => {
						const a = document.createElement('a');	
						a.href = url;
						a.target = '_blank';
						const evt = document.createEvent('MouseEvents');
						evt.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
						a.dispatchEvent(evt);
					});
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
				} else
					notification.notify({
						message:
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const [contractFormData, setContractFormData] = useState<IContractSagAftraSdEmpCtrIndEduFilmVideoPgmForm>();

	const [noOfTalentsSelected, setNoOfTalentsSelected] = useState(false);

	const handleSaveAndDuplicate = (data: IContractSagAftraSdEmpCtrIndEduFilmVideoPgmForm) => {
		if(!props?.mainForm?.watch('contractDisplayName') && !props?.isEdit){
			notification.notify({
				message: 'Please fill Contract Display Name.',
				type: 'error'
			});
		}
		setNoOfTalentsSelected(true);
		setContractFormData(data);
	};

	const handleTalentDetailsForm = (data: any) => {
		setNoOfTalentsSelected(false);
		const handleMultipleContractAdd = (payloadData) => {
			if(data) {
				const contractAddModels = data?.talentRecords?.map((talent, index) => {
					const submitData = {
						...payloadData,
						id: undefined,
						status:ContractStatus?.Draft,
						partName: talent?.role,
						agentId: talent?.agent?.value,
						performerId: talent?.talentName?.value,
						contractDisplayName: `${payloadData?.contractDisplayName}_copy_${index+1}`,
						compensation: talent?.compensation,
						performerType:talent?.perfCategory?.toUpperCase(),
						performerAddressId:undefined,
						performerEmail:undefined,
						performerPhone:undefined,
						agentAddressId:undefined,
						ssn:undefined,
						fedId:undefined,
					};
					return submitData;
				});
				const multipleContractData = {
					contractAddSagAftraSdEmpCtrIndEduFilmVideoPgmModels: contractAddModels
				};
				addMultipleContractDetails(multipleContractData);
			}
		};
		contractFormData && handleContractSubmission(contractFormData, handleMultipleContractAdd);
	};

	const onBAgentAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('agentId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('agentAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
		onAddressChange(contractForm.watch('agentAddressId.value') || '', 'agent');
	};


	const [phoneNumberPopOver, setPhoneNumberInlinePopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const openPhoneNumberInlinePopOver = (e: React.MouseEvent<HTMLButtonElement | null>, key?: string) => {
		setPhoneNumberInlinePopOver({ isOpen: true, event: e?.currentTarget, for: key });
	};

	const closePhoneNumberInlinePopOver = () => {
		setPhoneNumberInlinePopOver({ isOpen: false });
	};

	const onPerformerPhoneNumberInlineSubmit = (formData: any) => {
		const personId = contractForm.watch('performerId.value') || '';
		createPersonPhoneNumber({ personId, phoneNumber: {...formData, isPrimary: true} }).then((res: any) => {
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Phone Number', 'CREATE'),
					type: 'success',
				});
				closePhoneNumberInlinePopOver();

				setPersonOptions(prev => (
					prev?.map(pn => (pn?.id == contractForm.watch('performerId.value') ? ({
						...pn,
						phoneNumbers: [
							{ id: res?.data?.id, ...formData },
							...(pn?.phoneNumbers || {}) as any
						]
					}) : pn))
				));	
				
				contractForm.trigger('performerId');
				contractForm.trigger('performerId.value');
				contractForm.setValue('performerPhone', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Phone Number.',
					type: 'error',
				});
			}
		});
	};

	const [emailPopOver, setEmailInlinePopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const openEmailInlinePopOver = (e: React.MouseEvent<HTMLButtonElement | null>, key?: string) => {
		setEmailInlinePopOver({ isOpen: true, event: e?.currentTarget, for: key });
	};

	const closeEmailInlinePopOver = () => {
		setEmailInlinePopOver({ isOpen: false });
	};

	const onPerformerEmailInlineSubmit = (formData: any) => {
		const personId = contractForm.watch('performerId.value') || '';
		createPersonEmail({personId, Email: {...formData, isPrimary: true}}).then((res: any) =>{
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Email', 'CREATE'),
					type: 'success',
				});
				closeEmailInlinePopOver();

				setPersonOptions(prev => (
					prev?.map(em => (em?.id == contractForm.watch('performerId.value') ? ({
						...em,
						emails: [
							{ id: res?.data?.id, ...formData },
							...(em?.emails || {}) as any
						]
					}) : em))
				));	

				contractForm.trigger('performerId');
				contractForm.trigger('performerId.value');
				contractForm.setValue('performerEmail', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Email Address.',
					type: 'error',
				});
			}
		});
	};

	const getPerformerSSN = () =>{
		const performerSsn = personOptions?.find((a) => a?.id === contractForm.watch('performerId.value'))?.ssn || '';
		if (performerSsn) {
			return `***-**-${performerSsn.slice(-4)}`;
		}
		return '';
	};

	const [loanOutCorp,setLoanOutCorp]=useState<any>();

	const getPerformerLoanOutCorp = () =>{
		const performerLoanOutCorp = personOptions?.find((a) => a?.id === contractForm.watch('performerId.value'))?.loanOutCorporations;
		const performerLoanOutCorpValue= performerLoanOutCorp ? performerLoanOutCorp[0]?.id : '';
		setLoanOutCorp(performerLoanOutCorpValue);
	};

	useQuery({
		queryKey: [loanOutCorp],
		queryFn: () => getBusinessTaxInfo(loanOutCorp || ''),
		refetchOnWindowFocus: false,
		enabled: !!contractForm.watch('performerId.value') && !props?.isPrint,
		onSuccess: (res: any) => {
			const ein = res?.data?.ein;
				
			!props?.isPrint && contractForm.setValue('fedId', ein, { shouldDirty: true});
					
		},
		onError: () => contractForm.setValue('fedId', '', { shouldDirty: true}),
	});

	const payload = {
		advertiserCompanyId: contractForm.watch('advertiserId.value'),
		adAgencyCompanyId: '',
	};

	const [emailsForCompany,getEmailsForCompany]=useState<any>();

	useQuery({
		queryKey: [contractForm.watch('advertiserId.value'),''],
		queryFn: () => getCompanyPrimaryEmailIdsForContract(payload),
		refetchOnWindowFocus: false,
		enabled: !!contractForm.watch('advertiserId.value') ,
		onSuccess: (res) => {
			if (res?.data?.length > 0) {
				getEmailsForCompany(
					res.data?.map((e) => ({
						label: displayOnlyEmail(e) || '-', 
						value: e.id || '',  
					})) || []
				);
			}
			else {
				return (
					getEmailsForCompany([])
				);
			}
		},
		onError: () => {
			return (
				getEmailsForCompany([])
			);
		},
	});


	return (
		<>
			<div className={props?.isPrint ? styles.formWrapperPreview : ''}>
				<form onSubmit={contractForm.handleSubmit(onSubmit, onSubmitError)}>
					<div className={styles.packetTemplate} id='form'>
						<Grid item container columnSpacing={3}>
							{contractFormState?.isPacketOpen && (
								<Grid item sm={12} md={4} lg={3}>
									<div className={styles.snippetSection}>
										<Grid item container columnSpacing={3}>
											<Grid item md={12}>
												<h3 className={styles.pocketTitle}> Government & Payroll forms</h3>
											</Grid>
										</Grid>
										<div className={styles.contractFormBlockSnippet}>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW4shortForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW4shortForm');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW4shortForm');
																		}}
																	/>{' '}
																	<span>W4- short version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW8BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW8BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW8BenShort');
																		}}
																	/>
																	<span>W8 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW9BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW9BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW9BenShort');
																		}}
																	/>
																	<span>W9 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isMinorTrust'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isMinorTrust');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isMinorTrust');
																		}}
																	/>
																	<span>Minor Trust Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isNYTheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isNYTheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isNYTheftPreventionForm'
																			);
																		}}
																	/>
																	<span>NY Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isCATheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isCATheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isCATheftPreventionForm'
																			);
																		}}
																	/>
																	<span>CA Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
										</div>
									</div>
								</Grid>
							)}
							<Grid
								item
								sm={12}
								md={4}
								lg={!contractFormState?.isPacketOpen ? 12 : 9}
							>
								<div
									className={
										!contractFormState?.isPacketOpen ? undefined : styles.scrollFIle
									}
								>
									<div className={styles.contractFormBlockPacket} id='contract-form-block-packet'>
										<div className={styles.contractFormBlock}>
											{props?.isEdit && (
												<div className={styles.formFiledsBlock}>
													<Grid container item columnSpacing={3}>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>Contract Name<sup>*</sup></FormLabel>
																<Controller
																	control={contractForm.control}
																	name='contractDisplayName'
																	render={({ field }) => (
																		<>
																			<NabuTextbox 
																				{...field}
																				placeholder={!props?.isPrint ? 'Contract Name' : ''}
																			/>
																		</>
																	)}
																/>
																{contractForm.formState?.errors?.contractDisplayName
																	?.message && (
																	<FormHelperText error={true}>
																		{
																			contractForm.formState.errors
																				?.contractDisplayName?.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Payroll Vendor <sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='payrollVendorId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				{...field}
																				placeholder={!props?.isPrint ? 'Payroll Vendor' : ''}
																				filterProperty='CompanyNames.Name'
																				APICaller={getCompanyBasicDetails}
																				optionGenerator={companyOptionGenerator}
																				defaultFilter={VENDOR_FILTER}
																				isClearable={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																				}}
																				cacheOptions={false}
																				defaultOptions
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																			/>
																		</>
																	)}
																/>
																{contractForm.formState.errors?.payrollVendorId?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors?.payrollVendorId.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Payroll Vendor Address
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	key={`payroll-address-${contractForm.watch(
																		'payrollVendorAddressId.value'
																	)}`}
																	name='payrollVendorAddressId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				{...field}
																				placeholder={'Select Address'}
																				key={`payroll-vendor-address-${contractForm.watch(
																					'payrollVendorAddressId.value'
																				)}-${contractForm.watch('payrollVendorId.value')}`}
																				filterProperty='Line1'
																				APICaller={(filterModel) =>
																					getAllAddressByCompany(
																						filterModel,
																						contractForm.watch('payrollVendorId.value') || ''
																					)
																				}
																				optionGenerator={
																					companyAddressOptionGenerator
																				}
																				isDisabled={!contractForm.watch('payrollVendorId.value')}
																				dropDownsize={50}
																				isClearable={true}
																				isSearchText={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																					contractForm.trigger('payrollVendorAddressId.value');
																				}}
																				cacheOptions={false}
																				defaultOptions
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																			/>
																			{contractForm.watch('payrollVendorId.value') && <div style={{float:'right'}}>
																				<Box sx={{mt:0.9}}>
																					<AddCompanyAddressInline
																						companyId={contractForm.watch('payrollVendorId.value')}
																						title='ADDRESS'
																						isOpen={addressPopOver?.isOpen}
																						event={addressPopOver?.event}
																						onAddressCreated={(d) => onPayrollVendorAddressCreated(d)}
																						onClose={() => setAddressPopOver({ isOpen: false })}
																						onPopOverOpen={(e) => setAddressPopOver({
																							isOpen: true,
																							event: e?.currentTarget,
																						})}
																						isPersistent={true} />
																				</Box>
																			</div>}
																		</>
																	
																	)}
																/>
																{contractForm.formState.errors?.payrollVendorAddressId?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors
																				?.payrollVendorAddressId.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Status
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='status'
																	render={({ field }) => (
																		<>
																			<NabuSelect
																				{...field}
																				placeholder={!props?.isPrint ? 'Select Status' : ''}
																				options={ContractStatusOptions || []}
																				convertValueToUpperCase={false}
																				onChange={(e) => field.onChange(e)}
																			/>
																		</>
																	)}
																/>
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
														Union
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='unionType'
																	defaultValue={undefined}
																	render={({ field }) => (
																		<NabuSelect
																			{...field}
																			enableEmptySelection={true}
																			placeholder='Select Union'
																			options={getUnions()}
																		/>
																	)}
																/>
																{contractForm.formState?.errors?.unionType?.message && (
																	<FormHelperText error={true}>
																		{contractForm.formState.errors.unionType.message}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														{getUnionAgreement(contractForm.watch('unionType'))?.length > 0 && (
															<Grid item sm={12} md={6} lg={2.4}>
																<FormControl fullWidth>
																	<FormLabel>
											Union Agreement <sup />
																	</FormLabel>
																	<Controller
																		control={contractForm.control}
																		name='unionAgreement'
																		defaultValue=''
																		render={({ field }) => (
																			<NabuSelect
																				{...field}
																				placeholder='Select Agreement'
																				options={getUnionAgreement(
																					contractForm.watch('unionType')
																				)}
																			/>
																		)}
																	/>
																	{contractForm.formState?.errors?.unionAgreement?.message && (
																		<FormHelperText error={true}>
																			{contractForm.formState.errors.unionAgreement.message}
																		</FormHelperText>
																	)}
																</FormControl>
															</Grid>
														)}
													</Grid>
												</div>
											)}
											{/* Main Contract Form Start */}
											<>
												<div className={styles.contractFormBlock}>
													<div id={CONTRACT_REF_ID.MAIN_FORM} className={styles.bgWhite} style={{padding:'20px'}}>
														<table className={`${styles.contractForms} keep-together`} width={'100%'}>
															<tbody>
																<tr>
																	<h4>{contractForm.watch('contractId') ? `#${contractForm.watch('contractId')}` : ''}</h4>
																</tr>
																<tr>
																	<td style={{ width: '70%' }}>
																		<h2>
																	AFTRA STANDARD EMPLOYMENT CONTRACT <br></br>{' '}
																	INDUSTRIAL/ EDUCATIONAL FILM OR VIDEOTAPE
																	PROGRAMS
																		</h2>{' '}
																	</td>
																	<td style={{ width: '30%' }}>
																		<table
																			width={'100%'}
																			border={1}
																			cellPadding={0}
																			cellSpacing={0}
																		>
																			<tr style={{ background: '#808080' }}>
																				<th colSpan={2} align='left'>
																			PRODUCER TO COMPLETE
																				</th>
																			</tr>
																			<tr>
																				<td style={{ width: '30%' }}>Date</td>
																				<td style={{ width: '70%' }}>
																					<FormControl
																						fullWidth
																						className={styles.tableForms}
																					>
																						{props?.isPrint ? (
																							<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('producerDate')) || ''}</p>
																						) :(<Controller
																							control={contractForm.control}
																							name='producerDate'
																							render={({ field }) => (
																								<NabuDatepicker
																									{...field}
																									disabled={props?.isPrint}
																									placeholder={!props?.isPrint ? 'Date' : ''}
																								/>
																							)}
																						/>)}
																						{contractForm.formState?.errors
																							?.producerDate?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors
																										?.producerDate.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																			<tr>
																				<td>Please return to</td>
																				<td>
																					<FormControl
																						className={styles.tableForms}
																						fullWidth
																					>
																						<Controller
																							control={contractForm.control}
																							name='returnToEmail'
																							defaultValue=''
																							render={({ field }) => (
																								<>
																									<NabuSelect
																										{...field}
																										key={`select-email-${contractForm.watch(
																											'advertiserId.value'
																										)}-${contractForm.watch('returnToEmail')}`}
																										defaultCase={true}
																										placeholder={!props?.isPrint ? 'Select Email' : ''}
																										convertValueToUpperCase={false}
																										enableEmptySelection={true}
																										disabled={
																											props?.isPrint 
																										}
																										onChange={(e: any) => {
																											field.onChange(e);
																										}}
																										options={emailsForCompany}
																									/>
																								</>
																							)}
																						/>
																						{contractForm.formState?.errors
																							?.returnToEmail?.message && (
																							<FormHelperText
																								data-cy='errorHelper_first_session_date'
																								error={true}
																							>
																								{
																									contractForm.formState?.errors
																										?.returnToEmail?.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																		</table>
																	</td>
																</tr>
															</tbody>
														</table>
														<table
															className={`${styles.contractFormsInner} keep-together`}
															width={'100%'}
														>
															<tbody>
																<tr>
																	<td></td>
																	<td
																		align='right'
																		style={{
																			display: 'flex',
																			justifyContent: 'flex-end',
																		}}
																	>
																		<div style={{ display: 'flex' }}>
																			<Controller
																				control={contractForm.control}
																				name='isJPCAuthorizer'
																				render={({ field }) => (
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							{...field}
																							key={`jpc-${contractForm.watch(
																								'agentId.value'
																							)}-${contractForm.watch(
																								'advertiserId.value'
																							)}`}
																							value={`${field.value}`}
																							checked={field.value || false}
																							onChange={undefined}
																							type='checkbox'
																							style={{
																								marginTop: '3px',
																							}}
																						/>
																						<span>
																			JPC Authorizer Agency or Advertiser
																						</span>
																					</Box>
																				)}
																			/>
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>

														<table
															className={`${styles.contractFormsInner} keep-together`}
															width={'100%'}
														>
															<tr>
																<td style={{ width: '10%' }}>Job #</td>
																<td style={{ width: '90%' }}>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			name='jobTitle'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					data-cy='job_name_textbox'
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={!props?.isPrint ? 'Job' : ''}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.jobTitle
																			?.message && (
																			<FormHelperText
																				data-cy='errorHelper-job-title-name'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors?.jobTitle
																						.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</td>
															</tr>
														</table>
														<table
															width={'100%'}
															border={1}
															cellPadding={0}
															cellSpacing={0}
															className={`${styles.contractFormsInner} keep-together`}
														>
															<tr style={{ background: '#808080' }}>
																<th className={styles.formTableBorder} colSpan={2}>
													Dates Worked<sup></sup>
																</th>
																<th className={styles.formTableBorder} colSpan={2}>
													Work Time
																	<div
																		style={{
																			display: 'flex',
																			justifyContent: 'space-around',
																		}}
																	>
																		<span>From</span> <span>To</span>{' '}
																	</div>
																</th>
																<th className={styles.formTableBorder} colSpan={2}>
													Meals
																	<div
																		style={{
																			display: 'flex',
																			justifyContent: 'space-around',
																		}}
																	>
																		<span>From</span> <span>To</span>{' '}
																	</div>
																</th>
																<th className={styles.formTableBorder} colSpan={2}>
													Travel to Location
																	<div
																		style={{
																			display: 'flex',
																			justifyContent: 'space-around',
																		}}
																	>
																		<span>From</span> <span>To</span>{' '}
																	</div>
																</th>
																<th className={styles.formTableBorder} colSpan={2}>
													Travel from Location
																	<div
																		style={{
																			display: 'flex',
																			justifyContent: 'space-around',
																		}}
																	>
																		<span>From</span> <span>To</span>{' '}
																	</div>
																</th>
																<th className={styles.formTableBorder} colSpan={2}>
														Fittings, Makeup
																	<div
																		style={{
																			display: 'flex',
																			justifyContent: 'space-around',
																		}}
																	>
																		<span>From</span> <span>To</span>{' '}
																	</div>
																</th>
															</tr>
															{workDayFieldArray.fields.map((field, index) => (
																<tr key={`work-field-${field.id}`}>
																	<td height={40} colSpan={2}>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.date`}
																				defaultValue=''
																				render={({ field }) => (
																					<NabuDatepicker
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'Date' : ''}
																					/>
																				)}
																			/>
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.date?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.workTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.workTimeStartAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.workTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.workTimeStartAt?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.workTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.workTimeEndAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.workTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.workTimeEndAt?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.mealTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.mealTimeStartAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.mealTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.mealTimeStartAt?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.mealTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.mealTimeEndAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.mealTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.mealTimeEndAt?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeToLocationStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeToLocationStartAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeToLocationStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.travelTimeToLocationStartAt?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeToLocationEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeToLocationEndAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeToLocationEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.travelTimeToLocationEndAt?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeFromLocationStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeFromLocationStartAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeFromLocationStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.travelTimeFromLocationStartAt?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeFromLocationEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeFromLocationEndAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeFromLocationEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.travelTimeFromLocationEndAt?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl className={styles.tableForms} fullWidth>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.makeUpTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.makeUpTimeStartAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.makeUpTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.makeUpTimeStartAt?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.makeUpTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																			{props?.isPrint ? (
																				<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.makeUpTimeEndAt`) || '', 'hh:mm aa')}</p>
																			) : (<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.makeUpTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disableOpenPicker={true}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																					/>
																				)}
																			/>)}
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.makeUpTimeEndAt?.message
																					}
																				</FormHelperText>
																			)} */}
																		</FormControl>
																	</td>
																	{/* <td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name={`workDays.${index}.performersInitials`}
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={!(contractForm.watch(`workDays.${index}.date`))}
																						placeholder={!props?.isPrint ? 'Initials' : ''}
																					/>
																				)}
																			/>
																			{/* {!!contractForm.formState?.errors?.workDays
																				?.length && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.workDays[
																							index
																						]?.performersInitials?.message
																					}
																				</FormHelperText>
																			)} 
																		</FormControl>
																	</td> */}
																	{!props?.isPrint && (
																		<>
																			<td style={{ display: 'flex' }}>
																				{workDayFieldArray.fields.length - 1 ===
																	index && (
																					<NabuButton
																						variant='text'
																						onClick={onAddWorkDay}
																					>
																						<Add />
																					</NabuButton>
																				)}
																				{workDayFieldArray?.fields.length > 1 && (
																					<NabuButton
																						variant='text'
																						onClick={() => onRemoveWorkDay(index)}
																					>
																						<DeleteForever color='error' />
																					</NabuButton>
																				)}
																			</td>
																		</>
																	)}
																</tr>
															))}
														</table>
														<table
															className={`${styles.contractFormsInner} keep-together`}
															width={'100%'}
														
														>
															<tbody>
																<tr>
																	<td style={{ width: '50%' }}>
																		<table width={'100%'}>
																			<tr>
																				<td width={250} colSpan={4}>
																					<div style={{display:'flex', alignItems:'center', gap:'5'}}>
																			Performer &apos;s Signature or Initials
																						<FormControl
																							fullWidth
																							style={{maxWidth:400}}
																							className={styles.tableForms}
																						>
																							<NabuTextbox disabled={true}/>
																						</FormControl>
																					</div>
																				</td>
		
																			</tr>
																			<tr>
																				<td width={50}><div style={{ display:'flex'}}>Between</div></td>
																				<td width={400}>
																					<FormControl
																						fullWidth
																						className={styles.tableForms}
																					>
																						{/* <Controller
																							control={contractForm.control}
																							name='producerCompanyId'
																							render={({ field }) => (
																								<>
																									<LookAheadSelect
																										{...field}
																										placeholder={!props?.isPrint ? 'Producer' : ''}
																										key={`producer-${contractForm.watch('producerCompanyId.value')}`}
																										filterProperty='CompanyNames.Name'
																										APICaller={getProducerCompanyForContract}
																										optionGenerator={companyOptionGenerator}
																										isDisabled={props?.isPrint}
																										isClearable={true}
																										onChange={(e: any) => {
																											field.onChange(e);
																											contractForm.setValue('producerAddressId', null, { shouldDirty: true });																								
																										}}
																										cacheOptions={false}
																										defaultOptions
																										className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																									/>
																									{/* <Box sx={{mt:1, height:'15px'}}>
																										{!props?.isPrint && (
																									
																											<AddCompanyInline
																												title='Company'
																											/>
																									
																										)}
																									</Box>
																								</>
																							)}
																						/> */}
																						{/* {contractForm.formState.errors?.producerCompanyId
																							?.value?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors
																										?.producerCompanyId.value.message
																								}
																							</FormHelperText>
																						)} */}
																						<Controller
																							control={contractForm.control}
																							name='producerCompany'
																							defaultValue=''
																							render={({ field }) => (
																								<NabuTextbox
																									{...field}
																									disabled={props?.isPrint}
																									placeholder={!props?.isPrint ? 'Producer' : ''}
																								/>
																							)}
																						/>
																					</FormControl>
																				</td>
																				<td width={140}><div style={{margin:'-20px 0px 0px', display:'flex'}}><sup></sup>Producer{ !props?.isPrint && (
																					<Tooltip
																						placement='right-end'
																						title={'Signatory'}
																					>
																						<HelpOutline fontSize='inherit' />
																					</Tooltip>)
																				} , and</div></td>
																				<td width={110}><div style={{display:'flex', justifyContent:'flex-end'}}><sup></sup>Producer, and</div></td>
																				<td>
																					<FormControl
																						className={styles.tableForms}
																						style={{ width: '90%' }}
																					>
																						<div
																							style={{
																								display: 'flex',
																								alignItems: 'center',
																							}}
																						>
																							<FormControl
																								fullWidth
																								className={styles.tableForms}
																							>
																								<Controller
																									key={`performer-name
																									-${contractForm.watch('performerId.value')}
																									-${contractForm.watch('performerPhone')}-${contractForm.watch('performerEmail')}`}
																									control={contractForm.control}
																									name='performerId'
																									render={({ field }) => (
																										<>
																											<LookAheadSelect
																												data-cy='name_select'
																												{...field}
																												key={`performer-name
																												-${contractForm.watch('performerId.value')}
																												-${contractForm.watch('performerPhone')}-${contractForm.watch('performerEmail')}`}
																												isClearable={true}
																												placeholder={!props?.isPrint ? 'Performer' : ''}
																												filterProperty='PersonName.FirstName'
																												APICaller={(
																													filterModel,
																													searchText
																												) =>
																													GetByWithFullNameSearchForBasicDetail(
																														filterModel,
																														searchText
																													)
																												}
																												isSearchText={true}
																												isDisabled={props?.isPrint}
																												optionGenerator={
																													performerOptionGenerator
																												}
																												defaultFilter={getPerformerFilter()}
																												onChange={(e: any) => {
																													field.onChange(e);
																													getPerformerLoanOutCorp();
																													contractForm.setValue('performerPhone', '', { shouldDirty: true });
																													contractForm.setValue('performerEmail', '', { shouldDirty: true });
																													contractForm.setValue('performerZip', '', { shouldDirty: true });
																													contractForm.setValue('performerState', '', { shouldDirty: true });
																													contractForm.setValue('performerCity', '', { shouldDirty: true });
																													contractForm.trigger('performerPhone');
																													contractForm.trigger('performerEmail');
																													contractForm.setValue('performerAddressId', null, { shouldDirty: true });
																													contractForm.setValue('agentId',null,{shouldDirty:true});
																													contractForm.setValue('agentAddressId',null,{shouldDirty:true});
																													contractForm.setValue('agentZip','',{shouldDirty:true});
																													contractForm.setValue('agentState','',{shouldDirty:true});
																													contractForm.setValue('agentCity','',{shouldDirty:true});
																													contractForm.setValue('ssn','',{shouldDirty:true});
																													contractForm.setValue('fedId','',{shouldDirty:true});
																												}}
																												cacheOptions={false}
																												defaultOptions
																												className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																											/>
																											<Box sx={{mt:'-3px', height:'15px'}}>
																												{!props?.isPrint && (
																													<AddPersonInline
																														title='Person'
																														onPersonCreated={() => ({})}
																														initialValues={{personType: ['TALENT'], isMinor: false}}
																													/>
																												)}
																											</Box>
																										</>
																									)}
																								/>																							
																								{contractForm.formState?.errors?.performerId?.message && (
																									<FormHelperText
																										data-cy='errorHelper_first_session_date'
																										error={true}
																									>
																										{
																											contractForm.formState?.errors
																												?.performerId?.message
																										}
																									</FormHelperText>
																								)}
																							</FormControl> &nbsp; <div style={{margin:'-14px 0px 0px', display:'flex'}}><sup>*</sup>, 
																								<div>Performer.</div></div>
																						</div>
																					</FormControl>
																				</td>
																			</tr>
																		</table>
																	</td>
																</tr>
															</tbody>
														</table>
														<ol className={`${styles.tableList} keep-together`}>
															<li>
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																		gap: '8px',
																		flexWrap: 'wrap',
																		marginBottom: '15px',
																	}}
																>
																	<span>
																SERVICES. Producer engages Performer and
																Performer agrees to perform services in a
																program tentatively entitled
																		<sup>*</sup>
																	</span>
																	<FormControl
																		fullWidth
																		className={styles.tableForms}
																	>
																		<Controller
																			control={contractForm.control}
																			name='tentativeProgramTitle'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={!props?.isPrint ? 'Program Title' : ''}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.tentativeProgramTitle
																			?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors?.tentativeProgramTitle
																						.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																	<span>to portray the role of</span>
																	<FormControl
																		className={styles.tableForms}
																		style={{ width: '250px' }}
																	>
																		<Controller
																			control={contractForm.control}
																			name='partName'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={!props?.isPrint ? 'Role' : ''}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.partName
																			?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors?.partName
																						.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																	<span> to be produced on behalf of <sup>*</sup></span>
																	<FormControl
																		className={styles.tableForms}
																		style={{ width: '280px' }}
																	>
																		<Controller
																			control={contractForm.control}
																			name='advertiserId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						{...field}
																						placeholder={!props?.isPrint ? 'Advertiser' : ''}
																						key={`advertiser-${contractForm.watch('advertiserId.value')}`}
																						filterProperty='CompanyNames.Name'
																						APICaller={getCompanyBasicDetails}
																						optionGenerator={companyOptionGenerator}
																						isDisabled={props?.isPrint}
																						isClearable={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																							setJPCAuthorizer();
																						}}
																						cacheOptions={false}
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					<Box sx={{mt:0.5, height:'15px'}}>
																						{!props?.isPrint && (
																					
																							<AddCompanyInline
																								title='Company'
																								companyType={{ mainType: 'ADVERTISER' as CompanyMainType, subType: [] }} />
																					
																						)}
																					</Box>
																				</>
																			)}
																		/>
																		{contractForm.formState.errors?.advertiserId?.message && (
																			<FormHelperText
																				error={true}
																				data-cy='errorHelper_ad_agency'
																			>
																				{
																					contractForm.formState?.errors?.advertiserId.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>{' '}
															(client)
																</div>
															</li>
															<li>
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																		gap: '8px',
																		flexWrap: 'wrap',
																		marginBottom: '15px',
																	}}
																>
																	<span>
																CATEGORY. Indicate the initial primary use of
																the program.
																	</span><sup>*</sup>{' '}
																	<FormControl className={styles.tableForms}>
																		<Controller
																			control={contractForm.control}
																			name='categoryType'
																			// defaultValue=''
																			render={({ field }) => (
																				<>
																					<span aria-label={field.name}/>
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							type='checkbox'
																							name={field.name}
																							checked={field.value === 'category1'}
																							onChange={() => field.onChange(field.value === 'category1' ? '' : 'category1')}
																							disabled={props?.isPrint}
																							style={{ marginTop: '3px' }}
																						/>
																						<span> Cat. I (Industrial/Educational)</span>
																					</Box>
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							type='checkbox'
																							name={field.name}
																							checked={field.value === 'category2'}
																							onChange={() => field.onChange(field.value === 'category2' ? '' : 'category2')}
																							disabled={props?.isPrint}
																							style={{ marginTop: '3px' }}
																						/>
																						<span> Cat. II Point of Purchase (Inc. Cat I)</span>
																					</Box>
																				</>
																			)}
																		/>
																		{contractForm?.formState?.errors?.categoryType
																			?.message && (
																			<FormHelperText error={true}>
																				{contractForm?.formState?.errors?.categoryType.message}
																			</FormHelperText>
																		)}
																	</FormControl>
																</div>
															</li>
															<li>
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																		gap: '8px',
																		flexWrap: 'wrap',
																		marginBottom: '15px',
																	}}
																>
																	<span>
																NUMBER OF CLIENTS. Indicate the number of
																clients for which the program will be used{' '}
																	</span>
																	<FormControl
																		className={styles.tableForms}
																		style={{ width: '250px' }}
																	>
																		<Controller
																			control={contractForm.control}
																			name='numberOfClients'
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					disabled={props?.isPrint}
																					type='number'
																					placeholder={!props?.isPrint ? 'Number of Advertiser' : ''}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.numberOfClients
																			?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.numberOfClients.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</div>
															</li>
															<li>
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																		gap: '8px',
																		flexWrap: 'wrap',
																		marginBottom: '15px',
																	}}
																>
																	<span>
																TERM. Performer’s employment shall be for the
																continuous period commencing<sup>*</sup>
																	</span>{' '}
																	<FormControl
																		className={styles.tableForms}
																		style={{ width: '250px' }}
																	>
																		<Controller
																			control={contractForm.control}
																			name='term'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={!props?.isPrint ? 'Term Start Date' : ''}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors
																			?.term?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.term.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																	<span>
																and continuing until completion of photography
																and recordation of said role. Exception (for Day
																Performers Only) Performer may be dismissed and
																recalled without payment for intervening period
																provided Performer is given a firm recall date
																at time of engagement. If applicable,
																Performer’s firm recall date is
																	</span>{' '}
																	<FormControl
																		className={styles.tableForms}
																		style={{ width: '250px' }}
																	>
																		{props?.isPrint ? (
																			<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('performerFirmRecallDate')) || ''}</p>
																		) :<Controller
																			control={contractForm.control}
																			name='performerFirmRecallDate'
																			defaultValue=''
																			render={({ field }) => (
																				<NabuDatepicker
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={!props?.isPrint ? 'Firm Recall Date' : ''}
																				/>
																			)}
																		/>}
																		{contractForm.formState.errors
																			?.performerFirmRecallDate?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.performerFirmRecallDate.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																</div>
															</li>
															<li>
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																		gap: '8px',
																		flexWrap: 'wrap',
																		marginBottom: '15px',
																	}}
																>
																	<span>
																. COMPENSATION. Producer employs Performer as
																Classification
																	</span>
																	<Controller
																		control={contractForm.control}
																		name='classification'
																		defaultValue={'OFF-CAMERA'}
																		render={({ field }) => (
																			<RadioGroup
																				className={styles.radioGroupPrint}
																				row
																				{...field}
																			>
																				<FormControlLabel
																					value={'ON-CAMERA'}
																					control={<Radio data-cy=' On-Camera' />}
																					label=' On-Camera'
																					disabled={props?.isPrint}
																				/>
																				<FormControlLabel
																					value={'OFF-CAMERA'}
																					control={<Radio data-cy='On-Camera' />}
																					label='Off-Camera'
																					disabled={props?.isPrint}
																				/>
																				<FormControlLabel
																					value={'ON-CAMERA-NARRATOR/SPOKESPERSON'}
																					control={<Radio data-cy='On-Camera' />}
																					label='On-Camera Narrator/Spokesperson'
																					disabled={props?.isPrint}
																				/>
																			</RadioGroup>
																		)}
																	/>															
																</div>
															</li>
														</ol>
														<ol>
															<table
																width={'100%'}
																cellPadding={0}
																cellSpacing={0}
																className={`${styles.contractFormsInner} keep-together`}
															>
																<tr>
																	<td>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerType'
																				// defaultValue={'DAY PERFORMER'}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange('DAY_PERFORMER')}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value='DAY_PERFORMER'
																								checked={field.value === 'DAY_PERFORMER'}
																								onChange={(e) => field.onChange(e.target.value)}
																								type='radio'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Day Performer</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</td>
																	<td>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerType'
																				// defaultValue={'1/2 DAY PERFORMER'}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange('1/HALF_DAY_PERFORMER')}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value='HALF_DAY_PERFORMER'
																								checked={field.value === 'HALF_DAY_PERFORMER'}
																								onChange={(e) => field.onChange(e.target.value)}
																								type='radio'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>1/2 Day Performer</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</td>
																	<td>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerType'
																				// defaultValue={'SINGER, SOLO/DUO'}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange('SINGER_SOLO_DUO')}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value='SINGER_SOLO_DUO'
																								checked={field.value === 'SINGER_SOLO_DUO'}
																								onChange={(e) => field.onChange(e.target.value)}
																								type='radio'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Singer, Solo/Duo</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</td>
																	<td>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerType'
																				// defaultValue={'GENERAL BACKGROUND ACTOR'}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange('GENERAL_BACKGROUND_ACTOR')}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value='GENERAL_BACKGROUND_ACTOR'
																								checked={field.value === 'GENERAL_BACKGROUND_ACTOR'}
																								onChange={(e) => field.onChange(e.target.value)}
																								type='radio'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>General Background Actor</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerType'
																				// defaultValue={'3-DAY PERFORMER'}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange('THREE_DAY_PERFORMER')}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value='THREE_DAY_PERFORMER'
																								checked={field.value === 'THREE_DAY_PERFORMER'}
																								onChange={(e) => field.onChange(e.target.value)}
																								type='radio'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>3-day Performer</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</td>
																	<td>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerType'
																				// defaultValue={'DANCER, SOLO/DUO'}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange('DANCER_SOLO_DUO')}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value='DANCER_SOLO_DUO'
																								checked={field.value === 'DANCER_SOLO_DUO'}
																								onChange={(e) => field.onChange(e.target.value)}
																								type='radio'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Dancer, Solo/Duo</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</td>
																	<td>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerType'
																				// defaultValue={'SINGER, GROUP'}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange('SINGER_GROUP')}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value='SINGER_GROUP'
																								checked={field.value === 'SINGER_GROUP'}
																								onChange={(e) => field.onChange(e.target.value)}
																								type='radio'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Singer, Group</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</td>
																	<td>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerType'
																				// defaultValue={'SPECIAL ABILITY BACKGROUND ACTOR'}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange('SPECIAL_ABILITY_BACKGROUND_ACTOR')}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value='SPECIAL_ABILITY_BACKGROUND_ACTOR'
																								checked={field.value === 'SPECIAL_ABILITY_BACKGROUND_ACTOR'}
																								onChange={(e) => field.onChange(e.target.value)}
																								type='radio'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Special Ability Background Actor</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerType'
																				// defaultValue={'SPECIAL ABILITY BACKGROUND ACTOR'}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange('WEEKLY_PERFORMER')}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value='WEEKLY_PERFORMER'
																								checked={field.value === 'WEEKLY_PERFORMER'}
																								onChange={(e) => field.onChange(e.target.value)}
																								type='radio'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Weekly Performer</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</td>
																	<td>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerType'
																				// defaultValue={'SPECIAL ABILITY BACKGROUND ACTOR'}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange('DANCER_GROUP')}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value='DANCER_GROUP'
																								checked={field.value === 'DANCER_GROUP'}
																								onChange={(e) => field.onChange(e.target.value)}
																								type='radio'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Dancer, Group</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</td>
																	<td>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerType'
																				// defaultValue={'SPECIAL ABILITY BACKGROUND ACTOR'}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange('SINGER_STEP_OUT')}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value='SINGER_STEP_OUT'
																								checked={field.value === 'SINGER_STEP_OUT'}
																								onChange={(e) => field.onChange(e.target.value)}
																								type='radio'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Singer, Step Out</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</td>
																	<td>
																		<div
																			style={{
																				display: 'inline-flex',
																				alignItems: 'center',
																			}}
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerType'
																				// defaultValue={'SPECIAL ABILITY BACKGROUND ACTOR'}
																				render={({ field }) => (
																					<div
																						onClick={() => (!props?.isPrint) && field.onChange('SILENT_BIT_BACKGROUND_ACTOR')}
																						style={{ userSelect: 'none' }}
																					>
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value='SILENT_BIT_BACKGROUND_ACTOR'
																								checked={field.value === 'SILENT_BIT_BACKGROUND_ACTOR'}
																								onChange={(e) => field.onChange(e.target.value)}
																								type='radio'
																								disabled={props?.isPrint}
																								style={{ marginTop: '3px' }}
																							/>
																							<span>Silent Bit Background Actor</span>
																						</Box>
																					</div>
																				)}
																			/>
																		</div>
																	</td>
																</tr>
															</table>
															<hr></hr>
															<table
																width={'100%'}
																cellPadding={0}
																cellSpacing={0}
																className={`${styles.contractFormsInner} keep-together`}
															>
																<tr>
																	<td width={280}>
																		<p>
																	City and State in which services rendered:
																		</p>
																	</td>
																	<td>
																		<FormControl
																			className={styles.tableForms}
																			fullWidth
																		>
																			<Controller
																				control={contractForm.control}
																				name='serviceState'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuSelect
																						{...field}
																						options={getStateOptions()}
																						disabled={props?.isPrint}
																						convertValueToUpperCase={false}
																						placeholder={!props?.isPrint ? 'Select State' : ''}
																					/>
																				)}
																			/>
																			{contractForm?.formState?.errors?.serviceState
																				?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.serviceState
																							.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																		<div style={{marginBottom:'-10px'}}>(Work State)<sup>*</sup></div>
																	</td>
																	<td>
																		<FormControl
																			className={styles.tableForms}
																			fullWidth
																		>
																			<Controller
																				control={contractForm.control}
																				name='serviceCity'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuSelect
																						{...field}
																						disabled={props?.isPrint}
																						options={getCityOptions(
																							contractForm.watch('serviceState')
																						)}
																						convertValueToUpperCase={true}
																						placeholder={!props?.isPrint ? 'Select City' : ''}
																					/>
																				)}
																			/>
																			{contractForm.formState?.errors?.serviceCity
																				?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.serviceCity
																							.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																		<div style={{marginBottom:'-10px'}}>(Work City)<sup>*</sup></div>
																	</td>
																	<td>
																		<FormControl
																			className={styles.tableForms}
																			fullWidth
																		>
																			<Controller
																				control={contractForm.control}
																				name='placeOfEngagement'
																				render={({ field }) => (
																					<LocationFreeTextSelect
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={!props?.isPrint ? 'Place of Engagement' : ''}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors?.placeOfEngagement
																				?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.placeOfEngagement.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																		<div style={{marginBottom:'15px'}}>(Place of engagement)</div>
																	</td>
																</tr>
															</table>
															<table width={'100%'} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}
															>
																<tr>
																	<td valign='top' width={120}>
																		<p>
																			<b>at the salary of:</b>
																		</p>
																	</td>
																	<td>
																		<div
																			style={{
																				display: 'flex',
																				alignItems: 'center',
																				gap: '8px',
																				flexWrap: 'wrap',
																				marginBottom: '15px',
																			}}
																		>
																			<span>On-Camera<sup>{contractForm.watch('classification')!== 'OFF-CAMERA' ? '*' : ''}</sup></span>{' '}$
																			<FormControl
																				className={styles.tableForms}
																				style={{ width: '200px' }}
																			>
																				<Controller
																					control={contractForm.control}
																					name='onCameraSalary'
																					render={({ field }) => (
																						<NabuTextbox
																							{...field}
																							disabled={props?.isPrint}
																							placeholder={!props?.isPrint ? 'On Camera Salary' : ''}
																							onChange={(e: any) => {
																								const value = e?.target?.value || '';
																								if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																									field.onChange(value);
																								} else {
																									const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																									field.onChange(num);
																								}
																							}}
																							onBlur={() => {
																								const numericValue = parseFloat((field.value ?? '0').toString());
																								if (!isNaN(numericValue)) {
																									field.onChange(numericValue.toFixed(2));
																								} else {
																									field.onChange('0.00');
																								}
																							}}
																						/>
																					)}
																				/>
																				{contractForm.formState.errors?.onCameraSalary
																					?.message && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState?.errors
																								?.onCameraSalary.message
																						}
																					</FormHelperText>
																				)}
																			</FormControl>{' '}
																			<span>per</span>{' '}
																			<Controller
																				control={contractForm.control}
																				name='salaryPayPeriod'
																				defaultValue={'DAY'}
																				render={({ field }) => (
																					<RadioGroup row {...field} className={styles.radioGroupPrint}>
																						<FormControlLabel
																							value={'DAY'}
																							control={<Radio />}
																							label='Day'
																							disabled={props?.isPrint}
																						/>
																						<FormControlLabel
																							value={'3-DAY'}
																							control={<Radio />}
																							label='3-Day'
																							disabled={props?.isPrint}
																						/>
																						<FormControlLabel
																							value={'WEEK'}
																							control={<Radio />}
																							label='Week'
																							disabled={props?.isPrint}
																						/>
																					</RadioGroup>
																				)}
																			/>
																		</div>
																		<div
																			style={{
																				display: 'flex',
																				alignItems: 'center',
																				gap: '8px',
																				flexWrap: 'wrap',
																				marginBottom: '15px',
																			}}
																		>
																			<span>Off-Camera<sup>{contractForm.watch('classification') == 'OFF-CAMERA' ? '*' : ''}</sup></span>{' '}$
																			<FormControl
																				className={styles.tableForms}
																				style={{ width: '200px' }}
																			>
																				<Controller
																					control={contractForm.control}
																					name='offCameraSalaryForFirstHour'
																					render={({ field }) => (
																						<NabuTextbox
																							{...field}
																							disabled={props?.isPrint}
																							placeholder={!props?.isPrint ? 'Off Camera Salary' : ''}
																							onChange={(e: any) => {
																								const value = e?.target?.value || '';
																								if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																									field.onChange(value);
																								} else {
																									const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																									field.onChange(num);
																								}
																							}}
																							onBlur={() => {
																								const numericValue = parseFloat((field.value ?? '0').toString());
																								if (!isNaN(numericValue)) {
																									field.onChange(numericValue.toFixed(2));
																								} else {
																									field.onChange('0.00');
																								}
																							}}
																						/>
																					)}
																				/>
																				{contractForm.formState.errors?.offCameraSalaryForFirstHour
																					?.message && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState?.errors
																								?.offCameraSalaryForFirstHour.message
																						}
																					</FormHelperText>
																				)}
																			</FormControl>{' '}
																			<span>for the first hour,</span>{' '}$
																			<FormControl className={styles.tableForms}>
																				<Controller
																					control={contractForm.control}
																					name='offCameraSalaryForAdditionalHour'
																					render={({ field }) => (
																						<NabuTextbox
																							{...field}
																							disabled={props?.isPrint}
																							placeholder={!props?.isPrint ? 'Off Camera Salary' : ''}
																							onChange={(e: any) => {
																								const value = e?.target?.value || '';
																								if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																									field.onChange(value);
																								} else {
																									const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																									field.onChange(num);
																								}
																							}}
																							onBlur={() => {
																								const numericValue = parseFloat((field.value ?? '0').toString());
																								if (!isNaN(numericValue)) {
																									field.onChange(numericValue.toFixed(2));
																								} else {
																									field.onChange('0.00');
																								}
																							}}
																						/>
																					)}
																				/>
																				{contractForm.formState.errors?.offCameraSalaryForAdditionalHour
																					?.message && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState?.errors
																								?.offCameraSalaryForAdditionalHour.message
																						}
																					</FormHelperText>
																				)}
																			</FormControl>{' '}
																			<span>for each additional half hour </span>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td colSpan={2}>
																	Producer must mail payment not later than
																	thirty (30) calendar days after employment
																		<br />
																	OVERTIME. All overtime rates MUST be computed
																	on Performer’s full contractual rate, up to
																	permitted ceilings (NO CREDITING). Straight
																	time is 1/8th of Day
																	</td>
																</tr>
															</table>
														
														</ol>
													</div>
													<div id={CONTRACT_REF_ID.MAIN_FORM_PART_2} className={styles.bgWhite} style={{padding:'20px'}}>
														<ol>														
															<br></br>
															<li value={6}>
																<p>
															Performer’s Rate, 1/24th of 3-Day Performer’s
															Rate, 1/40th of Weekly Performer’s Rate.
															Time-and-one-half rate: payable per hour (1.5 x
															straight time rate.) Double time rate: payable per
															hour (2 x straight time rate). See the Basic
															Contract for details. Weekly and 3-Day Performer
															for time-and-one-half and doubletime rates per
															hour.
																</p>
																<br></br>
															</li>
															<li value={7}>
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																		gap: '8px',
																		flexWrap: 'wrap',
																		marginBottom: '15px',
																	}}
																>
																	<span>
																WEEKLY CONVERSION RATE. See the applicable Basic
																Contract for details (Day Performer or 3-Day
																Performers Only). The Performer’s weekly
																conversion rate is
																	</span>
																	<FormControl
																		className={styles.tableForms}
																		style={{ width: '250px' }}
																	>
																		<Controller
																			control={contractForm.control}
																			name='weeklyConversionRate'
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={!props?.isPrint ? 'Weekly Conversion Rate' : ''}
																					onChange={(e: any) => {
																						const value = e?.target?.value || '';
																						if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																							field.onChange(value);
																						} else {
																							const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																							field.onChange(num);
																						}
																					}}
																					onBlur={() => {
																						const numericValue = parseFloat((field.value ?? '0').toString());
																						if (!isNaN(numericValue)) {
																							field.onChange(numericValue.toFixed(2));
																						} else {
																							field.onChange('0.00');
																						}
																					}}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.weeklyConversionRate
																			?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.weeklyConversionRate.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>{' '}
																	<span>per week</span>
																</div>
															</li>
															<li value={8}>
																<p>
															PAYMENT ADDRESS. Performer’s payment shall be sent
															to the appropriate Union office in city nearest
															recording site.
																</p>
																{/* <table
																	width={'100%'}
																	cellPadding={0}
																	cellSpacing={0}
																	border={1}
																	className={styles.contractFormsInner}
																>
																	<tr>
																		<td width={120} rowSpan={2}>
																			<p>A. Performer:</p>
																		</td>
																		<td colSpan={2}>
																			<span>(Performer&apos;s Address) </span>
																			<FormControl
																				className={styles.tableForms}
																				fullWidth
																			>
																				<Controller
																					control={contractForm.control}
																					key={`select_Address_${contractForm.watch(
																						'performerId.value'
																					)}`}
																					name='performerAddressId'
																					render={({ field }) => (
																						<>
																							<LookAheadSelect
																								{...field}
																								placeholder={!props?.isPrint ? 'Select Address' : ''}
																								key={`performer-address-${contractForm.watch(
																									'performerId.value'
																								)}`}
																								filterProperty='Line1'
																								sortField='IsPrimary'
																								sortDescending={true}
																								onOptionLoaded={(options) => {
																									if(options?.length && !contractForm.watch('performerAddressId.value')){
																										contractForm.setValue('performerAddressId', options[0], { shouldDirty: true});
																										contractForm.trigger('performerAddressId', { shouldFocus: true});
																										onAddressChange(contractForm.watch('performerAddressId.value') || '', 'performer');
																									}
																								}}
																								APICaller={(filterModel) =>
																									getAllAddressByPerson(
																										filterModel,
																										contractForm.watch(
																											'performerId.value'
																										) || ''
																									)
																								}
																								optionGenerator={
																									adAgencyAddressOptionGenerator
																								}
																								dropDownsize={50}
																								isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
																								isClearable={true}
																								isSearchText={true}
																								onChange={(e: any) => {
																									field.onChange(e);
																									onAddressChange(contractForm.watch('performerAddressId.value') || '', 'performer');
																								}}
																								cacheOptions={false}
																								defaultOptions
																								className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																							/>
																							{																							
																								<Box sx={{mt:1.3}}>
																									{(contractForm.watch('performerId.value') && (!props.isPrint)) && 
																									<AddPersonAddressInline
																										personId={contractForm.watch('performerId.value')}
																										title='ADDRESS'
																										isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'performer-address' || false}
																										event={addressPopOver?.event}
																										onAddressCreated={(d) => onPersonAddressCreated(d)}
																										onClose={() => setAddressPopOver({ isOpen: false })}
																										onPopOverOpen={(e) => setAddressPopOver({
																											isOpen: true,
																											event: e?.currentTarget,
																											for: 'performer-address'
																										})}
																										isPersistent={true} />
																									}
																								</Box>
																							}
																						</>
																					)}
																				/>
																				{contractForm.formState?.errors?.performerAddressId?.message && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState.errors
																								?.performerAddressId?.message
																						}
																					</FormHelperText>
																				)}
																			</FormControl>
																		</td>
																		<td>
																			<span>(ZIP) </span>
																			<FormControl
																				className={styles.tableForms}
																				style={{minWidth:250}}
																				fullWidth
																			>
																				<Controller
																					key={`performer-zip-${contractForm.watch('performerAddressId.value')}`}
																					control={contractForm.control}
																					name='performerZip'
																					defaultValue=''
																					render={({ field }) => (
																						<NabuTextbox
																							key={`performer-zip-${contractForm.watch('performerAddressId.value')}`}
																							{...field}
																							placeholder={!props?.isPrint ? 'ZIP' : ''}
																							disabled={props?.isPrint}
																							onChange={(e) => {
																								field.onChange(e);
																								onZipCodeChange(
																									contractForm.watch('performerZip') || '',
																									'performer'
																								);
																							}}
																						/>
																					)}
																				/>
																				{contractForm.formState.errors?.performerZip
																					?.message && (
																					<FormHelperText
																						data-cy='errorHelper-performerZip'
																						error={true}
																					>
																						{
																							contractForm.formState?.errors?.performerZip
																								.message
																						}
																					</FormHelperText>
																				)}
																			</FormControl>
																		</td>
																	</tr>
																	<tr>
																		<td colSpan={2}>
																			<span>(State) </span>
																			<FormControl
																				className={styles.tableForms}
																				key={`performer-state-${contractForm.watch('performerState')}`}
																				fullWidth
																			>
																				<Controller
																					key={`performer-state-${contractForm.watch('performerState')}`}
																					control={contractForm.control}
																					name='performerState'
																					defaultValue=''
																					render={({ field }) => (
																						<>
																							<NabuSelect
																								displayEmpty={true}
																								{...field}
																								disabled={props?.isPrint}
																								options={getStateOptions()}
																								convertValueToUpperCase={true}
																								placeholder={!props?.isPrint ? 'Select State' : ''}
																							/>
																						</>
																					)}
																				/>
																				{contractForm.formState?.errors?.performerState
																					?.message && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState?.errors?.performerState
																								.message
																						}
																					</FormHelperText>
																				)}
																			</FormControl>
																		</td>
																		<td>
																			<span>(City)</span>
																			<FormControl
																				className={styles.tableForms}

																				fullWidth
																			>
																				<Controller
																					key={`performer-city-${contractForm.watch('performerCity')}`}
																					control={contractForm.control}
																					name='performerCity'
																					defaultValue=''
																					render={({ field }) => (
																						<>
																							<NabuSelect
																								key={`performer-city-${contractForm.watch('performerCity')}`}
																								displayEmpty={true}
																								{...field}
																								disabled={props?.isPrint}
																								options={getCityOptions(
																									contractForm.watch('performerState')
																								)}
																								convertValueToUpperCase={true}
																								placeholder={!props?.isPrint ? 'Select City' : ''}
																							/>
																						</>
																					)}
																				/>
																				{contractForm.formState?.errors?.performerCity
																					?.message && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState?.errors?.performerCity
																								.message
																						}
																					</FormHelperText>
																				)}
																			</FormControl>
																		</td>
																	</tr>

																	<tr>
																		<td width={120} rowSpan={2}>
																			<p>B. Agent:</p>
																		</td>
																		<td>
																			<span>Agency</span>
																			<FormControl
																				className={styles.tableForms}
																				fullWidth
																			>
																				<Controller
																					key={`agency-${contractForm.watch('performerId.value')}`}
																					control={contractForm.control}
																					name='adAgencyId'
																					render={({ field }) => (
																						<>
																							<LookAheadSelect
																								{...field} 
																								key={`agency-${contractForm.watch('performerId.value')}`}
																								placeholder='Agency'
																								filterProperty='CompanyNames.Name'
																								APICaller={(filter) => {
																									filter.filters = filter.filters?.map((f) => f.property == 'CompanyNames.Name' ? {...f, value: f?.value?.trim() || '' } : f) || [];
																									return getAgentInfo(filter, contractForm.watch('performerId.value') || '');
																								}}
																								optionGenerator={companyOptionGenerator}
																								onChange={(e: any) => {
																									field.onChange(e);
																									contractForm.trigger('producerAddressId');
																									setJPCAuthorizer();
																									contractForm.setValue('agentAddressId', null, { shouldDirty: true});
																									contractForm.setValue('agentZip', '', { shouldDirty: true});
																									contractForm.setValue('agentState', '', { shouldDirty: true});
																									contractForm.setValue('agentCity', '', { shouldDirty: true});
																								}}
																								cacheOptions
																								isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
																								defaultOptions
																								dropDownsize={25}
																								isClearable={true}
																								onOptionLoaded={(options) => {
																									if(options?.length && !contractForm.watch('agentId.value')){
																										contractForm.setValue('adAgencyId', options[0], { shouldDirty: true});
																										contractForm.trigger('adAgencyId', { shouldFocus: true});
																									}
																								}}
																								className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																							/>
																							{!props?.isPrint && contractForm.watch('performerId.value') && (<div style={{marginBottom:'-24px', marginTop:'-9px', float:'right'}}><AddTalentAgentInline
																								onCompanyCreated={(createdAgent) => contractForm.setValue('adAgencyId', createdAgent, { shouldDirty: true})}
																								onClose={() => setCompanyPopoverInline({ isOpen: false})}
																								onPopOverOpen={(e) => setCompanyPopoverInline({ isOpen: true, event: e?.currentTarget })}
																								event={companyPopoverInline?.event}
																								isOpen={companyPopoverInline.isOpen}
																								personId={contractForm.watch('performerId.value') || ''}
																							/></div>)}
																						</>
																					)}
																				/>
																				{contractForm.formState.errors?.agentId?.message && (
																					<FormHelperText
																						error={true}
																						data-cy='errorHelper-agentId'
																					>
																						{
																							contractForm.formState?.errors
																								?.agentId.message
																						}
																					</FormHelperText>
																				)}
																				<span>&nbsp;</span>
																			</FormControl>
																		</td>
																		<td>
																			<span>(Agent&apos;s Address) </span>
																			<FormControl
																				className={styles.tableForms}
																				fullWidth
																			>
																				<Controller
																					control={contractForm.control}
																					key={`producer-address-cr-${contractForm.watch(
																						'agentId.value'
																					)}`}
																					name='agentAddressId'
																					render={({ field }) => (
																						<>
																							<LookAheadSelect
																								{...field}
																								placeholder={!props?.isPrint ? 'Select Address' : ''}
																								key={`producer-address-${contractForm.watch(
																									'agentId.value'
																								)}`}
																								filterProperty='Line1'
																								sortField='IsPrimary'
																								sortDescending={true}
																								onOptionLoaded={(options) => {
																									if(options?.length && !contractForm.watch('agentAddressId.value')){
																										contractForm.setValue('agentAddressId', options[0], { shouldDirty: true});
																										contractForm.trigger('agentAddressId', { shouldFocus: true});
																										onAddressChange(contractForm.watch('agentAddressId.value') || '', 'agent');
																									}
																								}}
																								APICaller={(filterModel) =>
																									getAllAddressByCompany(
																										filterModel,
																										contractForm.watch(
																											'agentId.value'
																										) || ''
																									)
																								}
																								optionGenerator={
																									agentAddressOptionGenerator
																								}
																								isDisabled={
																									!contractForm.watch(
																										'agentId.value'
																									) || props?.isPrint
																								}
																								dropDownsize={50}
																								isClearable={true}
																								isSearchText={true}
																								onChange={(e: any) => {
																									field.onChange(e);
																									onAddressChange(contractForm.watch('agentAddressId.value') || '', 'agent');
																								}}
																								cacheOptions={false}
																								defaultOptions
																								className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																							/>
																							{
																								<Box sx={{mt:1.3}}>
																									{(contractForm.watch('agentId.value') && (!props.isPrint)) &&
																									<AddCompanyAddressInline
																										companyId={contractForm.watch('agentId.value')}
																										title = 'ADDRESS'
																										isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'agent-address'|| false}
																										event={addressPopOver?.event}
																										onAddressCreated={(d) => onBAgentAddressCreated(d)}
																										onClose={() => setAddressPopOver({ isOpen: false})}
																										onPopOverOpen={(e) => setAddressPopOver({
																											isOpen: true,
																											event: e?.currentTarget,
																											for: 'agent-address'
																										})}
																										isPersistent={true} />
																											}
																										</Box>
																									}
																								</>
																							)}
																						/>
																						{contractForm.formState.errors?.agentAddressId?.message && (
																							<FormHelperText
																								error={true}
																								data-cy='errorHelper_producer'
																							>
																								{
																									contractForm.formState?.errors
																										?.agentAddressId.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				
																				</div>
																				<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																					<span>(ZIP) </span>
																					<FormControl
																						className={styles.tableForms}
																						style={{minWidth:50}}
																					>
																						<Controller
																							key={`select-zip-${contractForm.watch('agentAddressId.value')}`}
																							control={contractForm.control}
																							name='agentZip'
																							defaultValue=''
																							render={({ field }) => (
																								<NabuTextbox
																									key={`select-zip-${contractForm.watch('agentAddressId.value')}`}
																									{...field}
																									placeholder={!props?.isPrint ? 'ZIP' : ''}
																									disabled={props?.isPrint}
																									onChange={(e) => {
																										field.onChange(e);
																										onZipCodeChange(
																											contractForm.watch('agentZip') || '',
																											'agent'
																										);
																									}}
																								/>
																							)}
																						/>
																						{contractForm.formState.errors?.agentZip
																							?.message && (
																							<FormHelperText
																								data-cy='errorHelper-agentZip'
																								error={true}
																							>
																								{
																									contractForm.formState?.errors?.agentZip
																										.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																		
																				</div>
																			</div>
																			<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																				<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																					<span>(State)</span>
																					<FormControl
																						className={styles.tableForms}
																						style={{minWidth:250}}
																					>
																						<Controller
																							key={`select-${contractForm.watch('agentState')}`}
																							control={contractForm.control}
																							name='agentState'
																							render={({ field }) => (
																								<>
																									<NabuSelect
																										displayEmpty={true}
																										{...field}
																										key={`select-${contractForm.watch('agentState')}`}
																										disabled={props?.isPrint}
																										options={getStateOptions()}
																										convertValueToUpperCase={true}
																										placeholder={!props?.isPrint ? 'Select State' : ''}
																									/>
																								</>
																							)}
																						/>
																						{contractForm?.formState?.errors?.agentState
																							?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors?.agentState
																										.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																					
																				</div>
																				<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																					<span>(City) </span>
																					<FormControl
																						className={styles.tableForms}
																						style={{minWidth:250}}
																					>
																						<Controller
																							key={`select-city-${contractForm.watch('agentCity')}-${contractForm.watch('agentState')}`}
																							control={contractForm.control}
																							name='agentCity'
																							defaultValue=''
																							render={({ field }) => (
																								<>
																									<NabuSelect
																										displayEmpty={true}
																										key={`select-city-${contractForm.watch('agentCity')}-${contractForm.watch('agentState')}`}
																										{...field}
																										disabled={props?.isPrint}
																										options={getCityOptions(
																											contractForm.watch('agentState')
																										)}
																										convertValueToUpperCase={true}
																										placeholder={!props?.isPrint ? 'Select City' : ''}
																									/>
																								</>
																							)}
																						/>
																						{contractForm.formState?.errors?.agentCity
																							?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors?.agentCity
																										.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				
																				</div>
																			</div>

																		</td>
																	</tr>
																</table> */}
																<table
																	width={'100%'}
																	border={0}
																	cellPadding={0}
																	cellSpacing={0}
																	className={styles.contractFormsInner}
																	style={{
																		border: '#515151 solid 1px',
																		
																	}}
																>
																	<tr style={{ background: '#808080' }}>
																	</tr>
																	<tr>
																		<td style={{ paddingTop: '9px' }} colSpan={4}>
																			<div
																				style={{
																					display: 'inline-flex',
																					alignItems: 'center',
																				}}
																			>
																				<Controller
																					control={contractForm.control}
																					name='isPaymentAtPerformerAddress'
																					// defaultValue={true}
																					render={({ field }) => (
																						<div
																							onClick={() => {(!props?.isPrint) && field.onChange(!field.value);
																								contractForm.setValue('isPaymentAtPerformerAgencyAddress', !contractForm.watch('isPaymentAtPerformerAddress'));
																							}}
																							style={{ userSelect: 'none' }}
																						>
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									{...field}
																									value={field.value + ''}
																									checked={field.value}
																									onChange={(e) =>{ field.onChange(e);
																										contractForm.setValue('isPaymentAtPerformerAgencyAddress', !contractForm.watch('isPaymentAtPerformerAddress'));
																									}}
																									type='checkbox'
																									disabled={props?.isPrint}
																									style={{ marginTop: '3px' }}
																								/>
																								<span>
																									<b>To Perfomer at (Address):</b>
																								</span></Box>
																						</div>
																					)}
																				/>
																			</div>
																		</td>
																	</tr>
																	{(<>
																		<tr>
																			<td style={{ width: '170px' }}>
															(Performer&apos;s Address):
																				{contractForm.watch('isPaymentAtPerformerAddress') && <sup>*</sup>}
																			</td>
																			<td colSpan={3}>
																				<FormControl
																					className={styles.tableForms}
																					style={{width:250}}
																				>
																					<Controller
																						control={contractForm.control}
																						key={`select_Address_${contractForm.watch(
																							'performerId.value'
																						)}`}
																						name='performerAddressId'
																						render={({ field }) => (
																							<>
																								<LookAheadSelect
																									{...field}
																									placeholder={!props?.isPrint ? 'Select Address' : ''}
																									key={`performer-address-${contractForm.watch(
																										'performerId.value'
																									)}`}
																									filterProperty='Line1'
																									sortField='IsPrimary'
																									sortDescending={true}
																									onOptionLoaded={(options) => {
																										if(options?.length && !contractForm.watch('performerAddressId.value')){
																											contractForm.setValue('performerAddressId', options[0], { shouldDirty: true});
																											contractForm.trigger('performerAddressId', { shouldFocus: true});
																											onAddressChange(contractForm.watch('performerAddressId.value') || '', 'performer');
																										}
																									}}
																									APICaller={(filterModel) =>
																										getAllAddressByPerson(
																											filterModel,
																											contractForm.watch(
																												'performerId.value'
																											) || ''
																										)
																									}
																									optionGenerator={
																										adAgencyAddressOptionGenerator
																									}
																									dropDownsize={50}
																									isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
																									isClearable={true}
																									isSearchText={true}
																									onChange={(e: any) => {
																										field.onChange(e);
																										onAddressChange(contractForm.watch('performerAddressId.value') || '', 'performer');
																									}}
																									cacheOptions={false}
																									defaultOptions
																									className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																								/>
																								{																							
																									<Box sx={{mt:1.3}}>
																										{(contractForm.watch('performerId.value') && (!props.isPrint)) && 
																									<AddPersonAddressInline
																										personId={contractForm.watch('performerId.value')}
																										title='ADDRESS'
																										isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'performer-address' || false}
																										event={addressPopOver?.event}
																										onAddressCreated={(d) => onPersonAddressCreated(d)}
																										onClose={() => setAddressPopOver({ isOpen: false })}
																										onPopOverOpen={(e) => setAddressPopOver({
																											isOpen: true,
																											event: e?.currentTarget,
																											for: 'performer-address'
																										})}
																										isPersistent={true} />
																										}
																									</Box>
																								}
																							</>
																						)}
																					/>
																					{contractForm.formState?.errors?.performerAddressId
																						?.value?.message && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState.errors
																									?.performerAddressId?.value?.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																		</tr>
																		<tr>
																			<td colSpan={4}>
																				<div
																					style={{
																						marginTop: '7px',
																						display: 'inline-block',
																					}}
																				>
																(ZIP) :
																				</div>{' '}
																				<FormControl
																					className={styles.tableForms}
																					style={{minWidth:250}}
																				>
																					<Controller
																						key={`performer-zip-${contractForm.watch('performerZip')}`}
																						control={contractForm.control}
																						name='performerZip'
																						defaultValue=''
																						render={({ field }) => (
																							<NabuTextbox
																								key={`performer-zip-${contractForm.watch('performerZip')}`}
																								{...field}
																								placeholder={!props?.isPrint ? 'ZIP' : ''}
																								disabled={props?.isPrint}
																								onChange={(e) => {
																									field.onChange(e);
																									onZipCodeChange(
																										contractForm.watch('performerZip') || '',
																										'performer'
																									);
																								}}
																								type='number'
																							/>
																						)}
																					/>
																					{contractForm.formState.errors?.performerZip
																						?.message && (
																						<FormHelperText
																							data-cy='errorHelper-performerZip'
																							error={true}
																						>
																							{
																								contractForm.formState?.errors?.performerZip
																									.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																				<div
																					style={{
																						marginTop: '7px',
																						display: 'inline-block',
																					}}
																				>
																(State):
																				</div>{' '}
																				<FormControl
																					className={styles.tableForms}
																					style={{width:250}}
																					key={`performer-state-${contractForm.watch('performerState')}`}
																				>
																					<Controller
																						key={`performer-state-${contractForm.watch('performerState')}`}
																						control={contractForm.control}
																						name='performerState'
																						defaultValue=''
																						render={({ field }) => (
																							<>
																								<NabuSelect
																									displayEmpty={true}
																									{...field}
																									disabled={props?.isPrint}
																									options={getStateOptions()}
																									convertValueToUpperCase={true}
																									placeholder={!props?.isPrint ? 'Select State' : ''}
																								/>
																							</>
																						)}
																					/>
																					{contractForm.formState?.errors?.performerState
																						?.message && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors?.performerState
																									.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																				<div
																					style={{
																						marginTop: '7px',
																						display: 'inline-block',
																					}}
																				>
																(City) :
																				</div>{' '}
																				<FormControl
																					className={styles.tableForms}
																					style={{minWidth:250}}
																				>
																					<Controller
																						key={`performer-city-${contractForm.watch('performerCity')}`}
																						control={contractForm.control}
																						name='performerCity'
																						defaultValue=''
																						render={({ field }) => (
																							<>
																								<NabuSelect
																									key={`performer-city-${contractForm.watch('performerCity')}`}
																									displayEmpty={true}
																									{...field}
																									disabled={props?.isPrint}
																									options={getCityOptions(
																										contractForm.watch('performerState')
																									)}
																									convertValueToUpperCase={true}
																									placeholder={!props?.isPrint ? 'Select City' : ''}
																								/>
																							</>
																						)}
																					/>
																					{contractForm.formState?.errors?.performerCity
																						?.message && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors?.performerCity
																									.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																		</tr>
																	</>)}
																</table>
																<table
																	width={'100%'}
																	border={0}
																	cellPadding={0}
																	cellSpacing={0}
																	className={styles.contractFormsInner}
																	style={{
																		border: '#515151 solid 1px',
																		borderTop: 'none',
																	}}
																>
																	<tr>
																		<td style={{ paddingTop: '9px' }} colSpan={6}>
																			<div
																				style={{
																					display: 'inline-flex',
																					alignItems: 'center',
																				}}
																			>
																				<Controller
																					control={contractForm.control}
																					name='isPaymentAtPerformerAgencyAddress'
																					defaultValue={false}
																					render={({ field }) => (
																						<div
																							onClick={() =>{ (!props?.isPrint) && field.onChange(!field.value);
																								contractForm.setValue('isPaymentAtPerformerAddress', !contractForm.watch('isPaymentAtPerformerAgencyAddress'));
																							}}
																							style={{ userSelect: 'none' }}
																						>
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									{...field}
																									value={field.value + ''}
																									checked={field.value}
																									onChange={(e) => {field.onChange(e);
																										contractForm.setValue('isPaymentAtPerformerAddress', !contractForm.watch('isPaymentAtPerformerAgencyAddress'));
																									}}
																									type='checkbox'
																									disabled={props?.isPrint}
																									style={{ marginTop: '3px' }}
																								/>
																								<span>
																									<b>
																					To Performer c/o (Agent/Representative,
																					Address):
																									</b>
																								</span></Box>
																						</div>
																					)}
																				/>
																			</div>
																		</td>
																	</tr>
																	{(<>
																		<tr>
																			<td style={{ width: '170px' }}>
															(Agent/Representative)
																				{contractForm.watch('isPaymentAtPerformerAgencyAddress') && <sup>*</sup>}
																			</td>
																			<td>
																				<FormControl
																					className={styles.tableForms}
																					fullWidth
																				>
																					<Controller
																						key={`agency-${contractForm.watch('performerId.value')}-${contractForm.watch('agentId')}`}
																						control={contractForm.control}
																						name='agentId'
																						render={({ field }) => (
																							<>
																								<LookAheadSelect
																									{...field} 
																									key={`agency-${contractForm.watch('performerId.value')}-${contractForm.watch('agentId')}`}
																									placeholder='Agency'
																									filterProperty='CompanyNames.Name'
																									APICaller={(filter) => {
																										filter.filters = filter.filters?.map((f) => f.property == 'CompanyNames.Name' ? {...f, value: f?.value?.trim() || '' } : f) || [];
																										return getAgentInfo(filter, contractForm.watch('performerId.value') || '');
																									}}
																									optionGenerator={companyOptionGenerator}
																									onChange={(e: any) => {
																										field.onChange(e);
																										contractForm.setValue('agentAddressId', null, { shouldDirty: true});
																										onAddressChange(contractForm.watch('agentAddressId.value') || '', 'agent');
																									}}
																									onOptionLoaded={(options) => {
																										if(options?.length && !contractForm.watch('agentId.value')){
																											contractForm.setValue('agentId', options[0], { shouldDirty: true});									
																											contractForm.trigger('agentId', { shouldFocus: true});																					
																										}
																									}}
																									cacheOptions
																									isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
																									defaultOptions
																									dropDownsize={25}
																									isClearable={true}
																									className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																								/>
																								{!props?.isPrint && contractForm.watch('performerId.value') && (<AddTalentAgentInline
																									onCompanyCreated={(createdAgent) => contractForm.setValue('agentId', createdAgent, { shouldDirty: true})}
																									onClose={() => setCompanyPopoverInline({ isOpen: false})}
																									onPopOverOpen={(e) => setCompanyPopoverInline({ isOpen: true, event: e?.currentTarget })}
																									event={companyPopoverInline?.event}
																									isOpen={companyPopoverInline.isOpen}
																									personId={contractForm.watch('performerId.value') || ''}
																								/>)}
																							</>
																						)}
																					/>
																					{contractForm.formState.errors?.agentId?.message && (
																						<FormHelperText
																							error={true}
																							data-cy='errorHelper_ad_agency'
																						>
																							{
																								contractForm.formState?.errors
																									?.agentId.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																			<td style={{ width: '140px' }}>
															(Agent&apos;s Address)
																				{contractForm.watch('isPaymentAtPerformerAgencyAddress') && <sup>*</sup>}
																			</td>
																			<td>
																				<FormControl
																					className={styles.tableForms}
																					style={{minWidth:250}}
																				>
																					<Controller
																						control={contractForm.control}
																						key={`producer-address-cr-${contractForm.watch(
																							'agentId.value'
																						)}`}
																						name='agentAddressId'
																						render={({ field }) => (
																							<>
																								<LookAheadSelect
																									{...field}
																									placeholder={!props?.isPrint ? 'Select Address' : ''}
																									key={`producer-address-${contractForm.watch(
																										'agentId.value'
																									)}`}
																									filterProperty='Line1'
																									sortField='IsPrimary'
																									sortDescending={true}
																									onOptionLoaded={(options) => {
																										if(options?.length && !contractForm.watch('agentAddressId.value')){
																											contractForm.setValue('agentAddressId', options[0], { shouldDirty: true});
																											contractForm.trigger('agentAddressId', { shouldFocus: true});
																											onAddressChange(contractForm.watch('agentAddressId.value') || '', 'agent');
																										}
																									}}
																									APICaller={(filterModel) =>
																										getAllAddressByCompany(
																											filterModel,
																											contractForm.watch(
																												'agentId.value'
																											) || ''
																										)
																									}
																									optionGenerator={
																										agentAddressOptionGenerator
																									}
																									isDisabled={
																										!contractForm.watch(
																											'agentId.value'
																										) || props?.isPrint
																									}
																									dropDownsize={50}
																									isClearable={true}
																									isSearchText={true}
																									onChange={(e: any) => {
																										field.onChange(e);
																										onAddressChange(contractForm.watch('agentAddressId.value') || '', 'agent');
																									}}
																									cacheOptions={false}
																									defaultOptions
																									className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																								/>
																								{
																									<Box sx={{mt:1.3}}>
																										{(contractForm.watch('agentId.value') && (!props.isPrint)) &&
																									<AddCompanyAddressInline
																										companyId={contractForm.watch('agentId.value')}
																										title = 'ADDRESS'
																										isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'agent-address'|| false}
																										event={addressPopOver?.event}
																										onAddressCreated={(d) => onBAgentAddressCreated(d)}
																										onClose={() => setAddressPopOver({ isOpen: false})}
																										onPopOverOpen={(e) => setAddressPopOver({
																											isOpen: true,
																											event: e?.currentTarget,
																											for: 'agent-address'
																										})}
																										isPersistent={true} />
																										}
																									</Box>
																								}
																							</>
																						)}
																					/>
																					{contractForm.formState.errors?.agentAddressId
																						?.value?.message && (
																						<FormHelperText
																							error={true}
																							data-cy='errorHelper_producer'
																						>
																							{
																								contractForm.formState?.errors
																									?.agentAddressId.value.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																		</tr>
																		<tr>
																			<td style={{ width: '140px' }}>(ZIP)</td>
																			<td>
																				<FormControl
																					className={styles.tableForms}
																					style={{minWidth:50}}
																				>
																					<Controller
																						key={`select-zip-${contractForm.watch('agentZip')}`}
																						control={contractForm.control}
																						name='agentZip'
																						defaultValue=''
																						render={({ field }) => (
																							<NabuTextbox
																								key={`select-zip-${contractForm.watch('agentZip')}`}
																								{...field}
																								placeholder={!props?.isPrint ? 'ZIP' : ''}
																								disabled={props?.isPrint}
																								onChange={(e) => {
																									field.onChange(e);
																									onZipCodeChange(
																										contractForm.watch('agentZip') || '',
																										'agent'
																									);
																								}}
																								type='number'
																							/>
																						)}
																					/>
																					{contractForm.formState.errors?.agentZip
																						?.message && (
																						<FormHelperText
																							data-cy='errorHelper-agentZip'
																							error={true}
																						>
																							{
																								contractForm.formState?.errors?.agentZip
																									.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																			<td style={{ width: '170px' }}>(State)</td>
																			<td>
																				<FormControl
																					className={styles.tableForms}
																					style={{minWidth:250}}
																				>
																					<Controller
																						key={`select-${contractForm.watch('agentState')}`}
																						control={contractForm.control}
																						name='agentState'
																						defaultValue=''
																						render={({ field }) => (
																							<>
																								<NabuSelect
																									displayEmpty={true}
																									{...field}
																									key={`select-${contractForm.watch('agentState')}`}
																									disabled={props?.isPrint}
																									options={getStateOptions()}
																									convertValueToUpperCase={true}
																									placeholder={!props?.isPrint ? 'Select State' : ''}
																								/>
																							</>
																						)}
																					/>
																					{contractForm?.formState?.errors?.agentState
																						?.message && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors?.agentState
																									.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																			<td style={{ width: '130px' }}>(City)</td>
																			<td>
																				<FormControl
																					className={styles.tableForms}
																					style={{minWidth:250}}
																				>
																					<Controller
																						key={`select-city-${contractForm.watch('agentCity')}-${contractForm.watch('agentState')}`}
																						control={contractForm.control}
																						name='agentCity'
																						defaultValue=''
																						render={({ field }) => (
																							<>
																								<NabuSelect
																									displayEmpty={true}
																									key={`select-city-${contractForm.watch('agentCity')}-${contractForm.watch('agentState')}`}
																									{...field}
																									disabled={props?.isPrint}
																									options={getCityOptions(
																										contractForm.watch('agentState')
																									)}
																									convertValueToUpperCase={true}
																									placeholder={!props?.isPrint ? 'Select City' : ''}
																								/>
																							</>
																						)}
																					/>
																					{contractForm.formState?.errors?.agentCity
																						?.message && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors?.agentCity
																									.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																		</tr>
																	</>)}
																</table>
																<br></br>
															</li>
															<li value={9}>
																<p>
															ADDITIONAL COMPENSATION FOR SUPPLEMENTAL USE -
															Producer may acquire the following supplemental
															use rights by the payment of the indicated
															amounts.
																</p>
																<p>
																	<b>
																(Check appropriate items below.) See the
																applicable Basic Contract for details of
																payment.
																	</b>
																</p>
																<br></br>
																<table
																	width={'100%'}
																	cellPadding={0}
																	cellSpacing={0}
																	className={styles.contractFormsInner}
																	border={1}
																>
																	<tr>
																		<td colSpan={2}> </td>

																		<td align='center'>
																			{' '}
																			<b>
																		Within 90 Days<br></br>
																		(Total Applicable Salary)
																			</b>
																		</td>
																		<td align='center'>
																			{' '}
																			<b>
																		Beyond 90 Days<br></br>
																		(Total Applicable Salary)
																			</b>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<div className={styles.contractTableIndustrial}>
																				<FormControl className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						name='isAdditionalCompensationA'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										onChange={(e) => field.onChange(e)}
																										type='checkbox'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>A</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</FormControl>																		
																			</div>
																		</td>
																		<td>
																			{' '}
																	Basic Cable Television, 3 Years. Supplemental
																	Use rights are a % of the total actual salary{' '}
																		</td>
																		<td align='center'> 15% </td>
																		<td align='center'> 65% </td>
																	</tr>
																	<tr>
																		<td>
																			<div className={styles.contractTableIndustrial}>
																				<FormControl className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						name='isAdditionalCompensationB'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										onChange={(e) => field.onChange(e)}
																										type='checkbox'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>B</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</FormControl>
																			</div>
																		</td>
																		<td>Non-Network Television, unlimited runs</td>
																		<td align='center'>75% </td>
																		<td align='center'>125%</td>
																	</tr>
																	<tr>
																		<td>
																			<div className={styles.contractTableIndustrial}>
																				<FormControl className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						name='isAdditionalCompensationC'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										onChange={(e) => field.onChange(e)}
																										type='checkbox'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>C</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</FormControl>
																			</div>
																		</td>
																		<td>Theatrical Exhibition, unlimited runs</td>
																		<td align='center'> 100%</td>
																		<td align='center'>150%</td>
																	</tr>
																	<tr>
																		<td>
																			<div className={styles.contractTableIndustrial}>
																				<FormControl className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						name='isAdditionalCompensationD'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										onChange={(e) => field.onChange(e)}
																										type='checkbox'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>D</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</FormControl>
																			</div>
																		</td>
																		<td>
																	Foreign Television, unlimited runs outside US
																	and Canada
																		</td>
																		<td align='center'>25% </td>
																		<td align='center'>75%</td>
																	</tr>
																	<tr>
																		<td>
																			<div className={styles.contractTableIndustrial}>
																				<FormControl className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						name='isAdditionalCompensationE'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										onChange={(e) => field.onChange(e)}
																										type='checkbox'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>E</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</FormControl>
																			</div>
																		</td>
																		<td>Integration and/or Customization </td>
																		<td align='center'> 100%</td>
																		<td align='center'>100%</td>
																	</tr>
																	<tr>
																		<td>
																			<div className={styles.contractTableIndustrial}>
																				<FormControl className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						name='isAdditionalCompensationF'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										onChange={(e) => field.onChange(e)}
																										type='checkbox'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>F</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</FormControl>
																			</div>
																		</td>
																		<td>Sale and/or Rental to Industry</td>
																		<td align='center'>15% </td>
																		<td align='center'>25%</td>
																	</tr>
																	<tr>
																		<td>
																			<div className={styles.contractTableIndustrial}>
																				<FormControl className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						name='isAdditionalCompensationG'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										onChange={(e) => field.onChange(e)}
																										type='checkbox'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>G</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</FormControl>
																			</div>
																		</td>
																		<td>
																			{' '}
																	&quot;Package&quot;` Rights to A, B, C, D, E,
																	F and G above
																		</td>
																		<td align='center'>200% </td>
																		<td align='center'>Not Available</td>
																	</tr>
																	<tr>
																		<td>
																			<div className={styles.contractTableIndustrial}>
																				<FormControl className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						name='isAdditionalCompensationH'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										onChange={(e) => field.onChange(e)}
																										type='checkbox'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>H</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</FormControl>
																			</div>
																		</td>
																		<td>
																	Category II (point of purchase of Category I
																	program only){' '}
																		</td>
																		<td align='center'>50% </td>
																		<td align='center'>100%</td>
																	</tr>
																	<tr>
																		<td>
																			<div className={styles.contractTableIndustrial}>
																				<FormControl className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						name='isAdditionalCompensationI'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										onChange={(e) => field.onChange(e)}
																										type='checkbox'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>I</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</FormControl>
																			</div>
																		</td>
																		<td>
																	Network Television (available only by prior
																	negotiation with and approval of the Union)*
																		</td>
																		<td align='center'> </td>
																		<td align='center'></td>
																	</tr>
																	<tr>
																		<td>
																			<div className={styles.contractTableIndustrial}>
																				<FormControl className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						name='isAdditionalCompensationJ'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										onChange={(e) => field.onChange(e)}
																										type='checkbox'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>J</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</FormControl>
																			</div>
																		</td>
																		<td>
																	*Performer does not consent to the use of
																	his/her services made hereunder for Network
																	Television.
																		</td>
																		<td align='center'> </td>
																		<td align='center'></td>
																	</tr>
																	<tr>
																		<td>
																			<div className={styles.contractTableIndustrial}>
																				<FormControl className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						name='isAdditionalCompensationK'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										onChange={(e) => field.onChange(e)}
																										type='checkbox'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>K</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</FormControl>
																			</div>
																		</td>
																		<td>
																	Pay Cable Television (available only by prior
																	negotiation with and approval of the Union)**
																		</td>
																		<td align='center'> </td>
																		<td align='center'></td>
																	</tr>
																	<tr>
																		<td>
																			<div className={styles.contractTableIndustrial}>
																				<FormControl className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						name='isAdditionalCompensationL'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										onChange={(e) => field.onChange(e)}
																										type='checkbox'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>L</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</FormControl>
																			</div>
																		</td>
																		<td>
																	**Performer does not consent to the use of
																	his/her services made hereunder for Pay Cable
																	Television
																		</td>
																		<td align='center'> </td>
																		<td align='center'></td>
																	</tr>
																	<tr>
																		<td>
																			<div className={styles.contractTableIndustrial}>
																				<FormControl className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						name='isAdditionalCompensationM'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										onChange={(e) => field.onChange(e)}
																										type='checkbox'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>M</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</FormControl>
																			</div>
																		</td>
																		<td>
																	Group Dancers (See Basic Contract for payment
																	provisions)
																		</td>
																		<td align='center'> </td>
																		<td align='center'></td>
																	</tr>
																	<tr>
																		<td>
																			<div className={styles.contractTableIndustrial}>
																				<FormControl className={styles.tableForms}>
																					<Controller
																						control={contractForm.control}
																						name='isAdditionalCompensationN'
																						defaultValue={false}
																						render={({ field }) => (
																							<div
																								onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																								style={{ userSelect: 'none' }}
																							>
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										onChange={(e) => field.onChange(e)}
																										type='checkbox'
																										disabled={props?.isPrint}
																										style={{ marginTop: '3px' }}
																									/>
																									<span>N</span>
																								</Box>
																							</div>
																						)}
																					/>
																				</FormControl>
																			</div>
																		</td>
																		<td>Program for Government Service Only</td>
																		<td align='center'>40%</td>
																		<td align='center'>Not Available</td>
																	</tr>
																</table>
																<br></br>
															</li>
															<li value={10}>
																<p>
																	{' '}
															SALE AND/OR RENTAL OF PROGRAMS TO THE GENERAL
															PUBLIC. Producer may acquire sale/rental rights
															for an additional 200% of scale for the number of
															days worked.
																</p>
																<br></br>
															</li>
															<li value={11}>
																
															WARDROBE.<br></br> 
																<p>
	If Principal Performer furnishes own wardrobe, the following fees shall apply for each
	two-day period or portion thereof:
																</p>
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																		gap: '8px',
																		flexWrap: 'wrap',
																		marginBottom: '15px',
																	}}
																>
																	<FormControl className={styles.tableForms}>
																		<Controller
																			control={contractForm.control}
																			name='wardrobePrincipalPerformer'
																			render={({ field }) => (
																				<div
																					onClick={() => {
																						if (!props?.isPrint) {
																							field.onChange(!field.value);
																							setSelectedWardrobe('principal');
																						}
																					}}
																					style={{ userSelect: 'none' }}
																				>
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							{...field}
																							value={field.value + ''}
																							checked={selectedWardrobe === 'principal'}
																							onChange={(e) => {
																								field.onChange(e);
																								setSelectedWardrobe('principal');
																							}}
																							type='checkbox'
																							disabled={props?.isPrint}
																							style={{ marginTop: '3px' }}
																						/>
																					</Box>
																				</div>
																			)}
																		/>
																	</FormControl>
																	<span>Ordinary Wardrobe (non-evening)</span>

																	<FormControl className={styles.tableForms} style={{ width: '100px' }}>
																		<Controller
																			control={contractForm.control}
																			name='nonEveningWearCountPrincipalPerformer'
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={!props?.isPrint ? 'Non Evening Wear Count' : ''}
																					type='number'
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.nonEveningWearCountPrincipalPerformer?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors?.nonEveningWearCountPrincipalPerformer.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																	<span>($19 Minimum); Evening or Formal Wear</span>

																	<FormControl className={styles.tableForms} style={{ width: '100px' }}>
																		<Controller
																			control={contractForm.control}
																			name='eveningWearCountByPrincipalPerformer'
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={!props?.isPrint ? 'Evening Wear Count' : ''}
																					type='number'
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.eveningWearCountByPrincipalPerformer?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors?.eveningWearCountByPrincipalPerformer.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																	<span>($29 Minimum)</span><br></br>
																	<span>
															If Extra Performer furnishes own wardrobe, the
															following fees shall apply:
																	</span>
																</div>
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																		gap: '8px',
																		flexWrap: 'wrap',
																		marginBottom: '15px',
																	}}
																>
																	<FormControl className={styles.tableForms}>
																		<Controller
																			control={contractForm.control}
																			name='wardrobeExtraPerformer'
																			render={({ field }) => (
																				<div
																					onClick={() => {
																						if (!props?.isPrint) {
																							field.onChange(!field.value);
																							setSelectedWardrobe('extra');
																						}
																					}}
																					style={{ userSelect: 'none' }}
																				>
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							{...field}
																							value={field.value + ''}
																							checked={selectedWardrobe === 'extra'}
																							onChange={(e) => {
																								field.onChange(e);
																								setSelectedWardrobe('extra');
																							}}
																							type='checkbox'
																							disabled={props?.isPrint}
																							style={{ marginTop: '3px' }}
																						/>
																					</Box>
																				</div>
																			)}
																		/>
																	</FormControl>
																	<span>Ordinary Wardrobe, 1st change @ $17</span>
																	<FormControl
																		className={styles.tableForms}
																		style={{ width: '100px' }}
																	>
																		<Controller
																			control={contractForm.control}
																			name='eveningWearCountByExtraPerformer'
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					disabled={props?.isPrint}
																					type='number'
																					placeholder={!props?.isPrint ? 'Evening Wear Count' : ''}
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.eveningWearCountByExtraPerformer?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.eveningWearCountByExtraPerformer.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																	<span># add’l changes @ $6 ea.; Formal/Dress/Period Wear</span>
																	<FormControl
																		className={styles.tableForms}
																		style={{ width: '100px' }}
																	>
																		<Controller
																			control={contractForm.control}
																			name='additionalWearChangeCountByExtraPerformer'
																			render={({ field }) => (
																				<NabuTextbox
																					{...field}
																					disabled={props?.isPrint}
																					placeholder={!props?.isPrint ? 'Additional Wear Change Count' : ''}
																					type='number'
																				/>
																			)}
																		/>
																		{contractForm.formState.errors?.additionalWearChangeCountByExtraPerformer?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.additionalWearChangeCountByExtraPerformer.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
																	<span> # costume changes @ $28 ea. </span>
																</div>
															</li>
														</ol>
													</div>
													<div id={CONTRACT_REF_ID.MAIN_FORM_PART_3} className={styles.contractFormBlock} style={{padding:'20px'}}>
														<div className={styles.bgWhite}>
															<ol>
																<li value={12}>
																	<table
																		width={'100%'}
																		cellPadding={0}
																		cellSpacing={0}
																		border={0}
																		className={`${styles.contractFormsInner} keep-together`}
																	>
																		<tr>
																			<td colSpan={2}>SPECIAL PROVISIONS</td>
																		</tr>
																		<tr>
																			<td colSpan={2}>
																				<div>
																					{
																						props?.isPrint ? (
																							<p className={styles.descriptionPrintText}>{contractForm.watch('specialProvisions')}</p>
																						) : (<div className={styles.textareaSection} > 
																							<Controller
																								control={contractForm.control}
																								name='specialProvisions'
																								render={({ field }) => (
																									<NabuBaseTextArea
																										className={styles.specialProvision}
																										{...field}
																										rows={5}
																										disabled={props?.isPrint}
																										placeholder={!props?.isPrint ? 'SPECIAL PROVISIONS' : ''}
																									/>
																								)}
																							/>
																							{contractForm.formState.errors?.specialProvisions
																								?.message && (
																								<FormHelperText error={true}>
																									{
																										contractForm.formState?.errors
																											?.specialProvisions.message
																									}
																								</FormHelperText>
																							)}
																						</div> )
																					}
																				</div>
																			
																			</td>
																		</tr>
																		<tr>
																			<td width={'50%'}>
																				<p>
																		Performer acknowledges that he/she has read
																		all the terms and conditions in the Special
																		Provisions section above and hereby agrees
																		thereto:
																				</p>
																			</td>
																			<td>
																	Performer Signature
																				<FormControl
																					fullWidth
																					className={styles.tableForms}
																				>
																					<NabuTextbox disabled={true} />
																				</FormControl>
																			</td>
																		</tr>
																	</table>
																	<br></br>
																</li>
																<li value={13} className={'keep-together'}>
														GENERAL. All Terms and conditions of the current
														Non-Broadcast/Industrial/Educational Recorded
														Materials Contract (Basic Contract) shall be
														applicable to such employment. <br></br>
																	<table
																		width={'100%'}
																		border={0}
																		cellPadding={0}
																		cellSpacing={0}
																		className={styles.contractFormsInner}
																	>
																		<tr>
																			<td width={210}>(Producer&apos;s Signature)</td>
																			<td width={400}>
																				<FormControl
																					fullWidth
																					className={styles.tableForms}
																				>
																					<NabuTextbox disabled={true} />
																				</FormControl>
																			</td>
																			<td width={180}>
																	(Performer&apos;s Signature)
																			</td>
																			<td>
																				<FormControl
																					fullWidth
																					className={styles.tableForms}
																				>
																					<NabuTextbox disabled={true} />
																				</FormControl>
																			</td>
																		</tr>
																		<tr>
																			<td width={210}>(Producer){ !props?.isPrint && (
																				<Tooltip
																					placement='right-end'
																					title={'Signatory'}
																				>
																					<HelpOutline fontSize='inherit' />
																				</Tooltip>)
																			} </td>
																			<td>
																				<FormControl
																					fullWidth
																					className={styles.tableForms}
																				>
																					{/* <Controller
																						control={contractForm.control}
																						name='producerCompanyId'
																						render={({ field }) => (
																							<>
																								<LookAheadSelect
																									{...field}
																									placeholder={!props?.isPrint ? 'Producer' : ''}
																									key={`producer-${contractForm.watch('producerCompanyId.value')}`}
																									filterProperty='CompanyNames.Name'
																									APICaller={getProducerCompanyForContract}
																									optionGenerator={companyOptionGenerator}
																									isDisabled={props?.isPrint}
																									isClearable={true}
																									onChange={(e: any) => {
																										field.onChange(e);
																										contractForm.setFocus('producerAddressId');
																										contractForm.setValue('producerAddressId', null, { shouldDirty: true });
																									}}
																									cacheOptions={false}
																									defaultOptions
																									className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																								/>
																								{!props?.isPrint && (
																									<Box sx={{mt:0.5}}>
																										<AddCompanyInline
																											title='Company'
																										/>
																									</Box>
																								)}
																							</>
																						)}
																					/> */}
																					{/* {contractForm.formState.errors?.producerCompanyId
																						?.value?.message && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors
																									?.producerCompanyId.value.message
																							}
																						</FormHelperText>
																					)} */}
																					<Controller
																						control={contractForm.control}
																						name='producerCompany'
																						defaultValue=''
																						render={({ field }) => (
																							<NabuTextbox
																								{...field}
																								disabled={props?.isPrint}
																								placeholder={!props?.isPrint ? 'Producer' : ''}
																							/>
																						)}
																					/>
																				</FormControl>
																			</td>
																			<td width={180}>(Social Security #)</td>
																			<td>
																				<FormControl
																					className={styles.tableForms}
																					fullWidth
																				>
																					<Controller
																						control={contractForm.control}
																						name='ssn'
																						render={({ field }) => (
																							<>
																								<input
																									{...field}
																									placeholder={!props?.isPrint ? 'SSN' : ''}
																									disabled={props?.isPrint}
																									value={getPerformerSSN()}
																								/>
																							</>
																						)}
																					/>
																				</FormControl> 
																			</td>
																		</tr>
																		<tr>
																			<td width={200}>(Producer&apos;s Address)</td>
																			<td>
																				<FormControl
																					fullWidth
																					className={styles.tableForms}
																				>
																					{/* <Controller
																						control={contractForm.control}
																						key={`producer-address-cr-${contractForm.watch(
																							'producerCompanyId.value'
																						)}`}
																						name='producerAddressId'
																						render={({ field }) => (
																							<>
																								<LookAheadSelect
																									{...field}
																									placeholder={!props?.isPrint ? 'Select Address' : ''}
																									key={`producer-address-${contractForm.watch(
																										'producerCompanyId.value'
																									)}`}
																									filterProperty='Line1'
																									sortField='IsPrimary'
																									sortDescending={true}
																									onOptionLoaded={(options) => {
																										if(options?.length && !contractForm.watch('producerAddressId.value')){
																											contractForm.setValue('producerAddressId', options[0], { shouldDirty: true});
																											contractForm.trigger('producerAddressId', { shouldFocus: true});
																										}
																									}}
																									APICaller={(filterModel) =>
																										getAllAddressByCompany(
																											filterModel,
																											contractForm.watch(
																												'producerCompanyId.value'
																											) || ''
																										)
																									}
																									optionGenerator={
																										producerAddressOptionGenerator
																									}
																									isDisabled={
																										!contractForm.watch(
																											'producerCompanyId.value'
																										) || props?.isPrint
																									}
																									dropDownsize={50}
																									isClearable={true}
																									isSearchText={true}
																									onChange={(e: any) => {
																										field.onChange(e);
																									}}
																									cacheOptions={false}
																									defaultOptions
																									className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																								/>
																								{
																									<Box sx={{mt:1.3}}>
																										{(contractForm.watch('producerCompanyId.value')&& (!props.isPrint)) &&
																									<AddCompanyAddressInline
																										companyId={contractForm.watch('producerCompanyId.value')}
																										title = 'ADDRESS'
																										isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'ProducerCompany-address'|| false}
																										event={addressPopOver?.event}
																										onAddressCreated={(d) => onProducerAddressCreated(d)}
																										onClose={() => setAddressPopOver({ isOpen: false})}
																										onPopOverOpen={(e) => setAddressPopOver({
																											isOpen: true,
																											event: e?.currentTarget,
																											for: 'ProducerCompany-address'
																										})}
																										isPersistent = {true} />
																										}
																									</Box>
																								}
																							</>
																						)}
																					/>
																					{contractForm.formState.errors?.producerAddressId
																						?.value?.message && (
																						<FormHelperText
																							error={true}
																							data-cy='errorHelper_producer'
																						>
																							{
																								contractForm.formState?.errors
																									?.producerAddressId.value.message
																							}
																						</FormHelperText>
																					)} */}
																					<Controller
																						control={contractForm.control}
																						name='producerCompanyAddress'
																						defaultValue=''
																						render={({ field }) => (
																							<NabuTextbox
																								{...field}
																								disabled={props?.isPrint}
																								placeholder={!props?.isPrint ? 'Producer Address' : ''}
																							/>
																						)}
																					/>
																				</FormControl>
																			</td>
																			<td width={180}>(FED ID #)</td>
																			<td>
																				<FormControl 
																					fullWidth 
																					className={styles.tableForms}
																				>
																					<Controller
																						control={contractForm.control}
																						name = 'fedId'
																						key={`fedId_${contractForm.watch('performerId.value')}`}
																						render={({ field }) => (
																							<NabuTextbox 
																								{...field}
																								key={`fedId_${contractForm.watch('performerId.value')}`}
																								// onChange={undefined}
																								value = {contractForm.watch('fedId')}
																								placeholder="FED-ID #" 
																								disabled = {true}
																							/>
																						)}
																		
																					/>
																	
																				</FormControl> 
																			</td>
																		</tr>
																		<tr>
																			<td width={200}>(Performer&apos;s Address)</td>
																			<td>
																				<FormControl
																					fullWidth
																					className={styles.tableForms}
																				>
																					<Controller
																						control={contractForm.control}
																						key={`select_Address_${contractForm.watch(
																							'performerId.value'
																						)}`}
																						name='performerAddressId'
																						render={({ field }) => (
																							<>
																								<LookAheadSelect
																									{...field}
																									placeholder={!props?.isPrint ? 'Select Address' : ''}
																									key={`performer-address-${contractForm.watch(
																										'performerId.value'
																									)}`}
																									filterProperty='Line1'
																									sortField='IsPrimary'
																									sortDescending={true}
																									onOptionLoaded={(options) => {
																										if(options?.length && !contractForm.watch('performerAddressId.value')){
																											contractForm.setValue('performerAddressId', options[0], { shouldDirty: true});
																											contractForm.trigger('performerAddressId', { shouldFocus: true});
																											onAddressChange(contractForm.watch('performerAddressId.value') || '', 'performer');
																										}
																									}}
																									APICaller={(filterModel) =>
																										getAllAddressByPerson(
																											filterModel,
																											contractForm.watch(
																												'performerId.value'
																											) || ''
																										)
																									}
																									optionGenerator={
																										adAgencyAddressOptionGenerator
																									}
																									dropDownsize={50}
																									isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
																									isClearable={true}
																									isSearchText={true}
																									onChange={(e: any) => {
																										field.onChange(e);
																									}}
																									cacheOptions={false}
																									defaultOptions
																									className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																								/>
																							</>
																						)}
																					/>
																					{contractForm.formState?.errors?.performerAddressId?.message && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState.errors
																									?.performerAddressId?.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</td>
																			<td width={180}>City/State/Zip</td>
																			<td>
																				<div
																					style={{
																						display: 'flex',
																						alignItems: 'center',
																						gap: '8px',
																						flexWrap: 'wrap',
																					}}
																				>
																					<FormControl className={styles.tableForms}>
																						<Controller
																							key={`performer-zip-${contractForm.watch('performerAddressId.value')}`}
																							control={contractForm.control}
																							name='performerZip'
																							defaultValue=''
																							render={({ field }) => (
																								<NabuTextbox
																									key={`performer-zip-${contractForm.watch('performerAddressId.value')}`}
																									{...field}
																									placeholder={!props?.isPrint ? 'ZIP' : ''}
																									disabled={props?.isPrint}
																									onChange={(e) => {
																										field.onChange(e);
																										onZipCodeChange(
																											contractForm.watch('performerZip') || '',
																											'performer'
																										);
																									}}
																								/>
																							)}
																						/>
																						{contractForm.formState.errors?.performerZip
																							?.message && (
																							<FormHelperText
																								data-cy='errorHelper-performerZip'
																								error={true}
																							>
																								{
																									contractForm.formState?.errors?.performerZip
																										.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																					<FormControl className={styles.tableForms}>
																						<Controller
																							key={`performer-state-${contractForm.watch('performerState')}`}
																							control={contractForm.control}
																							name='performerState'
																							render={({ field }) => (
																								<NabuSelect
																									key={`performer-state-${contractForm.watch('performerState')}`}
																									{...field}
																									options={getStateOptions()}
																									disabled={props?.isPrint}
																									convertValueToUpperCase={false}
																									placeholder={!props?.isPrint ? 'Select State' : ''}
																								/>
																							)}
																						/>
																						{contractForm?.formState?.errors?.performerState
																							?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors?.performerState
																										.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																					<FormControl className={styles.tableForms}>
																						<Controller
																							key={`performer-city-${contractForm.watch('performerCity')}`}
																							control={contractForm.control}
																							name='performerCity'
																							defaultValue=''
																							render={({ field }) => (
																								<NabuSelect
																									key={`performer-city-${contractForm.watch('performerCity')}`}
																									{...field}
																									disabled={props?.isPrint}
																									options={getCityOptions(
																										contractForm.watch('performerState')
																									)}
																									convertValueToUpperCase={true}
																									placeholder={!props?.isPrint ? 'Select City' : ''}
																								/>
																							)}
																						/>
																						{contractForm.formState?.errors?.performerCity
																							?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors?.performerCity
																										.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</div>
																			</td>
																		</tr>
																	</table>
																</li>
															</ol>
															<br></br>

															<p style={{ textAlign: 'center' }}>
																<b>
														NOTE: Performer Must Complete W4 Form. - If minor,
														parent or guardian must sign.
																</b>
															</p>
															<br></br>
															<p style={{ textAlign: 'center' }}>
													Employer of Record for income tax and unemployment
													insurance is
																<br></br>
													Extreme Reach Talent, Inc. (fka Spotlight Payroll,
													Inc.), 111 W. Jackson Blvd., Suite 1525, Chicago, IL
													60604 312-726-4404
															</p>

															<br></br>
															<br></br>
															<table
																width={'100%'}
																border={1}
																cellPadding={0}
																cellSpacing={0}
																className={[styles.contractFormsInner, styles.tableBottom, 'keep-together'].join(' ')}
															>
																<tr>
																	<td width={180}>Performer&apos;s Phone</td>
																	<td>
																		<FormControl
																			className={styles.tableForms}
																			fullWidth
																		>
																			<Controller
																				control={contractForm.control}
																				key={`select-phone-ctr${contractForm.watch(
																					'performerPhone'
																				)}-${contractForm.watch(
																					'performerId.value'
																				)}`}
																				name='performerPhone'
																				defaultValue=''
																				render={({ field }) => (
																					<>
																						<NabuSelect
																							{...field}
																							key={`select-phone-${contractForm.watch(
																								'performerPhone'
																							)}-${contractForm.watch(
																								'performerId.value'
																							)}`}
																							placeholder={!props?.isPrint ? 'Select Phone' : ''}
																							convertValueToUpperCase={false}
																							enableEmptySelection={true}
																							disabled={
																								props?.isPrint ||
																						!contractForm.watch(
																							'performerId.value'
																						)
																							}
																							options={getPerformerPhoneNumberOptions()}
																							handleDefaultSelection={() => {
																								return !contractForm.watch('performerPhone');
																							}}
																						/>
																						{
																							<Box sx={{mt:1.3}}>
																								{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																								<AddPhoneNumberInline
																									formData={{ ...new PhoneNumberFormModel()}}
																									onSubmit={onPerformerPhoneNumberInlineSubmit}
																									isOpen={phoneNumberPopOver.isOpen && phoneNumberPopOver?.for == 'Performer-address-2'|| false}	
																									onClose={closePhoneNumberInlinePopOver}
																									title='Phone Number'
																									onPopOverOpen={(e) => openPhoneNumberInlinePopOver(e, 'Performer-address-2')}
																									event={phoneNumberPopOver?.event}	
																								/>
																								}
																							</Box>	
																						}
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors
																				?.performerPhone?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.performerPhone?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td width={210}>Performer&apos;s Email Address</td>
																	<td>
																		<FormControl
																			className={styles.emaillowerCase}
																			fullWidth
																		>
																			<Controller
																				key={`select-${contractForm.watch('performerId.value')}-${contractForm.watch('performerEmail')}`}
																				control={contractForm.control}
																				name='performerEmail'
																				defaultValue=''
																				render={({ field }) => (
																					<>
																						<NabuSelect
																							{...field}
																							defaultCase={true}
																							key={`select-${contractForm.watch('performerId.value')}-${contractForm.watch('performerEmail')}`}
																							options={getPerformerEmailsOptions()}
																							disabled={props?.isPrint || !contractForm.watch('performerId.value')}
																							convertValueToUpperCase={false}
																							placeholder={!props?.isPrint ? 'Select Email' : ''}
																							handleDefaultSelection={() => {
																								return !contractForm.watch('performerEmail');
																							}}
																						/>
																						{
																							<Box sx={{mt:1.3}}>
																								{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																						<AddEmailInline
																							formData={{ ...new EmailFormModel()}}
																							isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Performer-email-address'|| false}	
																							onSubmit={onPerformerEmailInlineSubmit}
																							onClose={closeEmailInlinePopOver}
																							title='Email address'
																							onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Performer-email-address')}
																							event={emailPopOver?.event}
																						/>
																								}
																							</Box>	
																						}
																					</>
																				)}
																			/>
																			{contractForm?.formState?.errors?.performerEmail
																				?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors?.performerEmail
																							.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																</tr>
															</table>
														</div>
													</div>
												</div>
											</>
											{/* Main Contract Form End */}

											{/* W4 Short form */}
											{packetControlForm.watch('isW4shortForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW4ShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
											{/* W8 Ben Short form */}
											{packetControlForm.watch('isW8BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW8BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* W9 Ben Short form */}
											{packetControlForm.watch('isW9BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW9BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* Minor Trust form */}
											{packetControlForm.watch('isMinorTrust') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractMinorTrustForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* NY-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isNYTheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractNYWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* CA-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isCATheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractCAWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>
					{!props?.isPrint && (
						<div className={styles.contractTemplateBtn}>
							<Grid container>
								<Grid xs={12} md={6}>
									<NabuButton variant='filled' onClick={onClickSnippetBtn}>
										{!contractFormState?.isPacketOpen ? (
											<>
												<NoteAdd /> Create
											</>
										) : (
											'Close'
										)}
								&nbsp; Packet
									</NabuButton>
								</Grid>
								<Grid xs={12} md={6} dir='rtl'>
									<NabuButton
										variant='outlined'
										data-cy='cancel_btn'
										shouldHandleConfirmAction={contractForm.formState.isDirty}
										onClick={() => handleFormClose()}
									>
								Cancel
									</NabuButton>{' '}
							&nbsp;
									<NabuButton
										variant='filled'
										type='submit'
										data-cy='save_btn'
										disabled={
											!(
												contractForm.formState.isDirty ||
										packetControlForm.formState.isDirty
											) ||
									isAddingContract ||
									isEditingContract
										}
									>
										{isAddingContract || isEditingContract ? 'Saving' : 'Save'}
										<Save />
									</NabuButton>{' '}
							&nbsp;
									<NabuButton
										variant='filled' 
										onClick={contractForm.handleSubmit(handleSaveAndDuplicate)}
										disabled={
											!(
												contractForm.formState.isDirty ||
										packetControlForm.formState.isDirty
											) ||
									isAddingContract ||
									isEditingContract || (!(props?.mainForm?.watch('contractDisplayName'))&& !props?.isEdit)
										}
									>
								Save & Duplicate <CopyAll />
									</NabuButton>
							&nbsp;
								</Grid>
							</Grid>
						</div>
					)}
				</form>
			</div>
			<TalentDetailsForm handleTalentDetailsForm={(value) => handleTalentDetailsForm(value)} noOfTalentsSelected={noOfTalentsSelected} setNoOfTalentsSelected={(value) => setNoOfTalentsSelected(value)} performerCategory={SagAftraVideoProgramsPerformerTypeOptions} isUnion={true}  />
		</>
	);
});
export default SagAftraSdEmpCtrIndEduFilmVideoPgm;
